import Vue from "vue";
import Vuex from "vuex";
import { TokenService } from "../../../services/token.service";
import { AuthService } from "../../../services/auth.service";
import { UserService } from "../../../services/user.service";

Vue.use(Vuex);

const state = {
  user: {
    info: {
      first_name: "",
      role: {
        name: ""
      }
    }
  },
  token: "",
  loading: false,
  tokenWatchdogTimer: null,
  applicationVersion: ""
};

const getters = {
  loggedUser: state => state.user,
  profile: state => state.user.info,
  isLogged: state =>
    state.token == null ? false : TokenService.isTokenValid(),
  getLoginLoading: state => state.loading,
  token: state => state.token,
  applicationVersion: state => state.applicationVersion
};

const mutations = {
  setProfile(state, profile) {
    state.user.info = profile;
  },
  setToken(state, token) {
    state.token = token;
    Vue.$jsonApi.headers.Authorization = `Bearer ${state.token}`;
    // state.user.id = TokenService.getUserId();
    // state.applicationVersion = TokenService.getVersion();
  },
  setUserId(state, id) {
    state.user.id = id;
  },
  setVersion(state, version) {
    state.applicationVersion = version;
  },
  startLoading(state) {
    state.loading = true;
  },
  stopLoading(state) {
    state.loading = false;
  },
  setTokenWatchdogTimer(state, interval) {
    if (state.tokenWatchdogTimer) {
      clearInterval(state.tokenWatchdogTimer);
    }
    state.tokenWatchdogTimer = interval;
  },
  resetToken(state) {
    state.token = null;
    TokenService.removeToken();

    // Vue.$jsonApi.headers.Authorization = null;
    // Vue.Axios.defaults.headers.post.Authorization = null;
    // Vue.Axios.defaults.headers.delete.Authorization = null;
    // Vue.Axios.defaults.headers.get.Authorization = null;
  }
};

const actions = {
  async login({ commit }, selected) {
    commit("startLoading");
    try {
      const response = await AuthService.login(selected.username, selected.password);
      if (response && response.data) {
        commit("setToken", response.data);
      }
      return response;
      // dispatch("ajaxSetProfile");
    } catch (error) {
      return error;
    } finally {
      commit("stopLoading");
    }
  },

  async logout({ dispatch, commit }) {
    commit("startLoading");
    try {
      await AuthService.logout();
      dispatch("sessionClose");
    } catch (error) {
      return error;
    } finally {
      commit("stopLoading");
    }
  },

  startTokenWatchdog({ dispatch, commit }) {
    dispatch("checkToken");
    const interval = setInterval(() => {
      dispatch("checkToken");
    }, process.env.VUE_APP_TOKEN_WATCHDOG_INTERVAL);
    commit("setTokenWatchdogTimer", interval);
  },

  checkToken({ dispatch, commit }) {
    if (TokenService.isTokenValid()) {
      const token = localStorage.jwt;
      commit("setToken", token);
      dispatch("loadFromToken");
    } else {
      dispatch("logout");
    }
  },

  loadFromToken({ commit }) {
    const payload = TokenService.getPayload();
    const userId = payload ? payload.id : null;
    const version = payload ? payload.version : null;
    commit("setUserId", userId);
    commit("setVersion", version);
  },

  sessionClose({ commit }) {
    AuthService.logout();
    commit("resetToken");
    commit("setTokenWatchdogTimer", null);
    commit("setUserId", null);
    commit("setVersion", null);
    commit("setProfile", {});
  },

  async ajaxSetProfile({ state, commit, dispatch }) {
    commit("startLoading");
    try {
      if (state.user.id == null) {
        dispatch("loadFromToken");
      }
      const data = await UserService.loadProfile(state.user.id);
      commit("setProfile", data);
    } catch (error) {
      return error;
    } finally {
      commit("stopLoading");
    }
  },

  async ajaxUpdateProfile({ dispatch, commit }, selected) {
    commit("startLoading");
    try {
      const params = {
        first_name: selected.first_name,
        last_name: selected.last_name
      };
      await UserService.updateProfile(state.user.info.id, params);
      dispatch("ajaxSetProfile");
    } catch (error) {
      return error;
    } finally {
      commit("stopLoading");
    }
  }

  /*async ajaxChangePsw({ dispatch, commit }, selected) {
    commit("startLoading");
    try {
      const params = {
        password: selected.password,
        confirm_password: selected.confirm_password,
        old_password: selected.old_password
      };
      await UserService.updatePassword(state.user.info.id, params);
      dispatch("ajaxSetProfile");
    } catch (error) {
      console.log("ajaxChangePsw error: ", JSON.stringify(error));
    } finally {
      commit("stopLoading");
    }
  }*/
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
