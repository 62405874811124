<template>
  <v-container fluid>
    <ToolbarDetail
      :title="detailTitle | upperCase"
      :withEdit="true"
      @onEdit="openFormDialog(Enums.FORM_MODE.UPDATE)"
    ></ToolbarDetail>

    <template v-if="loaded">
      <v-tabs
        class="my-4"
        v-model="activeTab"
        background-color="secondary"
        dark
        slider-color="accent"
        @change="syncUrl"
      >
        <v-tab name="details">{{ $t("labels.details") }}</v-tab>
        <!-- v-tab name="services">{{ $t("labels.services") }}</v-tab -->
        <v-tab name="attachments">{{ $t("labels.attachments") }}</v-tab>
        <v-tab name="links">{{ $t("labels.links") }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <v-card flat>
            <UserForm
              :mode="Enums.FORM_MODE.SHOW"
              :selectedItem="selectedUser"
            ></UserForm>
          </v-card>
        </v-tab-item>
        <!-- v-tab-item>
          <v-card flat>
            <ServicesList
              :listMode="Enums.LIST_MODE.TABBED"
              :withModelId="this.selectedUser.id"
            ></ServicesList>
          </v-card>
        </v-tab-item -->
        <v-tab-item>
          <v-card flat>
            <AttachmentsList
              withModelType="users"
              :withModelId="selectedUser.id"
              :listMode="Enums.LIST_MODE.TABBED"
            ></AttachmentsList>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <LinksList
              withModelType="user"
              :withModelId="this.selectedUser.id"
              :listMode="Enums.LIST_MODE.TABBED"
            ></LinksList>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <v-dialog v-model="formDialog" persistent content-class="edit-form-dialog">
      <UserForm
        v-if="formDialog"
        :mode="formMode"
        :selectedItem="this.preparedSelected()"
        @formSucceed="handleFormSucceed"
        @formCancel="handleFormCancel"
      ></UserForm>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "lodash";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { mapActions, mapGetters } from "vuex";
import FormsHelper from "@/mixins/formsHelper";
import UserForm from "@/components/User/UserForm";
import ServicesList from "@/views/ServicesList";
import AttachmentsList from "@/components/Attachment/AttachmentsList";
import LinksList from "@/components/Link/LinksList";
import ToolbarDetail from "@/components/Shared/ToolbarDetail";

export default {
  name: "UserDetails",
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      resourceType: this.$t("resource_types.user"),
      activeTab: null,
      loaded: false
    };
  },
  components: {
    UserForm,
    ServicesList,
    LinksList,
    AttachmentsList,
    ToolbarDetail
  },
  mixins: [SharedMixin, Enums, FormsHelper],
  watch: {
    id() {
      this.initComponent();
    }
  },
  methods: {
    ...mapActions("user", ["ajaxUserSetSelected"]),
    async initComponent() {
      await this.ajaxUserSetSelected({ id: this.$props.id });
      this.loaded = true;
    },
    syncUrl() {},
    handleFormSucceed() {
      this.closeFormDialog();
      this.ajaxUserSetSelected({ id: this.$props.id });
    },
    handleFormCancel() {
      this.closeFormDialog();
    },
    preparedSelected() {
      return _.clone(this.selectedUser);
    }
  },
  computed: {
    ...mapGetters("user", ["users", "selectedUser", "total", "onLoading"]),
    Enums() {
      return Enums;
    },
    userURL() {
      const r = this.$router.resolve({
        name: "users",
        params: {}
      });
      return r.href;
    },
    detailTitle() {
      return this.$t("labels.detailResourceTypeName", {
        resourceType: this.resourceType,
        resourceName:
          this.selectedUser.last_name + " " + this.selectedUser.first_name
      });
    }
  },
  created() {
    this.initComponent();
  }
};
</script>
