var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("AttachmentsList", {
        attrs: {
          withModelType: _vm.Enums.MODEL_NAME.CUSTOMERS,
          withModelId: this.selectedCustomer.info.id,
          machineName: _vm.Enums.FILE_TYPE.QUESTIONARIO,
          permittedTypes: _vm.Enums.FILE_TYPE.QUESTIONARIO,
          listMode: _vm.Enums.LIST_MODE.TABBED
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }