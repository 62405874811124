<template>
  <v-container fluid>
    <ToolbarDetail
      :title="detailTitle | upperCase"
      :withEdit="true"
      @onEdit="openFormDialog(Enums.FORM_MODE.UPDATE)"
    ></ToolbarDetail>

    <template v-if="loaded">
      <v-tabs
        class="my-4"
        v-model="activeTab"
        background-color="secondary"
        dark
        slider-color="accent"
        @change="syncUrl"
      >
        <v-tab key="details">{{ $t("labels.details") }}</v-tab>
        <v-tab key="attachments">{{ $t("labels.attachments") }}</v-tab>
        <v-tab key="links">{{ $t("labels.links") }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab">
        <v-tab-item key="details">
          <v-card flat>
            <ServiceForm
              :mode="Enums.FORM_MODE.SHOW"
              :selectedItem="this.selectedService"
            ></ServiceForm>
          </v-card>
        </v-tab-item>
        <v-tab-item key="attachments">
          <v-card flat>
            <AttachmentsList
              withModelType="services"
              :withModelId="this.selectedService.id"
              :listMode="Enums.LIST_MODE.TABBED"
            ></AttachmentsList>
          </v-card>
        </v-tab-item>
        <v-tab-item key="links">
          <v-card flat>
            <LinksList
              withModelType="services"
              :withModelId="this.selectedService.id"
              :listMode="Enums.LIST_MODE.TABBED"
            ></LinksList>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <v-dialog v-model="formDialog" persistent content-class="edit-form-dialog">
      <ServiceForm
        v-if="formDialog"
        :mode="Enums.FORM_MODE.UPDATE"
        :selectedItem="this.clonedSelected()"
        @formSucceed="handleFormSucceed"
        @formCancel="handleFormCancel"
        @formDelete="handleDelete"
      ></ServiceForm>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "lodash";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { mapActions, mapGetters } from "vuex";
import ServiceForm from "@/components/Service/ServiceForm";
import ToolbarDetail from "@/components/Shared/ToolbarDetail";
import FormsHelper from "@/mixins/formsHelper";
import AttachmentsList from "@/components/Attachment/AttachmentsList";
import LinksList from "@/components/Link/LinksList";

export default {
  name: "ServiceDetails",
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      resourceType: this.$t("resource_types.service"),
      activeTab: null,
      loaded: false
    };
  },
  components: {
    ServiceForm,
    ToolbarDetail,
    LinksList,
    AttachmentsList
  },
  mixins: [SharedMixin, Enums, FormsHelper],
  watch: {
    id() {
      this.initComponent();
    }
  },
  methods: {
    ...mapActions("service", ["ajaxServiceSetSelected"]),
    async initComponent() {
      await this.ajaxServiceSetSelected({ id: this.$props.id });
      this.loaded = true;
    },
    syncUrl() {},

    handleFormSucceed() {
      this.closeFormDialog();
      this.ajaxServiceSetSelected({ id: this.$props.id });
    },
    handleFormCancel() {
      this.closeFormDialog();
    },
    handleDelete() {
      this.$router.pushCatch({ name: "services" });
    },
    clonedSelected() {
      return _.clone(this.selectedService);
    }
  },
  computed: {
    ...mapGetters("service", [
      "services",
      "total",
      "onLoading",
      "selectedService"
    ]),
    Enums() {
      return Enums;
    },
    detailTitle() {
      return this.$t("labels.detailResourceTypeName", {
        resourceType: this.resourceType,
        resourceName: this.selectedService.name
      });
    }
  },
  created() {
    this.initComponent();
  }
};
</script>
