import Vue from "vue";
import vuetify from "@/plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {} from "./bootstrap";
import {} from "./devour";
import "./filters";
import i18n from "./i18n";
import settings from "./settings";
import "vuetify/dist/vuetify.min.css";
import rollbar from "./rollbar";

Vue.config.productionTip = false;

Vue.prototype.$rollbar = rollbar;

Vue.config.errorHandler = (err, vm, info) => {
  vm.$rollbar.error(err);
  throw err; // rethrow
};

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");

Object.defineProperty(Vue.prototype, "$settings", { value: settings });
