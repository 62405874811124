<template>
  <div>
    <ApplicationLogFilter
      @filtersSelected="handleFiltersSelected"
      ref="drawer"
      @drawerChanged="handleDrawer"
    ></ApplicationLogFilter>
    <div :class="{ 'center-panel': drawer_flag }">
      <v-card v-if="this.listMode === Enums.LIST_MODE.FULL">
        <ToolbarList
          :title="$t('labels.applicationLog')"
          :subtitle="$tc('labels.found_elements', this.total)"
          :withAdd="false"
          :withFilter="true"        
          @onFilter="$refs.drawer.open()"
        ></ToolbarList>
        <BaseGrid
          tableName="application-log"
          :headers="this.tableData.headers"
          :items="this.tableData.items"
          :totalLength="this.total"
          :pagination="this.tableOptions"
          :loading="this.onLoading"
          :hideActions="true"
          @onPaginationChanged="handlePaginationChanged"
        ></BaseGrid>
      </v-card>
      <v-container v-if="this.listMode === Enums.LIST_MODE.TABBED">
        <v-card>
          <ToolbarTab
            :subtitle="$tc('labels.found_elements', this.total)"
            :withAdd="false"
          ></ToolbarTab>
          <BaseGrid
            tableName="application-log"
            :headers="this.tableData.headers"
            :items="this.tableData.items"
            :totalLength="this.total"
            :pagination="this.tableOptions"
            :loading="this.onLoading"
            :hideActions="true"
            @onPaginationChanged="handlePaginationChanged"
          ></BaseGrid>
        </v-card>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import ToolbarList from "@/components/Shared/ToolbarList";
import ToolbarTab from "@/components/Shared/ToolbarTab";
import BaseGrid from "@/components/Shared/BaseGrid";
import FormsHelper from "@/mixins/formsHelper";
import { HelperService } from "@/services/helper.service";
import ApplicationLogFilter from "@/components/Filter/ApplicationLogFilter";

import Enums from "@/mixins/enums";
// import { Ability } from "@casl/ability";
// import {} from "@/abilities";

const computed = {
  ...mapGetters("applicationLog", ["applicationLogs", "total", "onLoading"]),
  _() {
    return _;
  },
  Enums() {
    return Enums;
  },
  applicationLogList() {
    return this.applicationLogs;
  },
  showPost() {}
};

export default {
  name: "applicationLogList",
  components: {
    ToolbarList,
    ToolbarTab,
    BaseGrid,
    ApplicationLogFilter
  },
  props: {
    listMode: {
      default: Enums.LIST_MODE.FULL,
      type: String
    },
    withModelType: {},
    withModelId: {}
  },
  data() {
    return {
      //resourceType: this.$t("resource_types.activity"),
      progress: false,
      formDialog: false,
      formMode: "",
      tableData: { headers: [], items: [] },
      tableOptions: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["created_at"],
        sortDesc: [false]
      },
      drawer_flag: false,
      filter_criteria: {},
      resourceMapping: {
        agreements: item => {
          return item.code;
        },
        tickets: item => {
          return item.code;
        },
        orders: item => {
          return item.number;
        },
        tenants: item => {
          return item.name;
        },
        services: item => {
          return item.name;
        },
        customers: item => {
          return item.name;
        },
        generation_sessions: item => {
          return item.id;
        },
        activities: () => {
          return "";
        },
        attachments: item => {
          return item.name;
        },
        links: item => {
          return item.description;
        },
        users: item => {
          return item.last_name + " " + item.first_name;
        },
        email_message: item => {
          return item.subject
        }
      },
      selectedFields: {
        agreements: [],
        tickets: [],
        orders: [],
        tenants: [],
        services: [],
        customers: [],
        generation_sessions: [],
        activities: [],
        attachments: [],
        links: [],
        users: [],
        emails: []
      },
      actionMapping: {
        UPDATE: this.$t("labels.updated"),
        CREATE: this.$t("labels.created"),
        DELETE: this.$t("labels.deleted"),
        LOGIN: this.$t("labels.login"),
        SEND_EMAIL: this.$t("labels.send_email"),
      }
    };
  },
  methods: {
    ...mapActions("applicationLog", [
      "ajaxApplicationLogFetchAll"
    ]),

    async fetchData() {
      let payloadFAP;
      if (this.listMode === Enums.LIST_MODE.TABBED)
        payloadFAP = HelperService.buildFilterAndPagination({
          pagination: this.tableOptions,
          filter: {
            model_id: this.withModelId,
            model_type: this.withModelType
          }
        });
      else
        payloadFAP = HelperService.buildFilterAndPagination({
          pagination: this.tableOptions,
          filter_criteria: this.filter_criteria,
          filter: this.filter
        });
      await this.ajaxApplicationLogFetchAll(payloadFAP);
      this.initTable();
    },
    initTable() {
      this.tableData.headers = this.mapHeaders();
      this.tableData.items = this.mapItems();
    },
    mapHeaders() {
      const tableHeaders = [];
      tableHeaders.push({
        text: "User",
        value: "user",
        align: "start",
        sortable: false
      });
      tableHeaders.push({
        text: "Description",
        value: "description",
        sortable: true,
        align: "start"
      });
      tableHeaders.push({
        text: "Created at",
        value: "created_at",
        align: "start",
        sortable: true
      });
      return tableHeaders;
    },
    mapItems() {
      const map = _.map(this.applicationLogList, item => {
        item.fields = {
          user: {
            data: this.getUser(item),
            dataType: "text",
            css: "text-xs-left"
          },
          description: {
            data: this.descriptionAction(item),
            dataType: "text",
            css: "text-xs-left"
          },
          created_at: {
            data: item.created_at,
            dataType: "datetime",
            css: "text-xs-left"
          }
        };
        return item;
      });
      return map;
    },
    handleDrawer(val) {
      this.drawer_flag = val;
    },
    handleFormSucceed() {
      this.closeFormDialog();
      this.fetchData();
    },
    handleFormCancel() {
      this.closeFormDialog();
    },
    handlePaginationChanged(paginationOpts) {
      this.tableOptions = paginationOpts;
      this.fetchData();
    },
    activityAction(item) {
      if (!item || !item.metadata || !item.metadata.action) return "";
      return item.metadata.action;
    },
    te(type, key) {
      let path = "forms." + type + "." + key;
      return this.$te(path);
    },
    translate(type, key) {
      let path = "forms." + type + "." + key;
      if (!this.$te(path)) return "?" + key;
      return this.$t(path);
    },
    fixNull(item) {
      if (!item) return this.$t("labels.propDeleted");
      return item;
    },
    descriptionAction(item) {
      let action = this.activityAction(item);

      if (!item || action === "LOGIN") {
        return (
          '<span class="colored">' + this.actionMapping[action] + "</span>"
        );
      } else {
        let obj;
        if (item.metadata.old_object) obj = item.metadata.old_object;
        else obj = item.metadata.new_object;

        let resType = this.$t("resource_type." + item.model_type);

        let resName;
        if (obj && this.resourceMapping[item.model_type]) {
          resName = this.resourceMapping[item.model_type](obj);
          if (resName != "") resName = ' "' + resName + '"';
        } else resName = " (id " + item.model_id + ")";        
        
        if (action == "SEND_EMAIL") {
          resName = " (id " + item.model_id + ")";
        }
        let text =
          '<span class="colored">' +
          this.actionMapping[action] +
          " " +
          resType +
          resName +
          "</span>";

        if (action == "SEND_EMAIL") {
          item.model_type = "emails";
        }
        if (item.diff && (action == "CREATE" || action == "UPDATE" || action == "SEND_EMAIL")) {
          for (let key in item.diff) {
            if (action === "UPDATE" || item.diff[key])
              if (
                !this.selectedFields[item.model_type] ||
                this.selectedFields[item.model_type].length == 0 ||
                this.selectedFields[item.model_type].includes(key)
              )
                if (this.te(item.model_type, key))
                  text +=
                    "<br><b>" +
                    this.translate(item.model_type, key) +
                    "</b>: " +
                    this.fixNull(item.diff[key]);
          }
        }
        return text;
      }
    },
    getUser(item) {
      if (item.user) {
        return item.user.last_name + " " + item.user.first_name
      } else {
        return this.$t("labels.system")
      }
    },    
    async handleFiltersSelected(
      allClear,
      actionFilter,
      modelTypeFilter,
      userFilter,
      startDateFilter,
      endDateFilter
    ) {
      this.allClear = allClear;
      this.filter_criteria.action = actionFilter;
      this.filter_criteria.model_type = modelTypeFilter;
      this.filter_criteria.user_id = userFilter;
      this.filter = {
        gt: { created_at: startDateFilter }
      }
      if(endDateFilter)
        this.filter.lt = { created_at: endDateFilter }
      await this.fetchData();
    },
  },
  mixins: [FormsHelper, Enums],
  async created() {
    await this.fetchData();
  },
  computed
};
</script>

<style>
.colored {
  color: #00f;
}
</style>
