<template>
  <v-container fluid>
    <ToolbarDetail
      :title="detailTitle | capitalize"
      @onBack="setAgreementFlag(true)"
    ></ToolbarDetail>

    <br />
    <InvoicePeriodsGrid></InvoicePeriodsGrid>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import InvoicePeriodsGrid from "@/components/InvoicePeriod/InvoicePeriodsGrid";
import ToolbarDetail from "@/components/Shared/ToolbarDetail";

export default {
  name: "AgreementsInvoicing",
  components: {
    InvoicePeriodsGrid,
    ToolbarDetail
  },
  computed: {
    detailTitle() {
      return this.$t("labels.invoicing");
    }
  },
  methods: {
    ...mapMutations("filters", ["setAgreementFilter", "setAgreementFlag"])
  }
};
</script>
