import { render, staticRenderFns } from "./Navigation.vue?vue&type=template&id=81440b78&scoped=true&"
import script from "./Navigation.vue?vue&type=script&lang=js&"
export * from "./Navigation.vue?vue&type=script&lang=js&"
import style0 from "./Navigation.vue?vue&type=style&index=0&id=81440b78&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81440b78",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VList,VListItem,VListItemTitle,VMenu})


/* hot reload */
if (module.hot) {
  var api = require("/home/deploy/netcrm/staging/source/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('81440b78')) {
      api.createRecord('81440b78', component.options)
    } else {
      api.reload('81440b78', component.options)
    }
    module.hot.accept("./Navigation.vue?vue&type=template&id=81440b78&scoped=true&", function () {
      api.rerender('81440b78', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Navigation.vue"
export default component.exports