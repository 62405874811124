/* eslint-disable no-unused-vars */
import _ from "lodash";

export default {
  methods: {},
  data() {
    return {
      progress: false,
      tableData1: { headers: [], items: [] },
      tableOptions1: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["name"],
        sortDesc: [false],
        multiSort: false,
        mustSort: true
      },
      tableData2: { headers: [], items: [] },
      tableOptions2: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["name"],
        sortDesc: [false],
        multiSort: false,
        mustSort: true
      },
      tableOptions3: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["name"],
        sortDesc: [false],
        multiSort: false,
        mustSort: true
      },
      tableOptions4: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["name"],
        sortDesc: [false],
        multiSort: false,
        mustSort: true
      }
    };
  },
  computed: {}
};
