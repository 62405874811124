<template>
  <div>    
    <v-card>
      <ToolbarList
        :title="this.$t('report_type.'+this.reportType)"
        :subtitle="$tc('labels.found_elements', this.total)"
        :withExportPdf="true"
        :withFilter="false"
        :reportType="this.reportType"
        @onExportPdf="exportPdf"
        @onReportFilter="handleFiltersSelected"
      ></ToolbarList>
      <BaseGrid
        id="content"
        tableName="reports"
        :headers="this.headers"
        :items="this.items"
        :totalLength="this.total"
        :pagination="this.tableOptions"
        :loading="this.onLoading"
        @onPaginationChanged="handlePaginationChanged"
      ></BaseGrid>
    </v-card>     
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

import BaseGrid from "@/components/Shared/BaseGrid";
import ToolbarList from "@/components/Shared/ToolbarList";

import FormsHelper from "@/mixins/formsHelper";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";

import { HelperService } from "@/services/helper.service";
import { ReportService } from "@/services/report.service";

const computed = {
  ...mapGetters("report", ["reports", "total", "onLoading"]),

  _() {
    return _;
  },
  Enums() {
    return Enums;
  },
  reportsList() {
    return this.reports;
  }
};

export default {
  name: "reportsList",
  components: {
    BaseGrid,
    ToolbarList
  },
  props: {
  },
  data() {
    return {
      resourceType: this.$t("resource_types.reports"),
      reportType: "",
      headers: [],
      items: [],
      tableOptions: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["peo"]
      },
      filterCriteria: {}
    };
  },
  mixins: [FormsHelper, Enums, SharedMixin],
  watch: {
    '$route': 'fetchData'
  },
  methods: {
    ...mapActions("report", ["ajaxReportFetchAll"]),

    async fetchData() {
      this.reportType = this.$router.history.current.params.report_type;
      if(this.reportType === 'expiring_agreements') {
        this.tableOptions.sortBy = ['end_date'];
      }
      let payloadFAP = HelperService.buildFilterAndPagination({
        pagination: this.tableOptions,
        filter_criteria: { report_type: this.reportType}
      });
      await this.ajaxReportFetchAll(payloadFAP)
      this.initTable();
    },
    initTable() {
      this.headers = this.mapHeaders();
      this.items = this.mapItems();
    },
    mapHeaders() {
      const tableHeaders = [];
      if (this.reportType === 'questionario_gdpr') {
        tableHeaders.push({
          text: "Name",
          value: "name",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Peo",
          value: "peo",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "UploadFile",
          value: "upload_file",
          align: "start"
        });
      }
      if (this.reportType === 'expiring_agreements') {
        tableHeaders.push({
          text: "Code",
          value: "code",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Service",
          value: "service_machine_name",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Customer",
          value: "customer_name",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Start date",
          value: "start_date",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "End date",
          value: "end_date",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "State",
          value: "state",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Tenant",
          value: "tenant_name",
          align: "start",
          sortable: true
        });
      }
      return tableHeaders;
    },
    mapItems() {
      let map = {};
      if (this.reportType === 'questionario_gdpr') {
        map = _.map(this.reportsList, item => {
          item.fields = {
            name: { data: item.name, dataType: "text", css: "text-uppercase" },
            peo: { data: item.peo, dataType: "text", css: "text-xs-left" },
            uploadFile: {
              data: item.attachments.length > 0 ? "Si" : "No",
              dataType: "text",
              css: "text-xs-left"
            }
          };
          return item;
        });
      }
      if (this.reportType === 'expiring_agreements') {
        map = _.map(this.reportsList, item => {
          item.fields = {
            code: { data: item.code, dataType: "text", css: "text-uppercase" },
            service_machine_name: { data: this.getService(item.services), dataType: "text", css: "text-xs-left" },     
            customer_name: { data: item.customer.name, dataType: "text", css: "text-uppercase" },           
            start_date: { data: item.start_date, dataType: "date", css: "text-xs-left" },           
            end_date: { data: item.end_date, dataType: "date", css: "text-xs-left" },           
            state: { data: this.getEnumTranslationFor("agreements", item.state), dataType: "text", css: "text-xs-left" },      
            tenant_name: { data: item.tenant.name, dataType: "text", css: "text-xs-left" }      
          };
          return item;
        });
      }      
      return map;
    },
    getService(services) {
      let service_name = '';
      for(let i=0; i<services.length;i++){
        service_name = services.map(object => object.machine_name).join(' - ')
      }
      return service_name;
    },
    exportPdf() {
      this.$confirm({
        message: this.$t("dialogs.exportPdf"),
        button: {
          no: this.$t("dialogs.no"),
          yes: this.$t("dialogs.yes")
        },
        callback: async confirm => {          
          if (confirm) {            
            const filter_criteria = this.filterCriteria;
            const responseExport = await ReportService.export(filter_criteria);
            var filename = `report_${this.reportType}.pdf`;
            var fileURL = window.URL.createObjectURL(responseExport.data);
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', filename);
            document.body.appendChild(fileLink);
            fileLink.click();
            fileLink.remove();
          }
        }
      });
    },
    async handleFiltersSelected(reportFilter) {
      this.goBack();      
      this.filterCriteria = this.uniformatFilterCriteria(reportFilter);
      let payloadFAP = HelperService.buildFilterAndPagination({
        pagination: this.tableOptions,
        filter_criteria: this.filterCriteria
      });
      await this.ajaxReportFetchAll(payloadFAP);
      this.initTable();
      this.scrollToTop();
    },
    handlePaginationChanged(paginationOpts) {
      this.tableOptions = paginationOpts;
      this.fetchData();
    },
    goBack() {
      this.tableOptions = { ...this.tableOptions, page: 1 };
    },
    scrollToTop() {
      this.$vuetify.goTo(0);
    },
    uniformatFilterCriteria(reportFilter) {
      let filter = {
        report_type : this.reportType        
      }
      if (this.reportType === 'questionario_gdpr') {
        filter.upload_file = reportFilter.uploadFileFilter
      }
      if (this.reportType === 'expiring_agreements') {
        if (reportFilter.customerTypeFilter) filter.customer_type = reportFilter.customerTypeFilter;
        if (reportFilter.serviceFilter) filter.having_agreement_of_type = reportFilter.serviceFilter;
        if (reportFilter.tenantFilter) filter.tenant_id = reportFilter.tenantFilter;
        if (reportFilter.endDateFrom) filter.end_date_from = reportFilter.endDateFrom;
        if (reportFilter.endDateTo) filter.end_date_to = reportFilter.endDateTo;
      }
      return filter;
    }
  },
  async created() {
    await this.fetchData();    
  },
  computed
};
</script>
