/*  eslint no-shadow: ["error", { "allow": ["state"] }] */
/*  eslint no-param-reassign: ["error", { "props": false }] */
import Vue from "vue";
import Vuex from "vuex";
import { ReportService } from "@/services/report.service";
import { HelperService } from "@/services/helper.service";

Vue.use(Vuex);

const state = {
  reports: [],
  loading: false,
  totalCount: 0
};

const getters = {
  reports: state => state.reports,
  onLoading: state => state.loading,
  total: state => state.totalCount
};

const mutations = {
  setList(state, sel) {
    state.reports = sel;
  },
  setTotal(state, sel) {
    state.totalCount = Number(sel);
  },
  setLoading(state, sel) {
    state.loading = sel;
  }
};

const actions = {

  async ajaxReportFetchAll({ commit }, payload) {
    let searchFilterPagination = HelperService.localToJSONAPIPagination(
      payload
    );
    await HelperService.genericFetchAll(
      commit,
      ReportService.fetchAll,
      searchFilterPagination,
      null,
      null
    );
  },

 
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
