<template>
  <v-progress-circular indeterminate class="mr-3" v-if="loading" />
  <v-container fluid v-else>
    <template>    
      <v-toolbar flat class="toolbar-94" elevation="1">
        <v-btn fab small @click="goTo()">
          <v-icon>{{ $t("icons.arrow_back") }}</v-icon>
        </v-btn>
        <v-toolbar-title class="mx-7">{{ detailTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!this.isRootSection"
          color="accent"
          small
          outlined
          class="mx-4 text-xs-right"
          @click.stop="openFormDialog(Enums.FORM_MODE.UPDATE)"
        >
          <v-icon small>{{ $t("icons.edit") }}</v-icon>
          &nbsp;
          {{ $t("buttons.edit") }}
        </v-btn>
      </v-toolbar>
    </template>

    <template v-if="selectedFolder.fixed_structure">
      <div v-if="this.tagName==Enums.TAG_NAME.PROCEDURA_AFFIDAMENTO">
        <ProcedureAffidamento
          :withModelType="this.withModelType"
          :withModelId="this.withModelId"
          :withParentId="this.id"
          :folderId="this.id"
        ></ProcedureAffidamento>
      </div>
      <FileUploader v-else
        :mode="Enums.FORM_MODE.CREATE"        
        :withModelType="this.withModelType"
        :withModelId="this.withModelId"
        :tagName="this.tagName"
        :folderId="this.id"
      ></FileUploader>
    </template>

    <template v-else>
      <v-card-text class=text-left v-if="this.tagName==Enums.TAG_NAME.ATTI_AMMINISTRAZIONI_ENTI">   
        <v-card flat v-if="this.isRootSection">
          <FoldersList
            :withModelType="this.withModelType"
            :withModelId="this.withModelId"
            :withParentId="this.id"
            :listMode="Enums.LIST_MODE.FULL"
            :tagName="this.tagName"
            :isRootSection="this.isRootSection"
          ></FoldersList>
        </v-card>
        <v-card flat v-else :key="componentKey">
          <FolderForm
            :mode="Enums.FORM_MODE.SHOW"
            :selectedItem="this.selectedFolder"
            :withModelType="this.withModelType"
            :withModelId="this.withModelId"
            @formSucceed="handleFormSucceed"
          ></FolderForm>
          <ProceedingsList
            :withModelType="this.withModelType"
            :withModelId="this.withModelId"
            :folderId="this.id"
            :listMode="Enums.LIST_MODE.FULL"
            :tagName="this.tagName"
          ></ProceedingsList>
        </v-card>

        <!-- v-card flat v-if="this.withAttchments">
          <AttachmentsList
            :withModelType="this.withModelType"
            :withModelId="this.withModelId"
            :listMode="Enums.LIST_MODE.FULL"
            :tagName="this.tagName"
            :folderId="this.id"
          ></AttachmentsList>
        </v-card>     
        <v-card flat v-if="this.withAttchments">
          <LinksList
            :withModelType="this.withModelType"
            :withModelId="this.withModelId"
            :listMode="Enums.LIST_MODE.FULL"
            :tagName="this.tagName"
            :withFolderId="this.id"
            :readOnly="true"
          ></LinksList>
        </v-card -->
      </v-card-text>
      <v-card-text class=text-left v-else>   
        <v-card flat>
          <ProceedingsList
            :withModelType="this.withModelType"
            :withModelId="this.withModelId"
            :folderId="this.id"
            :listMode="Enums.LIST_MODE.FULL"
            :tagName="this.tagName"
          ></ProceedingsList>
        </v-card>
      </v-card-text>
    </template>

    <v-dialog v-model="formDialog" persistent content-class="edit-form-dialog">
      <FolderForm
        v-if="formDialog"
        :mode="formMode"
        :selectedItem="selectedFolder"
        :withModelType="this.withModelType"
        :withModelId="this.withModelId"
        :tagName="this.tagName"
        :isRootSection="this.isRootSection"
        @formSucceed="handleFormSucceed"
        @formCancel="handleFormCancel"
      ></FolderForm>
    </v-dialog>

  </v-container>
</template>

<script>

import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import FormsHelper from "@/mixins/formsHelper";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { HelperService } from "@/services/helper.service";
import ToolbarDetail from "@/components/Shared/ToolbarDetail";
import FileUploader from "@/views/Customer/FileUploader";
import FoldersList from "@/components/Folder/FoldersList";
import FolderForm from "@/components/Folder/FolderForm";
import AttachmentsList from "@/components/Attachment/AttachmentsList";
import ProceedingsList from "@/components/Proceeding/ProceedingsList";
import LinksList from "@/components/Link/LinksList";
import ProcedureAffidamento from "@/views/ProcedureAffidamento";

const computed = {
  ...mapGetters("folder", ["folders", "selectedFolder"]),
  ...mapGetters("tagging", ["taggings"]),
  _() {
    return _;
  },
  Enums() {
    return Enums;
  }
};

export default {
  name: "folderDetails",
  components: {
    ToolbarDetail,
    FileUploader,    
    FoldersList,
    FolderForm,
    AttachmentsList,
    ProceedingsList,
    LinksList,
    ProcedureAffidamento
  },
  props: {
    id: {
      type: String,
      required: true
    },
    mode: {
      default: Enums.FORM_MODE.CREATE,
      type: String
    },
    withModelType: {
      default: Enums.MODEL_NAME.AGREEMENTS,
      type: String,
    },
    withModelId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      detailTitle: "",
      isRootSection: true,
      withAttchments: false,
      tagName: Enums.TAG_NAME.PROGRAMMA_ANNUALE,
      procedureFolderId: "",
      componentKey: 0
    };
  },
  watch: {
    async id() {
      this.initComp();
    }
  },
  methods: {
    ...mapActions("folder", ["ajaxFolderFetchAll", "ajaxFolderSetSelected"]),
    ...mapActions("tagging", ["ajaxTaggingFetchAll"]),
    handleFormSucceed() {
      this.componentKey += 1;
      this.closeFormDialog();
      this.initComp();
    },
    handleFormCancel() {
      this.closeFormDialog();
    },
    async initComp() {
      await this.ajaxFolderSetSelected({ id: this.$props.id });

      let _filter = { taggable_type: Enums.MODEL_NAME.FOLDERS, taggable_id: this.$props.id };     
      let payloadFAP = HelperService.buildFilterAndPagination({
        filter: _filter
      });
      await this.ajaxTaggingFetchAll(payloadFAP);

      if (this.taggings && this.taggings.length === 1) {
        this.tagName = this.taggings[0].tag.name;
      }

      this.isRootSection = (this.selectedFolder && this.selectedFolder.parent_id == undefined) ? true : false;
      this.withAttchments = (this.selectedFolder && this.selectedFolder.parent_id == undefined) ? false : true;
     
      this.detailTitle = await this.makeTitle();
      this.loading = false;
    },
    async makeTitle() {
      var title = this.$t("labels.at");
      var resourceTranslate =  this.$t("labels.detailsPublicPageAT", {
        resourceName: this.selectedFolder.name
      });
      if (this.selectedFolder.parent_id) {
        let _filter = { model_id: this.$props.withModelId, model_type: this.$props.withModelType, parent_id: 'null' }
        let _filter_criteria = { tag_name: Enums.TAG_NAME.PROCEDURA_AFFIDAMENTO }
        let payloadFAP = HelperService.buildFilterAndPagination({
          filter: _filter,
          filter_criteria: _filter_criteria
        });
        await this.ajaxFolderFetchAll(payloadFAP);
        if (this.folders && this.folders.length == 1) {
          this.procedureFolderId = this.folders[0].id;
          //title = `${title} -> ${this.folders[0].name}`
        }
      }
      /*var parent_id = this.selectedFolder.parent_id;
      while (parent_id) {
        await this.ajaxFolderSetSelected({ id: parent_id });
        var resourceTranslateSubSection = this.$t("labels.detailsPublicPageAT", {
          resourceName: this.selectedFolder.name
        });
        parent_id = this.selectedFolder.parent_id
        resourceTranslate = resourceTranslateSubSection + " -> " + resourceTranslate
      }
      await this.ajaxFolderSetSelected({ id: this.$props.id });*/

      title = `${title} -> ${resourceTranslate}`
      return title;
    },
    goTo() {
      if (this.selectedFolder && this.selectedFolder.parent_id === null) {
        this.$router.pushCatch({ name: "amministrazione_trasparente", params: { withModelId: this.$props.withModelId } });
      } else {
        //this.$router.pushCatch({ name: "folderDetails", params: { withModelId: this.$props.withModelId, id: String(this.selectedFolder.parent_id) } });
        this.$router.pushCatch({ name: "folderDetails", params: { withModelId: this.$props.withModelId, id: String(this.procedureFolderId) } });
      }
    },
  },
  mixins: [FormsHelper, Enums, SharedMixin],
  async created() {
    this.initComp();
  },
  computed
};
</script>
