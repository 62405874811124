var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      this.listMode === _vm.Enums.LIST_MODE.FULL
        ? _c(
            "v-card",
            [
              this.proceeding_type === _vm.Enums.PROCEEDING_TYPE.ATTI_PROCEDURA
                ? _c(
                    "v-toolbar",
                    {
                      staticClass: "toolbar-94",
                      staticStyle: { "background-color": "#7389ae24" },
                      attrs: { flat: "", elevation: "1" }
                    },
                    [
                      _c(
                        "v-toolbar-title",
                        {
                          staticClass: "mx-7",
                          staticStyle: { "font-weight": "bold" }
                        },
                        [_vm._v(_vm._s(_vm.detailTitle))]
                      ),
                      _c("v-spacer"),
                      !this.isRootSection
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mx-4 text-xs-right",
                              attrs: {
                                color: "accent",
                                small: "",
                                outlined: ""
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.openFormDialog(
                                    _vm.Enums.FORM_MODE.UPDATE
                                  )
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(_vm._s(_vm.$t("icons.edit")))
                              ]),
                              _vm._v(
                                "\n         \n        " +
                                  _vm._s(_vm.$t("buttons.edit")) +
                                  "\n      "
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _c("ToolbarList", {
                    attrs: {
                      title: _vm.detailTitle,
                      subtitle: _vm.$tc("labels.found_elements", this.total),
                      withSearch: true
                    },
                    on: { onSearch: _vm.searchFunc }
                  }),
              this.proceeding_type === _vm.Enums.PROCEEDING_TYPE.ATTI_PROCEDURA
                ? _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "4" } }),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("misc.procedure-search"),
                              color: "warning",
                              clearable: "",
                              "single-line": ""
                            },
                            on: { input: _vm.searchFunc },
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("BaseGrid", {
                attrs: {
                  tableName: "folders",
                  headers: this.tableData.headers,
                  items: this.tableData.items,
                  totalLength: this.total,
                  pagination: this.tableOptions,
                  loading: this.onLoading
                },
                on: { onPaginationChanged: _vm.handlePaginationChanged }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "content-class": "edit-form-dialog" },
          model: {
            value: _vm.formDialog,
            callback: function($$v) {
              _vm.formDialog = $$v
            },
            expression: "formDialog"
          }
        },
        [
          _vm.formDialog
            ? _c("ProceedingForm", {
                attrs: {
                  mode: _vm.formMode,
                  selectedItem: _vm.formItem,
                  withModelType: this.withModelType,
                  withModelId: this.withModelId,
                  withPermalink: this.permalink,
                  procedureId: this.procedure_id,
                  tagName: this.tag_name,
                  proceedingType: this.proceeding_type
                },
                on: {
                  formSucceed: _vm.handleFormSucceed,
                  formCancel: _vm.handleFormCancel
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }