import Vue from "vue";

const PermaLinkService = {
  async fetchOne(id) {
    if (id == null) {
      return null;
    }
    try {
      const data = await Vue.$jsonApi.find("permalink", id, {});
      return data.data[0];
    } catch (error) {
      console.log("error fetchOne: ", error);
      return { error: error };
    }
  },
  async fetchAll(searchFilterPagination) {
    try {
      const res = await Vue.$jsonApi.findAll(
        "permalinks",
        searchFilterPagination
      );
      return res;
    } catch (error) {
      console.log("error fetchAll: ", error);
      return { error: error };
    }
  },
  async createOrUpdate(payload) {
    try {
      if (payload.id == "" || payload.id == null) {
        return await Vue.$jsonApi.create("permalink", payload);
      } else {
        return await Vue.$jsonApi.update("permalink", payload);
      }
    } catch (error) {
      console.log("createOrUpdate error: ", error);
      return { error: error };
    }
  },
  async delete(id) {
    if (id == null) {
      return null;
    }
    try {
      const data = await Vue.$jsonApi.destroy("permalink", id);
      return data;
    } catch (error) {
      console.log("delete error: ", error);
      return { error: error };
    }
  },
};

export { PermaLinkService };
