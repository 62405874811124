<template>
  <div>
    <v-progress-linear
      v-if="loading"
      color="info"
      height="5"
      indeterminate
    ></v-progress-linear>
    <v-data-table
      ref="dTable"
      :headers="headersComputed"
      :items="items"
      :server-items-length="serverItems ? totalLength : -1"
      :options.sync="paginationOpts"
      :must-sort="true"
      dense
      :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100] }"
      hide-default-footer
    >
      <template v-slot:body="{ items }">
        <tbody>
          <template v-for="(item, i) in items">
            <tr :key="i">
              <!-- :class="showRowFooter ? 'tr1' : 'tr0'" -->
              <td
                v-for="(v, fieldName, j) in item.fields"
                :key="fieldName"
                :class="v.css + ' ' + tableName + '-' + j.toString()"
              >
                <a
                  :href="resolve(item, item.click)"
                  @click="handleRowClick(item, item.click)"
                  :class="{
                    'cursor-pointer': !('click' in item) || !item.click.show,
                    'td-content': true
                  }"
                >
                  <v-chip v-if="v.dataType == 'chip'" :class="v.chipClass">{{
                    v.data
                  }}</v-chip>
                  <span v-if="v.dataType == 'text'" v-html="v.data"></span>
                  <span v-if="v.dataType == 'datetime'">{{
                    v.data | datetime
                  }}</span>
                  <span v-if="v.dataType == 'date'">{{ v.data | date }}</span>
                  <a v-if="v.dataType == 'link'" :href="v.data">{{ v.data }}</a>
                </a>
              </td>

              <td class="text-center actions-td" v-if="!hideActions">
                <a
                  :href="resolve(item, item.click)"
                  :class="{
                    'cursor-pointer': !('click' in item) || !item.click.show,
                    'td-content': true
                  }"
                >
                  <slot :item="item" name="actions"></slot>
                  <template v-for="(v, index) in item.actions">
                    <a
                      v-if="v.actionType == 'router-link'"
                      :key="index"
                      :href="resolve(item, v)"
                      class="a-btn"
                    >
                      <v-btn
                        v-show="!('show' in v) || v.show"
                        :title="v.tooltip"
                        :disabled="v.disabled"
                        icon
                        color="primary"
                      >
                        <span v-if="v.icon_text">{{ v.icon_text }}</span>
                        <v-icon v-if="!v.icon_text" dark>{{ v.icon }}</v-icon>
                      </v-btn>
                    </a>
                    <v-btn
                      :key="index"
                      :title="v.tooltip"
                      v-if="v.actionType == 'custom'"
                      v-show="!('show' in v) || v.show"
                      icon
                      :color="v.color || 'primary'"
                      slot="activator"
                      @click.stop.prevent="v.callback(item)"
                      :disabled="v.disabled"
                    >
                      <span v-if="v.loading"
                        ><v-progress-circular indeterminate
                      /></span>
                      <span v-if="!v.loading && v.icon_text">{{
                        v.icon_text
                      }}</span>
                      <v-icon v-if="!v.loading && !v.icon_text" dark>{{
                        v.icon
                      }}</v-icon>
                    </v-btn>
                    <FileDownloader
                      :key="index"
                      v-if="v.actionType == 'file-download'"
                      :fileRef="v.fileRef"
                      :fileName="v.fileName"
                    />
                  </template>
                  <v-btn
                    v-if="withEdit"
                    icon
                    color="primary"
                    @click.stop.prevent="onEdit(item)"
                  >
                    <v-icon dark>{{ $t("icons.edit") }}</v-icon>
                  </v-btn>

                  <v-btn
                    v-if="withDelete"
                    icon
                    color="primary"
                    @click.stop.prevent="onDelete(item)"
                  >
                    <v-icon dark>{{ $t("icons.delete") }}</v-icon>
                  </v-btn>
                </a>
              </td>
            </tr>
          </template>
          <v-alert v-if="totalLength == 0 && !loading" icon="warning">{{
            $t("baseGrid.noResults")
          }}</v-alert>
        </tbody>
      </template>
    </v-data-table>

  <div class="custom-header">
    <div class="paginator">
      {{ $t("baseGrid.rowsPerPage") }}:
      <div class="per-page-selector">
        <v-select
          v-model="paginationOpts.itemsPerPage"
          :items="[10, 25, 50, 100]"
          @change="handlePerPageChange"
        ></v-select>
      </div>
      {{
        paginationOpts.page * paginationOpts.itemsPerPage +
          1 -
          paginationOpts.itemsPerPage
      }}-{{
        Math.min(
          paginationOpts.page * paginationOpts.itemsPerPage,
          totalLength
        )
      }}
      {{ $t("baseGrid.of") }} {{ totalLength }}
      <v-btn icon large color="primary" @click="handleLeft">
        <v-icon dark>{{ $t("icons.left") }}</v-icon>
      </v-btn>
      <v-btn icon large color="primary" @click="handleRight">
        <v-icon dark>{{ $t("icons.right") }}</v-icon>
      </v-btn>
    </div>
  </div>
  </div>
</template>

<script>
import FileDownloader from "@/components/Shared/FileDownloader";
import _ from "lodash";

export default {
  props: {
    headers: {},
    items: {},
    totalLength: {},
    loading: {},
    pagination: {},
    withEdit: {},
    withDelete: {},
    showRowFooter: {},
    hideActions: {},
    serverItems: {
      default: true,
      type: Boolean
    },
    tableName: {
      default: "default",
      type: String
    }
  },
  data() {
    return {
      paginationOpts: {
        page: 1,
        itemsPerPage: 25,
        multiSort: false,
        mustSort: true
      },
      selected: {},
      headersComputed: []
    };
  },
  watch: {
    pagination(val) {
      this.paginationOpts = { ...this.paginationOpts, ...val };
    },
    headers() {
      this.computeHeaders();
    }
  },

  components: { FileDownloader },
  methods: {
    onEdit(item) {
      this.$emit("onEdit", item);
    },
    onDelete(item) {
      this.$emit("onDelete", item);
    },
    initComp() {
      this.paginationOpts = {
        ...this.paginationOpts,
        ...this.$props.pagination
      };
    },
    debounceEmit: _.debounce(function() {
      this.$emit("onPaginationChanged", this.paginationOpts);
    }, process.env.VUE_APP_DEBOUNCE_TIME),
    handlePagination() {
      this.debounceEmit();
    },
    handleLeft: function() {
      if (this.paginationOpts.page > 1) {
        this.paginationOpts.page--;
        this.debounceEmit();
      }
    },
    handleRight: function() {
      if (
        this.paginationOpts.page <
        this.totalLength / this.paginationOpts.itemsPerPage
      ) {
        this.paginationOpts.page++;
        this.debounceEmit();
      }
    },
    handlePerPageChange: function() {
      this.paginationOpts.page = 1;
      this.debounceEmit();
    },
    /*handleClick: function(item, v) {
      if (!v) return;
      if (!v.show) return;
      if (v.actionType == "router-link")
        this.goto({ name: v.namedRoot, params: { id: v.namedRootId } });
      else if (v.actionType == "custom") v.callback(item);
    },*/
    resolve(item, v) {
      if (!v) return null;
      if (!v.show) return null;
      if (v.actionType != "router-link") return null;
      let props = this.$router.resolve({
        name: v.namedRoot,
        params: { id: v.namedRootId }
      });
      return props.href;
    },
    handleRowClick(item, v) {
      if (!v) return null;
      if (!v.show) return null;
      if (v.actionType != "custom") return null;
      v.callback(item);
    },
    goto: function(dst) {
      this.$router.pushCatch(dst);
    },
    computeHeaders() {
      this.headersComputed = [];
      for (let i = 0; i < this.headers.length; i++) {
        var temp = _.cloneDeep(this.headers[i]);
        temp.class = this.tableName + "-" + i.toString();
        temp.text = this.$t("headers." + this.tableName + "." + temp.value);
        this.headersComputed.push(temp);
      }
    }
  },
  created() {
    this.initComp();
    this.computeHeaders();
  },
  mounted() {
    //this.$refs.dTable.$on("update:options", this.handlePagination);
    this.$refs.dTable.$on("update:sort-by", this.handlePagination);
    this.$refs.dTable.$on("update:sort-desc", this.handlePagination);
  }
};
</script>

<style>
th {
  white-space: nowrap;
}
.actions-td {
  white-space: nowrap;
}
.custom-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  height: 58px;
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
}
.paginator {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0px;
  font-size: 0.75rem !important;
}

.per-page-selector {
  display: inline-block;
  height: 58px;
  margin-bottom: 8px;
  width: 64px;
  margin-left: 8px;
  margin-right: 8px;
}
.per-page-selector .v-select__selection.v-select__selection--comma {
  overflow: inherit;
}
.per-page-selector .v-input {
  font-size: small;
}

.paginator,
.paginator p,
.paginator div,
.paginator span {
  font-size: inherit;
  font-style: italic;
}

.cursor-pointer {
  cursor: default !important;
}

td {
  padding: 0px !important;
}
.td-content {
  display: flex;
  align-items: center;

  width: 100%;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: inherit !important;
  text-decoration: inherit !important;
}
.actions-td .td-content {
  justify-content: center;
}

.a-btn {
  color: inherit !important;
  text-decoration: inherit !important;
}
</style>
