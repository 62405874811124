<template>
  <v-container fluid class="form-container title">
    <div
      v-if="mode == Enums.FORM_MODE.CREATE || mode == Enums.FORM_MODE.UPDATE"
    >
      <v-card-title class="form-title title">{{
        this.formTitle | capitalize
      }}</v-card-title>
      <v-form
        v-show="!editPassword"
        class="form-body"
        @submit.prevent="onSubmit('updateUser')"
        data-vv-scope="updateUser"
      >
        <div class="form-group required">
          <v-text-field
            :label="$t('forms.users.firstName')"
            v-model="formUser.firstName"
            name="firstName"
            v-validate="'required'"
          ></v-text-field>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('updateUser.firstName')"
            >{{ veeErrors.first("updateUser.firstName") }}</span
          >
        </div>
        <div class="form-group required">
          <v-text-field
            :label="$t('forms.users.lastName')"
            v-model="formUser.lastName"
            name="lastName"
            v-validate="'required'"
          ></v-text-field>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('updateUser.lastName')"
            >{{ veeErrors.first("updateUser.lastName") }}</span
          >
        </div>
        <div class="form-group required">
          <v-text-field
            :label="$t('forms.users.username')"
            v-model="formUser.username"
            name="username"
            v-validate="'required'"
          ></v-text-field>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('updateUser.username')"
            >{{ veeErrors.first("updateUser.username") }}</span
          >
        </div>
        <div class="form-group required">
          <v-text-field
            :label="$t('forms.users.email')"
            v-model="formUser.email"
            name="email"
            v-validate="'required'"
            :disabled="mode == Enums.FORM_MODE.UPDATE"
          ></v-text-field>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('updateUser.email')"
            >{{ veeErrors.first("updateUser.email") }}</span
          >
        </div>
        <div class="form-group required">
          <v-select
            v-model="formUser.role"
            :items="this.userRoles"
            item-text="name"
            item-value="id"
            return-object
            :label="$t('forms.users.role')"
            v-validate.immediate="'required'"
            name="role"
          ></v-select>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('updateUser.role')"
            >{{ veeErrors.first("updateUser.role") }}</span
          >
        </div>
        <div class="form-group required">
          <v-select
            v-model="formUser.tenant"
            :items="this.tenants"
            item-text="name"
            item-value="name"
            return-object
            :label="$t('forms.users.tenant')"
            v-validate.immediate="'required'"
            multiple
            name="tenant"
          ></v-select>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('updateUser.tenant')"
            >{{ veeErrors.first("updateUser.tenant") }}</span
          >
        </div>
        <div class="form-group">
          <CustomerAutocomplete
            :mode="mode"
            v-model="formUser.customer"
            itemText="name"
            itemValue="id"
            :returnObject="true"
            :label="$t('forms.users.customer')"
            :fetchFunction="ajaxCustomerFetchAll"
            :getter="customers"
            name="customer"
          />
        </div>
        <div class="form-group required">
          <v-text-field
            v-if="mode == Enums.FORM_MODE.CREATE"
            :label="$t('forms.users.password')"
            v-model="formUser.password"
            name="password"
            v-validate.immediate="'required'"
            type="password"
          ></v-text-field>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('updateUser.password')"
            >{{ veeErrors.first("updateUser.password") }}</span
          >
        </div>
        <div class="form-group required">
          <v-text-field
            v-if="mode == Enums.FORM_MODE.CREATE"
            :label="$t('forms.users.confirmPassword')"
            v-model="formUser.confirmPassword"
            name="confirmPassword"
            v-validate.immediate="'required'"
            type="password"
          ></v-text-field>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('updateUser.confirmPassword')"
            >{{ veeErrors.first("updateUser.confirmPassword") }}</span
          >
        </div>
        <v-btn
          small
          color="primary"
          @click="editPsw"
          v-if="mode == Enums.FORM_MODE.UPDATE"
        >
          {{ $t("forms.users.editPassword") }}
        </v-btn>
        <FormButtons
          @onSave="onSubmit('updateUser')"
          @onCancel="onCancel"
          :disabled="veeErrors.any('updateUser')"
        />
      </v-form>
      <v-form
        v-show="editPassword"
        class="form-body"
        @submit.prevent="onSubmit('updatePassword')"
        data-vv-scope="updatePassword"
      >
        <div :class="profile ? 'form-group required':'form-group'" v-show="profile">
          <v-text-field
            :label="$t('forms.users.oldPassword')"
            v-model="formPassword.oldPassword"
            name="oldPassword"
            type="password"
          ></v-text-field>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('updatePassword.oldPassword')"
            >{{ veeErrors.first("updatePassword.oldPassword") }}</span
          >
        </div>
        <div class="form-group required">
          <v-text-field
            :label="$t('forms.users.password')"
            v-model="formPassword.password"
            name="password"
            v-validate.immediate="'required'"
            type="password"
          ></v-text-field>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('updatePassword.password')"
            >{{ veeErrors.first("updatePassword.password") }}</span
          >
        </div>
        <div class="form-group required">
          <v-text-field
            :label="$t('forms.users.confirmPassword')"
            v-model="formPassword.confirmPassword"
            name="confirmPassword"
            v-validate.immediate="'required'"
            type="password"
          ></v-text-field>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('updatePassword.confirmPassword')"
            >{{ veeErrors.first("updatePassword.confirmPassword") }}</span
          >
        </div>
        <FormButtons
          @onSave="onSubmit('updatePassword')"
          @onCancel="onCancel2"
          :disabled="veeErrors.any('updatePassword')"
        />
      </v-form>
    </div>
    <!-- form in show mode -->
    <div v-show="mode === Enums.FORM_MODE.SHOW" class="form-body-show">
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.users.name')"
        :value="this.formUser.firstName + ' ' + this.formUser.lastName"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.LINK"
        :label="$t('forms.users.email')"
        :value="this.formUser.email"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.users.username')"
        :value="this.formUser.username"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.users.role')"
        :value="this.formUser.role.name"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.users.tenant')"
        :value="getTenantsString"
      />
      <DisplayKV
        v-if="this.formUser.role.name=='customer'"
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.users.customer')"
        :value="this.formUser.customer.name"
      />
    </div>
  </v-container>
</template>

<script>
import SharedMixin from "@/mixins/shared";
import Enums from "@/mixins/enums";
import DisplayKV from "@/components/Shared/DisplayKeyValue";
import FormButtons from "@/components/Shared/FormButtons";
import CustomerAutocomplete from "@/components/Customer/CustomerAutocomplete";

import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "UserForm",
  props: {
    mode: {
      default: Enums.FORM_MODE.CREATE,
      type: String
    },
    selectedItem: Object,
    profile: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      resourceType: this.$t("resource_types.user"),
      formUser: {
        id: null,
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        role: "",
        tenant: null,
        customer: null,
        password: "",
        confirmPassword: ""
      },
      formPassword: {
        oldPassword: "",
        password: "",
        confirmPassword: ""
      },
      editPassword: false
    };
  },
  components: { DisplayKV, FormButtons, CustomerAutocomplete },
  mixins: [SharedMixin, Enums],
  watch: {
    selectedItem() {
      this.initComp();
      this.initForm();
    }
  },
  methods: {
    ...mapActions("user", ["ajaxUserCreateOrUpdate"]),
    ...mapActions("tenant", ["ajaxTenantFetchAll"]),
    ...mapActions("userRole", ["ajaxUserRoleFetchAll"]),
    ...mapActions("customer", ["ajaxCustomerFetchAll"]),
    setFormUser(row) {
      if (row && !_.isEmpty(row)) {
        this.formUser.id = row.id;
        this.formUser.firstName = row.first_name;
        this.formUser.lastName = row.last_name;
        this.formUser.username = row.username;
        this.formUser.email = row.email;
        this.formUser.role = row.role;
        this.formUser.tenant = row.tenants;
        this.formUser.customer = row.customer;
        this.formUser.password = "";
        this.formUser.confirmPassword = "";
      } else {
        this.formUser.id = null;
        this.formUser.firstName = "";
        this.formUser.lastName = "";
        this.formUser.username = "";
        this.formUser.email = "";
        this.formUser.role = "";
        this.formUser.tenant = null;
        this.formUser.customer = null;
        this.formUser.password = "";
        this.formUser.confirmPassword = "";
      }
    },
    setFormPassword() {
      this.formPassword.oldPassword = "";
      this.formPassword.password = "";
      this.formPassword.confirmPassword = "";
      this.formPassword.role = "";
      this.formPassword.tenant = null;
    },
    async onSubmit(scope) {
      if (scope == "updateUser") {
        const validate = await this.$validator.validateAll(scope);
        if (validate) {
          if (
            this.formPassword.password === this.formPassword.confirmPassword
          ) {
            await this.submitProfileToStore();
          } else {
            alert(this.$t("forms.users.errorPassword"));
          }
        } else {
          alert(this.$t("forms.common.errorValidateFields"));
        }
      }
      if (scope == "updatePassword") {
        const validate = await this.$validator.validateAll(scope);
        if (validate) {
          if (
            this.formPassword.password === this.formPassword.confirmPassword
          ) {
            await this.submitPswToStore();
          } else {
            alert(this.$t("forms.users.errorPassword"));
          }
        } else {
          alert(this.$t("forms.common.errorValidateFields"));
        }
      }
    },
    async submitProfileToStore() {
      let payload = {
        id: this.formUser.id,
        first_name: this.formUser.firstName,
        last_name: this.formUser.lastName,
        username: this.formUser.username,
        email: this.formUser.email,
        role: this.formUser.role,
        tenant: this.formUser.tenant,
        customer: this.formUser.customer,
        password: this.formUser.password
      };
      await this.createOrUpdateHelper(
        this.mode,
        this.resourceType,
        this.ajaxUserCreateOrUpdate,
        payload,
        payload => payload.p.last_name + " " + payload.p.first_name,
        payload => payload.p.last_name + " " + payload.p.first_name
      );
      this.$emit("formSucceed");
    },
    async submitPswToStore() {
      let payload = {
        id: this.formUser.id,
        password: this.formPassword.password,
        confirm_password: this.formPassword.confirmPassword,
      };
      if (this.$props.profile) {
        payload.old_password = this.formPassword.oldPassword
      }
      console.log('payload: ', payload)
      await this.createOrUpdateHelper(
        Enums.FORM_MODE.UPDATE,
        this.$t("resource_types.user_psw"),
        this.ajaxUserCreateOrUpdate,
        payload,
        payload => payload.p.last_name + " " + payload.p.first_name,
        payload => payload.p.last_name + " " + payload.p.first_name
      );
      this.onCancel2();
      this.$emit("formSucceed");
    },
    async onCancel() {
      this.$emit("formCancel");
    },
    async onCancel2() {
      this.setFormPassword();
      this.editPassword = false;
      if(this.$props.profile) {
        this.onCancel()
      }
    },
    initForm() {
      if (this.mode == Enums.FORM_MODE.CREATE) {
        this.setFormUser();
        this.setFormPassword();
      } else {
        this.setFormUser(this.$props.selectedItem);
        this.setFormPassword();
        if (this.$props.profile) {
          this.editPassword = true;
        }
      }
    },
    async initComp() {
      await this.ajaxTenantFetchAll();
      await this.ajaxUserRoleFetchAll();
    },
    editPsw() {
      this.setFormPassword();
      this.editPassword = true;
    }
  },
  computed: {
    ...mapGetters("userRole", ["userRoles"]),
    ...mapGetters("tenant", ["tenants"]),
    ...mapGetters("customer", ["customers"]),
    Enums() {
      return Enums;
    },
    getTenantsString() {
      if (!this.formUser.tenant) return "";
      let os = "";
      this.formUser.tenant.forEach(val => {
        os += val.name + ", ";
      });
      return os;
    },
    formTitle() {
      var title = "";
      switch (this.mode) {
        case Enums.FORM_MODE.CREATE:
          title = this.$t("labels.createResourceType", {
            resourceType: this.resourceType || "-"
          });
          break;
        case Enums.FORM_MODE.UPDATE:
          title = this.$t("labels.updateResourceTypeName", {
            resourceType: this.resourceType || "-",
            resourceName:
              this.formUser.lastName + " " + this.formUser.firstName || "-"
          });
          break;

        default:
          break;
      }
      return title;
    }
  },
  created() {
    this.initForm();
    this.initComp();
  }
};
</script>
