var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLogged
    ? _c(
        "div",
        [
          _c(
            "v-btn",
            {
              attrs: { id: "button", text: "", small: "" },
              on: { click: _vm.doLogout }
            },
            [_vm._v(_vm._s(_vm.$t("labels.logout")))]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }