<template>
  <v-container>
    <v-card>
      <ToolbarTab
        v-if="!singleRow"
        :title="$t('labels.agreements')"
        :subtitle="$tc('labels.found_elements', this.total)"
        :withSearch="true"
        @onSearch="debounceSearch"
      ></ToolbarTab>

      <ColorGrid
        :headers="this.tableData.headers"
        :items="this.tableData.items"
        :elementsList="this.elementsList"
        :totalLength="this.total"
        :pagination="this.tableOptions"
        :loading="this.onLoading"
        :tableOptions="this.tableOptions"
        :total="this.total"
        @onPageChange="handlePageChange"
        :singleRow="singleRow"
        tableName="invoice-periods-grid"
      >
        <!--
        <template v-slot:cell-content="slotProps">
          <v-btn
            v-if="slotProps.origin.state == 'NOT_SENT'"
            :title="$t('labels.gift')"
            icon
            color="primary"
            x-small
            @click="onChange(slotProps.origin, disabledOption.OMAGGIO)"
            :disabled="!slotProps.origin.enabled"
          >
            <v-icon dark>{{ $t("icons.gift") }}</v-icon>
          </v-btn>
          <v-btn
            v-if="slotProps.origin.state == 'NOT_SENT'"
            :title="$t('labels.redistribute')"
            icon
            color="primary"
            x-small
            @click="onChange(slotProps.origin, disabledOption.RIDISTRIBUITO)"
            :disabled="!slotProps.origin.enabled"
          >
            <v-icon dark>{{ $t("icons.tune") }}</v-icon>
          </v-btn>
        </template>
        -->
      </ColorGrid>
    </v-card>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import _ from "lodash";
import ToolbarTab from "@/components/Shared/ToolbarTab";
import { HelperService } from "@/services/helper.service";
import ColorGrid from "@/components/Shared/ColorGrid";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";

const computed = {
  ...mapGetters("agreement", [
    "agreements",
    "selectedAgreement",
    "total",
    "onLoading",
  ]),
  _() {
    return _;
  },
  Enums() {
    return Enums;
  },
  agreementsList() {
    return this.agreements;
  },
};

export default {
  components: {
    ToolbarTab,
    ColorGrid,
  },
  props: {
    listMode: {
      default: Enums.LIST_MODE.TABBED,
      type: String,
    },
    singleRow: {
      default: false,
      type: Boolean,
    },
    onReloadPage: {},
    selectedItem: {},
  },
  watch: {
    selectedItem() {
      this.fetchData();
    },
  },
  data() {
    return {
      resourceType: this.$t("resource_types.invoice_period"),
      disabledOption: Enums.INVOICE_PERIOD_DISABLED_OPTION,
      currentPage: 1,
      search: "",
      // selectedItem: {},
      tableOptions: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["code"],
        sortDesc: [false],
      },
      tableData: { headers: [], items: [] },
      elementsList: [],
      months: [
        "gennaio",
        "febbraio",
        "marzo",
        "aprile",
        "maggio",
        "giugno",
        "luglio",
        "agosto",
        "settembre",
        "ottobre",
        "novembre",
        "dicembre",
      ],
    };
  },
  mixins: [Enums, SharedMixin],
  methods: {
    ...mapActions("agreement", [
      "ajaxAgreementFetchAll",
      "ajaxAgreementCreateOrUpdate",
      "ajaxAgreementSetSelected",
    ]),
    ...mapActions("invoicePeriod", ["ajaxInvoicePeriodUpdate"]),
    ...mapMutations("agreement", ["setList"]),

    debounceSearch: _.debounce(async function (val) {
      this.tableOptions.page = 1;
      this.search = val;
      await this.fetchData();
    }, process.env.VUE_APP_DEBOUNCE_TIME),
    prepareMetadata(item) {
      let baseMetadata = {
        disabled_reason: "",
      };

      let currentMetadata = null;
      if (typeof item.invoice_data == "object") {
        currentMetadata = item.metadata;
      } else {
        const meta = JSON.parse(item.metadata);
        currentMetadata = meta;
      }
      return _.merge(baseMetadata, currentMetadata);
    },
    async handlePageChange(tableOptions) {
      this.tableOptions = tableOptions;
      await this.fetchData();
    },
    updateElementsList() {
      var elementsList = [];
      this.agreements.forEach(
        function (val) {
          var row = {
            elements: [],
          };
          if (val.invoice_periods.length > 0) {
            var end = false;
            var current_period = val.invoice_periods[0].period;
            var ind = 1;
            var pcount = 1;
            var sum = val.invoice_periods[0].price;
            while (!end) {
              var new_period;
              if (ind < val.invoice_periods.length)
                new_period = val.invoice_periods[ind].period;
              else {
                new_period = -1;
                end = true;
              }
              if (new_period != current_period) {
                var val2 = val.invoice_periods[ind - 1];
                var inv2 = val.invoices.find((x) => x.period === val2.period);
                var element = {
                  label: Math.round(sum * 100) / 100 + " €",
                  color: 0,
                  date: null,
                  content: "",
                  span: pcount,
                  origin: val2,
                };

                let meta = this.prepareMetadata(val2);

                element.content +=
                  this.$t("forms.invoicing.period") + ": " + val2.period;
                element.content +=
                  "<br>" +
                  this.$t("forms.invoicing.price") +
                  ": " +
                  Math.round(sum * 100) / 100 +
                  " €";
                element.content +=
                  "<br>" +
                  this.$t("forms.invoicing.notification") +
                  ": " +
                  this.getEnumTranslationFor("invoice_periods", val2.state);
                element.content +=
                  "<br>" +
                  this.$t("forms.invoicing.enabled") +
                  ": " +
                  (val2.enabled ? this.$t("labels.yes") : this.$t("labels.no"));
                if (meta.disabled_reason)
                  element.content +=
                    "<br>" +
                    this.$t("forms.invoicing.option") +
                    ": " +
                    this.getEnumTranslationFor(
                      "invoice_periods",
                      meta.disabled_reason
                    );

                if (val2.state == "NOT_SENT") element.color = 1;
                else if (inv2 != null && inv2.state == "EMITTED")
                  element.color = 3;
                else element.color = 2;

                var year = val2.name.substring(0, 4);
                var month = this.months.indexOf(val2.name.substring(5)) + 1;
                element.date = new Date(
                  year,
                  month - pcount + 1,
                  1,
                  0,
                  0,
                  0,
                  0
                );

                row.elements.push(element);

                current_period = new_period;
                if (val.invoice_periods[ind])
                  sum = val.invoice_periods[ind].price;
                pcount = 1;
              } else {
                pcount++;
                sum += val.invoice_periods[ind].price;
              }
              ind++;
            }
          }
          elementsList.push(row);
        }.bind(this)
      );
      this.elementsList = elementsList;
    },
    async fetchData() {
      if (this.selectedItem) {
        this.setList([this.selectedItem]);
      } else {
        let filter = { id: this.id };
        if (!filter.id) filter = {};
        let payloadFAP = HelperService.buildFilterAndPagination({
          search: this.search,
          pagination: this.tableOptions,
          filter_criteria: this.filter_criteria,
          filter,
          optimize: false,
        });
        await this.ajaxAgreementFetchAll(payloadFAP);
      }
      this.initTable();
      this.updateElementsList();
    },
    initTable() {
      this.tableData.headers = this.mapHeaders();
      this.tableData.items = this.mapItems();
    },
    mapHeaders() {
      const tableHeaders = [];
      tableHeaders.push({
        text: "Agreement",
        value: "code",
        align: "start",
        sortable: true,
      });
      tableHeaders.push({
        text: "Customer",
        value: "tenant_id",
        align: "start",
        sortable: true,
      });
      return tableHeaders;
    },
    mapItems() {
      const map = _.map(this.agreementsList, (item) => {
        if (this.singleRow) {
          item.fields = {
            services: {
              data: item.code + " (" + item.service + ")",
              dataType: "text",
              css: "text-xs-left",
            },
            tenant_id: {
              data: item.customer.name + " (" + item.tenant.machine_name + ")",
              dataType: "text",
              css: "text-xs-left",
            },
          };
        } else {
          item.fields = {
            services: {
              data: item.code + " (" + item.service + ")",
              dataType: "link",
              css: "text-xs-left",
              linkCallback: this.handleLink,
            },
            tenant_id: {
              data: item.customer.name + " (" + item.tenant.machine_name + ")",
              dataType: "text",
              css: "text-xs-left",
            },
          };
        }
        item.actions = {};
        return item;
      });
      return map;
    },
    handleLink(item) {
      this.$router.pushCatch({
        name: "AgreementDetailsInv",
        params: {
          id: Number(item.id),
        },
      });
    },
  },

  async created() {
    await this.fetchData();
  },
  computed,
};
</script>
