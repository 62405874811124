var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "grey lighten-4 title", attrs: { fluid: "" } },
    [
      _vm.mode == _vm.Enums.FORM_MODE.CREATE ||
      _vm.mode == _vm.Enums.FORM_MODE.UPDATE
        ? _c(
            "div",
            [
              _c("v-card-title", { staticClass: "form-title title" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("capitalize")(_vm.formTitle)) +
                    "\n    "
                )
              ]),
              _c(
                "v-form",
                {
                  staticClass: "form-body",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.onSubmit($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.immediate",
                            value: "required",
                            expression: "'required'",
                            modifiers: { immediate: true }
                          }
                        ],
                        attrs: {
                          label: _vm.$t("forms.links.url"),
                          name: "url"
                        },
                        model: {
                          value: _vm.form.url,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "url", $$v)
                          },
                          expression: "form.url"
                        }
                      }),
                      _vm.veeErrors.has("url")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [_vm._v(_vm._s(_vm.veeErrors.first("url")))]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("forms.links.description"),
                          name: "description"
                        },
                        model: {
                          value: _vm.form.description,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "description", $$v)
                          },
                          expression: "form.description"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.mode == _vm.Enums.FORM_MODE.CREATE
                    ? _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("v-select", {
                            attrs: {
                              items: this.tags,
                              "item-text": "name",
                              "item-value": "id",
                              label: _vm.$t("misc.select_tag"),
                              "single-line": "",
                              clearable: "",
                              name: "tag",
                              disabled: _vm.disabledTag
                            },
                            model: {
                              value: _vm.form.tag_ids,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "tag_ids", $$v)
                              },
                              expression: "form.tag_ids"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("FormButtons", {
                    attrs: { disabled: _vm.veeErrors.any() },
                    on: { onSave: _vm.onSubmit, onCancel: _vm.onCancel }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.mode === _vm.Enums.FORM_MODE.SHOW,
              expression: "mode === Enums.FORM_MODE.SHOW"
            }
          ]
        },
        [
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.links.id"),
              value: this.form.id
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.links.description"),
              value: _vm.form.description
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.links.url"),
              value: this.form.url
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }