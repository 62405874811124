<template>
  <v-container>
    <v-card v-if="this.listMode === Enums.LIST_MODE.FULL">
      <ToolbarList
        :title="$t('labels.attachments')"
        :subtitle="$tc('labels.found_elements', this.total)"
        :withAdd="$can('addButton', 'customers')"
        @onAdd="openFormDialog(Enums.FORM_MODE.CREATE)"
        :tagName="tagName"
      ></ToolbarList>

      <BaseGrid
        tableName="attachments"
        :headers="this.tableData.headers"
        :items="this.tableData.items"
        :totalLength="this.total"
        :pagination="this.tableOptions"
        :loading="this.onLoading"
        @onPaginationChanged="handlePaginationChanged"
        :withEdit="!this.readOnly"
        :withDelete="!this.readOnly"
        @onEdit="handleEdit"
        @onDelete="handleDelete"
      ></BaseGrid>
      
    </v-card>

    <v-card
      v-if="
        this.listMode == Enums.LIST_MODE.TABBED ||
          this.listMode == Enums.LIST_MODE.READONLY
      "
    >
      <ToolbarTab
        :title="$t('labels.attachments')"
        :subtitle="$tc('labels.found_elements', this.total)"
        :withAdd="!this.readOnly"
        @onAdd="
          openFormDialog(Enums.FORM_MODE.CREATE);
          attachmentFormKey += 1;
        "
      ></ToolbarTab>

      <BaseGrid
        tableName="attachments"
        :headers="this.tableData.headers"
        :items="this.tableData.items"
        :totalLength="this.total"
        :pagination="this.tableOptions"
        :loading="this.onLoading"
        @onPaginationChanged="handlePaginationChanged"
        :withEdit="!this.readOnly && withModelType != Enums.MODEL_NAME.CUSTOMERS"
        :withDelete="!this.readOnly"
        @onEdit="handleEdit"
        @onDelete="handleDelete"
      ></BaseGrid>
    </v-card>

    <v-dialog v-model="formDialog" content-class="edit-form-dialog" v-if="this.folderId">
      <ContentForm
        v-if="formDialog"
        :mode="formMode"
        :selectedItem="formItem"
        :withModelType="this.withModelType"
        :withModelId="this.withModelId"
        :permittedTypes="this.permittedTypes"
        @formSucceed="handleFormSucceed"
        @formCancel="handleFormCancel"
        :key="this.attachmentFormKey"
        :tagName="tagName"
        :folderId="this.folderId"
      ></ContentForm>
    </v-dialog>

    <v-dialog v-model="formDialog" content-class="edit-form-dialog" v-else>
      <AttachmentForm
        v-if="formDialog"
        :mode="formMode"
        :selectedItem="formItem"
        :withModelType="this.withModelType"
        :withModelId="this.withModelId"
        :permittedTypes="this.permittedTypes"
        @formSucceed="handleFormSucceed"
        @formCancel="handleFormCancel"
        :key="this.attachmentFormKey"
        :machineName="machineName"
      ></AttachmentForm>
    </v-dialog>

    <v-dialog v-model="secondFormDialog" content-class="edit-form-dialog">
      <ProceedingForm
        v-if="secondFormDialog"
        :mode="secondFormMode"
        :selectedItem="formItem"
        :withModelType="this.withModelType"
        :withModelId="this.withModelId"
        :folderId="this.folderId"
        :procedureId="this.folderId"
        :tagName="Enums.TAG_NAME.DETERMINE_DECISIONI_CONTRARRE"
        :proceedingType="Enums.PROCEEDING_TYPE.DETERMINA"
        @formSucceed="handleSecondFormSucceed"
        @formCancel="handleSecondFormCancel"
      ></ProceedingForm>
    </v-dialog>

  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import AttachmentForm from "@/components/Attachment/AttachmentForm";
import ContentForm from "@/components/Attachment/ContentForm";
import ProceedingForm from "@/components/Proceeding/ProceedingForm";
import ToolbarTab from "@/components/Shared/ToolbarTab";
import ToolbarList from "@/components/Shared/ToolbarList";
import { HelperService } from "@/services/helper.service";
import BaseGrid from "@/components/Shared/BaseGrid";
import FormsHelper from "@/mixins/formsHelper";
import DatatablesHelper from "@/mixins/datatablesHelper";
import Enums from "@/mixins/enums";

const computed = {
  ...mapGetters("attachment", [
    "attachments",
    "selectedAttachment",
    "total",
    "onLoading"
  ]),
  ...mapGetters("attachmentType", [
    "attachmentTypes",
    "selectedAttachmentType",
    "mappedAttachmentTypes",
    "getTypesByMachineNames",
    "getTypesByIds"
  ]),
  ...mapGetters("folder", [
    "folders"
  ]),
  _() {
    return _;
  },
  Enums() {
    return Enums;
  },
  attachmentsList() {
    return this.attachments;
  }
};

export default {
  components: {
    AttachmentForm,
    ContentForm,
    ProceedingForm,
    ToolbarTab,
    ToolbarList,
    BaseGrid
  },
  props: {
    withModelType: {
      required: true,
      type: String
    },
    withModelId: {
      required: true,
      type: String
    },
    listMode: {
      default: Enums.LIST_MODE.TABBED,
      type: String
    },
    readOnly: {
      default: false,
      type: Boolean
    },
    permittedTypes: {
      default: null,
      type: String
    },
    machineName: {},
    tagName: {},
    folderId: {}
  },
  data() {
    return {
      search: "",
      attachmentFormKey: 0
    };
  },
  mixins: [FormsHelper, Enums, DatatablesHelper],
  watch: {
    async folderId() {
      this.fetchData();
    }
  },
  /*watch: {
    async withModelType() {
      this.fetchData();
    },
    async withModelId() {
      this.fetchData();
    }
  },*/
  methods: {
    ...mapActions("attachment", [
      "ajaxAttachmentFetchAll",
      "ajaxAttachmentCreateOrUpdate",
      "ajaxAttachmentDelete"
    ]),
    ...mapActions("attachmentType", ["ajaxAttachmentTypeFetchAll"]),
    ...mapActions("folder", ["ajaxFolderFetchAll"]),

    async redirectToProceeding(tag_name) {
      let _filter = {};
      let _procedure_filter_criteria = { tag_name: tag_name };
      if (this.$props.withModelType) {
        _filter = { ..._filter, ...{ model_type: this.$props.withModelType } };
      }
      if (this.$props.withModelId) {
        _filter = { ..._filter, ...{ model_id: this.$props.withModelId } };
      }

      let payloadFAP = HelperService.buildFilterAndPagination({
        filter: _filter,
        filter_criteria: _procedure_filter_criteria
      });
      
      await this.ajaxFolderFetchAll(payloadFAP);

      if (this.folders && this.folders.length == 1) {
        const folder_id = this.folders[0].id;
      
        this.$router.pushCatch({ name: "folderDetails", params: { withModelId: this.$props.withModelId, id: folder_id } });
      }
    },
    async fetchData(needle = null) {
      let _filter = {};
      const _search = needle ? needle : this.search || "";

      let machineName=this.machineName;
      let parentId;
      if (machineName === Enums.FILE_TYPE.TEMPLATE)
        parentId = 15;
      else if (machineName === Enums.FILE_TYPE.ORDER || machineName === Enums.FILE_TYPE.QUESTIONARIO)
        parentId = 17;
      else
        parentId = 31;
      //_filter.file_type_id=parentId;

      if (this.$props.withModelType) {
        _filter = { ..._filter, ...{ model_type: this.$props.withModelType } };
      }
      if (this.$props.withModelId) {
        _filter = { ..._filter, ...{ model_id: this.$props.withModelId } };
      }
      if (this.$props.folderId) {
        _filter = { ..._filter, ...{ folder_id: this.$props.folderId } };
      }

      let payloadFAP = HelperService.buildFilterAndPagination({
        search: _search,
        filter: _filter,
        pagination: this.tableOptions,
      });
      await this.ajaxAttachmentFetchAll(payloadFAP);
      //await this.ajaxAttachmentTypeFetchAll({machineName:this.machineName});
      this.initTable();
    },
    initTable() {
      this.tableData.headers = this.mapHeaders();
      this.tableData.items = this.mapItems();
    },
    mapHeaders() {
      const tableHeaders = [];
      if (this.$props.folderId) {
        tableHeaders.push({
          text: "Title",
          value: "title",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Detail",
          value: "detail",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Name",
          value: "name",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center"
        });
      }
      else{
        tableHeaders.push({
          text: "Type",
          value: "type",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Name",
          value: "name",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Detail",
          value: "detail",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Tag",
          value: "tags",
          align: "start",
          sortable: false
        });
        tableHeaders.push({
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center"
        });
      }
      return tableHeaders;
    },
    mapItems() {
      let map = {};
      if (this.$props.folderId) {
        map = _.map(this.attachmentsList, item => {
          item.fields = {
            title: { data: item.title, dataType: "text", css: "text-xs-left" },
            detail: { data: item.detail, dataType: "text", css: "text-xs-left" },
            name: { data: item.name, dataType: "text", css: "text-xs-left" }
          };
          item.actions = {
            download: {
              actionType: "file-download",
              fileRef: item.id,
              fileName: item.name
            }
          };
          return item;
        });
      } else {
        map = _.map(this.attachmentsList, item => {
          const attachmentType = item.file_type.name;
          item.fields = {
            type: { data: attachmentType, dataType: "text", css: "text-xs-left" },
            name: { data: item.name, dataType: "text", css: "text-xs-left" },
            detail: { data: item.detail, dataType: "text", css: "text-xs-left" },
            tags: { data: item.detail, dataType: "text", css: "text-xs-left" }
          };
          item.actions = {
            download: {
              actionType: "file-download",
              fileRef: item.id,
              fileName: item.name
            }
          };
          return item;
        });
      }
      return map;
    },
    handlePaginationChanged(paginationOpts) {
      this.tableOptions = paginationOpts;
      this.fetchData();
    },
    handleFormSucceed() {
      this.closeFormDialog();
      this.fetchData();
    },
    handleFormCancel() {
      this.closeFormDialog();
    },
    handleSecondFormSucceed() {
      this.closeSecondFormDialog();
      this.fetchData();
    },
    handleSecondFormCancel() {
      this.closeSecondFormDialog();
    },

    debounceSearch: _.debounce(async function(val) {
      this.search = val;
      await this.fetchData();
    }, process.env.VUE_APP_DEBOUNCE_TIME),

    async handleEdit(item) {
      this.openFormDialog(Enums.FORM_MODE.UPDATE, item);
    },
    async handleDelete(item) {
      this.$confirm({
        message:
          this.$t("dialogs.deleteConfirmMessage") + "\n\nFile | " + item.name,
        button: {
          no: this.$t("dialogs.no"),
          yes: this.$t("dialogs.yes")
        },
        callback: async confirm => {
          if (confirm) {
            await this.ajaxAttachmentDelete(item);
            this.fetchData();
          }
        }
      });
    }
  },

  async created() {
    this.formDialog = false;
    this.secondFormDialog = false;
    await this.fetchData();
  },
  computed
};
</script>
