var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c("ToolbarList", {
            attrs: {
              title: _vm.$t("labels.tenants"),
              subtitle: _vm.$tc("labels.found_elements", this.total),
              withAdd: _vm.$can("addButton", "tenants")
            },
            on: {
              onAdd: function($event) {
                return _vm.openFormDialog(_vm.Enums.FORM_MODE.CREATE)
              }
            }
          }),
          _c("BaseGrid", {
            attrs: {
              tableName: "tenants",
              headers: this.tableData.headers,
              items: this.tableData.items,
              totalLength: this.total,
              pagination: this.tableOptions,
              loading: this.onLoading,
              hideActions: true
            },
            on: { onPaginationChanged: _vm.handlePaginationChanged }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "content-class": "edit-form-dialog" },
          model: {
            value: _vm.formDialog,
            callback: function($$v) {
              _vm.formDialog = $$v
            },
            expression: "formDialog"
          }
        },
        [
          _vm.formDialog
            ? _c("TenantForm", {
                attrs: { mode: _vm.Enums.FORM_MODE.CREATE },
                on: {
                  formSucceed: _vm.handleFormSucceed,
                  formCancel: _vm.handleFormCancel
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }