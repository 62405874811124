/*  eslint no-shadow: ["error", { "allow": ["state"] }] */
/*  eslint no-param-reassign: ["error", { "props": false }] */
import Vue from "vue";
import Vuex from "vuex";
import { CustomerTypeService } from "@/services/customerType.service";
import { HelperService } from "@/services/helper.service";

Vue.use(Vuex);

const state = {
  customerTypes: [],
  loading: false,
  totalCount: 0
};

const getters = {
  customerTypes: state => state.customerTypes,
  onLoading: state => state.loading,
  total: state => state.totalCount
};

const mutations = {
  // setSelected(state, sel) {
  //   state.selected = sel;
  // },
  setList(state, sel) {
    state.customerTypes = sel;
  },
  setTotal(state, sel) {
    state.totalCount = Number(sel);
  },
  setLoading(state, sel) {
    state.loading = sel;
  }
};

const actions = {
  async ajaxCustomerTypeFetchAll({ commit }, payload) {
    let searchFilterPagination = HelperService.localToJSONAPIPagination(
      payload
    );

    await HelperService.genericFetchAll(
      commit,
      CustomerTypeService.fetchAll,
      searchFilterPagination,
      null,
      null
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
