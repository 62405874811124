import Vue from "vue";

const FolderService = {
  async fetchOne(id) {
    if (id == null) {
      return null;
    }
    try {
      const data = await Vue.$jsonApi.find("folder", id, {
        include: "permalink,tagging,tagging.tag" //"customer,services,tenant,agreementServices,invoice_periods,invoices"
      });
      return data.data[0];
    } catch (error) {
      console.log("fetchOne error: ", error);
      return { error: error };
    }
  },
  async fetchAll(searchFilterPagination) {
    try {
      searchFilterPagination.include="permalink,tagging,tagging.tag"
      const res = await Vue.$jsonApi.findAll(
        "folders",
        searchFilterPagination
      );
      return res;
    } catch (error) {
      console.log("fetchAll error: ", error);
      return { error: error };
    }
  },
  async createOrUpdate(payload) {
    try {
      if (payload.id == "" || payload.id == null) {
        return await Vue.$jsonApi.create("folder", payload);
      } else {
        return await Vue.$jsonApi.update("folder", payload);
      }
    } catch (error) {
      console.log("createOrUpdate error: ", error);
      return { error: error };
    }
  },
  async delete(id) {
    if (id == null) {
      return null;
    }
    try {
      const data = await Vue.$jsonApi.destroy("folder", id);
      return data;
    } catch (error) {
      console.log("delete error: ", error);
      return { error: error };
    }
  }
};

export { FolderService };
