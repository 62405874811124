<template>
  <div>
    <EmailFilter
      @filtersSelected="handleFiltersSelected"
      ref="drawer"
      @drawerChanged="handleDrawer"
    ></EmailFilter>
    <div :class="{ 'center-panel': drawer_flag }">
      <v-card v-if="this.listMode === Enums.LIST_MODE.FULL">
        <ToolbarList
          :title="$t('labels.email_message')"
          :subtitle="$tc('labels.found_elements', this.total)"
          :withAdd="false"
          :withSearch="true"
          :withFilter="true"
          @onSearch="searchFunc"
          @onFilter="$refs.drawer.open()"
          :allClear="allClear"
        ></ToolbarList>
        <BaseGrid
          tableName="email-messages"
          :headers="this.tableData.headers"
          :items="this.tableData.items"
          :totalLength="this.total"
          :pagination="this.tableOptions"
          :loading="this.onLoading"
          :hideActions="true"
          @onPaginationChanged="handlePaginationChanged"
        ></BaseGrid>
      </v-card>
      <v-container v-if="this.listMode === Enums.LIST_MODE.TABBED">
        <v-card>
          <ToolbarTab
            :subtitle="$tc('labels.found_elements', this.total)"
            :withAdd="false"
          ></ToolbarTab>
          <BaseGrid
            tableName="email-messages"
            :headers="this.tableData.headers"
            :items="this.tableData.items"
            :totalLength="this.total"
            :pagination="this.tableOptions"
            :loading="this.onLoading"
            :hideActions="true"
            @onPaginationChanged="handlePaginationChanged"
          ></BaseGrid>
        </v-card>
      </v-container>
      <v-dialog v-model="formDialog" persistent content-class="edit-form-dialog">
        <EmailModal
          v-if="formDialog"
          :selectedItem="formItem"
          @formCancel="handleFormCancel"
        ></EmailModal>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import ToolbarList from "@/components/Shared/ToolbarList";
import ToolbarTab from "@/components/Shared/ToolbarTab";
import BaseGrid from "@/components/Shared/BaseGrid";
import FormsHelper from "@/mixins/formsHelper";
import { HelperService } from "@/services/helper.service";
import Enums from "@/mixins/enums";
import EmailFilter from "@/components/Filter/EmailFilter";
import EmailModal from "@/components/Email/EmailModal";

const computed = {
  ...mapGetters("emailMessage", ["emailMessages", "total", "onLoading"]),
  _() {
    return _;
  },
  Enums() {
    return Enums;
  },
  emailMessageList() {
    return this.emailMessages;
  },
  showPost() {}
};

export default {
  name: "emailMessageList",
  components: {
    ToolbarList,
    ToolbarTab,
    BaseGrid,
    EmailFilter,
    EmailModal
  },
  props: {
    listMode: {
      default: Enums.LIST_MODE.FULL,
      type: String
    }
  },
  data() {
    return {
      filter: {},
      allClear: true,
      resourceType: this.$t("resource_types.email_message"),
      formMode: "",
      search: "",
      tableData: { headers: [], items: [] },
      tableOptions: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["created_at"],
        sortDesc: [false]
      },
      drawer_flag: false
    };
  },
  methods: {
    ...mapActions("emailMessage", ["ajaxEmailMessageFetchAll"]),

    async fetchData() {
      let payloadFAP = HelperService.buildFilterAndPagination({
        search: this.search,
        pagination: this.tableOptions,
        filter_criteria: this.filter
      });
      await this.ajaxEmailMessageFetchAll(payloadFAP);
      this.initTable();
    },
    handleDrawer(val) {
      this.drawer_flag = val;
    },
    handleFormCancel() {
      this.closeFormDialog();
    },
    initTable() {
      this.tableData.headers = this.mapHeaders();
      this.tableData.items = this.mapItems();
    },
    mapHeaders() {
      const tableHeaders = [];
      tableHeaders.push({
        value: "created_at",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "From",
        value: "from",
        align: "start",
        sortable: false
      });
      tableHeaders.push({
        text: "To",
        value: "to",
        sortable: false,
        align: "start"
      });
      tableHeaders.push({
        text: "Subject",
        value: "subject",
        align: "start",
        sortable: false
      });
      return tableHeaders;
    },
    mapItems() {
      const map = _.map(this.emailMessageList, item => {
        item.fields = {
          created_at: {
            data: item.created_at,
            dataType: "date",
            css: "text-xs-left"
          },
          from: {
            data: item.from,
            dataType: "text",
            css: "text-xs-left"
          },
          to: {
            data: item.to,
            dataType: "text",
            css: "text-xs-left"
          },
          subject: {
            data: item.subject,
            dataType: "text",
            css: "text-xs-left"
          }
        };
        item.click = {
          show: true,
          actionType: "custom",
          callback: item => {
            this.openFormDialog(Enums.FORM_MODE.UPDATE, item);
          }
        };
        return item;
      });
      return map;
    },
    async handleFiltersSelected(
      allClear,
      tenantFilter,
      customerFilter,
      subjectFilter
    ) {
      this.goBack();
      this.allClear = allClear;
      this.filter = {
        from_email: tenantFilter,
        to_email: customerFilter,
        subject: subjectFilter
      };

      await this.fetchData();
      this.scrollToTop();
    },
    scrollToTop() {
      this.$vuetify.goTo(0);
    },
    goBack() {
      this.tableOptions = { ...this.tableOptions, page: 1 };
    },
    searchFunc: async function(searchQuery) {
      this.search = searchQuery
      await this.fetchData();
    },
    handlePaginationChanged(paginationOpts) {
      this.tableOptions = paginationOpts;
      this.fetchData();
    }
  },
  mixins: [FormsHelper, Enums],
  async created() {},
  computed
};
</script>

<style>
.colored {
  color: #00f;
}
</style>
