<template>
  <div class="filter-list">
    <div v-if="autocomplete && allowMultiple">
      <v-autocomplete
        autocomplete="off"
        :items="value"
        v-model="selectedItems"
        item-text="name"
        return-object
        multiple
        @change="setFilterAutocMultiple"
        :search-input.sync="search"
        no-filter
        clearable
      >
        <template v-slot:item="{ item }">
          <v-list-item-action>
            <v-icon v-if="item.checked">check_box</v-icon>
            <v-icon v-else>check_box_outline_blank</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-html="item.name"></v-list-item-title>
            <v-list-item-subtitle v-if="'subtitle' in item">
              {{ item.subtitle }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </div>
    <div v-else-if="autocomplete && !allowMultiple">
      <v-autocomplete
        autocomplete="off"
        :items="value"
        v-model="selectedItem"
        item-text="name"
        return-object
        @change="setFilterAutoc"
        :search-input.sync="search"
        no-filter
        clearable
      >
        <template v-slot:item="{ item }">
          <v-list-item-action>
            <v-icon v-if="item.checked">check_box</v-icon>
            <v-icon v-else>check_box_outline_blank</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-html="item.name"></v-list-item-title>
            <v-list-item-subtitle v-if="'subtitle' in item">
              {{ item.subtitle }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </div>
    <div v-else-if="showAll">
      <v-list-item v-for="(item, i) in value" :key="i" dense>
        <template>
          <v-list-item-action>
            <v-checkbox
              color="primary"
              v-model="item.checked"
              @change="setFilterCheckbox(item)"
            ></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </v-list-item>
      <v-col>
        <v-btn
          color="primary"
          x-small
          v-if="showButton"
          @click="showAll = false"
          >{{ $t("filters.hide") }}</v-btn
        >
      </v-col>
    </div>
    <div v-else>
      <v-list-item v-for="(item, i) in value.slice(0, 5)" :key="i" dense>
        <template>
          <v-list-item-action>
            <v-checkbox
              color="primary"
              v-model="item.checked"
              @change="setFilterCheckbox(item)"
            ></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </v-list-item>
      <v-col>
        <v-btn
          color="primary"
          x-small
          v-if="!alwaysShowAllComputed"
          @click="(showAll = true), (showButton = true)"
          >{{ $t("filters.showAll") }}</v-btn
        >
      </v-col>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  data() {
    return {
      showAll: false,
      showButton: false,
      selectedItem: {},
      selectedItems: [],
      search: "",
      alwaysShowAllComputed: false
    };
  },
  props: [
    "value",
    "alwaysShowAll",
    "autocomplete",
    "allowMultiple",
    "matchAttribute",
    "filterName"
  ],
  methods: {
    setFilterCheckbox(item) {
      let new_value = _.cloneDeep(this.value);
      new_value.forEach(element => {
        if (element[this.matchAttribute] != item[this.matchAttribute]) {
          element.checked = false;
          element.enabled = false;
        }
      });
      this.$emit("input", new_value);
      this.$emit("change");
    },
    setFilterAutoc(item) {
      let new_value = _.cloneDeep(this.value);
      new_value.forEach(element => {
        if (item==null || element[this.matchAttribute] != item[this.matchAttribute]) {
          element.checked = false;
          element.enabled = false;
        }
        else {
          element.checked = true;
          element.enabled = true;
        }
      });
      this.$emit("input", new_value);
      this.$emit("change");
    },
    setFilterAutocMultiple(items) {
      let new_value = _.cloneDeep(this.value);
      new_value.forEach(element => {
        let found = false;
        items.every(item => {
          if (element[this.matchAttribute] == item[this.matchAttribute]) {
            found = true;
            return false;
          }
          return true;
        });
        if (found) {
          element.checked = true;
          element.enabled = true;
        } else {
          element.checked = false;
          element.enabled = false;
        }
      });
      this.$emit("input", new_value);
      this.$emit("change");
    },
    loadItems() {
      this.selectedItems = [];
      this.value.forEach(item => {
        if (item.checked) {
          this.selectedItem=item;
          this.selectedItems.push(item);
        }
      });
    }
  },
  created() {
    if (this.value.length <= 5) this.alwaysShowAllComputed = true;
    this.showAll = this.alwaysShowAllComputed;
    this.loadItems();
  },
  watch: {
    value: function() {
      this.loadItems();
    },
    search: function(val) {
      this.$emit("search", val);
    }
  }
};
</script>

<style>
div.filter-list .v-list--dense .v-list-item,
.v-list-item--dense {
  height: 30px !important;
}

div.filter-list .v-list-item--dense .v-list-item__content {
  padding: 4px, 0px !important;
}
.v-autocomplete__content {
  max-width: 500px !important;
}
</style>
