import Vue from "vue";
import store from "./store";

/* ============
 * VueConfirmDialog
 * ============
 */
import VueConfirmDialog from "vue-confirm-dialog";
Vue.use(VueConfirmDialog);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);

/* ============
 * FlagIcon
 * ============
 */
import FlagIcon from "vue-flag-icon";
Vue.use(FlagIcon);
Vue.config.debug = process.env.NODE_ENV !== "production";

/* ============
 * VeeValidate
 * ============
 */
import VeeValidate from "vee-validate";
const config = {
  aria: true,
  classNames: {},
  classes: true,
  // delay: 0,
  dictionary: {
    en: {
      custom: {
        website: {
          required: "Enter the site with http://" // messages can be strings as well.
        },
        url: {
          required: "Enter the site with http://" // messages can be strings as well.
        },
      }
    },
    it: {
      messages: {
        required: 'Campo obbligatorio',
      }
    }
  },
  errorBagName: "veeErrors", // change if property conflicts
  events: "input|blur",
  fieldsBagName: "fields",
  i18n: null, // the vue-i18n plugin instance
  i18nRootKey: "validations", // the nested key under which the validation messages will be located
  inject: true,
  locale: localStorage.locale,
  // validity: false,
  // useConstraintAttrs: true,
};
Vue.use(VeeValidate, config);

/* ============
 * Axios
 * ============
 */
import { AXIOS } from "./axios";

AXIOS.interceptors.response.use(
  response => response,
  error => {
    if (error.response !== undefined && error.response.status === 401) {
      store.dispatch("login/logout");
    }
    return Promise.reject(error);
  }
);
Vue.Axios = AXIOS;

/* ============
 * VUE-SELECT
 * ============
 */
import vSelect from "vue-select";
Vue.component("vue-select", vSelect);
import "vue-select/dist/vue-select.css";

/* ============
 * CASL-ABILITY (ACL)
 * ============
 */
import { abilitiesPlugin } from "@casl/vue";
//import abilities from './abilities'
//Vue.use(abilitiesPlugin, abilities)
Vue.use(abilitiesPlugin);

/* ============
 * VUE-DATETIME
 * ============
 */
import { Datetime } from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.min.css";

Vue.component("datetime", Datetime);
