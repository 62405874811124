<template>
  <v-container>
    <v-card
      v-if="
        this.listMode == Enums.LIST_MODE.TABBED ||
          this.listMode == Enums.LIST_MODE.READONLY
      "
    >
      <ToolbarTab
        :title="$t('labels.templates')"
        :subtitle="$tc('labels.found_elements', this.total)"
        :withAdd="!this.readOnly"
        @onAdd="
          openFormDialog(Enums.FORM_MODE.CREATE);
          templateFormKey += 1;
        "
      ></ToolbarTab>

      <BaseGrid
        tableName="attachments"
        :headers="this.tableData.headers"
        :items="this.tableData.items"
        :totalLength="this.total"
        :pagination="this.tableOptions"
        :loading="this.onLoading"
        @onPaginationChanged="handlePaginationChanged"
        :withEdit="!this.readOnly"
        :withDelete="!this.readOnly"
        @onEdit="handleEdit"
        @onDelete="handleDelete"
      ></BaseGrid>
    </v-card>

    <v-dialog v-model="formDialog" content-class="edit-form-dialog">
      <AttachmentForm
        v-if="formDialog"
        :mode="formMode"
        :selectedItem="formItem"
        :withModelType="this.withModelType"
        :withModelId="this.withModelId"
        :permittedTypes="this.permittedTypes"
        @formSucceed="handleFormSucceed"
        @formCancel="handleFormCancel"
        :key="this.templateFormKey"
        :machineName="machineName"
      ></AttachmentForm>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import AttachmentForm from "@/components/Attachment/AttachmentForm";
import ToolbarTab from "@/components/Shared/ToolbarTab";
import { HelperService } from "@/services/helper.service";
import BaseGrid from "@/components/Shared/BaseGrid";
import FormsHelper from "@/mixins/formsHelper";
import DatatablesHelper from "@/mixins/datatablesHelper";
import Enums from "@/mixins/enums";

const computed = {
  ...mapGetters("template", [
    "templates",
    "selectedTemplate",
    "total",
    "onLoading"
  ]),
  ...mapGetters("attachmentType", [
    "attachmentTypes",
    "selectedAttachmentType",
    "mappedAttachmentTypes",
    "getTypesByMachineNames",
    "getTypesByIds"
  ]),
  _() {
    return _;
  },
  Enums() {
    return Enums;
  },
  templatesList() {
    return this.templates;
  }
};

export default {
  components: {
    AttachmentForm,
    ToolbarTab,
    BaseGrid
  },
  props: {
    withModelType: {
      required: true,
      type: String
    },
    withModelId: {
      required: true,
      type: String
    },
    listMode: {
      default: Enums.LIST_MODE.TABBED,
      type: String
    },
    readOnly: {
      default: false,
      type: Boolean
    },
    permittedTypes: {
      default: null,
      type: String
    },
    machineName: {}
  },
  data() {
    return {
      search: "",
      templateFormKey: 0
    };
  },
  mixins: [FormsHelper, Enums, DatatablesHelper],
  /*watch: {
    async withModelType() {
      this.fetchData();
    },
    async withModelId() {
      this.fetchData();
    }
  },*/
  methods: {
    ...mapActions("template", [
      "ajaxTemplateFetchAll",
      "ajaxTemplateCreateOrUpdate",
      "ajaxTemplateDelete"
    ]),
    ...mapActions("attachmentType", ["ajaxAttachmentTypeFetchAll"]),

    async fetchData(needle = null) {
      let _filter = {};
      const _search = needle ? needle : this.search || "";

      console.log("PROPS MACHINE NAME ", this.$props.machineName)
      console.log("THIS MACHINE NAME ", this.machineName)

      let machineName=this.machineName;
      let parentId;
      if (machineName === 'TEMPLATE')
        parentId = 30;
        //parentId = 15;
      else if (machineName === 'ORDER')
        parentId = 17;
      else
        parentId = 31;
      _filter.file_type_id=parentId;

      if (this.$props.withModelType) {
        _filter = { ..._filter, ...{ model_type: this.$props.withModelType } };
      }
      if (this.$props.withModelId) {
        _filter = { ..._filter, ...{ model_id: this.$props.withModelId } };
      }

      let payloadFAP = HelperService.buildFilterAndPagination({
        search: _search,
        filter: _filter,
        pagination: this.tableOptions,
      });
      await this.ajaxTemplateFetchAll(payloadFAP);
      await this.ajaxAttachmentTypeFetchAll({machineName:this.machineName});
      this.initTable();
    },
    initTable() {
      this.tableData.headers = this.mapHeaders();
      this.tableData.items = this.mapItems();
    },
    mapHeaders() {
      const tableHeaders = [];
      tableHeaders.push({
        text: "Type",
        value: "type",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Name",
        value: "name",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Detail",
        value: "detail",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center"
      });
      return tableHeaders;
    },
    mapItems() {
      const map = _.map(this.templatesList, item => {
        const attachmentType = item.file_type.name;
        item.fields = {
          type: { data: attachmentType, dataType: "text", css: "text-xs-left" },
          name: {
            data: item.name,
            dataType: "text",
            css: "text-xs-left"
          },
          detail: { data: item.detail, dataType: "text", css: "text-xs-left" }
        };
        item.actions = {
          download: {
            actionType: "file-download",
            fileRef: item.id,
            fileName: item.name
          }
        };
        return item;
      });
      return map;
    },
    handlePaginationChanged(paginationOpts) {
      this.tableOptions = paginationOpts;
      this.fetchData();
    },
    handleFormSucceed() {
      this.closeFormDialog();
      this.fetchData();
    },
    handleFormCancel() {
      this.closeFormDialog();
    },

    debounceSearch: _.debounce(async function(val) {
      this.search = val;
      await this.fetchData();
    }, process.env.VUE_APP_DEBOUNCE_TIME),

    async handleEdit(item) {
      this.openFormDialog(Enums.FORM_MODE.UPDATE, item);
    },
    async handleDelete(item) {
      this.$confirm({
        message:
          this.$t("dialogs.deleteConfirmMessage") + "\n\nFile | " + item.name,
        button: {
          no: this.$t("dialogs.no"),
          yes: this.$t("dialogs.yes")
        },
        callback: async confirm => {
          if (confirm) {
            await this.ajaxTemplateDelete(item);
            this.fetchData();
          }
        }
      });
    }
  },

  async created() {
    this.formDialog = false;
    await this.fetchData();
  },
  computed
};
</script>
