var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "form-container title", attrs: { fluid: "" } },
    [
      _vm.mode == _vm.Enums.FORM_MODE.CREATE ||
      _vm.mode == _vm.Enums.FORM_MODE.UPDATE
        ? _c(
            "div",
            [
              _c("v-card-title", { staticClass: "form-title title" }, [
                _vm._v(_vm._s(_vm._f("capitalize")(this.formTitle)))
              ]),
              _c(
                "v-form",
                {
                  staticClass: "form-body",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.onSubmit($event)
                    }
                  }
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "form-group required",
                          attrs: { cols: "6" }
                        },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate.immediate",
                                value: "required",
                                expression: "'required'",
                                modifiers: { immediate: true }
                              }
                            ],
                            attrs: {
                              label: _vm.$t("forms.customers.name"),
                              name: "name"
                            },
                            model: {
                              value: _vm.form.name,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name"
                            }
                          }),
                          _vm.veeErrors.has("name")
                            ? _c(
                                "span",
                                {
                                  class:
                                    _vm.Settings.style.class
                                      .formFieldRequiredCaption
                                },
                                [_vm._v(_vm._s(_vm.veeErrors.first("name")))]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "form-group required",
                          attrs: { cols: "6" }
                        },
                        [
                          _c("v-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate.immediate",
                                value: "required",
                                expression: "'required'",
                                modifiers: { immediate: true }
                              }
                            ],
                            attrs: {
                              items: this.tenants,
                              enabled: !this.$props.withModelId,
                              "item-text": "name",
                              "item-value": "id",
                              label: _vm.$t("forms.customers.tenant"),
                              "single-line": "",
                              clearable: "",
                              name: "tenant"
                            },
                            model: {
                              value: _vm.form.tenant_id,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "tenant_id", $$v)
                              },
                              expression: "form.tenant_id"
                            }
                          }),
                          _vm.veeErrors.has("tenant")
                            ? _c(
                                "span",
                                {
                                  class:
                                    _vm.Settings.style.class
                                      .formFieldRequiredCaption
                                },
                                [_vm._v(_vm._s(_vm.veeErrors.first("tenant")))]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "form-group required",
                          attrs: { cols: "6" }
                        },
                        [
                          _c("v-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate.immediate",
                                value: "required",
                                expression: "'required'",
                                modifiers: { immediate: true }
                              }
                            ],
                            ref: "customer_type",
                            attrs: {
                              items: this.customerTypes,
                              "item-text": "name",
                              "item-value": "id",
                              label: _vm.$t("forms.customers.customer_type"),
                              "single-line": "",
                              clearable: "",
                              name: "customer_type"
                            },
                            on: { change: _vm.onCustomerTypeChange },
                            model: {
                              value: _vm.form.type_id,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "type_id", $$v)
                              },
                              expression: "form.type_id"
                            }
                          }),
                          _vm.veeErrors.has("customer_type")
                            ? _c(
                                "span",
                                {
                                  class:
                                    _vm.Settings.style.class
                                      .formFieldRequiredCaption
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.veeErrors.first("customer_type"))
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate.immediate",
                                value: "required_if:customer_type,1",
                                expression: "'required_if:customer_type,1'",
                                modifiers: { immediate: true }
                              }
                            ],
                            attrs: {
                              label: _vm.$t(
                                "forms.customers.mechanographyc_code"
                              ),
                              name: "mechanographyc_code"
                            },
                            model: {
                              value: _vm.form.mechanographyc_code,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "mechanographyc_code", $$v)
                              },
                              expression: "form.mechanographyc_code"
                            }
                          }),
                          _vm.veeErrors.has("mechanographyc_code")
                            ? _c(
                                "span",
                                {
                                  class:
                                    _vm.Settings.style.class
                                      .formFieldRequiredCaption
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.veeErrors.first("mechanographyc_code")
                                    )
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate.immediate",
                                value: "required_if:customer_type,2",
                                expression: "'required_if:customer_type,2'",
                                modifiers: { immediate: true }
                              }
                            ],
                            attrs: {
                              label: _vm.$t("forms.customers.piva"),
                              name: "piva"
                            },
                            model: {
                              value: _vm.form.piva,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "piva", $$v)
                              },
                              expression: "form.piva"
                            }
                          }),
                          _vm.veeErrors.has("piva")
                            ? _c(
                                "span",
                                {
                                  class:
                                    _vm.Settings.style.class
                                      .formFieldRequiredCaption
                                },
                                [_vm._v(_vm._s(_vm.veeErrors.first("piva")))]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate.immediate",
                                value: "required_if:customer_type,3",
                                expression: "'required_if:customer_type,3'",
                                modifiers: { immediate: true }
                              }
                            ],
                            attrs: {
                              label: _vm.$t("forms.customers.cf"),
                              name: "cf"
                            },
                            model: {
                              value: _vm.form.cf,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "cf", $$v)
                              },
                              expression: "form.cf"
                            }
                          }),
                          _vm.veeErrors.has("cf")
                            ? _c(
                                "span",
                                {
                                  class:
                                    _vm.Settings.style.class
                                      .formFieldRequiredCaption
                                },
                                [_vm._v(_vm._s(_vm.veeErrors.first("cf")))]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "form-group required",
                          attrs: { cols: "6" }
                        },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate.immediate",
                                value: "required|email",
                                expression: "'required|email'",
                                modifiers: { immediate: true }
                              }
                            ],
                            attrs: {
                              label: _vm.$t("forms.customers.peo"),
                              name: "peo"
                            },
                            model: {
                              value: _vm.form.peo,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "peo", $$v)
                              },
                              expression: "form.peo"
                            }
                          }),
                          _vm.veeErrors.has("peo")
                            ? _c(
                                "span",
                                {
                                  class:
                                    _vm.Settings.style.class
                                      .formFieldRequiredCaption
                                },
                                [_vm._v(_vm._s(_vm.veeErrors.first("peo")))]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "form-group required",
                          attrs: { cols: "6" }
                        },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate.immediate",
                                value: "required|email",
                                expression: "'required|email'",
                                modifiers: { immediate: true }
                              }
                            ],
                            attrs: {
                              label: _vm.$t("forms.customers.pec"),
                              name: "pec"
                            },
                            model: {
                              value: _vm.form.pec,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "pec", $$v)
                              },
                              expression: "form.pec"
                            }
                          }),
                          _vm.veeErrors.has("pec")
                            ? _c(
                                "span",
                                {
                                  class:
                                    _vm.Settings.style.class
                                      .formFieldRequiredCaption
                                },
                                [_vm._v(_vm._s(_vm.veeErrors.first("pec")))]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: _vm.$t("forms.customers.address") },
                            model: {
                              value: _vm.form.address,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "address", $$v)
                              },
                              expression: "form.address"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: _vm.$t("forms.customers.city") },
                            model: {
                              value: _vm.form.city,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "city", $$v)
                              },
                              expression: "form.city"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: _vm.$t("forms.customers.cap") },
                            model: {
                              value: _vm.form.cap,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "cap", $$v)
                              },
                              expression: "form.cap"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("forms.customers.province")
                            },
                            model: {
                              value: _vm.form.province,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "province", $$v)
                              },
                              expression: "form.province"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: _vm.$t("forms.customers.phone") },
                            model: {
                              value: _vm.form.phone,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "phone", $$v)
                              },
                              expression: "form.phone"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate.immediate",
                                value: "required",
                                expression: "'required'",
                                modifiers: { immediate: true }
                              }
                            ],
                            attrs: {
                              label: _vm.$t("forms.customers.website"),
                              name: "website"
                            },
                            model: {
                              value: _vm.form.website,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "website", $$v)
                              },
                              expression: "form.website"
                            }
                          }),
                          _vm.veeErrors.has("website")
                            ? _c(
                                "span",
                                {
                                  class:
                                    _vm.Settings.style.class
                                      .formFieldRequiredCaption
                                },
                                [_vm._v(_vm._s(_vm.veeErrors.first("website")))]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("forms.customers.referent_surname")
                            },
                            model: {
                              value: _vm.form.referent_surname,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "referent_surname", $$v)
                              },
                              expression: "form.referent_surname"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("forms.customers.referent_name")
                            },
                            model: {
                              value: _vm.form.referent_name,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "referent_name", $$v)
                              },
                              expression: "form.referent_name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: _vm.$t("forms.customers.dsga") },
                            model: {
                              value: _vm.form.dsga,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "dsga", $$v)
                              },
                              expression: "form.dsga"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("DisplaySeparator", {
                    attrs: {
                      as: _vm.Enums.DISPLAY_AS.SEPARATOR,
                      label: _vm.$t("labels.generalPreferences")
                    }
                  }),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.ticketCreationEnum,
                              label: _vm.$t(
                                "forms.customers.preferences.ticket_creation"
                              )
                            },
                            model: {
                              value: _vm.form.preferences.ticket_creation,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form.preferences,
                                  "ticket_creation",
                                  $$v
                                )
                              },
                              expression: "form.preferences.ticket_creation"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("DisplaySeparator", {
                    attrs: {
                      as: _vm.Enums.DISPLAY_AS.SEPARATOR,
                      label: _vm.$t("labels.metadataScuole")
                    }
                  }),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t(
                                "forms.customers.metadata_scuole.dirigente_territoriale.city"
                              )
                            },
                            model: {
                              value:
                                _vm.form.metadata_scuole.dirigente_territoriale
                                  .city,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form.metadata_scuole
                                    .dirigente_territoriale,
                                  "city",
                                  $$v
                                )
                              },
                              expression:
                                "form.metadata_scuole.dirigente_territoriale.city"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t(
                                "forms.customers.metadata_scuole.dirigente_territoriale.province"
                              )
                            },
                            model: {
                              value:
                                _vm.form.metadata_scuole.dirigente_territoriale
                                  .province,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form.metadata_scuole
                                    .dirigente_territoriale,
                                  "province",
                                  $$v
                                )
                              },
                              expression:
                                "form.metadata_scuole.dirigente_territoriale.province"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate.immediate",
                                value: "email",
                                expression: "'email'",
                                modifiers: { immediate: true }
                              }
                            ],
                            attrs: {
                              label: _vm.$t(
                                "forms.customers.metadata_scuole.dirigente_territoriale.email"
                              ),
                              name: "dirigente_territoriale_email"
                            },
                            model: {
                              value:
                                _vm.form.metadata_scuole.dirigente_territoriale
                                  .email,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form.metadata_scuole
                                    .dirigente_territoriale,
                                  "email",
                                  $$v
                                )
                              },
                              expression:
                                "form.metadata_scuole.dirigente_territoriale.email"
                            }
                          }),
                          _vm.veeErrors.has("dirigente_territoriale_email")
                            ? _c(
                                "span",
                                {
                                  class:
                                    _vm.Settings.style.class
                                      .formFieldRequiredCaption
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.veeErrors.first(
                                        "dirigente_territoriale_email"
                                      )
                                    )
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("DisplaySeparator", {
                    attrs: {
                      as: _vm.Enums.DISPLAY_AS.SEPARATOR,
                      label: _vm.$t("labels.gdprPreferences")
                    }
                  }),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.gdprPublicPageVisibilityEnum,
                              label: _vm.$t(
                                "forms.customers.preferences.gdpr_public_page_visibility"
                              )
                            },
                            model: {
                              value:
                                _vm.form.preferences
                                  .gdpr_public_page_visibility,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form.preferences,
                                  "gdpr_public_page_visibility",
                                  $$v
                                )
                              },
                              expression:
                                "form.preferences.gdpr_public_page_visibility"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t(
                                "forms.customers.preferences.gdpr_owncloud_password"
                              )
                            },
                            model: {
                              value:
                                _vm.form.preferences.gdpr_owncloud_password,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form.preferences,
                                  "gdpr_owncloud_password",
                                  $$v
                                )
                              },
                              expression:
                                "form.preferences.gdpr_owncloud_password"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t(
                                "forms.customers.preferences.gdpr_owncloud_folder"
                              )
                            },
                            model: {
                              value: _vm.form.preferences.gdpr_owncloud_folder,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form.preferences,
                                  "gdpr_owncloud_folder",
                                  $$v
                                )
                              },
                              expression:
                                "form.preferences.gdpr_owncloud_folder"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("FormButtons", {
                    attrs: { disabled: _vm.veeErrors.any() },
                    on: { onSave: _vm.onSubmit, onCancel: _vm.onCancel }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.mode === _vm.Enums.FORM_MODE.SHOW,
              expression: "mode === Enums.FORM_MODE.SHOW"
            }
          ],
          staticClass: "form-body-show"
        },
        [
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.customers.tenant"),
              value: this.localTenant.name
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.customers.id"),
              value: this.form.id
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.customers.code"),
              value: this.form.code
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.customers.name"),
              value: this.form.name
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.customers.customer_type"),
              value: this.localCustomerType.name
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.customers.mechanographyc_code"),
              value: this.form.mechanographyc_code
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.customers.piva"),
              value: this.form.piva
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.customers.cf"),
              value: this.form.cf
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.customers.peo"),
              value: this.form.peo
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.customers.pec"),
              value: this.form.pec
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.customers.address"),
              value: this.form.address
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.customers.cap"),
              value: this.form.cap
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.customers.city"),
              value: this.form.city
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.customers.province"),
              value: this.form.province
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.customers.phone"),
              value: this.form.phone
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.LINK,
              label: _vm.$t("forms.customers.website"),
              value: this.form.website
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.customers.referent_name"),
              value: this.form.referent_name
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.customers.referent_surname"),
              value: this.form.referent_surname
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.customers.dsga"),
              value: this.form.dsga
            }
          }),
          _c("DisplaySeparator", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.SEPARATOR,
              label: _vm.$t("labels.generalPreferences")
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.customers.preferences.ticket_creation"),
              value: this.form.preferences.ticket_creation
            }
          }),
          _c("DisplaySeparator", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.SEPARATOR,
              label: _vm.$t("labels.metadataScuole")
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t(
                "forms.customers.metadata_scuole.dirigente_territoriale.city"
              ),
              value: this.form.metadata_scuole.dirigente_territoriale.city
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t(
                "forms.customers.metadata_scuole.dirigente_territoriale.email"
              ),
              value: this.form.metadata_scuole.dirigente_territoriale.email
            }
          }),
          _c("DisplaySeparator", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.SEPARATOR,
              label: _vm.$t("labels.gdprPreferences")
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t(
                "forms.customers.preferences.gdpr_public_page_visibility"
              ),
              value: this.form.preferences.gdpr_public_page_visibility
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t(
                "forms.customers.preferences.gdpr_owncloud_password"
              ),
              value: this.form.preferences.gdpr_owncloud_password
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.customers.preferences.gdpr_owncloud_folder"),
              value: this.form.preferences.gdpr_owncloud_folder
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }