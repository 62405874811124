var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "color-footer" },
        [
          _vm._v(
            "\n    " +
              _vm._s(this.$t("colorGrid.year")) +
              ": " +
              _vm._s(this.selectedYear) +
              "\n    "
          ),
          _c(
            "v-btn",
            {
              attrs: { icon: "", large: "", color: "primary" },
              on: { click: _vm.onLeft }
            },
            [
              _c("v-icon", { attrs: { dark: "" } }, [
                _vm._v(_vm._s(_vm.$t("icons.left")))
              ])
            ],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { icon: "", large: "", color: "primary" },
              on: { click: _vm.onCurrent }
            },
            [
              _c("v-icon", { attrs: { dark: "" } }, [
                _vm._v(_vm._s(_vm.$t("icons.currentYear")))
              ])
            ],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { icon: "", large: "", color: "primary" },
              on: { click: _vm.onRight }
            },
            [
              _c("v-icon", { attrs: { dark: "" } }, [
                _vm._v(_vm._s(_vm.$t("icons.right")))
              ])
            ],
            1
          ),
          !_vm.singleRow
            ? _c(
                "span",
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.tableOptions.page * _vm.tableOptions.itemsPerPage +
                          1 -
                          _vm.tableOptions.itemsPerPage
                      ) +
                      "-" +
                      _vm._s(
                        Math.min(
                          _vm.tableOptions.page * _vm.tableOptions.itemsPerPage,
                          _vm.total
                        )
                      ) +
                      "\n      " +
                      _vm._s(this.$t("colorGrid.of")) +
                      " " +
                      _vm._s(_vm.total) +
                      "\n      "
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", large: "", color: "primary" },
                      on: { click: _vm.onUp }
                    },
                    [
                      _c("v-icon", { attrs: { dark: "" } }, [
                        _vm._v(_vm._s(_vm.$t("icons.up")))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", large: "", color: "primary" },
                      on: { click: _vm.onDown }
                    },
                    [
                      _c("v-icon", { attrs: { dark: "" } }, [
                        _vm._v(_vm._s(_vm.$t("icons.down")))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.loading
        ? _c("v-progress-linear", {
            attrs: { color: "info", height: "5", indeterminate: "" }
          })
        : _vm._e(),
      _c("table", { staticClass: "color-table" }, [
        _c("thead", [
          _c(
            "tr",
            _vm._l(_vm.tableHeaders, function(header, i) {
              return _c(
                "th",
                {
                  key: i,
                  class: {
                    "color-header": header.month == -1,
                    active: _vm.sortKey == i,
                    "highlight-col":
                      header.month == _vm.currentMonth &&
                      _vm.selectedYear == _vm.currentYear
                  },
                  on: {
                    click: function($event) {
                      return _vm.sortBy(i)
                    }
                  }
                },
                [
                  header.month == _vm.currentMonth &&
                  _vm.selectedYear == _vm.currentYear
                    ? _c("div", {
                        staticClass: "highlight-col-before",
                        style: "height: " + 56 * (_vm.items.length + 1) + "px"
                      })
                    : _vm._e(),
                  _vm._v(
                    "\n          " + _vm._s(header.label) + "\n          "
                  ),
                  header.sortable
                    ? _c("span", {
                        staticClass: "arrow",
                        class: _vm.sortOrders[i] > 0 ? "asc" : "dsc"
                      })
                    : _vm._e()
                ]
              )
            }),
            0
          )
        ]),
        _c(
          "tbody",
          _vm._l(_vm.rows, function(row, i) {
            return _c(
              "tr",
              { key: i },
              [
                _vm._l(_vm.items[i].fields, function(field, k) {
                  return _c("td", { key: "-" + k }, [
                    _c("div", { staticClass: "color-content" }, [
                      field.dataType == "text"
                        ? _c("span", [_vm._v(_vm._s(field.data))])
                        : _vm._e(),
                      field.dataType == "link"
                        ? _c(
                            "a",
                            {
                              on: {
                                click: function($event) {
                                  return field.linkCallback(_vm.items[i])
                                }
                              }
                            },
                            [_vm._v(_vm._s(field.data))]
                          )
                        : _vm._e()
                    ])
                  ])
                }),
                _vm._l(row.cells, function(cell, j) {
                  return [
                    !cell.skip
                      ? _c(
                          "td",
                          {
                            key: j,
                            class: [
                              "color-" + cell.color,
                              {
                                "left-cap": cell.color > 0 && cell.left_cap,
                                "right-cap": cell.color > 0 && cell.right_cap,
                                "with-border": cell.color > 0
                              }
                            ],
                            attrs: {
                              id: "cell" + i + "_" + j,
                              colspan: cell.span
                            },
                            on: {
                              mouseover: function($event) {
                                return _vm.handleHover(cell, true)
                              },
                              mouseleave: function($event) {
                                return _vm.handleHover(cell, false)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(cell.label) +
                                "\n            "
                            ),
                            cell.color > 0
                              ? _vm._t("cell-content", null, {
                                  origin: cell.origin
                                })
                              : _vm._e(),
                            cell.tooltip
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      top: "",
                                      attach: "#cell" + i + "_" + j
                                    },
                                    model: {
                                      value: _vm.showTooltip,
                                      callback: function($$v) {
                                        _vm.showTooltip = $$v
                                      },
                                      expression: "showTooltip"
                                    }
                                  },
                                  [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(cell.content)
                                      }
                                    })
                                  ]
                                )
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                })
              ],
              2
            )
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }