var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { class: { "center-panel": _vm.drawer_flag } },
      [
        this.newRelease
          ? _c(
              "v-card",
              [
                _c("v-card-title", [
                  _c(
                    "span",
                    { staticClass: "headline", staticStyle: { color: "red" } },
                    [
                      _vm._v(
                        "Stiamo effettuando un aggiornamento del server. Momentaneamente non sarà possibile caricare decisioni a contrarre e atti relativi a procedure di affidamento. Ci scusiamo per il disagio."
                      )
                    ]
                  )
                ])
              ],
              1
            )
          : _vm._e(),
        _c(
          "v-card",
          [
            _c(
              "v-toolbar",
              { staticClass: "toolbar-94", attrs: { flat: "" } },
              [
                _c(
                  "v-row",
                  { staticClass: "toolbar-list", attrs: { align: "center" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "1" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { fab: "", small: "" },
                            on: {
                              click: function($event) {
                                return _vm.goTo()
                              }
                            }
                          },
                          [
                            _c("v-icon", [
                              _vm._v(_vm._s(_vm.$t("icons.arrow_back")))
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "toolbar-list-info",
                        attrs: { cols: "8" }
                      },
                      [
                        _c("v-toolbar-title", [
                          _c(
                            "p",
                            { staticClass: "text-h4 text-sm-left my-1" },
                            [_vm._v(_vm._s(_vm.$t("labels.at")))]
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            [
              _c(
                "v-list",
                { attrs: { "three-line": "", flat: "" } },
                [
                  _vm._l(_vm.listOptions, function(item, i) {
                    return [
                      _c(
                        "v-list-item",
                        {
                          key: i,
                          attrs: { to: item.to, disabled: item.disabled }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c(
                                "v-btn",
                                { attrs: { icon: "", title: item.info } },
                                [
                                  _c("v-icon", {
                                    staticClass: "tip-align",
                                    attrs: { color: "grey lighten-1" },
                                    domProps: {
                                      textContent: _vm._s("mdi-information")
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                domProps: { textContent: _vm._s(item.text) }
                              }),
                              _c("v-list-item-subtitle", {
                                domProps: {
                                  textContent: _vm._s(item.description)
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      item.divider == true ? _c("v-divider") : _vm._e()
                    ]
                  })
                ],
                2
              ),
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    {
                      attrs: { disabled: _vm.links.length == 0 },
                      on: {
                        click: function($event) {
                          return _vm.goToForm()
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: "",
                                title: _vm.$t("labels.access_register.info")
                              }
                            },
                            [
                              _c("v-icon", {
                                staticClass: "tip-align",
                                attrs: { color: "grey lighten-1" },
                                domProps: {
                                  textContent: _vm._s("mdi-information")
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              _vm._s(_vm.$t("labels.access_register.title"))
                            )
                          ]),
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              _vm._s(_vm.$t("labels.access_register.subtitle"))
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-dialog",
                    {
                      attrs: { persistent: "", "max-width": "700px" },
                      model: {
                        value: _vm.dialog,
                        callback: function($$v) {
                          _vm.dialog = $$v
                        },
                        expression: "dialog"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-card-title", [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "headline",
                                                staticStyle: { color: "red" }
                                              },
                                              [
                                                _vm._v(
                                                  "Il server Aruba è in manuntenzione."
                                                )
                                              ]
                                            )
                                          ]),
                                          _c("v-card-title", [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "headline",
                                                staticStyle: { color: "red" }
                                              },
                                              [
                                                _vm._v(
                                                  "Al momento non è possibile caricare documenti."
                                                )
                                              ]
                                            )
                                          ]),
                                          _c("v-card-title", [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "headline",
                                                staticStyle: { color: "red" }
                                              },
                                              [
                                                _vm._v(
                                                  "Ci scusiamo per il disagio."
                                                )
                                              ]
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }