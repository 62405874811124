var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "grey lighten-4 title", attrs: { fluid: "" } },
    [
      _vm.mode == _vm.Enums.FORM_MODE.CREATE
        ? _c(
            "div",
            [
              _c("v-card-title", { staticClass: "form-title title" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("capitalize")(_vm.formTitle)) +
                    "\n    "
                )
              ]),
              _c(
                "v-form",
                {
                  staticClass: "form-body",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.onSubmit($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _c("v-textarea", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.immediate",
                            value: "required",
                            expression: "'required'",
                            modifiers: { immediate: true }
                          }
                        ],
                        attrs: {
                          label: _vm.$t("forms.permalinks.subject"),
                          hint: _vm.$t("forms.permalinks.subject-hint"),
                          placeholder: _vm.$t(
                            "forms.permalinks.subject-placeholder"
                          ),
                          name: "subject",
                          rows: "2",
                          "no-resize": "",
                          counter: "200",
                          maxlength: 200
                        },
                        model: {
                          value: _vm.form.subject,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "subject", $$v)
                          },
                          expression: "form.subject"
                        }
                      }),
                      _vm.veeErrors.has("subject")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [_vm._v(_vm._s(_vm.veeErrors.first("subject")))]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("FormButtons", {
                    attrs: {
                      createLoading: _vm.createLoading,
                      disabled: _vm.veeErrors.any()
                    },
                    on: { onSave: _vm.onSubmit, onCancel: _vm.onCancel }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          staticClass: "custom-dialog",
          attrs: { persistent: "", "max-width": "500" },
          model: {
            value: _vm.formDialog,
            callback: function($$v) {
              _vm.formDialog = $$v
            },
            expression: "formDialog"
          }
        },
        [
          _c(
            "v-container",
            { staticClass: "grey lighten-4 title", attrs: { fluid: "" } },
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.$t("forms.permalinks.form.attenzione")))
              ]),
              _c("v-card-text", [
                _c("p", { staticStyle: { "font-size": "15px" } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("forms.permalinks.dialog-info.msg")) +
                      "\n        "
                  )
                ])
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: !_vm.confirmedCheck,
                        color: "primary"
                      },
                      on: { click: _vm.handleConfirm }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("dialogs.confirm")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }