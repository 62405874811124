import Vue from "vue";
import Vuex from "vuex";
import { GenerationSessionItemService } from "@/services/generationSessionItem.service";
import { HelperService } from "@/services/helper.service";

Vue.use(Vuex);

const state = {
  generationSessionItems: [],
  selected: {},
  loading: false,
  totalCount: 0
};

const getters = {
  generationSessionItems: state => state.generationSessionItems,
  onLoading: state => state.loading,
  selectedGenerationSessionItem: state => state.selected,
  total: state => state.totalCount
};

const mutations = {
  setSelected(state, sel) {
    state.selected = sel;
  },
  setList(state, sel) {
    state.generationSessionItems = sel;
  },
  setTotal(state, sel) {
    state.totalCount = Number(sel);
  },

  setLoading(state, sel) {
    state.loading = sel;
  }
};

const actions = {
  async ajaxGenerationSessionItemSetSelected({ commit }, payload) {
    await HelperService.genericSetSelected(
      commit,
      GenerationSessionItemService.fetchOne,
      payload.id
    );
  },

  async ajaxGenerationSessionItemFetchAll({ commit }, payloadFAP) {
    const searchFilterPagination = HelperService.localToJSONAPIPagination(
      payloadFAP
    );
    searchFilterPagination.include = "customer,agreement,file_type";

    await HelperService.genericFetchAll(
      commit,
      GenerationSessionItemService.fetchAll,
      searchFilterPagination,
      null,
      null
    );
  },

  async ajaxGenerationSessionItemCreateOrUpdate({ commit }, payload) {
    return HelperService.genericCreateOrUpdate(
      commit,
      GenerationSessionItemService.createOrUpdate,
      payload
    );
  },

  async ajaxGenerationSessionItemDelete({ commit }, payload) {
    return HelperService.genericDelete(
      commit,
      GenerationSessionItemService.delete,
      payload.id
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
