<template>
  <v-navigation-drawer
    class="filter-drawer"
    v-model="drawer"
    v-show="drawer"
    absolute
    temporary
  >
    <v-toolbar color="primary" dark>
      <v-toolbar-title>{{ $t("filters.filters") }}</v-toolbar-title>
    </v-toolbar>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.status") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="false"
          matchAttribute="value"
          v-model="statusesAll"
          :alwaysShowAll="true"
          @change="getAllFiltersSelected"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.customer_type") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="false"
          matchAttribute="value"
          v-model="customerTypesAll"
          :alwaysShowAll="true"
          @change="getAllFiltersSelected"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.expiration") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="false"
          matchAttribute="value"
          v-model="expirationsAll"
          :alwaysShowAll="true"
          @change="getAllFiltersSelected"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.renewal_proposal") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="false"
          matchAttribute="value"
          v-model="renewalProposalAll"
          :alwaysShowAll="true"
          @change="getAllFiltersSelected"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.tenant") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="false"
          matchAttribute="value"
          v-model="tenantsAll"
          :alwaysShowAll="true"
          @change="getAllFiltersSelected"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.services") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="true"
          matchAttribute="machineName"
          v-model="servicesAll"
          :alwaysShowAll="false"
          @change="getAllFiltersSelected"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.customer") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="true"
          matchAttribute="value"
          v-model="customersAll"
          :autocomplete="true"
          @change="getAllFiltersSelected"
          @search="handleCustomerSearch"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-col align="right">
      <v-btn color="primary" small outlined @click="clearFilters">
        {{ $t("buttons.clear") }}
      </v-btn>
    </v-col>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { HelperService } from "@/services/helper.service";
import _ from "lodash";
import moment from "moment";
import FilterList from "@/components/Shared/FilterList";
import SharedMixin from "@/mixins/shared";

export default {
  mixins: [SharedMixin],
  components: {
    FilterList
  },
  data() {
    return {
      drawer: false,
      statusesAll: [
        { value: "ACTIVE", checked: false, enabled: false },
        { value: "WARNING", checked: false, enabled: false },
        { value: "EXPIRING", checked: false, enabled: false },
        { value: "EXPIRED", checked: false, enabled: false },
        { value: "RENEWED", checked: false, enabled: false },
        { value: "NOT_RENEWED", checked: false, enabled: false },
        { value: "MIGRATED", checked: false, enabled: false },
        { value: "INTERRUPTED", checked: false, enabled: false }
      ],
      customerTypesAll: [],
      expirationsAll: [
        { value: "CURRENT_MONTH", checked: false, enabled: false },
        { value: "FROM_NOW_TO_NEXT_MONTH", checked: false, enabled: false },
        { value: "FROM_NOW_TO_3_MONTHS", checked: false, enabled: false },
        { value: "FROM_NOW_TO_6_MONTHS", checked: false, enabled: false }
      ],
      renewalProposalAll: [
        { value: "YES", checked: false, enabled: false },
        { value: "NO", checked: false, enabled: false }
      ],
      tenantsAll: [],
      servicesAll: [],
      customersAll: []
    };
  },
  methods: {
    ...mapActions("customer", ["ajaxCustomerFetchAll"]),
    ...mapActions("customerType", ["ajaxCustomerTypeFetchAll"]),
    ...mapActions("tenant", ["ajaxTenantFetchAll"]),
    ...mapActions("service", ["ajaxServiceFetchAll"]),
    ...mapMutations("filters", ["setAgreementFilter", "setAgreementFlag"]),
    open() {
      this.drawer = true;
    },

    save() {
      this.setAgreementFilter({
        statusesAll: this.statusesAll,
        customerTypesAll: this.customerTypesAll,
        expirationsAll: this.expirationsAll,
        renewalProposalAll: this.renewalProposalAll,
        tenantsAll: this.tenantsAll,
        servicesAll: this.servicesAll,
        customersAll: this.customersAll
      });
    },
    load() {
      if (!this.agreement_flag) return false;
      this.setAgreementFlag(false);
      if (_.isEmpty(this.agreement_filter)) return false;
      this.statusesAll = this.agreement_filter.statusesAll;
      this.customerTypesAll = this.agreement_filter.customerTypesAll;
      this.expirationsAll = this.agreement_filter.expirationsAll;
      this.renewalProposalAll = this.agreement_filter.renewalProposalAll;
      this.tenantsAll = this.agreement_filter.tenantsAll;
      this.servicesAll = this.agreement_filter.servicesAll;
      this.customersAll = this.agreement_filter.customersAll;
      return true;
    },

    //funzioni get filtro
    async getCustomerTypes() {
      let customerTypesArray = this.customerTypesAll;
      await this.ajaxCustomerTypeFetchAll();
      if (this.customerTypes) {
        this.customerTypes.forEach(function(customerType) {
          customerTypesArray.push({
            value: customerType.name,
            name: customerType.name,
            checked: false,
            enabled: false
          });
        });
      }
      customerTypesArray.unshift({
        name: this.$t("filters.all"),
        all: true,
        checked: false,
        enabled: false
      });
      this.customerTypesAll = customerTypesArray;
    },
    async getTenants() {
      let tenantsArray = this.tenantsAll;
      await this.ajaxTenantFetchAll();
      if (this.tenants) {
        this.tenants.forEach(function(tenant) {
          tenantsArray.push({
            value: tenant.id,
            name: tenant.name,
            checked: false,
            enabled: false
          });
        });
      }
      this.tenantsAll = tenantsArray;
    },
    async getServices() {
      let payloadFAP = HelperService.buildFilterAndPagination({
        pagination: {
          itemsPerPage: 100
        }
      });
      await this.ajaxServiceFetchAll(payloadFAP);

      let servicesArray = [];
      if (this.services) {
        this.services.forEach(function(service) {
          servicesArray.push({
            machineName: service.machine_name,
            name: service.machine_name,
            checked: false,
            enabled: false
          });
        });
      }
      servicesArray = _.uniqBy(servicesArray, "machineName");
      this.servicesAll = _.orderBy(servicesArray, "machineName");
    },
    getCustomers: _.debounce(async function getCustomers(val) {
      const filterAndPagination = { search: val, include: "tenant" };
      await this.ajaxCustomerFetchAll(filterAndPagination);

      let customersArray = [];
      if (this.customers) {
        this.customers.forEach(function(customer) {
          let tenantName = customer.tenant ? customer.tenant.name : ""
          customersArray.push({
            value: customer.id,
            name: customer.name,
            subtitle:
              tenantName +
              " - " +
              customer.mechanographyc_code +
              " (" +
              customer.city +
              " - " +
              customer.province +
              ")",
            checked: false,
            enabled: false
          });
        });
      }
      this.customersAll = _.orderBy(
        this.mergeWithSelected(customersArray, this.customersAll),
        "name"
      );
    }, process.env.VUE_APP_DEBOUNCE_TIME),
    handleCustomerSearch(query) {
      if (query) {
        this.getCustomers(query);
      }
    },
    formatDate(date) {
      if (!date) {
        return null;
      } else {
        return date.substring(0, date.indexOf("T"));
      }
    },
    getEndDateFrom(expiration) {
      if (expiration && expiration !== "ALL") {
        return new moment().startOf("month").format("YYYY-MM-DD");
      }
      return null;
    },
    getEndDateTo(expiration) {
      if (expiration === "CURRENT_MONTH") {
        return new moment().endOf("month").format("YYYY-MM-DD");
      } else if (expiration === "FROM_NOW_TO_NEXT_MONTH") {
        return new moment()
          .add(1, "M")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (expiration === "FROM_NOW_TO_3_MONTHS") {
        return new moment()
          .add(2, "M")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (expiration === "FROM_NOW_TO_6_MONTHS") {
        return new moment()
          .add(5, "M")
          .endOf("month")
          .format("YYYY-MM-DD");
      }
      return null;
    },
    //get filtri selezionati
    getAllFiltersSelected() {
      let statusFilter = this.computeFilter(this.statusesAll);
      let customerTypeFilter = this.computeFilter(this.customerTypesAll);
      let renewalProposalFilter = this.computeFilter(this.renewalProposalAll);
      let tenantFilter = this.computeFilter(this.tenantsAll);
      let serviceFilter = this.computeFilterMultiple(
        this.servicesAll,
        "machineName"
      );
      let customerFilter = this.computeFilterMultiple(
        this.customersAll,
        "value"
      );

      let expirationFilter = this.computeFilter(this.expirationsAll);
      let endDateFrom = this.getEndDateFrom(expirationFilter);
      let endDateTo = this.getEndDateTo(expirationFilter);

      let allClear = this.computeClear([
        statusFilter,
        customerTypeFilter,
        renewalProposalFilter,
        tenantFilter,
        serviceFilter,
        customerFilter,
        endDateFrom,
        endDateTo
      ]);

      this.save();
      this.$emit(
        "filtersSelected",
        allClear,
        statusFilter,
        customerTypeFilter,
        renewalProposalFilter,
        tenantFilter,
        serviceFilter,
        customerFilter,
        endDateFrom,
        endDateTo
      );
    },
    //svuota filtri
    clearFilters() {
      let statusFilter = null;
      let customerTypeFilter = null;
      let renewalProposalFilter = null;
      let tenantFilter = null;
      let serviceFilter = [];
      let customerFilter = [];
      let endDateFrom = null;
      let endDateTo = null;

      //funzioni set filtro
      this.statusesAll = this.resetFilter(this.statusesAll);
      this.customerTypesAll = this.resetFilter(this.customerTypesAll);
      this.renewalProposalAll = this.resetFilter(this.renewalProposalAll);
      this.expirationsAll = this.resetFilter(this.expirationsAll);
      this.tenantsAll = this.resetFilter(this.tenantsAll);
      this.servicesAll = this.resetFilter(this.servicesAll);
      this.customersAll = this.resetFilter(this.customersAll);

      this.save();
      this.$emit(
        "filtersSelected",
        true,
        statusFilter,
        customerTypeFilter,
        renewalProposalFilter,
        tenantFilter,
        serviceFilter,
        customerFilter,
        endDateFrom,
        endDateTo
      );
    }
  },
  filters: {},
  computed: {
    ...mapGetters("customerType", ["customerTypes"]),
    ...mapGetters("tenant", ["tenants"]),
    ...mapGetters("service", ["services"]),
    ...mapGetters("customer", ["customers"]),
    ...mapGetters("filters", ["agreement_filter", "agreement_flag"])
  },
  props: [],
  watch: {
    drawer(val) {
      this.$emit("drawerChanged", val);
    }
  },
  async mounted() {
    if (!this.load()) {
      this.statusesAll = this.getLangStrings(this.statusesAll);
      this.expirationsAll = this.getLangStrings(this.expirationsAll);
      await this.getCustomerTypes();
      this.renewalProposalAll = this.getLangStrings(this.renewalProposalAll);
      await this.getTenants();
      await this.getServices();
      this.getCustomers("");
    }
    this.getAllFiltersSelected();
  }
};
</script>
<style scoped>
.dateComponent {
  border: 1px solid grey;
  margin-left: 15px;
  margin-right: 120px;
}
</style>
