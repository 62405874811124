<template>
  <div>
    <AgreementFilter
      @filtersSelected="handleFiltersSelected"
      ref="drawer"
      @drawerChanged="handleDrawer"
    ></AgreementFilter>
    <div :class="{ 'center-panel': drawer_flag }">
      <v-card v-if="this.listMode === Enums.LIST_MODE.FULL">
        <ToolbarList
          :title="$t('labels.agreements')"
          :subtitle="$tc('labels.found_elements', this.total)"
          :withSearch="true"
          :withAdd="this.$can('addButton', 'agreements')"
          :withFilter="this.$can('filterButton', 'agreements')"
          :withInvoicing="this.$can('invoiceButton', 'agreements')"
          @onAdd="openFormDialog(Enums.FORM_MODE.CREATE)"
          @onSearch="searchFunc"
          @onInvoicing="handleInvoicing"
          @onFilter="$refs.drawer.open()"
          :allClear="allClear"
        ></ToolbarList>
        <BaseGrid
          tableName="agreements"
          :headers="this.tableData.headers"
          :items="this.tableData.items"
          :totalLength="this.total"
          :pagination="this.tableOptions"
          :loading="this.onLoading"
          @onPaginationChanged="handlePaginationChanged"
          :showRowFooter="false"
          :hideActions="false"
        >
        </BaseGrid>
      </v-card>
      <v-container v-if="this.listMode === Enums.LIST_MODE.TABBED">
        <v-card>
          <ToolbarTab
            :title="$t('labels.agreements')"
            :subtitle="$tc('labels.found_elements', this.total)"
            :withAdd="this.$can('addButton', 'agreements')"
            @onAdd="openFormDialog(Enums.FORM_MODE.CREATE)"
          ></ToolbarTab>
          <BaseGrid
            tableName="agreements"
            :headers="this.tableData.headers"
            :items="this.tableData.items"
            :totalLength="this.total"
            :pagination="this.tableOptions"
            :loading="this.onLoading"
            @onPaginationChanged="handlePaginationChanged"
            :showRowFooter="false"
            :hideActions="false"
          >
          </BaseGrid>
        </v-card>
      </v-container>

      <v-dialog
        v-model="formDialog"
        persistent
        content-class="edit-form-dialog"
      >
        <AgreementForm
          v-if="formDialog"
          :mode="formMode"
          :withModelId="this.withModelId"
          :withModelType="this.withModelType"
          :selectedItem="formItem"
          @formSucceed="handleFormSucceed"
          @formCancel="handleFormCancel"
          :advancedRenewalMode="advancedRenewalMode"
          :renewalMode="renewalMode"
          :generateDocument="generateDocument"
          :sendDocument="sendDocument"
        ></AgreementForm>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import moment from "moment-timezone";
import ToolbarList from "@/components/Shared/ToolbarList";
import ToolbarTab from "@/components/Shared/ToolbarTab";
import BaseGrid from "@/components/Shared/BaseGrid";
import AgreementForm from "@/components/Agreement/AgreementForm";
import FormsHelper from "@/mixins/formsHelper";
import { HelperService } from "@/services/helper.service";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import AgreementFilter from "@/components/Filter/AgreementFilter";
import { Mutex } from "async-mutex";

const computed = {
  ...mapGetters("agreement", ["agreements", "total", "onLoading"]),
  ...mapGetters("login", ["loggedUser", "token"]),

  _() {
    return _;
  },
  Enums() {
    return Enums;
  },
  agreementsList() {
    return this.agreements;
  }
};

export default {
  name: "agreementsList",
  components: {
    AgreementForm,
    ToolbarList,
    ToolbarTab,
    BaseGrid,
    AgreementFilter
  },
  props: {
    listMode: {
      default: Enums.LIST_MODE.FULL,
      type: String
    },
    withModelId: {
      type: String,
      required: false
    },
    withModelType: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      resourceType: this.$t("resource_types.agreement"),
      allClear: true,
      search: "",
      filter_criteria: {},
      progress: false,
      formDialog: false,
      formMode: "",
      tableData: { headers: [], items: [] },
      tableOptions: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["end_date"],
        sortDesc: [false]
      },
      drawer_flag: false,
      advancedRenewalMode: false,
      renewalMode: false,
      generateDocument: false,
      sendDocument: false,
      mutex: null
    };
  },
  /*watch: {
    async withModelId() {
      this.fetchData();
    }
  },*/
  methods: {
    ...mapActions("agreement", [
      "ajaxAgreementFetchAll",
      "ajaxAgreementCreateOrUpdate",
      "ajaxAgreementDelete"
    ]),
    ...mapActions("generationSession", ["ajaxGenerationSessionCreateOrUpdate"]),
    //...mapMutations("agreement", ["addToQueue", "removeFromQueue"]),
    async onDelete(item) {
      if (item.state != Enums.AGREEMENT_STATUS.EXPIRED) {
        this.toast(
          Enums.TOAST_TYPE.ERROR,
          "",
          this.$t("toasts.delete_agreement")
        );
      } else
        this.$confirm({
          message: this.$t("dialogs.deleteConfirmMessage"),
          button: {
            no: this.$t("dialogs.no"),
            yes: this.$t("dialogs.yes")
          },
          callback: async confirm => {
            if (confirm) {
              await this.deleteHelper(
                this.resourceType,
                this.ajaxAgreementDelete,
                item,
                payload => payload.p.code
              );
              this.fetchData();
            }
          }
        });
    },
    handleDrawer(val) {
      this.drawer_flag = val;
    },
    handleInvoicing() {
      this.$router.pushCatch({ name: "invoicing" });
    },
    disabledRenew(agreement) {
      let disabledRenew = false;
      if (
        agreement.state == "ACTIVE" ||
        agreement.filed ||
        agreement.filed === 1
      ) {
        disabledRenew = true;
      }
      return disabledRenew;
    },
    searchFunc: async function(searchQuery) {
      this.search = searchQuery
      await this.fetchData();
    },
    async fetchData() {
      if (this.withModelId) {
        this.filter_criteria.customer_ids = [this.withModelId];
      }
      if (this.loggedUser.info.role.name == "extATOperator") {
        this.filter_criteria.having_agreement_of_type = ["AMMINISTRAZIONE_TRASPARENTE"];
      }

      let payloadFAP = HelperService.buildFilterAndPagination({
        search: this.search,
        pagination: this.tableOptions,
        filter_criteria: this.filter_criteria
      });
      await this.ajaxAgreementFetchAll(payloadFAP);
      this.initTable();
    },
    initTable() {
      this.tableData.headers = this.mapHeaders();
      this.tableData.items = this.mapItems();
    },
    mapHeaders() {
      const tableHeaders = [];
      tableHeaders.push({
        text: "Code",
        value: "code",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Service",
        value: "service_machine_name",
        align: "start",
        sortable: false
      });
      tableHeaders.push({
        text: "Customer",
        value: "customer.name",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Mechanographyc",
        value: "customer.mechanographyc_code",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Begin date",
        value: "start_date",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "End date",
        value: "end_date",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "State",
        value: "state",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Operative flow",
        value: "mainFlowState",
        align: "start",
        sortable: false
      });
      tableHeaders.push({
        text: "Renewal proposal",
        value: "renewal_proposal_id",
        align: "start",
        sortable: false
      });
      tableHeaders.push({
        text: "Hours (Worked|Budget|Extra)",
        value: "hours",
        align: "start",
        sortable: false
      });
      tableHeaders.push({
        text: "Actions",
        value: "actions",
        align: "center"
      });
      return tableHeaders;
    },
    getStateIcon(item) {
      switch (item.state) {
        case Enums.AGREEMENT_STATUS.ACTIVE:
          return "green";
        case Enums.AGREEMENT_STATUS.WARNING:
          return "yellow";
        case Enums.AGREEMENT_STATUS.EXPIRING:
          return "orange";
        case Enums.AGREEMENT_STATUS.EXPIRED:
          return "red";
      }
    },
    mapItems() {
      const map = _.map(this.agreementsList, item => {
        item.fields = {
          code: { data: item.code, dataType: "text", css: "text-xs-left" },
          service_machine_name: {
            data: item.service,
            dataType: "text",
            css: "text-xs-left"
          },
          customer_name: {
            data: item.customer.name,
            dataType: "text",
            css: "text-uppercase"
          },
          customer_code: {
            data: item.customer.mechanographyc_code,
            dataType: "text",
            css: "text-uppercase"
          },
          start_date: {
            data: item.start_date,
            dataType: "date",
            css: "text-xs-left"
          },
          end_date: {
            data: item.end_date,
            dataType: "date",
            css: "text-xs-left"
          },
          state: {
            data: this.getEnumTranslationFor("agreements", item.state),
            dataType: "chip",
            css: "text-xs-left",
            chipClass: this.getStateIcon(item)
          },
          mainFlowState: {
            data: this.getEnumTranslationFor(
              "agreements",
              item.metadata.workflow_contratto
            ),
            dataType: "text",
            css: "text-xs-left"
          },
          renewal_proposal_id: {
            data: item.metadata.renewal_proposal_sent_at ? moment(item.metadata.renewal_proposal_sent_at).format("YYYY/MM/DD") + ' (' + item.metadata.renewal_proposal_id + ')' : '',
            dataType: "text",
            css: "text-xs-left"
          },
          hours: {
            data:
              item.worked_hours +
              " | " +
              item.total_hours +
              " | " +
              item.extra_hours,
            dataType: "text",
            css: "text-xs-left"
          }
        };
        (item.click = {
          tooltip:
            this.$t("forms.agreements.details") +
            " " +
            this.$t("forms.agreements.agreement"),
          show: this.$can("detailsButton", "agreements"),
          actionType: "router-link",
          namedRoot: "agreementDetails",
          namedRootId: item.id,
          icon: this.$t("icons.detail"),
          css: "text-xs-left"
        }),
          (item.actions = {
            edit: {
              tooltip:
                this.$t("forms.agreements.edit") +
                " " +
                this.$t("forms.agreements.agreement"),
              show: this.$can("editButton", "agreements"),
              actionType: "custom",
              callback: item => {
                this.renewalMode = false;
                this.generateDocument = false;
                this.sendDocument = false;
                this.openFormDialog(Enums.FORM_MODE.UPDATE, item);
              },
              icon: "edit",
              css: "text-xs-left",
              disabled: item.filed === 1 ? true : false
            },
            /*delete: {
              tooltip:
                this.$t("forms.agreements.delete") +
                " " +
                this.$t("forms.agreements.agreement"),
              show: this.$can("editButton", "agreements"),
              actionType: "custom",
              callback: item => {
                this.onDelete(item);
              },
              icon: "delete",
              css: "text-xs-left"
            },*/
            generate_gdpr: {
              tooltip: this.$t("forms.agreements.generate_gdpr_files"),
              show: this.$can("generateFilesButton", "agreements"),
              actionType: "custom",
              callback: item => {
                this.createFileGenerationSession(item);
              },
              icon: "create_new_folder",
              css: "text-xs-left",
              disabled: item.filed === 1 || !item.has_gdpr
            },
            generate_generic: {
              tooltip: this.$t("forms.agreements.generate_generic_files"),
              show: this.$can("generateGenericFilesButton", "agreements"),
              actionType: "custom",
              callback: item => {
                this.generateDocument = true;
                this.sendDocument = false;
                this.renewalMode = false;
                this.advancedRenewalMode = false;
                this.openFormDialog(Enums.FORM_MODE.UPDATE, item);
              },
              icon: "mdi-file-edit",
              css: "text-xs-left",
              disabled: (item.filed || item.customer.type_id !== 1) ? true : false
            },
            send_email: {
              tooltip: this.$t("forms.agreements.send_generic_files"),
              show: this.$can("sendGenericFilesButton", "agreements"),
              actionType: "custom",
              callback: item => {
                this.sendDocument = true;
                this.generateDocument = false;
                this.renewalMode = false;
                this.advancedRenewalMode = false;
                this.openFormDialog(Enums.FORM_MODE.UPDATE, item);
              },
              icon: "mdi-file-send",
              css: "text-xs-left",
              color: (item.emails_count && item.emails_count.send_document && item.emails_count.send_document > 0) ? "green" : "primary",
              disabled: item.customer.type_id === 1 ? false : true
            },
            invoicing: {
              tooltip:
                this.$t("forms.agreements.invoicing") +
                " " +
                this.$t("forms.agreements.agreement"),
              show: this.$can("invoiceButton", "agreements"),
              actionType: "router-link",
              namedRoot: "agreementInvoicing",
              namedRootId: item.id,
              icon: "attach_money",
              css: "text-xs-left"
            },
            export: {
              show: true,
              disabled: !this.getService(item),
              actionType: "custom",
              tooltip: this.$t("buttons.export-content"),
              icon: this.$t("icons.export"),
              callback: item => {
                this.exportContent(item);
              }
            },
            renew: {
              tooltip:
                this.$t("forms.agreements.renewal") +
                " " +
                this.$t("forms.agreements.simple") +
                " " +
                this.$t("forms.agreements.agreement"),
              show: this.$can("renewButton", "agreements"),
              actionType: "custom",
              callback: item => {
                this.renewalMode = true;
                this.advancedRenewalMode = false;
                this.generateDocument = false;
                this.sendDocument = false;
                this.openFormDialog(Enums.FORM_MODE.UPDATE, item);
              },
              icon_text: "RS",
              css: "text-xs-left",
              disabled: this.disabledRenew(item)
            },
            advancedRenew: {
              tooltip:
                this.$t("forms.agreements.renewal") +
                " " +
                this.$t("forms.agreements.advanced") +
                " " +
                this.$t("forms.agreements.agreement"),
              show: this.$can("renewButton", "agreements"),
              actionType: "custom",
              callback: item => {
                this.renewalMode = true;
                this.advancedRenewalMode = true;
                this.generateDocument = false;
                this.sendDocument = false;
                this.openFormDialog(Enums.FORM_MODE.UPDATE, item);
              },
              icon_text: "RA",
              css: "text-xs-left",
              disabled: this.disabledRenew(item)
            },
            nr_state: {
              tooltip: this.$t("forms.agreements.not_renew"),
              show: this.$can("renewButton", "agreements"),
              actionType: "custom",
              callback: item => {
                this.setNotRenewedState(item);
              },
              icon_text: "NR",
              css: "text-xs-left",
              disabled: item.state != "EXPIRED" ? true : false
            },
            interrupt_state: {
              tooltip: this.$t("forms.agreements.interrupt"),
              show: this.$can("interruptButton", "agreements"),
              actionType: "custom",
              callback: item => {
                this.setInterruptedState(item);
              },
              icon_text: "IN",
              css: "text-xs-left",
              disabled: (item.state == "EXPIRED" || item.state == "RENEWED" || item.state == "NOT_RENEWED" || item.state == "MIGRATED") || item.state == "INTERRUPTED" ? true : false
            }
          });
        return item;
      });
      return map;
    },
    async createFileGenerationSession(item) {
      this.$confirm({
        message:
          this.$t("dialogs.sure") + " " + this.$t("dialogs.generate_overwrite"),
        button: {
          no: this.$t("dialogs.no"),
          yes: this.$t("dialogs.yes")
        },
        callback: async confirm => {
          if (confirm) {
            let customerTemp = [];
            if (item && item.customer) customerTemp = [item.customer.id];
            const payload = {
              agreement_id: item.id,
              customers: customerTemp,
              templates: [],
              allegato: true
            };
            var release;
            release = await this.mutex.acquire();
            item.actions.generate_gdpr.loading = true;
            this.tableData.items = _.clone(this.tableData.items);
            release();
            await this.createOrUpdateHelper(
              Enums.FORM_MODE.CREATE,
              this.$t("resource_types.session"),
              this.ajaxGenerationSessionCreateOrUpdate,
              payload,
              payload => payload.v.id,
              payload => payload.p.id
            );
            release = await this.mutex.acquire();
            item.actions.generate_gdpr.loading = false;
            this.tableData.items = _.clone(this.tableData.items);
            release();
          }
        }
      });
    },
    async setNotRenewedState(item) {
      let agreement = {
        id: item.id,
        state: Enums.STATE_AGREEMENT.NOT_RENEWED,
        set_not_renewed: true
      };
      this.$confirm({
        message: this.$t("dialogs.not_renewed") + " " + this.$t("dialogs.sure"),
        button: {
          no: this.$t("dialogs.no"),
          yes: this.$t("dialogs.yes")
        },
        callback: async confirm => {
          if (confirm) {
            await this.createOrUpdateHelper(
              Enums.FORM_MODE.UPDATE,
              this.resourceType,
              this.ajaxAgreementCreateOrUpdate,
              agreement,
              () => "",
              () => ""
            );
            this.fetchData();
          }
        }
      });
    },
    async setInterruptedState(item) {
      let agreement = {
        id: item.id,
        state: Enums.STATE_AGREEMENT.INTERRUPTED,
        set_interrupted: true
      };
      this.$confirm({
        message: this.$t("dialogs.interrupted"),
        button: {
          no: this.$t("dialogs.no"),
          yes: this.$t("dialogs.yes")
        },
        callback: async confirm => {
          if (confirm) {
            await this.createOrUpdateHelper(
              Enums.FORM_MODE.UPDATE,
              this.resourceType,
              this.ajaxAgreementCreateOrUpdate,
              agreement,
              () => "",
              () => ""
            );
            this.fetchData();
          }
        }
      });
    },
    getService(item) {
      let services = item.services ? item.services : [];
      let serviceMachineNameArray = [];
      for (let i=0; i<services.length;i++){
        const service = services[i];
        if(service && service.machine_name == "AMMINISTRAZIONE_TRASPARENTE") serviceMachineNameArray.push(service.machine_name)
      }
      let hasATService = serviceMachineNameArray && serviceMachineNameArray.length > 0 ? true : false;
      return hasATService
    },
    async exportContent(item) {
      console.log("exportCustomer ITEM ", item)
      let agreementId = item.id;
      this.$confirm({
        message: this.$t("dialogs.export-content") + " " + this.$t("dialogs.sure"),
        button: {
          no: this.$t("dialogs.no"),
          yes: this.$t("dialogs.yes")
        },
        callback: async confirm => {
          if (confirm) {
            Vue.Axios({
              url: `${process.env.VUE_APP_ENDPOINT_API_URI}/private/agreements/export-content/${agreementId}`,
              method: "get",
              withCredentials: true,
              headers: {
                Authorization: `Bearer ${this.token}`,
                "Content-Type": "application/json"
              },
              responseType: "blob"
            })
            .then(res => {
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(res.data);
              link.download = "export-content.csv";
              link.click();
            })
            .catch(err => {
              console.log("error: ", err);
              this.$emit("showError", [{ title: err }]);
            });
          }
        }
      });
    },
    goBack() {
      this.tableOptions = { ...this.tableOptions, page: 1 };
    },
    handleFormSucceed() {
      this.closeFormDialog();
      this.fetchData();
    },
    handleFormCancel() {
      this.closeFormDialog();
    },
    handlePaginationChanged(paginationOpts) {
      this.tableOptions = paginationOpts;
      this.fetchData();
    },
    async handleFiltersSelected(
      allClear,
      statusFilter,
      customerTypeFilter,
      renewalProposalFilter,
      tenantFilter,
      serviceFilter,
      customerFilter,
      endDateFrom,
      endDateTo
    ) {
      this.goBack();
      this.allClear = allClear;
      if (serviceFilter.length == 0) {
        serviceFilter = null;
      }
      this.filter_criteria = {
        customer_ids: customerFilter,
        customer_type: customerTypeFilter,
        status: statusFilter,
        having_agreement_of_type: serviceFilter,
        tenant_id: tenantFilter,
        renewal_proposal: renewalProposalFilter,
        end_date_from: endDateFrom,
        end_date_to: endDateTo
      };
      await this.fetchData();
      this.scrollToTop();
    },
    scrollToTop() {
      this.$vuetify.goTo(0);
    }
  },
  mixins: [FormsHelper, Enums, SharedMixin],
  async created() {
    this.mutex = new Mutex();
  },
  computed
};
</script>
