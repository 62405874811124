<template>
  <v-navigation-drawer
    class="filter-drawer"
    v-model="drawer"
    v-show="drawer"
    absolute
    temporary
  >
    <v-toolbar color="primary" dark>
      <v-toolbar-title>{{ $t("filters.filters") }}</v-toolbar-title>
    </v-toolbar>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.withActiveAgreements") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="false"
          matchAttribute="value"
          v-model="agreementTypesAll"
          :alwaysShowAll="true"
          @change="getAllFiltersSelected"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.customerType") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="false"
          matchAttribute="value"
          v-model="customerTypesAll"
          :alwaysShowAll="true"
          @change="getAllFiltersSelected"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.createdFrom") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <datetime v-model="dateFrom" class="dateComponent"></datetime>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ $t("filters.createdTo") }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <datetime v-model="dateTo" class="dateComponent"></datetime>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ $t("filters.tenants") }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="false"
          matchAttribute="value"
          v-model="tenantsAll"
          :alwaysShowAll="true"
          @change="getAllFiltersSelected"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ $t("filters.services") }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="true"
          matchAttribute="value"
          v-model="servicesAll"
          :alwaysShowAll="false"
          @change="getAllFiltersSelected"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-col align="right">
      <v-btn color="primary" small outlined @click="clearFilters">
        {{ $t("buttons.clear") }}
      </v-btn>
    </v-col>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { HelperService } from "@/services/helper.service";
import _ from "lodash";
import FilterList from "@/components/Shared/FilterList";
import SharedMixin from "@/mixins/shared";

export default {
  mixins: [SharedMixin],
  components: {
    FilterList
  },
  data() {
    return {
      drawer: false,
      agreementTypesAll: [
        { name: "Tutti", checked: false, enabled: false, all: true },
        { name: "SI", value: "SI", checked: false, enabled: false },
        { name: "NO", value: "NO", checked: false, enabled: false }
      ],
      customerTypesAll: [],
      dateFrom: null,
      dateFormattedFrom: null,
      dateTo: null,
      dateFormattedTo: null,
      tenantsAll: [],
      servicesAll: [],
      showServicesAll: false,
      showButton: false
    };
  },
  methods: {
    ...mapActions("customerType", ["ajaxCustomerTypeFetchAll"]),
    ...mapActions("tenant", ["ajaxTenantFetchAll"]),
    ...mapActions("service", ["ajaxServiceFetchAll"]),
    ...mapMutations("filters", ["setCustomerFilter", "setCustomerFlag"]),

    save() {
      this.setCustomerFilter({
        agreementTypesAll: this.agreementTypesAll,
        customerTypesAll: this.customerTypesAll,
        dateFrom: this.dateFrom,
        dateFormattedFrom: this.dateFormattedFrom,
        dateTo: this.dateFrom,
        dateFormattedTo: this.dateFormattedFrom,
        tenantsAll: this.tenantsAll,
        servicesAll: this.servicesAll
      });
    },
    load() {
      if (!this.customer_flag) return false;
      this.setCustomerFlag(false);
      if (_.isEmpty(this.customer_filter)) return false;
      this.agreementTypesAll = this.customer_filter.agreementTypesAll;
      this.customerTypesAll = this.customer_filter.customerTypesAll;
      this.dateFrom = this.customer_filter.dateFrom;
      this.dateFormattedFrom = this.customer_filter.dateFormattedFrom;
      this.dateTo = this.customer_filter.dateTo;
      this.dateFormattedTo = this.customer_filter.dateFormattedTo;
      this.tenantsAll = this.customer_filter.tenantsAll;
      this.servicesAll = this.customer_filter.servicesAll;
      return true;
    },

    open() {
      this.drawer = true;
    },

    formatDate(date) {
      if (!date) {
        return null;
      } else {
        return date.substring(0, date.indexOf("T"));
      }
    },

    //funzioni get filtro
    async getCustomerTypes() {
      let customerTypesArray = this.customerTypesAll;
      await this.ajaxCustomerTypeFetchAll();
      if (this.customerTypes) {
        this.customerTypes.forEach(function(customerType) {
          customerTypesArray.push({
            name: customerType.name,
            value: customerType.id,
            checked: false,
            enabled: false
          });
        });
      }
      customerTypesArray.unshift({
        name: this.$t("filters.all"),
        checked: false,
        enabled: false,
        all: true
      });
      this.customerTypesAll = customerTypesArray;
    },
    async getTenants() {
      let tenantsArray = this.tenantsAll;
      await this.ajaxTenantFetchAll();
      if (this.tenants) {
        this.tenants.forEach(function(tenant) {
          tenantsArray.push({
            value: tenant.id,
            name: tenant.name,
            checked: false,
            enabled: false
          });
        });
      }
      this.tenantsAll = tenantsArray;
    },
    async getServices() {
      let servicesArray = [];
      let payloadFAP = HelperService.buildFilterAndPagination({
        pagination: {
          itemsPerPage: 100
        }
      });

      await this.ajaxServiceFetchAll(payloadFAP);
      if (this.services) {
        this.services.forEach(function(service) {
          servicesArray.push({
            value: service.machine_name,
            name: service.machine_name,
            checked: false,
            enabled: false
          });
        });
      }
      servicesArray = _.uniqBy(servicesArray, "value");
      this.servicesAll = _.orderBy(servicesArray, "name");
      if (this.servicesAll.length > 5) {
        this.showServicesAll = true;
      }
    },
    //get filtri selezionati
    getAllFiltersSelected(dateFrom = null, dateTo = null) {
      let agreementFilter = this.computeFilter(this.agreementTypesAll);
      let customerTypeFilter = this.computeFilter(this.customerTypesAll);
      let dateFromFilter = dateFrom;
      let dateToFilter = dateTo;
      let tenantFilter = this.computeFilter(this.tenantsAll);
      //let serviceFilter = this.computeFilter(this.servicesAll);
      let serviceFilter = this.computeFilterMultiple(
        this.servicesAll,
        "value"
      );

      let allClear = this.computeClear([
        agreementFilter,
        customerTypeFilter,
        dateFromFilter,
        dateToFilter,
        tenantFilter,
        serviceFilter
      ]);

      this.save();
      this.$emit(
        "filtersSelected",
        allClear,
        agreementFilter,
        customerTypeFilter,
        dateFromFilter,
        dateToFilter,
        tenantFilter,
        serviceFilter
      );
    },
    //svuota filtri
    clearFilters() {
      let agreementFilter = "";
      let customerTypeFilter = "";
      let dateFromFilter = null;
      this.dateFrom = "";
      let dateToFilter = null;
      this.dateTo = "";
      let tenantFilter = null;
      let serviceFilter = [];

      this.agreementFilter = this.resetFilter(this.agreementTypesAll);
      this.customerTypeFilter = this.resetFilter(this.customerTypesAll);
      this.tenantFilter = this.resetFilter(this.tenantsAll);
      this.serviceFilter = this.resetFilter(this.servicesAll);

      this.save();
      this.$emit(
        "filtersSelected",
        true,
        agreementFilter,
        customerTypeFilter,
        dateFromFilter,
        dateToFilter,
        tenantFilter,
        serviceFilter
      );
    }
  },
  filters: {},
  computed: {
    ...mapGetters("customerType", ["customerTypes"]),
    ...mapGetters("tenant", ["tenants"]),
    ...mapGetters("service", ["services"]),
    ...mapGetters("filters", ["customer_filter", "customer_flag"])
  },
  props: [],
  watch: {
    dateFrom(val) {
      if (val != "") {
        this.dateFormattedFrom = this.formatDate(val);
        this.getAllFiltersSelected(this.dateFormattedFrom);
      }
    },
    dateTo(val) {
      if (val != "") {
        this.dateFormattedTo = this.formatDate(val);
        this.getAllFiltersSelected(
          this.dateFormattedFrom,
          this.dateFormattedTo
        );
      }
    },
    drawer(val) {
      this.$emit("drawerChanged", val);
    }
  },
  async mounted() {
    if (!this.load()) {
      await this.getCustomerTypes();
      await this.getTenants();
      await this.getServices();
    }
    this.getAllFiltersSelected();
  }
};
</script>
<style scoped>
.dateComponent {
  border: 1px solid grey;
  margin-left: 15px;
  margin-right: 120px;
}
/*
.filter-drawer {
  font-size: smaller;
}

.filter-drawer div,
.paginator span {
  font-size: inherit;
  font-style: italic;
}*/
</style>
