import _ from "lodash";

import moment from "moment-timezone";
import rollbar from "@/rollbar";

const HelperService = {
  difference(object, base) {
    function changes(object, base) {
      return _.transform(object, function(result, value, key) {
        if (!_.isEqual(String(value), String(base[key]))) {
          result[key] =
            _.isObject(value) && _.isObject(base[key])
              ? changes(value, base[key])
              : value;
        }
      });
    }
    return changes(object, base);
  },
  getDiffHours(activity) {
    const start_date = activity.start_date
      ? new Date(activity.start_date)
      : undefined;
    const end_date = activity.end_date
      ? new Date(activity.end_date)
      : undefined;
    let diff_hours =
      start_date && end_date ? (end_date - start_date) / (1000 * 60 * 60) : 0;
    return diff_hours;
  },
  agreementServiceChangeStartDate(item, newDate) {
    //if (!item.metadata.start_date)
    item.metadata.start_date = moment(newDate).format("YYYY-MM-DD");
  },
  prepareAgreementServiceMetadata(instance, item) {
    if (!instance.services) return;
    const service = instance.services.filter(
      serviceItem => serviceItem.id == item.service_id
    );
    let baseMetadata = {
      amount: ""
    };
    if (service && service.length > 0) {
      baseMetadata.amount = service[0].amount || 0;
      if (service[0].machine_name === "GDPR") {
        baseMetadata.comunicazione_garante = "TO_DO";
        baseMetadata.nomina = "TO_DO";
        baseMetadata.pagina_pubblica = "NOT_EXIST";
      }
      if (service[0].machine_name === "ASSISTENZA_TECNICA") {
        baseMetadata.total_hours = "";
      }
    }
    let currentMetadata = null;
    if (typeof item.metadata == "object") {
      currentMetadata = item.metadata;
    } else {
      currentMetadata = JSON.parse(item.metadata);
    }
    if ("worked_hours" in currentMetadata) {
      delete currentMetadata.worked_hours;
    }
    if ("extra_hours" in currentMetadata) {
      delete currentMetadata.extra_hours;
    }
    let newMeta = _.merge(baseMetadata, currentMetadata);
    if (newMeta.start_date)
      newMeta.start_date = moment(newMeta.start_date).format("YYYY-MM-DD");
    item.metadata = newMeta;
  },
  getServicesToAgreement(item) {
    let name_services = "";
    if (item && item.services && item.services.length > 0) {
      for (let i = 0; i < item.services.length; i++) {
        name_services = name_services + `${item.services[i].machine_name}`;
        if (i !== item.services.length - 1) {
          name_services = name_services + ` - `;
        }
      }
    }
    return name_services;
  },
  prepareAgreementMetadata(item) {
    let data = { total_hours: "", worked_hours: "", extra_hours: "" };
    let total_hours = 0;
    let worked_hours = 0;
    let extra_hours = 0;

    if (!item || !item.agreementServices) {
      return data;
    } else {
      let agreements_services = item.agreementServices;
      // item.agreementServices.filter(value => {
      //   const metadata = value.metadata ? JSON.parse(value.metadata) : null;
      //   return metadata && metadata.total_hours;
      // });
      // item => item.machine_name === 'ASSISTENZA_TECNICA',
      if (agreements_services && agreements_services.length > 0) {
        for (let i = 0; i < agreements_services.length; i++) {
          const agreement_service = agreements_services[i];
          if (agreement_service.metadata) {
            let metadata = (agreement_service.metadata)
              ? agreement_service.metadata
              : null;
            metadata = (metadata && typeof metadata !== 'object') ? JSON.parse(metadata) : metadata;
            if (metadata.total_hours) {
              total_hours = total_hours + parseInt(metadata.total_hours);
            }
            if (metadata.extra_hours) {
              extra_hours = extra_hours + parseFloat(metadata.extra_hours);
            }
            if (metadata.worked_hours) {
              worked_hours = worked_hours + parseFloat(metadata.worked_hours);
            }
          }
        }
        data.total_hours = total_hours;
        data.worked_hours = this.convertHoursToTime(worked_hours);
        data.extra_hours = this.convertHoursToTime(extra_hours);
      }
    }
    return data;
  },
  getTotalAmount(item) {
    let totalAmount = 0;
    let agreementMetadata = item.metadata;
    if (item.agreementServices) {
      for (let i = 0; i < item.agreementServices.length; i++) {
        const agreementService = item.agreementServices[i];
        let metadata = agreementService.metadata;
        if (typeof metadata !== "object") metadata = JSON.parse(metadata);
        totalAmount = totalAmount + parseInt(metadata.amount);
      }
    }
    if (totalAmount == 0 && agreementMetadata.amount)
      totalAmount = agreementMetadata.amount;
    return String(totalAmount);
  },
  unformatAgreement(instance) {
    delete instance.has_gdpr;
    delete instance.code;

    if ("set_not_renewed" in instance) {
      delete instance.set_not_renewed;
      return;
    }

    if ("set_interrupted" in instance) {
      delete instance.set_interrupted;
      return;
    }    

    instance.metadata.amount = 0;

    if ("tenant" in instance.customer)
      instance.tenant_id = instance.customer.tenant.id;
    else instance.tenant_id = instance.tenant.id;
    if ("tenant" in instance) delete instance.tenant;

    instance.customer_id = instance.customer.id;
    delete instance.customer;

    let agreements_services = [];
    for (let i = 0; i < instance.agreementServices.length; i++) {
      //let metadata = instance.services[i].metadata;
      //metadata.amount = Number(metadata.amount);
      instance.metadata.amount += Number(
        instance.agreementServices[i].metadata.amount
      );
      const newAgreementService = {
        id: "renewal" in instance ? null : instance.agreementServices[i].id,
        agreement_id: instance.agreementServices[i].agreement_id,
        service_id: instance.agreementServices[i].service_id,
        metadata: JSON.stringify(instance.agreementServices[i].metadata)
      };
      delete newAgreementService.worked_hours;
      delete newAgreementService.extra_hours;
      agreements_services.push(newAgreementService);
    }
    instance.agreements_services = agreements_services;
    delete instance.agreementServices;
    delete instance.service;
    delete instance.services;

    instance.invoice_data = JSON.stringify(instance.invoice_data);

    instance.metadata = JSON.stringify(instance.metadata);

    instance.start_date = moment(instance.start_date).format(
      "YYYY-MM-DD hh:mm:ss"
    );
    if (instance.infinite_deadline) delete instance.end_date;
    else if (!instance.end_date) {
      delete instance.end_date;
      instance.infinite_deadline = true;
    } else
      instance.end_date = moment(instance.end_date).format(
        "YYYY-MM-DD hh:mm:ss"
      );

    delete instance.infinite_deadline;
    delete instance.total_amount;
    delete instance.total_hours;
    delete instance.worked_hours;
    delete instance.extra_hours;
    delete instance.last_email_sent_date;
    delete instance.email_sent_count;
  },
  unformatGenerationSession(instance) {
    instance.metadata = {};
    instance.metadata.allegato = instance.allegato;
    instance.metadata.customers = instance.customers;
    instance.metadata.templates = instance.templates;
    //instance.metadata=JSON.stringify(instance.metadata);
    delete instance.allegato;
    delete instance.customers;
    delete instance.templates;

    if (instance.agreement_id) {
      instance.metadata.agreement_id = instance.agreement_id;
      delete instance.agreement_id;
    }
  },
  unformatTicket(instance) {
    if (instance.ticket_type == "-1") delete instance.ticket_type;
    if (instance.ticket_subtype == "-1") delete instance.ticket_subtype;
    if (instance.model_id == "-1") delete instance.model_id;

    if ("change_status" in instance) {
      delete instance.change_status;
      return;
    }
    delete instance.completed_at;
    delete instance.billed_total_hours;
    delete instance.code;
    delete instance.created_at;
    instance.customer_id = instance.customer.id;
    if (instance.id == "" || instance.id == null)
      instance.tenant_id = instance.customer.tenant_id;
    delete instance.customer;
    delete instance.internal_state;
    delete instance.state;
    if (instance.id != "" && instance.id != null) {
      delete instance.ticket_type;
      delete instance.operator_ids;
    }
    delete instance.total_hours;
    instance.user_id = instance.user.id;
    delete instance.user;
    instance.operator_ids = _.map(instance.users, "id");
    delete instance.users;
    //instance.tenant_id=instance.tenant.id;
    delete instance.tenant;
    //instance.agreement_id=null;
    //instance.note="";
    if (instance.due_date)
      instance.due_date = moment(instance.due_date).format("YYYY-MM-DD hh:mm");
    else delete instance.due_date;
    //delete instance.additional_costs;
    delete instance.end_date;
    //delete instance.final_note;

  },
  unformatUser(instance) {
    if (instance.id) {
      if (instance.password == "") delete instance.password;
    } else delete instance.id;

    if (instance.role) {
      instance.role_id = instance.role.id;
      delete instance.role;

      instance.tenant_ids = _.map(instance.tenant, "id");
      delete instance.tenant;
    }
    if (instance.customer) {
      instance.customer_id = instance.customer.id;
      delete instance.customer;
    }
  },
  localToJSONAPIPagination(payload) {
    const _search = _.isNull(payload) ? "" : _.get(payload, "search", "");
    const _filter = _.isNull(payload) ? "" : _.get(payload, "filter", "");
    const _filter_criteria = _.isNull(payload)
      ? ""
      : _.get(payload, "filter_criteria", "");
    //const _page = _.isNull(payload) ? process.env.VUE_APP_DEFAULT_PAGE : _.get(_pagination, "page", process.env.VUE_APP_DEFAULT_PAGE);
    //const _pageSize = _.isNull(payload) ? process.env.VUE_APP_DEFAULT_PAGE_SIZE : _.get(_pagination, "itemsPerPage", process.env.VUE_APP_DEFAULT_PAGE_SIZE);
    const _pagination = _.get(payload, "pagination");
    let _page = process.env.VUE_APP_DEFAULT_PAGE;
    if (_pagination && _pagination.page) {
      _page = _pagination.page;
    }
    let _pageSize = process.env.VUE_APP_DEFAULT_PAGE_SIZE;
    if (_pagination && _pagination.itemsPerPage) {
      _pageSize = _pagination.itemsPerPage;
    }
    let _sortBy = "";
    if (_pagination && _pagination.sortBy) {
      _sortBy = _.last(_pagination.sortBy);
    }
    let _sortDescending = "";
    if (_pagination && _pagination.sortDesc) {
      _sortDescending = _.last(_pagination.sortDesc);
    }
    let _finalSort = "";
    if (_sortBy && _sortDescending) {
      _finalSort = _sortBy;
    } else {
      _finalSort = `-${_sortBy}`;
    }

    const searchFilterPagination = {
      search: _search,
      filter: _filter,
      filter_criteria: _filter_criteria,
      page: {
        page: _page,
        pageSize: _pageSize
      },
      sort: _finalSort
    };
    return searchFilterPagination;
  },
  buildFilterAndPagination(options) {
    options = options || {};
    return {
      search: options.search || "",
      filter: options.filter || "",
      pagination: options.pagination || "",
      filter_criteria: options.filter_criteria || "",
      headers_query: options.headers_query,
      optimize: options.optimize
    };
  },
  convertTimeToHours(time) {
    let hours = time.split(":")[0];
    let minute = time.split(":")[1];
    let billed_hours = _.reduce([Number(_.round(minute/60, 2)), Number(hours)], function(sum, n) {
      return sum + n;
    }, 0);
    return billed_hours;
  },
  convertHoursToTime(time) {
    let hours = Math.floor(time);
    let minutes = _.round(_.subtract(Number(time), Number(hours)) * 60);
    return hours + ":" + minutes;
  },
  // Allow pagination for included resources using based on the include array
  // For example sort: "customer" will be converted in sort: "customer_id"
  fixIncludedResourceSorting(payload) {
    let sortBy = payload.sort;
    const includes = payload.include;
    let newSortBy = sortBy;
    let withMinus = false;
    if (sortBy.charAt(0) === "-") {
      sortBy = sortBy.slice(1);
      withMinus = true;
    }
    if (sortBy.length > 0 && includes.includes(sortBy)) {
      newSortBy = (sortBy + "_id").toString();
      if (withMinus == true) {
        newSortBy = "-" + newSortBy;
      }
    }
    return newSortBy;
  },
  async genericCreateOrUpdate(commit, createOrUpdateFunc, payload) {
    //commit("setLoading", true);
    try {
      let res = await createOrUpdateFunc(payload);
      //commit("setLoading", false);
      if (res && res.error) {
        rollbar.error(res.error);
        if (Array.isArray(res.error))
          return { error: res.error[0].code };
        return { error: res.error };
      }
      return res;
    } catch (error) {
      //commit("setLoading", false);
      rollbar.error(error);
      console.log(error);
      return { error };
    }
  },
  async genericDelete(commit, deleteFunc, id) {
    //commit("setLoading", true);
    try {
      let res = await deleteFunc(id);
      //commit("setLoading", false);
      if (res && res.error) {
        rollbar.error(res.error);
        if (Array.isArray(res.error))
          return { error: "Error: " + res.error[0].code };
        return { error: res.error };
      }
      return res;
    } catch (error) {
      //commit("setLoading", false);
      rollbar.error(error);
      console.log(error);
      return { error };
    }
  },
  async genericFetchAll(
    commit,
    fetchFunc,
    searchFilterPagination,
    headers = null,
    formatFunc = null
  ) {
    commit("setLoading", true);
    try {
      const res = await fetchFunc(searchFilterPagination, headers);
      if (res && res.error) {
        rollbar.error(res.error);
        commit("setList", []);
        commit("setTotal", 0);
        return;
      }
      if (formatFunc) {
        res.data.forEach(function(instance) {
          formatFunc(instance);
        });
      }
      commit("setList", res.data);
      commit("setTotal", res.meta.pagination.rowCount);
    } catch (error) {
      rollbar.error(error);
      console.log(error);
    } finally {
      commit("setLoading", false);
    }
  },
  async genericSetSelected(commit, fetchFunc, id, formatFunc = null) {
    //commit("setLoading", true);
    try {
      const res = await fetchFunc(id);
      if (res && res.error) {
        rollbar.error(res.error);
        return;
      }
      if (formatFunc) formatFunc(res);
      commit("setSelected", res);
    } catch (error) {
      rollbar.error(error);
      console.log(error);
    } finally {
      //commit("setLoading", false);
    }
  },
  async tempFetchAll(
    commit,
    fetchFunc,
    searchFilterPagination,
    headers = null,
    formatFunc = null
  ) {
    //commit("setLoading", true);
    try {
      const res = await fetchFunc(searchFilterPagination, headers);
      if (res && res.error) {
        rollbar.error(res.error);
        return;
      }
      if (formatFunc) {
        res.data.forEach(function(instance) {
          formatFunc(instance);
        });
      }
      commit("setTemp", res.data);
    } catch (error) {
      rollbar.error(error);
      console.log(error);
    } finally {
      //commit("setLoading", false);
    }
  },
  async tempSetSelected(commit, fetchFunc, id, formatFunc = null) {
    //commit("setLoading", true);
    try {
      const res = await fetchFunc(id);
      if (res && res.error) {
        rollbar.error(res.error);
        return;
      }
      if (formatFunc) formatFunc(res);
      commit("setTemp", res);
    } catch (error) {
      rollbar.error(error);
      console.log(error);
    } finally {
      //commit("setLoading", false);
    }
  }
};
export { HelperService };
