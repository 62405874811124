var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "grey lighten-4 title", attrs: { fluid: "" } },
    [
      _vm.mode == _vm.Enums.FORM_MODE.CREATE ||
      _vm.mode == _vm.Enums.FORM_MODE.UPDATE
        ? _c(
            "div",
            [
              _c("v-card-title", { staticClass: "form-title title" }, [
                _vm._v(_vm._s(_vm._f("capitalize")(this.formTitle)))
              ]),
              _c(
                "v-form",
                {
                  staticClass: "form-body",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.onSubmit($event)
                    }
                  }
                },
                [
                  _c("FileUploader", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.immediate",
                        value: "required",
                        expression: "'required'",
                        modifiers: { immediate: true }
                      }
                    ],
                    attrs: {
                      fileTypeId: this.initial_file_type_id,
                      permittedTypes: this.permittedTypes,
                      name: "fileUpload",
                      mode: _vm.mode,
                      machineName: _vm.machineName
                    },
                    model: {
                      value: _vm.attachment,
                      callback: function($$v) {
                        _vm.attachment = $$v
                      },
                      expression: "attachment"
                    }
                  }),
                  _vm.veeErrors.has("fileUpload")
                    ? _c(
                        "span",
                        {
                          class:
                            _vm.Settings.style.class.formFieldRequiredCaption
                        },
                        [_vm._v(_vm._s(_vm.veeErrors.first("fileUpload")))]
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.withDescription === "true",
                          expression: "withDescription === 'true'"
                        }
                      ]
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("forms.links.description"),
                          name: "description"
                        },
                        model: {
                          value: _vm.form.detail,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "detail", $$v)
                          },
                          expression: "form.detail"
                        }
                      })
                    ],
                    1
                  ),
                  _c("FormButtons", {
                    attrs: { disabled: _vm.veeErrors.any() },
                    on: { onSave: _vm.onSubmit, onCancel: _vm.onCancel }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.mode === _vm.Enums.FORM_MODE.SHOW,
            expression: "mode === Enums.FORM_MODE.SHOW"
          }
        ]
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }