var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "form-container title", attrs: { fluid: "" } },
    [
      _vm.mode == _vm.Enums.FORM_MODE.CREATE ||
      _vm.mode == _vm.Enums.FORM_MODE.UPDATE
        ? _c(
            "div",
            [
              _c("v-card-title", { staticClass: "form-title title" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("capitalize")(this.formTitle)) +
                    "\n    "
                )
              ]),
              _c(
                "v-form",
                {
                  staticClass: "form-body",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.onSubmit($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.immediate",
                            value: "required",
                            expression: "'required'",
                            modifiers: { immediate: true }
                          }
                        ],
                        attrs: {
                          label: _vm.$t("forms.tenants.name"),
                          name: "name"
                        },
                        model: {
                          value: _vm.form.name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name"
                        }
                      }),
                      _vm.veeErrors.has("name")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [_vm._v(_vm._s(_vm.veeErrors.first("name")))]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.immediate",
                            value: "required|email",
                            expression: "'required|email'",
                            modifiers: { immediate: true }
                          }
                        ],
                        attrs: {
                          label: _vm.$t("forms.tenants.email"),
                          name: "email"
                        },
                        model: {
                          value: _vm.form.email,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email"
                        }
                      }),
                      _vm.veeErrors.has("email")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [_vm._v(_vm._s(_vm.veeErrors.first("email")))]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.immediate",
                            value: "required",
                            expression: "'required'",
                            modifiers: { immediate: true }
                          }
                        ],
                        attrs: {
                          label: _vm.$t("forms.tenants.domain"),
                          name: "domain"
                        },
                        model: {
                          value: _vm.form.domain,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "domain", $$v)
                          },
                          expression: "form.domain"
                        }
                      }),
                      _vm.veeErrors.has("domain")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [_vm._v(_vm._s(_vm.veeErrors.first("domain")))]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.immediate",
                            value: "required|email",
                            expression: "'required|email'",
                            modifiers: { immediate: true }
                          }
                        ],
                        attrs: {
                          label: _vm.$t("forms.tenants.accountingEmails"),
                          name: "accountingEmails"
                        },
                        model: {
                          value: _vm.form.accounting_emails,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "accounting_emails", $$v)
                          },
                          expression: "form.accounting_emails"
                        }
                      }),
                      _vm.veeErrors.has("accountingEmails")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.veeErrors.first("accountingEmails"))
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("forms.tenants.address"),
                          name: "address"
                        },
                        model: {
                          value: _vm.form.address,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "address", $$v)
                          },
                          expression: "form.address"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("forms.tenants.phone"),
                          type: "tel",
                          name: "phone"
                        },
                        model: {
                          value: _vm.form.phone,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "phone", $$v)
                          },
                          expression: "form.phone"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.immediate",
                            value: "required",
                            expression: "'required'",
                            modifiers: { immediate: true }
                          }
                        ],
                        attrs: {
                          label: _vm.$t("forms.tenants.website"),
                          name: "website"
                        },
                        model: {
                          value: _vm.form.website,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "website", $$v)
                          },
                          expression: "form.website"
                        }
                      }),
                      _vm.veeErrors.has("website")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [_vm._v(_vm._s(_vm.veeErrors.first("website")))]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("FormButtons", {
                    attrs: { disabled: _vm.veeErrors.any() },
                    on: { onSave: _vm.onSubmit, onCancel: _vm.onCancel }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.mode === _vm.Enums.FORM_MODE.SHOW,
              expression: "mode === Enums.FORM_MODE.SHOW"
            }
          ],
          staticClass: "form-body-show"
        },
        [
          _c("DisplayKV", {
            attrs: {
              label: _vm.$t("forms.tenants.name"),
              value: this.form.name
            }
          }),
          _c("DisplayKV", {
            attrs: {
              label: _vm.$t("forms.tenants.email"),
              value: this.form.email
            }
          }),
          _c("DisplayKV", {
            attrs: {
              label: _vm.$t("forms.tenants.domain"),
              value: this.form.domain
            }
          }),
          _c("DisplayKV", {
            attrs: {
              label: _vm.$t("forms.tenants.accountingEmails"),
              value: this.form.accounting_emails
            }
          }),
          _c("DisplayKV", {
            attrs: {
              label: _vm.$t("forms.tenants.address"),
              value: this.form.address
            }
          }),
          _c("DisplayKV", {
            attrs: {
              label: _vm.$t("forms.tenants.phone"),
              value: this.form.phone
            }
          }),
          _c("DisplayKV", {
            attrs: {
              label: _vm.$t("forms.tenants.website"),
              value: this.form.website
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }