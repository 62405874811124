<template>
  <v-container fluid>
    <ToolbarDetail
      :title="detailTitle | upperCase"
      :withEdit="true"
      @onEdit="openFormDialog(Enums.FORM_MODE.UPDATE)"
    ></ToolbarDetail>

    <template v-if="loaded">
      <v-tabs
        class="my-4"
        v-model="activeTab"
        background-color="secondary"
        dark
        slider-color="accent"
        @change="syncUrl"
      >
        <v-tab name="details">{{ $t("labels.details") }}</v-tab>
        <v-tab name="services">{{ $t("labels.services") }}</v-tab>
        <v-tab name="attachments">{{ $t("labels.attachments") }}</v-tab>
        <v-tab name="templates">{{ $t("labels.templates") }}</v-tab>
        <v-tab name="links">{{ $t("labels.links") }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <v-card flat>
            <TenantForm
              :mode="Enums.FORM_MODE.SHOW"
              :selectedItem="selectedTenant"
            ></TenantForm>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <ServicesList
              :listMode="Enums.LIST_MODE.TABBED"
              :withModelId="this.selectedTenant.id"
            ></ServicesList>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <AttachmentsList
              withModelType="tenants"
              :withModelId="selectedTenant.id"
              :listMode="Enums.LIST_MODE.TABBED"
              :machineName="Enums.MACHINE_NAME_DOCUMENT_TYPE.GENERIC_FILES"
            ></AttachmentsList>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <TemplatesList
              withModelType="tenants"
              :withModelId="selectedTenant.id"
              :listMode="Enums.LIST_MODE.TABBED"
              :machineName="Enums.MACHINE_NAME_TEMPLATE_TYPE.TEMPLATE"
            ></TemplatesList>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <LinksList
              withModelType="tenant"
              :withModelId="this.selectedTenant.id"
              :listMode="Enums.LIST_MODE.TABBED"
            ></LinksList>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <v-dialog v-model="formDialog" persistent content-class="edit-form-dialog">
      <TenantForm
        v-if="formDialog"
        :mode="formMode"
        :selectedItem="this.preparedSelected()"
        @formSucceed="handleFormSucceed"
        @formCancel="handleFormCancel"
      ></TenantForm>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "lodash";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { mapActions, mapGetters } from "vuex";
import FormsHelper from "@/mixins/formsHelper";
import TenantForm from "@/components/Tenant/TenantForm";
import ServicesList from "@/views/ServicesList";
import AttachmentsList from "@/components/Attachment/AttachmentsList";
import TemplatesList from "@/components/Attachment/TemplatesList";
import LinksList from "@/components/Link/LinksList";
import ToolbarDetail from "@/components/Shared/ToolbarDetail";

export default {
  name: "TenantDetails",
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      resourceType: this.$t("resource_types.tenant"),
      activeTab: null,
      loaded: false
    };
  },
  components: {
    TenantForm,
    ServicesList,
    LinksList,
    AttachmentsList,
    ToolbarDetail,
    TemplatesList
  },
  mixins: [SharedMixin, Enums, FormsHelper],
  watch: {
    id() {
      this.initComponent();
    }
  },
  methods: {
    ...mapActions("tenant", ["ajaxTenantSetSelected"]),
    async initComponent() {
      await this.ajaxTenantSetSelected({ id: this.$props.id });
      this.loaded = true;
    },
    syncUrl() {},
    handleFormSucceed() {
      this.closeFormDialog();
      this.ajaxTenantSetSelected({ id: this.$props.id });
    },
    handleFormCancel() {
      this.closeFormDialog();
    },
    preparedSelected() {
      return _.clone(this.selectedTenant);
    }
  },
  computed: {
    ...mapGetters("tenant", [
      "tenants",
      "selectedTenant",
      "total",
      "onLoading"
    ]),
    Enums() {
      return Enums;
    },
    tenantURL() {
      const r = this.$router.resolve({
        name: "tenants",
        params: {}
      });
      return r.href;
    },
    detailTitle() {
      return this.$t("labels.detailResourceTypeName", {
        resourceType: this.resourceType,
        resourceName: this.selectedTenant.name
      });
    }
  },
  created() {
    this.initComponent();
  }
};
</script>
