/*  eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: ["error", { "props": false }] */
import Vue from "vue";
import Vuex from "vuex";
import { AgreementServiceService } from "@/services/agreementService.service";
import { HelperService } from "@/services/helper.service";

Vue.use(Vuex);
const state = {
  agreements_services: [],
  agreements_servicesTemp: [],
  selected: {},
  loading: false,
  totalCount: 0
};

const getters = {
  agreements_services: state => state.agreements_services,
  agreements_servicesTemp: state => state.agreements_servicesTemp,
  onLoading: state => state.loading,
  selectedAgreementService: state => state.selected
};

const mutations = {
  setSelected(state, sel) {
    state.selected = sel;
  },
  setList(state, sel) {
    state.agreements_services = sel;
  },
  setTemp(state, sel) {
    state.agreements_servicesTemp = sel;
  },
  setLoading(state, value) {
    state.loading = value;
  },
  setTotal(state, sel) {
    state.totalCount = Number(sel);
  }
};

const actions = {
  async ajaxAgreementServiceSetSelected({ commit }, payload) {
    await HelperService.genericSetSelected(
      commit,
      AgreementServiceService.fetchOne,
      payload.id
    );
  },

  async ajaxAgreementServiceFetchAll({ commit }, payload) {
    let searchFilterPagination = HelperService.localToJSONAPIPagination(
      payload
    );
    searchFilterPagination.include = "service,agreement";

    await HelperService.genericFetchAll(
      commit,
      AgreementServiceService.fetchAll,
      searchFilterPagination,
      null,
      null
    );
  },

  async ajaxAgreementServiceSetSelectedTemp({ commit }, payload) {
    await HelperService.tempSetSelected(
      commit,
      AgreementServiceService.fetchOne,
      payload.id
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
