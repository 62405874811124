<template>
  <v-autocomplete
    autocomplete="off"
    v-bind:value="value"
    v-on:input="onInput($event)"
    :items="getter"
    :item-text="itemText"
    :item-value="itemValue"
    :return-object="returnObject"
    :label="label"
    :search-input.sync="search"
    :disabled="disabled"
    no-filter
    clearable
  >
    <template v-slot:selection="{ item }">
      <slot name="selection" v-bind:item="item"></slot>
    </template>
    <template v-slot:item="{ item }">
      <slot name="item" v-bind:item="item"></slot>
    </template>
  </v-autocomplete>
</template>

<script>
import _ from "lodash";
import Enums from "@/mixins/enums";

export default {
  props: [
    "mode",
    "itemText",
    "itemValue",
    "returnObject",
    "label",
    "disabled",
    "value",
    "withModelId",
    "fetchFunction",
    "getter"
  ],
  data() {
    return {
      paginationOpts: {
        sortBy: [this.itemText],
        sortDesc: [false],
        page: 1,
        itemsPerPage: 25,
        multiSort: false,
        mustSort: true
      },
      search: "",
      searchComputed: "",
      filter: null
    };
  },
  computed: {
    Enums() {
      return Enums;
    }
  },
  watch: {
    search: _.debounce(async function querySearch() {
      if (this.search) {
        this.searchComputed = this.search;
        await this.fetch();
      }
    }, process.env.VUE_APP_DEBOUNCE_TIME)
  },
  methods: {
    async fetch() {
      const filterAndPagination = {
        search: this.searchComputed,
        filter: this.filter
      };
      await this.fetchFunction(filterAndPagination);
    },
    onInput(val) {
      this.$emit("input", val);
    }
  },
  async created() {
    if (this.withModelId) this.filter = { id: this.withModelId };
    else if (
      this.value &&
      (!this.returnObject || this.itemValue in this.value)
    ) {
      if (this.returnObject) this.filter = { id: this.value[this.itemValue] };
      else this.filter = { id: this.value };
    }
    await this.fetch();

    if (this.withModelId && this.mode == Enums.FORM_MODE.CREATE)
      this.$emit("input", this.getter[0]);
  }
};
</script>
