var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.onlyAdd
        ? _c(
            "v-toolbar",
            { staticClass: "toolbar-94" },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    "align-center": "",
                    "justify-space-between": "",
                    "fill-heigh": ""
                  }
                },
                [
                  _c(
                    "v-flex",
                    { attrs: { md4: "" } },
                    [
                      _c("v-toolbar-title", [
                        _vm.title
                          ? _c(
                              "p",
                              { staticClass: "text-h4 text-sm-left my-1" },
                              [_vm._v(_vm._s(_vm.title))]
                            )
                          : _vm._e(),
                        _vm.subtitle
                          ? _c("p", { staticClass: "caption text-sm-left" }, [
                              _vm._v(_vm._s(_vm.subtitle))
                            ])
                          : _vm._e()
                      ])
                    ],
                    1
                  ),
                  _vm.withSearch
                    ? _c(
                        "v-flex",
                        { attrs: { md6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("misc.search"),
                              clearable: "",
                              "single-line": ""
                            },
                            on: { input: _vm.onSearchType },
                            model: {
                              value: _vm.searchText,
                              callback: function($$v) {
                                _vm.searchText = $$v
                              },
                              expression: "searchText"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-spacer"),
                  _c(
                    "v-flex",
                    { staticClass: "align-right", attrs: { md2: "" } },
                    [
                      _vm.withAdd
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mx-4",
                              attrs: { color: "accent", small: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.onAdd($event)
                                }
                              }
                            },
                            [
                              _c("v-icon", [
                                _vm._v(_vm._s(_vm.$t("icons.add")))
                              ]),
                              _vm._v(
                                "\n           \n          " +
                                  _vm._s(_vm.$t("buttons.create")) +
                                  "\n        "
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-toolbar",
            { staticClass: "toolbar-94 mt-15", attrs: { Floating: "" } },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    "align-center": "",
                    "justify-space-between": "",
                    "fill-heigh": ""
                  }
                },
                [
                  _c(
                    "v-flex",
                    { staticClass: "align-left", attrs: { md2: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-4",
                          attrs: { color: "accent", normal: "" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.onAdd($event)
                            }
                          }
                        },
                        [
                          _c("v-icon", [_vm._v(_vm._s(_vm.$t("icons.add")))]),
                          _vm._v("\n           \n          "),
                          _c("span", [_vm._v("Crea nuova procedura")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-flex",
                    { attrs: { md4: "" } },
                    [
                      _c("v-toolbar-title", [
                        _vm.subtitle
                          ? _c("p", { staticClass: "caption text-sm-left" }, [
                              _vm._v(_vm._s(_vm.subtitle))
                            ])
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }