<template>
    <div>
        <div :class="{ 'center-panel': drawer_flag }">
            <v-card>
              <!-- v-card-title v-if="msg">
                <span class="headline" style="color: red">Il server Aruba è in manuntenzione. Al momento non è possibile caricare documenti. Ci scusiamo per il disagio.</span>
              </v-card-title -->
                <ToolbarList
                    :title="$t('labels.dashboard')" 
                ></ToolbarList>
                <template>
                    <v-row>
                        <v-col cols="12" sm="10" offset-sm="1">
                        <v-card>
                            <v-container fluid>
                            <v-row>
                                <v-col
                                    class="d-flex child-flex"
                                    cols="2"
                                >
                                    <v-card flat tile class="d-flex">
                                        <v-img 
                                            :src="require('../assets/logo_amministrazione_trasparente.jpg')" 
                                            width="70"
                                            class="grey lighten-2"
                                            @click="showPopUp"
                                            :disabled="msg"
                                        >
                                        <template v-slot:placeholder>
                                            <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                            >
                                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                            </v-row>
                                        </template>
                                        </v-img>
                                    </v-card>
                                </v-col>
                            </v-row>
                            </v-container>
                        </v-card>
                        </v-col>
                    </v-row>
                </template>

                <template>
                  <v-row justify="center">
                    <v-dialog v-model="dialog" persistent max-width="600px">
                        <v-card>
                            <v-card-title>
                            <span class="headline">{{ this.$t("labels.confirm_at_section") }}</span>
                            </v-card-title>
                            <v-card-text>
                            <v-container>
                                <v-row>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field v-model="pin" label="PIN*" hint="" required type="password" ></v-text-field>
                                </v-col>
                                </v-row>
                            </v-container>
                            </v-card-text>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog = false">{{ this.$t("dialogs.cancel") }}</v-btn>
                            <v-btn color="blue darken-1" text @click="onSubmit">{{ this.$t("dialogs.save") }}</v-btn>
                            </v-card-actions>
                        </v-card>
                        </v-dialog>
                  </v-row>
                </template>

                <template>
                <v-row justify="center">
                  <v-dialog v-model="msg" persistent max-width="700px">
                      <v-card>
                          <v-card-text>
                          <v-container>
                              <v-row>
                              <v-col cols="12">
                                <v-card-title>
                                  <span class="headline" style="color: red">Il server Aruba è in manuntenzione.</span>
                                </v-card-title>
                                <v-card-title>
                                  <span class="headline" style="color: red">Al momento non è possibile caricare documenti.</span>
                                </v-card-title>
                                <v-card-title>
                                  <span class="headline" style="color: red">Ci scusiamo per il disagio.</span>
                                </v-card-title>
                              </v-col>
                              </v-row>
                          </v-container>
                          </v-card-text>
                      </v-card>
                  </v-dialog>
                </v-row>
              </template>

            </v-card>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import FormsHelper from "@/mixins/formsHelper";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { HelperService } from "@/services/helper.service";

import ToolbarList from "@/components/Shared/ToolbarList";

const computed = {
  ...mapGetters("login", ["token", "loggedUser"]),
  ...mapGetters("agreement", ["agreements"]),
  _() {
    return _;
  },
  Enums() {
    return Enums;
  }
};

export default {
  name: "dashboard",
  components: {
    ToolbarList
  },
  props: {
  },
  data() {
    return {
      msg: false,
      drawer_flag: false,
      dialog: false,
      pin: null,
      machineName: "AMMINISTRAZIONE_TRASPARENTE",
      filter_criteria: {},
      selectedItem: null
    };
  },
  methods: {  
    ...mapActions("agreement", ["ajaxAgreementFetchAll"]),
    async initComp() {
      if (this.loggedUser && this.loggedUser.info && this.loggedUser.info.role.name == "customer") {
        // Carico il contratto attivo con servizio AT se esiste
        var customerId = this.loggedUser.info.customer_id; 
        this.filter_criteria.customer_ids = [customerId];
        this.filter_criteria.having_agreement_of_type = [this.machineName];
        this.filter_criteria.only_active_agreement = true;

        let payloadFAP = HelperService.buildFilterAndPagination({
          filter_criteria: this.filter_criteria
        });
        await this.ajaxAgreementFetchAll(payloadFAP);
        if (this.agreements.length == 1) {
          this.selectedItem = this.agreements[0];
        }
      }
    },
    showPopUp() {
      this.dialog = true;
    },
    onSubmit() {
      this.dialog = false;
      this.verifyPin();
    },
    verifyPin() {
      if (this.pin) {
        Vue.Axios({
            url: `${process.env.VUE_APP_ENDPOINT_API_URI}/private/customers/verify`,
            method: "post",
            withCredentials: true,
            headers: {
            Authorization: `Bearer ${this.token}`,
                "Content-Type": "application/json"
            },
            data: { pin: this.pin }
        })
          .then(res => {
            if (res && res.status === 200) {
              this.$router.pushCatch({ name: "amministrazione_trasparente", params: { withModelId: this.selectedItem.id } }); 
            } else {
              this.toast(Enums.TOAST_TYPE.ERROR, "", "Verifica PIN fallita. Riprova!");
            }
            this.pin = null;           
          })
          .catch(err => {
            this.pin = null;
            this.toast(Enums.TOAST_TYPE.ERROR, "", "Verifica PIN fallita. Riprova!");
          });
      } else {
        this.toast(Enums.TOAST_TYPE.ERROR, "", "Inserisci il pin per verificare la tua identità");
      }
    }
  },
  mixins: [FormsHelper, Enums, SharedMixin],
  async created() {
    await this.initComp();
  },
  computed
};
</script>

<style>
.colored {
  color: #00f;
}
</style>
