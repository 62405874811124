<template>
  <v-container fluid class="form-container title">
    <div
      v-if="mode == Enums.FORM_MODE.CREATE || mode == Enums.FORM_MODE.UPDATE"
    >
      <v-card-title class="form-title title">{{
        this.formTitle | capitalize
      }}</v-card-title>
      <v-form
        v-show="mode == Enums.FORM_MODE.CREATE"
        class="form-body"
        @submit.prevent="onSubmit"
        data-vv-scope="createOrder"
      >
        <div class="form-group required">
          <CustomerAutocomplete
            :mode="mode"
            v-model="selectedCustomer"
            itemText="name"
            itemValue="id"
            :returnObject="true"
            :label="$t('forms.orders.customer')"
            v-validate.immediate="'required'"
            @input="customerSelected"
            :fetchFunction="ajaxCustomerFetchAll"
            :getter="customers"
            name="customer"
          />
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('createOrder.customer')"
          >
            {{ veeErrors.first("createOrder.customer") }}
          </span>
        </div>
        <div class="form-group required">
          <v-text-field
            :label="$t('forms.orders.number')"
            v-model="form.number"
            name="number"
            v-validate.immediate="'required'"
            @keydown.space.prevent
          >
          </v-text-field>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('createOrder.number')"
          >
            {{ veeErrors.first("createOrder.number") }}
          </span>
        </div>
        <div class="form-group">
          <v-text-field
            :label="$t('forms.orders.details')"
            v-model="form.detail"
            v-validate.immediate="'required'"
            name="detail"
          ></v-text-field>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('createOrder.detail')"
          >
            {{ veeErrors.first("createOrder.detail") }}
          </span>
        </div>
        <div class="form-group">
          <v-select
            v-model="form.operator_ids"
            :items="users"
            :item-text="getOperatorText"
            item-value="id"
            :label="$t('forms.orders.operators')"
            multiple
            name="operators"
          ></v-select>
        </div>
        <v-container v-if="mode == Enums.FORM_MODE.CREATE">
          <FileUploader
            ref="fileuploader"
            v-model="attachment"
            v-validate.immediate="'required'"
            name="fileUpload"
            permittedTypes="ORDINE"
            :mode="mode"
          >
          </FileUploader>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('createOrder.fileUpload')"
            >{{ veeErrors.first("createOrder.fileUpload") }}</span
          >
        </v-container>
        <!-- BUTTONS -->
        <FormButtons
          @onSave="onSubmit"
          @onCancel="onCancel"
          :disabled="veeErrors.any('createOrder')"
        />
      </v-form>
      <v-form
        v-show="mode == Enums.FORM_MODE.UPDATE"
        class="form-body"
        @submit.prevent="onSubmit"
        data-vv-scope="updateOrder"
      >
        <div class="form-group required">
          <v-text-field
            :label="$t('forms.orders.number')"
            v-model="form.number"
            name="number"
            v-validate.immediate="'required'"
          >
          </v-text-field>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('updateOrder.number')"
          >
            {{ veeErrors.first("updateOrder.number") }}
          </span>
        </div>
        <div class="form-group">
          <v-text-field
            :label="$t('forms.orders.details')"
            v-model="form.detail"
            name="detail"
          ></v-text-field>
        </div>
        <!-- BUTTONS -->
        <FormButtons
          @onSave="onSubmit"
          @onCancel="onCancel"
          :disabled="veeErrors.any('updateOrder')"
        />
      </v-form>
    </div>
    <div v-show="mode === Enums.FORM_MODE.SHOW" class="form-body-show">
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.orders.id')"
        :value="this.form.id"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.orders.number')"
        :value="this.form.number"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.orders.details')"
        :value="this.form.detail"
      />
      <DisplayKV
        v-if="this.form.tickets"
        :as="Enums.DISPLAY_AS.LINK"
        :label="$t('forms.orders.ticket')"
        :value="'#' + this.form.tickets.id"
        :custom="true"
        namedRoot="ticketDetails"
        :namedRootId="this.form.tickets.id"
      />
    </div>
  </v-container>
</template>

<script>
import _ from "lodash";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { mapActions, mapGetters } from "vuex";
import DisplayKV from "@/components/Shared/DisplayKeyValue";
import CustomerAutocomplete from "@/components/Customer/CustomerAutocomplete";
import FormButtons from "@/components/Shared/FormButtons";
import FileUploader from "@/components/Attachment/FileUploader";

export default {
  name: "OrderForm",
  props: {
    mode: {
      default: Enums.FORM_MODE.CREATE,
      type: String
    },
    selectedItem: Object,
    withModelId: {
      type: String,
      required: false
    },
    withModelType: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      resourceType: this.$t("resource_types.order"),
      form: {
        id: "",
        customer_id: "",
        number: "",
        detail: "",
        tenant_id: "", //this.$props.withModelId
        operator_ids: [],
        tickets: {}
      },
      attachmentForm: {
        id: "",
        detail: "",
        name: "",
        file: "",
        temp_file: "",
        file_type_id: "",
        file_type: "",
        model_type: "orders",
        model_id: ""
      },
      attachment: null,
      customersAll: [],
      selectedCustomer: null
    };
  },
  components: { DisplayKV, FileUploader, FormButtons, CustomerAutocomplete },
  mixins: [SharedMixin, Enums],
  watch: {
    selectedItem() {
      this.initForm();
      this.initComp();
    }
  },
  methods: {
    ...mapActions("attachment", ["ajaxAttachmentCreateOrUpdate"]),
    ...mapActions("order", ["ajaxOrderCreateOrUpdate", "ajaxOrderDelete"]),
    ...mapActions("customer", ["ajaxCustomerFetchAll"]),
    ...mapActions("user", ["ajaxUserFetchAll", "ajaxUserFilterAndOrder"]),

    getOperatorText(item) {
      return `${item.first_name} ${item.last_name}`;
    },
    initForm() {
      if (this.mode == Enums.FORM_MODE.CREATE) {
        this.setForm();
      } else {
        this.setForm(this.$props.selectedItem);
      }
    },
    async initComp() {
      await this.ajaxUserFetchAll();
      await this.ajaxUserFilterAndOrder();
    },
    handleFileUpload(e) {
      this.attachment.pickedFiles = e;
    },
    async customerSelected() {
      if (this.selectedCustomer && this.form) {
        this.form.tenant_id = this.selectedCustomer.tenant_id;
        this.form.customer_id = this.selectedCustomer.id;
      }
    },
    async onSubmit() {
      const validate = await this.$validator.validateAll();
      if (validate) {
        await this.submitToStore();
      } else {
        alert(this.$t("forms.common.errorValidateFields"));
      }
    },
    async onCancel() {
      this.$emit("formCancel");
    },
    succeed() {
      this.$emit("formSucceed");
    },
    async submitToStore() {
      const payload = _.clone(this.form);
      delete payload.tickets;
      console.log('submitToStore: ', payload)
      if (this.mode == Enums.FORM_MODE.CREATE) {
        await this.createOrUpdateHelper(
          this.mode,
          this.resourceType,
          this.ajaxOrderCreateOrUpdate,
          payload,
          payload => payload.p.number,
          payload => payload.p.number,
          async res => {
            console.log('this.attachmentForm: ', this.attachmentForm)

            let attachmentPayload = _.clone(this.attachmentForm);
            console.log('attachmentPayload: ', attachmentPayload)

            attachmentPayload.file_type_id = this.attachment.file_type_id;
            attachmentPayload.name = this.attachment.payload.name;
            attachmentPayload.temp_file = this.attachment.payload.file;
            attachmentPayload.model_id = res.data.id;
            const res2 = await this.ajaxAttachmentCreateOrUpdate(
              attachmentPayload
            );
            if (res2 && res2.error) return res2.error;
            return null;
          }
        );
      } else {
        delete payload.tenant_id;
        delete payload.customer_id;
        delete payload.operator_ids;
        await this.createOrUpdateHelper(
          this.mode,
          this.resourceType,
          this.ajaxOrderCreateOrUpdate,
          payload,
          payload => payload.p.number,
          payload => payload.p.number
        );
      }
      this.succeed();
    },
    setForm(row = null) {
      if (row && !_.isEmpty(row)) {
        this.form.id = row.id;
        this.form.customer_id = row.customer_id;
        this.form.number = row.number;
        this.form.detail = row.detail;
        this.form.tenant_id = row.tenant_id;
        this.form.tickets = row.tickets;
      } else if (row == null) {
        this.form.id = "";
        this.form.customer_id = "";
        this.form.number = "";
        this.form.detail = "";
        this.form.tenant_id = ""; //this.$props.withModelId || "";
        this.attachment = null;
        this.selectedCustomer = null;
        this.form.operator_ids = [];
        this.form.tickets = {};
      }
    },
    clearFile() {
      if (this.mode == Enums.FORM_MODE.CREATE) this.$refs.fileuploader.clear();
    }
  },
  computed: {
    ...mapGetters("customer", ["customers"]),
    ...mapGetters("user", ["users"]),
    Enums() {
      return Enums;
    },
    formTitle() {
      var title = "";
      if (this.mode == Enums.FORM_MODE.CREATE) {
        title = this.$t("labels.createResourceType", {
          resourceType: this.resourceType || "-"
        });
      }
      if (this.mode == Enums.FORM_MODE.UPDATE) {
        title = this.$t("labels.updateResourceTypeName", {
          resourceType: this.resourceType || "-",
          resourceName: this.form.number || "-"
        });
      }
      return title;
    }
  },
  created() {
    this.initForm();
    this.initComp();
  },
  mounted() {
    this.clearFile();
  }
};
</script>

<style scoped>
.has-error {
  color: red;
}
.required label:before {
  color: #e32;
  content: "* ";
  display: inline;
}
.summary-font {
  font-size: small;
  line-height: 24px;
}
.summary-font-bold {
  font-size: small;
  font-weight: bold;
  line-height: 24px;
}
</style>
