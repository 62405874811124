var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("AgreementFilter", {
        ref: "drawer",
        on: {
          filtersSelected: _vm.handleFiltersSelected,
          drawerChanged: _vm.handleDrawer
        }
      }),
      _c(
        "div",
        { class: { "center-panel": _vm.drawer_flag } },
        [
          this.listMode === _vm.Enums.LIST_MODE.FULL
            ? _c(
                "v-card",
                [
                  _c("ToolbarList", {
                    attrs: {
                      title: _vm.$t("labels.agreements"),
                      subtitle: _vm.$tc("labels.found_elements", this.total),
                      withSearch: true,
                      withAdd: this.$can("addButton", "agreements"),
                      withFilter: this.$can("filterButton", "agreements"),
                      withInvoicing: this.$can("invoiceButton", "agreements"),
                      allClear: _vm.allClear
                    },
                    on: {
                      onAdd: function($event) {
                        return _vm.openFormDialog(_vm.Enums.FORM_MODE.CREATE)
                      },
                      onSearch: _vm.searchFunc,
                      onInvoicing: _vm.handleInvoicing,
                      onFilter: function($event) {
                        return _vm.$refs.drawer.open()
                      }
                    }
                  }),
                  _c("BaseGrid", {
                    attrs: {
                      tableName: "agreements",
                      headers: this.tableData.headers,
                      items: this.tableData.items,
                      totalLength: this.total,
                      pagination: this.tableOptions,
                      loading: this.onLoading,
                      showRowFooter: false,
                      hideActions: false
                    },
                    on: { onPaginationChanged: _vm.handlePaginationChanged }
                  })
                ],
                1
              )
            : _vm._e(),
          this.listMode === _vm.Enums.LIST_MODE.TABBED
            ? _c(
                "v-container",
                [
                  _c(
                    "v-card",
                    [
                      _c("ToolbarTab", {
                        attrs: {
                          title: _vm.$t("labels.agreements"),
                          subtitle: _vm.$tc(
                            "labels.found_elements",
                            this.total
                          ),
                          withAdd: this.$can("addButton", "agreements")
                        },
                        on: {
                          onAdd: function($event) {
                            return _vm.openFormDialog(
                              _vm.Enums.FORM_MODE.CREATE
                            )
                          }
                        }
                      }),
                      _c("BaseGrid", {
                        attrs: {
                          tableName: "agreements",
                          headers: this.tableData.headers,
                          items: this.tableData.items,
                          totalLength: this.total,
                          pagination: this.tableOptions,
                          loading: this.onLoading,
                          showRowFooter: false,
                          hideActions: false
                        },
                        on: { onPaginationChanged: _vm.handlePaginationChanged }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "content-class": "edit-form-dialog" },
              model: {
                value: _vm.formDialog,
                callback: function($$v) {
                  _vm.formDialog = $$v
                },
                expression: "formDialog"
              }
            },
            [
              _vm.formDialog
                ? _c("AgreementForm", {
                    attrs: {
                      mode: _vm.formMode,
                      withModelId: this.withModelId,
                      withModelType: this.withModelType,
                      selectedItem: _vm.formItem,
                      advancedRenewalMode: _vm.advancedRenewalMode,
                      renewalMode: _vm.renewalMode,
                      generateDocument: _vm.generateDocument,
                      sendDocument: _vm.sendDocument
                    },
                    on: {
                      formSucceed: _vm.handleFormSucceed,
                      formCancel: _vm.handleFormCancel
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }