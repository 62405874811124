import Vue from "vue";

const EmailMessageService = {
  async fetchAll(searchFilterPagination) {
    try {
      const res = await Vue.$jsonApi.findAll(
        "email_messages",
        searchFilterPagination
      );
      return res;
    } catch (error) {
      return { error: error };
    }
  }
};

export { EmailMessageService };
