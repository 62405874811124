<template>
  <v-container fluid class="form-container title">
    <v-card-title class="form-title title">{{
      this.formTitle | capitalize
    }}</v-card-title>
    <v-form class="form-body" @submit.prevent="onSubmit">
      <div class="form-group required">
        <v-select
          v-model="form.tenant_id"
          :items="this.tenants"
          :disabled="this.$props.withModelId != null"
          item-text="name"
          item-value="id"
          :label="$t('forms.customers.tenant')"
          single-line
          clearable
          v-validate.immediate="'required'"
          name="tenant"
        ></v-select>
        <span
          :class="Settings.style.class.formFieldRequiredCaption"
          v-if="veeErrors.has('tenant')"
          >{{ veeErrors.first("tenant") }}</span
        >
      </div>
      <FormButtons
        @onSave="onSubmit"
        @onCancel="onCancel"
        :disabled="veeErrors.any()"
      />
    </v-form>
  </v-container>
</template>

<script>
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { mapActions, mapGetters } from "vuex";
import FormButtons from "@/components/Shared/FormButtons";
import _ from "lodash";

export default {
  name: "CustomerClone",
  components: { FormButtons },
  props: {
    mode: {
      default: Enums.FORM_MODE.CREATE,
      type: String
    },
    selectedItem: Object,
    withModelId: {
      type: String,
      required: false
    },
    withModelType: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      resourceType: this.$t("resource_types.customer"),
      form: {
        name: "",
        tenant_id: this.$props.withModelId
      }
    };
  },
  mixins: [SharedMixin, Enums],
  watch: {
    selectedItem() {
      this.initForm();
      this.initComp();
    }
  },
  methods: {
    ...mapActions("customer", ["ajaxCustomerCreateOrUpdate"]),
    ...mapActions("customerType", ["ajaxCustomerTypeFetchAll"]),
    ...mapActions("tenant", ["ajaxTenantFetchAll"]),

    initForm() {
      this.setForm(this.$props.selectedItem);
    },

    async initComp() {
      await this.ajaxTenantFetchAll();
    },

    async onSubmit() {
      const validate = await this.$validator.validateAll();
      if (validate) {
        this.$emit("formCancel");
      } else {
        alert(this.$t("forms.common.errorValidateFields"));
      }
    },
    async onCancel() {
      this.$emit("formCancel");
    },
    async submitToStore() {},
    setForm(row = null) {
      if (row && !_.isEmpty(row)) {
        this.form.name = row.name;
        this.form.tenant_id = row.tenant_id;
      } else if (row == null) {
        this.form.name = "";
        this.form.tenant_id = this.$props.withModelId || "";
      }
    }
  },
  computed: {
    ...mapGetters("tenant", ["tenants"]),
    ...mapGetters("customerType", ["customerTypes"]),

    Enums() {
      return Enums;
    },
    formTitle() {
      var title = "";
      const subject = this.$t("labels.customer");

      title = this.$t("labels.cloneResourceTypeName", {
        resourceType: subject || "-",
        resourceName: this.form.name || "-"
      });

      return title;
    }
  },
  created() {
    this.initForm();
    this.initComp();
  }
};
</script>
<style scoped>
input[aria-required="true"] {
  border-color: red;
}
input[aria-invalid="true"] {
  border-color: yellow;
}

input[aria-invalid="false"] {
  border-color: green;
}
</style>
