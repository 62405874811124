var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { attrs: { id: "navigation" } },
    [
      _vm._l(_vm.navigationItems, function(item) {
        return [
          _vm.$can(item.name, "app")
            ? _c(
                "li",
                { key: item.text },
                [
                  item.children
                    ? [
                        _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _c(
                              "v-menu",
                              {
                                attrs: { "offset-y": "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    dark: ""
                                                  }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(item.text) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c(
                                  "v-list",
                                  _vm._l(item.children, function(child, index) {
                                    return _c(
                                      "v-list-item",
                                      { key: index },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          {
                                            class: {
                                              active: _vm.isActive(child.name)
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.goToPage(
                                                  child,
                                                  "reports"
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(_vm._s(child.text))]
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : [
                        _c(
                          "a",
                          {
                            class: { active: _vm.isActive(item.name) },
                            attrs: { title: item.name },
                            on: {
                              click: function($event) {
                                return _vm.goToPage(item)
                              }
                            }
                          },
                          [_vm._v(_vm._s(item.text))]
                        )
                      ]
                ],
                2
              )
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }