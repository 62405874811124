<template>
  <iframe class="mailFrame" ref="mailFrame"></iframe>
</template>

<script>

export default {
  props: ["body"],
  data() {
    return {
    };
  },
  methods: {

  },
  mounted() {
    this.$refs.mailFrame.contentWindow.document.getElementsByTagName("body")[0].innerHTML=this.$props.body; 
  }
};
</script>

<style>
.mailFrame {
  width: 100%;
  height: 256px;
}
</style>
