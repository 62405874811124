var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "form-container title", attrs: { fluid: "" } },
    [
      _c(
        "div",
        [
          _c("v-card-title", { staticClass: "form-title title" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("labels.settings")) +
                " " +
                _vm._s(_vm.$t("labels.invoicingSettings")) +
                "\n    "
            )
          ]),
          _c(
            "v-form",
            {
              staticClass: "form-body",
              attrs: { "data-vv-scope": "invoicingSettings" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.onSubmit()
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "form-group required" },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("forms.settings.invoicing_ns"),
                      name: "invoicing_netsense_send_to"
                    },
                    model: {
                      value: _vm.invoicingForm.invoicing_netsense_send_to,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.invoicingForm,
                          "invoicing_netsense_send_to",
                          $$v
                        )
                      },
                      expression: "invoicingForm.invoicing_netsense_send_to"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-group required" },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("forms.settings.invoicing_sfera"),
                      name: "invoicing_sfera_send_to"
                    },
                    model: {
                      value: _vm.invoicingForm.invoicing_sfera_send_to,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.invoicingForm,
                          "invoicing_sfera_send_to",
                          $$v
                        )
                      },
                      expression: "invoicingForm.invoicing_sfera_send_to"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-group required" },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.enabledValues,
                      "item-text": "label",
                      "item-value": "value",
                      label: _vm.$t("forms.settings.invoicing_enabled"),
                      name: "flag_sending_invoice_email"
                    },
                    model: {
                      value: _vm.invoicingForm.flag_sending_invoice_email,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.invoicingForm,
                          "flag_sending_invoice_email",
                          $$v
                        )
                      },
                      expression: "invoicingForm.flag_sending_invoice_email"
                    }
                  })
                ],
                1
              ),
              _c("FormButtons", {
                attrs: { disabled: _vm.veeErrors.any("invoicingSettings") },
                on: { onSave: _vm.onSubmit, onCancel: _vm.onCancel }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }