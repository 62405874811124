<template>
  <v-container fluid>
    <ToolbarDetail
      :title="detailTitle | upperCase"
      :withEdit="this.$can('editButton', 'agreements') && !this.selectedAgreement.filed"
      @onEdit="openFormDialog(Enums.FORM_MODE.UPDATE)"
      @onBack="setAgreementFlag(true)"
    ></ToolbarDetail>

    <template v-if="loaded">
      <v-tabs
        class="my-4"
        v-model="activeTab"
        background-color="secondary"
        dark
        slider-color="accent"
        @change="syncUrl"
      >
        <v-tab name="head">{{ $t("labels.head") }}</v-tab>
        <v-tab name="details">{{ $t("labels.details") }}</v-tab>
        <v-tab name="attachments">{{ $t("labels.attachments") }}</v-tab>
        <v-tab name="links">{{ linkLabel }}</v-tab>
        <v-tab name="applicationLog">{{ $t("labels.applicationLog") }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <v-card flat>
            <AgreementForm
              :mode="Enums.FORM_MODE.SHOW_SUMMARY"
              :selectedItem="selectedAgreement"
            ></AgreementForm>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <AgreementForm
              :mode="Enums.FORM_MODE.SHOW"
              :selectedItem="selectedAgreement"
            ></AgreementForm>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <AttachmentsList
              withModelType="agreements"
              :withModelId="this.selectedAgreement.id"
              :listMode="Enums.LIST_MODE.TABBED"
              :readOnly="(this.selectedAgreement.filed == 1 || this.selectedAgreement.filed == true) ? true : false"
            ></AttachmentsList>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <LinksList
              withModelType="agreements"
              :withModelId="this.selectedAgreement.id"
              :listMode="Enums.LIST_MODE.TABBED"
              :readOnly="(this.selectedAgreement.filed == 1 || this.selectedAgreement.filed == true) ? true : false"
            ></LinksList>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <ApplicationLogList
              withModelType="agreements"
              :withModelId="this.selectedAgreement.id"
              :listMode="Enums.LIST_MODE.TABBED"
            ></ApplicationLogList>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <v-dialog v-model="formDialog" persistent content-class="edit-form-dialog">
      <AgreementForm
        v-if="formDialog"
        :mode="Enums.FORM_MODE.UPDATE"
        :selectedItem="this.preparedSelected()"
        @formSucceed="handleFormSucceed"
        @formCancel="handleFormCancel"
        @formDelete="handleDelete"
      ></AgreementForm>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "lodash";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { mapActions, mapGetters, mapMutations } from "vuex";
import FormsHelper from "@/mixins/formsHelper";
import AgreementForm from "@/components/Agreement/AgreementForm";
import AttachmentsList from "@/components/Attachment/AttachmentsList";
import ToolbarDetail from "@/components/Shared/ToolbarDetail";
import ApplicationLogList from "@/views/ApplicationLogList";
import LinksList from "@/components/Link/LinksList";

export default {
  name: "AgreementDetails",
  props: {
    id: {
      required: true
    }
  },
  data() {
    return {
      resourceType: this.$t("resource_types.agreement"),
      activeTab: null,
      loaded: false,
      linkLabel: ""
    };
  },
  components: {
    AgreementForm,
    AttachmentsList,
    ToolbarDetail,
    LinksList,
    ApplicationLogList
  },
  mixins: [SharedMixin, Enums, FormsHelper],
  watch: {
    id() {
      this.initComponent();
    }
  },
  methods: {
    ...mapActions("agreement", [
      "ajaxAgreementSetSelected",
      "ajaxAgreementCreateOrUpdate"
    ]),
    ...mapMutations("filters", ["setAgreementFilter", "setAgreementFlag"]),

    async initComponent() {
      await this.ajaxAgreementSetSelected({ id: this.$props.id });
      this.loaded = true;
      this.linkLabel = _.includes(_.map(this.selectedAgreement.services, 'machine_name'), "AMMINISTRAZIONE_TRASPARENTE") ? "Link AT" : "Link";      
    },
    syncUrl() {},
    handleFormSucceed() {
      this.closeFormDialog();
      this.ajaxAgreementSetSelected({ id: this.$props.id });
    },
    handleFormCancel() {
      this.closeFormDialog();
    },
    handleDelete() {
      this.$router.pushCatch({ name: "agreements" });
    },
    preparedSelected() {
      return _.clone(this.selectedAgreement);
    }
  },
  computed: {
    ...mapGetters("agreement", [
      "agreements",
      "selectedAgreement",
      "total",
      "onLoading"
    ]),
    Enums() {
      return Enums;
    },
    agreementURL() {
      const r = this.$router.resolve({
        name: "agreements",
        params: {}
      });
      return r.href;
    },
    detailTitle() {
      return this.$t("labels.detailResourceTypeName", {
        resourceType: this.resourceType,
        resourceName: this.selectedAgreement.code
      });
    }
  },
  created() {
    this.initComponent();
  }
};
</script>
