<template>
  <v-container fluid>
    <ToolbarDetail
      :title="detailTitle | upperCase"
      :withClone="true"
      :withEdit="true"
      @onClone="cloneCustomer"
      @onEdit="openFormDialog(Enums.FORM_MODE.UPDATE)"
      @onBack="setCustomerFlag(true)"
    ></ToolbarDetail>

    <template v-if="loaded">
      <v-tabs
        class="my-4"
        v-model="activeTab"
        background-color="secondary"
        dark
        slider-color="accent"
        @change="syncUrl"
      >
        <v-tab key="details">{{ $t("labels.details") }}</v-tab>
        <v-tab key="agreements">{{ $t("labels.agreements") }}</v-tab>
        <v-tab key="attachments">{{ $t("labels.attachments") }}</v-tab>
        <v-tab key="links">{{ $t("labels.links") }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab">
        <v-tab-item key="details">
          <v-card flat>
            <CustomerForm
              :mode="Enums.FORM_MODE.SHOW"
              :selectedItem="this.selectedCustomer"
            ></CustomerForm>
          </v-card>
        </v-tab-item>
        <v-tab-item key="agreements">
          <v-card flat>
            <AgreementsList
              :listMode="Enums.LIST_MODE.TABBED"
              :withModelId="this.selectedCustomer.id"
            ></AgreementsList>
          </v-card>
        </v-tab-item>
        <v-tab-item key="attachments">
          <v-card flat>
            <AttachmentsList
              withModelType="customers"
              :withModelId="this.selectedCustomer.id"
              :listMode="Enums.LIST_MODE.TABBED"
            ></AttachmentsList>
          </v-card>
        </v-tab-item>
        <v-tab-item key="links">
          <v-card flat>
            <LinksList
              withModelType="customers"
              :withModelId="this.selectedCustomer.id"
              :listMode="Enums.LIST_MODE.TABBED"
            ></LinksList>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <v-dialog v-model="formDialog" persistent content-class="edit-form-dialog">
      <CustomerForm
        v-if="formDialog"
        :mode="Enums.FORM_MODE.UPDATE"
        :selectedItem="this.clonedSelected()"
        @formSucceed="handleFormSucceed"
        @formCancel="handleFormCancel"
      ></CustomerForm>
    </v-dialog>
  </v-container>
</template>

<script>
// import Vue from "vue";
import _ from "lodash";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { mapActions, mapGetters, mapMutations } from "vuex";
import CustomerForm from "@/components/Customer/CustomerForm";
import ToolbarDetail from "@/components/Shared/ToolbarDetail";
import FormsHelper from "@/mixins/formsHelper";
import AttachmentsList from "@/components/Attachment/AttachmentsList";
import LinksList from "@/components/Link/LinksList";
import AgreementsList from "@/views/AgreementsList";

export default {
  name: "CustomerDetails",
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      resourceType: this.$t("resource_types.customer"),
      activeTab: null,
      loaded: false
    };
  },
  components: {
    CustomerForm,
    ToolbarDetail,
    LinksList,
    AttachmentsList,
    AgreementsList
  },
  mixins: [SharedMixin, Enums, FormsHelper],
  watch: {
    id() {
      this.initComponent();
    }
  },
  methods: {
    ...mapActions("customer", ["ajaxCustomerSetSelected", "ajaxCustomerCreateOrUpdate"]),
    ...mapMutations("filters", ["setCustomerFilter", "setCustomerFlag"]),
    async initComponent() {
      await this.ajaxCustomerSetSelected({ id: this.$props.id });
      this.loaded = true;
    },
    syncUrl() {},
    async cloneCustomer() {
      const tenant = this.getTenantOnCloneCustomer();
      this.$confirm({
        message: this.$t("dialogs.cloneCustomerMessage", {TenantName: tenant.name}),
        button: {
          no: this.$t("dialogs.no"),
          yes: this.$t("dialogs.yes")
        },
        callback: async confirm => {
          if (confirm) {
            let payload = this.clonedSelected();
            payload.tenant_id = tenant.id;
            payload.preferences = JSON.stringify(payload.preferences)
            payload.metadata_scuole = JSON.stringify(payload.metadata_scuole)
            delete payload["type"];
            delete payload["links"];
            delete payload["id"];
            delete payload["code"];
            delete payload["customer_type"];
            delete payload["tenant"];
            delete payload["created_at"];
            delete payload["updated_at"];
            delete payload["deleted_at"];   
            await this.createOrUpdateHelper(
              Enums.FORM_MODE.CREATE,
              this.resourceType,
              this.ajaxCustomerCreateOrUpdate,
              payload,
              payload => payload.p.name,
              payload => payload.p.name
            );
          }
        }
      });
    },
    handleFormSucceed() {
      this.closeFormDialog();
      this.ajaxCustomerSetSelected({ id: this.$props.id });
    },
    handleFormCancel() {
      this.closeFormDialog();
    },
    clonedSelected() {
      return _.clone(this.selectedCustomer);
    },
    getTenantOnCloneCustomer() {
      return this.tenantsList.filter((item) => item.id != this.selectedCustomer.tenant_id)[0];
    }
  },
  computed: {
    ...mapGetters("customer", [
      "customers",
      "total",
      "onLoading",
      "selectedCustomer"
    ]),
    ...mapGetters("tenant", ["tenants"]),
    Enums() {
      return Enums;
    },
    detailTitle() {
      return this.$t("labels.detailResourceTypeName", {
        resourceType: this.resourceType,
        resourceName: this.selectedCustomer.name
      });
    },
    tenantsList(){
      return this.tenants; 
    }
  },
  created() {
    this.initComponent();
  }
};
</script>
