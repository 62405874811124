var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.componentKey },
    [
      _c("PermaLinkList", {
        attrs: { withAgreementId: this.withModelId, folderId: this.folderId },
        on: { formSucceed: _vm.forceRerender }
      }),
      _c("FoldersList", {
        attrs: {
          withModelType: this.withModelType,
          withModelId: this.withModelId,
          withParentId: this.withParentId,
          listMode: _vm.Enums.LIST_MODE.FULL,
          isRootSection: true
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }