import Vue from "vue";
import Vuex from "vuex";
import { EmailMessageService } from "@/services/emailMessage.service";
import { HelperService } from "@/services/helper.service";
import _ from "lodash";

Vue.use(Vuex);

const state = {
  emailMessages: [],
  selected: {},
  loading: false,
  totalCount: 0
};

const getters = {
    emailMessages: state => state.emailMessages,
    onLoading: state => state.loading,
    selectedEmailMessage: state => state.selected,
    total: state => state.totalCount
};

const mutations = {
  setSelected(state, sel) {
    state.selected = sel;
  },
  setList(state, sel) {
    state.emailMessages = sel;
  },
  setTotal(state, sel) {
    state.totalCount = Number(sel);
  },

  setLoading(state, sel) {
    state.loading = sel;
  }
};

const actions = {

  async ajaxEmailMessageFetchAll({ commit }, payloadFAP) {
    const searchFilterPagination = HelperService.localToJSONAPIPagination(
      payloadFAP
    );
    //searchFilterPagination.include = "delivery";
    await HelperService.genericFetchAll(
      commit,
      EmailMessageService.fetchAll,
      searchFilterPagination,
      null,
      null
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
