<template>
  <v-container fluid>
    <ToolbarDetail :title="title" :withBack="false"></ToolbarDetail>
    <v-tabs
      class="my-4"
      v-model="activeTab"
      background-color="secondary"
      dark
      slider-color="accent"
      @change="syncUrl"
    >
      <v-tab key="invoicing_settings">{{
        $t("labels.invoicingSettings")
      }}</v-tab>
      <v-tab key="expiration_settings">{{
        $t("labels.expirationSettings")
      }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="activeTab">
      <v-tab-item key="invoicing_settings">
        <v-card flat>
          <InvoicingSettings
            :settings="invoicingSettingsSettings"
            @formSave="handleSaveInvoicing"
          ></InvoicingSettings>
        </v-card>
      </v-tab-item>
      <v-tab-item key="expiration_settings">
        <v-card flat>
          <ExpirationSettings
            :settings="expirationSettingsSettings"
            @formSave="handleSaveExpiration"
          ></ExpirationSettings>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import ToolbarDetail from "@/components/Shared/ToolbarDetail";
import InvoicingSettings from "@/components/Settings/InvoicingSettings";
import ExpirationSettings from "@/components/Settings/ExpirationSettings";
import FormsHelper from "@/mixins/formsHelper";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "OrderDetails",
  props: {},
  data() {
    return {
      activeTab: null,
      invoicingSettings: null,
      expirationSettings: null,
      invoicingSettingsSettings: null,
      expirationSettingsSettings: null
    };
  },
  components: {
    ToolbarDetail,
    InvoicingSettings,
    ExpirationSettings
  },
  mixins: [SharedMixin, Enums, FormsHelper],
  methods: {
    ...mapActions("applicationSetting", [
      "setApplicationSetting",
      "setApplicationSettingsState",
      "updateApplicationSettings"
    ]),
    initComponent() {},
    async handleSaveInvoicing(val) {
      let val2 = {
        invoicing_netsense_send_to: val.invoicing_netsense_send_to,
        invoicing_sfera_send_to: val.invoicing_sfera_send_to,
        flag_sending_invoice_email: val.flag_sending_invoice_email
      };
      let payload = {
        settings: val2,
        type_settings: this.invoicingSettings.type_settings,
        id: this.invoicingSettings.id
      };
      await this.updateApplicationSettings(payload);
      this.toast(
        Enums.TOAST_TYPE.SUCCESS,
        "",
        this.$t("toasts.configuration_updated")
      );
    },
    async handleSaveExpiration(val) {
      let val2 = {
        warning: val.warning,
        expiring: val.expiring,
        expired: val.expired,
        validity_day_after_expiring: val.validity_day_after_expiring,
        flag_sending_expiring_email: val.flag_sending_expiring_email
      };
      let payload = {
        settings: val2,
        type_settings: this.expirationSettings.type_settings,
        id: this.expirationSettings.id
      };
      await this.updateApplicationSettings(payload);
      this.toast(
        Enums.TOAST_TYPE.SUCCESS,
        "",
        this.$t("toasts.configuration_updated")
      );
    },
    syncUrl() {}
  },
  computed: {
    ...mapGetters("applicationSetting", [
      "application_settings",
      "selectedApplicationSetting",
      "applicationSettingLoading"
    ]),
    Enums() {
      return Enums;
    },
    title() {
      return this.$t("labels.settings");
    }
  },
  async mounted() {
    await this.setApplicationSettingsState();
    this.application_settings.forEach(v => {
      if (v.type_settings === Enums.SETTING_TYPE.INVOICING) {
        this.invoicingSettings = v;
        this.invoicingSettingsSettings = JSON.parse(v.settings);
      } else if (v.type_settings === Enums.SETTING_TYPE.AGREEMENT_DEADLINE) {
        this.expirationSettings = v;
        this.expirationSettingsSettings = JSON.parse(v.settings);
      }
    });
  }
};
</script>
