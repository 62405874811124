import { render, staticRenderFns } from "./CustomersList.vue?vue&type=template&id=cceb956e&"
import script from "./CustomersList.vue?vue&type=script&lang=js&"
export * from "./CustomersList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VCard,VDialog})


/* hot reload */
if (module.hot) {
  var api = require("/home/deploy/netcrm/staging/source/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cceb956e')) {
      api.createRecord('cceb956e', component.options)
    } else {
      api.reload('cceb956e', component.options)
    }
    module.hot.accept("./CustomersList.vue?vue&type=template&id=cceb956e&", function () {
      api.rerender('cceb956e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/CustomersList.vue"
export default component.exports