<template>
  <v-navigation-drawer
    class="filter-drawer"
    v-model="drawer"
    v-show="drawer"
    absolute
    temporary
  >
    <v-toolbar color="primary" dark>
      <v-toolbar-title>{{ $t("filters.filters") }}</v-toolbar-title>
    </v-toolbar>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ $t("filters.action") }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="false"
          matchAttribute="value"
          v-model="actionsAll"
          :alwaysShowAll="true"
          @change="getAllFiltersSelected"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.model_type") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="false"
          matchAttribute="value"
          v-model="modelTypesAll"
          :alwaysShowAll="true"
          @change="getAllFiltersSelected"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ $t("filters.users") }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="false"
          matchAttribute="value"
          v-model="usersAll"
          :autocomplete="true"
          @change="getAllFiltersSelected"
          @search="handleUserSearch"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.start_date") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <datetime v-model="startDate" class="dateComponent"></datetime>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.end_date") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <datetime v-model="endDate" class="dateComponent"></datetime>
      </v-list-group>
    </v-list>
    <v-col align="right">
      <v-btn color="primary" small outlined @click="clearFilters">{{
        $t("buttons.clear")
      }}</v-btn>
    </v-col>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import _ from "lodash";
import Enums from "@/mixins/enums";
import FilterList from "@/components/Shared/FilterList";
import SharedMixin from "@/mixins/shared";

export default {
  components: {
    FilterList
  },
  mixins: [SharedMixin],
  data() {
    return {
      drawer: false,
      actionsAll: [
        {
          name: this.$t("filters.all"),
          value: Enums.ACTION_APPLICATION_LOG.ALL,
          checked: false,
          enabled: false,
          all: true
        },
        {
          name: this.$t("filters.login"),
          value: Enums.ACTION_APPLICATION_LOG.LOGIN,
          checked: false,
          enabled: false
        },
        {
          name: this.$t("filters.create"),
          value: Enums.ACTION_APPLICATION_LOG.CREATE,
          checked: false,
          enabled: false
        },
        {
          name: this.$t("filters.update"),
          value: Enums.ACTION_APPLICATION_LOG.UPDATE,
          checked: false,
          enabled: false
        },
        {
          name: this.$t("filters.delete"),
          value: Enums.ACTION_APPLICATION_LOG.DELETE,
          checked: false,
          enabled: false
        },
        {
          name: this.$t("filters.send_email"),
          value: Enums.ACTION_APPLICATION_LOG.SEND_EMAIL,
          checked: false,
          enabled: false
        }
      ],
      modelTypesAll: [
        {
          name: this.$t("filters.all"),
          value: Enums.CASHED_INVOICE.ALL,
          checked: false,
          enabled: false,
          all: true
        },
        {
          name: this.$t("filters.activity"),
          value: Enums.MODEL_NAME.ACTIVITIES,
          checked: false,
          enabled: false
        },
        {
          name: this.$t("filters.agreement"),
          value: Enums.MODEL_NAME.AGREEMENTS,
          checked: false,
          enabled: false
        },
        {
          name: this.$t("filters.attachment"),
          value: Enums.MODEL_NAME.ATTACHMENTS,
          checked: false,
          enabled: false
        },
        {
          name: this.$t("filters.customer"),
          value: Enums.MODEL_NAME.CUSTOMERS,
          checked: false,
          enabled: false
        },
        {
          name: this.$t("filters.generation_session"),
          value: Enums.MODEL_NAME.GENERATION_SESSIONS,
          checked: false,
          enabled: false
        },
        {
          name: this.$t("filters.invoice_period"),
          value: Enums.MODEL_NAME.INVOICE_PERIODS,
          checked: false,
          enabled: false
        },
        {
          name: this.$t("filters.invoice"),
          value: Enums.MODEL_NAME.INVOICES,
          checked: false,
          enabled: false
        },
        {
          name: this.$t("filters.order"),
          value: Enums.MODEL_NAME.ORDERS,
          checked: false,
          enabled: false
        },
        {
          name: this.$t("filters.service"),
          value: Enums.MODEL_NAME.SERVICES,
          checked: false,
          enabled: false
        },
        {
          name: this.$t("filters.ticket"),
          value: Enums.MODEL_NAME.TICKET,
          checked: false,
          enabled: false
        },
        {
          name: this.$t("filters.user"),
          value: Enums.MODEL_NAME.USERS,
          checked: false,
          enabled: false
        }
      ],
      usersAll: [],
      customersSelected: null,
      startDate: null,
      startDateFormatted: null,
      endDate: null,
      endDateFormatted: null,
    };
  },
  methods: {
    ...mapActions("user", ["ajaxUserFetchAll"]),
    ...mapMutations("filters", ["setApplicationLogFilter", "setApplicationLogFlag"]),

    save() {
      this.setApplicationLogFilter({
        actionsAll: this.actionsAll,
        modelTypesAll: this.modelTypesAll,
        usersAll: this.usersAll,
        startDate: this.startDate,
        endDate: this.endDate
      });
    },
    load() {
      if (!this.application_log_flag) return false;
      this.setInvoiceFlag(false);
      if (_.isEmpty(this.application_log_filter)) return false;
      this.actionsAll = this.application_log_filter.actionsAll;
      this.modelTypesAll = this.application_log_filter.modelTypesAll;
      this.usersAll = this.application_log_filter.usersAll;
      this.startDate = this.application_log_filter.startDate;
      this.endDate = this.application_log_filter.endDate;
      return true;
    },

    open() {
      this.drawer = true;
    },

    getText(customer) {
      let name = customer ? `${customer.name}` : "";
      if (customer.tenant && customer.tenant.machine_name) {
        name = `${customer.tenant.machine_name} - ${name}`;
      }
      return name;
    },
    handleUserSearch(query) {
      if (query) {
        this.getUsers(query);
      }
    },
    getUsers: _.debounce(async function getUsers(val) {
      const filterAndPagination = { search: val };
      await this.ajaxUserFetchAll(filterAndPagination);

      let usersArray = [];
      if (this.users) {
        this.users.forEach(function(user) {
          usersArray.push({
            value: user.id,
            name: user.first_name + ' ' + user.last_name,
            checked: false,
            enabled: false
          });
        });
      }
      this.usersAll = _.orderBy(
        this.mergeWithSelected(usersArray, this.usersAll),
        "last_name"
      );
    }, process.env.VUE_APP_DEBOUNCE_TIME),
    formatDate(date) {
      if (!date) {
        return null;
      } else {
        return date.substring(0, date.indexOf("T"));
      }
    },
    //get filtri selezionati
    getAllFiltersSelected() {
      let actionFilter = this.computeFilter(this.actionsAll);
      let modelTypeFilter = this.computeFilter(this.modelTypesAll);
      let userFilter = this.computeFilter(this.usersAll);
      let startDateFilter = this.startDateFormatted;
      let endDateFilter = this.endDateFormatted;

      let allClear = this.computeClear([
        actionFilter,
        modelTypeFilter,
        userFilter,
        startDateFilter,
        endDateFilter
      ]);

      this.save();
      this.$emit(
        "filtersSelected",
        allClear,
        actionFilter,
        modelTypeFilter,
        userFilter,
        startDateFilter,
        endDateFilter
      );
    },
    //svuota filtri
    clearFilters() {
      let actionFilter = "";
      let modelTypeFilter = "";
      let userFilter = "";
      let startDateFilter = null;
      let endDateFilter = null;

      this.actionsAll = this.resetFilter(this.actionsAll);
      this.modelTypesAll = this.resetFilter(this.modelTypesAll);
      this.usersAll = this.resetFilter(this.usersAll);
      this.startDate = null;
      this.endDate = null;

      this.save();
      this.$emit(
        "filtersSelected",
        true,
        actionFilter,
        modelTypeFilter,
        userFilter,
        startDateFilter,
        endDateFilter
      );
    }
  },
  filters: {},
  computed: {
    ...mapGetters("user", ["users"]),
    ...mapGetters("filters", ["application_log_filter", "application_log_flag"]),
    Enums() {
      return Enums;
    }
  },
  props: {},
  watch: {
    startDate(val) {
      if (val != "" && val != null) {
        this.startDateFormatted = this.formatDate(val);
        this.getAllFiltersSelected();
      } else this.startDateFormatted = null;
    },
    endDate(val) {
      if (val != "" && val != null) {
        this.endDateFormatted = this.formatDate(val);
        this.getAllFiltersSelected();
      } else this.endDateFormatted = null;
    },
    drawer(val) {
      this.$emit("drawerChanged", val);
    }
  },
  async mounted() {
    if (!this.load()) {
      this.getUsers("");
    }
    this.getAllFiltersSelected();
  }
};
</script>

<style scoped>
.dateComponent {
  border: 1px solid grey;
  margin-left: 15px;
  margin-right: 120px;
}
</style>
