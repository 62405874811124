var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      this.listMode == _vm.Enums.LIST_MODE.TABBED
        ? _c(
            "v-card",
            [
              _c("ToolbarTab", {
                attrs: {
                  subtitle: _vm.$tc("labels.found_elements", this.total)
                }
              }),
              _c("BaseGrid", {
                attrs: {
                  tableName: "invoice-periods",
                  headers: this.tableData.headers,
                  items: this.tableData.items,
                  pagination: this.tableOptions,
                  totalLength: _vm.total,
                  serverItems: false
                },
                on: { onPaginationChanged: _vm.handlePaginationChanged }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }