import Vue from "vue";
// import Vuetify from "vuetify/lib";
import Vuetify from "vuetify/lib/framework";
// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);
/**
 *      primary: "#2196f3",
        secondary: "#009688",
        accent: "#cddc39",
        error: "#f44336",
        warning: "#ffc107",
        info: "#00bcd4"

 */
const opts = {
  theme: {
    themes: {
      light: {
        primary: "#7389AE",
        primarybak: "#71D6A0",
        secondary: "#1E392A",
        tertiary: "#a4a2a3",
        // accent: "#E9C493",
        accent: "#dca256",
        error: "#FF895D",
        warning: "#7389AE",
        info: "#71D6A0",
        infobak: "#7389AE"
      },
      dark: {
        // primary: colors.blue.lighten3,
      }
    },
    icons: {
      iconfont: "mdi" // default - only for display purposes
      // iconfont: 'fa',
    },
    options: {
      customProperties: true
    }
  }
};

const vuetify = new Vuetify(opts);

export default vuetify;
