var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "form-container title", attrs: { fluid: "" } },
    [
      _vm.mode == _vm.Enums.FORM_MODE.CREATE ||
      _vm.mode == _vm.Enums.FORM_MODE.UPDATE
        ? _c(
            "div",
            [
              _c("v-card-title", { staticClass: "form-title title" }, [
                _vm._v(_vm._s(_vm._f("capitalize")(this.formTitle)))
              ]),
              _c(
                "v-form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.editPassword,
                      expression: "!editPassword"
                    }
                  ],
                  staticClass: "form-body",
                  attrs: { "data-vv-scope": "updateUser" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.onSubmit("updateUser")
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        attrs: {
                          label: _vm.$t("forms.users.firstName"),
                          name: "firstName"
                        },
                        model: {
                          value: _vm.formUser.firstName,
                          callback: function($$v) {
                            _vm.$set(_vm.formUser, "firstName", $$v)
                          },
                          expression: "formUser.firstName"
                        }
                      }),
                      _vm.veeErrors.has("updateUser.firstName")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.veeErrors.first("updateUser.firstName")
                                )
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        attrs: {
                          label: _vm.$t("forms.users.lastName"),
                          name: "lastName"
                        },
                        model: {
                          value: _vm.formUser.lastName,
                          callback: function($$v) {
                            _vm.$set(_vm.formUser, "lastName", $$v)
                          },
                          expression: "formUser.lastName"
                        }
                      }),
                      _vm.veeErrors.has("updateUser.lastName")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.veeErrors.first("updateUser.lastName")
                                )
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        attrs: {
                          label: _vm.$t("forms.users.username"),
                          name: "username"
                        },
                        model: {
                          value: _vm.formUser.username,
                          callback: function($$v) {
                            _vm.$set(_vm.formUser, "username", $$v)
                          },
                          expression: "formUser.username"
                        }
                      }),
                      _vm.veeErrors.has("updateUser.username")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.veeErrors.first("updateUser.username")
                                )
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        attrs: {
                          label: _vm.$t("forms.users.email"),
                          name: "email",
                          disabled: _vm.mode == _vm.Enums.FORM_MODE.UPDATE
                        },
                        model: {
                          value: _vm.formUser.email,
                          callback: function($$v) {
                            _vm.$set(_vm.formUser, "email", $$v)
                          },
                          expression: "formUser.email"
                        }
                      }),
                      _vm.veeErrors.has("updateUser.email")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.veeErrors.first("updateUser.email"))
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.immediate",
                            value: "required",
                            expression: "'required'",
                            modifiers: { immediate: true }
                          }
                        ],
                        attrs: {
                          items: this.userRoles,
                          "item-text": "name",
                          "item-value": "id",
                          "return-object": "",
                          label: _vm.$t("forms.users.role"),
                          name: "role"
                        },
                        model: {
                          value: _vm.formUser.role,
                          callback: function($$v) {
                            _vm.$set(_vm.formUser, "role", $$v)
                          },
                          expression: "formUser.role"
                        }
                      }),
                      _vm.veeErrors.has("updateUser.role")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.veeErrors.first("updateUser.role"))
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.immediate",
                            value: "required",
                            expression: "'required'",
                            modifiers: { immediate: true }
                          }
                        ],
                        attrs: {
                          items: this.tenants,
                          "item-text": "name",
                          "item-value": "name",
                          "return-object": "",
                          label: _vm.$t("forms.users.tenant"),
                          multiple: "",
                          name: "tenant"
                        },
                        model: {
                          value: _vm.formUser.tenant,
                          callback: function($$v) {
                            _vm.$set(_vm.formUser, "tenant", $$v)
                          },
                          expression: "formUser.tenant"
                        }
                      }),
                      _vm.veeErrors.has("updateUser.tenant")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.veeErrors.first("updateUser.tenant"))
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("CustomerAutocomplete", {
                        attrs: {
                          mode: _vm.mode,
                          itemText: "name",
                          itemValue: "id",
                          returnObject: true,
                          label: _vm.$t("forms.users.customer"),
                          fetchFunction: _vm.ajaxCustomerFetchAll,
                          getter: _vm.customers,
                          name: "customer"
                        },
                        model: {
                          value: _vm.formUser.customer,
                          callback: function($$v) {
                            _vm.$set(_vm.formUser, "customer", $$v)
                          },
                          expression: "formUser.customer"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _vm.mode == _vm.Enums.FORM_MODE.CREATE
                        ? _c("v-text-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate.immediate",
                                value: "required",
                                expression: "'required'",
                                modifiers: { immediate: true }
                              }
                            ],
                            attrs: {
                              label: _vm.$t("forms.users.password"),
                              name: "password",
                              type: "password"
                            },
                            model: {
                              value: _vm.formUser.password,
                              callback: function($$v) {
                                _vm.$set(_vm.formUser, "password", $$v)
                              },
                              expression: "formUser.password"
                            }
                          })
                        : _vm._e(),
                      _vm.veeErrors.has("updateUser.password")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.veeErrors.first("updateUser.password")
                                )
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _vm.mode == _vm.Enums.FORM_MODE.CREATE
                        ? _c("v-text-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate.immediate",
                                value: "required",
                                expression: "'required'",
                                modifiers: { immediate: true }
                              }
                            ],
                            attrs: {
                              label: _vm.$t("forms.users.confirmPassword"),
                              name: "confirmPassword",
                              type: "password"
                            },
                            model: {
                              value: _vm.formUser.confirmPassword,
                              callback: function($$v) {
                                _vm.$set(_vm.formUser, "confirmPassword", $$v)
                              },
                              expression: "formUser.confirmPassword"
                            }
                          })
                        : _vm._e(),
                      _vm.veeErrors.has("updateUser.confirmPassword")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.veeErrors.first(
                                    "updateUser.confirmPassword"
                                  )
                                )
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.mode == _vm.Enums.FORM_MODE.UPDATE
                    ? _c(
                        "v-btn",
                        {
                          attrs: { small: "", color: "primary" },
                          on: { click: _vm.editPsw }
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("forms.users.editPassword")) +
                              "\n      "
                          )
                        ]
                      )
                    : _vm._e(),
                  _c("FormButtons", {
                    attrs: { disabled: _vm.veeErrors.any("updateUser") },
                    on: {
                      onSave: function($event) {
                        return _vm.onSubmit("updateUser")
                      },
                      onCancel: _vm.onCancel
                    }
                  })
                ],
                1
              ),
              _c(
                "v-form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editPassword,
                      expression: "editPassword"
                    }
                  ],
                  staticClass: "form-body",
                  attrs: { "data-vv-scope": "updatePassword" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.onSubmit("updatePassword")
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.profile,
                          expression: "profile"
                        }
                      ],
                      class: _vm.profile ? "form-group required" : "form-group"
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("forms.users.oldPassword"),
                          name: "oldPassword",
                          type: "password"
                        },
                        model: {
                          value: _vm.formPassword.oldPassword,
                          callback: function($$v) {
                            _vm.$set(_vm.formPassword, "oldPassword", $$v)
                          },
                          expression: "formPassword.oldPassword"
                        }
                      }),
                      _vm.veeErrors.has("updatePassword.oldPassword")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.veeErrors.first(
                                    "updatePassword.oldPassword"
                                  )
                                )
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.immediate",
                            value: "required",
                            expression: "'required'",
                            modifiers: { immediate: true }
                          }
                        ],
                        attrs: {
                          label: _vm.$t("forms.users.password"),
                          name: "password",
                          type: "password"
                        },
                        model: {
                          value: _vm.formPassword.password,
                          callback: function($$v) {
                            _vm.$set(_vm.formPassword, "password", $$v)
                          },
                          expression: "formPassword.password"
                        }
                      }),
                      _vm.veeErrors.has("updatePassword.password")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.veeErrors.first("updatePassword.password")
                                )
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.immediate",
                            value: "required",
                            expression: "'required'",
                            modifiers: { immediate: true }
                          }
                        ],
                        attrs: {
                          label: _vm.$t("forms.users.confirmPassword"),
                          name: "confirmPassword",
                          type: "password"
                        },
                        model: {
                          value: _vm.formPassword.confirmPassword,
                          callback: function($$v) {
                            _vm.$set(_vm.formPassword, "confirmPassword", $$v)
                          },
                          expression: "formPassword.confirmPassword"
                        }
                      }),
                      _vm.veeErrors.has("updatePassword.confirmPassword")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.veeErrors.first(
                                    "updatePassword.confirmPassword"
                                  )
                                )
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("FormButtons", {
                    attrs: { disabled: _vm.veeErrors.any("updatePassword") },
                    on: {
                      onSave: function($event) {
                        return _vm.onSubmit("updatePassword")
                      },
                      onCancel: _vm.onCancel2
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.mode === _vm.Enums.FORM_MODE.SHOW,
              expression: "mode === Enums.FORM_MODE.SHOW"
            }
          ],
          staticClass: "form-body-show"
        },
        [
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.users.name"),
              value: this.formUser.firstName + " " + this.formUser.lastName
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.LINK,
              label: _vm.$t("forms.users.email"),
              value: this.formUser.email
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.users.username"),
              value: this.formUser.username
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.users.role"),
              value: this.formUser.role.name
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.users.tenant"),
              value: _vm.getTenantsString
            }
          }),
          this.formUser.role.name == "customer"
            ? _c("DisplayKV", {
                attrs: {
                  as: _vm.Enums.DISPLAY_AS.TEXT,
                  label: _vm.$t("forms.users.customer"),
                  value: this.formUser.customer.name
                }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }