var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("ToolbarDetail", { attrs: { title: _vm.title, withBack: false } }),
      _c(
        "v-tabs",
        {
          staticClass: "my-4",
          attrs: {
            "background-color": "secondary",
            dark: "",
            "slider-color": "accent"
          },
          on: { change: _vm.syncUrl },
          model: {
            value: _vm.activeTab,
            callback: function($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab"
          }
        },
        [
          _c("v-tab", { key: "invoicing_settings" }, [
            _vm._v(_vm._s(_vm.$t("labels.invoicingSettings")))
          ]),
          _c("v-tab", { key: "expiration_settings" }, [
            _vm._v(_vm._s(_vm.$t("labels.expirationSettings")))
          ])
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.activeTab,
            callback: function($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab"
          }
        },
        [
          _c(
            "v-tab-item",
            { key: "invoicing_settings" },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c("InvoicingSettings", {
                    attrs: { settings: _vm.invoicingSettingsSettings },
                    on: { formSave: _vm.handleSaveInvoicing }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-tab-item",
            { key: "expiration_settings" },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c("ExpirationSettings", {
                    attrs: { settings: _vm.expirationSettingsSettings },
                    on: { formSave: _vm.handleSaveExpiration }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }