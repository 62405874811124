import Vue from "vue";

const CustomerTypeService = {
  async fetchAll(searchFilterPagination) {
    try {
      const res = await Vue.$jsonApi.findAll(
        "customer_types",
        searchFilterPagination
      );
      return res;
    } catch (error) {
      console.log("fetchAll error: ", error);
      return { error: error };
    }
  }
};

export { CustomerTypeService };
