var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "grey lighten-4 title", attrs: { fluid: "" } },
    [
      _vm.mode == _vm.Enums.FORM_MODE.UPDATE
        ? _c(
            "div",
            [
              _c("v-card-title", { staticClass: "form-title title" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("capitalize")(_vm.formTitle)) +
                    "\n    "
                )
              ]),
              _c(
                "v-form",
                {
                  staticClass: "form-body",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.onSubmit($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      class:
                        _vm.form.state == "EMITTED"
                          ? "form-group required"
                          : "form-group"
                    },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.immediate",
                            value:
                              _vm.form.state == "EMITTED" ? "required" : "",
                            expression:
                              "form.state == 'EMITTED' ? 'required' : ''",
                            modifiers: { immediate: true }
                          }
                        ],
                        attrs: {
                          label: _vm.$t("forms.invoicing.code"),
                          name: "code"
                        },
                        model: {
                          value: _vm.form.code,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "code", $$v)
                          },
                          expression: "form.code"
                        }
                      }),
                      _vm.veeErrors.has("code")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [_vm._v(_vm._s(_vm.veeErrors.first("code")))]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _c("v-select", {
                        attrs: {
                          items: this.options.state,
                          "item-text": "name",
                          "item-value": "id",
                          label: _vm.$t("forms.invoicing.state"),
                          name: "state"
                        },
                        model: {
                          value: _vm.form.state,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "state", $$v)
                          },
                          expression: "form.state"
                        }
                      }),
                      _vm.veeErrors.has("state")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [_vm._v(_vm._s(_vm.veeErrors.first("state")))]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("v-select", {
                        attrs: {
                          items: this.options.cashed,
                          "item-text": "name",
                          "item-value": "id",
                          label: _vm.$t("forms.invoicing.cashed"),
                          name: "cashed"
                        },
                        model: {
                          value: _vm.form.cashed,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "cashed", $$v)
                          },
                          expression: "form.cashed"
                        }
                      })
                    ],
                    1
                  ),
                  _c("FormButtons", {
                    attrs: { disabled: _vm.veeErrors.any() },
                    on: { onSave: _vm.onSubmit, onCancel: _vm.onCancel }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }