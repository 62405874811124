var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "grey lighten-4 title", attrs: { fluid: "" } },
    [
      _vm.mode == _vm.Enums.FORM_MODE.CREATE ||
      _vm.mode == _vm.Enums.FORM_MODE.UPDATE
        ? _c(
            "div",
            [
              _c("v-card-title", { staticClass: "form-title title" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("capitalize")(_vm.formTitle)) +
                    "\n    "
                )
              ]),
              _c(
                "div",
                [
                  _c(
                    "v-stepper",
                    {
                      attrs: { vertical: "" },
                      model: {
                        value: _vm.e6_step,
                        callback: function($$v) {
                          _vm.e6_step = $$v
                        },
                        expression: "e6_step"
                      }
                    },
                    [
                      _c(
                        "v-stepper-step",
                        { attrs: { complete: _vm.e6_step > 1, step: "1" } },
                        [_vm._v(_vm._s(_vm.$t("forms.proceedings.section1")))]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "1" } },
                        [
                          _c(
                            "v-form",
                            {
                              attrs: { "data-vv-scope": "section1" },
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.onSubmit($event)
                                }
                              }
                            },
                            [
                              this.proceedingSubtypes.length > 0
                                ? _c(
                                    "div",
                                    { staticClass: "form-group required" },
                                    [
                                      _c("v-select", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate.immediate",
                                            value: "required",
                                            expression: "'required'",
                                            modifiers: { immediate: true }
                                          }
                                        ],
                                        attrs: {
                                          items: this.proceedingSubtypes,
                                          label: _vm.$t(
                                            "forms.proceedings.proceeding_subtype"
                                          ),
                                          name: "proceeding_subtype",
                                          "item-value": "id",
                                          disabled:
                                            this.$props.proceedingType ==
                                              _vm.Enums.PROCEEDING_TYPE
                                                .DETERMINA ||
                                            (this.form.proceeding_type ==
                                              _vm.Enums.PROCEEDING_TYPE
                                                .DETERMINA &&
                                              this.form.proceeding_sutype !=
                                                _vm.Enums.PROCEEDING_TYPE
                                                  .DETERMINA.AFFIDAMENTO)
                                        },
                                        on: { change: _vm.changeSubtype },
                                        model: {
                                          value: _vm.form.proceeding_subtype,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "proceeding_subtype",
                                              $$v
                                            )
                                          },
                                          expression: "form.proceeding_subtype"
                                        }
                                      }),
                                      _vm.veeErrors.has(
                                        "section1.proceeding_subtype"
                                      )
                                        ? _c(
                                            "span",
                                            {
                                              class:
                                                _vm.Settings.style.class
                                                  .formFieldRequiredCaption
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.veeErrors.first(
                                                    "section1.proceeding_subtype"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "form-group required",
                                      staticStyle: { padding: "15px" },
                                      attrs: { cols: "6" }
                                    },
                                    [
                                      _c("DatePicker", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate.immediate",
                                            value: "required",
                                            expression: "'required'",
                                            modifiers: { immediate: true }
                                          }
                                        ],
                                        attrs: {
                                          label: _vm.$t(
                                            "forms.proceedings.date"
                                          ),
                                          name: "date",
                                          maxValue: _vm.maxDate
                                        },
                                        model: {
                                          value: _vm.form.date,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "date", $$v)
                                          },
                                          expression: "form.date"
                                        }
                                      }),
                                      _vm.veeErrors.has("section1.date")
                                        ? _c(
                                            "span",
                                            {
                                              class:
                                                _vm.Settings.style.class
                                                  .formFieldRequiredCaption
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.veeErrors.first(
                                                    "section1.date"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "form-group required",
                                      staticStyle: { padding: "15px" },
                                      attrs: { cols: "6" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate.immediate",
                                            value: "required",
                                            expression: "'required'",
                                            modifiers: { immediate: true }
                                          }
                                        ],
                                        attrs: {
                                          label: _vm.$t(
                                            "forms.proceedings.protocol_number"
                                          ),
                                          name: "protocol_number",
                                          type: "number",
                                          min: "1"
                                        },
                                        model: {
                                          value: _vm.form.protocol_number,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "protocol_number",
                                              $$v
                                            )
                                          },
                                          expression: "form.protocol_number"
                                        }
                                      }),
                                      _vm.veeErrors.has(
                                        "section1.protocol_number"
                                      )
                                        ? _c(
                                            "span",
                                            {
                                              class:
                                                _vm.Settings.style.class
                                                  .formFieldRequiredCaption
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.veeErrors.first(
                                                    "section1.protocol_number"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "form-group required",
                                      staticStyle: { padding: "15px" },
                                      attrs: { cols: "6" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate.immediate",
                                            value: "required",
                                            expression: "'required'",
                                            modifiers: { immediate: true }
                                          }
                                        ],
                                        attrs: {
                                          label: _vm.$t(
                                            "forms.proceedings.cig"
                                          ),
                                          name: "cig",
                                          counter: "10",
                                          maxlength: 10,
                                          rules: [_vm.rules.length],
                                          disabled:
                                            _vm.disabledCig ||
                                            _vm.mode ==
                                              _vm.Enums.FORM_MODE.UPDATE
                                              ? true
                                              : false
                                        },
                                        model: {
                                          value: _vm.form.cig,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "cig", $$v)
                                          },
                                          expression: "form.cig"
                                        }
                                      }),
                                      _vm.veeErrors.has("section1.cig")
                                        ? _c(
                                            "span",
                                            {
                                              class:
                                                _vm.Settings.style.class
                                                  .formFieldRequiredCaption
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.veeErrors.first(
                                                    "section1.cig"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticStyle: { padding: "15px" },
                                      attrs: { cols: "6" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t("forms.sections.cup"),
                                          name: "cup",
                                          counter: "",
                                          maxlength: "15",
                                          disabled:
                                            _vm.disabledCig ||
                                            _vm.mode ==
                                              _vm.Enums.FORM_MODE.UPDATE
                                              ? true
                                              : false
                                        },
                                        model: {
                                          value: _vm.form.cup,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "cup", $$v)
                                          },
                                          expression: "form.cup"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group required" },
                                [
                                  _c("v-textarea", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate.immediate",
                                        value: "required",
                                        expression: "'required'",
                                        modifiers: { immediate: true }
                                      }
                                    ],
                                    attrs: {
                                      label: _vm.$t("forms.proceedings.object"),
                                      name: "object",
                                      rows: "2",
                                      "no-resize": "",
                                      rules: [_vm.rules.match]
                                    },
                                    model: {
                                      value: _vm.form.object,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "object", $$v)
                                      },
                                      expression: "form.object"
                                    }
                                  }),
                                  _vm.veeErrors.has("section1.object")
                                    ? _c(
                                        "span",
                                        {
                                          class:
                                            _vm.Settings.style.class
                                              .formFieldRequiredCaption
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.veeErrors.first(
                                                "section1.object"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c("FormButtons", {
                                attrs: {
                                  multiForm: true,
                                  multiLayout: 0,
                                  disabled:
                                    _vm.veeErrors.any("section1") ||
                                    (_vm.proceedingType ==
                                      _vm.Enums.PROCEEDING_TYPE.DETERMINA &&
                                      _vm.form.cig.length != 10)
                                },
                                on: {
                                  onNext: _vm.onNext,
                                  onCancel: _vm.onCancel
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-step",
                        { attrs: { complete: _vm.e6_step > 2, step: "2" } },
                        [_vm._v(_vm._s(_vm.$t("forms.proceedings.section2")))]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "2" } },
                        [
                          _c(
                            "v-form",
                            {
                              attrs: { "data-vv-scope": "section2" },
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.onSubmit($event)
                                }
                              }
                            },
                            [
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "form-group required" },
                                  [
                                    _c("v-file-input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate.immediate",
                                          value: "required",
                                          expression: "'required'",
                                          modifiers: { immediate: true }
                                        }
                                      ],
                                      ref: "fileupload",
                                      attrs: {
                                        accept: _vm.acceptedFormats,
                                        clearable: "",
                                        label: _vm.$t("misc.file"),
                                        name: "file"
                                      },
                                      on: { change: _vm.handleFileUpload },
                                      model: {
                                        value: _vm.files,
                                        callback: function($$v) {
                                          _vm.files = $$v
                                        },
                                        expression: "files"
                                      }
                                    }),
                                    _vm.veeErrors.has("section2.file")
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              _vm.Settings.style.class
                                                .formFieldRequiredCaption
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.veeErrors.first(
                                                  "section2.file"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]),
                              _c("FormButtons", {
                                attrs: {
                                  multiForm: true,
                                  multiLayout: 1,
                                  disabled: _vm.veeErrors.any("section2")
                                },
                                on: {
                                  onBack: _vm.onBack,
                                  onNext: _vm.onNext,
                                  onCancel: _vm.onCancel
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-step",
                        { attrs: { complete: _vm.e6_step > 3, step: "3" } },
                        [_vm._v(_vm._s(_vm.$t("forms.proceedings.section4")))]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "3" } },
                        [
                          _c(
                            "v-container",
                            [
                              _c("v-row", [
                                _c("h5", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t("forms.proceedings.section1")
                                      ) +
                                      "\n            "
                                  )
                                ])
                              ]),
                              _c("br"),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "summary-font-bold",
                                      attrs: { cols: "2" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("forms.proceedings.date"))
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "summary-font",
                                      attrs: { cols: "3" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("fullDate")(_vm.form.date)
                                        )
                                      )
                                    ]
                                  ),
                                  _c("v-col", { attrs: { cols: "1" } }),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "summary-font-bold",
                                      attrs: { cols: "2" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "forms.proceedings.protocol_number"
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "summary-font",
                                      attrs: { cols: "4" }
                                    },
                                    [_vm._v(_vm._s(_vm.form.protocol_number))]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "summary-font-bold",
                                      attrs: { cols: "2" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("forms.proceedings.cig"))
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "summary-font",
                                      attrs: { cols: "3" }
                                    },
                                    [_vm._v(_vm._s(_vm.form.cig))]
                                  ),
                                  _c("v-col", { attrs: { cols: "1" } }),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "summary-font-bold",
                                      attrs: { cols: "2" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("forms.sections.cup"))
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "summary-font",
                                      attrs: { cols: "4" }
                                    },
                                    [_vm._v(_vm._s(_vm.form.cup))]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "summary-font-bold",
                                      attrs: { cols: "2" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("forms.proceedings.object")
                                        )
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "summary-font",
                                      attrs: { cols: "10" }
                                    },
                                    [_vm._v(_vm._s(_vm.form.object))]
                                  )
                                ],
                                1
                              ),
                              _c("br"),
                              _c("v-divider"),
                              _c("br"),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "summary-font-bold",
                                      attrs: { cols: "2" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("forms.proceedings.file"))
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "summary-font",
                                      attrs: { cols: "10" }
                                    },
                                    [_vm._v(_vm._s(_vm.form.filename))]
                                  )
                                ],
                                1
                              ),
                              _c("br"),
                              _c("v-divider"),
                              _c("br")
                            ],
                            1
                          ),
                          _c(
                            "v-form",
                            {
                              attrs: { "data-vv-scope": "section4" },
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.onSubmit($event)
                                }
                              }
                            },
                            [
                              _c("FormButtons", {
                                attrs: {
                                  multiForm: true,
                                  multiLayout: 2,
                                  createLoading: _vm.createLoading,
                                  disabled: _vm.veeErrors.any("section4")
                                },
                                on: {
                                  onBack: _vm.onBack,
                                  onSave: _vm.onSubmit,
                                  onCancel: _vm.onCancel
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.mode === _vm.Enums.FORM_MODE.SHOW,
              expression: "mode === Enums.FORM_MODE.SHOW"
            }
          ]
        },
        [
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.sections.name"),
              value: this.form.date
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }