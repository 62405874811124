import { render, staticRenderFns } from "./FileUploader.vue?vue&type=template&id=bef8211e&"
import script from "./FileUploader.vue?vue&type=script&lang=js&"
export * from "./FileUploader.vue?vue&type=script&lang=js&"
import style0 from "./FileUploader.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardText,VCol,VFileInput,VIcon,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/deploy/netcrm/staging/source/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bef8211e')) {
      api.createRecord('bef8211e', component.options)
    } else {
      api.reload('bef8211e', component.options)
    }
    module.hot.accept("./FileUploader.vue?vue&type=template&id=bef8211e&", function () {
      api.rerender('bef8211e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Customer/FileUploader.vue"
export default component.exports