import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  agreement: {},
  customer: {},
  order: {},
  ticket: {},
  email: {},
  invoice: {},
  application_log: {},
  report: {},
  agreement_flag: false,
  customer_flag: false,
  order_flag: false,
  ticket_flag: false,
  email_flag: false,
  invoice_flag: false,
  application_log_flag: false,
  report_flag: false
};

const getters = {
  agreement_filter: state => state.agreement,
  customer_filter: state => state.customer,
  order_filter: state => state.order,
  ticket_filter: state => state.ticket,
  email_filter: state => state.ticket,
  invoice_filter: state => state.invoice,
  application_log_filter: state => state.application_log_filter,
  report_filter: state => state.report,
  agreement_flag: state => state.agreement_flag,
  customer_flag: state => state.customer_flag,
  order_flag: state => state.order_flag,
  ticket_flag: state => state.ticket_flag,
  email_flag: state => state.ticket_flag,
  invoice_flag: state => state.invoice_flag,
  application_log_flag: state => state.application_log_flag,
  report_flag: state => state.report_flag
};

const mutations = {
  setAgreementFilter(state, sel) {
    state.agreement = sel;
  },
  setCustomerFilter(state, sel) {
    state.customer = sel;
  },
  setOrderFilter(state, sel) {
    state.order = sel;
  },
  setTicketFilter(state, sel) {
    state.ticket = sel;
  },
  setEmailFilter(state, sel) {
    state.email = sel;
  },
  setInvoiceFilter(state, sel) {
    state.invoice = sel;
  },
  setApplicationLogFilter(state, sel) {
    state.application_log = sel;
  },
  setReportFilter(state, sel) {
    state.report = sel;
  },
  setAgreementFlag(state, sel) {
    state.agreement_flag = sel;
  },
  setCustomerFlag(state, sel) {
    state.customer_flag = sel;
  },
  setOrderFlag(state, sel) {
    state.order_flag = sel;
  },
  setTicketFlag(state, sel) {
    state.ticket_flag = sel;
  },
  setEmailFlag(state, sel) {
    state.email_flag = sel;
  },
  setInvoiceFlag(state, sel) {
    state.invoice_flag = sel;
  },
  setApplicationLogFlag(state, sel) {
    state.application_log_flag = sel;
  },
  setReportFlag(state, sel) {
    state.report_flag = sel;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
