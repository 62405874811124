<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <font color="white" size="5">{{ $t("greeting") }}</font>
    </v-row>

    <v-row align="center" justify="center">
      <v-col cols="6" class="text-right px-3">
        <img :src="require('../assets/ns-logo.png')" width="70" />
      </v-col>

      <v-col cols="6" class="text-left px-3">
        <img :src="require('../assets/sf-logo.png')" width="40" />
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="my-12">
      <v-col cols="6">
        <v-form>
          <div class="form-group">
            <v-text-field
              class="login-email"
              :label="$t('forms.login.username')"
              v-model="username"
              required
              dark
            ></v-text-field>
          </div>
          <div class="form-group">
            <v-text-field
              class="login-password"
              dark
              :label="$t('forms.login.password')"
              :append-icon="showPassword ? 'visibility' : 'visibility_off'"
              @click:append="showPassword = !showPassword"
              :type="showPassword ? 'password' : 'text'"
              v-model="password"
              required
              el-input
              @keyup.enter.native="submit"
            ></v-text-field>
          </div>
        </v-form>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-btn large :loading="inLoading" :disabled="inLoading" @click="submit">
        <font color="secondary">{{$t('buttons.login')}}</font>
      </v-btn>
      <v-btn text :disabled="!username" @click="forgot_password">
        <font color="secondary">{{$t('buttons.forgot_password')}}</font>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { AuthService } from "@/services/auth.service";
import Enums from "@/mixins/enums";

const computed = {
  ...mapGetters("login", ["getLoginLoading", "profile"]),
  inLoading() {
    return this.getLoginLoading;
  },
  Enums() {
    return Enums;
  }
};

export default {
  data() {
    return {
      username: "",
      password: "",
      color: "orange",
      progress: true,
      showPassword: true
    };
  },
  methods: {
    ...mapActions("login", ["login"]),
    toast(context, mode, text) {
      const messageObj = {
        context,
        mode,
        text,
        snackbar: true
      };
      this.$store.dispatch("snackbar/showMessage", messageObj);
    },
    async submit() {
      this.color = "orange";
      this.progress = true;
      try {
        const loginRes = await this.login({ username: this.username, password: this.password });
        if (loginRes && loginRes.status && loginRes.status === 200) {
          this.sessionInit();
        }  else {
          this.toast(Enums.TOAST_TYPE.ERROR, "", this.$t("toasts.login_failed"));
        }
      } catch (error) {
        this.color = "red";
        this.progress = false;
        this.toast(Enums.TOAST_TYPE.ERROR, "", this.$t("toasts.network_problems"));
      }
    },
    async forgot_password() {
      this.progress = true;
      try {
        const response = await AuthService.forgot_password(this.username); 
        this.toast(Enums.TOAST_TYPE.SUCCESS, "", response.data);
      } catch (error) {      
        this.toast(Enums.TOAST_TYPE.SUCCESS, "", this.$t("toasts.network_problems"));
      }
    },
    sessionInit() {
      this.$router.pushCatch({ name: "loading" });
    }
  },
  computed
};
</script>

<style scoped>
.container {
  background: #7389ae;
  width: 50%;
}
</style>
