import Vue from "vue";
import Vuex from "vuex";
import { TagService } from "@/services/tag.service";
import { HelperService } from "@/services/helper.service";
import store from "@/store";
import _ from "lodash";
import rollbar from "@/rollbar";

Vue.use(Vuex);

const state = {
  tags: [],
  selected: {},
  loading: false,
  totalCount: 0
};

const getters = {
    tags: state => state.tags,
    onLoading: state => state.loading,
    selectedTag: state => state.selected,
    total: state => state.totalCount
};

const mutations = {
  setSelected(state, sel) {
    state.selected = sel;
  },
  setList(state, sel) {
    state.tags = sel;
  },
  setTotal(state, sel) {
    state.totalCount = Number(sel);
  },
  setLoading(state, sel) {
    state.loading = sel;
  }
};

const actions = {
  async ajaxTagSetSelected({ commit }, payload) {
    await HelperService.genericSetSelected(
      commit,
      TagService.fetchOne,
      payload.id
    );
  },

  async ajaxTagFetchAll({ commit }, payloadFAP) {

    const searchFilterPagination = HelperService.localToJSONAPIPagination(
      payloadFAP
    );
    //searchFilterPagination.include = "";

    await HelperService.genericFetchAll(
      commit,
      TagService.fetchAll,
      searchFilterPagination,
      null,
      null
    );
  },

  /*async ajaxTagCreateOrUpdate({ commit }, payload) {
    HelperService.unformatAgreement(payload);
    return HelperService.genericCreateOrUpdate(
      commit,
      AgreementService.createOrUpdate,
      payload
    );
  },*/

  /*async ajaxAgreementDelete({ commit }, payload) {
    return HelperService.genericDelete(
      commit,
      AgreementService.delete,
      payload.id
    );
  },*/

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
