import Vue from "vue";
import Vuex from "vuex";
import { AgreementService } from "@/services/agreement.service";
import { HelperService } from "@/services/helper.service";
import store from "@/store";
import _ from "lodash";
import rollbar from "@/rollbar";

Vue.use(Vuex);

const state = {
  agreements: [],
  agreementsTemp: [],
  selected: {},
  loading: false,
  totalCount: 0
};

const getters = {
  agreements: state => state.agreements,
  agreementsTemp: state => state.agreementsTemp,
  onLoading: state => state.loading,
  selectedAgreement: state => state.selected,
  total: state => state.totalCount
};

const mutations = {
  setSelected(state, sel) {
    state.selected = sel;
  },
  setList(state, sel) {
    state.agreements = sel;
  },
  setTemp(state, sel) {
    state.agreementsTemp = sel;
  },
  setTotal(state, sel) {
    state.totalCount = Number(sel);
  },

  setLoading(state, sel) {
    state.loading = sel;
  }
};

const formatting = function(instance) {
  instance.metadata = JSON.parse(instance.metadata);
  instance.invoice_data = JSON.parse(instance.invoice_data);
  instance.emails_count = JSON.parse(instance.emails_count);
  instance.service = HelperService.getServicesToAgreement(instance);

  let data = HelperService.prepareAgreementMetadata(instance);
  instance.total_hours = data.total_hours;
  instance.worked_hours = data.worked_hours;
  instance.extra_hours = data.extra_hours;

  instance.total_amount = HelperService.getTotalAmount(instance);

  for (let i = 0; i < instance.agreementServices.length; i++) {
    HelperService.prepareAgreementServiceMetadata(
      instance,
      instance.agreementServices[i]
    );
  }

  instance.has_gdpr = false;
  instance.services.forEach(value => {
    if (value.machine_name == "GDPR") instance.has_gdpr = true;
  });
};

const actions = {
  async ajaxAgreementSetSelected({ commit }, payload) {
    await HelperService.genericSetSelected(
      commit,
      AgreementService.fetchOne,
      payload.id,
      formatting
    );
  },

  async ajaxAgreementFetchAll({ commit }, payloadFAP) {
    const searchFilterPagination = HelperService.localToJSONAPIPagination(
      payloadFAP
    );
    searchFilterPagination.include =
      "customer,services,tenant,agreementServices";
    if ("optimize" in payloadFAP)
      searchFilterPagination.include += ",invoice_periods,invoices";

    await HelperService.genericFetchAll(
      commit,
      AgreementService.fetchAll,
      searchFilterPagination,
      null,
      formatting
    );
  },

  async ajaxAgreementCreateOrUpdate({ commit }, payload) {
    HelperService.unformatAgreement(payload);
    return HelperService.genericCreateOrUpdate(
      commit,
      AgreementService.createOrUpdate,
      payload
    );
  },

  async ajaxAgreementDelete({ commit }, payload) {
    return HelperService.genericDelete(
      commit,
      AgreementService.delete,
      payload.id
    );
  },

  async ajaxAgreementSetSelectedTemp({ commit }, payload) {
    await HelperService.tempSetSelected(
      commit,
      AgreementService.fetchOne,
      payload.id,
      formatting
    );
  },

  async ajaxUpdateInvoiceData({ commit }, agreement) {
    //commit("setLoading", true);
    try {
      await Vue.Axios({
        // eslint-disable-next-line no-undef
        url: `${process.env.VUE_APP_ENDPOINT_API_URI}/private/invoices/invoice_data/${agreement.id}`,
        method: "patch",
        headers: {
          Authorization: `Bearer ${store.getters["login/token"]}`
        },
        withCredentials: true,
        data: {
          id: agreement.id,
          invoice_data: JSON.stringify(agreement.invoice_data)
        }
      });
      //commit("setLoading", false);
      return null;
    } catch (error) {
      //commit("setLoading", false);
      rollbar.error(error);
      console.log(error);
      return error;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
