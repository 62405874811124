var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { class: { "center-panel": _vm.drawer_flag } },
      [
        _c(
          "v-card",
          [
            _c("ToolbarList", { attrs: { title: _vm.$t("labels.dashboard") } }),
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "10", "offset-sm": "1" } },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-container",
                            { attrs: { fluid: "" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex child-flex",
                                      attrs: { cols: "2" }
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "d-flex",
                                          attrs: { flat: "", tile: "" }
                                        },
                                        [
                                          _c("v-img", {
                                            staticClass: "grey lighten-2",
                                            attrs: {
                                              src: require("../assets/logo_amministrazione_trasparente.jpg"),
                                              width: "70",
                                              disabled: _vm.msg
                                            },
                                            on: { click: _vm.showPopUp },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "placeholder",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "v-row",
                                                      {
                                                        staticClass:
                                                          "fill-height ma-0",
                                                        attrs: {
                                                          align: "center",
                                                          justify: "center"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-progress-circular",
                                                          {
                                                            attrs: {
                                                              indeterminate: "",
                                                              color:
                                                                "grey lighten-5"
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-dialog",
                    {
                      attrs: { persistent: "", "max-width": "600px" },
                      model: {
                        value: _vm.dialog,
                        callback: function($$v) {
                          _vm.dialog = $$v
                        },
                        expression: "dialog"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c("v-card-title", [
                            _c("span", { staticClass: "headline" }, [
                              _vm._v(
                                _vm._s(this.$t("labels.confirm_at_section"))
                              )
                            ])
                          ]),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "PIN*",
                                              hint: "",
                                              required: "",
                                              type: "password"
                                            },
                                            model: {
                                              value: _vm.pin,
                                              callback: function($$v) {
                                                _vm.pin = $$v
                                              },
                                              expression: "pin"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "blue darken-1", text: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.dialog = false
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(this.$t("dialogs.cancel")))]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "blue darken-1", text: "" },
                                  on: { click: _vm.onSubmit }
                                },
                                [_vm._v(_vm._s(this.$t("dialogs.save")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-dialog",
                    {
                      attrs: { persistent: "", "max-width": "700px" },
                      model: {
                        value: _vm.msg,
                        callback: function($$v) {
                          _vm.msg = $$v
                        },
                        expression: "msg"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-card-title", [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "headline",
                                                staticStyle: { color: "red" }
                                              },
                                              [
                                                _vm._v(
                                                  "Il server Aruba è in manuntenzione."
                                                )
                                              ]
                                            )
                                          ]),
                                          _c("v-card-title", [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "headline",
                                                staticStyle: { color: "red" }
                                              },
                                              [
                                                _vm._v(
                                                  "Al momento non è possibile caricare documenti."
                                                )
                                              ]
                                            )
                                          ]),
                                          _c("v-card-title", [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "headline",
                                                staticStyle: { color: "red" }
                                              },
                                              [
                                                _vm._v(
                                                  "Ci scusiamo per il disagio."
                                                )
                                              ]
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }