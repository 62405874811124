<template>
  <FetchAutocomplete
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
    :itemText="itemText"
    :itemValue="itemValue"
    :returnObject="returnObject"
    :label="label"
    :disabled="disabled"
    :fetchFunction="fetchFunction"
    :getter="getter"
    :withModelId="withModelId"
    :mode="mode"
  >
    <template v-slot:selection="{ item }"
      >{{ item.name }} - {{ item.tenant.name }} -
      {{ item.mechanographyc_code }}</template
    >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title v-html="item.name"></v-list-item-title>
        <v-list-item-subtitle>
          {{ item.tenant.name }} - {{ item.mechanographyc_code }} ({{
            item.city
          }}
          - {{ item.province }})
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </FetchAutocomplete>
</template>

<script>
import FetchAutocomplete from "@/components/Shared/FetchAutocomplete";

export default {
  components: { FetchAutocomplete },
  props: [
    "itemText",
    "itemValue",
    "returnObject",
    "label",
    "disabled",
    "value",
    "fetchFunction",
    "getter",
    "withModelId",
    "mode"
  ]
};
</script>
