<template>
  <v-container>
    <v-card v-if="this.listMode === Enums.LIST_MODE.FULL">
      <v-toolbar flat class="toolbar-94" elevation="1" style="background-color: #7389ae24;" v-if="this.proceeding_type === Enums.PROCEEDING_TYPE.ATTI_PROCEDURA">
        <!-- v-btn fab small @click="goTo()">
          <v-icon>{{ $t("icons.arrow_back") }}</v-icon>
        </v-btn -->
        <!-- v-toolbar-title class="mx-7">
          <p class="text-h4 text-sm-left my-1">{{ detailTitle }}</p>
          <p class="caption text-sm-left">{{ detailTitle }}</p>
        </v-toolbar-title -->
        <v-toolbar-title class="mx-7" style="font-weight: bold;">{{ detailTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!this.isRootSection"
          color="accent"
          small
          outlined
          class="mx-4 text-xs-right"
          @click.stop="openFormDialog(Enums.FORM_MODE.UPDATE)"
        >
          <v-icon small>{{ $t("icons.edit") }}</v-icon>
          &nbsp;
          {{ $t("buttons.edit") }}
        </v-btn>
      </v-toolbar>
      <ToolbarList
        v-else
        :title="detailTitle"
        :subtitle="$tc('labels.found_elements', this.total)"
        :withSearch="true"
        @onSearch="searchFunc"
      ></ToolbarList>
      <v-row v-if="this.proceeding_type === Enums.PROCEEDING_TYPE.ATTI_PROCEDURA">
        <v-col cols="4">
        </v-col>
        <v-col cols="4">
          <v-text-field
            :label="$t('misc.procedure-search')"
            color="warning"
            v-model="search"
            @input="searchFunc"
            clearable
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
      <BaseGrid
        tableName="folders"
        :headers="this.tableData.headers"
        :items="this.tableData.items"
        :totalLength="this.total"
        :pagination="this.tableOptions"
        :loading="this.onLoading"
        @onPaginationChanged="handlePaginationChanged"
      ></BaseGrid>
    </v-card>
    

    <!-- v-dialog v-model="formDialog" persistent content-class="edit-form-dialog">
      <FolderForm
        v-if="formDialog"
        :mode="formMode"
        :selectedItem="formItem"
        :withModelType="this.withModelType"
        :withModelId="this.withModelId"
        :withParentId="this.withParentId"
        :tagName="this.tagName"
        :isRootSection="this.isRootSection"
        @formSucceed="handleFormSucceed"
        @formCancel="handleFormCancel"
      ></FolderForm>
    </v-dialog -->

    <v-dialog v-model="formDialog" persistent content-class="edit-form-dialog">
      <ProceedingForm
        v-if="formDialog"
        :mode="formMode"
        :selectedItem="formItem"
        :withModelType="this.withModelType"
        :withModelId="this.withModelId"
        :withPermalink="this.permalink"
        :procedureId="this.procedure_id"
        :tagName="this.tag_name"
        :proceedingType="this.proceeding_type"
        @formSucceed="handleFormSucceed"
        @formCancel="handleFormCancel"
      ></ProceedingForm>
    </v-dialog>

  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import FolderForm from "@/components/Folder/FolderForm";
import ProceedingForm from "@/components/Proceeding/ProceedingForm";
import ToolbarTab from "@/components/Shared/ToolbarTab";
import ToolbarList from "@/components/Shared/ToolbarList";
import { HelperService } from "@/services/helper.service";
import BaseGrid from "@/components/Shared/BaseGrid";
import FormsHelper from "@/mixins/formsHelper";
import DatatablesHelper from "@/mixins/datatablesHelper";
import Enums from "@/mixins/enums";

const computed = {
    ...mapGetters("folder", [
      "folders",
      "selectedFolder",
      "total",
      "onLoading"
    ]),
  _() {
    return _;
  },
  Enums() {
    return Enums;
  },
  foldersList() {
    return this.folders;
  }
};

export default {
  components: {
    FolderForm,
    ToolbarTab,
    ToolbarList,
    BaseGrid,
    ProceedingForm
  },
  props: {
    withModelType: {
      required: true,
      type: String
    },
    withModelId: {
      required: true,
      type: String
    },
    listMode: {
      default: Enums.LIST_MODE.TABBED,
      type: String
    },
    withParentId: {
      type: String
    },
    /*tagName: {
      type: String
    },*/
    isRootSection: {
      default: false
    }
  },
  data() {
    return {
      search: "",
      detailTitle: "",
      proceeding_type: "",
      tag_name: "",
      procedure_id: "",
      folder_id: "",
      permalink: null,
      tableOptions: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["sorting"],
        sortDesc: [true]
      }
    };
  },
  mixins: [FormsHelper, Enums, DatatablesHelper],
  watch: {
    async withParentId() {
      this.fetchData();
    }
  },
  methods: {
    ...mapActions("folder", [
      "ajaxFolderFetchAll",
      "ajaxFolderSetSelected",
      "ajaxFolderDelete"
    ]),
    searchFunc: async function(searchQuery) {
      this.search = searchQuery
      await this.fetchData();
    },
    async fetchData(needle = null) {
      let _filter = { };
      let _filter_criteria = { tag_name: this.Enums.TAG_NAME.ATTI_AMMINISTRAZIONI_ENTI, is_root_section: "false" };
      const _search = needle ? needle : this.search || "";

      if (this.$props.withModelId) {
        _filter = { ..._filter, ...{ model_id: this.$props.withModelId } };
      }
      if (this.$props.withModelType) {
        _filter = { ..._filter, ...{ model_type: this.$props.withModelType } };
      }

      let payloadFAP = HelperService.buildFilterAndPagination({
        search: _search,
        filter: _filter,
        filter_criteria: _filter_criteria,
        pagination: this.tableOptions,
      });
      await this.ajaxFolderFetchAll(payloadFAP);

      await this.ajaxFolderSetSelected({ id: this.$props.withParentId });
      this.detailTitle = await this.makeTitle();

      this.initTable();
    },
    async makeTitle() {
      var title = this.$t("labels.sub_sections");
      if (this.selectedFolder && this.selectedFolder.parent_id !== null) {
        const cig_position = this.selectedFolder.name.indexOf("CIG");
        const cup_position = this.selectedFolder.name.indexOf("CUP");
        let cig = this.selectedFolder.name.slice(cig_position, -1);
        if (cup_position != -1) {
          cig = this.selectedFolder.name.slice(cig_position, cup_position-3);
        }
        title = title + ' - ' + cig
      }
      if (this.selectedFolder && this.selectedFolder.tagging && this.selectedFolder.tagging.tag && this.selectedFolder.tagging.tag.name == this.Enums.TAG_NAME.PROCEDURA_AFFIDAMENTO) {
        title = this.$t("labels.atti_procedura.title.step3")
        this.proceeding_type = Enums.PROCEEDING_TYPE.ATTI_PROCEDURA
        this.tag_name = Enums.TAG_NAME.ATTI_PROCEDURA
      } else {
        this.proceeding_type = Enums.PROCEEDING_TYPE.ATTI_AMMINISTRAZIONI_ENTI
        this.tag_name = Enums.TAG_NAME.ATTI_AMMINISTRAZIONI_ENTI
      }

      return title;
    },
    initTable() {
      this.tableData.headers = this.mapHeaders();
      this.tableData.items = this.mapItems();
    },
    mapHeaders() {
      const tableHeaders = [];
      tableHeaders.push({
        text: "Name",
        value: "name",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Cig",
        value: "cig",
        align: "start",
        sortable: false
      });
      tableHeaders.push({
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center"
      });
      return tableHeaders;
    },
    mapItems() {
      const map = _.map(this.foldersList, item => {
        const cig_position = item.name.indexOf("CIG");
        const cup_position = item.name.indexOf("CUP");
        let cig = item.name.slice(cig_position+4);
        if (cup_position != -1) {
          cig = item.name.slice(cig_position+4, cup_position-3);
        }
        let name = item.name.slice(0,cig_position-3)
        item.fields = { 
          name: {
            data: name,
            dataType: "text",
            css: "text-xs-left"
          },
          cig: {
            data: cig,
            dataType: "text",
            css: "text-xs-left"
          }
          //description: { data: item.description, dataType: "text", css: "text-xs-left" }
        };
        item.click = {
          actionType: "router-link",
          namedRoot: "folderDetails",
          namedRootId: item.id,
          icon: this.$t("icons.detail"),
          show: true
        };
        item.actions = {
          upload: {
            tooltip: this.$t("forms.folders.file-add"),
            show: this.$can("uploadButton", "folders"),
            actionType: "custom",
            callback: async item => {
              this.procedure_id = item.id;
              this.permalink = item.permalink ? item.permalink : null;
              this.openFormDialog(Enums.FORM_MODE.CREATE, { procedure_id: item.id});
            },
            icon: this.$t("icons.file-add"),
            css: "text-xs-left",
            disabled: false
          },
          show: {
            tooltip: this.$t("forms.folders.edit"),
            actionType: "router-link",
            namedRoot: "folderDetails",
            namedRootId: item.id,
            icon: this.$t("icons.edit"),
            show: this.$can("editButton", "folders")
          },
          delete: {
            tooltip: this.$t("forms.folders.delete"),
            show: this.$can("deleteButton", "folders"),
            actionType: "custom",
            callback: item => {
              this.handleDelete(item);
            },
            icon: this.$t("icons.delete"),
            css: "text-xs-left",
            disabled: false
          },
        };
        return item;
      });
      return map;
    },
    handlePaginationChanged(paginationOpts) {
      this.tableOptions = paginationOpts;
      this.fetchData();
    },
    handleFormSucceed() {
      this.closeFormDialog();
      this.fetchData();
    },
    handleFormCancel() {
      this.closeFormDialog();
    },

    /*debounceSearch: _.debounce(async function(val) {
      this.search = val;
      await this.fetchData();
    }, process.env.VUE_APP_DEBOUNCE_TIME),*/

    async handleEdit(item) {
      this.openFormDialog(Enums.FORM_MODE.UPDATE, item);
    },
    async handleDelete(item) {
      this.$confirm({
        message:
          this.$t("dialogs.deleteConfirmMessage") + "\n\nFolder | " + item.name + "\n\n" + this.$t("dialogs.deleteFolderAttachmentsConfirmMessage"),
        button: {
          no: this.$t("dialogs.no"),
          yes: this.$t("dialogs.yes")
        },
        callback: async confirm => {
          if (confirm) {
            await this.ajaxFolderDelete(item);
            this.fetchData();
          }
        }
      });
    }
  },

  async created() {
    this.formDialog = false;
    await this.fetchData();
  },
  computed
};
</script>
