import { render, staticRenderFns } from "./InvoicePeriodsGrid.vue?vue&type=template&id=0ce0c27c&"
import script from "./InvoicePeriodsGrid.vue?vue&type=script&lang=js&"
export * from "./InvoicePeriodsGrid.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VContainer})


/* hot reload */
if (module.hot) {
  var api = require("/home/deploy/netcrm/staging/source/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0ce0c27c')) {
      api.createRecord('0ce0c27c', component.options)
    } else {
      api.reload('0ce0c27c', component.options)
    }
    module.hot.accept("./InvoicePeriodsGrid.vue?vue&type=template&id=0ce0c27c&", function () {
      api.rerender('0ce0c27c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/InvoicePeriod/InvoicePeriodsGrid.vue"
export default component.exports