var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "form-group required", attrs: { cols: "5" } },
            [
              _c("v-select", {
                attrs: {
                  items: this.attachmentsTypes,
                  label: _vm.$t("misc.select_type"),
                  "single-line": "",
                  clearable: "",
                  name: "attachmentType"
                },
                on: { change: _vm.handleTypeChange },
                model: {
                  value: _vm.val.file_type_id,
                  callback: function($$v) {
                    _vm.$set(_vm.val, "file_type_id", $$v)
                  },
                  expression: "val.file_type_id"
                }
              })
            ],
            1
          ),
          _vm.mode == _vm.Enums.FORM_MODE.CREATE
            ? _c(
                "v-col",
                { staticClass: "form-group required", attrs: { cols: "7" } },
                [
                  _c("v-file-input", {
                    ref: "fileupload",
                    attrs: {
                      accept: _vm.acceptedFormats,
                      clearable: "",
                      label: _vm.$t("misc.file"),
                      name: "file"
                    },
                    on: { change: _vm.handleFileUpload },
                    model: {
                      value: _vm.files,
                      callback: function($$v) {
                        _vm.files = $$v
                      },
                      expression: "files"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.mode == _vm.Enums.FORM_MODE.CREATE
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "form-group", attrs: { cols: "5" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: this.tags,
                      label: _vm.$t("misc.select_tag"),
                      "item-text": "name",
                      "item-value": "id",
                      "single-line": "",
                      clearable: "",
                      name: "tag"
                    },
                    on: { change: _vm.handleTagChange },
                    model: {
                      value: _vm.val.tag_ids,
                      callback: function($$v) {
                        _vm.$set(_vm.val, "tag_ids", $$v)
                      },
                      expression: "val.tag_ids"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }