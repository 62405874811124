var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("v-progress-linear", {
            attrs: { color: "info", height: "5", indeterminate: "" }
          })
        : _vm._e(),
      _c("v-data-table", {
        ref: "dTable",
        attrs: {
          headers: _vm.headersComputed,
          items: _vm.items,
          "server-items-length": _vm.serverItems ? _vm.totalLength : -1,
          options: _vm.paginationOpts,
          "must-sort": true,
          dense: "",
          "footer-props": { itemsPerPageOptions: [10, 25, 50, 100] },
          "hide-default-footer": ""
        },
        on: {
          "update:options": function($event) {
            _vm.paginationOpts = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "body",
              fn: function(ref) {
                var items = ref.items
                return [
                  _c(
                    "tbody",
                    [
                      _vm._l(items, function(item, i) {
                        return [
                          _c(
                            "tr",
                            { key: i },
                            [
                              _vm._l(item.fields, function(v, fieldName, j) {
                                return _c(
                                  "td",
                                  {
                                    key: fieldName,
                                    class:
                                      v.css +
                                      " " +
                                      _vm.tableName +
                                      "-" +
                                      j.toString()
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        class: {
                                          "cursor-pointer":
                                            !("click" in item) ||
                                            !item.click.show,
                                          "td-content": true
                                        },
                                        attrs: {
                                          href: _vm.resolve(item, item.click)
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.handleRowClick(
                                              item,
                                              item.click
                                            )
                                          }
                                        }
                                      },
                                      [
                                        v.dataType == "chip"
                                          ? _c(
                                              "v-chip",
                                              { class: v.chipClass },
                                              [_vm._v(_vm._s(v.data))]
                                            )
                                          : _vm._e(),
                                        v.dataType == "text"
                                          ? _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(v.data)
                                              }
                                            })
                                          : _vm._e(),
                                        v.dataType == "datetime"
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("datetime")(v.data)
                                                )
                                              )
                                            ])
                                          : _vm._e(),
                                        v.dataType == "date"
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(_vm._f("date")(v.data))
                                              )
                                            ])
                                          : _vm._e(),
                                        v.dataType == "link"
                                          ? _c(
                                              "a",
                                              { attrs: { href: v.data } },
                                              [_vm._v(_vm._s(v.data))]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                )
                              }),
                              !_vm.hideActions
                                ? _c(
                                    "td",
                                    { staticClass: "text-center actions-td" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          class: {
                                            "cursor-pointer":
                                              !("click" in item) ||
                                              !item.click.show,
                                            "td-content": true
                                          },
                                          attrs: {
                                            href: _vm.resolve(item, item.click)
                                          }
                                        },
                                        [
                                          _vm._t("actions", null, {
                                            item: item
                                          }),
                                          _vm._l(item.actions, function(
                                            v,
                                            index
                                          ) {
                                            return [
                                              v.actionType == "router-link"
                                                ? _c(
                                                    "a",
                                                    {
                                                      key: index,
                                                      staticClass: "a-btn",
                                                      attrs: {
                                                        href: _vm.resolve(
                                                          item,
                                                          v
                                                        )
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                !(
                                                                  "show" in v
                                                                ) || v.show,
                                                              expression:
                                                                "!('show' in v) || v.show"
                                                            }
                                                          ],
                                                          attrs: {
                                                            title: v.tooltip,
                                                            disabled:
                                                              v.disabled,
                                                            icon: "",
                                                            color: "primary"
                                                          }
                                                        },
                                                        [
                                                          v.icon_text
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    v.icon_text
                                                                  )
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          !v.icon_text
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    dark: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      v.icon
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              v.actionType == "custom"
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            !("show" in v) ||
                                                            v.show,
                                                          expression:
                                                            "!('show' in v) || v.show"
                                                        }
                                                      ],
                                                      key: index,
                                                      attrs: {
                                                        slot: "activator",
                                                        title: v.tooltip,
                                                        icon: "",
                                                        color:
                                                          v.color || "primary",
                                                        disabled: v.disabled
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          $event.preventDefault()
                                                          return v.callback(
                                                            item
                                                          )
                                                        }
                                                      },
                                                      slot: "activator"
                                                    },
                                                    [
                                                      v.loading
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "v-progress-circular",
                                                                {
                                                                  attrs: {
                                                                    indeterminate:
                                                                      ""
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      !v.loading && v.icon_text
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                v.icon_text
                                                              )
                                                            )
                                                          ])
                                                        : _vm._e(),
                                                      !v.loading && !v.icon_text
                                                        ? _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                dark: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(v.icon)
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              v.actionType == "file-download"
                                                ? _c("FileDownloader", {
                                                    key: index,
                                                    attrs: {
                                                      fileRef: v.fileRef,
                                                      fileName: v.fileName
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          }),
                                          _vm.withEdit
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    color: "primary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.onEdit(item)
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { dark: "" } },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("icons.edit")
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.withDelete
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    color: "primary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.onDelete(item)
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { dark: "" } },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("icons.delete")
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        ]
                      }),
                      _vm.totalLength == 0 && !_vm.loading
                        ? _c("v-alert", { attrs: { icon: "warning" } }, [
                            _vm._v(_vm._s(_vm.$t("baseGrid.noResults")))
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _c("div", { staticClass: "custom-header" }, [
        _c(
          "div",
          { staticClass: "paginator" },
          [
            _vm._v(
              "\n    " + _vm._s(_vm.$t("baseGrid.rowsPerPage")) + ":\n    "
            ),
            _c(
              "div",
              { staticClass: "per-page-selector" },
              [
                _c("v-select", {
                  attrs: { items: [10, 25, 50, 100] },
                  on: { change: _vm.handlePerPageChange },
                  model: {
                    value: _vm.paginationOpts.itemsPerPage,
                    callback: function($$v) {
                      _vm.$set(_vm.paginationOpts, "itemsPerPage", $$v)
                    },
                    expression: "paginationOpts.itemsPerPage"
                  }
                })
              ],
              1
            ),
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.paginationOpts.page * _vm.paginationOpts.itemsPerPage +
                    1 -
                    _vm.paginationOpts.itemsPerPage
                ) +
                "-" +
                _vm._s(
                  Math.min(
                    _vm.paginationOpts.page * _vm.paginationOpts.itemsPerPage,
                    _vm.totalLength
                  )
                ) +
                "\n    " +
                _vm._s(_vm.$t("baseGrid.of")) +
                " " +
                _vm._s(_vm.totalLength) +
                "\n    "
            ),
            _c(
              "v-btn",
              {
                attrs: { icon: "", large: "", color: "primary" },
                on: { click: _vm.handleLeft }
              },
              [
                _c("v-icon", { attrs: { dark: "" } }, [
                  _vm._v(_vm._s(_vm.$t("icons.left")))
                ])
              ],
              1
            ),
            _c(
              "v-btn",
              {
                attrs: { icon: "", large: "", color: "primary" },
                on: { click: _vm.handleRight }
              },
              [
                _c("v-icon", { attrs: { dark: "" } }, [
                  _vm._v(_vm._s(_vm.$t("icons.right")))
                ])
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }