<template>
  <v-container fluid class="grey lighten-4 title" v-if="mode == Enums.FORM_MODE.CREATE || mode == Enums.FORM_MODE.UPDATE">
    <!-- div
      v-if="mode == Enums.FORM_MODE.CREATE || mode == Enums.FORM_MODE.UPDATE"
  -->
      <v-card-title class="form-title title">
        {{ formTitle | capitalize }}
      </v-card-title>
      <v-form class="form-body" @submit.prevent="onSubmit" >
        <!-- SUBJECT -->
        <div class="form-group required">
          <v-text-field
            :label="$t('forms.sections.name')"
            v-model="form.name"
            name="name"
            v-validate.immediate="'required'"
            :counter="isRootSection"
            :maxlength="isRootSection ? 100 : ''"
          ></v-text-field>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('name')"
            >{{ veeErrors.first("name") }}</span
          >
        </div>
        <div class="form-group required" v-if="!isRootSection">
          <v-text-field
            :label="$t('forms.sections.cig')"
            v-model="form.cig"
            name="cig"
            v-validate.immediate="'required'"
            counter="10"
            :maxlength="10"
            :rules="[rules.length]"
          ></v-text-field>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('cig')"
            >{{ veeErrors.first("cig") }}</span
          >
        </div>
        <div class="form-group" v-if="!isRootSection">
          <v-text-field
            :label="$t('forms.sections.cup')"
            v-model="form.cup"
            name="cup"
            counter
            maxlength="15"
          ></v-text-field>
        </div>
        <!-- div class="form-group">
          <v-text-field
            :label="$t('forms.sections.description')"
            v-model="form.description"
            name="description"
          ></v-text-field>
        </div -->
        <!-- BUTTONS -->
        <FormButtons
          @onSave="onSubmit"
          @onCancel="onCancel"
          :disabled="veeErrors.any() || this.form.cig.trim().replace(/\s+/g, '').length != 10"
        />
      </v-form>
    <!-- /div>
    <div v-show="mode === Enums.FORM_MODE.SHOW">
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.sections.name')"
        :value="this.form.name"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.sections.description')"
        :value="form.description"
      />
    </div -->
  </v-container>
  <v-container v-else>
    <v-card>
      <v-card-text>
        <v-card-title>{{$t('forms.proceedings.proceeding')}}</v-card-title>
        <v-container>
          <DisplayKV
            :as="Enums.DISPLAY_AS.TEXT"
            :label="$t('forms.sections.name')"
            :value="this.form.name"
          />
          <DisplayKV
            :as="Enums.DISPLAY_AS.LINK"
            :label="$t('forms.sections.permalink')"
            :value="this.permalink"
          />
          <DisplayKV
            :as="Enums.DISPLAY_AS.TEXT"
            :label="$t('forms.sections.cig')"
            :value="form.cig"
          />
          <DisplayKV
            :as="Enums.DISPLAY_AS.TEXT"
            :label="$t('forms.sections.cup')"
            :value="form.cup"
          />
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import _ from "lodash";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { mapActions, mapGetters } from "vuex";
import { HelperService } from "@/services/helper.service";
import DisplayKV from "@/components/Shared/DisplayKeyValue";
import FormButtons from "@/components/Shared/FormButtons";

export default {
  props: {
    mode: {
      default: Enums.FORM_MODE.CREATE,
      type: String
    },
    selectedItem: Object,
    withModelType: {
      type: String,
      required: true
    },
    withModelId: {
      type: String,
      required: true
    },
    withParentId: {
      type: String
    },
    tagName: {
      type: String
    },
    isRootSection: {
      default: false
    }
  },
  data() {
    return {
      progress: true,
      resourceType: this.$t("resource_types.section"),
      permalink: "",
      form: {
        id: "",
        name: "",
        description: "",
        tag_ids: "",
        cip: "",
        cig: "",
        cup: "",
        parent_id: this.$props.withParentId,
        model_type: this.$props.withModelType,
        model_id: this.$props.withModelId
      },
      rules: {
        length: v => v.trim().replace(/\s+/g, '').length == 10 || this.$t("labels.length_validation", {
          numberChar: "10"
        }),
      }
    };
  },
  components: { DisplayKV, FormButtons },
  mixins: [SharedMixin, Enums],
  methods: {
    ...mapActions("folder", ["ajaxFolderCreateOrUpdate", "ajaxFolderDelete"]),
    ...mapActions("tag", ["ajaxTagFetchAll"]),

    async onSubmit() {
      const validate = await this.$validator.validateAll();
      if (validate) {
        await this.submitToStore();
      } else {
        alert(this.$t("forms.common.errorValidateFields"));
      }
    },
    async onCancel() {
      this.$emit("formCancel");
    },
    async submitToStore() {
      const payload = _.clone(this.form);
      await this.createOrUpdateHelper(
        this.mode,
        this.resourceType,
        this.ajaxFolderCreateOrUpdate,
        payload,
        payload => payload.p.name,
        payload => payload.p.name
      );
      this.$emit("formSucceed");
    },
    setForm(row = null) {
      if (row && !_.isEmpty(row)) {
        this.form.id = row.id;
        this.form.description = row.description;
        this.form.parent_id = row.parent_id;
        if (!this.isRootSection) {
          let title = row.name;
          this.form.name = title;

          const cig_position = title.indexOf("CIG");
          const cup_position = title.indexOf("CUP");

          if (cig_position != -1) {
            this.form.name = title.slice(0, cig_position - 3);
            if (cup_position != -1) {
              this.form.cig = title.slice(cig_position+4, cup_position-3);
              this.form.cup = title.slice(cup_position+4);
            } else {
              this.form.cig = title.slice(cig_position+4);
            }
          }
          this.permalink = row.permalink ? row.permalink.permalink : "";
        } else {
          this.form.name = row.name;
        }
      } else if (row == null) {
        this.form.id = "";
        this.form.name = "";
        this.form.description = "";
        this.form.cig = "";
        this.form.cup = "";
      }
    },
    initForm() {
      if (this.mode == Enums.FORM_MODE.CREATE) {
        this.setForm();
      } else {
        this.setForm(this.$props.selectedItem);
      }
    },
    async initComp() {
      let _filter = { name: this.$props.tagName };
      let payloadFAP = HelperService.buildFilterAndPagination({
        filter: _filter
      });
      await this.ajaxTagFetchAll(payloadFAP);
      if (this.tags && this.tags.length > 0) {
        this.form.tag_ids = _.map(this.tags, "id")
      }
    }
  },
  computed: {
    ...mapGetters("tag", ["tags"]),

    Enums() {
      return Enums;
    },
    formTitle() {
      var title = "";
      switch (this.mode) {
        case Enums.FORM_MODE.CREATE:
          title = this.$t("labels.createResourceType", {
            resourceType: this.resourceType || "-"
          });
          break;
        case Enums.FORM_MODE.UPDATE:
          title = this.$t("labels.updateResourceTypeName", {
            resourceType: this.resourceType || "-",
            resourceName: this.form.name || "-"
          });
          break;

        default:
          break;
      }
      return title;
    }
  },
  created() {
    this.initForm();
    this.initComp();
  }
};
</script>

<style scoped>
.has-error {
  color: red;
}
.required label:before {
  color: #e32;
  content: "* ";
  display: inline;
}
.summary-font {
  font-size: small;
  line-height: 24px;
}
.summary-font-bold {
  font-size: small;
  font-weight: bold;
  line-height: 24px;
}
</style>
