<template>
  <v-container fluid class="form-container title">
    <div>
      <v-form
        class="form-body"
        @submit.prevent="onSubmit()"
        data-vv-scope="invoicingData"
      >
        <div class="form-group">
          <v-text-field
            :label="$t('forms.invoicing.total_amount')"
            :value="getTotalAmount()"
            name="total_amount"
            disabled
          ></v-text-field>
        </div>
        <div class="form-group">
          <v-text-field
            :label="$t('forms.invoicing.agreement_duration')"
            :value="invoice_data.agreement_duration"
            name="agreement_duration"
            disabled
          ></v-text-field>
        </div>
        <div class="form-group">
          <v-text-field
            :label="$t('forms.invoicing.state_billing')"
            :value="
              getEnumTranslationFor('invoice_data', invoice_data.state_billing)
            "
            name="state_billing"
            disabled
          ></v-text-field>
        </div>
        <div class="form-group">
          <v-text-field
            :label="$t('forms.invoicing.billing_period')"
            v-model="invoice_data.billing_period"
            name="billing_period"
            :disabled="disabledUpdate"
          ></v-text-field>
        </div>
        <div class="form-group">
          <v-select
            v-model="invoice_data.early"
            :items="enabledValues"
            item-text="label"
            item-value="value"
            :label="$t('forms.invoicing.early')"
            name="early"
            :disabled="disabledUpdate"
          ></v-select>
        </div>
        <FormButtons
          @onSave="onSubmit"
          @onCancel="onCancel"
          :disabled="veeErrors.any('invoicingData')"
        />
      </v-form>
    </div>
  </v-container>
</template>

<script>
import SharedMixin from "@/mixins/shared";
import Enums from "@/mixins/enums";
import { mapActions } from "vuex";
import FormButtons from "@/components/Shared/FormButtons";
import _ from "lodash";

export default {
  name: "ExpirationSettings",
  props: {
    selectedItem: Object,
    onReloadPage: {}
  },
  data() {
    return {
      invoice_data: {
        agreement_duration: "",
        billing_period: "",
        early: "",
        state_billing: ""
      },
      enabledValues: [
        {
          label: this.$t("forms.invoicing.yes"),
          value: true
        },
        {
          label: this.$t("forms.invoicing.no"),
          value: false
        }
      ],
      disabledUpdate: true
    };
  },
  components: { FormButtons },
  mixins: [SharedMixin, Enums],
  watch: {
    selectedItem() {
      this.clearForm();
    }
  },
  methods: {
    ...mapActions("agreement", ["ajaxUpdateInvoiceData"]),
    async formSave(invoice_data) {
      this.inLoadingPage = true;
      const agreement = {
        id: this.selectedItem.id,
        invoice_data
      };
      let error = await this.ajaxUpdateInvoiceData(agreement);
      if (error) this.toast(Enums.TOAST_TYPE.ERROR, "", error);
      else
        this.toast(
          Enums.TOAST_TYPE.SUCCESS,
          "",
          this.$t("toasts.invoicing_updated")
        );
      this.inLoadingPage = false;
      await this.onReloadPage();
    },
    onSubmit() {
      this.formSave(this.invoice_data);
    },
    async onCancel() {
      this.clearForm();
    },
    clearForm() {
      if (this.selectedItem != null) {
        this.invoice_data = _.cloneDeep(this.selectedItem.invoice_data);

        if (this.selectedItem) {
          this.disabledUpdate = false;
          if (this.selectedItem.state === Enums.AGREEMENT_STATUS.EXPIRED) {
            this.disabledUpdate = true;
          }
          if (
            this.selectedItem.invoice_data &&
            this.invoice_data.state_billing === "FINISHED"
          ) {
            this.disabledUpdate = true;
          }
          if (
            this.selectedItem.invoice_periods &&
            this.selectedItem.invoice_periods.length > 0
          ) {
            const not_sent_invoice_period = this.selectedItem.invoice_periods.filter(
              item => item.state == Enums.INVOICE_PERIOD_STATE.NOT_SENT
            );
            if (
              not_sent_invoice_period &&
              not_sent_invoice_period.length == 0
            ) {
              this.disabledUpdate = true;
            }
          }
        }
      }
    },
    getTotalAmount() {
      let totalAmount = 0;
      let agreementMetadata = this.$props.selectedItem.metadata;
      if (this.$props.selectedItem.agreementServices) {
        for (
          let i = 0;
          i < this.$props.selectedItem.agreementServices.length;
          i++
        ) {
          const metadata = this.$props.selectedItem.agreementServices[i]
            .metadata;
          totalAmount = totalAmount + parseInt(metadata.amount);
        }
      }
      totalAmount = totalAmount != 0 ? totalAmount : agreementMetadata.amount;
      return totalAmount;
    }
  },
  computed: {
    Enums() {
      return Enums;
    }
  },
  created() {
    this.clearForm();
  }
};
</script>
