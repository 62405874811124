var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    { staticClass: "toolbar-94", attrs: { flat: "", elevation: "1" } },
    [
      _vm.withBack
        ? _c(
            "v-btn",
            {
              attrs: { fab: "", small: "" },
              on: {
                click: function($event) {
                  return _vm.goTo()
                }
              }
            },
            [_c("v-icon", [_vm._v(_vm._s(_vm.$t("icons.arrow_back")))])],
            1
          )
        : _vm._e(),
      _c("v-toolbar-title", { staticClass: "mx-7" }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _c("v-spacer"),
      _vm.withClone
        ? _c(
            "v-btn",
            {
              staticClass: "mx-4 text-xs-right",
              attrs: { color: "accent", small: "", outlined: "" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.onClone($event)
                }
              }
            },
            [
              _c("v-icon", { attrs: { small: "" } }, [
                _vm._v(_vm._s(_vm.$t("icons.clone")))
              ]),
              _vm._v("\n     \n    " + _vm._s(_vm.$t("buttons.clone")) + "\n  ")
            ],
            1
          )
        : _vm._e(),
      _vm.withEdit
        ? _c(
            "v-btn",
            {
              staticClass: "mx-4 text-xs-right",
              attrs: { color: "accent", small: "", outlined: "" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.onEdit($event)
                }
              }
            },
            [
              _c("v-icon", { attrs: { small: "" } }, [
                _vm._v(_vm._s(_vm.$t("icons.edit")))
              ]),
              _vm._v("\n     \n    " + _vm._s(_vm.$t("buttons.edit")) + "\n  ")
            ],
            1
          )
        : _vm._e(),
      _vm.withDelete
        ? _c(
            "v-btn",
            {
              staticClass: "text-xs-right",
              attrs: { md2: "", icon: "" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.onDelete($event)
                }
              }
            },
            [_c("v-icon", [_vm._v(_vm._s(_vm.$t("icons.delete")))])],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }