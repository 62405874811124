<template>
  <v-container fluid class="grey lighten-4 title">
    <div
      v-if="mode == Enums.FORM_MODE.CREATE || mode == Enums.FORM_MODE.UPDATE"
    >
      <v-card-title class="form-title title">
        {{ formTitle | capitalize }}
      </v-card-title>
      <div>
      <v-stepper v-model="e6_step" vertical>
        <v-stepper-step :complete="e6_step > 1" step="1">{{
          $t("forms.proceedings.section1")
        }}</v-stepper-step>
        <v-stepper-content step="1">
          <v-form @submit.prevent="onSubmit" data-vv-scope="section1">
            <div class="form-group required" v-if="this.proceedingSubtypes.length > 0">
              <v-select
                :items="this.proceedingSubtypes"
                v-model="form.proceeding_subtype"
                v-on:change="changeSubtype"
                :label="$t('forms.proceedings.proceeding_subtype')"
                v-validate.immediate="'required'"
                name="proceeding_subtype"
                item-value="id"
                :disabled="this.$props.proceedingType==Enums.PROCEEDING_TYPE.DETERMINA || (this.form.proceeding_type == Enums.PROCEEDING_TYPE.DETERMINA && this.form.proceeding_sutype != Enums.PROCEEDING_TYPE.DETERMINA.AFFIDAMENTO)"
                >
              </v-select>
              <span
                :class="Settings.style.class.formFieldRequiredCaption"
                v-if="veeErrors.has('section1.proceeding_subtype')"
                >{{ veeErrors.first("section1.proceeding_subtype") }}</span
              >          
            </div>
            <v-row>
            <v-col cols="6" class="form-group required" style="padding: 15px;">
              <DatePicker
                v-validate.immediate="'required'"
                :label="$t('forms.proceedings.date')"
                v-model="form.date"
                name="date"
                :maxValue="maxDate"
              ></DatePicker>  
              <span
                :class="Settings.style.class.formFieldRequiredCaption"
                v-if="veeErrors.has('section1.date')"
                >{{ veeErrors.first("section1.date") }}</span
              >          
            </v-col>
            <v-col cols="6" class="form-group required" style="padding: 15px;">
              <v-text-field
                :label="$t('forms.proceedings.protocol_number')"
                v-model="form.protocol_number"
                name="protocol_number"
                v-validate.immediate="'required'"
                type="number"
                min="1"
              ></v-text-field>
              <span
                :class="Settings.style.class.formFieldRequiredCaption"
                v-if="veeErrors.has('section1.protocol_number')"
                >{{ veeErrors.first("section1.protocol_number") }}</span
              >              
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="form-group required" style="padding: 15px;">
              <v-text-field
                :label="$t('forms.proceedings.cig')"
                v-model="form.cig"
                name="cig"
                counter="10"
                :maxlength="10"
                :rules="[rules.length]"
                v-validate.immediate="'required'"
                :disabled="disabledCig || mode == Enums.FORM_MODE.UPDATE ? true : false"
              ></v-text-field>
              <span
                :class="Settings.style.class.formFieldRequiredCaption"
                v-if="veeErrors.has('section1.cig')"
                >{{ veeErrors.first("section1.cig") }}</span
              >  
            </v-col>
            <v-col cols="6" style="padding: 15px;">
              <v-text-field              
                :label="$t('forms.sections.cup')"
                v-model="form.cup"
                name="cup"
                counter
                maxlength="15"
                :disabled="disabledCig || mode == Enums.FORM_MODE.UPDATE ? true : false"
              ></v-text-field>
            </v-col>
          </v-row>
            <div class="form-group required">
              <v-textarea
                :label="$t('forms.proceedings.object')"
                v-model="form.object"
                name="object"
                rows="2"
                no-resize
                :rules="[rules.match]"
                v-validate.immediate="'required'"
              ></v-textarea>
              <span
                :class="Settings.style.class.formFieldRequiredCaption"
                v-if="veeErrors.has('section1.object')"
                >{{ veeErrors.first("section1.object") }}</span
              > 
            </div>
            
            <FormButtons
              :multiForm="true"
              :multiLayout="0"
              @onNext="onNext"
              @onCancel="onCancel"
              :disabled="veeErrors.any('section1') || (proceedingType == Enums.PROCEEDING_TYPE.DETERMINA && form.cig.length != 10)"
            />
          </v-form>
        </v-stepper-content>
        <v-stepper-step :complete="e6_step > 2" step="2">{{
          $t("forms.proceedings.section2")
        }}</v-stepper-step>
        <v-stepper-content step="2">
          <v-form @submit.prevent="onSubmit" data-vv-scope="section2">
            <!-- div v-if="mode == Enums.FORM_MODE.CREATE" -->            
            <div>            
              <div class="form-group required">
                <v-file-input
                  ref="fileupload"
                  :accept="acceptedFormats"
                  clearable
                  @change="handleFileUpload"
                  :label="$t('misc.file')"
                  name="file"
                  v-model="files"
                  v-validate.immediate="'required'"
                ></v-file-input>
                <span
                  :class="Settings.style.class.formFieldRequiredCaption"
                  v-if="veeErrors.has('section2.file')"
                  >{{ veeErrors.first("section2.file") }}</span
                >
              </div>
            </div>
            <!-- div v-else>
              <v-container>
                <v-row>
                  <v-col cols="2" class="summary-font-bold">{{
                    $t("misc.file")
                  }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="summary-font">{{
                    form.filename
                  }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="summary-xfont">{{
                    $t("labels.detailsUpdateAttachment", {
                      proceedingType: this.form.proceeding_type
                    })
                  }}</v-col>
                </v-row>
              </v-container>
            </div -->
            <FormButtons
              :multiForm="true"
              :multiLayout="1"
              @onBack="onBack"
              @onNext="onNext"
              @onCancel="onCancel"
              :disabled="veeErrors.any('section2')"
            />
          </v-form>
        </v-stepper-content>
        <v-stepper-step :complete="e6_step > 3" step="3">{{
          $t("forms.proceedings.section4")
        }}</v-stepper-step>
        <v-stepper-content step="3">
          <v-container>
            <v-row>
              <h5>
                {{ $t("forms.proceedings.section1") }}
              </h5>
            </v-row>
            <br />
            <v-row>
              <v-col cols="2" class="summary-font-bold">{{
                $t("forms.proceedings.date")
              }}</v-col>
              <v-col cols="3" class="summary-font">{{
                form.date | fullDate
              }}</v-col>
              <v-col cols="1"></v-col>
              <v-col cols="2" class="summary-font-bold">{{
                $t("forms.proceedings.protocol_number")
              }}</v-col>
              <v-col cols="4" class="summary-font">{{
                form.protocol_number
              }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class="summary-font-bold">{{
                $t("forms.proceedings.cig")
              }}</v-col>
              <v-col cols="3" class="summary-font">{{
                form.cig
              }}</v-col>
              <v-col cols="1"></v-col>
              <v-col cols="2" class="summary-font-bold">{{
                $t("forms.sections.cup")
              }}</v-col>
              <v-col cols="4" class="summary-font">{{
                form.cup
              }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class="summary-font-bold">{{
                $t("forms.proceedings.object")
              }}</v-col>
              <v-col cols="10" class="summary-font">{{
                form.object
              }}</v-col>
            </v-row>
            <br />
            <v-divider></v-divider>
            <br />
            <v-row>
              <v-col cols="2" class="summary-font-bold">{{
                $t("forms.proceedings.file")
              }}</v-col>
              <v-col cols="10" class="summary-font">{{
                form.filename
              }}</v-col>
            </v-row>
            <br />
            <v-divider></v-divider>
            <br />
          </v-container>
          <v-form @submit.prevent="onSubmit" data-vv-scope="section4">
            <FormButtons
              :multiForm="true"
              :multiLayout="2"
              :createLoading="createLoading"
              @onBack="onBack"
              @onSave="onSubmit"
              @onCancel="onCancel"
              :disabled="veeErrors.any('section4')"
            />
          </v-form>
        </v-stepper-content>
      </v-stepper>
      </div>
    </div>
    <div v-show="mode === Enums.FORM_MODE.SHOW">
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.sections.name')"
        :value="this.form.date"
      />
    </div>
  </v-container>
</template>

<script>
const moment = require('moment');

import _ from "lodash";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { mapActions, mapGetters } from "vuex";
import { HelperService } from "@/services/helper.service";
import DisplayKV from "@/components/Shared/DisplayKeyValue";
import FormButtons from "@/components/Shared/FormButtons";
import DatePicker from "@/components/Shared/DatePicker";

export default {
  props: {
    mode: {
      default: Enums.FORM_MODE.CREATE,
      type: String
    },
    selectedItem: Object,
    withModelType: {
      type: String,
      required: true
    },
    withModelId: {
      type: String,
      required: true
    },
    proceedingType: {
      type: String,
      required: true
    },
    procedureId: {},
    withPermalink: {}
  },
  data() {
    return {
      progress: true,
      resourceType: this.$t("resource_types.proceeding"),
      proceedingSubtypes: [],
      subtype: "",
      e6_step: 1,
      createLoading: false,
      acceptedFormats:
        ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, .jpg, .png",
      hide_procedure_select: false,
      disabledCig: false,
      fromActs: false,
      subject: "",
      maxDate: moment().format("YYYY-MM-DD"),
      form: {
        id: "",
        date: "",
        protocol_number: "",
        cig: "",
        cup: "",
        proceeding_subtype: "",
        object: this.$props.withPermalink ? this.$props.withPermalink.subject : "",
        proceeding_type: this.$props.proceedingType,
        model_type: this.$props.withModelType,
        model_id: this.$props.withModelId,        
        procedure_id: this.$props.procedureId,
        permalink_id: this.$props.withPermalink ? this.$props.withPermalink.id : '',
        temp_file: "",
        filename: ""
      },
      pickedFiles: null,
      myfile: "data:application/octet-stream;base64,UEGVUtNpsZV",
      files: null,
      rules: {
        length: v => v.trim().replace(/\s+/g, '').length == 10 || this.$t("labels.length_validation", {
          numberChar: "10"
        }),
        match: v => (this.form.proceeding_subtype && v !== this.$t("labels.atti_procedura.proceeding_subtype."+this.form.proceeding_subtype) + " - " && v.length > (this.$t("labels.atti_procedura.proceeding_subtype."+this.form.proceeding_subtype) + " - ").length) || this.$t("labels.match_validation", { field: 'Oggetto'})
      }
    };
  },
  components: { DisplayKV, FormButtons, DatePicker },
  mixins: [SharedMixin, Enums],
  methods: {
    ...mapActions("proceeding", ["ajaxProceedingCreateOrUpdate"]),
    ...mapActions("folder", ["ajaxFolderFetchAll", "ajaxFolderCreateOrUpdate", "ajaxFolderSetSelected"]),
    ...mapActions("tag", ["ajaxTagFetchAll"]),
    ...mapActions("attachmentType", ["ajaxAttachmentTypeFetchAll"]),
    
    async onCancel() {
      this.$emit("formCancel");
    },
    async onBack() {
      this.e6_step--;
    },
    async onNext() {
      this.e6_step++;
    },
    changeSubtype(e) {
      this.form.object = this.$t('labels.atti_procedura.proceeding_subtype.'+this.form.proceeding_subtype) + " - " + this.subject;

      if (this.form.proceeding_subtype == this.Enums.PROCEEDING_SUBTYPE.ATTI_PROCEDURA.AFFIDAMENTO || this.form.proceeding_subtype == this.Enums.PROCEEDING_SUBTYPE.DETERMINA.AVVIO || this.form.proceeding_subtype == this.Enums.PROCEEDING_SUBTYPE.DETERMINA.CONTRARRE) {
        this.form.proceeding_type = this.Enums.PROCEEDING_TYPE.DETERMINA
      } else {
        this.form.proceeding_type = this.Enums.PROCEEDING_TYPE.ATTI_PROCEDURA
      }
    },
    handleFileUpload(e) {
      this.pickedFiles = e;
      if (this.pickedFiles) {
        let payload = {};
        const fileReader = new FileReader();
        const fileToRead = this.pickedFiles;
        fileReader.onload = async e2 => {
          try {
            this.form.filename = fileToRead.name;
            this.form.temp_file = e2.target.result;
          } catch (e) {
            console.log("error during file upload: ", e);
            this.form.filename = "";
            this.form.temp_file = null;
          }
        };
        fileReader.readAsDataURL(fileToRead);
      } else {
      }
    },
    async onSubmit() {
      const validate = await this.$validator.validateAll();
      if (validate) {
        await this.submitToStore();
      } else {
        alert(this.$t("forms.common.errorValidateFields"));
      }
    },
    async submitToStore() {

      this.form.date = moment(this.form.date).format("YYYY/MM/DD");
      const payload = _.clone(this.form);

      if (this.form.proceeding_type == this.Enums.PROCEEDING_TYPE.ATTI_PROCEDURA || this.form.proceeding_subtype == this.Enums.PROCEEDING_SUBTYPE.ATTI_PROCEDURA.AFFIDAMENTO) {
        delete payload["permalink_id"]
      }

      if (this.$props.mode == Enums.FORM_MODE.UPDATE && (this.form.temp_file == "" || this.form.temp_file == null)) {
        delete payload["temp_file"]
        delete payload["filename"]
      }
      this.createLoading = true;
      await this.createOrUpdateHelper(
        this.mode,
        this.resourceType,
        this.ajaxProceedingCreateOrUpdate,
        payload,
        payload => payload.p.date + " - " + payload.p.protocol_number + " - " + payload.p.cig,
        payload => payload.p.date + " - " + payload.p.protocol_number + " - " + payload.p.cig
      );
      this.createLoading = false;
      this.$emit("formSucceed");
    },
    setForm(row = null) {
      if (row && !_.isEmpty(row)) {
        this.form.id = row.id;
        this.form.date = row.date;
        this.form.protocol_number = row.protocol_number;
        this.form.cig = row.cig;
        this.form.object = row.object;
        this.form.proceeding_type = row.proceeding_type;
        this.form.proceeding_subtype = row.proceeding_subtype;
        this.form.procedure_id = row.procedure_id;
        this.form.model_id = row.model_id;
        this.form.model_type = row.model_type;
        this.form.filename = (row.attachments && row.attachments.length == 1) ? row.attachments[0].name : ""

        this.files = this.dataURLtoFile(this.myfile, row.attachments[0].name)
        this.subject = row.object.replace(this.$t('labels.atti_procedura.proceeding_subtype.'+this.form.proceeding_subtype) + " - ","");
        if (this.form.proceeding_type) {
          this.subtype = this.form.proceeding_subtype == Enums.PROCEEDING_SUBTYPE.ATTI_PROCEDURA.AFFIDAMENTO ? Enums.PROCEEDING_TYPE.ATTI_PROCEDURA : this.form.proceeding_type
        }
      } else {
        this.subtype = this.$props.proceedingType;
        this.subject = this.$props.withPermalink ? this.$props.withPermalink.subject : "";
      }
      this.initComp(this.subtype)
    },
    dataURLtoFile(dataurl, filename) {
 
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type:mime});
    },
    initForm() {
      if (this.mode == Enums.FORM_MODE.CREATE) {
        this.setForm();
      } else {
        this.setForm(this.$props.selectedItem);
      }
    },
    async initComp(tag_name) {
      let proceedingSubtypeEnum = this.Enums.PROCEEDING_SUBTYPE[tag_name];
      if (proceedingSubtypeEnum) {
        Object.entries(proceedingSubtypeEnum).forEach(([key, value]) => {
          this.proceedingSubtypes.push({ id: value, text: this.$t("labels.proceeding_subtype."+key), disabled: key == this.Enums.PROCEEDING_SUBTYPE.DETERMINA.AVVIO ? true : false })
        })
      }
      if (this.$props.mode == Enums.FORM_MODE.CREATE && tag_name == this.Enums.PROCEEDING_TYPE.DETERMINA) {
        this.form.proceeding_subtype = this.Enums.PROCEEDING_SUBTYPE.DETERMINA.CONTRARRE
        this.form.object = this.$t('labels.atti_procedura.proceeding_subtype.'+this.form.proceeding_subtype) + " - " + this.subject;
      }

      if (this.$props.procedureId) {
        let _filter = { id: this.$props.procedureId }
        let payloadFAP = HelperService.buildFilterAndPagination({
          filter: _filter
        });
        await this.ajaxFolderFetchAll(payloadFAP);
        if (this.folders && this.folders.length == 1) {
          const folder = this.folders[0];
          if (folder) {
            const cig_position = folder.name.indexOf("CIG");
            const cup_position = folder.name.indexOf("CUP");
            if (this.$props.mode==Enums.FORM_MODE.CREATE) {
              this.form.object = folder.name.slice(0, cig_position-3);
              this.subject = folder.name.slice(0, cig_position-3);
            }
            this.form.cig = folder.name.slice(cig_position+4);
            this.form.cup = ""
            if (cup_position != -1) {
              this.form.cig = folder.name.slice(cig_position+4, cup_position-3);
              this.form.cup = folder.name.slice(cup_position+4);
            }
            this.disabledCig = true;
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters("folder", ["folders", "selectedFolder"]),
    ...mapGetters("tag", ["tags"]),
    ...mapGetters("attachmentType", ["attachmentTypes"]),

    Enums() {
      return Enums;
    },
    formTitle() {
      var title = "";
      switch (this.mode) {
        case Enums.FORM_MODE.CREATE:
          if (this.proceedingType == Enums.PROCEEDING_TYPE.DETERMINA) {
            title = "Creazione nuova Decisione";
          } else if (this.proceedingType == Enums.PROCEEDING_TYPE.ATTI_PROCEDURA) {
            title = "Creazione nuovo Atto procedura";
          } else {
            title = this.$t("labels.createResourceType", {
              resourceType: this.proceedingType || "-"
            });
          }
          break;
        case Enums.FORM_MODE.UPDATE:
          if (this.proceedingType == Enums.PROCEEDING_TYPE.DETERMINA) {
              title = this.$t("labels.updateResourceTypeName", {
                resourceType: "Decisione" || "-",
                resourceName: this.form.object || "-"
              });
            } else {
              title = this.$t("labels.updateResourceTypeName", {
                resourceType: this.proceedingType || "-",
                resourceName: this.form.object || "-"
              });
            }
          break;

        default:
          break;
      }
      return title;
    }
  },
  created() {
    //this.initComp();
    this.initForm();
  }
};
</script>

<style scoped>
.has-error {
  color: red;
}
.required label:before {
  color: #e32;
  content: "* ";
  display: inline;
}
.summary-xfont {
  font-size: x-small;
  line-height: 24px;
}
.summary-font {
  font-size: small;
  line-height: 24px;
}
.summary-font-bold {
  font-size: small;
  font-weight: bold;
  line-height: 24px;
}
.v-messages{
  color: rgb(0 0 0) !important;
  font-weight: bold !important;
}
.theme--light.v-messages{
  color: rgb(0 0 0) !important;
  font-weight: bold !important;
}
</style>
