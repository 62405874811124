var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLogged
    ? _c(
        "v-row",
        { attrs: { align: "center", justify: "space-between" } },
        [
          _c(
            "v-col",
            { staticClass: "py-0 mt-n1", attrs: { cols: "4", align: "left" } },
            [
              _c("span", { staticClass: "caption" }, [
                _vm._v(_vm._s(_vm.version))
              ])
            ]
          ),
          _c("v-col", {
            staticClass: "py-0 mt-n1",
            attrs: { cols: "4", align: "center" }
          }),
          _c(
            "v-col",
            { staticClass: "py-0 mt-n1", attrs: { cols: "4", align: "right" } },
            [
              _c("span", { staticClass: "caption" }, [
                _vm._v(_vm._s(_vm._f("fullDate")(_vm.now)))
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }