var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InvoiceFilter", {
        ref: "drawer",
        on: {
          filtersSelected: _vm.handleFiltersSelected,
          drawerChanged: _vm.handleDrawer
        }
      }),
      _c(
        "div",
        { class: { "center-panel": _vm.drawer_flag } },
        [
          this.listMode === _vm.Enums.LIST_MODE.FULL
            ? _c(
                "v-card",
                [
                  _c("ToolbarList", {
                    attrs: {
                      title: _vm.$t("labels.invoices"),
                      subtitle: _vm.$tc("labels.found_elements", this.total),
                      withAdd: false,
                      withSearch: true,
                      withFilter: true,
                      allClear: _vm.allClear
                    },
                    on: {
                      onSearch: _vm.searchFunc,
                      onFilter: function($event) {
                        return _vm.$refs.drawer.open()
                      }
                    }
                  }),
                  _c("BaseGrid", {
                    attrs: {
                      tableName: "invoices",
                      headers: this.tableData.headers,
                      items: this.tableData.items,
                      totalLength: this.total,
                      pagination: this.tableOptions,
                      loading: this.onLoading,
                      withEdit: true
                    },
                    on: {
                      onEdit: _vm.handleEdit,
                      onPaginationChanged: _vm.handlePaginationChanged
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          this.listMode === _vm.Enums.LIST_MODE.TABBED
            ? _c(
                "v-container",
                [
                  _c(
                    "v-card",
                    [
                      _c("ToolbarTab", {
                        attrs: {
                          subtitle: _vm.$tc(
                            "labels.found_elements",
                            this.total
                          ),
                          withAdd: true
                        },
                        on: { onAdd: _vm.generateInvoice }
                      }),
                      _c("BaseGrid", {
                        attrs: {
                          tableName: "invoices",
                          headers: this.tableData.headers,
                          items: this.tableData.items,
                          pagination: this.tableOptions,
                          withEdit: true,
                          totalLength: _vm.total,
                          serverItems: false
                        },
                        on: {
                          onPaginationChanged: _vm.handlePaginationChanged,
                          onEdit: _vm.handleEdit
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "content-class": "edit-form-dialog" },
          model: {
            value: _vm.formDialog,
            callback: function($$v) {
              _vm.formDialog = $$v
            },
            expression: "formDialog"
          }
        },
        [
          _vm.formDialog
            ? _c("InvoiceForm", {
                attrs: { mode: _vm.formMode, selectedItem: _vm.formItem },
                on: {
                  formSucceed: _vm.handleFormSucceed,
                  formCancel: _vm.handleFormCancel
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }