<template>
  <div class="container">
    <div v-if="hasPhoto" class="align-right photo-div">
      <v-img
        src="https://randomuser.me/api/portraits/women/85.jpg"
        id="photo"
        width="70%"
        @click="openFormDialog(Enums.FORM_MODE.UPDATE)"
      ></v-img>
    </div>
    <div v-else class="align-right photo-div">
      <v-btn icon large @click="openFormDialog(Enums.FORM_MODE.UPDATE)">
        <v-avatar size="36px" class="teal">
          <span class="white--text headline">{{ profileSignature }}</span>
        </v-avatar>
      </v-btn>
    </div>
    <div class="master-div text-caption">
      {{ this.getProfileFullName() }}<br />
      {{ this.getProfileRole() }}
    </div>
    <v-dialog v-model="formDialog" persistent content-class="edit-form-dialog">
      <UserForm
        :mode="Enums.FORM_MODE.UPDATE"
        :selectedItem="loggedUser.info"
        :profile="true"
        v-if="formDialog"
        @formSucceed="handleFormSucceed"
        @formCancel="handleFormCancel"
      ></UserForm>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SharedMixin from "@/mixins/shared";
import FormsHelper from "@/mixins/formsHelper";
import Enums from "@/mixins/enums";
import UserForm from "@/components/User/UserForm";

export default {
  name: "UserProfile",
  components: {
    UserForm
  },
  data() {
    return {
      hasPhoto: false //sostiture con la prop dell'api che contiene la foto
    };
  },
  mixins: [SharedMixin, FormsHelper, Enums],
  computed: {
    ...mapGetters("login", ["loggedUser"]),
    profileSignature: function() {
      let signature = "";
      if (this.loggedUser.info.first_name !== undefined && this.loggedUser.info.first_name !== null) {
        if (this.loggedUser.info.last_name !== undefined && this.loggedUser.info.last_name !== null) {
          signature = this.loggedUser.info.first_name.charAt(0) + this.loggedUser.info.last_name.charAt(0)
        } else {
            signature = this.loggedUser.info.first_name.charAt(0)
        }
      }
      return signature;
    }
  },
  methods: {
    handleFormSucceed() {
      this.closeFormDialog();
    },
    handleFormCancel() {
      this.closeFormDialog();
    }
  },
  async created() {
    this.formDialog = false;
  }
};
</script>

<style scoped>
#photo {
  cursor: pointer;
}
.photo-div {
  width: 48px;
  display: inline-block;
  padding-left: 4px;
}
.master-div {
  display: inline-block;
  text-align: right;
}
.container > * {
  vertical-align: middle;
}
</style>
