var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      this.as === _vm.Enums.DISPLAY_AS.SEPARATOR
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "body-1 font-weight-light separator",
                  attrs: { cols: "3" }
                },
                [_vm._v(_vm._s(_vm._f("upperCase")(this.label)))]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }