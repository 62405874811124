import Vue from "vue";
import Vuex from "vuex";
import { ProceedingService } from "@/services/proceeding.service";
import { HelperService } from "@/services/helper.service";
import store from "@/store";
import _ from "lodash";

Vue.use(Vuex);

const state = {
  proceedings: [],
  selected: {},
  loading: false,
  totalCount: 0,
};

const getters = {
  proceedings: (state) => state.proceedings,
  onLoading: (state) => state.loading,
  selectedProcedure: (state) => state.selected,
  total: (state) => state.totalCount,
};

const mutations = {
  setSelected(state, sel) {
    state.selected = sel;
  },
  setList(state, sel) {
    state.proceedings = sel;
  },
  setTotal(state, sel) {
    state.totalCount = Number(sel);
  },
  setLoading(state, sel) {
    state.loading = sel;
  },
};

const actions = {
  async ajaxProceedingSetSelected({ commit }, payload) {
    await HelperService.genericSetSelected(
      commit,
      ProceedingService.fetchOne,
      payload.id
      //formatting
    );
  },

  async ajaxProceedingFetchAll({ commit }, payloadFAP) {
    const searchFilterPagination = HelperService.localToJSONAPIPagination(
      payloadFAP
    );
    searchFilterPagination.include = "attachments,procedure";
    searchFilterPagination.sort = "date";

    await HelperService.genericFetchAll(
      commit,
      ProceedingService.fetchAll,
      searchFilterPagination,
      null
    );
  },

  async ajaxProceedingCreateOrUpdate({ commit }, payload) {
    return HelperService.genericCreateOrUpdate(
      commit,
      ProceedingService.createOrUpdate,
      payload
    );
  },

  async ajaxProceedingDelete({ commit }, payload) {
    return HelperService.genericDelete(
      commit,
      ProceedingService.delete,
      payload.id
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
