<template>
  <div>
    <v-row>
      <v-col
        cols="5"
        class="form-group required">
        <v-select
          :items="this.attachmentsTypes"
          v-model="val.file_type_id"
          @change="handleTypeChange"
          :label="$t('misc.select_type')"
          single-line
          clearable
          name="attachmentType"
        ></v-select>
      </v-col>      
      <v-col
        v-if="mode == Enums.FORM_MODE.CREATE"
        cols="7"
        class="form-group required"
      >
        <v-file-input
          ref="fileupload"
          :accept="acceptedFormats"
          clearable
          @change="handleFileUpload"
          :label="$t('misc.file')"
          name="file"
          v-model="files"
        ></v-file-input>
      </v-col>  
    </v-row>
    <v-row v-if="mode == Enums.FORM_MODE.CREATE"> 
      <v-col
        cols="5"
        class="form-group">
        <v-select
          :items="this.tags"
          v-model="val.tag_ids"
          @change="handleTagChange"
          :label="$t('misc.select_tag')"
          item-text="name"
          item-value="id"
          single-line
          clearable
          name="tag"
        ></v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SharedMixin from "@/mixins/shared";
import Enums from "@/mixins/enums";

export default {
  mixins: [SharedMixin, Enums],
  data() {
    return {
      acceptedFormats:
        ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, .jpg, .png",
      attachmentsTypes: [],
      val: {
        tag_ids: "",
        file_type_id: "",
        pickedFiles: null,
        payload: null
      },
      files: null
    };
  },
  props: ["label", "value", "permittedTypes", "mode", "fileTypeId", "machineName"],
  methods: {
    ...mapActions("attachmentType", ["ajaxAttachmentTypeFetchAll"]),
    ...mapActions("tag", ["ajaxTagFetchAll"]),
    async prepareTypes() {
      let payload;
      if (
        this.$props.permittedTypes == undefined ||
        this.$props.permittedTypes == null
      ) {
        payload = { machineName: this.machineName };
      } else {
        const types = this.$props.permittedTypes;
        payload = { filter: { machine_name: types } };
      }

      await this.ajaxAttachmentTypeFetchAll(payload);
      this.attachmentsTypes = this.mappedAttachmentTypes;

      if (this.attachmentsTypes.length == 1) {
        this.val.file_type_id = this.attachmentsTypes[0].value;
      }
    },
    handleFileUpload(e) {
      this.val.pickedFiles = e;
      if (this.val.pickedFiles) {
        let payload = {};
        const fileReader = new FileReader();
        const fileToRead = this.val.pickedFiles;
        fileReader.onload = async e2 => {
          try {
            payload.fileType = this.fileType;
            payload.description = this.description;
            payload.name = fileToRead.name;
            payload.title = (fileToRead.name.split(".").length > 2) ? fileToRead.name : fileToRead.name.split(".").shift();
            payload.file = e2.target.result;
            this.val.payload = payload;
            this.emit();
          } catch (e) {
            console.log("error during file upload: ", e);
            this.payload = null;
            this.emit();
          }
        };
        fileReader.readAsDataURL(fileToRead);
      } else {
        this.emit();
      }
    },
    handleTypeChange(e) {
      this.val.file_type_id = e;
      this.emit();
    },
    handleTagChange(e) {
      this.val.tag_ids = e;
      this.emit();
    },
    emit() {
      if (
        this.val &&
        (this.mode == Enums.FORM_MODE.UPDATE ||
          (this.val.pickedFiles && this.val.payload)) &&
        this.val.file_type_id //&& this.val.tag_ids
      )
        this.$emit("input", this.val);
      else this.$emit("input", undefined);
    },
    updateFileTypeId() {
      if (this.fileTypeId && this.fileTypeId != "")
        this.val.file_type_id = this.fileTypeId;
      else this.val.file_type_id = "";
    },
    clear() {
      if (this.attachmentsTypes.length > 1) this.val.file_type_id = null;
      if (this.tags.length > 1) this.val.tag_ids = null;
      this.val.pickedFiles = null;
      this.val.payload = null;
      this.files = null;
    }
  },
  computed: {
    ...mapGetters("attachmentType", ["mappedAttachmentTypes"]),
    ...mapGetters("tag", ["tags"]),
    Enums() {
      return Enums;
    }
  },
  watch: {
    fileTypeId() {
      this.updateFileTypeId();
      this.emit();
    }
  },
  async created() {
    this.updateFileTypeId();
    this.emit();
    await this.prepareTypes();
    await this.ajaxTagFetchAll();
  }
};
</script>
