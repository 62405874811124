<template>
  <div>
    <v-card>
      <ToolbarList
        :title="$t('labels.generationSessions')"
        :subtitle="$tc('labels.found_elements', this.total)"
        :withAdd="true"
        @onAdd="openFormDialog(Enums.FORM_MODE.CREATE)"
      ></ToolbarList>
      <BaseGrid
        tableName="sessions"
        :headers="this.tableData.headers"
        :items="this.tableData.items"
        :totalLength="this.total"
        :pagination="this.tableOptions"
        :loading="this.onLoading"
        @onPaginationChanged="handlePaginationChanged"
      ></BaseGrid>
    </v-card>

    <v-dialog v-model="formDialog" persistent content-class="edit-form-dialog">
      <GenerationSessionForm
        v-if="formDialog"
        :selectedItem="this.formItem"
        :mode="this.formMode"
        @formSucceed="handleFormSucceed"
        @formCancel="handleFormCancel"
      ></GenerationSessionForm>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import ToolbarList from "@/components/Shared/ToolbarList";
import BaseGrid from "@/components/Shared/BaseGrid";
import GenerationSessionForm from "@/components/GenerationSession/GenerationSessionForm";
import FormsHelper from "@/mixins/formsHelper";
import { HelperService } from "@/services/helper.service";
import Enums from "@/mixins/enums";
// import { Ability } from "@casl/ability";
// import {} from "@/abilities";
import SharedMixin from "@/mixins/shared";

const computed = {
  ...mapGetters("generationSession", [
    "generationSessions",
    "total",
    "onLoading"
  ]),
  _() {
    return _;
  },
  Enums() {
    return Enums;
  },
  generationSessionsList() {
    return this.generationSessions;
  }
};

export default {
  name: "generationSessionsList",
  components: {
    GenerationSessionForm,
    ToolbarList,
    BaseGrid
  },
  data() {
    return {
      resourceType: this.$t("resource_types.session"),
      progress: false,
      formDialog: false,
      formMode: "",
      tableData: { headers: [], items: [] },
      tableOptions: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["created_at"],
        sortDesc: [false]
      }
    };
  },
  methods: {
    ...mapActions("generationSession", [
      "ajaxGenerationSessionFetchAll",
      "ajaxGenerationSessionCreateOrUpdate"
    ]),
    ...mapActions("generationSessionItem", [
      "ajaxGenerationSessionItemCreateOrUpdate"
    ]),
    getStateIcon(item) {
      if (item.state === "PROCESSING") {
        return "orange";
      }
      if (item.state === "FINISHED") {
        return "green";
      }
      if (item.state === "STOPPED" || item.state === "FAILED") {
        return "red";
      }
      return "";
    },
    allowStart(state) {
      if (state === "INITIALIZED" || state === "STOPPED") {
        return true;
      } else {
        return false;
      }
    },
    allowStop(state) {
      if (state === "PROCESSING") {
        return true;
      } else {
        return false;
      }
    },
    allowUpdate(state) {
      if (state === "INITIALIZED" || state === "STOPPED") {
        return true;
      } else {
        return false;
      }
    },
    async generationProcessFile(generationSession, startGeneration) {
      const generation_session_id = generationSession.id;
      let message = "";
      if (startGeneration === true) {
        message = "Your file generation process request is running";
      } else {
        message = `Generation Session ${generation_session_id} STOPPED!!!`;
      }
      await this.ajaxGenerationSessionItemCreateOrUpdate({
        generation_session_id,
        startGeneration
      });
      await this.fetchData();
    },
    async fetchData() {
      let payloadFAP = HelperService.buildFilterAndPagination({
        pagination: this.tableOptions
      });
      await this.ajaxGenerationSessionFetchAll(payloadFAP);
      this.initTable();
    },
    initTable() {
      this.tableData.headers = this.mapHeaders();
      this.tableData.items = this.mapItems();
    },
    mapHeaders() {
      const tableHeaders = [];
      tableHeaders.push({
        text: "Id",
        value: "id",
        align: "start",
        sortable: false
      });
      tableHeaders.push({
        text: "Creator",
        value: "first_name",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Creation Date",
        value: "created_at",
        sortable: true,
        align: "start"
      });
      tableHeaders.push({
        text: "End Date",
        value: "updated_at",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "State",
        value: "state",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Generated Files",
        value: "generated_file",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Total Files",
        value: "tot_file",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Actions",
        value: "actions",
        sortable: null,
        align: "center"
      });
      return tableHeaders;
    },
    mapItems() {
      const map = _.map(this.generationSessionsList, item => {
        item.fields = {
          id: { data: item.id, dataType: "text", css: "text-xs-left" },
          first_name: {
            data: item.user.first_name,
            dataType: "text",
            css: "text-xs-left"
          },
          created_at: {
            data: item.created_at,
            dataType: "date",
            css: "text-xs-left"
          },
          updated_at: {
            data: item.updated_at,
            dataType: "date",
            css: "text-xs-left"
          },
          state: {
            data: this.getEnumTranslationFor("sessions", item.state),
            dataType: "chip",
            css: "text-xs-left",
            chipClass: this.getStateIcon(item)
          },
          generated_file: {
            data: item.generated_file,
            dataType: "text",
            css: "text-xs-left"
          },
          tot_file: {
            data: item.tot_file,
            dataType: "text",
            css: "text-xs-left"
          }
        };
        item.click = {
          actionType: "router-link",
          namedRoot: "generationSessionItems",
          namedRootId: item.id,
          icon: this.$t("icons.detail"),
          css: "text-xs-left",
          show: true
        };
        item.actions = {
          modify: {
            tooltip: this.$t("forms.generation_sessions.edit"),
            actionType: "custom",
            callback: item => {
              this.openFormDialog(Enums.FORM_MODE.UPDATE, item);
            },
            icon: this.$t("icons.edit"),
            css: "text-xs-left",
            disabled: !this.allowUpdate(item.state)
          },
          start: {
            tooltip:
              item.state !== "STOPPED"
                ? this.$t("forms.generation_sessions.start")
                : this.$t("forms.generation_sessions.restart"),
            actionType: "custom",
            callback: item => {
              this.generationProcessFile(item, true);
            },
            icon: this.$t("icons.start"),
            css: "text-xs-left",
            disabled: !this.allowStart(item.state)
          },
          stop: {
            tooltip: this.$t("forms.generation_sessions.stop"),
            actionType: "custom",
            callback: item => {
              this.generationProcessFile(item, false);
            },
            icon: this.$t("icons.stop"),
            css: "text-xs-left",
            disabled: !this.allowStop(item.state)
          }
        };
        return item;
      });
      return map;
    },
    handleFormSucceed() {
      this.closeFormDialog();
      this.fetchData();
    },
    handleFormCancel() {
      this.closeFormDialog();
    },
    handlePaginationChanged(paginationOpts) {
      this.tableOptions = paginationOpts;
      this.fetchData();
    }
  },
  mixins: [FormsHelper, Enums, SharedMixin],
  async created() {
    await this.fetchData();
    this.interval = setInterval(this.fetchData, 20000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed
};
</script>
