<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <font color="white" size="5">{{ $t("forms.reset_password.message") }}</font>
    </v-row>

    <v-row align="center" justify="center">
      <v-col cols="6" class="text-right px-3">
        <img :src="require('../assets/ns-logo.png')" width="70" />
      </v-col>

      <v-col cols="6" class="text-left px-3">
        <img :src="require('../assets/sf-logo.png')" width="40" />
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="my-12">
      <v-col cols="6">
        <v-form>
          <div class="form-group required">
            <v-text-field
              :label="$t('forms.reset_password.username')"
              v-model="username"
              v-validate.immediate="'required'"
              name="username"
              dark
            ></v-text-field>
          </div>
          <div class="form-group required">
            <v-text-field
              :label="$t('forms.reset_password.password')"
              :append-icon="showPassword ? 'visibility' : 'visibility_off'"
              @click:append="showPassword = !showPassword"
              :type="showPassword ? 'password' : 'text'"
              v-model="password"
              v-validate.immediate="'required'"
              name="password"
              el-input
              dark
              @keyup.enter.native="submit"
            ></v-text-field>
          </div>
          <div class="form-group required">
            <v-text-field
              :label="$t('forms.reset_password.confirm_password')"
              :append-icon="showConfirmPassword ? 'visibility' : 'visibility_off'"
              @click:append="showConfirmPassword = !showConfirmPassword"
              :type="showConfirmPassword ? 'password' : 'text'"
              v-model="confirm_password"
              v-validate.immediate="'required'"
              name="confirm_password"
              el-input
              dark
              @keyup.enter.native="submit"
            ></v-text-field>
          </div>
        </v-form>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-btn large :loading="inLoading" :disabled="veeErrors.any()" @click="submit">
        <font color="secondary">{{$t('buttons.edit')}}</font>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { AuthService } from "@/services/auth.service";
import Enums from "@/mixins/enums";

const computed = {
  inLoading() {
    return this.getLoginLoading;
  },
  Enums() {
    return Enums;
  }
};

export default {
  data() {
    return {
      username: "",
      password: "",
      confirm_password: "",
      password_reset_token: "",
      color: "orange",
      progress: true,
      showPassword: true,
      showConfirmPassword: true
    };
  },
  methods: {
    toast(context, mode, text) {
      const messageObj = {
        context,
        mode,
        text,
        snackbar: true
      };
      this.$store.dispatch("snackbar/showMessage", messageObj);
    },
    async submit() {
      this.color = "orange";
      this.progress = true;
      try {
        this.password_reset_token = this.$router.history.current.params.password_reset_token;
        const resetPasswordRes = await AuthService.resetPassword(this.username, this.password, this.confirm_password, this.password_reset_token);
        if(resetPasswordRes.error && resetPasswordRes.error.response) {
          const status = resetPasswordRes.error.response.status;
          let message = "";
          if(status===404) {
            message = this.$t("resource_types.user") + " ";
          } 
          message = message + this.$t("toasts.error_response."+status)
          this.toast(Enums.TOAST_TYPE.ERROR, "", message);

        } else {
          const message = this.$t("resource_types.user") + " " + this.$t("toasts.updated") + " " + this.$t("toasts.success_response")
          this.toast(Enums.TOAST_TYPE.SUCCESS, "", message);
          this.$router.push({name: "login"});
        }
      } catch (error) {
        console.log('erroreeeeeeeeeeee ', error)
        this.color = "red";
        this.progress = false;
        const message = this.$t("toasts.error_response.500")
        this.toast(Enums.TOAST_TYPE.ERROR, "", message);
      }
    }
  },
  computed
};
</script>

<style scoped>
.container {
  background: #7389ae;
  width: 50%;
}
</style>
