<template>
  <div>
    <OrderFilter
      @filtersSelected="handleFiltersSelected"
      ref="drawer"
      @drawerChanged="handleDrawer"
    ></OrderFilter>
    <div :class="{ 'center-panel': drawer_flag }">
      <v-card v-if="this.listMode === Enums.LIST_MODE.FULL">
        <ToolbarList
          :title="$t('labels.orders')"
          :subtitle="$tc('labels.found_elements', this.total)"
          :withSearch="true"
          :withAdd="true"
          :withFilter="true"
          @onAdd="openFormDialog(Enums.FORM_MODE.CREATE)"
          @onSearch="searchFunc"
          @onFilter="$refs.drawer.open()"
          :allClear="allClear"
        ></ToolbarList>
        <BaseGrid
          tableName="orders"
          :headers="this.tableData.headers"
          :items="this.tableData.items"
          :totalLength="this.total"
          :pagination="this.tableOptions"
          :loading="this.onLoading"
          @onPaginationChanged="handlePaginationChanged"
          :withDelete="true"
          @onDelete="onDelete"
        ></BaseGrid>
      </v-card>
      <v-card v-if="this.listMode === Enums.LIST_MODE.TABBED">
        <ToolbarTab
          :subtitle="$tc('labels.found_elements', this.total)"
          :withAdd="true"
          @onAdd="openFormDialog(Enums.FORM_MODE.CREATE)"
        ></ToolbarTab>
        <BaseGrid
          tableName="orders"
          headers="this.tableData.headers"
          :items="this.tableData.items"
          :totalLength="this.total"
          :pagination="this.tableOptions"
          :loading="this.onLoading"
          @onPaginationChanged="handlePaginationChanged"
          :withDelete="true"
          @onDelete="onDelete"
        ></BaseGrid>
      </v-card>
      <v-dialog v-model="formDialog" persistent max-width="960">
        <OrderForm
          v-if="formDialog"
          :mode="formMode"
          @formSucceed="handleFormSucceed"
          @formCancel="handleFormCancel"
        ></OrderForm>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import OrderForm from "@/components/Order/OrderForm";
import ToolbarList from "@/components/Shared/ToolbarList";
import ToolbarTab from "@/components/Shared/ToolbarTab";
import { HelperService } from "@/services/helper.service";
import BaseGrid from "@/components/Shared/BaseGrid";
import FormsHelper from "@/mixins/formsHelper";
import Enums from "@/mixins/enums";
import moment from "moment";
import OrderFilter from "@/components/Filter/OrderFilter";
import SharedMixin from "@/mixins/shared";

const computed = {
  ...mapGetters("order", ["orders", "total", "onLoading"]),
  ...mapGetters("customer", ["customers", "selectedCustomer"]),
  ...mapGetters("tenant", ["tenants", "selectedTenant"]),
  _() {
    return _;
  },
  Enums() {
    return Enums;
  },
  ordersList() {
    return this.orders;
  }
};

export default {
  name: "ordersList",
  components: {
    OrderForm,
    ToolbarList,
    ToolbarTab,
    BaseGrid,
    OrderFilter
  },
  props: {
    withModelId: {
      default: null,
      type: String
    },
    withModelType: {
      default: null,
      type: String
    },
    listMode: {
      default: Enums.LIST_MODE.FULL,
      type: String
    }
  },
  data() {
    return {
      resourceType: this.$t("resource_types.order"),
      tableData: { headers: [], items: [] },
      allClear: true,
      search: "",
      tableOptions: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["created_at"]
      },
      drawer_flag: false
    };
  },
  mixins: [FormsHelper, Enums, SharedMixin],
  /*watch: {
    async withModelId() {
      this.fetchData();
    }
  },*/
  methods: {
    ...mapActions("order", ["ajaxOrderFetchAll", "ajaxOrderDelete"]),
    ...mapActions("ticket", ["ajaxTicketDelete"]),
    ...mapActions("customer", ["ajaxCustomerFetchAll"]),
    ...mapActions("tenant", ["ajaxTenantFetchAll"]),

    async onDelete(item) {
      if (item.state != Enums.STATE_ORDER.OPENED) {
        this.toast(Enums.TOAST_TYPE.ERROR, "", this.$t("toasts.delete_order"));
      } else
        this.$confirm({
          message: this.$t("dialogs.ticketOrder"),
          button: {
            no: this.$t("dialogs.no"),
            yes: this.$t("dialogs.yes")
          },
          callback: async confirm => {
            if (confirm) {
              await this.deleteHelper(
                this.resourceType,
                this.ajaxOrderDelete,
                item,
                payload => payload.p.number
              );
              this.fetchData();
            }
          }
        });
    },
    handleDrawer(val) {
      this.drawer_flag = val;
    },
    async fetchData(needle = null) {
      const _search = needle ? needle : this.search || "";
      let payloadFAP = HelperService.buildFilterAndPagination({
        search: _search,
        pagination: this.tableOptions
      });

      await this.ajaxOrderFetchAll(payloadFAP);
      this.initTable();
    },
    initTable() {
      this.tableData.headers = this.mapHeaders();
      this.tableData.items = this.mapItems();
    },
    mapHeaders() {
      const tableHeaders = [];
      if (this.listMode === Enums.LIST_MODE.FULL) {
        tableHeaders.push({
          text: "Number",
          value: "number",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Customer",
          value: "customer_id",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Tenant",
          value: "tenant_id",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "State",
          value: "state",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Created at",
          value: "created_at",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "End date",
          value: "end_date",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center"
        });
      }
      if (this.listMode === Enums.LIST_MODE.TABBED) {
        tableHeaders.push({
          text: "Number",
          value: "number",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Customer",
          value: "customer_id",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center"
        });
      }
      return tableHeaders;
    },
    mapItems() {
      let map = {};
      if (this.listMode === Enums.LIST_MODE.FULL) {
        map = _.map(this.ordersList, item => {
          item.fields = {
            number: {
              data: item.number,
              dataType: "text",
              css: "text-xs-left"
            },
            customerName: {
              data: item.customer.name,
              dataType: "text",
              css: "text-uppercase"
            },
            tenantName: {
              data: item.tenant.name,
              dataType: "text",
              css: "text-xs-left"
            },
            state: {
              data: this.getEnumTranslationFor("orders", item.state),
              dataType: "text",
              css: "text-xs-left"
            },
            createdAt: {
              data: item.created_at,
              dataType: "date",
              css: "text-xs-left"
            },
            endDate: {
              data: item.end_date,
              dataType: "date",
              css: "text-xs-left"
            }
          };
          (item.click = {
            actionType: "router-link",
            namedRoot: "orderDetails",
            namedRootId: item.id,
            icon: this.$t("icons.detail"),
            show: true
          }),
            (item.actions = {});
          return item;
        });
      }
      if (this.listMode === Enums.LIST_MODE.TABBED) {
        map = _.map(this.ordersList, item => {
          item.fields = {
            number: {
              data: item.number,
              dataType: "text",
              css: "text-xs-left"
            },
            customerName: {
              data: item.customer.name,
              dataType: "text",
              css: "text-xs-left"
            }
          };
          item.click = {
            actionType: "router-link",
            namedRoot: "orderDetails",
            namedRootId: item.id,
            icon: this.$t("icons.detail"),
            css: "text-xs-left",
            show: true
          };
          item.actions = {};
          return item;
        });
      }
      return map;
    },
    handlePaginationChanged(paginationOpts) {
      this.tableOptions = paginationOpts;
      this.fetchData();
    },
    handleFormSucceed() {
      this.closeFormDialog();
      this.fetchData();
    },
    handleFormCancel() {
      this.closeFormDialog();
    },
    goBack() {
      this.tableOptions = { ...this.tableOptions, page: 1 };
    },
    searchFunc: async function(searchQuery) {
      this.search = searchQuery;
      this.goBack();
      await this.fetchData();
    },
    formatDate(date) {
      let newDate = "";
      if (date) {
        newDate = moment(date, "YYYY/MM/DD").format("DD/MM/YYYY");
      }
      return newDate.toString();
    },
    async handleFiltersSelected(allClear, state, customerId, tenantId) {
      this.goBack();
      this.allClear = allClear;
      const filter = {
        //state: state,
        //customer_id : customerId,
        //tenant_id : tenantId
      };
      if (state != "") {
        filter.state = state;
      }
      if (customerId != "") {
        filter.customer_id = customerId;
      }
      if (tenantId != "") {
        filter.tenant_id = tenantId;
      }
      let payloadFAP = HelperService.buildFilterAndPagination({
        filter: filter,
        pagination: this.tableOptions
      });
      await this.ajaxOrderFetchAll(payloadFAP);
      this.initTable();
      this.scrollToTop();
    },
    scrollToTop() {
      this.$vuetify.goTo(0);
    }
  },
  async created() {},
  computed
};
</script>
