<template>
  <v-container fluid>
        <AttachmentsList
            :withModelType="Enums.MODEL_NAME.CUSTOMERS"
            :withModelId="this.selectedCustomer.info.id"
            :machineName="Enums.FILE_TYPE.QUESTIONARIO"
            :permittedTypes="Enums.FILE_TYPE.QUESTIONARIO"
            :listMode="Enums.LIST_MODE.TABBED"
        ></AttachmentsList>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import Enums from "@/mixins/enums";
import AttachmentsList from "@/components/Attachment/AttachmentsList";

const computed = {
  ...mapGetters("login", ["loggedUser"]),
  _() {
    return _;
  },
  Enums() {
    return Enums;
  },
  selectedCustomer() {
    return this.loggedUser;
  }
};

export default {
  name: "documentsList",
  components: {
    AttachmentsList,
  },
  data() {
    return {
      mutex: null,
    };
  },
  /*watch: {
  },*/
  methods: {
  },
  mixins: [Enums],
  async created() {
  },
  computed
};
</script>
