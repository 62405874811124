var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mode == _vm.Enums.FORM_MODE.CREATE ||
    _vm.mode == _vm.Enums.FORM_MODE.UPDATE
    ? _c(
        "v-container",
        { staticClass: "grey lighten-4 title", attrs: { fluid: "" } },
        [
          _c("v-card-title", { staticClass: "form-title title" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm._f("capitalize")(_vm.formTitle)) +
                "\n    "
            )
          ]),
          _c(
            "v-form",
            {
              staticClass: "form-body",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.onSubmit($event)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "form-group required" },
                [
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.immediate",
                        value: "required",
                        expression: "'required'",
                        modifiers: { immediate: true }
                      }
                    ],
                    attrs: {
                      label: _vm.$t("forms.sections.name"),
                      name: "name",
                      counter: _vm.isRootSection,
                      maxlength: _vm.isRootSection ? 100 : ""
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  }),
                  _vm.veeErrors.has("name")
                    ? _c(
                        "span",
                        {
                          class:
                            _vm.Settings.style.class.formFieldRequiredCaption
                        },
                        [_vm._v(_vm._s(_vm.veeErrors.first("name")))]
                      )
                    : _vm._e()
                ],
                1
              ),
              !_vm.isRootSection
                ? _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.immediate",
                            value: "required",
                            expression: "'required'",
                            modifiers: { immediate: true }
                          }
                        ],
                        attrs: {
                          label: _vm.$t("forms.sections.cig"),
                          name: "cig",
                          counter: "10",
                          maxlength: 10,
                          rules: [_vm.rules.length]
                        },
                        model: {
                          value: _vm.form.cig,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "cig", $$v)
                          },
                          expression: "form.cig"
                        }
                      }),
                      _vm.veeErrors.has("cig")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [_vm._v(_vm._s(_vm.veeErrors.first("cig")))]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isRootSection
                ? _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("forms.sections.cup"),
                          name: "cup",
                          counter: "",
                          maxlength: "15"
                        },
                        model: {
                          value: _vm.form.cup,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "cup", $$v)
                          },
                          expression: "form.cup"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("FormButtons", {
                attrs: {
                  disabled:
                    _vm.veeErrors.any() ||
                    this.form.cig.trim().replace(/\s+/g, "").length != 10
                },
                on: { onSave: _vm.onSubmit, onCancel: _vm.onCancel }
              })
            ],
            1
          )
        ],
        1
      )
    : _c(
        "v-container",
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c("v-card-title", [
                    _vm._v(_vm._s(_vm.$t("forms.proceedings.proceeding")))
                  ]),
                  _c(
                    "v-container",
                    [
                      _c("DisplayKV", {
                        attrs: {
                          as: _vm.Enums.DISPLAY_AS.TEXT,
                          label: _vm.$t("forms.sections.name"),
                          value: this.form.name
                        }
                      }),
                      _c("DisplayKV", {
                        attrs: {
                          as: _vm.Enums.DISPLAY_AS.LINK,
                          label: _vm.$t("forms.sections.permalink"),
                          value: this.permalink
                        }
                      }),
                      _c("DisplayKV", {
                        attrs: {
                          as: _vm.Enums.DISPLAY_AS.TEXT,
                          label: _vm.$t("forms.sections.cig"),
                          value: _vm.form.cig
                        }
                      }),
                      _c("DisplayKV", {
                        attrs: {
                          as: _vm.Enums.DISPLAY_AS.TEXT,
                          label: _vm.$t("forms.sections.cup"),
                          value: _vm.form.cup
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }