var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c("ToolbarList", {
            attrs: {
              title: this.$t("report_type." + this.reportType),
              subtitle: _vm.$tc("labels.found_elements", this.total),
              withExportPdf: true,
              withFilter: false,
              reportType: this.reportType
            },
            on: {
              onExportPdf: _vm.exportPdf,
              onReportFilter: _vm.handleFiltersSelected
            }
          }),
          _c("BaseGrid", {
            attrs: {
              id: "content",
              tableName: "reports",
              headers: this.headers,
              items: this.items,
              totalLength: this.total,
              pagination: this.tableOptions,
              loading: this.onLoading
            },
            on: { onPaginationChanged: _vm.handlePaginationChanged }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }