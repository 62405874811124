<template>
  <div>
    <v-card>
      <ToolbarList
        :title="$t('labels.tenants')"
        :subtitle="$tc('labels.found_elements', this.total)"
        :withAdd="$can('addButton', 'tenants')"
        @onAdd="openFormDialog(Enums.FORM_MODE.CREATE)"
      ></ToolbarList>
      <BaseGrid
        tableName="tenants"
        :headers="this.tableData.headers"
        :items="this.tableData.items"
        :totalLength="this.total"
        :pagination="this.tableOptions"
        :loading="this.onLoading"
        @onPaginationChanged="handlePaginationChanged"
        :hideActions="true"
      ></BaseGrid>
    </v-card>

    <v-dialog v-model="formDialog" persistent content-class="edit-form-dialog">
      <TenantForm
        v-if="formDialog"
        :mode="Enums.FORM_MODE.CREATE"
        @formSucceed="handleFormSucceed"
        @formCancel="handleFormCancel"
      ></TenantForm>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import ToolbarList from "@/components/Shared/ToolbarList";
import BaseGrid from "@/components/Shared/BaseGrid";
import TenantForm from "@/components/Tenant/TenantForm";
import FormsHelper from "@/mixins/formsHelper";
import { HelperService } from "@/services/helper.service";
import Enums from "@/mixins/enums";
// import { Ability } from "@casl/ability";
// import {} from "@/abilities";

const computed = {
  ...mapGetters("tenant", ["tenants", "total", "onLoading"]),
  _() {
    return _;
  },
  Enums() {
    return Enums;
  },
  tenantsList() {
    return this.tenants;
  }
};

export default {
  name: "tenantsList",
  components: {
    TenantForm,
    ToolbarList,
    BaseGrid
  },
  data() {
    return {
      resourceType: this.$t("resource_types.tenant"),
      progress: false,
      formDialog: false,
      formMode: "",
      tableData: { headers: [], items: [] },
      tableOptions: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["name"],
        sortDesc: [false]
      }
    };
  },
  methods: {
    ...mapActions("tenant", ["ajaxTenantFetchAll"]),

    async fetchData() {
      let payloadFAP = HelperService.buildFilterAndPagination({
        pagination: this.tableOptions
      });
      await this.ajaxTenantFetchAll(payloadFAP);
      this.initTable();
    },
    initTable() {
      this.tableData.headers = this.mapHeaders();
      this.tableData.items = this.mapItems();
    },
    mapHeaders() {
      const tableHeaders = [];
      tableHeaders.push({
        text: "Name",
        value: "name",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Mail",
        value: "email",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Phone",
        value: "phone",
        sortable: true,
        align: "start"
      });
      tableHeaders.push({
        text: "Website",
        value: "website",
        align: "start",
        sortable: true
      });
      return tableHeaders;
    },
    mapItems() {
      const map = _.map(this.tenantsList, item => {
        item.fields = {
          name: { data: item.name, dataType: "text", css: "text-xs-left" },
          email: { data: item.email, dataType: "text", css: "text-xs-left" },
          phone: { data: item.phone, dataType: "text", css: "text-xs-left" },
          website: { data: item.website, dataType: "link", css: "text-xs-left" }
        };
        item.click = {
          actionType: "router-link",
          namedRoot: "tenantDetails",
          namedRootId: item.id,
          icon: this.$t("icons.detail"),
          css: "text-xs-left",
          show: true
        };
        item.actions = {};
        return item;
      });
      return map;
    },
    handleFormSucceed() {
      this.closeFormDialog();
      this.fetchData();
    },
    handleFormCancel() {
      this.closeFormDialog();
    },
    handlePaginationChanged(paginationOpts) {
      this.tableOptions = paginationOpts;
      this.fetchData();
    }
  },
  /*watch: {
    async withModelId() {
      this.fetchData();
    }
  },*/
  mixins: [FormsHelper, Enums],
  async created() {
    await this.fetchData();
  },
  computed
};
</script>
