var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ApplicationLogFilter", {
        ref: "drawer",
        on: {
          filtersSelected: _vm.handleFiltersSelected,
          drawerChanged: _vm.handleDrawer
        }
      }),
      _c(
        "div",
        { class: { "center-panel": _vm.drawer_flag } },
        [
          this.listMode === _vm.Enums.LIST_MODE.FULL
            ? _c(
                "v-card",
                [
                  _c("ToolbarList", {
                    attrs: {
                      title: _vm.$t("labels.applicationLog"),
                      subtitle: _vm.$tc("labels.found_elements", this.total),
                      withAdd: false,
                      withFilter: true
                    },
                    on: {
                      onFilter: function($event) {
                        return _vm.$refs.drawer.open()
                      }
                    }
                  }),
                  _c("BaseGrid", {
                    attrs: {
                      tableName: "application-log",
                      headers: this.tableData.headers,
                      items: this.tableData.items,
                      totalLength: this.total,
                      pagination: this.tableOptions,
                      loading: this.onLoading,
                      hideActions: true
                    },
                    on: { onPaginationChanged: _vm.handlePaginationChanged }
                  })
                ],
                1
              )
            : _vm._e(),
          this.listMode === _vm.Enums.LIST_MODE.TABBED
            ? _c(
                "v-container",
                [
                  _c(
                    "v-card",
                    [
                      _c("ToolbarTab", {
                        attrs: {
                          subtitle: _vm.$tc(
                            "labels.found_elements",
                            this.total
                          ),
                          withAdd: false
                        }
                      }),
                      _c("BaseGrid", {
                        attrs: {
                          tableName: "application-log",
                          headers: this.tableData.headers,
                          items: this.tableData.items,
                          totalLength: this.total,
                          pagination: this.tableOptions,
                          loading: this.onLoading,
                          hideActions: true
                        },
                        on: { onPaginationChanged: _vm.handlePaginationChanged }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }