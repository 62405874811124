var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("ToolbarDetail", {
        attrs: { title: _vm._f("capitalize")(_vm.detailTitle), withEdit: false }
      }),
      _c("br"),
      _c(
        "v-card",
        [
          _c("ToolbarList", {
            attrs: {
              title: _vm.$t("labels.generationSessionItems"),
              subtitle: _vm.$tc("labels.found_elements", this.total),
              withAdd: false
            }
          }),
          _c("BaseGrid", {
            attrs: {
              tableName: "session-items",
              headers: this.tableData.headers,
              items: this.tableData.items,
              totalLength: this.total,
              pagination: this.tableOptions,
              loading: this.onLoading,
              hideActions: true
            },
            on: { onPaginationChanged: _vm.handlePaginationChanged }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }