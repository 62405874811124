import Vue from "vue";

const UserRoleService = {
  async fetchOne(id) {
    if (id == null) {
      return null;
    }
    try {
      const data = await Vue.$jsonApi.find("role", id, {});
      return data.data[0];
    } catch (error) {
      console.log("fetchOne error: ", error);
      return { error: error };
    }
  },
  async fetchAll() {
    try {
      const res = await Vue.$jsonApi.findAll("roles");

      return res;
    } catch (error) {
      console.log("fetchAll error: ", error);
      return { error: error };
    }
  }
};

export { UserRoleService };
