<template>
  <v-container style="border: 5px solid green;margin-top: 6%">
    <v-row align="center" justify="center">
      <v-col cols="12" class="text-center px-3">
        <img :src="require('../../assets/sf-logo.png')" width="70" />
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <font color="green" size="5">{{ $t("customer_login_greeting") }}</font>
    </v-row>
    <v-row align="center" justify="center" class="my-12">
      <v-col cols="6">
        <v-form>
          <div class="form-group">
            <v-text-field
              class="label-auth"
              :label="$t('forms.auth_registro_accessi.code')"
              v-model="code"
              required              
            ></v-text-field>
          </div>
          <div class="form-group">
            <v-text-field
              class="label-auth"
              :label="$t('forms.auth_registro_accessi.password')"
              :append-icon="showPassword ? 'visibility' : 'visibility_off'"
              @click:append="showPassword = !showPassword"
              :type="showPassword ? 'password' : 'text'"
              v-model="password"
              required
              el-input
              @keyup.enter.native="submit"
            ></v-text-field>
          </div>
        </v-form>
        <v-row align="center" justify="center">
          <font color="green" size="2">Cliccare sulla matita e inserire il risultato della somma</font>
        </v-row>
        <v-row align="center" justify="center">
          <my-captcha :callSuccess="captchaBtn" mode="math" resolve="text"></my-captcha>
        </v-row>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-btn large @click="submit" color="green" v-bind:disabled="btndis">
        <font color="white">{{$t('buttons.auth')}}</font>
      </v-btn>
    </v-row>
  </v-container>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import { AuthService } from "@/services/auth.service";
import Enums from "@/mixins/enums";
import myCaptcha from 'vue-captcha';

const computed = {
  Enums() {
    return Enums;
  }
};

export default {
  components: {
    'my-captcha': myCaptcha
  },
  data() {
    return {
      code: "",
      password: "",
      color: "orange",
      showPassword: true,
      btndis: true
    };
  },
  methods: {
    ...mapActions("login", ["login"]),
    toast(context, mode, text) {
      const messageObj = {
        context,
        mode,
        text,
        snackbar: true
      };
      this.$store.dispatch("snackbar/showMessage", messageObj);
    },
    captchaBtn () {
     this.btndis = false
    },
    async submit() {
      this.color = "orange";
      this.progress = true;
      try {
        const loginRes = await this.login({ username: this.code, password: this.password });
        if (loginRes && loginRes.status && loginRes.status === 200) {
          this.toast(Enums.TOAST_TYPE.SUCCESS, "", this.$t("toasts.login_ok"));
          this.sessionInit();
        }  else {
          this.toast(Enums.TOAST_TYPE.ERROR, "", this.$t("toasts.login_failed"));
        }
      } catch (error) {
        this.color = "red";
        this.progress = false;
        this.toast(Enums.TOAST_TYPE.ERROR, "", this.$t("toasts.network_problems"));
      }
    },
    sessionInit() {
      this.$router.pushCatch({ name: "loading" });
    }
  },
  computed
};
</script>

<style scoped>
.container {
  width: 50%;
}
.v-application .form-group
    .primary--text {
        color: green !important
    }

.button[disabled] {
  cursor: not-allowed;
  opacity: 0.6;
}  

.vue-captcha {
  border: 1px solid green !important;
}

</style>
