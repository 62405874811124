/*  eslint no-shadow: ["error", { "allow": ["state"] }] */
/*  eslint no-param-reassign: ["error", { "props": false }] */
import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";
import { AttachmentService } from "@/services/attachment.service";
import { HelperService } from "@/services/helper.service";

Vue.use(Vuex);

const state = {
  attachments: [],
  selected: {},
  loading: false,
  totalCount: 0
};

const getters = {
  attachments: state => state.attachments,
  selectedAttachment: state => state.selected,
  onLoading: state => state.loading,
  total: state => state.totalCount
};

const mutations = {
  setSelected(state, sel) {
    state.selected = sel;
  },
  setList(state, sel) {
    state.attachments = sel;
  },
  setTotal(state, sel) {
    state.totalCount = Number(sel);
  },
  setLoading(state, sel) {
    state.loading = sel;
  }
};

const actions = {
  async ajaxAttachmentSetSelected({ commit }, payload) {
    await HelperService.genericSetSelected(
      commit,
      AttachmentService.fetchOne,
      payload.id
    );
  },

  async ajaxAttachmentFetchAll({ commit }, payload) {
    const _search = _.isNull(payload) ? "" : _.get(payload, "search", "");
    const _filter = _.isNull(payload) ? "" : _.get(payload, "filter", "");
    const _page = _.isNull(payload)
      ? process.env.VUE_APP_DEFAULT_PAGE
      : _.get(payload, "pagination.page", process.env.VUE_APP_DEFAULT_PAGE);
    const _pageSize = _.isNull(payload)
      ? process.env.VUE_APP_DEFAULT_PAGE_SIZE
      : _.get(
          payload,
          "pagination.itemsPerPage",
          process.env.VUE_APP_DEFAULT_PAGE_SIZE
        );
    const _sortBy = _.isNull(payload)
      ? ""
      : _.get(payload, "pagination.sortBy", "");
    const _sortDescending = _.isNull(payload)
      ? false
      : _.get(payload, "pagination.descending", false);

    let searchFilterPagination = {
      search: _search,
      filter: _filter,
      page: {
        page: _page,
        pageSize: _pageSize
      },
      sort: "",
      include: "type,taggings"
    };
    if (_sortBy && _sortDescending) {
      searchFilterPagination.sort = _sortBy;
    } else {
      searchFilterPagination.sort = `-${_sortBy}`;
    }

    await HelperService.genericFetchAll(
      commit,
      AttachmentService.fetchAll,
      searchFilterPagination,
      null,
      null
    );
  },

  async ajaxAttachmentCreateOrUpdate({ commit }, payload) {
    return HelperService.genericCreateOrUpdate(
      commit,
      AttachmentService.createOrUpdate,
      payload
    );
  },

  async ajaxAttachmentDelete({ commit }, payload) {
    return HelperService.genericDelete(
      commit,
      AttachmentService.delete,
      payload.id
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
