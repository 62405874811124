<template>
  <div>
    <TicketFilter
      @filtersSelected="handleFiltersSelected"
      ref="drawer"
      @drawerChanged="handleDrawer"
    ></TicketFilter>
    <div :class="{ 'center-panel': drawer_flag }">
      <v-card v-if="this.listMode === Enums.LIST_MODE.FULL">
        <ToolbarList
          :title="$t('labels.ticket')"
          :subtitle="$tc('labels.found_elements', this.total)"
          :withSearch="true"
          :withAdd="true"
          @onAdd="openFormDialog(Enums.FORM_MODE.CREATE)"
          @onSearch="searchFunc"
          :withFilter="true"
          @onFilter="$refs.drawer.open()"
          :allClear="allClear"
        ></ToolbarList>
        <BaseGrid
          tableName="tickets"
          :headers="this.tableData.headers"
          :items="this.tableData.items"
          :totalLength="this.total"
          :pagination="this.tableOptions"
          :loading="this.onLoading"
          @onPaginationChanged="handlePaginationChanged"
          :withDelete="true"
          @onDelete="onDelete"
        ></BaseGrid>
      </v-card>
      <v-card v-if="this.listMode === Enums.LIST_MODE.TABBED">
        <ToolbarTab
          :subtitle="$tc('labels.found_elements', this.total)"
          :withAdd="true"
          @onAdd="openFormDialog(Enums.FORM_MODE.CREATE)"
        ></ToolbarTab>
        <BaseGrid
          tableName="tickets"
          :headers="this.tableData.headers"
          :items="this.tableData.items"
          :totalLength="this.total"
          :pagination="this.tableOptions"
          :loading="this.onLoading"
          @onPaginationChanged="handlePaginationChanged"
          :withDelete="true"
          @onDelete="onDelete"
        ></BaseGrid>
      </v-card>

      <v-dialog
        v-model="formDialog"
        persistent
        content-class="edit-form-dialog"
      >
        <TicketForm
          v-if="formDialog"
          :mode="formMode"
          :withModelId="this.withModelId"
          @formSucceed="handleFormSucceed"
          @formCancel="handleFormCancel"
        ></TicketForm>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import TicketForm from "@/components/Ticket/TicketForm";
import ToolbarList from "@/components/Shared/ToolbarList";
import ToolbarTab from "@/components/Shared/ToolbarTab";
import { HelperService } from "@/services/helper.service";
import BaseGrid from "@/components/Shared/BaseGrid";
import FormsHelper from "@/mixins/formsHelper";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import TicketFilter from "@/components/Filter/TicketFilter";

const computed = {
  ...mapGetters("ticket", ["tickets", "total", "onLoading"]),
  ...mapGetters("customer", ["customers"]),
  ...mapGetters("login", ["loggedUser"]),

  _() {
    return _;
  },
  Enums() {
    return Enums;
  },
  ticketsList() {
    return this.tickets;
  },
  customerList() {
    return this.customers;
  }
};

export default {
  name: "ticketsList",
  components: {
    TicketForm,
    ToolbarList,
    ToolbarTab,
    BaseGrid,
    TicketFilter
  },
  props: {
    withModelId: {
      default: null,
      type: String
    },
    withModelType: {
      default: null,
      type: String
    },
    listMode: {
      default: Enums.LIST_MODE.FULL,
      type: String
    }
  },
  data() {
    return {
      resourceType: this.$t("resource_types.ticket"),
      search: "",
      filter: {},
      allClear: true,
      tableData: { headers: [], items: [] },
      tableOptions: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["id"]
      },
      headers_query: false,
      drawer_flag: false
    };
  },
  mixins: [FormsHelper, Enums, SharedMixin],
  methods: {
    ...mapActions("ticket", [
      "ajaxTicketFetchAll",
      "ajaxTicketCreateOrUpdate",
      "ajaxTicketDelete"
    ]),
    ...mapActions("customer", [
      "ajaxCustomerFetchAll",
    ]),

    async onDelete(item) {
      if (item.state != Enums.TICKET_STATUS.OPENED) {
        this.toast(Enums.TOAST_TYPE.ERROR, "", this.$t("toasts.delete_ticket"));
      } else
        this.$confirm({
          message: this.$t("dialogs.deleteConfirmMessage"),
          button: {
            no: this.$t("dialogs.no"),
            yes: this.$t("dialogs.yes")
          },
          callback: async confirm => {
            if (confirm) {
              await this.deleteHelper(
                this.resourceType,
                this.ajaxTicketDelete,
                item,
                payload => payload.p.code
              );
              this.fetchData();
            }
          }
        });
    },
    async fetchData(needle = null) {
      const _search = needle ? needle : this.search || "";

      let _filter = this.$props.withModelId
        ? { tenant_id: this.$props.withModelId, ...this.filter }
        : this.filter;      
      
      if (this.loggedUser.info.role.name === 'customer') {
        const search_value = this.loggedUser.info.email;
        let customerPayloadFAP = HelperService.buildFilterAndPagination({
          filter: {peo: search_value},
        });
        await this.ajaxCustomerFetchAll(customerPayloadFAP);
        _filter = (this.customers && this.customers.length > 0)
        ? { customer_id: this.customers[0].id, ...this.filter }
        : this.filter;
      }
      
      let payloadFAP = HelperService.buildFilterAndPagination({
        search: _search,
        filter: _filter,
        pagination: this.tableOptions,
        headers_query: this.headers_query
      });
      await this.ajaxTicketFetchAll(payloadFAP);
      this.initTable();
    },

    initTable() {
      this.tableData.headers = this.mapHeaders();
      this.tableData.items = this.mapItems();
    },
    mapHeaders() {
      let tableHeaders = [];
      if (this.listMode === Enums.LIST_MODE.FULL) {
        tableHeaders.push({
          text: "Code",
          value: "code",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Customer",
          value: "customer",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Subject",
          value: "subject",
          sortable: true,
          align: "start"
        });
        tableHeaders.push({
          text: "Created",
          value: "created_at",
          sortable: true,
          align: "start"
        });
        tableHeaders.push({
          text: "Type",
          value: "ticket_type",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Subtype",
          value: "ticket_subtype",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "State",
          value: "state",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Internal State",
          value: "internal_state",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Priority",
          value: "priority",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center"
        });
      }
      if (this.listMode === Enums.LIST_MODE.TABBED) {
        tableHeaders.push({
          text: "Name",
          value: "name",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Note",
          value: "note",
          sortable: true,
          align: "start"
        });
        tableHeaders.push({
          text: "Priority",
          value: "priority",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center"
        });
      }
      return tableHeaders;
    },
    mapItems() {
      let map = {};
      if (this.listMode === Enums.LIST_MODE.FULL) {
        map = _.map(this.ticketsList, item => {
          item.fields = {
            code: { data: item.code, dataType: "text", css: "text-xs-left" },
            customer: {
              data: item.customer.name || "",
              dataType: "text",
              css: "text-uppercase"
            },
            subject: {
              data: item.subject,
              dataType: "text",
              css: "text-xs-left"
            },
            created: {
              data: item.created_at,
              dataType: "datetime",
              css: "text-xs-left"
            },
            type: {
              data: this.getEnumTranslationFor("tickets", item.ticket_type),
              dataType: "text",
              css: "text-xs-left"
            },
            subtype: {
              data: this.getEnumTranslationFor("tickets", item.ticket_subtype),
              dataType: "text",
              css: "text-xs-left"
            },
            state: {
              data: this.getEnumTranslationFor("tickets", item.state),
              dataType: "text",
              css: "text-xs-left"
            },
            internalState: {
              data: this.getEnumTranslationFor("tickets", item.internal_state),
              dataType: "text",
              css: "text-xs-left"
            },
            priority: {
              data: item.priority,
              dataType: "text",
              css: "text-xs-left"
            }
          };
          item.click = {
            actionType: "router-link",
            namedRoot: "ticketDetails",
            namedRootId: item.id,
            icon: this.$t("icons.detail"),
            show: true
          };
          item.actions = {};
          return item;
        });
      }
      if (this.listMode === Enums.LIST_MODE.TABBED) {
        map = _.map(this.ticketsList, item => {
          item.fields = {
            name: { data: item.name, dataType: "text", css: "text-xs-left" },
            note: { data: item.note, dataType: "text", css: "text-xs-left" }
          };
          item.click = {
            actionType: "router-link",
            namedRoot: "ticketDetails",
            namedRootId: item.id,
            icon: this.$t("icons.detail"),
            css: "text-xs-left",
            show: true
          };
          item.actions = {};
          return item;
        });
      }
      return map;
    },
    handleDrawer(val) {
      this.drawer_flag = val;
    },
    handlePaginationChanged(paginationOpts) {
      this.tableOptions = paginationOpts;
      this.fetchData();
    },
    handleFormSucceed() {
      this.closeFormDialog();
      this.fetchData();
    },
    handleFormCancel() {
      this.closeFormDialog();
    },
    scrollToTop() {
      this.$vuetify.goTo(0);
    },
    async handleFiltersSelected(
      allClear,
      myticketsAll,
      tenantFilter,
      customerFilter,
      statusFilter,
      intStatusFilter,
      priorityFilter,
      typeFilter,
      subtypeFilter,
      startDateFilter,
      endDateFilter
    ) {
      this.goBack();
      this.allClear = allClear;
      this.filter = {
        customer_id: customerFilter.toString(),
        tenant_id: tenantFilter,
        internal_state: intStatusFilter,
        priority: priorityFilter,
        state: statusFilter,
        ticket_subtype: subtypeFilter,
        ticket_type: typeFilter,
        lt: { end_date: endDateFilter },
        gt: { create_date: startDateFilter }
      };

      if (this.filter.customer_id == "" || this.filter.customer_id == null)
        delete this.filter.customer_id;
      if (this.filter.tenant_id == "" || this.filter.tenant_id == null)
        delete this.filter.tenant_id;
      if (
        this.filter.internal_state == "" ||
        this.filter.internal_state == null
      )
        delete this.filter.internal_state;
      if (this.filter.priority == "" || this.filter.priority == null)
        delete this.filter.priority;

      if (this.filter.state == "" || this.filter.state == null)
        this.filter.state = "OPENED";
      else if (this.filter.state == "ALL") delete this.filter.state;
      if (
        this.filter.ticket_subtype == "" ||
        this.filter.ticket_subtype == null
      )
        delete this.filter.ticket_subtype;
      if (this.filter.ticket_type == "" || this.filter.ticket_type == null)
        delete this.filter.ticket_type;
      if (this.filter.lt.end_date == null) delete this.filter.lt;
      if (this.filter.gt.create_date == null) delete this.filter.gt;

      this.headers_query = false;
      if (myticketsAll == "MYTICKETS_YES") this.headers_query = true;

      await this.fetchData();
      this.scrollToTop();
    },
    goBack() {
      this.tableOptions = { ...this.tableOptions, page: 1 };
    },
    searchFunc: async function(searchQuery) {
      this.search = searchQuery
      await this.fetchData();
    },
  },

  async created() {},
  computed
};
</script>
