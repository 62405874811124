import { render, staticRenderFns } from "./CustomerAutocomplete.vue?vue&type=template&id=4da9f517&"
import script from "./CustomerAutocomplete.vue?vue&type=script&lang=js&"
export * from "./CustomerAutocomplete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VListItemContent,VListItemSubtitle,VListItemTitle})


/* hot reload */
if (module.hot) {
  var api = require("/home/deploy/netcrm/staging/source/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4da9f517')) {
      api.createRecord('4da9f517', component.options)
    } else {
      api.reload('4da9f517', component.options)
    }
    module.hot.accept("./CustomerAutocomplete.vue?vue&type=template&id=4da9f517&", function () {
      api.rerender('4da9f517', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Customer/CustomerAutocomplete.vue"
export default component.exports