<template>
  <v-container fluid>
    <ToolbarDetail
      :title="detailTitle | capitalize"
      @onBack="setAgreementFlag(true)"
    ></ToolbarDetail>

    <template v-if="loaded">
      <v-tabs
        class="my-4"
        v-model="activeTab"
        background-color="secondary"
        dark
        slider-color="accent"
        @change="syncUrl"
      >
        <v-tab name="invoicing_periods1">{{
          $t("labels.invoicing_periods1")
        }}</v-tab>
        <v-tab name="invoicing_periods2">{{
          $t("labels.invoicing_periods2")
        }}</v-tab>
        <v-tab name="invoices_management">{{
          $t("labels.invoices_management")
        }}</v-tab>
        <v-tab name="invoicing_data">{{ $t("labels.invoicing_data") }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <v-card flat>
            <InvoicePeriodsGrid
              :selectedItem="this.preparedSelected()"
              :singleRow="true"
              :onReloadPage="onReloadPage"
            ></InvoicePeriodsGrid>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <InvoicePeriodsList
              :withModelId="this.preparedSelected().id"
              :selectedItem="this.preparedSelected()"
              :listMode="Enums.LIST_MODE.TABBED"
              :onReloadPage="onReloadPage"
            ></InvoicePeriodsList>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <InvoicesList
              :withModelId="this.preparedSelected().id"
              :selectedItem="this.preparedSelected()"
              :listMode="Enums.LIST_MODE.TABBED"
              :onReloadPage="onReloadPage"
            ></InvoicesList>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <InvoicingData
              :withModelId="this.preparedSelected().id"
              :selectedItem="this.preparedSelected()"
              :listMode="Enums.LIST_MODE.TABBED"
              :onReloadPage="onReloadPage"
            ></InvoicingData>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </v-container>
</template>

<script>
import _ from "lodash";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { mapActions, mapGetters, mapMutations } from "vuex";
import FormsHelper from "@/mixins/formsHelper";
import InvoicingData from "@/components/Agreement/InvoicingData";
import InvoicePeriodsGrid from "@/components/InvoicePeriod/InvoicePeriodsGrid";
import InvoicePeriodsList from "@/components/InvoicePeriod/InvoicePeriodsList";
import InvoicesList from "@/components/Invoice/InvoicesList";
import ToolbarDetail from "@/components/Shared/ToolbarDetail";

export default {
  name: "AgreementInvoicing",
  props: {
    id: {
      required: true
    }
  },
  data() {
    return {
      resourceType: this.$t("resource_types.agreement_invoicing"),
      activeTab: null,
      loaded: false
    };
  },
  components: {
    InvoicePeriodsGrid,
    ToolbarDetail,
    InvoicesList,
    InvoicePeriodsList,
    InvoicingData
  },
  mixins: [SharedMixin, Enums, FormsHelper],
  watch: {
    id() {
      this.initComponent();
    }
  },
  methods: {
    ...mapActions("agreement", [
      "ajaxAgreementSetSelected",
      "ajaxAgreementCreateOrUpdate"
    ]),
    ...mapMutations("filters", ["setAgreementFilter", "setAgreementFlag"]),

    async initComponent() {
      await this.ajaxAgreementSetSelected({ id: this.$props.id });
      this.loaded = true;
    },
    syncUrl() {},
    handleFormSucceed() {
      this.closeFormDialog();
      this.ajaxAgreementSetSelected({ id: this.$props.id });
    },
    handleFormCancel() {
      this.closeFormDialog();
    },
    preparedSelected() {
      return _.clone(this.selectedAgreement);
    },
    async onReloadPage() {
      await this.ajaxAgreementSetSelected({ id: this.$props.id });
    }
  },
  computed: {
    ...mapGetters("agreement", [
      "agreements",
      "selectedAgreement",
      "total",
      "onLoading"
    ]),
    Enums() {
      return Enums;
    },
    agreementURL() {
      const r = this.$router.resolve({
        name: "agreements",
        params: {}
      });
      return r.href;
    },
    detailTitle() {
      return this.$t("labels.detailResourceTypeName", {
        resourceType: this.resourceType,
        resourceName: this.selectedAgreement.code
      });
    }
  },
  created() {
    this.initComponent();
  }
};
</script>
