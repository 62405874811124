<template>
  <v-navigation-drawer
    class="filter-drawer"
    v-model="drawer"
    v-show="drawer"
    absolute
    temporary
  >
    <v-toolbar color="primary" dark>
      <v-toolbar-title>{{ $t("filters.filters") }}</v-toolbar-title>
    </v-toolbar>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ $t("filters.status") }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="false"
          matchAttribute="value"
          v-model="statesAll"
          :alwaysShowAll="true"
          @change="getAllFiltersSelected"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ $t("filters.cashed") }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="false"
          matchAttribute="value"
          v-model="cashesAll"
          :alwaysShowAll="true"
          @change="getAllFiltersSelected"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.model_type") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="false"
          matchAttribute="value"
          v-model="modelTypesAll"
          :alwaysShowAll="true"
          @change="getAllFiltersSelected"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ $t("filters.customers") }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="false"
          matchAttribute="value"
          v-model="customersAll"
          :autocomplete="true"
          @change="getAllFiltersSelected"
          @search="handleCustomerSearch"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.start_date") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <datetime v-model="startDate" class="dateComponent"></datetime>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.end_date") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <datetime v-model="endDate" class="dateComponent"></datetime>
      </v-list-group>
    </v-list>
    <v-col align="right">
      <v-btn color="primary" small outlined @click="clearFilters">{{
        $t("buttons.clear")
      }}</v-btn>
    </v-col>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import _ from "lodash";
import Enums from "@/mixins/enums";
import FilterList from "@/components/Shared/FilterList";
import SharedMixin from "@/mixins/shared";

export default {
  components: {
    FilterList
  },
  mixins: [SharedMixin],
  data() {
    return {
      drawer: false,
      statesAll: [
        {
          name: this.$t("filters.all"),
          value: Enums.STATE_INVOICE.ALL,
          checked: false,
          enabled: false,
          all: true
        },
        {
          name: this.$t("filters.emitted"),
          value: Enums.STATE_INVOICE.EMITTED,
          checked: false,
          enabled: false
        },
        {
          name: this.$t("filters.not_emitted"),
          value: Enums.STATE_INVOICE.NOT_EMITTED,
          checked: false,
          enabled: false
        }
      ],
      cashesAll: [
          {
          name: this.$t("filters.all"),
          value: Enums.CASHED_INVOICE.ALL,
          checked: false,
          enabled: false,
          all: true
        },
        {
          name: this.$t("filters.YES"),
          value: Enums.CASHED_INVOICE.CASHED,
          checked: false,
          enabled: false
        },
        {
          name: this.$t("filters.NO"),
          value: Enums.CASHED_INVOICE.NOT_CASHED,
          checked: false,
          enabled: false
        }
      ],
      modelTypesAll: [
        {
          name: this.$t("filters.all"),
          value: Enums.CASHED_INVOICE.ALL,
          checked: false,
          enabled: false,
          all: true
        },
        {
          name: this.$t("filters.agreement"),
          value: Enums.MODEL_NAME.AGREEMENTS,
          checked: false,
          enabled: false
        },
        {
          name: this.$t("filters.ticket"),
          value: Enums.MODEL_NAME.TICKET,
          checked: false,
          enabled: false
        },
        {
          name: this.$t("filters.order"),
          value: Enums.MODEL_NAME.ORDERS,
          checked: false,
          enabled: false
        }
      ],
      customersAll: [],
      customersSelected: null,
      startDate: null,
      startDateFormatted: null,
      endDate: null,
      endDateFormatted: null,
    };
  },
  methods: {
    ...mapActions("customer", ["ajaxCustomerFetchAll"]),
    ...mapMutations("filters", ["setInvoiceFilter", "setInvoiceFlag"]),

    save() {
      this.setInvoiceFilter({
        statesAll: this.statesAll,
        cashesAll: this.cashesAll,
        modelTypesAll: this.modelTypesAll,
        customersAll: this.customersAll,
        startDate: this.startDate,
        endDate: this.endDate
      });
    },
    load() {
      if (!this.invoice_flag) return false;
      this.setInvoiceFlag(false);
      if (_.isEmpty(this.invoice_filter)) return false;
      this.statesAll = this.invoice_filter.statesAll;
      this.cashesAll = this.invoice_filter.statesAll;
      this.modelTypesAll = this.invoice_filter.modelTypesAll;
      this.customersAll = this.invoice_filter.customersAll;
      this.startDate = this.invoice_filter.startDate;
      this.endDate = this.invoice_filter.endDate;
      return true;
    },

    open() {
      this.drawer = true;
    },

    getText(customer) {
      let name = customer ? `${customer.name}` : "";
      if (customer.tenant && customer.tenant.machine_name) {
        name = `${customer.tenant.machine_name} - ${name}`;
      }
      return name;
    },
    handleCustomerSearch(query) {
      if (query) {
        this.getCustomers(query);
      }
    },
    getCustomers: _.debounce(async function getCustomers(val) {
      const filterAndPagination = { search: val, include: "tenant" };
      await this.ajaxCustomerFetchAll(filterAndPagination);

      let customersArray = [];
      if (this.customers) {
        this.customers.forEach(function(customer) {
          customersArray.push({
            value: customer.id,
            subtitle: customer.tenant.name,
            name: customer.name,
            checked: false,
            enabled: false
          });
        });
      }
      this.customersAll = _.orderBy(
        this.mergeWithSelected(customersArray, this.customersAll),
        "name"
      );
    }, process.env.VUE_APP_DEBOUNCE_TIME),
    formatDate(date) {
      if (!date) {
        return null;
      } else {
        return date.substring(0, date.indexOf("T"));
      }
    },
    //get filtri selezionati
    getAllFiltersSelected() {
      let stateFilter = this.computeFilter(this.statesAll);
      let cashFilter = this.computeFilter(this.cashesAll);
      let modelTypeFilter = this.computeFilter(this.modelTypesAll);
      let customerFilter = this.computeFilter(this.customersAll);
      let startDateFilter = this.startDateFormatted;
      let endDateFilter = this.endDateFormatted;

      let allClear = this.computeClear([
        stateFilter,
        cashFilter,
        modelTypeFilter,
        customerFilter,
        startDateFilter,
        endDateFilter
      ]);

      this.save();
      this.$emit(
        "filtersSelected",
        allClear,
        stateFilter,
        cashFilter,
        modelTypeFilter,
        customerFilter,
        startDateFilter,
        endDateFilter
      );
    },
    //svuota filtri
    clearFilters() {
      let stateFilter = "";
      let cashFilter = "";
      let modelTypeFilter = "";
      let customerFilter = "";
      let startDateFilter = null;
      let endDateFilter = null;

      this.statesAll = this.resetFilter(this.statesAll);
      this.cashesAll = this.resetFilter(this.cashesAll);
      this.modelTypesAll = this.resetFilter(this.modelTypesAll);
      this.customersAll = this.resetFilter(this.customersAll);
      this.startDate = null;
      this.endDate = null;

      this.save();
      this.$emit(
        "filtersSelected",
        true,
        stateFilter,
        cashFilter,
        modelTypeFilter,
        customerFilter,
        startDateFilter,
        endDateFilter
      );
    }
  },
  filters: {},
  computed: {
    ...mapGetters("customer", ["customers"]),
    ...mapGetters("filters", ["invoice_filter", "invoice_flag"]),
    Enums() {
      return Enums;
    }
  },
  props: {},
  watch: {
    startDate(val) {
      if (val != "" && val != null) {
        this.startDateFormatted = this.formatDate(val);
        this.getAllFiltersSelected();
      } else this.startDateFormatted = null;
    },
    endDate(val) {
      if (val != "" && val != null) {
        this.endDateFormatted = this.formatDate(val);
        this.getAllFiltersSelected();
      } else this.endDateFormatted = null;
    },
    drawer(val) {
      this.$emit("drawerChanged", val);
    }
  },
  async mounted() {
    if (!this.load()) {
      this.getCustomers("");
    }
    this.getAllFiltersSelected();
  }
};
</script>

<style scoped>
.dateComponent {
  border: 1px solid grey;
  margin-left: 15px;
  margin-right: 120px;
}
</style>
