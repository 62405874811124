<template>
  <v-container fluid class="form-container title">
    <div
      v-if="mode == Enums.FORM_MODE.CREATE || mode == Enums.FORM_MODE.UPDATE"
    >
      <v-card-title class="form-title title">
        {{ this.formTitle | capitalize }}
      </v-card-title>
      <v-form class="form-body" @submit.prevent="onSubmit">
        <div class="form-group required">
          <CustomerAutocomplete
            :mode="mode"
            v-model="form.customer"
            itemText="name"
            itemValue="id"
            :returnObject="true"
            :label="$t('forms.tickets.customer')"
            v-validate.immediate="'required'"
            :disabled="mode == Enums.FORM_MODE.UPDATE"
            @input="handleCustomerChange"
            :fetchFunction="ajaxCustomerFetchAll"
            :getter="customers"
            name="customer"
          />
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('customer')"
            >{{ veeErrors.first("customer") }}</span
          >
        </div>
        <v-container>
          <v-row>
            <v-col class="form-group">
              <v-select
                v-model="form.ticket_type"
                :items="this.ticket_types_create"
                item-text="text"
                item-value="value"
                :label="$t('forms.tickets.type')"
                :disabled="
                  mode == Enums.FORM_MODE.UPDATE || _.isEmpty(form.customer)
                "
                @input="handleTypeChange"
                v-validate.immediate="'required'"
                name="type"
              ></v-select>
              <span
                :class="Settings.style.class.formFieldRequiredCaption"
                v-if="veeErrors.has('type')"
                >{{ veeErrors.first("type") }}</span
              >
            </v-col>
            <v-dialog
              v-model="dialog"
              width="500"
            >
              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  {{this.$t("dialogs.info_ticket_subtype")}}
                </v-card-title>
                <br>
                <v-card-text>
                  {{this.$t("dialogs.info_time_budget_ticket")}}
                </v-card-text>
                <v-card-text>
                  {{this.$t("dialogs.info_flat_ticket")}}
                </v-card-text>
                <v-card-text>
                  {{this.$t("dialogs.info_warrenty_assistance_ticket")}}
                </v-card-text>
                <v-card-text>
                  {{this.$t("dialogs.info_without_contract_ticket")}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="dialog = false">
                    {{this.$t("dialogs.cancel")}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-col class="form-group">                       
              <v-select
                ref="theSelect"
                v-model="form.ticket_subtype"
                :items="this.ticket_subtypes"
                item-text="text"
                item-value="value"
                :label="$t('forms.tickets.subtype')"
                :disabled="
                  mode == Enums.FORM_MODE.UPDATE || form.ticket_type == ''
                "
                name="subtype"
                v-validate.immediate="'required'"
                prepend-icon="info"
                @click:prepend="showInfo"
                @input="this.handleSubtypeChange"
              ></v-select>
              <span
                :class="Settings.style.class.formFieldRequiredCaption"
                v-if="veeErrors.has('subtype')"
                >{{ veeErrors.first("subtype") }}</span
              >
            </v-col>
            <v-col class="form-group">
              <v-select
                v-validate.immediate="'required'"
                v-model="form.model_id"
                :items="this.agreements_services"
                item-text="service.id"
                item-value="id"
                :label="$t('forms.tickets.service')"
                :disabled="
                  mode == Enums.FORM_MODE.UPDATE ||
                    form.ticket_subtype == '' ||
                    !agreementsEnabled
                "
                name="agreement"
              >
                <template slot="item" slot-scope="data">
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item.service.name"
                    ></v-list-item-title>
                    <v-list-item-subtitle>
                      #{{ data.item.agreement.id }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
                <template slot="selection" slot-scope="data">
                  {{ data.item.service.name }} (#{{ data.item.agreement.id }})
                </template>
              </v-select>
              <span
                :class="Settings.style.class.formFieldRequiredCaption"
                v-if="veeErrors.has('agreement')"
                >{{ veeErrors.first("agreement") }}</span
              >
            </v-col>
          </v-row>
        </v-container>
        <div class="form-group required">
          <v-text-field
            :label="$t('forms.tickets.subject')"
            v-model="form.subject"
            name="subject"
            v-validate.immediate="'required'"
          ></v-text-field>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('subject')"
            >{{ veeErrors.first("subject") }}</span
          >
        </div>
        <div class="form-group">
          <v-textarea
            :label="$t('forms.tickets.detail')"
            v-model="form.detail"
            name="detail"
            auto-grow
            rows="1"
          ></v-textarea>
        </div>
        <div class="form-group">
          <v-select
            v-model="form.users"
            :items="users"
            :item-text="getOperatorText"
            item-value="id"
            return-object
            :label="$t('forms.tickets.operators')"
            multiple
            name="operators"
          >
          </v-select>
        </div>
        <v-container>
          <v-row>
            <v-col class="form-group">
              <DatePicker
                :label="$t('forms.tickets.due_date')"
                v-model="form.due_date"
                name="due_date"
              ></DatePicker>
            </v-col>
            <v-col class="form-group">
              <v-select
                v-model="form.priority"
                :items="this.priorities"
                item-text="text"
                item-value="value"
                :label="$t('forms.tickets.priority')"
                v-validate.immediate="'required'"
                name="priority"
              ></v-select>
              <span
                :class="Settings.style.class.formFieldRequiredCaption"
                v-if="veeErrors.has('priority')"
                >{{ veeErrors.first("priority") }}</span
              >
            </v-col>
            <v-col class="form-group">
              <v-select
                v-model="form.category"
                :items="this.categories"
                item-text="text"
                item-value="value"
                :label="$t('forms.tickets.category')"
                name="category"
              ></v-select>
            </v-col>
            <v-col class="form-group">
              <v-select
                v-model="form.opened_by"
                :items="this.open_channel"
                item-text="text"
                item-value="value"
                :label="$t('forms.tickets.opened_by')"
                name="opened_by"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
        <div v-if="mode == Enums.FORM_MODE.UPDATE" class="form-group">
          <v-textarea
            :label="$t('forms.tickets.additional_costs')"
            v-model="form.additional_costs"
            name="additional_costs"
            auto-grow
            rows="1"
          ></v-textarea>
        </div>
        <div v-if="mode == Enums.FORM_MODE.UPDATE" class="form-group">
          <v-textarea
            :label="$t('forms.tickets.final_note')"
            v-model="form.final_note"
            name="final_note"
            auto-grow
            rows="1"
          ></v-textarea>
        </div>
        <!-- BUTTONS -->
        <FormButtons
          @onSave="onSubmit"
          @onCancel="onCancel"
          :disabled="veeErrors.any()"
        />
      </v-form>
    </div>
    <div
      v-if="mode === Enums.FORM_MODE.SHOW || mode === Enums.FORM_MODE.READONLY"
    >
      <!-- ############# LEFT COLUMN ############# -->
      <v-container>
        <v-row>
          <!-- ############# LEFT COLUMN ############# -->
          <v-col cols="8">
            <v-card flat>
              <v-card-text>
                <p class="ticket-details-label text-sm-left font-weight-medium">
                  {{ $t("forms.tickets.subject") }}
                </p>
                <v-text-field
                  flat
                  solo
                  readonly
                  hide-details
                  :value="this.form.subject"
                  class="ticket-details"
                >
                </v-text-field>
              </v-card-text>
            </v-card>

            <v-card flat>
              <v-card-text>
                <p class="ticket-details-label text-sm-left font-weight-medium">
                  {{ $t("forms.tickets.detail") }}
                </p>
                <v-textarea
                  flat
                  solo
                  readonly
                  auto-grow
                  hide-details
                  :value="this.form.detail"
                  class="text-sm-left ticket-details"
                ></v-textarea>
              </v-card-text>
            </v-card>

            <v-card flat>
              <v-card-text>
                <p class="ticket-details-label text-sm-left font-weight-medium">
                  {{ $t("forms.tickets.additional_costs") }}
                </p>
                <v-text-field
                  flat
                  solo
                  readonly
                  hide-details
                  :value="this.form.additional_costs"
                  class="ticket-details"
                ></v-text-field>
              </v-card-text>
            </v-card>

            <v-card flat>
              <v-card-text>
                <p class="ticket-details-label text-sm-left font-weight-medium">
                  {{ $t("forms.tickets.final_note") }}
                </p>

                <v-text-field
                  flat
                  solo
                  readonly
                  hide-details
                  :value="this.form.final_note"
                  class="ticket-details"
                ></v-text-field>
              </v-card-text>
            </v-card>

            <!-- ################# Activities ################### -->

            <TicketActivities
              modelType="tickets"
              :modelId="Number(this.form.id)"
              :ticketState="this.form.state"
              :ticketInternalState="this.form.internal_state"
              :ticketSubType="this.form.ticket_subtype"
              :ticketType="this.form.ticket_type"
            ></TicketActivities>
          </v-col>
          <!-- ############# RIGHT COLUMN ############# -->
          <v-col cols="4">
            <v-card class="info lighten-3 elevation-3 xs-buttons">
              <v-card-text>
                <v-layout row wrap mb-1 ticket-info>
                  <v-flex xs3>{{ $t("forms.tickets.state") }}</v-flex>
                  <v-flex xs3 class="font-weight-medium">
                    {{ getEnumTranslationFor("tickets", form.state) }}
                  </v-flex>
                  <v-flex xs6 class="py-2">
                    <v-btn
                      class="mr-3"
                      small
                      @click.native="quickStatusAction()"
                      v-show="
                        form.state != Enums.TICKET_STATUS.CLOSED &&
                          form.internal_state ==
                            Enums.TICKET_INTERNAL_STATUS.FINISHED
                      "
                      >{{
                        getEnumTranslationFor("tickets", getNextStatusAction())
                      }}</v-btn
                    >
                  </v-flex>
                </v-layout>
                <v-layout row wrap mb-1 ticket-info>
                  <v-flex xs3>{{ $t("forms.tickets.internal_state") }}</v-flex>
                  <v-flex xs3 class="font-weight-medium">
                    {{
                      getEnumTranslationFor("tickets", this.form.internal_state)
                    }}
                  </v-flex>
                  <v-flex
                    xs6
                    class="py-2"
                    v-show="this.form.state != Enums.TICKET_STATUS.CLOSED"
                  >
                    <v-btn
                      class="mr-3"
                      v-for="action in internalStatusNextAction"
                      :key="action"
                      small
                      color="primary"
                      @click.native="quickInternalStatusAction(action)"
                      >{{ getEnumTranslationFor("tickets", action) }}</v-btn
                    >
                  </v-flex>
                </v-layout>
                <!--
                <v-layout row wrap mb-1 ticket-info>
                  <v-flex xs3>{{ $t("forms.tickets.total_hours") }}</v-flex>
                  <v-flex xs3>{{ this.form.total_hours }}</v-flex>
                </v-layout>
                -->
                <v-layout row wrap mb-1 ticket-info>
                  <v-flex xs3>{{
                    $t("forms.tickets.billed_total_hours")
                  }}</v-flex>
                  <v-flex xs3>{{ getBilledTotalHours() }}</v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
            <br />
            <div class="subtitle-2">{{ $t("forms.tickets.ticket_info") }}</div>
            <v-card class="elevation-2">
              <v-card-text>
                <v-row class="ticket-info">
                  <v-col cols="4">{{ $t("forms.tickets.ticket") }}:</v-col>
                  <v-col cols="8">{{ this.form.code }}</v-col>
                </v-row>
                <v-row class="ticket-info">
                  <v-col cols="4">{{ $t("forms.tickets.ticket_type") }}:</v-col>
                  <v-col cols="8">{{ getTicketType() }}</v-col>
                </v-row>
                <v-row class="ticket-info">
                  <v-col cols="4"
                    >{{ $t("forms.tickets.ticket_subtype") }}:</v-col
                  >
                  <v-col cols="8">{{ getTicketSubtype() }}</v-col>
                </v-row>

                <v-row class="ticket-info">
                  <v-col cols="4">{{ $t("forms.tickets.operators") }}:</v-col>
                  <v-col cols="8">{{ currentOperator() || "-" }}</v-col>
                </v-row>
                <v-row class="ticket-info">
                  <v-col cols="4">{{ $t("forms.tickets.creator") }}:</v-col>
                  <v-col cols="8">{{ creator() || "-" }}</v-col>
                </v-row>
                <v-row class="ticket-info">
                  <v-col cols="4">{{ $t("forms.tickets.priority") }}:</v-col>
                  <v-col cols="8">{{ getPriority() }}</v-col>
                </v-row>
                <v-row class="ticket-info">
                  <v-col cols="4">{{ $t("forms.tickets.category") }}:</v-col>
                  <v-col cols="8">{{ getCategory() }}</v-col>
                </v-row>
                <v-row class="ticket-info">
                  <v-col cols="4">{{ $t("forms.tickets.opened_by") }}:</v-col>
                  <v-col cols="8">{{ getOpenedBy() }}</v-col>
                </v-row>
                <v-row class="ticket-info">
                  <v-col cols="4">{{ $t("forms.tickets.created_at") }}:</v-col>
                  <v-col cols="8">{{ this.form.created_at | date }}</v-col>
                </v-row>
                <v-row class="ticket-info">
                  <v-col cols="4">{{ $t("forms.tickets.due_date") }}:</v-col>
                  <v-col cols="8">{{ this.form.due_date | date }}</v-col>
                </v-row>
                <v-row
                  class="ticket-info"
                  v-if="this.form.state === Enums.TICKET_STATUS.OPENED"
                >
                  <v-col cols="4">{{ $t("forms.tickets.age") }}:</v-col>
                  <v-col cols="8">{{ this.ticketTiming() }}</v-col>
                </v-row>
                <v-row class="ticket-info" v-else>
                  <v-col cols="4"
                    >{{ $t("forms.tickets.processing_time") }}:</v-col
                  >
                  <v-col cols="8">{{ this.ticketTiming() }}</v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <br />
            <div class="subtitle-2">
              {{ $t("forms.tickets.customer_info") }}
            </div>
            <v-card class="elevation-2">
              <v-card-text>
                <v-row class="ticket-info">
                  <v-col cols="4">{{ $t("forms.tickets.customer") }}:</v-col>
                  <v-col cols="8">{{ this.form.customer.name || "" }}</v-col>
                </v-row>

                <v-row class="ticket-info">
                  <v-col cols="4"
                    >{{ $t("forms.tickets.mechanographyc_code") }}:</v-col
                  >
                  <v-col cols="8">{{
                    this.form.customer.mechanographyc_code || ""
                  }}</v-col>
                </v-row>
                <v-row class="ticket-info">
                  <v-col cols="4">{{ $t("forms.tickets.piva") }}:</v-col>
                  <v-col cols="8">{{ this.form.customer.piva || "" }}</v-col>
                </v-row>
                <v-row class="ticket-info">
                  <v-col cols="4">{{ $t("forms.tickets.city") }}:</v-col>
                  <v-col cols="8"
                    >{{ this.form.customer.city || "" }} ({{
                      this.form.customer.province || ""
                    }})</v-col
                  >
                </v-row>

                <v-layout
                  v-if="showAgreementDetail()"
                  row
                  wrap
                  mb-1
                  ticket-info
                >
                  <v-flex xs3>{{ $t("forms.tickets.agreement") }}:</v-flex>
                  <v-flex xs9>{{ getAgreementCode() }}</v-flex>
                </v-layout>
                <v-layout
                  v-if="showAgreementDetail()"
                  row
                  wrap
                  mb-1
                  ticket-info
                >
                  <v-flex xs3>{{ $t("forms.tickets.services") }}:</v-flex>
                  <v-flex xs9>{{ this.name_services }}</v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-container>
</template>

<script>
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { mapActions, mapGetters } from "vuex";
import CustomerAutocomplete from "@/components/Customer/CustomerAutocomplete";
import FormButtons from "@/components/Shared/FormButtons";
import _ from "lodash";
import DatePicker from "@/components/Shared/DatePicker";
import { HelperService } from "@/services/helper.service";
import moment from "moment";
import TicketActivities from "./TicketActivities";

export default {
  name: "TicketForm",
  props: {
    mode: {
      default: Enums.FORM_MODE.CREATE,
      type: String
    },
    advancedRenewalMode: {
      default: false,
      type: Boolean
    },
    selectedItem: Object
  },
  data() {
    return {
      dialog: false,
      agreementsEnabled: false,
      resourceType: this.$t("resource_types.ticket"),
      ticket_types: [{ value: "ORDER" }, { value: "TECH_SUPPORT" }],
      ticket_types_create: [{ value: "TECH_SUPPORT" }],
      ticket_subtypes: [
        { value: "TIME_BUDGET", contract: true, tooltip: "CIAO SONO IO"},
        { value: "FLAT", contract: true },
        {
          value: "WARRANTY_ASSISTANCE",
          contract: false
        },
        {
          value: "WITHOUT_CONTRACT",
          contract: false
        }
      ],
      priorities: [{ value: "LOW" }, { value: "MEDIUM" }, { value: "URGENT" }],
      open_channel: [{ value: "CALL" }, { value: "EMAIL" }],
      categories: [
        { value: "HARDWARE" },
        { value: "HARDWARE_SOFTWARE" },
        { value: "IMPIANTI" },
        { value: "NETWORK" },
        { value: "SOFTWARE" }
      ],
      agreements_services_FLAT: false,
      agreements_services_TIME_BUDGET: false,
      form: {
        id: "",
        subject: "",
        detail: "",
        additional_costs: "",
        final_note: "",
        ticket_type: "",
        ticket_subtype: "",
        code: "",
        user: "",
        users: [],
        priority: "",
        category: "",
        opened_by: "",
        created_at: "",
        end_date: "",
        customer: {},
        due_date: this.generateDate(),
        state: "",
        internal_state: "",
        total_hours: "",
        billed_total_hours: "",
        tenant: "",
        model_id: "",
        model_type: "",
        agreement: ""
      },
      name_services: "",
      info_subtype: "Ticket 'Time Budget' associato ad un contratto di tipo Assistenza le cui ore verranno scalate nel contratto. Ticket 'Flat' dipendono da un qualsiasi tipo di contratto diverso da Assistenza le cui ore verranno solo conteggiate."+
                  +"Ticket 'Assistenza in garanzia' non associato ad alcun contratto le cui ore non verranno fatturate."+
                  +"Ticket 'Senza contratto' non associato ad alcun contratto le cui ore verranno fatturate alla chiusura del ticket."
    };
  },
  components: {
    DatePicker,
    TicketActivities,
    FormButtons,
    CustomerAutocomplete
  },
  mixins: [SharedMixin, Enums],
  watch: {
    selectedItem() {
      this.initForm();
      this.initComp();
    }
  },
  methods: {
    ...mapActions("ticket", ["ajaxTicketCreateOrUpdate", "ajaxTicketDelete"]),
    ...mapActions("user", ["ajaxUserFetchAll", "ajaxUserFilterAndOrder"]),
    ...mapActions("customer", ["ajaxCustomerFetchAll"]),
    ...mapActions("agreementService", [
      "ajaxAgreementServiceFetchAll",
      "ajaxAgreementServiceSetSelectedTemp"
    ]),
    ...mapActions("agreement", [
      "ajaxAgreementFetchAll",
      "ajaxAgreementSetSelectedTemp"
    ]),
    reloadPage() {},
    getOperatorText(item) {
      return `${item.first_name} ${item.last_name}`;
    },
    showAgreementDetail() {
      return (
        (!this.form.ticket_subtype && !this.form.ticket_type) ||
        this.form.ticket_subtype === "FLAT" ||
        this.form.ticket_subtype === "TIME_BUDGET"
      );
    },
    getAgreementCode() {
      if (this.form.model_id) {
        if (this.form.agreement) {
          return this.form.agreement.code;
        }
      }
      return "";
    },
    async retrieveServicesString() {
      let services;

      if (this.form.model_type == "agreements" && this.form.model_id) {
        await this.ajaxAgreementSetSelectedTemp({
          id: Number(this.form.model_id)
        });
        const agreement = this.agreementsTemp;
        if (agreement) {
          //this.agreement = agreement;
          if (agreement.services) {
            services = agreement.services;
          }
        }
      }

      if (this.form.model_type == "agreements_services" && this.form.model_id) {
        await this.ajaxAgreementServiceSetSelectedTemp({
          id: Number(this.form.model_id)
        });
        const agreement_service = this.agreements_servicesTemp;
        if (agreement_service && agreement_service.agreement) {
          services = agreement_service.services;
          //this.agreement = agreement_service.agreement;
        }
        if (agreement_service && agreement_service.service) {
          services = [agreement_service.service];
        }
      }

      this.name_services = "";
      if (services && services.length > 0) {
        for (let i = 0; i < services.length; i++) {
          this.name_services =
            this.name_services + `${services[i].machine_name}`;
          if (i !== services.length - 1) {
            this.name_services = this.name_services + ` - `;
          }
        }
      }
    },
    initForm() {
      if (this.mode == Enums.FORM_MODE.CREATE) {
        this.e6_step = 1;
        this.setForm();
      } else {
        this.setForm(this.$props.selectedItem);
      }
    },
    async initComp() {
      await this.ajaxUserFetchAll();
      await this.ajaxUserFilterAndOrder();
      //await this.ajaxCustomerFetchAll();
      //await this.ajaxAgreementFetchAll();
      if (this.mode != Enums.FORM_MODE.CREATE) {
        await this.getAgreementServices();
      }

      this.retrieveServicesString();
    },
    showInfo() {
      this.dialog = true;
    },
    handleTypeChange() {
      this.form.ticket_subtype = "";
      this.form.model_id = "";
      this.form.model_type = "";
      this.setEnabledSubtypes();
    },
    handleSubtypeChange() {
      if (
        this.form.ticket_subtype == "FLAT" ||
        this.form.ticket_subtype == "TIME_BUDGET"
      ) {
        this.agreementsEnabled = true;
        this.form.model_type = "agreements_services";

        this.getAgreementServices();
        this.form.model_id = "";
      } else {
        this.agreementsEnabled = false;
        this.form.model_type = null;
        this.form.model_id = "-1";
      }
    },
    handleCustomerChange() {
      this.form.ticket_type = "";
      this.form.ticket_subtype = "";
      this.form.model_id = "";
      this.form.model_type = "";
    },
    async getAgreementServices() {
      let payloadFAP = HelperService.buildFilterAndPagination({
        filter_criteria: {
          customer_id: this.form.customer.id,
          ticket_subtype: this.form.ticket_subtype
        }
      });
      await this.ajaxAgreementServiceFetchAll(payloadFAP);
    },
    async onSubmit() {
      const validate = await this.$validator.validateAll();
      if (validate) {
        this.submitToStore();
      } else {
        alert(this.$t("forms.common.errorValidateFields"));
      }
    },
    async onCancel() {
      this.$emit("formCancel");
    },
    async submitToStore() {
      const payload = _.clone(this.formGetter);
      await this.createOrUpdateHelper(
        this.mode,
        this.resourceType,
        this.ajaxTicketCreateOrUpdate,
        payload,
        payload => payload.v.code,
        payload => payload.p.code
      );
      this.$emit("formSucceed");
    },
    setForm(row = null) {
      if (row && !_.isEmpty(row)) {
        this.form.id = row.id;
        this.form.subject = row.subject;
        this.form.detail = row.detail;
        this.form.additional_costs = row.additional_costs;
        this.form.final_note = row.final_note;
        this.form.ticket_type = row.ticket_type;
        this.form.ticket_subtype = row.ticket_subtype;
        this.form.code = row.code;
        this.form.user = row.user;
        this.form.users = row.users;
        this.form.priority = row.priority || 'LOW';
        this.form.category = row.category;
        this.form.opened_by = row.opened_by;
        this.form.created_at = row.created_at;
        this.form.end_date = row.end_date;
        this.form.state = row.state;
        this.form.customer = row.customer;
        this.form.due_date = row.due_date;
        this.form.state = row.state;
        this.form.internal_state = row.internal_state;
        this.form.total_hours = row.total_hours;
        this.form.billed_total_hours = row.billed_total_hours;
        this.form.tenant = row.tenant;
        this.form.model_id = String(row.model_id);
        this.form.model_type = row.model_type;
        this.form.agreement = row.agreement;

        if (!this.form.ticket_type || this.form.ticket_type == "")
          this.form.ticket_type = "-1";
        if (!this.form.ticket_subtype || this.form.ticket_subtype == "")
          this.form.ticket_subtype = "-1";
        if (!this.form.model_id || this.form.model_id == "")
          this.form.model_id = "-1";
      } else if (row == null) {
        this.form.id = "";
        this.form.subject = "";
        this.form.detail = "";
        this.form.additional_costs = "";
        this.form.final_note = "";
        this.form.ticket_type = "";
        this.form.ticket_subtype = "";
        this.form.code = "";
        this.form.user = "";
        this.form.users = [];
        this.form.priority = "LOW";
        this.form.category = "";
        this.form.opened_by = "";
        this.form.created_at = "";
        this.form.end_date = "";
        this.form.state = "";
        this.form.customer = null;
        this.form.due_date = this.generateDate();
        this.form.state = "";
        this.form.internal_state = "";
        this.form.total_hours = "";
        this.form.billed_total_hours = "";
        this.form.tenant = "";
        this.form.model_id = "";
        this.form.model_type = "";
        this.form.agreement = "";
      }
    },
    generateDate() {
      let date = new Date();
      date.setDate(date.getDate() + 3);
      return moment.utc(date).format();
    },
    getTicketType() {
      if (this.form.ticket_type) {
        const filtered = this.ticket_types.filter(
          val => val.value == this.form.ticket_type
        );
        if (filtered.length > 0) {
          return filtered[0].text;
        }
      }
      return "";
    },
    getTicketSubtype() {
      if (this.form.ticket_subtype) {
        const filtered = this.ticket_subtypes.filter(
          val => val.value == this.form.ticket_subtype
        );
        if (filtered.length > 0) {
          return filtered[0].text;
        }
      }
      return "";
    },
    getBilledTotalHours() {
      return HelperService.convertHoursToTime(this.form.billed_total_hours)
    },
    async setEnabledSubtypes() {
      // check if FLAT agreement services exists
      let payloadFAP = HelperService.buildFilterAndPagination({
        filter_criteria: {
          customer_id: this.form.customer.id,
          ticket_subtype: "FLAT"
        }
      });
      await this.ajaxAgreementServiceFetchAll(payloadFAP);
      this.agreements_services_FLAT = this.agreements_services.length > 0;

      // check if TIME_BUDGET agreement services exists
      payloadFAP = HelperService.buildFilterAndPagination({
        filter_criteria: {
          customer_id: this.form.customer.id,
          ticket_subtype: "TIME_BUDGET"
        }
      });
      await this.ajaxAgreementServiceFetchAll(payloadFAP);
      this.agreements_services_TIME_BUDGET =
        this.agreements_services.length > 0;

      this.ticket_subtypes = this.ticket_subtypes.map(item => {
        if (item.value == "FLAT" || item.value == "TIME_BUDGET") {
          if (item.value == "FLAT" && !this.agreements_services_FLAT) {
            item.disabled = true;
          } else if (
            item.value == "TIME_BUDGET" &&
            !this.agreements_services_TIME_BUDGET
          ) {
            item.disabled = true;
          } else {
            item.disabled = false;
          }
        }
        return item;
      });
    },
    getPriority() {
      if (this.form.priority) {
        const filtered = this.priorities.filter(
          val => val.value == this.form.priority
        );
        if (filtered.length > 0) {
          return filtered[0].text;
        }
      }
      return "";
    },
    getCategory() {
      if (this.form.category) {
        const filtered = this.categories.filter(
          val => val.value == this.form.category
        );
        if (filtered.length > 0) {
          return filtered[0].text;
        }
      }
      return "";
    },
    creator() {
      if (this.form.user) {
        return (
          `${this.form.user.last_name || "-"} ${this.form.user.first_name ||
            "-"}` || "-"
        );
      }
    },
    ticketTiming() {
      let age = 0;
      const from = moment(this.form.created_at);
      let to = "";
      if (this.form.state === "OPENED") {
        to = moment();
      } else {
        to = moment(this.form.end_date);
      }
      age = to.diff(from, "days");
      return moment.duration(age, "days").humanize();
    },
    currentOperator() {
      let op = this.form.users
        ? this.form.users.map(item => `${item.last_name} ${item.first_name}`)
        : [];
      if (op && op.length > 0) {
        return op.join(" - ");
      } else return "-";
    },
    getOpenedBy() {
      if (this.form.opened_by) {
        const filtered = this.open_channel.filter(
          val => val.value == this.form.opened_by
        );
        if (filtered.length > 0) {
          return filtered[0].text;
        }
      }
      return "";
    },
    async quickStatusAction() {
      let nextState = this.getNextStatusAction();
      nextState =
        nextState === Enums.TICKET_STATUS_ACTION.CLOSE
          ? Enums.TICKET_STATUS.CLOSED
          : "";
      this.ticketTemp = {};
      this.ticketTemp.id = this.form.id;
      this.ticketTemp.state = nextState;
      this.ticketTemp.change_status = true;

      await this.createOrUpdateHelper(
        Enums.FORM_MODE.UPDATE,
        this.resourceType,
        this.ajaxTicketCreateOrUpdate,
        this.ticketTemp,
        () => "",
        () => ""
      );
      this.form.state = nextState;
      this.ticketTemp = {};
    },
    async quickInternalStatusAction(action) {
      let nextState = this.getNextInternalStatusByAction(action);
      this.ticketTemp = {};
      this.ticketTemp.id = this.form.id;
      this.ticketTemp.internal_state = nextState;
      this.ticketTemp.change_status = true;
      await this.createOrUpdateHelper(
        Enums.FORM_MODE.UPDATE,
        this.resourceType,
        this.ajaxTicketCreateOrUpdate,
        this.ticketTemp,
        () => "",
        () => ""
      );
      //await this.setTicket(Number(this.form.id));
      this.form.internal_state = nextState;
      this.ticketTemp = {};
    },
    getNextStatusAction() {
      if (this.form.state === Enums.TICKET_STATUS.OPENED) {
        return Enums.TICKET_STATUS_ACTION.CLOSE;
      } else {
        return "";
      }
    },
    getNextInternalStatusByAction(action) {
      let nextState = "";
      switch (action) {
        case Enums.TICKET_INTERNAL_STATUS_ACTION.START:
          nextState = Enums.TICKET_INTERNAL_STATUS.STARTED;
          break;
        case Enums.TICKET_INTERNAL_STATUS_ACTION.HOLD:
          nextState = Enums.TICKET_INTERNAL_STATUS.ONHOLD;
          break;
        case Enums.TICKET_INTERNAL_STATUS_ACTION.FINISH:
          nextState = Enums.TICKET_INTERNAL_STATUS.FINISHED;
          break;
        default:
          nextState = Enums.TICKET_INTERNAL_STATUS.STARTED;
          break;
      }
      return nextState;
    }
  },
  computed: {
    ...mapGetters("user", ["users"]),
    ...mapGetters("customer", ["customers"]),
    ...mapGetters("agreementService", [
      "agreements_services",
      "agreements_servicesTemp"
    ]),
    ...mapGetters("agreement", ["agreements", "agreementsTemp"]),
    formGetter: state => {
      return JSON.parse(JSON.stringify(state.form));
    },
    formTitle() {
      var title = "";
      switch (this.mode) {
        case Enums.FORM_MODE.CREATE:
          title = this.$t("labels.createResourceType", {
            resourceType: this.resourceType || "-"
          });
          break;
        case Enums.FORM_MODE.UPDATE:
          title = this.$t("labels.updateResourceTypeName", {
            resourceType: this.resourceType || "-",
            resourceName: this.form.code ? this.form.code : "-"
          });
          break;

        default:
          break;
      }
      return title;
    },
    internalStatusNextAction() {
      const currentInternalStatus = this.form.internal_state;
      let nextAction = [];
      switch (currentInternalStatus) {
        case Enums.TICKET_INTERNAL_STATUS.PENDING:
          // nextAction = [Enums.TICKET_INTERNAL_STATUS_ACTION.START];
          break;
        case Enums.TICKET_INTERNAL_STATUS.STARTED:
          nextAction = [
            Enums.TICKET_INTERNAL_STATUS_ACTION.HOLD,
            Enums.TICKET_INTERNAL_STATUS_ACTION.FINISH
          ];
          break;
        case Enums.TICKET_INTERNAL_STATUS.ONHOLD:
          nextAction = [
            Enums.TICKET_INTERNAL_STATUS_ACTION.START
            // Enums.TICKET_INTERNAL_STATUS_ACTION.FINISH,
          ];
          break;
        case Enums.TICKET_INTERNAL_STATUS.FINISHED:
          nextAction = [Enums.TICKET_INTERNAL_STATUS_ACTION.START];
          break;
      }
      return nextAction;
    },
    Enums() {
      return Enums;
    }
  },
  created() {
    this.arrayTranslation("tickets", this.ticket_types);
    this.arrayTranslation("tickets", this.ticket_types_create);
    this.arrayTranslation("tickets", this.ticket_subtypes);
    this.arrayTranslation("tickets", this.priorities);
    this.arrayTranslation("tickets", this.categories);
    this.arrayTranslation("tickets", this.open_channel);

    this.initForm();
    this.initComp();
  }
};
</script>

<style scoped>
.has-error {
  color: red;
}
.required label:before {
  color: #e32;
  content: "* ";
  display: inline;
}
.summary-font {
  font-size: small;
  line-height: 24px;
}
.summary-font-bold {
  font-size: small;
  font-weight: bold;
  line-height: 24px;
}
.v-card {
  margin-bottom: 8px;
}
.ticket-info {
  align-items: center;
  margin: 5px;
  padding: 3px;
}
.ticket-details-label {
  margin-bottom: 0px;
}
.ticket-details {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  font-family: monospace;
}
</style>
