var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "grey lighten-4 title", attrs: { fluid: "" } },
    [
      _vm.mode == _vm.Enums.FORM_MODE.CREATE ||
      _vm.mode == _vm.Enums.FORM_MODE.UPDATE
        ? _c(
            "div",
            [
              _c("v-card-title", { staticClass: "form-title title" }, [
                _vm._v(_vm._s(_vm._f("capitalize")(this.formTitle)))
              ]),
              _c(
                "v-form",
                {
                  staticClass: "form-body",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.onSubmit($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _c("DatePicker", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.immediate",
                            value: "required",
                            expression: "'required'",
                            modifiers: { immediate: true }
                          }
                        ],
                        attrs: {
                          label: _vm.$t("forms.attachments.protocol_date"),
                          name: "protocol_date"
                        },
                        model: {
                          value: _vm.form.protocol_date,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "protocol_date", $$v)
                          },
                          expression: "form.protocol_date"
                        }
                      }),
                      _vm.veeErrors.has("protocol_date")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.veeErrors.first("protocol_date"))
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.immediate",
                            value: "required",
                            expression: "'required'",
                            modifiers: { immediate: true }
                          }
                        ],
                        attrs: {
                          label: _vm.$t("forms.attachments.protocol_number"),
                          name: "protocol_number"
                        },
                        model: {
                          value: _vm.form.protocol_number,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "protocol_number", $$v)
                          },
                          expression: "form.protocol_number"
                        }
                      }),
                      _vm.veeErrors.has("protocol_number")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.veeErrors.first("protocol_number"))
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.immediate",
                            value: "required",
                            expression: "'required'",
                            modifiers: { immediate: true }
                          }
                        ],
                        attrs: {
                          label: _vm.$t("forms.attachments.detail"),
                          name: "detail"
                        },
                        model: {
                          value: _vm.form.detail,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "detail", $$v)
                          },
                          expression: "form.detail"
                        }
                      }),
                      _vm.veeErrors.has("detail")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [_vm._v(_vm._s(_vm.veeErrors.first("detail")))]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.mode == _vm.Enums.FORM_MODE.CREATE
                    ? _c(
                        "div",
                        { staticClass: "form-group required" },
                        [
                          _c("v-file-input", {
                            ref: "fileupload",
                            attrs: {
                              accept: _vm.acceptedFormats,
                              clearable: "",
                              label: _vm.$t("misc.file"),
                              name: "file"
                            },
                            on: { change: _vm.handleFileUpload },
                            model: {
                              value: _vm.files,
                              callback: function($$v) {
                                _vm.files = $$v
                              },
                              expression: "files"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("FormButtons", {
                    attrs: { disabled: _vm.veeErrors.any() },
                    on: { onSave: _vm.onSubmit, onCancel: _vm.onCancel }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.mode === _vm.Enums.FORM_MODE.SHOW,
            expression: "mode === Enums.FORM_MODE.SHOW"
          }
        ]
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }