var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-align-center" },
    [
      _c("v-row", { staticClass: "filterTitle" }, [
        _c("h3", [_vm._v("Filtri")])
      ]),
      _vm.reportType === "questionario_gdpr"
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      dense: "",
                      label: "Questionatio caricato",
                      items: _vm.uploadFileOptions,
                      "item-text": "name",
                      clearable: ""
                    },
                    on: { change: _vm.getAllFiltersSelected },
                    model: {
                      value: _vm.selected,
                      callback: function($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.reportType === "expiring_agreements"
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      dense: "",
                      label: "Tipologia cliente",
                      items: _vm.customerTypesAll,
                      "item-text": "name",
                      clearable: ""
                    },
                    on: { change: _vm.getAllFiltersSelected },
                    model: {
                      value: _vm.customerTypeSelected,
                      callback: function($$v) {
                        _vm.customerTypeSelected = $$v
                      },
                      expression: "customerTypeSelected"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      dense: "",
                      label: "Azienda",
                      items: _vm.tenantAll,
                      "item-text": "name",
                      "item-value": "id",
                      clearable: ""
                    },
                    on: { change: _vm.getAllFiltersSelected },
                    model: {
                      value: _vm.tenantSelected,
                      callback: function($$v) {
                        _vm.tenantSelected = $$v
                      },
                      expression: "tenantSelected"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      dense: "",
                      label: "Servizi",
                      items: _vm.servicesAll,
                      "item-text": "name",
                      clearable: ""
                    },
                    on: { change: _vm.getAllFiltersSelected },
                    model: {
                      value: _vm.serviceSelected,
                      callback: function($$v) {
                        _vm.serviceSelected = $$v
                      },
                      expression: "serviceSelected"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      dense: "",
                      label: "Scadenza",
                      items: _vm.expirationsAll,
                      "item-text": "name",
                      "item-value": "value",
                      clearable: ""
                    },
                    on: { change: _vm.getAllFiltersSelected },
                    model: {
                      value: _vm.expirationSelected,
                      callback: function($$v) {
                        _vm.expirationSelected = $$v
                      },
                      expression: "expirationSelected"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }