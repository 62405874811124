import { TokenService } from "./token.service";
import ApiService from "./api.service";

async function login(username, password) {
  const requestData = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
    data: {
      username: username,
      password: password
    }
  };
  try {
    const response = await ApiService.customRequest(requestData);
    TokenService.saveToken(response.data);
    ApiService.setHeader();
    return response;
  } catch (error) {
    return error;
  }
}

function logout() {
  TokenService.removeToken();
  ApiService.removeHeader();
}

async function forgot_password(username){
  const requestData = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    url: `${process.env.VUE_APP_ENDPOINT_API_URI}/public/users/forgot_password`,
    data: {
      username
    }
  };
  try {
    const response = await ApiService.customRequest(requestData);
    return response;
  } catch (error) {
    return error;
  }
}

async function resetPassword(username, password, confirm_password, password_reset_token) {
  const requestData = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    url: `${process.env.VUE_APP_ENDPOINT_API_URI}/public/users/reset_password`,
    data: {
      username: username,
      password: password,
      confirm_password: confirm_password,
      password_reset_token: password_reset_token
    }
  };
  try {
    const response = await ApiService.customRequest(requestData);
    return response;
  } catch (error) {
    return {error};
  }
}

async function AuthRegistroAccessi(code, pin) {
  const requestData = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    url: `${process.env.VUE_APP_ENDPOINT_API_URI}/public/users/auth_registro_accessi`,
    data: {
      code: code,
      pin: pin
    }
  };
  try {
    const response = await ApiService.customRequest(requestData);
    return response;
  } catch (error) {
    return {error};
  }
}

export const AuthService = {
  login,
  logout,
  forgot_password,
  resetPassword,
  AuthRegistroAccessi
};
