import Vue from "vue";
import Vuex from "vuex";
import { TaggingService } from "@/services/tagging.service";
import { HelperService } from "@/services/helper.service";
import _ from "lodash";

Vue.use(Vuex);

const state = {
  taggings: [],
  selected: {},
  loading: false,
  totalCount: 0
};

const getters = {
    taggings: state => state.taggings,
    onLoading: state => state.loading,
    selectedTag: state => state.selected,
    total: state => state.totalCount
};

const mutations = {
  setSelected(state, sel) {
    state.selected = sel;
  },
  setList(state, sel) {
    state.taggings = sel;
  },
  setTotal(state, sel) {
    state.totalCount = Number(sel);
  },
  setLoading(state, sel) {
    state.loading = sel;
  }
};

const actions = {
  async ajaxTaggingSetSelected({ commit }, payload) {
    await HelperService.genericSetSelected(
      commit,
      TaggingService.fetchOne,
      payload.id
    );
  },

  async ajaxTaggingFetchAll({ commit }, payloadFAP) {

    const searchFilterPagination = HelperService.localToJSONAPIPagination(
      payloadFAP
    );
    searchFilterPagination.include = "tag";

    await HelperService.genericFetchAll(
      commit,
      TaggingService.fetchAll,
      searchFilterPagination,
      null,
      null
    );
  },

  /*async ajaxTagCreateOrUpdate({ commit }, payload) {
    HelperService.unformatAgreement(payload);
    return HelperService.genericCreateOrUpdate(
      commit,
      AgreementService.createOrUpdate,
      payload
    );
  },*/

  /*async ajaxAgreementDelete({ commit }, payload) {
    return HelperService.genericDelete(
      commit,
      AgreementService.delete,
      payload.id
    );
  },*/

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
