<template>
  <v-container>
    <ToolbarDetail
      :title="detailTitle | capitalize"
      :withEdit="false"
    ></ToolbarDetail>
    <br />
    <v-card>
      <ToolbarList
        :title="$t('labels.generationSessionItems')"
        :subtitle="$tc('labels.found_elements', this.total)"
        :withAdd="false"
      ></ToolbarList>
      <BaseGrid
        tableName="session-items"
        :headers="this.tableData.headers"
        :items="this.tableData.items"
        :totalLength="this.total"
        :pagination="this.tableOptions"
        :loading="this.onLoading"
        @onPaginationChanged="handlePaginationChanged"
        :hideActions="true"
      ></BaseGrid>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import ToolbarList from "@/components/Shared/ToolbarList";
import BaseGrid from "@/components/Shared/BaseGrid";
import FormsHelper from "@/mixins/formsHelper";
import { HelperService } from "@/services/helper.service";
import Enums from "@/mixins/enums";
import ToolbarDetail from "@/components/Shared/ToolbarDetail";
// import { Ability } from "@casl/ability";
// import {} from "@/abilities";

const computed = {
  ...mapGetters("generationSessionItem", [
    "generationSessionItems",
    "total",
    "onLoading"
  ]),
  _() {
    return _;
  },
  Enums() {
    return Enums;
  },
  generationSessionItemsList() {
    return this.generationSessionItems;
  },
  detailTitle() {
    return this.$t("labels.detailResourceTypeName", {
      resourceType: this.resourceType,
      resourceName: this.$props.id ? this.$props.id : "-"
    });
  }
};

export default {
  name: "generationSessionItemsList",
  components: {
    ToolbarList,
    BaseGrid,
    ToolbarDetail
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      resourceType: this.$t("resource_types.session"),
      progress: false,
      formDialog: false,
      formMode: "",
      tableData: { headers: [], items: [] },
      tableOptions: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["created_at"],
        sortDesc: [false]
      }
    };
  },
  methods: {
    ...mapActions("generationSessionItem", [
      "ajaxGenerationSessionItemFetchAll",
      "ajaxGenerationSessionItemCreateOrUpdate"
    ]),
    allowStart(state) {
      if (state === "INITIALIZED" || state === "STOPPED") {
        return true;
      } else {
        return false;
      }
    },
    allowStop(state) {
      if (state === "PROCESSING") {
        return true;
      } else {
        return false;
      }
    },
    allowUpdate(state) {
      if (state === "INITIALIZED" || state === "STOPPED") {
        return true;
      } else {
        return false;
      }
    },
    async fetchData() {
      let payloadFAP = HelperService.buildFilterAndPagination({
        pagination: this.tableOptions,
        filter: { generation_session_id: this.$props.id }
      });
      await this.ajaxGenerationSessionItemFetchAll(payloadFAP);
      this.initTable();
    },
    initTable() {
      this.tableData.headers = this.mapHeaders();
      this.tableData.items = this.mapItems();
    },
    mapHeaders() {
      const tableHeaders = [];
      tableHeaders.push({
        text: "Customer",
        value: "customer_mechanographyc_code",
        align: "start",
        sortable: false
      });
      tableHeaders.push({
        text: "Agreement",
        value: "agreement_code",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Template Type",
        value: "file_type_machine_name",
        sortable: true,
        align: "start"
      });
      tableHeaders.push({
        text: "Attachment",
        value: "allegato",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "State",
        value: "state",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Error",
        value: "error",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Creation Date",
        value: "created_at",
        align: "start",
        sortable: true
      });
      return tableHeaders;
    },
    mapItems() {
      const map = _.map(this.generationSessionItemsList, item => {
        item.fields = {
          customer_mechanographyc_code: {
            data: item.customer ? item.customer.mechanographyc_code : "",
            dataType: "text",
            css: "text-xs-left"
          },
          agreement_code: {
            data: item.agreement ? item.agreement.code : "",
            dataType: "text",
            css: "text-xs-left"
          },
          file_type_machine_name: {
            data: item.file_type ? item.file_type.machine_name : "",
            dataType: "text",
            css: "text-xs-left"
          },
          allegato: {
            data: item.allegato,
            dataType: "text",
            css: "text-xs-left"
          },
          state: { data: item.state, dataType: "text", css: "text-xs-left" },
          error: { data: item.error, dataType: "text", css: "text-xs-left" },
          created_at: {
            data: item.created_at,
            dataType: "date",
            css: "text-xs-left"
          }
        };
        item.actions = {};
        return item;
      });
      return map;
    },
    handleFormSucceed() {
      this.closeFormDialog();
      this.fetchData();
    },
    handleFormCancel() {
      this.closeFormDialog();
    },
    handlePaginationChanged(paginationOpts) {
      this.tableOptions = paginationOpts;
      this.fetchData();
    }
  },
  mixins: [FormsHelper, Enums],
  async created() {
    await this.fetchData();
  },
  computed
};
</script>
