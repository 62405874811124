<template>
  <div>
    <v-container style="margin: 30px 0;">
      <v-card>
        <v-toolbar flat class="toolbar-94" elevation="1" style="background-color: #7389ae24;">
          <v-toolbar-title class="mx-7" style="font-weight: bold;" >{{ $t('labels.atti_procedura.title.step1') }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-layout align-center justify-space-between fill-heigh>
              <v-flex md2 class="align-left">
                <v-btn color="accent" normal class="mx-4" @click.stop="openFormDialog(Enums.FORM_MODE.CREATE)">
                  <v-icon>{{ $t("icons.add") }}</v-icon>
                  &nbsp;
                  <span>{{ $t("buttons.create-permalink") }}</span>
                  
                </v-btn>
              </v-flex>
            </v-layout>
        </v-card-text>
      </v-card>
    </v-container>
    <v-container style="margin: 30px 0;">
      <v-card>
        <v-toolbar flat class="toolbar-94" elevation="1" style="background-color: #7389ae24;">
          <v-toolbar-title class="mx-7" style="font-weight: bold;" >{{ $t('labels.atti_procedura.title.step2') }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

      <BaseGrid
        tableName="permalinks"
        :headers="this.tableData.headers"
        :items="this.tableData.items"
        :totalLength="this.totalCount"
        :loading="this.onLoading"
        :pagination="this.tableOptions"
        @onPaginationChanged="handlePaginationChanged"
      ></BaseGrid>
    </v-card>
    </v-container>
    
    <v-dialog v-model="formDialog" persistent content-class="edit-form-dialog">
      <PermaLinkForm
        v-if="formDialog"
        :mode="Enums.FORM_MODE.CREATE"
        :agreementId="this.withAgreementId"
        :folderId="this.folderId"
        @formSucceed="handleFormSucceed"
        @formCancel="handleFormCancel"
      ></PermaLinkForm>
    </v-dialog>


    <v-dialog v-model="secondFormDialog" persistent content-class="edit-form-dialog">
      <ProceedingForm
        v-if="secondFormDialog"
        :mode="secondFormMode"
        :selectedItem="secodFormItem"
        :withModelType="Enums.MODEL_NAME.AGREEMENTS"
        :withModelId="this.withAgreementId"
        :withPermalink="this.permalink"
        :tagName="Enums.TAG_NAME.DETERMINE_DECISIONI_CONTRARRE"
        :proceedingType="Enums.PROCEEDING_TYPE.DETERMINA"
        @formSucceed="handleSecondFormSucceed"
        @formCancel="handleSecondFormCancel"
      ></ProceedingForm>
    </v-dialog>

  </div>
</template>

<script>
import _ from "lodash";
import Enums from "@/mixins/enums";
import FormsHelper from "@/mixins/formsHelper";
import DatatablesHelper from "@/mixins/datatablesHelper";

import BaseGrid from "@/components/Shared/BaseGrid";
import PermaLinkForm from "@/components/PermaLink/PermaLinkForm";
import ProceedingForm from "@/components/Proceeding/ProceedingForm";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BaseGrid,
    PermaLinkForm,
    ProceedingForm
  },

  props: {
    mode: {
      default: Enums.FORM_MODE.CREATE,
      type: String,
    },
    withAgreementId: {
      type: String,
      required: true,
    },
    folderId: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      permalink: null,
      tableOptions: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["permalink"],
      },
      tableData: { headers: [], items: [] },
    };
  },
  mixins: [FormsHelper, Enums, DatatablesHelper],
  methods: {
    ...mapActions("permalink", ["ajaxPermaLinkFetchAll", "ajaxPermaLinkDelete"]),

    handleFormSucceed() {
      this.closeFormDialog();
      this.init();
    },
    handleFormCancel() {
      this.closeFormDialog();
    },
    async handleSecondFormSucceed() {
      this.closeSecondFormDialog();
      await this.init();
      this.$emit("formSucceed");
    },
    handleSecondFormCancel() {
      this.closeSecondFormDialog();
    },
    async init() {
      const needle = null;
      const _search = needle ? needle : this.search || "";
      //let _filter = { model_id: 'null', cig: 'null', url: 'null' };
      let _filter = { };
      if (this.$props.withAgreementId) {
        _filter = { ..._filter, ...{ agreement_id: this.$props.withAgreementId } }
      }
      if (this.$props.folderId) {
        _filter = { ..._filter, ...{ folder_id: this.$props.folderId } }
      }

      const filterAndPagination = {
        search: _search,
        filter: _filter,
        pagination: this.tableOptions,
      };

      await this.ajaxPermaLinkFetchAll(filterAndPagination);
      this.initTable();
    },

    initTable() {
      this.tableData.headers = this.mapHeaders();
      this.tableData.items = this.mapItems();
    },
    toast(context, mode, text) {
      const messageObj = {
        context: context,
        mode: mode,
        text: text,
        snackbar: true
      };
      this.$store.dispatch("snackbar/showMessage", messageObj);
    },
    mapHeaders() {
      const tableHeader = [];
      tableHeader.push({
        title: "Subject",
        value: "subject",
        align: "start",
        sortable: true,
      });

      tableHeader.push({
        title: "Permalink",
        value: "permalink",
        align: "start",
        sortable: false,
      });
      /*tableHeader.push({
        title: "Copy",
        value: "copy",
        align: "center",
        sortable: false,
      });*/
      tableHeader.push({
        title: "Actions",
        value: "actions",
        align: "center",
        sortable: false,
      });

      return tableHeader;
    },
    mapItems() {
      const map = _.map(this.permalinks, (item) => {
        item.fields = {
          procedure_object: {
            data: item.subject,
            dataType: "text",
            css: "text-xs-left",
          },
          permalink: {
            data: item.permalink,
            dataType: "text",
            css: "text-xs-left",
          },
        };
        item.actions = {
          copy: {
            tooltip: this.$t("buttons.copy-permalink"),
            show: true,
            actionType: "custom",
            icon: this.$t("icons.clone"),
            callback: (item) => {
              navigator.clipboard
                .writeText(item.permalink)
                .then(() => {
                  this.toast(Enums.TOAST_TYPE.SUCCESS, "", this.$t("labels.copied-permalink"));
                })
                .catch(function(error) {
                  this.$toast(Enums.TOAST_TYPE.ERROR, "", "Errore nel copiare");
                });
            },
          },
          upload: {
            tooltip: this.$t("forms.permalinks.file-add"),
            show: this.$can("uploadButton", "permalinks"),
            actionType: "custom",
            callback: async item => {
              this.permalink = item;
              this.openSecondFormDialog(Enums.FORM_MODE.CREATE);
            },
            icon: this.$t("icons.file-add"),
            css: "text-xs-left",
            disabled: false
          },
          delete: {
            tooltip: this.$t("buttons.delete-permalink"),
            show: this.$can("deleteButton", "permalinks"),
            actionType: "custom",
            callback: async item => {
              this.handleDelete(item);
            },
            icon: this.$t("icons.delete"),
            css: "text-xs-left",
            disabled: false
          },
        };
        return item;
      });
      return map;
    },
    async handleDelete(item) {
      this.$confirm({
        message:
          this.$t("dialogs.deleteConfirmMessage") + "\n\Permalink | " + item.subject + "\n\n",
        button: {
          no: this.$t("dialogs.no"),
          yes: this.$t("dialogs.yes")
        },
        callback: async confirm => {
          if (confirm) {
            await this.ajaxPermaLinkDelete(item);
            this.init();
          }
        }
      });
    },
    handlePaginationChanged(paginationOpts) {
      this.tableOptions = paginationOpts;
      this.init();
    },
  },

  async created() {
    this.formDialog = false;
    this.secondFormDialog = false;
    await this.init();
  },
  computed: {
    ...mapGetters("permalink", [
      "permalinks",
      "totalCount",
      "onLoading",
      "selectedPermaLink",
    ]),

    Enums() {
      return Enums;
    }
  },
};
</script>

<style scoped></style>
