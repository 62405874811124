var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-autocomplete", {
    attrs: {
      autocomplete: "off",
      value: _vm.value,
      items: _vm.getter,
      "item-text": _vm.itemText,
      "item-value": _vm.itemValue,
      "return-object": _vm.returnObject,
      label: _vm.label,
      "search-input": _vm.search,
      disabled: _vm.disabled,
      "no-filter": "",
      clearable: ""
    },
    on: {
      input: function($event) {
        return _vm.onInput($event)
      },
      "update:searchInput": function($event) {
        _vm.search = $event
      },
      "update:search-input": function($event) {
        _vm.search = $event
      }
    },
    scopedSlots: _vm._u(
      [
        {
          key: "selection",
          fn: function(ref) {
            var item = ref.item
            return [_vm._t("selection", null, { item: item })]
          }
        },
        {
          key: "item",
          fn: function(ref) {
            var item = ref.item
            return [_vm._t("item", null, { item: item })]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }