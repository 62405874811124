<template>
  <v-container fluid class="grey lighten-4 title">
    <div v-if="mode == Enums.FORM_MODE.CREATE">
      <v-card-title class="form-title title">
        {{ formTitle | capitalize }}
      </v-card-title>
      <v-form class="form-body" @submit.prevent="onSubmit">
        <!-- SUBJECT -->
        <div class="form-group required">
          <v-textarea
            :label="$t('forms.permalinks.subject')"
            :hint="$t('forms.permalinks.subject-hint')"
            :placeholder="$t('forms.permalinks.subject-placeholder')"
            v-model="form.subject"
            name="subject"
            rows="2"
            no-resize
            v-validate.immediate="'required'"
            counter="200"
            :maxlength="200"
          ></v-textarea>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('subject')"
            >{{ veeErrors.first("subject") }}</span
          >
        </div>

        <!-- BUTTONS -->
        <FormButtons
          @onSave="onSubmit"
          @onCancel="onCancel"
          :createLoading="createLoading"
          :disabled="veeErrors.any()"
        />
      </v-form>
    </div>

    <v-dialog
      v-model="formDialog"
      persistent
      class="custom-dialog"
      max-width="500"
      >
      <v-container fluid class="grey lighten-4 title">
        <v-card-title class="headline">{{
          $t("forms.permalinks.form.attenzione")
        }}</v-card-title>
        <v-card-text>
          <p style="font-size: 15px;">
            {{ $t("forms.permalinks.dialog-info.msg") }}
          </p>
          <!-- v-checkbox
            v-model="confirmedCheck"
            :label="$t('forms.permalinks.form.confirm')"
          ></v-checkbox -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!confirmedCheck"
            color="primary"
            @click="handleConfirm"
          >
            {{$t("dialogs.confirm")}}
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "lodash";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { mapActions } from "vuex";
import FormButtons from "@/components/Shared/FormButtons";

export default {
  props: {
    mode: {
      default: Enums.FORM_MODE.CREATE,
      type: String,
    },
    agreementId: {
      type: String,
      required: true,
    },
    folderId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      resourceType: this.$t("resource_types.permalinks"),
      createLoading: false,
      confirmedCheck: true,
      formDialog: false,
      form: {
        subject: "",
        agreement_id: this.$props.agreementId,
        folder_id: this.$props.folderId,
      },
    };
  },
  components: { FormButtons },
  mixins: [SharedMixin, Enums],

  methods: {
    ...mapActions("permalink", [
      "ajaxPermaLinkCreateOrUpdate",
      "ajaxPermaLinkDelete",
    ]),

    async onSubmit() {
      await this.submitToStore();
    },

    handleConfirm() {
      this.formDialog = false;
    },

    async onCancel() {
      this.$emit("formCancel");
    },
    async submitToStore() {
      const payload = _.clone(this.form);
      this.createLoading = true;
      await this.createOrUpdateHelper(
        this.mode,
        this.resourceType,
        this.ajaxPermaLinkCreateOrUpdate,
        payload,
        (payload) => payload.p.subject,
        (payload) => payload.p.subject
      );
      this.createLoading = false;
      this.$emit("formSucceed");
    },
  },
  computed: {
    Enums() {
      return Enums;
    },
    formTitle() {
      var title = "";
      switch (this.mode) {
        case Enums.FORM_MODE.CREATE:
          title = this.$t("labels.createResourceType", {
            resourceType: this.resourceType || "-",
          });
          break;

        default:
          break;
      }
      return title;
    },
  },
  created() {
    setTimeout(() => {
      this.formDialog = true;
    }, 300);
  },
};
</script>

<style scoped>
.has-error {
  color: red;
}
.required label:before {
  color: #e32;
  content: "* ";
  display: inline;
}
.summary-font {
  font-size: small;
  line-height: 24px;
}
.summary-font-bold {
  font-size: small;
  font-weight: bold;
  line-height: 24px;
}
</style>
