var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            { staticClass: "text-left" },
            [
              _c(
                "v-col",
                { attrs: { cols: "10" } },
                [
                  _vm.tagName == _vm.Enums.TAG_NAME.RELAZIONI_PA_CC ||
                  _vm.tagName == _vm.Enums.TAG_NAME.VERBALI_REVISORI_CONTI
                    ? _c("div", { staticClass: "text-h5 font-weight-bold" }, [
                        _vm._v("Programma annuale")
                      ])
                    : _vm._e(),
                  _vm._l(_vm.sectionsOption, function(section, j) {
                    return _c(
                      "v-row",
                      { key: section.value },
                      [
                        !section.fileRef
                          ? _c(
                              "v-col",
                              { attrs: { cols: "6" } },
                              [
                                _c("v-file-input", {
                                  ref: "fileupload",
                                  refInFor: true,
                                  attrs: {
                                    accept: ".pdf,.zip,.rar,.7zip",
                                    clearable: "",
                                    label: section.title,
                                    name: section.title
                                  },
                                  on: { change: _vm.handleFileUpload },
                                  model: {
                                    value: _vm.allFiles[section.value],
                                    callback: function($$v) {
                                      _vm.$set(_vm.allFiles, section.value, $$v)
                                    },
                                    expression: "allFiles[section.value]"
                                  }
                                })
                              ],
                              1
                            )
                          : _c(
                              "v-col",
                              {
                                staticClass: "body-1 text-left",
                                staticStyle: {
                                  color: "blue",
                                  "margin-left": "33px"
                                },
                                attrs: { cols: "6" }
                              },
                              [_vm._v(_vm._s(section.fileName))]
                            ),
                        _c(
                          "v-col",
                          { attrs: { cols: "2" } },
                          [
                            _c("FileDownloader", {
                              attrs: {
                                fileRef: section.fileRef,
                                fileName: section.fileName
                              }
                            }),
                            section.fileRef
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "", color: "primary" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.onDelete(section.attachment)
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", { attrs: { dark: "" } }, [
                                      _vm._v(_vm._s(_vm.$t("icons.delete")))
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  _vm.tagName == _vm.Enums.TAG_NAME.RELAZIONI_PA_CC ||
                  _vm.tagName == _vm.Enums.TAG_NAME.VERBALI_REVISORI_CONTI
                    ? _c("div", { staticClass: "text-h5 font-weight-bold" }, [
                        _vm._v("Conto consuntivo")
                      ])
                    : _vm._e(),
                  _vm._l(_vm.sectionsOption2, function(section, k) {
                    return _c(
                      "v-row",
                      { key: section.value },
                      [
                        !section.fileRef
                          ? _c(
                              "v-col",
                              { attrs: { cols: "6" } },
                              [
                                _c("v-file-input", {
                                  ref: "fileupload",
                                  refInFor: true,
                                  attrs: {
                                    accept: ".pdf,.zip,.rar,.7zip",
                                    clearable: "",
                                    label: section.title,
                                    name: section.title
                                  },
                                  on: { change: _vm.handleFileUpload },
                                  model: {
                                    value: _vm.allFiles[section.value],
                                    callback: function($$v) {
                                      _vm.$set(_vm.allFiles, section.value, $$v)
                                    },
                                    expression: "allFiles[section.value]"
                                  }
                                })
                              ],
                              1
                            )
                          : _c(
                              "v-col",
                              {
                                staticClass: "body-1 text-left",
                                staticStyle: {
                                  color: "blue",
                                  "margin-left": "33px"
                                },
                                attrs: { cols: "6" }
                              },
                              [_vm._v(_vm._s(section.fileName))]
                            ),
                        _c(
                          "v-col",
                          { attrs: { cols: "2" } },
                          [
                            _c("FileDownloader", {
                              attrs: {
                                fileRef: section.fileRef,
                                fileName: section.fileName
                              }
                            }),
                            section.fileRef
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "", color: "primary" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.onDelete(section.attachment)
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", { attrs: { dark: "" } }, [
                                      _vm._v(_vm._s(_vm.$t("icons.delete")))
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }