var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      attrs: {
        color: _vm.context,
        timeout: _vm.timeout,
        top: true,
        right: _vm.x === "right",
        left: _vm.x === "left",
        "multi-line": _vm.mode === "multi-line",
        vertical: _vm.mode === "vertical",
        value: _vm.snackbar
      }
    },
    [
      _c("div", { staticClass: "snack-text" }, [_vm._v(_vm._s(_vm.text))]),
      _c(
        "v-btn",
        {
          staticClass: "close-button",
          attrs: { dark: "" },
          nativeOn: {
            click: function($event) {
              return _vm.closeMessage()
            }
          }
        },
        [_vm._v("Close")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }