/* ============
 * Snackbar Module
 * ============
 */
/*  eslint no-shadow: ["error", { "allow": ["state"] }] */
/*  eslint no-param-reassign: ["error", { "props": false }] */
import Vue from "vue";

const state = {
  snackbar: false,
  context: "",
  mode: "",
  text: "Hello, I'm a snackbar",
  x: "",
  y: "bottom",
  timeout: 5000
};

const getters = {
  snackbar: state => state.snackbar,
  context: state => state.context,
  mode: state => state.mode,
  text: state => state.text,
  x: state => state.x,
  y: state => state.y,
  timeout: state => state.timeout
};

const mutations = {
  showMessage(state, payload) {
    state.snackbar = true;
    state.context = payload.context;
    state.mode = payload.mode;
    state.text = payload.text;
  },
  closeMessage(state) {
    state.snackbar = false;
    state.text = "";
  },
  setSnackbarStatus(state, status) {
    state.snackbar = status;
  }
};

const actions = {
  showMessage({ commit }, messageObj) {
    commit("setSnackbarStatus", false);
    Vue.nextTick(() => {
      commit("showMessage", messageObj);
    });
  },

  closeMessage({ commit }, payload) {
    commit("closeMessage", payload);
  }
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
};
