<template>
  <!-- div -->
  <v-toolbar flat class="toolbar-94">
    <v-row align="center" class="toolbar-list">
      <v-col :cols="withSearch && withExportPdf ? 3 : withSearch || withExportPdf ? 4 : 8" class="toolbar-list-info">
        <v-toolbar-title>
          <div>
            <p v-if="title" class="text-h4 text-sm-left my-1">{{ title }}</p>
            <p v-if="subtitle" class="caption text-sm-left">{{ subtitle }}</p>
          </div>
        </v-toolbar-title>
      </v-col>
      <v-col cols="4" v-if="withSearch">
        <v-text-field
          :label="$t('misc.search')"
          color="warning"
          v-model="searchQuery"
          @input="isTyping = true"
          clearable
          single-line
        ></v-text-field>
      </v-col> 
      <v-col cols="6" v-if="withExportPdf">
        <ReportFilter
          :reportType="reportType"
          @filtersSelected="onReportFilter"
        ></ReportFilter>
      </v-col>      
      <v-spacer></v-spacer>
      <v-col cols="4" class="align-right toolbar-list-actions">
        <v-btn
          v-if="withFilter"
          @click.stop="onFilter"
          small
          outlined
          color="secondary"
          :class="{ 'colored-button': !allClear, 'mr-2': true }"
        >
          {{ $t("buttons.filter") }}
        </v-btn>
        <v-btn
          v-if="withInvoicing"
          @click.stop="onInvoicing"
          small
          outlined
          color="secondary"
        >
          {{ $t("buttons.invoicing") }}
        </v-btn>
        <v-btn
          v-if="withAdd"
          color="accent"
          small
          class="mx-4"
          @click.stop="onAdd"
        >
          <v-icon>{{ $t("icons.add") }}</v-icon>
          &nbsp;
          <div v-if="tagName!=Enums.TAG_NAME.ATTI_AMMINISTRAZIONI_ENTI">
            {{ $t("buttons.create") }}
          </div>
          <div v-else>
            {{ $t("buttons.create_act") }}
          </div>
        </v-btn>
        <v-btn
          v-if="withAddDetermina"
          color="accent"
          small
          class="mx-4"
          @click.stop="onAddProceeding(Enums.TAG_NAME.DETERMINE_DECISIONI_CONTRARRE)"
        >
          <v-icon>{{ $t("icons.add") }}</v-icon>
          &nbsp;
            {{ $t("buttons.create_determina") }}
        </v-btn>
        <v-btn
          v-if="withExportPdf"
          color="accent"
          small
          class="mx-4"
          @click.stop="onExportPdf"
        >
          <v-icon>{{ $t("icons.export_pdf") }}</v-icon>
          &nbsp;
          {{ $t("buttons.export_pdf") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-toolbar>

  <!-- v-toolbar Floating v-else class="toolbar-94 mt-15">
    <v-layout align-center justify-space-between fill-heigh>
      <v-flex md2 class="align-left">
        <v-btn color="accent" normal class="mx-4" @click.stop="onAdd">
          <v-icon>{{ $t("icons.add") }}</v-icon>
          &nbsp;
          <span>Crea nuova procedura</span>
        </v-btn>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex md4>
        <v-toolbar-title>
          <p v-if="subtitle" class="caption text-sm-left">{{ subtitle }}</p>
        </v-toolbar-title>
      </v-flex>
    </v-layout>
  </v-toolbar -->

  <!-- /div -->
</template>

<script>
import _ from "lodash";
import ReportFilter from "@/components/Filter/ReportFilter"
import Enums from "@/mixins/enums";

export default {
  props: {
    title: String,
    subtitle: String,
    reportType: String,
    withSearch: Boolean,
    withAdd: Boolean,
    withExportPdf: Boolean,
    withFilter: Boolean,
    withInvoicing: Boolean,
    tagName: String,
    withAddDetermina: Boolean,
    allClear: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      searchQuery: "",
      isTyping: false
    };
  },
  components: {
    ReportFilter
  },
  computed: {
    Enums() {
      return Enums;
    },
  },
  watch:{
    searchQuery: _.debounce(function() {
      this.isTyping = false;
    }, 700),
    isTyping: function(value) {
      if (!value) {
        this.$emit("onSearch", this.searchQuery);
      }
    }
  },
  methods: {
    onAdd() {
      this.$emit("onAdd");
    },
    onAddProceeding(params) {
      this.$emit("onAddProceeding", params);
    },
    onExportPdf() {
      this.$emit("onExportPdf");
    },
    onFilter() {
      this.$emit("onFilter");
    },
    onReportFilter(reportFilter) {
      this.$emit("onReportFilter", reportFilter);
    },
    onInvoicing() {
      this.$emit("onInvoicing");
    },
    /*
    onSearchType() {
      this.$emit("onSearch", this.searchText);
    }*/
  },
  created() {}
};
</script>

<style scoped>
.v-toolbar {
  height: 90px !important;
}
.v-toolbar__content {
  min-height: 90px !important;
  color: #f00;
}
.colored-button {
  background-color: var(--v-secondary-lighten5);
}
</style>
