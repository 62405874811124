import Vue from "vue";

const UserService = {
  async fetchOne(id) {
    if (id == null) {
      return null;
    }
    try {
      const data = await Vue.$jsonApi.find("user", id, {
        include: "tenants,role,customer"
      });
      return data.data[0];
    } catch (error) {
      console.log("fetchOne error: ", error);
      return { error: error };
    }
  },
  async fetchAll(searchFilterPagination) {
    try {
      const res = await Vue.$jsonApi.findAll("users", searchFilterPagination);
      return res;
    } catch (error) {
      console.log("fetchAll error: ", error);
      return { error: error };
    }
  },
  async createOrUpdate(payload) {
    try {
      if (payload.id == "" || payload.id == null) {
        return await Vue.$jsonApi.create("user", payload);
      } else {
        return await Vue.$jsonApi.update("user", payload);
      }
    } catch (error) {
      console.log("createOrUpdate error: ", error);
      return { error: error };
    }
  },
  /*async updatePassword(userId, params) {
    await Vue.$jsonApi.update("user", {
      id: userId,
      password: params.password,
      confirm_password: params.confirm_password,
      old_password: params.old_password
    });
  },*/
  async loadProfile(userId) {
    if (userId) {
      try {
        const filter = {
          filter: { id: userId },
          include: "role"
        };
        const data = await Vue.$jsonApi.findAll("user", filter);
        return data.data[0];
      } catch (error) {
        console.log("loadProfile error: ", error);
        return null;
      }
    }
    return null;
  }
};

export { UserService };
