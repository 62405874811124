var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      this.listMode === _vm.Enums.LIST_MODE.FULL
        ? _c(
            "v-card",
            [
              _c("ToolbarList", {
                attrs: {
                  title: _vm.$t("labels.procedures"),
                  subtitle: _vm.$tc("labels.found_elements", this.total),
                  withAdd: true,
                  tagName: _vm.Enums.TAG_NAME.ATTI_AMMINISTRAZIONI_ENTI,
                  withSearch: true
                },
                on: {
                  onAdd: function($event) {
                    return _vm.openFormDialog(_vm.Enums.FORM_MODE.CREATE)
                  },
                  onSearch: _vm.searchFunc
                }
              }),
              _c("BaseGrid", {
                attrs: {
                  tableName: "proceedings",
                  headers: this.tableData.headers,
                  items: this.tableData.items,
                  totalLength: this.total,
                  pagination: this.tableOptions,
                  loading: this.onLoading,
                  withEdit: this.$can("editButton", "proceedings"),
                  withDelete: this.$can("deleteButton", "proceedings")
                },
                on: {
                  onPaginationChanged: _vm.handlePaginationChanged,
                  onEdit: _vm.handleEdit,
                  onDelete: _vm.handleDelete
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "content-class": "edit-form-dialog" },
          model: {
            value: _vm.formDialog,
            callback: function($$v) {
              _vm.formDialog = $$v
            },
            expression: "formDialog"
          }
        },
        [
          _vm.formDialog
            ? _c("ProceedingForm", {
                attrs: {
                  mode: _vm.formMode,
                  selectedItem: _vm.formItem,
                  withModelType: this.withModelType,
                  withModelId: this.withModelId,
                  procedureId: this.folderId,
                  tagName: this.tagName,
                  proceedingType: this.proceeding_type
                },
                on: {
                  formSucceed: _vm.handleFormSucceed,
                  formCancel: _vm.handleFormCancel
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }