<template>
  <v-container fluid>
    <ToolbarDetail
      :title="detailTitle | upperCase"
      :withEdit="true"
      @onEdit="openFormDialog(Enums.FORM_MODE.UPDATE)"
      @onBack="setTicketFlag(true)"
    ></ToolbarDetail>

    <template v-if="loaded">
      <v-tabs
        class="my-4"
        v-model="activeTab"
        background-color="secondary"
        dark
        slider-color="accent"
        @change="syncUrl"
      >
        <v-tab name="data">{{ $t("labels.data") }}</v-tab>
        <v-tab name="attachments">{{ $t("labels.attachments") }}</v-tab>
        <v-tab name="applicationLog">{{ $t("labels.applicationLog") }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <v-card flat>
            <TicketForm
              :mode="Enums.FORM_MODE.SHOW"
              :selectedItem="selectedTicket"
            ></TicketForm>
          </v-card>
        </v-tab-item>        
        <v-tab-item>
          <v-card flat>
            <AttachmentsList
              withModelType="tickets"
              :withModelId="this.selectedTicket.id"
              :listMode="Enums.LIST_MODE.TABBED"
              machineName="GENERIC_FILES"
            ></AttachmentsList>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <ApplicationLogList
              withModelType="tickets"
              :withModelId="this.selectedTicket.id"
              :listMode="Enums.LIST_MODE.TABBED"
            ></ApplicationLogList>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <v-dialog v-model="formDialog" persistent content-class="edit-form-dialog">
      <TicketForm
        v-if="formDialog"
        :mode="Enums.FORM_MODE.UPDATE"
        :selectedItem="this.preparedSelected()"
        @formSucceed="handleFormSucceed"
        @formCancel="handleFormCancel"
        @formDelete="handleDelete"
      ></TicketForm>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "lodash";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { mapActions, mapGetters, mapMutations } from "vuex";
import FormsHelper from "@/mixins/formsHelper";
import TicketForm from "@/components/Ticket/TicketForm";
import AttachmentsList from "@/components/Attachment/AttachmentsList";
import ToolbarDetail from "@/components/Shared/ToolbarDetail";
// import ActivitiesList from "@/components/Activity/ActivitiesList";
import ApplicationLogList from "@/views/ApplicationLogList";

export default {
  name: "TicketDetails",
  props: {
    id: {
      required: true,
    },
  },
  data() {
    return {
      resourceType: this.$t("resource_types.ticket"),
      activeTab: null,
      loaded: false,
    };
  },
  components: {
    TicketForm,
    AttachmentsList,
    ToolbarDetail,
    // ActivitiesList,
    ApplicationLogList,
  },
  mixins: [SharedMixin, Enums, FormsHelper],
  watch: {
    id() {
      this.initComponent();
    },
  },
  methods: {
    ...mapMutations("filters", ["setTicketFilter", "setTicketFlag"]),
    ...mapActions("ticket", [
      "ajaxTicketSetSelected",
      "ajaxTicketCreateOrUpdate",
    ]),

    async initComponent() {
      await this.ajaxTicketSetSelected({ id: this.$props.id });
      this.loaded = true;
    },
    syncUrl() {},
    handleFormSucceed() {
      this.closeFormDialog();
      this.ajaxTicketSetSelected({ id: this.$props.id });
    },
    handleFormCancel() {
      this.closeFormDialog();
    },
    handleDelete() {
      this.$router.pushCatch({ name: "tickets" });
    },
    preparedSelected() {
      return _.clone(this.selectedTicket);
    },
  },
  computed: {
    ...mapGetters("ticket", [
      "tickets",
      "selectedTicket",
      "total",
      "onLoading",
    ]),
    Enums() {
      return Enums;
    },
    ticketURL() {
      const r = this.$router.resolve({
        name: "tickets",
        params: {},
      });
      return r.href;
    },
    detailTitle() {
      return this.$t("labels.detailResourceTypeName", {
        resourceType: this.resourceType,
        resourceName: this.selectedTicket.code,
      });
    },
  },
  created() {
    this.initComponent();
  },
};
</script>
