import Vue from "vue";
import JsonApi from "devour-client";
import _ from "lodash";

const jsonApi = new JsonApi({
  apiUrl: `${process.env.VUE_APP_ENDPOINT_API_URI}/private`
});
Vue.$jsonApi = jsonApi;

Object.defineProperty(Vue.prototype, "$jsonApi", {
  get() {
    return jsonApi;
  },
  post() {
    return jsonApi;
  }
});
const errorMiddleware = {
  name: "error-middleware",
  error: payload => {
    const errors = _.get(payload, "response.data.errors", []);
    return errors;
  }
};

Vue.$jsonApi.replaceMiddleware("errors", errorMiddleware);

Vue.$jsonApi.define("agreement", {
  agreements_services: [],
  renewal: "",
  action: "",
  amount: "",
  detail: "",
  start_date: "",
  end_date: "",
  last_email_sent_date: "",
  email_sent_count: null,
  emails_count: 0,
  invoicing: "",
  service_id: "",
  tenant_id: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  customer_id: "",
  metadata: "",
  metadata_agreements: "",
  note: "",
  outgoing: "",
  cig: "",
  code: "",
  cup: "",
  custom_fields: "",
  charge_for_call: "",
  hourly_rate: "",
  worked_hours: 0,
  //extra_hours: 0,
  state: "",
  custom_date: "",
  templates: [],
  customer: {
    jsonApi: "hasOne",
    type: "customer"
  },
  tenant: {
    jsonApi: "hasOne",
    type: "tenant"
  },
  service: {
    jsonApi: "hasOne",
    type: "service"
  },
  services: {
    jsonApi: "hasMany",
    type: "service"
  },
  attachments: {
    jsonApi: "hasMany",
    type: "attachment"
  },
  generationSessionItem: {
    jsonApi: "hasMany",
    type: "generationSessionItem"
  },
  agreementServices: {
    jsonApi: "hasMany",
    type: "agreementService"
  },
  invoices: {
    jsonApi: "hasMany",
    type: "invoice"
  },
  invoice_periods: {
    jsonApi: "hasMany",
    type: "invoice_periods"
  },
  invoice_data: "",
  filed: "",
  solicitation_status: ""
});

Vue.$jsonApi.define("agreementService", {
  service_id: "",
  agreement_id: "",
  metadata: "",
  created_at: "",
  updated_at: "",
  agreement: {
    jsonApi: "hasOne",
    type: "service"
  },
  service: {
    jsonApi: "hasOne",
    type: "service"
  }
});

Vue.$jsonApi.define("invoice", {
  model_id: "",
  model_type: "",
  cashed: "",
  code: "",
  email_sent_count: 0,
  invoice_data: "",
  period: "",
  price: "",
  state: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  invoice_periods: {
    jsonApi: "hasMany",
    type: "invoice_periods"
  },
  invoiceable: {
    jsonApi: "hasMany",
    type: "invoiceable"
  },/*
  order: {
    jsonApi: "hasOne",
    type: "orders"
  },
  ticket: {
    jsonApi: "hasOne",
    type: "tickets"
  },*/
});

Vue.$jsonApi.define("invoiceable", {
  amount: "",
  start_date: "",
  end_date: "",
  last_email_sent_date: "",
  email_sent_count: null,
  service_id: "",
  tenant_id: "",
  customer_id: "",
  note: "",
  outgoing: "",
  cig: "",
  code: "",
  cup: "",
  custom_fields: "",
  invoice_data: "",
  charge_for_call: "",
  hourly_rate: "",
  worked_hours: 0,
  state: "",
  solicitation_status: "",
  number: "",
  invoice_code: "",
  filed: false,
  agreement_id: null,
  attachment_id: null,
  additional_costs: "",
  billed_total_hours: null,
  category: "",
  create_date: "",
  due_date: "",
  detail: "",
  internal_state: "",
  final_note: "",
  model_id: null,
  model_type: null,
  opened_by: "",
  operator_ids: null,
  priority: "",
  subject: "",
  ticket_type: null,
  ticket_subtype: null,
  total_hours: null,
  user_id: null,
  metadata: "",
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

Vue.$jsonApi.define("invoice_period", {
  agreement_id: "",
  // disabled_option: '',
  enabled: "",
  invoice_id: "",
  metadata: "",
  name: "",
  period: "",
  price: "",
  state: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  invoice: {    
    jsonApi: "hasOne",
    type: "invoice"
  }
});

Vue.$jsonApi.define("customer", {
  address: "",
  cap: "",
  cf: "",
  city: "",
  code: "",
  dsga: "",
  email: "",
  mechanographyc_code: "",
  metadata: "",
  metadata_scuole: "",
  name: "",
  pec: "",
  peo: "",
  phone: "",
  piva: "",
  preferences: "",
  province: "",
  referent_name: "",
  referent_surname: "",
  tenant_id: "",
  type_id: "",
  website: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  agreement: {
    jsonApi: "hasMany",
    type: "agreement"
  },
  customer_type: {
    jsonApi: "hasOne",
    type: "customer_type"
  },
  generationSessionItem: {
    jsonApi: "hasMany",
    type: "generationSessionItem"
  },
  links: {
    jsonApi: "hasMany",
    type: "link"
  },
  attachments: {
    jsonApi: "hasMany",
    type: "attachment"
  },
  tenant: {
    jsonApi: "hasOne",
    type: "tenant"
  }
});

Vue.$jsonApi.define("user", {
  first_name: "",
  last_name: "",
  email: "",
  username: "",
  password: "",
  confirm_password: "",
  old_password: "",
  password_reset_token: "",
  enabled: true,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  tenants: {
    jsonApi: "hasMany",
    type: "tenant"
  },
  tenant_ids: "",
  role: {
    jsonApi: "hasOne",
    type: "role"
  },
  role_id: "",
  generationSessions: {
    jsonApi: "hasMany",
    type: "generation_session"
  },
  customer_id: "",
  customer: {
    jsonApi: "hasOne",
    type: "customer"
  },
});

Vue.$jsonApi.define("tenant", {
  machine_name: "",
  name: "",
  email: "",
  pec: "",
  address: "",
  phone: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  website: "",
  accounting_emails: null,
  domain: "",
  agreements: {
    jsonApi: "hasMany",
    type: "agreement"
  }
});

Vue.$jsonApi.define("service", {
  amount: "",
  hours: null,
  machine_name: "",
  name: "",
  note: "",
  public_page: "",
  folders: "",
  rate_orario_assistenza: null,
  self_generated_file: false,
  settings: "",
  tenant_id: "",
  settings: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  tenant: {
    jsonApi: "hasOne",
    type: "tenant"
  },
  links: {
    jsonApi: "hasMany",
    type: "link"
  }
});

Vue.$jsonApi.define("activity", {
  description: "",
  model_type: null,
  model_id: null,
  time_spent: null,
  user_id: null,
  created_at: "",
  updated_at: "",
  deleted_at: null,
  start_date: null,
  end_date: null,
  billed_time: null,
  person_number: null,
  user: {
    jsonApi: "hasOne",
    type: "user"
  }
});

Vue.$jsonApi.define("application_log", {
  metadata: "",
  model_id: "",
  model_type: "",
  tenant_id: "",
  user_id: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  user: {
    jsonApi: "hasOne",
    type: "user"
  }
});

Vue.$jsonApi.define("attachment", {
  detail: "",
  file: "",
  temp_file: "",
  model_id: "",
  model_type: "",
  name: "",
  machine_name: "",
  role_id: "",
  document_type_id: "",
  file_type_id: "",
  storage_key: "",
  folder_id: "",
  title: "",
  tag_ids: "",
  created_at: "",
  updated_at: "",
  deleted_at: null,
  file_type: {
    jsonApi: "hasOne",
    type: "file_type"
  },
  taggings: {
    jsonApi: "hasMany",
    type: "tagging"
  },
  folder: {
    jsonApi: "hasOne",
    type: "folder"
  },
});

Vue.$jsonApi.define("cost", {
  name: "",
  detail: "",
  cost: "",
  date: "",
  created_at: "",
  updated_at: "",
  deleted_at: null
});

Vue.$jsonApi.define("role", {
  name: "",
  level: "",
  created_at: "",
  updated_at: "",
  deleted_at: null
});

Vue.$jsonApi.define("application_setting", {
  settings: "",
  type_settings: "",
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

Vue.$jsonApi.define("ticket", {
  agreement_id: null,
  attachment_id: null,
  additional_costs: "",
  billed_total_hours: null,
  category: "",
  create_date: "",
  code: "",
  customer_id: null,
  due_date: "",
  detail: "",
  end_date: "",
  internal_state: "",
  final_note: "",
  model_id: null,
  model_type: null,
  opened_by: "",
  operator_ids: null,
  priority: "",
  start_date: "",
  state: "",
  completed_at: "",
  subject: "",
  tenant_id: null,
  ticket_type: null,
  ticket_subtype: null,
  total_hours: null,
  user_id: null,
  created_at: "",
  updated_at: "",
  deleted_at: null,
  metadata: "",
  activities: {
    jsonApi: "hasMany",
    type: "activity"
  },
  agreement: {
    jsonApi: "hasOne",
    type: "agreement"
  },
  attachments: {
    jsonApi: "hasMany",
    type: "attachment"
  },
  customer: {
    jsonApi: "hasOne",
    type: "customer"
  },
  tenant: {
    jsonApi: "hasOne",
    type: "tenant"
  },
  user: {
    jsonApi: "hasOne",
    type: "user"
  },
  users: {
    jsonApi: "hasMany",
    type: "user"
  }
});

Vue.$jsonApi.define("customer_type", {
  name: "",
  description: "",
  created_at: "",
  updated_at: "",
  deleted_at: null,
  customer: {
    jsonApi: "hasMany",
    type: "customer"
  }
});

Vue.$jsonApi.define("link", {
  description: "",
  model_type: "",
  model_type_id: "",
  model_id: "",
  folder_id: "",
  tag_ids: "",
  url: "",
  created_at: "",
  updated_at: "",
  deleted_at: null,
  customer: {
    jsonApi: "hasOne",
    type: "customer"
  },
  service: {
    jsonApi: "hasOne",
    type: "service"
  }
});

Vue.$jsonApi.define("file_type", {
  description: "",
  enabled: "",
  machine_name: "",
  model_type: "",
  name: "",
  output_file_type_id: "",
  parent_id: "",
  published: "",
  target_service_id: "",
  created_at: "",
  updated_at: "",
  deleted_at: null,
  attachment: {
    jsonApi: "hasMany",
    type: "attachment"
  },
  generationSessionItem: {
    jsonApi: "hasMany",
    type: "generationSessionItem"
  }
});

Vue.$jsonApi.define("agreement_schema", {
  schema: "",
  service_id: "",
  created_at: "",
  updated_at: "",
  deleted_at: null,
  service: {
    jsonApi: "hasOne",
    type: "service"
  }
});

Vue.$jsonApi.define("generation_session", {
  creator_user_id: "",
  generated_file: "",
  info_generation: "",
  metadata: "",
  state: "",
  tot_file: "",
  created_at: "",
  updated_at: "",
  deleted_at: null,
  user: {
    jsonApi: "hasOne",
    type: "user"
  }
});

Vue.$jsonApi.define("generation_session_item", {
  agreement_id: "",
  customer_id: "",
  template_type_id: "",
  generation_session_id: "",
  allegato: "",
  error: "",
  state: "",
  startGeneration: false,
  created_at: "",
  updated_at: "",
  deleted_at: null,
  customer: {
    jsonApi: "hasOne",
    type: "customer"
  },
  agreement: {
    jsonApi: "hasOne",
    type: "agreement"
  },
  file_type: {
    jsonApi: "hasOne",
    type: "file_type"
  }
});

Vue.$jsonApi.define("order", {
  attachment_id: "",
  created_at: "",
  customer_id: "",
  deleted_at: "",
  detail: "",
  end_date: "",
  invoice_code: "",
  number: "",
  state: "",
  tenant_id: "",
  updated_at: "",
  user_id: "",
  operator_ids: null,
  attachments: {
    jsonApi: "hasMany",
    type: "attachment"
  },
  customer: {
    jsonApi: "hasOne",
    type: "customer"
  },
  tenant: {
    jsonApi: "hasOne",
    type: "tenant"
  },
  tickets: {
    jsonApi: "hasOne",
    type: "ticket"
  },
  invoice: {
    jsonApi: "hasOne",
    type: "invoice"
  }
});

Vue.$jsonApi.define("email_message", {
  bcc: "",
  created_at: "",
  body: "",
  cc: "",
  from: "",
  subject: "",
  to: "",
  updated_at: ""
});

Vue.$jsonApi.define("report", {
  item: null
});

Vue.$jsonApi.define("tag", {
  name: "",
  tenant_id: "",
  created_at: "",
  updated_at: "",
  deleted_at: null
});


Vue.$jsonApi.define("permalink", {
  agreement_id: "",
  subject: "",
  folder_id: "",
  created_at: "",
  updated_at: "",
  deleted_at: null,
  links: "",
  type: "",
  url:"",
  model_type:"",
  permalink:"",
  cig:"",
  model_id:""
});

Vue.$jsonApi.define("tagging", {
  tag_id: "",
  taggable_id: "",
  taggable_type: "",
  created_at: "",
  updated_at: "",
  deleted_at: null,
  taggable: {
    jsonApi: "hasOne",
    type: "attachment"    
  },
  tag: {
    jsonApi: "hasOne",
    type: "tag"    
  }
});

Vue.$jsonApi.define("folder", {
  name: "",
  description: "",
  model_id: "",
  model_type: "",
  parent_id: "",
  sorting: "",
  fixed_structure: "",
  enabled: "",
  tag_ids: "",
  cig: "",
  cup: "",
  metadata: "",
  created_at: "",
  updated_at: "",
  deleted_at: null,
  permalink: {
    jsonApi: "hasOne",
    type: "permalink"
  },
  tagging: {
    jsonApi: "hasOne",
    type: "tagging"
  },
  tag: {
    jsonApi: "hasOne",
    type: "tag"
  }
});

Vue.$jsonApi.define("proceeding", {
  date: "",
  cig: "",
  cup: "",
  protocol_number: "",
  object: "",
  beneficiary: "",
  price: "",
  proceeding_type: "",
  proceeding_subtype: "",
  procedure_id: "",
  model_id: "",
  model_type: "",
  temp_file: "",
  filename: "",
  folder_id: "",
  metadata: "",
  permalink_id: "",
  created_at: "",
  updated_at: "",
  deleted_at: null,
  tagging: {
    jsonApi: "hasOne",
    type: "tagging"
  },
  procedure: {
    jsonApi: "hasOne",
    type: "folder"
  },
  attachments: {
    jsonApi: "hasMany",
    type: "attachment"
  }
});

Vue.$jsonApi.define("procedure", {
  name: "",
  description: "",
  model_id: "",
  model_type: "",
  parent_id: "",
  sorting: "",
  fixed_structure: "",
  enabled: "",
  tag_ids: "",
  cip: "",
  cup: "",
  metadata: "",
  created_at: "",
  updated_at: "",
  deleted_at: null,
  tagging: {
    jsonApi: "hasOne",
    type: "tagging"
  },
  tag: {
    jsonApi: "hasOne",
    type: "tag"
  }
});