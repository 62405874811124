var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.languages, function(lang) {
      return _c(
        "button",
        {
          key: lang.title,
          class: { active: lang.language == _vm.$i18n.locale, langbtn: true },
          on: {
            click: function($event) {
              return _vm.changeLocale(lang.language)
            }
          }
        },
        [_c("flag", { attrs: { iso: lang.flag, squared: false } })],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }