<template>
  <v-btn v-if="this.file" color="primary" icon @click="download">
    <v-icon dark>{{ $t("icons.download") }}</v-icon>
  </v-btn>
</template>

<script>
import Vue from "vue";
import Enums from "@/mixins/enums";
import { mapGetters } from "vuex";

export default {
  props: {
    fileRef: {
      type: String
    },
    fileName: {
      type: String
    }
  },
  data() {
    return {
      file: this.$props.fileRef,
      name: this.$props.fileName
    };
  },
  watch: {
    fileRef() {
      this.file = this.$props.fileRef;
    },
    fileName() {
      this.name = this.$props.fileName;
    }
  },

  mixins: [Enums],
  computed: {
    ...mapGetters("login", ["isLogged", "token"]),
    Enums() {
      return Enums;
    }
  },
  methods: {
    toast(context, mode, text) {
      const messageObj = {
        context,
        mode,
        text,
        snackbar: true,
      };
      this.$store.dispatch('snackbar/showMessage', messageObj);
    },
    download() {
      if (this.file) {
        Vue.Axios({
          url: `${process.env.VUE_APP_ENDPOINT_API_URI}/private/attachments/${this.file}/download`,
          method: "get",
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${this.token}`,
            "Content-Type": "application/json"
          },
          responseType: "blob"
        })
          .then(res => {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(res.data);
            link.download = this.name;
            link.click();
          })
          .catch(err => {
            console.log("download catch error: ", err);
            this.toast('error', '', 'Server momentaneamente non disponibile');
            //this.$emit("showError", [{ title: "Errore" }]);
          });
      }
    }
  }
};
</script>
