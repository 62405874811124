/* eslint-disable no-unused-vars */
import _ from "lodash";

export default {
  data() {
    return {
      progress: false,
      tableData: { headers: [], items: [] },
      tableOptions: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["name"],
        sortDesc: [false]
      }
    };
  }
};
