var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticStyle: { margin: "30px 0" } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  staticClass: "toolbar-94",
                  staticStyle: { "background-color": "#7389ae24" },
                  attrs: { flat: "", elevation: "1" }
                },
                [
                  _c(
                    "v-toolbar-title",
                    {
                      staticClass: "mx-7",
                      staticStyle: { "font-weight": "bold" }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("labels.atti_procedura.title.step1"))
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        "align-center": "",
                        "justify-space-between": "",
                        "fill-heigh": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        { staticClass: "align-left", attrs: { md2: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-4",
                              attrs: { color: "accent", normal: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.openFormDialog(
                                    _vm.Enums.FORM_MODE.CREATE
                                  )
                                }
                              }
                            },
                            [
                              _c("v-icon", [
                                _vm._v(_vm._s(_vm.$t("icons.add")))
                              ]),
                              _vm._v("\n                 \n                "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("buttons.create-permalink"))
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        { staticStyle: { margin: "30px 0" } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  staticClass: "toolbar-94",
                  staticStyle: { "background-color": "#7389ae24" },
                  attrs: { flat: "", elevation: "1" }
                },
                [
                  _c(
                    "v-toolbar-title",
                    {
                      staticClass: "mx-7",
                      staticStyle: { "font-weight": "bold" }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("labels.atti_procedura.title.step2"))
                      )
                    ]
                  ),
                  _c("v-spacer")
                ],
                1
              ),
              _c("BaseGrid", {
                attrs: {
                  tableName: "permalinks",
                  headers: this.tableData.headers,
                  items: this.tableData.items,
                  totalLength: this.totalCount,
                  loading: this.onLoading,
                  pagination: this.tableOptions
                },
                on: { onPaginationChanged: _vm.handlePaginationChanged }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "content-class": "edit-form-dialog" },
          model: {
            value: _vm.formDialog,
            callback: function($$v) {
              _vm.formDialog = $$v
            },
            expression: "formDialog"
          }
        },
        [
          _vm.formDialog
            ? _c("PermaLinkForm", {
                attrs: {
                  mode: _vm.Enums.FORM_MODE.CREATE,
                  agreementId: this.withAgreementId,
                  folderId: this.folderId
                },
                on: {
                  formSucceed: _vm.handleFormSucceed,
                  formCancel: _vm.handleFormCancel
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "content-class": "edit-form-dialog" },
          model: {
            value: _vm.secondFormDialog,
            callback: function($$v) {
              _vm.secondFormDialog = $$v
            },
            expression: "secondFormDialog"
          }
        },
        [
          _vm.secondFormDialog
            ? _c("ProceedingForm", {
                attrs: {
                  mode: _vm.secondFormMode,
                  selectedItem: _vm.secodFormItem,
                  withModelType: _vm.Enums.MODEL_NAME.AGREEMENTS,
                  withModelId: this.withAgreementId,
                  withPermalink: this.permalink,
                  tagName: _vm.Enums.TAG_NAME.DETERMINE_DECISIONI_CONTRARRE,
                  proceedingType: _vm.Enums.PROCEEDING_TYPE.DETERMINA
                },
                on: {
                  formSucceed: _vm.handleSecondFormSucceed,
                  formCancel: _vm.handleSecondFormCancel
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }