var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "form-container title", attrs: { fluid: "" } },
    [
      _c("v-card-title", { staticClass: "form-title title" }, [
        _vm._v(_vm._s(_vm._f("capitalize")(this.formTitle)))
      ]),
      _c(
        "v-form",
        {
          staticClass: "form-body",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("v-text-field", {
                attrs: { label: _vm.$t("forms.emails.from"), readonly: "" },
                model: {
                  value: _vm.form.from,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "from", $$v)
                  },
                  expression: "form.from"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("v-text-field", {
                attrs: { label: _vm.$t("forms.emails.to"), readonly: "" },
                model: {
                  value: _vm.form.to,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "to", $$v)
                  },
                  expression: "form.to"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("v-text-field", {
                attrs: { label: _vm.$t("forms.emails.subject"), readonly: "" },
                model: {
                  value: _vm.form.subject,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "subject", $$v)
                  },
                  expression: "form.subject"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("forms.emails.body")) + "\n      "
              ),
              _c("MailViewer", { attrs: { body: _vm.form.body } })
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "text-right", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    { attrs: { text: "" }, on: { click: _vm.onClose } },
                    [_vm._v(_vm._s(_vm.$t("buttons.close")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }