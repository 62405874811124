import _ from "lodash";

export default {
  watch: {
    veeErrors: {
      handler() {
        _.forEach(this.rules, (val, key) => {
          this.rules[key] = [
            () => (this.veeErrors.has(key) ? this.veeErrors.first(key) : true)
          ];
        });
      },
      deep: true
    }
  }
};
