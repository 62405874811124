/*  eslint no-shadow: ["error", { "allow": ["state"] }] */
/*  eslint no-param-reassign: ["error", { "props": false }] */
import Vue from "vue";
import Vuex from "vuex";
import { ActivityService } from "@/services/activity.service";
import { HelperService } from "@/services/helper.service";

Vue.use(Vuex);

const state = {
  activities: [],
  selected: {},
  loading: false,
  totalCount: 0
};

const getters = {
  activities: state => state.activities,
  selectedActivity: state => state.selected,
  onLoading: state => state.loading,
  total: state => state.totalCount
};

const mutations = {
  setSelected(state, sel) {
    state.selected = sel;
  },
  setList(state, sel) {
    state.activities = sel;
  },
  setTotal(state, sel) {
    state.totalCount = Number(sel);
  },
  setLoading(state, sel) {
    state.loading = sel;
  }
};

const actions = {
  async ajaxActivitySetSelected({ commit }, payload) {
    await HelperService.genericSetSelected(
      commit,
      ActivityService.fetchOne,
      payload.id
    );
  },

  async ajaxActivityFetchAll({ commit }, payload) {
    let searchFilterPagination = HelperService.localToJSONAPIPagination(
      payload
    );
    searchFilterPagination.include = "user";
    searchFilterPagination.sort = HelperService.fixIncludedResourceSorting(
      searchFilterPagination
    );

    await HelperService.genericFetchAll(
      commit,
      ActivityService.fetchAll,
      searchFilterPagination,
      payload.headers_query,
      null
    );
  },

  async ajaxActivityCreateOrUpdate({ commit }, payload) {
    return HelperService.genericCreateOrUpdate(
      commit,
      ActivityService.createOrUpdate,
      payload
    );
  },

  async ajaxActivityDelete({ commit }, payload) {
    return HelperService.genericDelete(
      commit,
      ActivityService.delete,
      payload.id
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
