var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "form-container title", attrs: { fluid: "" } },
    [
      _c("v-card-title", { staticClass: "form-title title" }, [
        _vm._v("\n    " + _vm._s(_vm._f("capitalize")(this.formTitle)) + "\n  ")
      ]),
      _c(
        "v-stepper",
        {
          attrs: { vertical: "" },
          model: {
            value: _vm.e6_step,
            callback: function($$v) {
              _vm.e6_step = $$v
            },
            expression: "e6_step"
          }
        },
        [
          _c(
            "v-stepper-step",
            { attrs: { complete: _vm.e6_step > 1, step: "1" } },
            [_vm._v(_vm._s(_vm.$t("forms.generation_sessions.section1")))]
          ),
          _c(
            "v-stepper-content",
            { attrs: { step: "1" } },
            [
              _c("v-text-field", {
                staticStyle: { "max-width": "500px", "min-width": "250px" },
                attrs: {
                  "append-icon": "search",
                  label: _vm.$t("misc.search"),
                  "single-line": "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.search1,
                  callback: function($$v) {
                    _vm.search1 = $$v
                  },
                  expression: "search1"
                }
              }),
              _vm.customersOnLoading
                ? _c("v-progress-linear", {
                    attrs: { color: "info", height: "5", indeterminate: "" }
                  })
                : _vm._e(),
              _c("v-data-table", {
                ref: "dTable1",
                staticClass: "elevation-1",
                attrs: {
                  headers: this.tableData1.headers,
                  items: this.tableData1.items,
                  options: _vm.tableOptions1,
                  "server-items-length": _vm.customersTotal,
                  search: _vm.search1,
                  "show-select": "",
                  "item-key": "code"
                },
                on: {
                  "update:options": function($event) {
                    _vm.tableOptions1 = $event
                  },
                  "toggle-select-all": function($event) {
                    return _vm.handleToggleAll1($event)
                  }
                },
                model: {
                  value: _vm.customersSelected,
                  callback: function($$v) {
                    _vm.customersSelected = $$v
                  },
                  expression: "customersSelected"
                }
              }),
              _c("FormButtons", {
                attrs: { multiForm: true, multiLayout: 0 },
                on: { onNext: _vm.onNext, onCancel: _vm.onCancel }
              })
            ],
            1
          ),
          _c(
            "v-stepper-step",
            { attrs: { complete: _vm.e6_step > 2, step: "2" } },
            [_vm._v(_vm._s(_vm.$t("forms.generation_sessions.section2")))]
          ),
          _c(
            "v-stepper-content",
            { attrs: { step: "2" } },
            [
              _c("v-text-field", {
                staticStyle: { "max-width": "500px", "min-width": "250px" },
                attrs: {
                  "append-icon": "search",
                  label: _vm.$t("misc.search"),
                  "single-line": "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.search2,
                  callback: function($$v) {
                    _vm.search2 = $$v
                  },
                  expression: "search2"
                }
              }),
              _vm.attachmentTypesOnLoading
                ? _c("v-progress-linear", {
                    attrs: { color: "info", height: "5", indeterminate: "" }
                  })
                : _vm._e(),
              _c("v-data-table", {
                ref: "dTable2",
                staticClass: "elevation-1",
                attrs: {
                  headers: this.tableData2.headers,
                  items: this.tableData2.items,
                  options: _vm.tableOptions2,
                  "server-items-length": _vm.attachmentTypesTotal,
                  search: _vm.search2,
                  "show-select": "",
                  "item-key": "machine_name"
                },
                on: {
                  "update:options": function($event) {
                    _vm.tableOptions2 = $event
                  },
                  "toggle-select-all": function($event) {
                    return _vm.handleToggleAll2($event)
                  }
                },
                model: {
                  value: _vm.templatesSelected,
                  callback: function($$v) {
                    _vm.templatesSelected = $$v
                  },
                  expression: "templatesSelected"
                }
              }),
              _c("FormButtons", {
                attrs: { multiForm: true, multiLayout: 1 },
                on: {
                  onBack: _vm.onBack,
                  onNext: _vm.onNext,
                  onCancel: _vm.onCancel
                }
              })
            ],
            1
          ),
          _c(
            "v-stepper-step",
            { attrs: { complete: _vm.e6_step > 3, step: "3" } },
            [_vm._v(_vm._s(_vm.$t("forms.generation_sessions.section3")))]
          ),
          _c(
            "v-stepper-content",
            { attrs: { step: "3" } },
            [
              _c("v-checkbox", {
                attrs: {
                  primary: "",
                  "hide-details": "",
                  label: _vm.$t("forms.generation_sessions.attach")
                },
                model: {
                  value: _vm.form.allegato,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "allegato", $$v)
                  },
                  expression: "form.allegato"
                }
              }),
              _c("FormButtons", {
                attrs: { multiForm: true, multiLayout: 1 },
                on: {
                  onBack: _vm.onBack,
                  onNext: _vm.onNext,
                  onCancel: _vm.onCancel
                }
              })
            ],
            1
          ),
          _c(
            "v-stepper-step",
            { attrs: { complete: _vm.e6_step > 4, step: "4" } },
            [_vm._v(_vm._s(_vm.$t("forms.generation_sessions.section4")))]
          ),
          _c(
            "v-stepper-content",
            { attrs: { step: "4" } },
            [
              _c(
                "v-card",
                { staticClass: "mb-5", attrs: { color: "grey lighten-5" } },
                [
                  _c("v-card-title", [
                    _vm._v(
                      _vm._s(_vm.$t("forms.generation_sessions.section1")) +
                        " (" +
                        _vm._s(_vm.customersSelected.length) +
                        "\n          " +
                        _vm._s(_vm.$t("forms.generation_sessions.selected")) +
                        ")"
                    )
                  ]),
                  _c("v-data-table", {
                    attrs: {
                      headers: this.tableData1.headers,
                      items: _vm.customersSelected,
                      options: _vm.tableOptions3,
                      "item-key": "code"
                    },
                    on: {
                      "update:options": function($event) {
                        _vm.tableOptions3 = $event
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "items",
                        fn: function(props) {
                          return [
                            _c("td", { staticStyle: { width: "30%" } }, [
                              _vm._v(_vm._s(props.item.code))
                            ]),
                            _c("td", { staticStyle: { width: "30%" } }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(props.item.mechanographyc_code) +
                                  "\n            "
                              )
                            ]),
                            _c("td", { staticStyle: { width: "40%" } }, [
                              _vm._v(_vm._s(props.item.name))
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("v-card-title", [
                    _vm._v(
                      _vm._s(_vm.$t("forms.generation_sessions.section2")) +
                        " (" +
                        _vm._s(_vm.templatesSelected.length) +
                        "\n          " +
                        _vm._s(_vm.$t("forms.generation_sessions.selected")) +
                        ")"
                    )
                  ]),
                  _c("v-data-table", {
                    attrs: {
                      headers: this.tableData2.headers,
                      items: _vm.templatesSelected,
                      options: _vm.tableOptions4,
                      "item-key": "machine_name"
                    },
                    on: {
                      "update:options": function($event) {
                        _vm.tableOptions4 = $event
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "items",
                        fn: function(props) {
                          return [
                            _c("td", { staticStyle: { width: "30%" } }, [
                              _vm._v(_vm._s(props.item.machine_name))
                            ]),
                            _c("td", { staticStyle: { width: "70%" } }, [
                              _vm._v(_vm._s(props.item.name))
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("v-checkbox", {
                    attrs: {
                      "input-value": _vm.form.allegato,
                      primary: "",
                      "hide-details": "",
                      readonly: "",
                      label: _vm.$t("forms.generation_sessions.attach")
                    }
                  }),
                  _c("br")
                ],
                1
              ),
              _c("FormButtons", {
                attrs: { multiForm: true, multiLayout: 2 },
                on: {
                  onBack: _vm.onBack,
                  onSave: _vm.onSubmit,
                  onCancel: _vm.onCancel
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }