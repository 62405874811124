<template>
  <v-navigation-drawer
    class="filter-drawer"
    v-model="drawer"
    v-show="drawer"
    absolute
    temporary
  >
    <v-toolbar color="primary" dark>
      <v-toolbar-title>{{ $t("filters.filters") }}</v-toolbar-title>
    </v-toolbar>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ $t("filters.tenants") }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="false"
          matchAttribute="value"
          v-model="tenantsAll"
          :alwaysShowAll="true"
          @change="getAllFiltersSelected"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ $t("filters.status") }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="false"
          matchAttribute="value"
          v-model="statesAll"
          :alwaysShowAll="true"
          @change="getAllFiltersSelected"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ $t("filters.customers") }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="true"
          matchAttribute="value"
          v-model="customersAll"
          :autocomplete="true"
          @change="getAllFiltersSelected"
          @search="handleCustomerSearch"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-col align="right">
      <v-btn color="primary" small outlined @click="clearFilters">{{
        $t("buttons.clear")
      }}</v-btn>
    </v-col>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { HelperService } from "@/services/helper.service";
import _ from "lodash";
import Enums from "@/mixins/enums";
import FilterList from "@/components/Shared/FilterList";
import SharedMixin from "@/mixins/shared";

export default {
  components: {
    FilterList
  },
  mixins: [SharedMixin],
  data() {
    return {
      drawer: false,
      statesAll: [
        {
          name: this.$t("filters.all"),
          value: Enums.STATE_ORDER.ALL,
          checked: false,
          enabled: false,
          all: true
        },
        {
          name: this.$t("filters.opened"),
          value: Enums.STATE_ORDER.OPENED,
          checked: false,
          enabled: false
        },
        {
          name: this.$t("filters.closed"),
          value: Enums.STATE_ORDER.CLOSED,
          checked: false,
          enabled: false
        },
        {
          name: this.$t("filters.invoiced"),
          value: Enums.STATE_ORDER.INVOICED,
          checked: false,
          enabled: false
        }
      ],
      tenantsAll: [],
      customersAll: [],
      //showCustomersAll: false,
      //showButton: false,
      customersSelected: null
    };
  },
  methods: {
    ...mapActions("customer", ["ajaxCustomerFetchAll"]),
    ...mapActions("tenant", ["ajaxTenantFetchAll"]),
    ...mapMutations("filters", ["setOrderFilter", "setOrderFlag"]),

    save() {
      this.setOrderFilter({
        statesAll: this.statesAll,
        tenantsAll: this.tenantsAll,
        customersAll: this.customersAll
      });
    },
    load() {
      if (!this.order_flag) return false;
      this.setOrderFlag(false);
      if (_.isEmpty(this.order_filter)) return false;
      this.statesAll = this.order_filter.statesAll;
      this.tenantsAll = this.order_filter.tenantsAll;
      this.customersAll = this.order_filter.customersAll;
      return true;
    },

    open() {
      this.drawer = true;
    },

    getText(customer) {
      let name = customer ? `${customer.name}` : "";
      if (customer.tenant && customer.tenant.machine_name) {
        name = `${customer.tenant.machine_name} - ${name}`;
      }
      return name;
    },
    handleCustomerSearch(query) {
      if (query) {
        this.getCustomers(query);
      }
    },
    getCustomers: _.debounce(async function getCustomers(val) {
      const filterAndPagination = { search: val, include: "tenant" };
      await this.ajaxCustomerFetchAll(filterAndPagination);

      let customersArray = [];
      if (this.customers) {
        this.customers.forEach(function(customer) {
          customersArray.push({
            value: customer.id,
            subtitle: customer.tenant.name,
            name: customer.name,
            checked: false,
            enabled: false
          });
        });
      }
      this.customersAll = _.orderBy(
        this.mergeWithSelected(customersArray, this.customersAll),
        "name"
      );
    }, process.env.VUE_APP_DEBOUNCE_TIME),
    async getTenants() {
      let tenantsArray = this.tenantsAll;
      await this.ajaxTenantFetchAll();
      if (this.tenants) {
        this.tenants.forEach(function(tenant) {
          tenantsArray.push({
            id: tenant.id,
            name: tenant.name,
            checked: false,
            enabled: false
          });
        });
      }
      this.tenantsAll = tenantsArray;
    },
    //get filtri selezionati
    getAllFiltersSelected() {
      let stateFilter = this.computeFilter(this.statesAll);
      let customerFilter = this.computeFilter(this.customersAll);
      let tenantFilter = this.computeFilter(this.tenantsAll, "id");

      let allClear = this.computeClear([
        stateFilter,
        customerFilter,
        tenantFilter
      ]);

      this.save();
      this.$emit(
        "filtersSelected",
        allClear,
        stateFilter,
        customerFilter,
        tenantFilter
      );
    },
    //svuota filtri
    clearFilters() {
      let stateFilter = "";
      let customerFilter = "";
      let tenantFilter = "";

      this.statesAll = this.resetFilter(this.statesAll);
      this.customersAll = this.resetFilter(this.customersAll);
      this.tenantsAll = this.resetFilter(this.tenantsAll);

      this.save();
      this.$emit(
        "filtersSelected",
        true,
        stateFilter,
        customerFilter,
        tenantFilter
      );
    }
  },
  filters: {},
  computed: {
    ...mapGetters("customer", ["customers"]),
    ...mapGetters("tenant", ["tenants"]),
    ...mapGetters("service", ["services"]),
    ...mapGetters("filters", ["order_filter", "order_flag"]),
    Enums() {
      return Enums;
    }
  },
  props: {},
  watch: {
    drawer(val) {
      this.$emit("drawerChanged", val);
    }
  },
  async mounted() {
    if (!this.load()) {
      this.getCustomers("");
      await this.getTenants();
    }
    this.getAllFiltersSelected();
  }
};
</script>

<style scoped>
.dateComponent {
  border: 1px solid grey;
  margin-left: 15px;
  margin-right: 120px;
}
</style>
