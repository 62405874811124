<template>
  <v-toolbar flat class="toolbar-94" elevation="1">
    <v-btn v-if="withBack" fab small @click="goTo()">
      <v-icon>{{ $t("icons.arrow_back") }}</v-icon>
    </v-btn>
    <v-toolbar-title class="mx-7">{{ title }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn
      v-if="withClone"
      color="accent"
      small
      outlined
      class="mx-4 text-xs-right"
      @click.stop="onClone"
    >
      <v-icon small>{{ $t("icons.clone") }}</v-icon>
      &nbsp;
      {{ $t("buttons.clone") }}
    </v-btn>
    <v-btn
      v-if="withEdit"
      color="accent"
      small
      outlined
      class="mx-4 text-xs-right"
      @click.stop="onEdit"
    >
      <v-icon small>{{ $t("icons.edit") }}</v-icon>
      &nbsp;
      {{ $t("buttons.edit") }}
    </v-btn>
    <v-btn
      v-if="withDelete"
      md2
      class="text-xs-right"
      icon
      @click.stop="onDelete"
    >
      <v-icon>{{ $t("icons.delete") }}</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  props: {
    title: String,
    withClone: Boolean,
    withEdit: Boolean,    
    withDelete: Boolean,
    withBack: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      searchText: "",
      mainPage: ""
    };
  },
  computed: {},
  methods: {
    getPosition(string, subString, index) {
      return string.split(subString, index).join(subString).length;
    },
    onClone() {
      this.$emit("onClone")
    },
    onEdit() {
      this.$emit("onEdit");
    },
    onDelete() {
      this.$emit("onDelete");
    },
    goTo() {
      this.$emit("onBack");
      this.$router.pushCatch({ name: this.mainPage });
    }
  },
  created() {
    let array = this.$route.path.split("/");
    let test = array[array.length - 2].split("#")[0];
    if (!isNaN(test)) {
      array.splice(array.length - 1, 1);
      array.splice(array.length - 1, 1);
    } else {
      array.splice(array.length - 1, 1);
    }
    this.mainPage = array[array.length - 1];
    /*this.mainPage = this.$route.path.substring(
      1,
      this.getPosition(this.$route.path,"/",2)
    );*/
  }
};
</script>
