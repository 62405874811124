<template>
  <div>
    <v-card>
      <ToolbarList
        :title="$t('labels.users')"
        :subtitle="$tc('labels.found_elements', this.total)"
        :withSearch="true"
        :withAdd="true"
        @onSearch="searchFunc"
        @onAdd="openFormDialog(Enums.FORM_MODE.CREATE)"
      ></ToolbarList>
      <BaseGrid
        tableName="users"
        :headers="this.tableData.headers"
        :items="this.tableData.items"
        :totalLength="this.total"
        :pagination="this.tableOptions"
        :loading="this.onLoading"
        @onPaginationChanged="handlePaginationChanged"
        :hideActions="true"
      ></BaseGrid>
    </v-card>

    <v-dialog v-model="formDialog" persistent content-class="edit-form-dialog">
      <UserForm
        v-if="formDialog"
        :mode="Enums.FORM_MODE.CREATE"
        @formSucceed="handleFormSucceed"
        @formCancel="handleFormCancel"
      ></UserForm>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import ToolbarList from "@/components/Shared/ToolbarList";
import BaseGrid from "@/components/Shared/BaseGrid";
import UserForm from "@/components/User/UserForm";
import FormsHelper from "@/mixins/formsHelper";
import { HelperService } from "@/services/helper.service";
import Enums from "@/mixins/enums";
// import { Ability } from "@casl/ability";
// import {} from "@/abilities";

const computed = {
  ...mapGetters("user", ["users", "total", "onLoading"]),
  _() {
    return _;
  },
  Enums() {
    return Enums;
  },
  usersList() {
    return this.users;
  }
};

export default {
  name: "usersList",
  components: {
    UserForm,
    ToolbarList,
    BaseGrid
  },
  data() {
    return {
      resourceType: this.$t("resource_types.user"),
      progress: false,
      formDialog: false,
      formMode: "",
      search: "",
      tableData: { headers: [], items: [] },
      tableOptions: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["last_name"],
        sortDesc: [false]
      }
    };
  },
  methods: {
    ...mapActions("user", ["ajaxUserFetchAll"]),
    searchFunc: async function(searchQuery) {
      this.search = searchQuery
      await this.fetchData();
    },
    async fetchData(needle = null) {
      const _search = needle ? needle : this.search || "";
      let payloadFAP = HelperService.buildFilterAndPagination({
        search: _search,
        pagination: this.tableOptions
      });
      await this.ajaxUserFetchAll(payloadFAP);
      this.initTable();
    },
    initTable() {
      this.tableData.headers = this.mapHeaders();
      this.tableData.items = this.mapItems();
    },
    mapHeaders() {
      const tableHeaders = [];
      tableHeaders.push({
        text: "First name",
        value: "first_name",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Last name",
        value: "last_name",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Mail",
        value: "email",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Username",
        value: "username",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Phone",
        value: "phone",
        sortable: true,
        align: "start"
      });      
      return tableHeaders;
    },
    mapItems() {
      const map = _.map(this.usersList, item => {
        item.fields = {
          first_name: {
            data: item.first_name,
            dataType: "text",
            css: "text-xs-left"
          },
          last_name: {
            data: item.last_name,
            dataType: "text",
            css: "text-xs-left"
          },
          email: { data: item.email, dataType: "text", css: "text-xs-left" },
          username: { data: item.username, dataType: "text", css: "text-xs-left" },
          phone: { data: item.phone, dataType: "text", css: "text-xs-left" }
        };
        item.click = {
          actionType: "router-link",
          namedRoot: "userDetails",
          namedRootId: item.id,
          icon: this.$t("icons.detail"),
          css: "text-xs-left",
          show: true
        };
        item.actions = {};
        return item;
      });
      return map;
    },
    handleFormSucceed() {
      this.closeFormDialog();
      this.fetchData();
    },
    handleFormCancel() {
      this.closeFormDialog();
    },
    handlePaginationChanged(paginationOpts) {
      this.tableOptions = paginationOpts;
      this.fetchData();
    }
  },
  mixins: [FormsHelper, Enums],
  async created() {
    await this.fetchData();
  },
  computed
};
</script>
