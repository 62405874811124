<template>
  <div>
    <v-toolbar v-if="!onlyAdd" class="toolbar-94">
      <v-layout align-center justify-space-between fill-heigh>
        <v-flex md4>
          <v-toolbar-title>
            <p v-if="title" class="text-h4 text-sm-left my-1">{{ title }}</p>
            <p v-if="subtitle" class="caption text-sm-left">{{ subtitle }}</p>
          </v-toolbar-title>
        </v-flex>
        <v-flex md6 v-if="withSearch">
          <v-text-field
            :label="$t('misc.search')"
            v-model="searchText"
            @input="onSearchType"
            clearable
            single-line
          ></v-text-field>
        </v-flex>
        <v-spacer></v-spacer>

        <v-flex md2 class="align-right">
          <v-btn
            v-if="withAdd"
            color="accent"
            small
            class="mx-4"
            @click.stop="onAdd"
          >
            <v-icon>{{ $t("icons.add") }}</v-icon>
            &nbsp;
            {{ $t("buttons.create") }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-toolbar>

    <v-toolbar Floating v-else class="toolbar-94 mt-15">
      <v-layout align-center justify-space-between fill-heigh>
        <v-flex md2 class="align-left">
          <v-btn color="accent" normal class="mx-4" @click.stop="onAdd">
            <v-icon>{{ $t("icons.add") }}</v-icon>
            &nbsp;
            <!-- {{ $t("buttons.create") }}-->
            <span>Crea nuova procedura</span>
          </v-btn>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex md4>
          <v-toolbar-title>
            <p v-if="subtitle" class="caption text-sm-left">{{ subtitle }}</p>
          </v-toolbar-title>
        </v-flex>
      </v-layout>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subtitle: String,
    withSearch: Boolean,
    withAdd: Boolean,
    onlyAdd: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchText: "",
    };
  },
  computed: {},
  methods: {
    
    onAdd() {
      this.$emit("onAdd");
    },
    onSearchType() {
      this.$emit("onSearch", this.searchText);
    },
  },
  created() {},
};
</script>
