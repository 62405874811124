/*  eslint no-shadow: ["error", { "allow": ["state"] }] */
/*  eslint no-param-reassign: ["error", { "props": false }] */
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  application_settings: [],
  selected: {},
  loading: false
};

const getters = {
  application_settings: state => state.application_settings,
  selectedApplicationSetting: state => state.selected,
  applicationSettingLoading: state => state.loading
};

const mutations = {
  setApplicationSettings(state, sel) {
    state.application_settings = sel;
  },
  setApplicationSetting(state, sel) {
    state.selected = sel;
  },
  setLoading(state, sel) {
    state.loading = sel;
  }
};

const actions = {
  async setApplicationSetting({ commit }, selected) {
    commit("setLoading", true);
    const res = await Vue.$jsonApi.find("application_setting", selected);
    commit("setApplicationSetting", res.data);
    commit("setLoading", false);
  },

  async setApplicationSettingsState({ commit }) {
    commit("setLoading", true);
    const res = await Vue.$jsonApi.findAll("application_setting");
    commit("setApplicationSettings", res.data);
    commit("setLoading", false);
  },
  // eslint-disable-next-line no-empty-pattern
  async updateApplicationSettings({}, selected) {
    try {
      await Vue.$jsonApi.update("application_setting", {
        id: selected.id,
        type_settings: selected.type_settings,
        settings: JSON.stringify(selected.settings)
      });
      // eslint-disable-next-line no-empty
    } finally {
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
