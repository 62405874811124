var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.logged
        ? _c(
            "v-app-bar",
            {
              attrs: {
                "clipped-left": _vm.$vuetify.breakpoint.lgAndUp,
                color: "primary",
                app: "",
                height: "73"
              }
            },
            [
              _c("div", { staticClass: "bar-element" }, [
                _c("h2", { staticClass: "app-title" }, [_vm._v("NetCRM")])
              ]),
              _c("div", { staticClass: "bar-element" }, [_c("Navigation")], 1),
              _c("div", { staticClass: "bar-right-group" }, [
                _c(
                  "div",
                  { staticClass: "bar-element" },
                  [_c("UserProfile")],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "bar-element" },
                  [_c("LocaleSwitch")],
                  1
                ),
                _c("div", { staticClass: "bar-element" }, [_c("Logout")], 1)
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }