export default {
  property: "1",
  style: {
    icons: {
      formFieldSuccessIcon: "fas fa-check",
      formFieldSuccessIconColor: "green"
    },
    class: {
      formFieldRequiredCaption:
        "red--text text--lighten-1 font-italic caption validation-message"
    }
  }
};
