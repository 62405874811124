<template>
  <v-container>
    <v-card  v-if="this.listMode === Enums.LIST_MODE.FULL">
      <ToolbarList
        :title="$t('labels.procedures')"
        :subtitle="$tc('labels.found_elements', this.total)"
        :withAdd="true"
        @onAdd="openFormDialog(Enums.FORM_MODE.CREATE)"
        :tagName="Enums.TAG_NAME.ATTI_AMMINISTRAZIONI_ENTI"
        :withSearch="true"
        @onSearch="searchFunc"
      ></ToolbarList>

      <BaseGrid
        tableName="proceedings"
        :headers="this.tableData.headers"
        :items="this.tableData.items"
        :totalLength="this.total"
        :pagination="this.tableOptions"
        :loading="this.onLoading"
        @onPaginationChanged="handlePaginationChanged"
        :withEdit="this.$can('editButton', 'proceedings')"
        :withDelete="this.$can('deleteButton', 'proceedings')"
        @onEdit="handleEdit"
        @onDelete="handleDelete"
      ></BaseGrid>
    </v-card>
    

    <v-dialog v-model="formDialog" persistent content-class="edit-form-dialog">
      <ProceedingForm
        v-if="formDialog"
        :mode="formMode"
        :selectedItem="formItem"
        :withModelType="this.withModelType"
        :withModelId="this.withModelId"
        :procedureId="this.folderId"
        :tagName="this.tagName"
        :proceedingType="this.proceeding_type"
        @formSucceed="handleFormSucceed"
        @formCancel="handleFormCancel"
      ></ProceedingForm>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import ProceedingForm from "@/components/Proceeding/ProceedingForm";
import ToolbarTab from "@/components/Shared/ToolbarTab";
import ToolbarList from "@/components/Shared/ToolbarList";
import { HelperService } from "@/services/helper.service";
import BaseGrid from "@/components/Shared/BaseGrid";
import FormsHelper from "@/mixins/formsHelper";
import DatatablesHelper from "@/mixins/datatablesHelper";
import Enums from "@/mixins/enums";

const computed = {
  ...mapGetters("proceeding", [
    "proceedings",
    "total",
    "onLoading"
  ]),
  ...mapGetters("folder", [
    "folders"
  ]),
  _() {
    return _;
  },
  Enums() {
    return Enums;
  },
  proceedingsList() {
    return this.proceedings;
  }
};

export default {
  components: {
    ProceedingForm,
    ToolbarTab,
    ToolbarList,
    BaseGrid
  },
  props: {
    withModelType: {
      default: Enums.MODEL_NAME.AGREEMENT,
      type: String
    },
    withModelId: {
      required: true,
      type: String
    },
    listMode: {
      default: Enums.LIST_MODE.TABBED,
      type: String
    },
    tagName: {
      type: String
    },
    folderId: {}
  },
  data() {
    return {
      search: "",
      procedure_id: "",
      proceeding_type: "", //Enums.PROCEEDING_TYPE.DETERMINA,
      tableOptions: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["date"],
        sortDesc: [false]
      }
    };
  },
  mixins: [FormsHelper, Enums, DatatablesHelper],
  watch: {
  },
  methods: {
    ...mapActions("proceeding", [
      "ajaxProceedingFetchAll",
      "ajaxProceedingDelete"
    ]),
    ...mapActions("folder", [
      "ajaxFolderFetchAll"
    ]),
    searchFunc: async function(searchQuery) {
      this.search = searchQuery
      await this.fetchData();
    },
    async fetchData(needle = null) {
      if (this.$props.tagName) {
        if (this.$props.tagName == Enums.TAG_NAME.DETERMINE_DECISIONI_CONTRARRE) {
          this.proceeding_type = Enums.PROCEEDING_TYPE.DETERMINA
        } else if (this.$props.tagName == Enums.TAG_NAME.ATTI_NOMINA) {
          this.proceeding_type = Enums.PROCEEDING_TYPE.NOMINA
        } else if (this.$props.tagName == Enums.TAG_NAME.ATTI_AMMINISTRAZIONI_ENTI) {
          this.proceeding_type = Enums.PROCEEDING_TYPE.ATTI_PROCEDURA
        }
      }

      const _search = needle ? needle : this.search || "";

      let _procedure_filter = { model_type: this.Enums.MODEL_NAME.AGREEMENT, parent_id: "null" };
      let _procedure_filter_criteria = { tag_name: this.Enums.TAG_NAME.ATTI_AMMINISTRAZIONI_ENTI };
      let _filter = { model_type: this.Enums.MODEL_NAME.AGREEMENT };

      if (this.$props.withModelId) {
        _filter = { ..._filter, ...{ model_id: this.$props.withModelId } };
        _procedure_filter = { ..._procedure_filter, ...{ model_id: this.$props.withModelId } };
      }
      if (this.$props.folderId) {
        if (this.$props.tagName && (this.$props.tagName == Enums.TAG_NAME.PROCEDURA_AFFIDAMENTO || this.$props.tagName == Enums.TAG_NAME.ATTI_AMMINISTRAZIONI_ENTI)) {
          _filter = { ..._filter, ...{ procedure_id: this.$props.folderId } };
        } else {
          _filter = { ..._filter, ...{ folder_id: this.$props.folderId } };
        }
      }
      /*if (this.proceeding_type) {
        _filter = { ..._filter, ...{ proceeding_type: this.proceeding_type } };
      }*/

      let payloadFAP = HelperService.buildFilterAndPagination({
        filter: _procedure_filter,
        filter_criteria: _procedure_filter_criteria
      });
      
      await this.ajaxFolderFetchAll(payloadFAP);
      if (this.folders && this.folders.length == 1) {
        this.procedure_id = this.folders[0].id;
      }

      payloadFAP = HelperService.buildFilterAndPagination({
        search: _search,
        filter: _filter,
        pagination: this.tableOptions,
      });
      await this.ajaxProceedingFetchAll(payloadFAP);

      this.initTable();
    },
    initTable() {
      this.tableData.headers = this.mapHeaders();
      this.tableData.items = this.mapItems();
    },
    mapHeaders() {
      const tableHeaders = [];
      tableHeaders.push({
        text: "Date",
        value: "date",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Protocon number",
        value: "protocol_number",
        align: "start",
        sortable: true
      });
      /*tableHeaders.push({
        text: "Cig",
        value: "cig",
        align: "start",
        sortable: true
      });*/
      tableHeaders.push({
        text: "Object",
        value: "object",
        align: "start",
        sortable: true
      });
      /*tableHeaders.push({
        text: "subtype",
        value: "proceeding_subtype",
        align: "start",
        sortable: true
      });*/
      /*tableHeaders.push({
        text: "Procedure",
        value: "is_procedure",
        align: "start",
        sortable: true
      });*/
      tableHeaders.push({
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center"
      });
      return tableHeaders;
    },
    mapItems() {
      const map = _.map(this.proceedingsList, item => {
        item.fields = {
          date: { data: item.date, dataType: "date", css: "text-xs-left" },
          protocol_number: { data: item.protocol_number, dataType: "text", css: "text-xs-left" },
          //cig: { data: item.cig, dataType: "text", css: "text-xs-left" },
          object: { data: item.object, dataType: "text", css: "text-xs-left" },
          //proceeding_subtype: { data: item.proceeding_subtype, dataType: "text", css: "text-xs-left" },
          //is_procedure: { data: item.procedure ? item.procedure.name : "-", dataType: "text", css: "text-xs-left" },
        };
        item.actions = {
          download: {
            actionType: "file-download",
            fileRef: item.attachments && item.attachments.length > 0 && item.attachments[0].storage_key ? item.attachments[0].id : null,
            fileName: item.attachments && item.attachments.length > 0 && item.attachments[0].storage_key ? item.attachments[0].name : null
          }
        };
        return item;
      });
      return map;
    },
    handlePaginationChanged(paginationOpts) {
      this.tableOptions = paginationOpts;
      this.fetchData();
    },
    handleFormSucceed() {
      this.closeFormDialog();
      this.fetchData();
    },
    handleFormCancel() {
      this.closeFormDialog();
    },

    /*debounceSearch: _.debounce(async function(val) {
      this.search = val;
      await this.fetchData();
    }, process.env.VUE_APP_DEBOUNCE_TIME),*/

    async handleEdit(item) {
      this.openFormDialog(Enums.FORM_MODE.UPDATE, item);
    },
    async handleDelete(item) {
      this.$confirm({
        message:
          this.$t("dialogs.deleteConfirmMessage") + "\n\nProcedura | " + item.object + "\n\n",
        button: {
          no: this.$t("dialogs.no"),
          yes: this.$t("dialogs.yes")
        },
        callback: async confirm => {
          if (confirm) {
            await this.ajaxProceedingDelete(item);
            this.fetchData();
          }
        }
      });
    }
  },

  async created() {
    this.formDialog = false;
    await this.fetchData();
  },
  computed
};
</script>
