<template>
  <div>
    <v-container>
      <v-card v-if="this.listMode == Enums.LIST_MODE.TABBED">
        <ToolbarTab
          :subtitle="$tc('labels.found_elements', this.total)"
          :withAdd="true"
          @onAdd="generateInvoice"
        ></ToolbarTab>

        <BaseGrid
          tableName="invoices"
          :headers="this.tableData.headers"
          :items="this.tableData.items"
          :pagination="this.tableOptions"
          @onPaginationChanged="handlePaginationChanged"
          :withEdit="true"
          @onEdit="handleEdit"
          :totalLength="total"
          :serverItems="false"
        ></BaseGrid>
      </v-card>
    </v-container>

    <v-dialog
      v-model="formDialog"
      persistent
      content-class="edit-form-dialog"
    >
      <InvoiceForm
        v-if="formDialog"
        :mode="formMode"
        :selectedItem="formItem"
        @formSucceed="handleFormSucceed"
        @formCancel="handleFormCancel"
      ></InvoiceForm>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import _ from "lodash";
import ToolbarTab from "@/components/Shared/ToolbarTab";
import BaseGrid from "@/components/Shared/BaseGrid";
import InvoiceForm from "@/components/Invoice/InvoiceForm";
import FormsHelper from "@/mixins/formsHelper";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";

const computed = {
  _() {
    return _;
  },
  Enums() {
    return Enums;
  },
  invoicesList() {
    return this.invoices;
  }
};

export default {
  components: {
    ToolbarTab,
    BaseGrid,
    InvoiceForm
  },
  props: {
    selectedItem: Object,
    listMode: {
      default: Enums.LIST_MODE.TABBED,
      type: String
    },
    onReloadPage: {}
  },
  data() {
    return {
      resourceType: this.$t("resource_types.invoice"),
      search: "",
      filter_criteria: {},
      invoiceFormKey: 0,
      invoices: [],
      total: 0,
      tableData: { headers: [], items: [] },
      tableOptions: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["period"],
        sortDesc: [true]
      }
    };
  },
  mixins: [FormsHelper, Enums, SharedMixin],  
  watch: {
    selectedItem() {
      this.fetchData();
    }
  },
  methods: {
    ...mapActions("invoice", ["ajaxInvoiceCreateOrUpdate"]),

    async generateInvoice() {
      const model_id = this.$props.selectedItem.id;
      const model_type = "agreements";
      const message = `${this.$t("dialogs.generate_invoice")}: ${
        this.$props.selectedItem.code
      }.\n ${this.$t("dialogs.sure")}`;
      this.$confirm({
        message,
        button: {
          no: this.$t("dialogs.no"),
          yes: this.$t("dialogs.yes")
        },
        callback: async confirm => {
          if (confirm) {
            await this.createOrUpdateHelper(
              Enums.FORM_MODE.CREATE,
              this.resourceType,
              this.ajaxInvoiceCreateOrUpdate,
              { model_id, model_type },
              () => "",
              () => ""
            );
            this.onReloadPage();
          }
        }
      });
    },
    async fetchData() {
      if (this.selectedItem) {
        this.invoices = this.selectedItem.invoices;
      } else this.invoices = [];
      this.total = this.invoices.length;
      this.initTable();
    },
    initTable() {
      this.tableData.headers = this.mapHeaders();
      this.tableData.items = this.mapItems();
    },
    mapHeaders() {
      const tableHeaders = [];
      tableHeaders.push({
        text: "Period",
        value: "period",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Monthly number",
        value: "monthly",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Code",
        value: "code",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Price",
        value: "price",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "State",
        value: "state",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Email_sent_count",
        value: "email_sent_count",
        align: "start",
        sortable: false
      });
      tableHeaders.push({
        text: "Cashed",
        value: "cashed",
        align: "start",
        sortable: false
      });
      tableHeaders.push({
        text: "Actions",
        value: "actions",
        align: "center"
      });
      return tableHeaders;
    },
    mapItems() {
      const map = _.map(this.invoicesList, item => {
        let cashed = item.cashed ? "CASHED" : "NOT_CASHED";
        item.fields = {
          period: { data: item.period, dataType: "text", css: "text-xs-left" },
          monthly: {
            data: this.getNumberOfMontly(item.period),
            dataType: "text",
            css: "text-xs-left"
          },
          code: { data: item.code, dataType: "text", css: "text-xs-left" },
          price: { data: item.price, dataType: "text", css: "text-xs-left" },
          state: {
            data: this.getEnumTranslationFor("invoices", item.state),
            dataType: "text",
            css: "text-xs-left"
          },
          email_sent_count: {
            data: item.email_sent_count,
            dataType: "text",
            css: "text-xs-left"
          },
          cashed: {
            data: this.getEnumTranslationFor("invoices", cashed),
            dataType: "text",
            css: "text-xs-left"
          }
        };
        item.actions = {};
        return item;
      });
      return map;
    },
    getNumberOfMontly(period) {      
      let invoice_periods = this.$props.selectedItem.invoice_periods;
      return invoice_periods.filter(item => item.period == period).length;
    },
    handleFormSucceed() {
      this.closeFormDialog();
      this.onReloadPage();
    },
    handleFormCancel() {
      this.closeFormDialog();
    },
    async handleEdit(item) {
      this.openFormDialog(Enums.FORM_MODE.UPDATE, item);
    },
    handlePaginationChanged(paginationOpts) {
      this.tableOptions = paginationOpts;
      this.fetchData();
    },
    debounceSearch: _.debounce(async function(val) {
      this.search = val;
      await this.fetchData();
    }, process.env.VUE_APP_DEBOUNCE_TIME)

   
  },
  async created() {
    await this.fetchData();
  },
  computed
};
</script>
