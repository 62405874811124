<template>
  <v-navigation-drawer
    class="filter-drawer"
    v-model="drawer"
    v-show="drawer"
    absolute
    temporary
  >
    <v-toolbar color="primary" dark>
      <v-toolbar-title>{{ $t("filters.filters") }}</v-toolbar-title>
    </v-toolbar>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.mytickets") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="false"
          matchAttribute="value"
          v-model="myticketsAll"
          :alwaysShowAll="true"
          @change="getAllFiltersSelected"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.tenant") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="false"
          matchAttribute="value"
          v-model="tenantsAll"
          :alwaysShowAll="true"
          @change="getAllFiltersSelected"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.customer") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="true"
          matchAttribute="value"
          v-model="customersAll"
          :autocomplete="true"
          @change="getAllFiltersSelected"
          @search="handleCustomerSearch"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.status") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="false"
          matchAttribute="value"
          v-model="statusesAll"
          :alwaysShowAll="true"
          @change="getAllFiltersSelected"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.internal_status") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="false"
          matchAttribute="value"
          v-model="intStatusesAll"
          :alwaysShowAll="true"
          @change="getAllFiltersSelected"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.priority") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="false"
          matchAttribute="value"
          v-model="prioritiesAll"
          :alwaysShowAll="true"
          @change="getAllFiltersSelected"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.type") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="false"
          matchAttribute="value"
          v-model="typesAll"
          :alwaysShowAll="true"
          @change="getAllFiltersSelected"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.subtype") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="false"
          matchAttribute="value"
          v-model="subtypesAll"
          :alwaysShowAll="true"
          @change="getAllFiltersSelected"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.start_date") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <datetime v-model="startDate" class="dateComponent"></datetime>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.end_date") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <datetime v-model="endDate" class="dateComponent"></datetime>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-col align="right">
      <v-btn color="primary" small outlined @click="clearFilters">
        {{ $t("buttons.clear") }}
      </v-btn>
    </v-col>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { HelperService } from "@/services/helper.service";
import _ from "lodash";
import FilterList from "@/components/Shared/FilterList";
import SharedMixin from "@/mixins/shared";

export default {
  mixins: [SharedMixin],
  components: {
    FilterList
  },
  data() {
    return {
      myticketsAll: [
        { value: "MYTICKETS_NO", checked: false, enabled: false, all: true },
        { value: "MYTICKETS_YES", checked: false, enabled: false }
      ],
      statusesAll: [
        { value: "OPENED", checked: false, enabled: false, all: true },
        { value: "CANCELED", checked: false, enabled: false },
        { value: "CLOSED", checked: false, enabled: false }
      ],
      intStatusesAll: [
        { value: "PENDING", checked: false, enabled: false },
        { value: "STARTED", checked: false, enabled: false },
        { value: "ONHOLD", checked: false, enabled: false },
        { value: "FINISHED", checked: false, enabled: false }
      ],
      prioritiesAll: [
        { value: "LOW", checked: false, enabled: false },
        { value: "MEDIUM", checked: false, enabled: false },
        { value: "URGENT", checked: false, enabled: false }
      ],
      typesAll: [
        { value: "ORDER", checked: false, enabled: false },
        { value: "TECH_SUPPORT", checked: false, enabled: false }
      ],
      subtypesAll: [
        { value: "FLAT", checked: false, enabled: false },
        { value: "TIME_BUDGET", checked: false, enabled: false },
        { value: "WARRANTY_ASSISTANCE", checked: false, enabled: false },
        { value: "WITHOUT_CONTRACT", checked: false, enabled: false }
      ],
      tenantsAll: [],
      customersAll: [],
      startDate: null,
      endDate: null,
      startDateFormatted: null,
      endDateFormatted: null,
      drawer: false
    };
  },
  methods: {
    ...mapActions("customer", ["ajaxCustomerFetchAll"]),
    ...mapActions("tenant", ["ajaxTenantFetchAll"]),
    ...mapMutations("filters", ["setTicketFilter", "setTicketFlag"]),

    save() {
      this.setTicketFilter({
        myticketsAll: this.myticketsAll,
        tenantsAll: this.tenantsAll,
        customersAll: this.customersAll,
        statusesAll: this.statusesAll,
        intStatusesAll: this.intStatusesAll,
        prioritiesAll: this.prioritiesAll,
        typesAll: this.typesAll,
        subtypesAll: this.subtypesAll,
        startDate: this.startDate,
        endDate: this.endDate
      });
    },
    load() {
      if (!this.ticket_flag) return false;
      this.setTicketFlag(false);
      if (_.isEmpty(this.ticket_filter)) return false;
      this.myticketsAll = this.ticket_filter.myticketsAll;
      this.tenantsAll = this.ticket_filter.tenantsAll;
      this.customersAll = this.ticket_filter.customersAll;
      this.statusesAll = this.ticket_filter.statusesAll;
      this.intStatusesAll = this.ticket_filter.intStatusesAll;
      this.prioritiesAll = this.ticket_filter.prioritiesAll;
      this.typesAll = this.ticket_filter.typesAll;
      this.subtypesAll = this.ticket_filter.subtypesAll;
      this.startDate = this.ticket_filter.startDate;
      this.endDate = this.ticket_filter.endDate;
      return true;
    },

    open() {
      this.drawer = true;
    },

    //funzioni get filtro
    async getTenants() {
      let tenantsArray = this.tenantsAll;
      await this.ajaxTenantFetchAll();
      if (this.tenants) {
        this.tenants.forEach(function(tenant) {
          tenantsArray.push({
            value: tenant.id,
            name: tenant.name,
            checked: false,
            enabled: false
          });
        });
      }
      this.tenantsAll = tenantsArray;
    },
    handleCustomerSearch(query) {
      if (query) {
        this.getCustomers(query);
      }
    },
    getCustomers: _.debounce(async function getCustomers(val) {
      const filterAndPagination = { search: val, include: "tenant" };
      await this.ajaxCustomerFetchAll(filterAndPagination);

      let customersArray = [];
      if (this.customers) {
        this.customers.forEach(function(customer) {
          customersArray.push({
            value: customer.id,
            subtitle:
              customer.tenant.name +
              " - " +
              customer.mechanographyc_code +
              " (" +
              customer.city +
              " - " +
              customer.province +
              ")",
            name: customer.name,
            checked: false,
            enabled: false
          });
        });
      }
      this.customersAll = _.orderBy(
        this.mergeWithSelected(customersArray, this.customersAll),
        "name"
      );
    }, process.env.VUE_APP_DEBOUNCE_TIME),
    formatDate(date) {
      if (!date) {
        return null;
      } else {
        return date.substring(0, date.indexOf("T"));
      }
    },
    //get filtri selezionati
    getAllFiltersSelected() {
      let myticketsAll = this.computeFilter(this.myticketsAll);
      let tenantFilter = this.computeFilter(this.tenantsAll);
      let customerFilter = this.computeFilterMultiple(
        this.customersAll,
        "value"
      );
      let statusFilter = this.computeFilter(this.statusesAll);
      let intStatusFilter = this.computeFilter(this.intStatusesAll);
      let priorityFilter = this.computeFilter(this.prioritiesAll);
      let typeFilter = this.computeFilter(this.typesAll);
      let subtypeFilter = this.computeFilter(this.subtypesAll);
      let startDateFilter = this.startDateFormatted;
      let endDateFilter = this.endDateFormatted;

      let allClear = this.computeClear([
        myticketsAll,
        tenantFilter,
        customerFilter,
        statusFilter,
        intStatusFilter,
        priorityFilter,
        typeFilter,
        subtypeFilter,
        startDateFilter,
        endDateFilter
      ]);

      this.save();
      this.$emit(
        "filtersSelected",
        allClear,
        myticketsAll,
        tenantFilter,
        customerFilter,
        statusFilter,
        intStatusFilter,
        priorityFilter,
        typeFilter,
        subtypeFilter,
        startDateFilter,
        endDateFilter
      );
    },
    //svuota filtri
    clearFilters() {
      let myticketsFilter = null;
      let tenantFilter = null;
      let customerFilter = [];
      let statusFilter = null;
      let intStatusFilter = null;
      let priorityFilter = null;
      let typeFilter = null;
      let subtypeFilter = null;
      let startDateFilter = null;
      let endDateFilter = null;

      this.myticketsAll = this.resetFilter(this.myticketsAll);
      this.tenantsAll = this.resetFilter(this.tenantsAll);
      this.customersAll = this.resetFilter(this.customersAll);
      this.statusesAll = this.resetFilter(this.statusesAll);
      this.intStatusesAll = this.resetFilter(this.intStatusesAll);
      this.prioritiesAll = this.resetFilter(this.prioritiesAll);
      this.typesAll = this.resetFilter(this.typesAll);
      this.subtypesAll = this.resetFilter(this.subtypesAll);
      this.startDate = null;
      this.endDate = null;

      this.save();
      this.$emit(
        "filtersSelected",
        true,
        myticketsFilter,
        tenantFilter,
        customerFilter,
        statusFilter,
        intStatusFilter,
        priorityFilter,
        typeFilter,
        subtypeFilter,
        startDateFilter,
        endDateFilter
      );
    }
  },
  filters: {},
  computed: {
    ...mapGetters("tenant", ["tenants"]),
    ...mapGetters("customer", ["customers"]),
    ...mapGetters("filters", ["ticket_filter", "ticket_flag"])
  },
  props: [],
  watch: {
    startDate(val) {
      if (val != "" && val != null) {
        this.startDateFormatted = this.formatDate(val);
        this.getAllFiltersSelected();
      } else this.startDateFormatted = null;
    },
    endDate(val) {
      if (val != "" && val != null) {
        this.endDateFormatted = this.formatDate(val);
        this.getAllFiltersSelected();
      } else this.endDateFormatted = null;
    },
    drawer(val) {
      this.$emit("drawerChanged", val);
    }
  },
  async mounted() {
    if (!this.load()) {
      this.myticketsAll = this.getLangStrings(this.myticketsAll, false);
      this.statusesAll = this.getLangStrings(this.statusesAll, true, false);
      this.intStatusesAll = this.getLangStrings(this.intStatusesAll);
      this.prioritiesAll = this.getLangStrings(this.prioritiesAll);
      this.typesAll = this.getLangStrings(this.typesAll);
      this.subtypesAll = this.getLangStrings(this.subtypesAll);
      await this.getTenants();
      this.getCustomers("");
    }
    this.getAllFiltersSelected();
  }
};
</script>
<style scoped>
.dateComponent {
  border: 1px solid grey;
  margin-left: 15px;
  margin-right: 120px;
}
</style>
