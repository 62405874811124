import Vue from "vue";
import ApiService from "./api.service";
import {TokenService} from "./token.service";

const ReportService = {
  async fetchAll(searchFilterPagination) {
    const report_type = searchFilterPagination.filter_criteria.report_type;
    if (report_type == 'questionario_gdpr') {
      searchFilterPagination.include = 'attachments'
    }
    if (report_type == 'expiring_agreements') {
      searchFilterPagination.include = 'customer,tenant,services'
    }
    try {
      const res = await Vue.$jsonApi.findAll('reports', searchFilterPagination);
      return res;
    } catch (error) {
      console.log("fetchAll error: ", error);
      return { error: error };
    }
  },
  async export(filterCriteria){
    let includeValues;
    if (filterCriteria.report_type == 'questionario_gdpr') {
      includeValues = 'attachments'
    }
    if (filterCriteria.report_type == 'expiring_agreements') {
      includeValues = 'customer,tenant,services'
    }
    const keys = Object.keys(filterCriteria);
    let textValue = '';
    keys.forEach(function eachKey(key) {
      textValue = `${textValue}&filter_criteria[${key}]=${filterCriteria[key]}`;
    })
    const exportUrl = `${process.env.VUE_APP_ENDPOINT_API_URI}/private/reports/export_file?${textValue}&include=${includeValues}`
    let token = TokenService.getToken();
    const requestData = {
      method: "get",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: exportUrl,
      responseType: 'blob',
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response;
    } catch (error) {
      return {error};
    }
  }
};

export { ReportService };
