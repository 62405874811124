<template>
  <v-container fluid class="form-container title">
    <div
      v-if="mode == Enums.FORM_MODE.CREATE || mode == Enums.FORM_MODE.UPDATE"
    >
      <v-card-title class="form-title title">
        {{ this.formTitle | capitalize }}
      </v-card-title>
      <v-form class="form-body" @submit.prevent="onSubmit">
        <div class="form-group required">
          <v-text-field
            :label="$t('forms.tenants.name')"
            v-model="form.name"
            name="name"
            v-validate.immediate="'required'"
          ></v-text-field>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('name')"
            >{{ veeErrors.first("name") }}</span
          >
        </div>
        <div class="form-group required">
          <v-text-field
            :label="$t('forms.tenants.email')"
            v-model="form.email"
            name="email"
            v-validate.immediate="'required|email'"
          ></v-text-field>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('email')"
            >{{ veeErrors.first("email") }}</span
          >
        </div>
        <div class="form-group required">
          <v-text-field
            :label="$t('forms.tenants.domain')"
            v-model="form.domain"
            name="domain"
            v-validate.immediate="'required'"
          ></v-text-field>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('domain')"
            >{{ veeErrors.first("domain") }}</span
          >
        </div>
        <div class="form-group required">
          <v-text-field
            :label="$t('forms.tenants.accountingEmails')"
            v-model="form.accounting_emails"
            name="accountingEmails"
            v-validate.immediate="'required|email'"
          ></v-text-field>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('accountingEmails')"
            >{{ veeErrors.first("accountingEmails") }}</span
          >
        </div>
        <div class="form-group">
          <v-text-field
            :label="$t('forms.tenants.address')"
            v-model="form.address"
            name="address"
          ></v-text-field>
        </div>
        <div class="form-group">
          <v-text-field
            :label="$t('forms.tenants.phone')"
            type="tel"
            v-model="form.phone"
            name="phone"
          ></v-text-field>
        </div>
        <div class="form-group required">
          <v-text-field
            :label="$t('forms.tenants.website')"
            v-model="form.website"
            name="website"
            v-validate.immediate="'required'"
          ></v-text-field>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('website')"
            >{{ veeErrors.first("website") }}</span
          >
        </div>
        <!-- BUTTONS -->
        <FormButtons
          @onSave="onSubmit"
          @onCancel="onCancel"
          :disabled="veeErrors.any()"
        />
      </v-form>
    </div>
    <div v-show="mode === Enums.FORM_MODE.SHOW" class="form-body-show">
      <DisplayKV :label="$t('forms.tenants.name')" :value="this.form.name" />
      <DisplayKV :label="$t('forms.tenants.email')" :value="this.form.email" />
      <DisplayKV
        :label="$t('forms.tenants.domain')"
        :value="this.form.domain"
      />
      <DisplayKV
        :label="$t('forms.tenants.accountingEmails')"
        :value="this.form.accounting_emails"
      />
      <DisplayKV
        :label="$t('forms.tenants.address')"
        :value="this.form.address"
      />
      <DisplayKV :label="$t('forms.tenants.phone')" :value="this.form.phone" />
      <DisplayKV
        :label="$t('forms.tenants.website')"
        :value="this.form.website"
      />
    </div>
  </v-container>
</template>

<script>
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { mapActions } from "vuex";
import DisplayKV from "@/components/Shared/DisplayKeyValue";
import FormButtons from "@/components/Shared/FormButtons";
import _ from "lodash";

export default {
  name: "TenantForm",
  props: {
    mode: {
      default: Enums.FORM_MODE.CREATE,
      type: String
    },
    selectedItem: Object
  },
  data() {
    return {
      progress: true,
      resourceType: this.$t("resource_types.tenant"),
      form: {
        name: "",
        email: "",
        domain: "",
        accounting_emails: "",
        address: "",
        phone: "",
        website: ""
      }
    };
  },
  components: { DisplayKV, FormButtons },
  mixins: [SharedMixin, Enums],
  watch: {
    selectedItem() {
      this.initForm();
      this.initComp();
    }
  },
  methods: {
    ...mapActions("tenant", ["ajaxTenantCreateOrUpdate"]),
    async onSubmit() {
      const validate = await this.$validator.validateAll();
      if (validate) {
        this.submitToStore();
      } else {
        alert(this.$t("forms.common.errorValidateFields"));
      }
    },
    async onCancel() {
      this.$emit("formCancel");
    },
    async submitToStore() {
      const payload = _.clone(this.form);
      await this.createOrUpdateHelper(
        this.mode,
        this.resourceType,
        this.ajaxTenantCreateOrUpdate,
        payload,
        payload => payload.p.name,
        payload => payload.p.name
      );
      this.$emit("formSucceed");
    },
    setForm(row = null) {
      if (row && !_.isEmpty(row)) {
        this.form.id = row.id;
        this.form.name = row.name;
        this.form.email = row.email;
        this.form.domain = row.domain;
        this.form.accounting_emails = row.accounting_emails;
        this.form.address = row.address;
        this.form.phone = row.phone;
        this.form.website = row.website;
      } else if (row == null) {
        this.form.id = "";
        this.form.name = "";
        this.form.email = "";
        this.form.domain = "";
        this.form.accounting_emails = "";
        this.form.address = "";
        this.form.phone = "";
        this.form.website = "";
      }
    },
    initForm() {
      if (this.mode == Enums.FORM_MODE.CREATE) {
        this.setForm();
      } else {
        this.setForm(this.$props.selectedItem);
      }
    },
    async initComp() {}
  },
  computed: {
    formTitle() {
      var title = "";
      switch (this.mode) {
        case Enums.FORM_MODE.CREATE:
          title = this.$t("labels.createResourceType", {
            resourceType: this.resourceType || "-"
          });
          break;
        case Enums.FORM_MODE.UPDATE:
          title = this.$t("labels.updateResourceTypeName", {
            resourceType: this.resourceType || "-",
            resourceName: this.form.name || "-"
          });
          break;

        default:
          break;
      }
      return title;
    },

    Enums() {
      return Enums;
    }
  },
  created() {
    this.initForm();
    this.initComp();
  }
};
</script>

<style scoped>
.has-error {
  color: red;
}
.required label:before {
  color: #e32;
  content: "* ";
  display: inline;
}
.summary-font {
  font-size: small;
  line-height: 24px;
}
.summary-font-bold {
  font-size: small;
  font-weight: bold;
  line-height: 24px;
}
</style>
