import Vue from "vue";

const OrderService = {
  async fetchOne(id) {
    if (id == null) {
      return null;
    }
    try {
      const data = await Vue.$jsonApi.find("order", id, {
        include: "tenant,customer,tickets"
      });
      return data.data[0];
    } catch (error) {
      console.log("fetchOne error: ", error);
      return { error: error };
    }
  },
  async fetchAll(searchFilterPagination) {
    try {
      const res = await Vue.$jsonApi.findAll("orders", searchFilterPagination);
      return res;
    } catch (error) {
      console.log("fetchAll error: ", error);
      return { error: error };
    }
  },
  async createOrUpdate(payload) {
    try {
      if (payload.id == "" || payload.id == null) {
        return await Vue.$jsonApi.create("order", payload);
      } else {
        return await Vue.$jsonApi.update("order", payload);
      }
    } catch (error) {
      console.log("createOrUpdate error: ", error);
      return { error: error };
    }
  },
  async delete(id) {
    if (id == null) {
      return null;
    }
    try {
      const data = await Vue.$jsonApi.destroy("order", id);
      return data;
    } catch (error) {
      console.log("delete error: ", error);
      return { error: error };
    }
  }
};

export { OrderService };
