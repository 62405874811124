<template>
  <div class="text-center">
    <v-overlay opacity="0.75">
      <p class="headline">Setup application...</p>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import defineRulesFor from "@/abilities";

export default {
  data() {
    return {
      polling: null
    };
  },
  computed: {
    ...mapGetters("login", ["getLoginLoading", "loggedUser"]),
    ...mapGetters("common", ["configurated"])
  },
  methods: {
    ...mapActions("login", ["ajaxSetProfile", "startTokenWatchdog"]),
    ...mapActions("common", [
      "startConfiguration",
      "completeConfiguration",
      "clockStart"
    ]),

    async applicationConfig() {
      this.startConfiguration();
      // 1) fetch user profile
      await this.ajaxSetProfile();

      if (this.loggedUser && this.loggedUser.info) {
        // 2) configure ACL for user/role
        this.setupACL();
        // 3) start timers
        this.startAllTimers();
        // END configurations
        this.completeConfiguration();
      }

      // Check configuration is complete
      if (this.configurated) {
        this.goToPage();
      }
    },
    goToPage() {
      const startPages = ["customers", "tickets", "dashboard", "agreements"];

      let found = false;
      for (const r of startPages) {
        if (this.$can("route", r)) {
          this.$router.pushCatch({ name: r });
          found = true;
          break;
        }
      }
      if (!found) this.$router.pushCatch({ name: "default" });
    },
    startAllTimers() {
      this.startTokenWatchdog();
      // this.clockStart();
    },
    setupACL() {
      this.$ability.update(defineRulesFor(this.loggedUser));
    }
  },
  created() {
    this.polling = setInterval(this.applicationConfig, 1000);
  },
  beforeDestroy() {
    clearInterval(this.polling);
  }
};
</script>
