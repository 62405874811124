<template>
  <div>
    <v-app-bar
      v-if="logged"
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      color="primary"
      app
      height="73"
    >
      <div class="bar-element">
        <h2 class="app-title">NetCRM</h2>
      </div>
      <div class="bar-element">
        <Navigation></Navigation>
      </div>
      <div class="bar-right-group">
        <div class="bar-element">
          <UserProfile></UserProfile>
        </div>
        <div class="bar-element">
          <LocaleSwitch></LocaleSwitch>
        </div>
        <div class="bar-element">
          <Logout></Logout>
        </div>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LocaleSwitch from "@/components/LocaleSwitch";
import Logout from "@/components/Logout";
import Navigation from "./Navigation";
import UserProfile from "@/components/User/UserProfile";

export default {
  name: "NavigationBar",
  components: {
    LocaleSwitch,
    Logout,
    Navigation,
    UserProfile
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("login", ["isLogged", "user"]),
    ...mapGetters("common", ["drawer"])
  },
  methods: {
    ...mapActions("common", ["toggleDrawer"]),
    logged: function() {
      return this.isLogged;
    }
  }
};
</script>

<style scoped>
.bar-element {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  margin-left: 10px;
  margin-right: 10px;
  height: 65px;
}
.bar-right-group {
  position: absolute;
  right: 16px;
  height: 65px;
}
ul {
  padding-left: 0px !important;
}
</style>
