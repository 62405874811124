var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm.hasPhoto
        ? _c(
            "div",
            { staticClass: "align-right photo-div" },
            [
              _c("v-img", {
                attrs: {
                  src: "https://randomuser.me/api/portraits/women/85.jpg",
                  id: "photo",
                  width: "70%"
                },
                on: {
                  click: function($event) {
                    return _vm.openFormDialog(_vm.Enums.FORM_MODE.UPDATE)
                  }
                }
              })
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "align-right photo-div" },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", large: "" },
                  on: {
                    click: function($event) {
                      return _vm.openFormDialog(_vm.Enums.FORM_MODE.UPDATE)
                    }
                  }
                },
                [
                  _c(
                    "v-avatar",
                    { staticClass: "teal", attrs: { size: "36px" } },
                    [
                      _c("span", { staticClass: "white--text headline" }, [
                        _vm._v(_vm._s(_vm.profileSignature))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
      _c("div", { staticClass: "master-div text-caption" }, [
        _vm._v("\n    " + _vm._s(this.getProfileFullName())),
        _c("br"),
        _vm._v("\n    " + _vm._s(this.getProfileRole()) + "\n  ")
      ]),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "content-class": "edit-form-dialog" },
          model: {
            value: _vm.formDialog,
            callback: function($$v) {
              _vm.formDialog = $$v
            },
            expression: "formDialog"
          }
        },
        [
          _vm.formDialog
            ? _c("UserForm", {
                attrs: {
                  mode: _vm.Enums.FORM_MODE.UPDATE,
                  selectedItem: _vm.loggedUser.info,
                  profile: true
                },
                on: {
                  formSucceed: _vm.handleFormSucceed,
                  formCancel: _vm.handleFormCancel
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }