var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("v-progress-circular", {
        staticClass: "mr-3",
        attrs: { indeterminate: "" }
      })
    : _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          [
            _c(
              "v-toolbar",
              {
                staticClass: "toolbar-94",
                attrs: { flat: "", elevation: "1" }
              },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { fab: "", small: "" },
                    on: {
                      click: function($event) {
                        return _vm.goTo()
                      }
                    }
                  },
                  [_c("v-icon", [_vm._v(_vm._s(_vm.$t("icons.arrow_back")))])],
                  1
                ),
                _c("v-toolbar-title", { staticClass: "mx-7" }, [
                  _vm._v(_vm._s(_vm.detailTitle))
                ]),
                _c("v-spacer"),
                !this.isRootSection
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "mx-4 text-xs-right",
                        attrs: { color: "accent", small: "", outlined: "" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.openFormDialog(
                              _vm.Enums.FORM_MODE.UPDATE
                            )
                          }
                        }
                      },
                      [
                        _c("v-icon", { attrs: { small: "" } }, [
                          _vm._v(_vm._s(_vm.$t("icons.edit")))
                        ]),
                        _vm._v(
                          "\n         \n        " +
                            _vm._s(_vm.$t("buttons.edit")) +
                            "\n      "
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          _vm.selectedFolder.fixed_structure
            ? [
                this.tagName == _vm.Enums.TAG_NAME.PROCEDURA_AFFIDAMENTO
                  ? _c(
                      "div",
                      [
                        _c("ProcedureAffidamento", {
                          attrs: {
                            withModelType: this.withModelType,
                            withModelId: this.withModelId,
                            withParentId: this.id,
                            folderId: this.id
                          }
                        })
                      ],
                      1
                    )
                  : _c("FileUploader", {
                      attrs: {
                        mode: _vm.Enums.FORM_MODE.CREATE,
                        withModelType: this.withModelType,
                        withModelId: this.withModelId,
                        tagName: this.tagName,
                        folderId: this.id
                      }
                    })
              ]
            : [
                this.tagName == _vm.Enums.TAG_NAME.ATTI_AMMINISTRAZIONI_ENTI
                  ? _c(
                      "v-card-text",
                      { staticClass: "text-left" },
                      [
                        this.isRootSection
                          ? _c(
                              "v-card",
                              { attrs: { flat: "" } },
                              [
                                _c("FoldersList", {
                                  attrs: {
                                    withModelType: this.withModelType,
                                    withModelId: this.withModelId,
                                    withParentId: this.id,
                                    listMode: _vm.Enums.LIST_MODE.FULL,
                                    tagName: this.tagName,
                                    isRootSection: this.isRootSection
                                  }
                                })
                              ],
                              1
                            )
                          : _c(
                              "v-card",
                              { key: _vm.componentKey, attrs: { flat: "" } },
                              [
                                _c("FolderForm", {
                                  attrs: {
                                    mode: _vm.Enums.FORM_MODE.SHOW,
                                    selectedItem: this.selectedFolder,
                                    withModelType: this.withModelType,
                                    withModelId: this.withModelId
                                  },
                                  on: { formSucceed: _vm.handleFormSucceed }
                                }),
                                _c("ProceedingsList", {
                                  attrs: {
                                    withModelType: this.withModelType,
                                    withModelId: this.withModelId,
                                    folderId: this.id,
                                    listMode: _vm.Enums.LIST_MODE.FULL,
                                    tagName: this.tagName
                                  }
                                })
                              ],
                              1
                            )
                      ],
                      1
                    )
                  : _c(
                      "v-card-text",
                      { staticClass: "text-left" },
                      [
                        _c(
                          "v-card",
                          { attrs: { flat: "" } },
                          [
                            _c("ProceedingsList", {
                              attrs: {
                                withModelType: this.withModelType,
                                withModelId: this.withModelId,
                                folderId: this.id,
                                listMode: _vm.Enums.LIST_MODE.FULL,
                                tagName: this.tagName
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
              ],
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "content-class": "edit-form-dialog" },
              model: {
                value: _vm.formDialog,
                callback: function($$v) {
                  _vm.formDialog = $$v
                },
                expression: "formDialog"
              }
            },
            [
              _vm.formDialog
                ? _c("FolderForm", {
                    attrs: {
                      mode: _vm.formMode,
                      selectedItem: _vm.selectedFolder,
                      withModelType: this.withModelType,
                      withModelId: this.withModelId,
                      tagName: this.tagName,
                      isRootSection: this.isRootSection
                    },
                    on: {
                      formSucceed: _vm.handleFormSucceed,
                      formCancel: _vm.handleFormCancel
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }