<template>
  <v-navigation-drawer
    class="filter-drawer"
    v-model="drawer"
    v-show="drawer"
    absolute
    temporary
  >
    <v-toolbar color="primary" dark>
      <v-toolbar-title>{{ $t("filters.filters") }}</v-toolbar-title>
    </v-toolbar>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.from") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="false"
          matchAttribute="value"
          v-model="tenantsAll"
          :alwaysShowAll="true"
          @change="getAllFiltersSelected"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.to") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <FilterList
          :allowMultiple="false"
          matchAttribute="value"
          v-model="customersAll"
          :autocomplete="true"
          @change="getAllFiltersSelected"
          @search="handleCustomerSearch"
        >
        </FilterList>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("filters.subject") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <v-text-field
          v-model="subject"
          @input="handleSubjectChange"
        ></v-text-field>
      </v-list-group>
    </v-list>
    <v-col align="right">
      <v-btn color="primary" small outlined @click="clearFilters">
        {{ $t("buttons.clear") }}
      </v-btn>
    </v-col>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { HelperService } from "@/services/helper.service";
import _ from "lodash";
import FilterList from "@/components/Shared/FilterList";
import SharedMixin from "@/mixins/shared";

export default {
  mixins: [SharedMixin],
  components: {
    FilterList
  },
  data() {
    return {
      tenantsAll: [],
      customersAll: [],
      drawer: false,
      subject: ""
    };
  },
  methods: {
    ...mapActions("customer", ["ajaxCustomerFetchAll"]),
    ...mapActions("tenant", ["ajaxTenantFetchAll"]),
    ...mapMutations("filters", ["setEmailFilter", "setEmailFlag"]),

    save() {
      this.setEmailFilter({
        tenantsAll: this.tenantsAll,
        customersAll: this.customersAll,
        subject: this.subject,
      });
    },
    load() {
      if (!this.email_flag) return false;
      this.setEmailFlag(false);
      if (_.isEmpty(this.email_filter)) return false;
      this.tenantsAll = this.email_filter.tenantsAll;
      this.customersAll = this.email_filter.customersAll;
      this.subject = this.email_filter.subject;
      return true;
    },

    open() {
      this.drawer = true;
    },

    //funzioni get filtro
    async getTenants() {
      let tenantsArray = this.tenantsAll;
      await this.ajaxTenantFetchAll();
      if (this.tenants) {
        this.tenants.forEach(function(tenant) {
          tenantsArray.push({
            value: tenant.id,
            name: tenant.name,
            email: tenant.email,
            checked: false,
            enabled: false
          });
        });
      }
      this.tenantsAll = tenantsArray;
    },

    handleSubjectChange: _.debounce(async function handleSubjectChange(val) {
      this.getAllFiltersSelected();
    }, process.env.VUE_APP_DEBOUNCE_TIME),

    handleCustomerSearch(query) {
      if (query) {
        this.getCustomers(query);
      }
    },
    getCustomers: _.debounce(async function getCustomers(val) {
      const filterAndPagination = { search: val, include: "tenant" };
      await this.ajaxCustomerFetchAll(filterAndPagination);

      let customersArray = [];
      if (this.customers) {
        this.customers.forEach(function(customer) {
          customersArray.push({
            value: customer.id,
            email: customer.peo,
            subtitle:
              customer.tenant.name +
              " - " +
              customer.mechanographyc_code +
              " (" +
              customer.city +
              " - " +
              customer.province +
              ")",
            name: customer.name,
            checked: false,
            enabled: false
          });
        });
      }
      this.customersAll = _.orderBy(
        this.mergeWithSelected(customersArray, this.customersAll),
        "name"
      );
    }, process.env.VUE_APP_DEBOUNCE_TIME),
    //get filtri selezionati
    getAllFiltersSelected() {
      let tenantFilter = this.computeFilter(this.tenantsAll,"email");
      let customerFilter = this.computeFilter(
        this.customersAll,
        "email"
      );
      let subjectFilter=this.subject;

      let allClear = this.computeClear([
        tenantFilter,
        customerFilter,
        subjectFilter,
      ]);

      this.save();
      this.$emit(
        "filtersSelected",
        allClear,
        tenantFilter,
        customerFilter,
        subjectFilter,
      );
    },
    //svuota filtri
    clearFilters() {
      let tenantFilter = null;
      let customerFilter = [];
      let subjectFilter = null;

      this.tenantsAll = this.resetFilter(this.tenantsAll);
      this.customersAll = this.resetFilter(this.customersAll);
      this.subject="";

      this.save();
      this.$emit(
        "filtersSelected",
        true,
        tenantFilter,
        customerFilter,
        subjectFilter,
      );
    }
  },
  filters: {},
  computed: {
    ...mapGetters("tenant", ["tenants"]),
    ...mapGetters("customer", ["customers"]),
    ...mapGetters("filters", ["email_filter", "email_flag"])
  },
  props: [],
  watch: {
    drawer(val) {
      this.$emit("drawerChanged", val);
    }
  },
  async mounted() {
    if (!this.load()) {
      await this.getTenants();
      this.getCustomers("");
    }
    this.getAllFiltersSelected();
  }
};
</script>
<style scoped>
.dateComponent {
  border: 1px solid grey;
  margin-left: 15px;
  margin-right: 120px;
}
</style>
