<template>
  <div v-if="isLogged">
    <v-btn id="button" text small @click="doLogout">{{
      $t("labels.logout")
    }}</v-btn>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SharedMixin from "@/mixins/shared";

export default {
  data() {
    return {};
  },
  mixins: [SharedMixin],
  computed: {
    ...mapGetters("login", ["isLogged"])
  },
  methods: {
    ...mapActions("login", ["logout"]),
    doLogout() {
      this.logout();
    }
  }
};
</script>

<style scoped>
/*
$black: #212529;
$white: #ffffff;

button {
  height: 30px;
  width: 60px;
  text-align: center;
  display: block;
  text-decoration: none;
  color: $white;
  border-radius: 0.35em;
  background-color: rgba($black, 0.25);
  transition: all 200ms ease;
}*/
#button {
  padding: 0px;
}
</style>
