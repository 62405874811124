import { render, staticRenderFns } from "./GenerationSessionItemsList.vue?vue&type=template&id=58c0dd7a&"
import script from "./GenerationSessionItemsList.vue?vue&type=script&lang=js&"
export * from "./GenerationSessionItemsList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VContainer})


/* hot reload */
if (module.hot) {
  var api = require("/home/deploy/netcrm/staging/source/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('58c0dd7a')) {
      api.createRecord('58c0dd7a', component.options)
    } else {
      api.reload('58c0dd7a', component.options)
    }
    module.hot.accept("./GenerationSessionItemsList.vue?vue&type=template&id=58c0dd7a&", function () {
      api.rerender('58c0dd7a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/GenerationSessionItemsList.vue"
export default component.exports