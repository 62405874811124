import Vue from "vue";

const AgreementServiceService = {
  async fetchOne(id) {
    if (id == null) {
      return null;
    }
    try {
      const data = await Vue.$jsonApi.find("agreementServices", id, {
        include: "service,agreement"
      });
      return data.data[0];
    } catch (error) {
      console.log("fetchOne error: ", error);
      return { error: error };
    }
  },
  async fetchAll(searchFilterPagination) {
    try {
      const res = await Vue.$jsonApi.findAll(
        "agreementServices",
        searchFilterPagination
      );
      return res;
    } catch (error) {
      console.log("fetchAll error: ", error);
      return { error: error };
    }
  }
};

export { AgreementServiceService };
