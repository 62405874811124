import Vuex from "vuex";

import common from "./modules/common";
import login from "./modules/login";
import tenant from "./modules/tenant";
import snackbar from "./modules/snackbar";
import service from "./modules/service";
import attachment from "./modules/attachment";
import template from "./modules/template";
import attachmentType from "./modules/attachmentType";
import link from "./modules/link";
import customer from "./modules/customer";
import customerType from "./modules/customerType";
import order from "./modules/order";
import ticket from "./modules/ticket";
import agreement from "./modules/agreement";
import agreementService from "./modules/agreementService";
import generationSession from "./modules/generationSession";
import generationSessionItem from "./modules/generationSessionItem";
import user from "./modules/user";
import userRole from "./modules/userRole";
import activity from "./modules/activity";
import applicationLog from "./modules/applicationLog";
import invoicePeriod from "./modules/invoicePeriod";
import invoice from "./modules/invoice";
import filters from "./modules/filters";
import applicationSetting from "./modules/applicationSetting";
import emailMessage from "./modules/emailMessage";
import report from "./modules/report";
import tag from "./modules/tag";
import tagging from "./modules/tagging";
import folder from "./modules/folder";
import proceeding from "./modules/proceeding";
import permalink from "./modules/permalink";

export default new Vuex.Store({
  modules: {
    common,
    login,
    tenant,
    snackbar,
    service,
    link,
    attachment,
    template,
    attachmentType,
    customer,
    customerType,
    order,
    ticket,
    agreement,
    agreementService,
    generationSession,
    generationSessionItem,
    user,
    userRole,
    activity,
    applicationLog,
    invoicePeriod,
    invoice,
    filters,
    applicationSetting,
    emailMessage,
    report,
    tag,
    tagging,
    folder,
    proceeding,
    permalink
  }
});
