<template>
  <div>
    <v-card>        
      <v-card-text class=text-left>            
        <v-col cols="10">
          <div class="text-h5 font-weight-bold" v-if="tagName == Enums.TAG_NAME.RELAZIONI_PA_CC || tagName == Enums.TAG_NAME.VERBALI_REVISORI_CONTI">Programma annuale</div>
          <v-row 
            v-for="(section, j) in sectionsOption"
            :key="section.value"
          >
            <v-col cols="6" v-if="!section.fileRef">
              <v-file-input                   
                ref="fileupload"
                accept=".pdf,.zip,.rar,.7zip"
                clearable
                @change="handleFileUpload"
                :label="section.title"
                :name="section.title"
                v-model="allFiles[section.value]"
              ></v-file-input>                  
            </v-col>
            <v-col cols="6" v-else class="body-1 text-left" style="color:blue;margin-left:33px">{{ section.fileName }}</v-col>                
            <v-col cols="2">
              <FileDownloader
                :fileRef="section.fileRef"
                :fileName="section.fileName"
              />
              <v-btn
                v-if="section.fileRef"
                icon
                color="primary"
                @click.stop.prevent="onDelete(section.attachment)"
              >
                <v-icon dark>{{ $t("icons.delete") }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <div class="text-h5 font-weight-bold" v-if="tagName == Enums.TAG_NAME.RELAZIONI_PA_CC || tagName == Enums.TAG_NAME.VERBALI_REVISORI_CONTI">Conto consuntivo</div>
          <v-row 
            v-for="(section, k) in sectionsOption2"
            :key="section.value"
          >
            <v-col cols="6" v-if="!section.fileRef">
              <v-file-input                   
                ref="fileupload"
                accept=".pdf,.zip,.rar,.7zip"
                clearable
                @change="handleFileUpload"
                :label="section.title"
                :name="section.title"
                v-model="allFiles[section.value]"
              ></v-file-input>                  
            </v-col>
            <v-col cols="6" v-else class="body-1 text-left" style="color:blue;margin-left:33px">{{ section.fileName }}</v-col>                
            <v-col cols="2">
              <FileDownloader
                :fileRef="section.fileRef"
                :fileName="section.fileName"
              />
              <v-btn
                v-if="section.fileRef"
                icon
                color="primary"
                @click.stop.prevent="onDelete(section.attachment)"
              >
                <v-icon dark>{{ $t("icons.delete") }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import FormsHelper from "@/mixins/formsHelper";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { HelperService } from "@/services/helper.service";
import ToolbarDetail from "@/components/Shared/ToolbarDetail";
import FileDownloader from "@/components/Shared/FileDownloader";
import DisplayKV from "@/components/Shared/DisplayKeyValue";

import FormButtons from "@/components/Shared/FormButtons";

const computed = {
  ...mapGetters("attachment", ["attachments"]),
  ...mapGetters("attachmentType", ["mappedAttachmentTypes"]),
  ...mapGetters("tag", ["tags"]),
  _() {
    return _;
  },
  Enums() {
    return Enums;
  }
};

export default {
  name: "fileUploader",
  components: {
    ToolbarDetail,
    FileDownloader,
    DisplayKV
  },
  props: {
    mode: {
      default: Enums.FORM_MODE.CREATE,
      type: String
    },
    withModelType: {
      default: "agreements",
      type: String,
    },
    withModelId: {
      type: String,
      required: true
    },
    tagName: {
      type: String,
      required: true
    },
    folderId: {}
  },
  data() {
    return {
      detailTitle: "",
      form: {
        name: "",
        detail: "",
        file: "",
        temp_file: "",
        model_id: this.$props.withModelId,
        model_type: this.$props.withModelType,
        file_type_id: null,
        folder_id: null,
        tag_ids: null,
        title: ""
      },
      allFiles: {},
      pickedFiles: null,
      acceptedFormats:
        ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, .jpg, .png",
      attachmentsTypes: [],
      sectionsOption: [],
      sectionsOption2: [],
      resourceType: this.$t("resource_types.attachment")
    };
  },
  methods: {
    ...mapActions("attachment", [
      "ajaxAttachmentFetchAll",
      "ajaxAttachmentDelete",
      "ajaxAttachmentCreateOrUpdate"
    ]),
    ...mapActions("attachmentType", ["ajaxAttachmentTypeFetchAll"]),
    ...mapActions("tag", ["ajaxTagFetchAll"]),
    async prepareTypes() {
      let payload = { filter: { machine_name: this.$props.tagName.toUpperCase() } };
      await this.ajaxAttachmentTypeFetchAll(payload);
      this.attachmentsTypes = this.mappedAttachmentTypes;
      if (this.attachmentsTypes.length == 1) {
        this.form.file_type_id = this.attachmentsTypes[0].value;
      }
    },
    async prepareTags() {
      let payload = { filter: { name: this.$props.tagName.toUpperCase() } };;
      await this.ajaxTagFetchAll(payload);
      if (this.tags.length == 1) {
        this.form.tag_ids = this.tags[0].id;
      }
    },
    async prepareAttachments() {
      let _filter = { file_type_id: this.form.file_type_id, model_id: this.form.model_id, model_type: this.form.model_type }
      if (this.$props.folderId) {
        _filter = { ..._filter, ...{ folder_id: this.$props.folderId} };
      }
      let payload = { filter: _filter };
      await this.ajaxAttachmentFetchAll(payload);
    },
    async initComp() {
      await this.prepareTypes();
      await this.prepareTags();
      await this.prepareAttachments();
      this.detailTitle = this.makeTitle();
      const sectionResult = this.mapSection();
      this.sectionsOption = sectionResult[0];
      this.sectionsOption2 = sectionResult[1];
    },
    makeTitle() {
      var title = this.$t("labels.at");
      if (this.attachmentsTypes.length == 1) {
        const resourceTranslate = this.$t("labels.detailsPublicPageAT", {
          resourceName: this.attachmentsTypes[0].text
        });
        title = `${title} -> ${resourceTranslate}`
      }
      return title;
    },
    mapSection() {
      const sections = [];

      var date_time = new Date();
      //var date_time = new Date(2024, 1, 1);
      var march_time = new Date(date_time.getFullYear(), 2, 1)
      
      let year_section_1 = date_time.getFullYear();
      let year_section_2 = date_time.getFullYear();

      if (date_time.getMonth() == 11 && this.$props.tagName != Enums.TAG_NAME.CONTO_CONSUNTIVO) {
        year_section_1 = year_section_1 + 1
      } else if (this.$props.tagName == Enums.TAG_NAME.CONTO_CONSUNTIVO && date_time >= march_time) {
        year_section_1 = year_section_1 - 1
      } else if (this.$props.tagName == Enums.TAG_NAME.CONTO_CONSUNTIVO && date_time < march_time) {
        year_section_1 = year_section_1 - 2
      }      
      
      if ((this.$props.tagName == Enums.TAG_NAME.RELAZIONI_PA_CC || this.$props.tagName == Enums.TAG_NAME.VERBALI_REVISORI_CONTI) && date_time >= march_time) {
        year_section_2 = year_section_2 - 1 
      } else if ((this.$props.tagName == Enums.TAG_NAME.RELAZIONI_PA_CC || this.$props.tagName == Enums.TAG_NAME.VERBALI_REVISORI_CONTI) && date_time < march_time) {
        year_section_2 = year_section_2 - 2
      }
      
      for (let j=0;j<5;j++) {
        var title = ``;
        if (this.$props.tagName == Enums.TAG_NAME.PROGRAMMA_ANNUALE) {
          title = `${this.attachmentsTypes[0].text} ${year_section_1-j}`;
        } else if (this.$props.tagName == Enums.TAG_NAME.RELAZIONI_PA_CC) {
          title = `Relazioni DS e DSGA (zip_pdf) al PA Esercizio finanziario ${year_section_1-j}`;
        } else if (this.$props.tagName == Enums.TAG_NAME.VERBALI_REVISORI_CONTI) {
          title = `Verbali dei revisori dei conti (zip_pdf) al PA Esercizio finanziario ${year_section_1-j}`;
        } else {
          //title = `${this.attachmentsTypes[0].text} ${year-j-1}`;
          title = `${this.attachmentsTypes[0].text} ${year_section_1-j}`;
        }
        const strValue = _.replace(title, / /g, "_").toLowerCase();

        const selectedAttachment = this.attachments.filter((item => item.name.split(".")[0] == `${title}`));
        var fileRef = null;
        var fileName = null;
        var attachment = null;
        if (selectedAttachment.length == 1) {
          attachment = selectedAttachment[0];
          fileRef = selectedAttachment[0].id;
          fileName = selectedAttachment[0].name;
        }        
        sections.push({
          title: title,
          value: strValue,
          fileRef: fileRef,
          fileName: fileName,
          attachment: attachment
        });
      }
      const secondSections = []
      if (this.$props.tagName == Enums.TAG_NAME.RELAZIONI_PA_CC || this.$props.tagName == Enums.TAG_NAME.VERBALI_REVISORI_CONTI) {
        for (let i=0;i<sections.length;i++) {
          let section = sections[i];
          //secondSections.push(section);
          var title = ``;
          if (this.$props.tagName == Enums.TAG_NAME.RELAZIONI_PA_CC) {
            //title = `Relazioni DS e DSGA (zip_pdf) al CC Esercizio finanziario ${year-i-1}`;
            title = `Relazioni DS e DSGA (zip_pdf) al CC Esercizio finanziario ${year_section_2-i}`;
          } else if (this.$props.tagName == Enums.TAG_NAME.VERBALI_REVISORI_CONTI) {
            //title = `Verbali dei revisori dei conti (zip_pdf) al CC Esercizio finanziario ${year-i-1}`;
            title = `Verbali dei revisori dei conti (zip_pdf) al CC Esercizio finanziario ${year_section_2-i}`;
          }
          const strValue = _.replace(title, / /g, "_").toLowerCase();

          const selectedAttachment = this.attachments.filter((item => item.name.split(".")[0] == `${title}`));
          var fileRef = null;
          var fileName = null;
          var attachment = null;
          if (selectedAttachment.length == 1) {
            attachment = selectedAttachment[0];
            fileRef = selectedAttachment[0].id;
            fileName = selectedAttachment[0].name;
          }        
          secondSections.push({
            title: title,
            value: strValue,
            fileRef: fileRef,
            fileName: fileName,
            attachment: attachment
          });
        }
        //return secondSections
      } 
      //else {
      //  return sections;
      //}
      return [sections, secondSections];
    },
    handleFileUpload(e) {
      var detail= "";
      var keys = Object.keys(this.allFiles);
      if (keys.length == 1) {
        var sections = this.sectionsOption.filter((item => item.value == keys[0]));
        if (sections.length == 0) {
          sections = this.sectionsOption2.filter((item => item.value == keys[0]));
        }
        if (sections.length == 1) {
          detail = sections[0].title;
        }
      }

      this.pickedFiles = e;
      if (this.pickedFiles) {
        let payload = {};
        const fileReader = new FileReader();
        const fileToRead = this.pickedFiles;
        //var ext = fileToRead.name.split(".")[1];
        var split_name = fileToRead.name.split(".");
        var ext = split_name[split_name.length - 1];
        var fileName = `${detail}.${ext}`

        fileReader.onload = async e2 => {
          try {
            payload.file_type_id = this.form.file_type_id;
            payload.tag_ids = this.form.tag_ids;
            payload.detail = detail;
            payload.name = fileName;
            payload.title = detail;
            //payload.name = fileToRead.name;
            payload.temp_file = e2.target.result;
            payload.model_id = this.$props.withModelId;
            payload.model_type = this.$props.withModelType;
            if (this.$props.folderId) {
              payload.folder_id = this.$props.folderId;
            }
            this.form = payload;
            this.onSubmit();
          } catch (e) {
            console.log("error during file upload: ", e);
            this.payload = null;
            //this.emit();
          }
        };
        fileReader.readAsDataURL(fileToRead);
      } else {
        //this.emit();
      }
    },
    async onSubmit() {
      const validate = true; //await this.$validator.validateAll();
      if (validate) {
        await this.submitToStore();
      } else {
        alert(this.$t("forms.common.errorValidateFields"));
      }
    },
    async submitToStore() {
      let payload = _.clone(this.form);
      await this.createOrUpdateHelper(
        this.mode,
        this.resourceType,
        this.ajaxAttachmentCreateOrUpdate,
        payload,
        payload => payload.p.name,
        payload => payload.p.name
      );
      await this.clearAll();
      await this.initComp();
      this.$emit("formSucceed");
    },
    onDelete(item) {
      this.$confirm({
        message:
          this.$t("dialogs.deleteConfirmMessage") + "\n\nFile | " + item.name,
        button: {
          no: this.$t("dialogs.no"),
          yes: this.$t("dialogs.yes")
        },
        callback: async confirm => {
          if (confirm) {
            await this.ajaxAttachmentDelete(item);
            await this.initComp();
          }
        }
      });
    },
    clearAll() {
      this.allFiles = {};
      this.pickedFiles = null;
      this.form = {
        name: "",
        detail: "",
        file: "",
        temp_file: "",
        model_id: this.$props.withModelId,
        model_type: this.$props.withModelType,
        file_type_id: null,
        tag_ids: null,
      }
    }
  },
  mixins: [FormsHelper, Enums, SharedMixin],
  async created() {
    this.initComp();
  },
  computed
};
</script>

<style>
.colored {
  color: #00f;
}
</style>
