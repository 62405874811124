<template>
  <div>
    <v-row v-if="this.as === Enums.DISPLAY_AS.SEPARATOR">
      <v-col cols="3" class="body-1 font-weight-light separator">{{
        this.label | upperCase
      }}</v-col>
    </v-row>
  </div>
</template>

<script>
import Enums from "@/mixins/enums";

export default {
  props: {
    label: {
      type: String
    },
    value: {},
    as: {
      type: String,
      default: "text"
    }
  },
  mixins: [Enums],
  computed: {
    Enums() {
      return Enums;
    }
  }
};
</script>
<style lang="sass">
.row .col
  padding: 4px
.separator
  margin-top: 25px
  margin-bottom: 5px
  border-bottom-width: 1px
  border-bottom-color: black
  border-bottom-style: outset
  font-style: oblique
</style>
