var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticStyle: { border: "5px solid green", "margin-top": "6%" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "text-center px-3", attrs: { cols: "12" } },
            [
              _c("img", {
                attrs: { src: require("../../assets/sf-logo.png"), width: "70" }
              })
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c("font", { attrs: { color: "green", size: "5" } }, [
            _vm._v(_vm._s(_vm.$t("customer_login_greeting")))
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-12", attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("v-form", [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("v-text-field", {
                      staticClass: "label-auth",
                      attrs: {
                        label: _vm.$t("forms.auth_registro_accessi.code"),
                        required: ""
                      },
                      model: {
                        value: _vm.code,
                        callback: function($$v) {
                          _vm.code = $$v
                        },
                        expression: "code"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("v-text-field", {
                      staticClass: "label-auth",
                      attrs: {
                        label: _vm.$t("forms.auth_registro_accessi.password"),
                        "append-icon": _vm.showPassword
                          ? "visibility"
                          : "visibility_off",
                        type: _vm.showPassword ? "password" : "text",
                        required: "",
                        "el-input": ""
                      },
                      on: {
                        "click:append": function($event) {
                          _vm.showPassword = !_vm.showPassword
                        }
                      },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.submit($event)
                        }
                      },
                      model: {
                        value: _vm.password,
                        callback: function($$v) {
                          _vm.password = $$v
                        },
                        expression: "password"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "v-row",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c("font", { attrs: { color: "green", size: "2" } }, [
                    _vm._v(
                      "Cliccare sulla matita e inserire il risultato della somma"
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c("my-captcha", {
                    attrs: {
                      callSuccess: _vm.captchaBtn,
                      mode: "math",
                      resolve: "text"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-btn",
            {
              attrs: { large: "", color: "green", disabled: _vm.btndis },
              on: { click: _vm.submit }
            },
            [
              _c("font", { attrs: { color: "white" } }, [
                _vm._v(_vm._s(_vm.$t("buttons.auth")))
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }