var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      this.listMode == _vm.Enums.LIST_MODE.TABBED ||
      this.listMode == _vm.Enums.LIST_MODE.READONLY
        ? _c(
            "v-card",
            [
              _c("ToolbarTab", {
                attrs: {
                  title: _vm.$t("labels.templates"),
                  subtitle: _vm.$tc("labels.found_elements", this.total),
                  withAdd: !this.readOnly
                },
                on: {
                  onAdd: function($event) {
                    _vm.openFormDialog(_vm.Enums.FORM_MODE.CREATE)
                    _vm.templateFormKey += 1
                  }
                }
              }),
              _c("BaseGrid", {
                attrs: {
                  tableName: "attachments",
                  headers: this.tableData.headers,
                  items: this.tableData.items,
                  totalLength: this.total,
                  pagination: this.tableOptions,
                  loading: this.onLoading,
                  withEdit: !this.readOnly,
                  withDelete: !this.readOnly
                },
                on: {
                  onPaginationChanged: _vm.handlePaginationChanged,
                  onEdit: _vm.handleEdit,
                  onDelete: _vm.handleDelete
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "content-class": "edit-form-dialog" },
          model: {
            value: _vm.formDialog,
            callback: function($$v) {
              _vm.formDialog = $$v
            },
            expression: "formDialog"
          }
        },
        [
          _vm.formDialog
            ? _c("AttachmentForm", {
                key: this.templateFormKey,
                attrs: {
                  mode: _vm.formMode,
                  selectedItem: _vm.formItem,
                  withModelType: this.withModelType,
                  withModelId: this.withModelId,
                  permittedTypes: this.permittedTypes,
                  machineName: _vm.machineName
                },
                on: {
                  formSucceed: _vm.handleFormSucceed,
                  formCancel: _vm.handleFormCancel
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }