var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("ToolbarDetail", {
        attrs: { title: _vm._f("upperCase")(_vm.detailTitle), withEdit: true },
        on: {
          onEdit: function($event) {
            return _vm.openFormDialog(_vm.Enums.FORM_MODE.UPDATE)
          }
        }
      }),
      _vm.loaded
        ? [
            _c(
              "v-tabs",
              {
                staticClass: "my-4",
                attrs: {
                  "background-color": "secondary",
                  dark: "",
                  "slider-color": "accent"
                },
                on: { change: _vm.syncUrl },
                model: {
                  value: _vm.activeTab,
                  callback: function($$v) {
                    _vm.activeTab = $$v
                  },
                  expression: "activeTab"
                }
              },
              [
                _c("v-tab", { key: "details" }, [
                  _vm._v(_vm._s(_vm.$t("labels.details")))
                ]),
                _c("v-tab", { key: "attachments" }, [
                  _vm._v(_vm._s(_vm.$t("labels.attachments")))
                ]),
                _c("v-tab", { key: "links" }, [
                  _vm._v(_vm._s(_vm.$t("labels.links")))
                ])
              ],
              1
            ),
            _c(
              "v-tabs-items",
              {
                model: {
                  value: _vm.activeTab,
                  callback: function($$v) {
                    _vm.activeTab = $$v
                  },
                  expression: "activeTab"
                }
              },
              [
                _c(
                  "v-tab-item",
                  { key: "details" },
                  [
                    _c(
                      "v-card",
                      { attrs: { flat: "" } },
                      [
                        _c("ServiceForm", {
                          attrs: {
                            mode: _vm.Enums.FORM_MODE.SHOW,
                            selectedItem: this.selectedService
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-tab-item",
                  { key: "attachments" },
                  [
                    _c(
                      "v-card",
                      { attrs: { flat: "" } },
                      [
                        _c("AttachmentsList", {
                          attrs: {
                            withModelType: "services",
                            withModelId: this.selectedService.id,
                            listMode: _vm.Enums.LIST_MODE.TABBED
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-tab-item",
                  { key: "links" },
                  [
                    _c(
                      "v-card",
                      { attrs: { flat: "" } },
                      [
                        _c("LinksList", {
                          attrs: {
                            withModelType: "services",
                            withModelId: this.selectedService.id,
                            listMode: _vm.Enums.LIST_MODE.TABBED
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "content-class": "edit-form-dialog" },
          model: {
            value: _vm.formDialog,
            callback: function($$v) {
              _vm.formDialog = $$v
            },
            expression: "formDialog"
          }
        },
        [
          _vm.formDialog
            ? _c("ServiceForm", {
                attrs: {
                  mode: _vm.Enums.FORM_MODE.UPDATE,
                  selectedItem: this.clonedSelected()
                },
                on: {
                  formSucceed: _vm.handleFormSucceed,
                  formCancel: _vm.handleFormCancel,
                  formDelete: _vm.handleDelete
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }