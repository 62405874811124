import Vue from "vue";
import Vuex from "vuex";
import { InvoicePeriodService } from "@/services/invoicePeriod.service";
import { HelperService } from "@/services/helper.service";

Vue.use(Vuex);

const state = {
  invoicePeriods: [],
  selected: {},
  loading: false,
  totalCount: 0
};

const getters = {
  invoicePeriods: state => state.invoicePeriods,
  onLoading: state => state.loading,
  selectedInvoicePeriod: state => state.selected,
  total: state => state.totalCount
};

const mutations = {
  setSelected(state, sel) {
    state.selected = sel;
  },
  setList(state, sel) {
    state.invoicePeriods = sel;
  },
  setTotal(state, sel) {
    state.totalCount = Number(sel);
  },

  setLoading(state, sel) {
    state.loading = sel;
  }
};

const actions = {
  async ajaxInvoicePeriodUpdate({ commit }, invoice_period) {
    if (invoice_period.metadata) {
      let metadata = invoice_period.metadata;
      invoice_period.metadata = JSON.stringify(metadata);
    }
    return HelperService.genericCreateOrUpdate(
      commit,
      InvoicePeriodService.createOrUpdate,
      invoice_period
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
