var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "form-container title", attrs: { fluid: "" } },
    [
      _vm.mode == _vm.Enums.FORM_MODE.CREATE ||
      _vm.mode == _vm.Enums.FORM_MODE.UPDATE
        ? _c(
            "div",
            [
              _c("v-card-title", { staticClass: "form-title title" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("capitalize")(this.formTitle)) +
                    "\n    "
                )
              ]),
              (_vm.generateDocument || _vm.sendDocument) &&
              _vm.mode == _vm.Enums.FORM_MODE.UPDATE
                ? _c(
                    "div",
                    [
                      _c("BR"),
                      _vm.generateDocument
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "font-weight-regular text-h6 text-center"
                            },
                            [
                              _vm._v(
                                "\n        I documenti che sceglierai di generare sostituiranno quelli esistenti\n      "
                              )
                            ]
                          )
                        : _c(
                            "p",
                            {
                              staticClass:
                                "font-weight-regular text-h6 text-center"
                            },
                            [
                              _vm._v(
                                "\n        Seleziona i documenti da inviare alla scuola\n      "
                              )
                            ]
                          ),
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-col",
                            { staticClass: "form-group" },
                            [
                              _c("v-data-table", {
                                staticClass: "elevation-1",
                                attrs: {
                                  headers: _vm.headers,
                                  items: _vm.items,
                                  "item-value": "name",
                                  "show-select": ""
                                },
                                model: {
                                  value: _vm.selectedTemplates,
                                  callback: function($$v) {
                                    _vm.selectedTemplates = $$v
                                  },
                                  expression: "selectedTemplates"
                                }
                              })
                            ],
                            1
                          ),
                          _vm.generateDocument
                            ? _c(
                                "v-col",
                                { staticClass: "form-group" },
                                [
                                  _c("DatePicker", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate.immediate",
                                        value: "required",
                                        expression: "'required'",
                                        modifiers: { immediate: true }
                                      }
                                    ],
                                    attrs: {
                                      label: _vm.$t(
                                        "forms.agreements.custom_date"
                                      ),
                                      name: "custom_date"
                                    },
                                    on: { input: _vm.handleDateChange },
                                    model: {
                                      value: _vm.form.custom_date,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "custom_date", $$v)
                                      },
                                      expression: "form.custom_date"
                                    }
                                  }),
                                  _vm.veeErrors.has("custom_date")
                                    ? _c(
                                        "span",
                                        {
                                          class:
                                            _vm.Settings.style.class
                                              .formFieldRequiredCaption
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.veeErrors.first("custom_date")
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("FormButtons", {
                        attrs: { disabled: _vm.veeErrors.any() },
                        on: { onSave: _vm.onSubmit, onCancel: _vm.onCancel }
                      })
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "v-stepper",
                        {
                          attrs: { vertical: "" },
                          model: {
                            value: _vm.e6_step,
                            callback: function($$v) {
                              _vm.e6_step = $$v
                            },
                            expression: "e6_step"
                          }
                        },
                        [
                          _c(
                            "v-stepper-step",
                            { attrs: { complete: _vm.e6_step > 1, step: "1" } },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("forms.agreements.section1"))
                              )
                            ]
                          ),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "1" } },
                            [
                              _c(
                                "v-form",
                                {
                                  attrs: { "data-vv-scope": "section1" },
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return _vm.onSubmit($event)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "form-group required" },
                                    [
                                      _c("CustomerAutocomplete", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate.immediate",
                                            value: "required",
                                            expression: "'required'",
                                            modifiers: { immediate: true }
                                          }
                                        ],
                                        attrs: {
                                          mode: _vm.mode,
                                          itemText: "name",
                                          itemValue: "id",
                                          returnObject: true,
                                          label: _vm.$t(
                                            "forms.agreements.customer"
                                          ),
                                          disabled:
                                            _vm.mode ==
                                              _vm.Enums.FORM_MODE.UPDATE ||
                                            this.withModelId != null,
                                          fetchFunction:
                                            _vm.ajaxCustomerFetchAll,
                                          getter: _vm.customers,
                                          withModelId: _vm.withModelId,
                                          name: "customer"
                                        },
                                        on: { input: _vm.customerSelected },
                                        model: {
                                          value: _vm.form.customer,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "customer", $$v)
                                          },
                                          expression: "form.customer"
                                        }
                                      }),
                                      _vm.veeErrors.has("section1.customer")
                                        ? _c(
                                            "span",
                                            {
                                              class:
                                                _vm.Settings.style.class
                                                  .formFieldRequiredCaption
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.veeErrors.first(
                                                    "section1.customer"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "form-group required" },
                                    [
                                      _c("v-select", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate.immediate",
                                            value: "required",
                                            expression: "'required'",
                                            modifiers: { immediate: true }
                                          }
                                        ],
                                        attrs: {
                                          items: this.tenantServices,
                                          "item-text": "name",
                                          "item-value": "id",
                                          "return-object": "",
                                          label: _vm.$t(
                                            "forms.agreements.service"
                                          ),
                                          disabled:
                                            _vm._.isEmpty(_vm.form.customer) ||
                                            (_vm.renewalMode &&
                                              !_vm.advancedRenewalMode),
                                          multiple: "",
                                          name: "service"
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.selectInput()
                                          }
                                        },
                                        model: {
                                          value: _vm.form.services,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "services", $$v)
                                          },
                                          expression: "form.services"
                                        }
                                      }),
                                      _vm.veeErrors.has("section1.service")
                                        ? _c(
                                            "span",
                                            {
                                              class:
                                                _vm.Settings.style.class
                                                  .formFieldRequiredCaption
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.veeErrors.first(
                                                    "section1.service"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t("forms.agreements.cig"),
                                          name: "cig"
                                        },
                                        model: {
                                          value: _vm.form.cig,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "cig", $$v)
                                          },
                                          expression: "form.cig"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "forms.agreements.cost"
                                          ),
                                          name: "cost"
                                        },
                                        model: {
                                          value: _vm.form.outgoing,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "outgoing", $$v)
                                          },
                                          expression: "form.outgoing"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: this.workflow_contratto,
                                          "item-text": "name",
                                          "item-value": "id",
                                          label: _vm.$t(
                                            "forms.agreements.flow"
                                          ),
                                          name: "flow"
                                        },
                                        model: {
                                          value:
                                            _vm.form.metadata
                                              .workflow_contratto,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form.metadata,
                                              "workflow_contratto",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.metadata.workflow_contratto"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "form-group" },
                                            [
                                              _c("DatePicker", {
                                                directives: [
                                                  {
                                                    name: "validate",
                                                    rawName:
                                                      "v-validate.immediate",
                                                    value: "required",
                                                    expression: "'required'",
                                                    modifiers: {
                                                      immediate: true
                                                    }
                                                  }
                                                ],
                                                attrs: {
                                                  label: _vm.$t(
                                                    "forms.agreements.begin_date"
                                                  ),
                                                  name: "start_date"
                                                },
                                                on: {
                                                  input: _vm.handleDateChange
                                                },
                                                model: {
                                                  value: _vm.form.start_date,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "start_date",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.start_date"
                                                }
                                              }),
                                              _vm.veeErrors.has(
                                                "section1.start_date"
                                              )
                                                ? _c(
                                                    "span",
                                                    {
                                                      class:
                                                        _vm.Settings.style.class
                                                          .formFieldRequiredCaption
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.veeErrors.first(
                                                            "section1.start_date"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { staticClass: "form-group" },
                                            [
                                              _c("DatePicker", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "forms.agreements.end_date"
                                                  ),
                                                  name: "end_date"
                                                },
                                                model: {
                                                  value: _vm.form.end_date,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "end_date",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.end_date"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { staticClass: "form-group" },
                                            [
                                              _c("v-checkbox", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "forms.agreements.infinite_deadline"
                                                  ),
                                                  name: "infinite_deadline"
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.infinite_deadline,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "infinite_deadline",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.infinite_deadline"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          label: _vm.$t(
                                            "forms.agreements.details"
                                          ),
                                          name: "details",
                                          "auto-grow": "",
                                          rows: "1"
                                        },
                                        model: {
                                          value: _vm.form.note,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "note", $$v)
                                          },
                                          expression: "form.note"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.renewalMode,
                                          expression: "!renewalMode"
                                        }
                                      ]
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("DatePicker", {
                                            attrs: {
                                              label: _vm.$t(
                                                "forms.agreements.renewal_proposal_sent_at"
                                              ),
                                              name: "renewal_proposal_date"
                                            },
                                            model: {
                                              value:
                                                _vm.form.metadata
                                                  .renewal_proposal_sent_at,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form.metadata,
                                                  "renewal_proposal_sent_at",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.metadata.renewal_proposal_sent_at"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "forms.agreements.renewal_proposal_id"
                                              ),
                                              name: "renewal_proposal_id"
                                            },
                                            model: {
                                              value:
                                                _vm.form.metadata
                                                  .renewal_proposal_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form.metadata,
                                                  "renewal_proposal_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.metadata.renewal_proposal_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-container",
                                    [
                                      _c("DisplaySeparator", {
                                        attrs: {
                                          as: _vm.Enums.DISPLAY_AS.SEPARATOR,
                                          label: _vm.$t("labels.metadataAT")
                                        }
                                      }),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "form-group" },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "forms.agreements.metadata_agreements.username"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.metadata_agreements
                                                      .username_at,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form
                                                        .metadata_agreements,
                                                      "username_at",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.metadata_agreements.username_at"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { staticClass: "form-group" },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "forms.agreements.metadata_agreements.password"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.metadata_agreements
                                                      .password_at,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form
                                                        .metadata_agreements,
                                                      "password_at",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.metadata_agreements.password_at"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { staticClass: "form-group" },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "forms.agreements.metadata_agreements.pin"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.metadata_agreements
                                                      .pin_at,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form
                                                        .metadata_agreements,
                                                      "pin_at",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.metadata_agreements.pin_at"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("FormButtons", {
                                    attrs: {
                                      multiForm: true,
                                      multiLayout: 0,
                                      disabled: _vm.veeErrors.any("section1")
                                    },
                                    on: {
                                      onNext: _vm.onNext,
                                      onCancel: _vm.onCancel
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-step",
                            { attrs: { complete: _vm.e6_step > 2, step: "2" } },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("forms.agreements.section2"))
                              )
                            ]
                          ),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "2" } },
                            [
                              _c(
                                "v-form",
                                {
                                  attrs: { "data-vv-scope": "section2" },
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return _vm.onSubmit($event)
                                    }
                                  }
                                },
                                [
                                  _vm._l(_vm.form.agreementServices, function(
                                    agreementService,
                                    i
                                  ) {
                                    return [
                                      _c(
                                        "v-container",
                                        { key: i },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getServiceName(
                                                        agreementService
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "form-group",
                                                  attrs: { cols: "3" }
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    directives: [
                                                      {
                                                        name: "validate",
                                                        rawName:
                                                          "v-validate.immediate",
                                                        value: "required",
                                                        expression:
                                                          "'required'",
                                                        modifiers: {
                                                          immediate: true
                                                        }
                                                      }
                                                    ],
                                                    attrs: {
                                                      label: !_vm.form
                                                        .infinite_deadline
                                                        ? "" +
                                                          _vm.$t(
                                                            "forms.agreements.amount"
                                                          )
                                                        : "" +
                                                          _vm.$t(
                                                            "forms.agreements.annual_amount"
                                                          ),
                                                      placeholder: "0.00",
                                                      name: "amount-" + [i]
                                                    },
                                                    on: {
                                                      input:
                                                        _vm.handleAmountChange
                                                    },
                                                    model: {
                                                      value:
                                                        agreementService
                                                          .metadata.amount,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          agreementService.metadata,
                                                          "amount",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "agreementService.metadata.amount"
                                                    }
                                                  }),
                                                  _vm.veeErrors.has(
                                                    "section2.amount-" + [i]
                                                  )
                                                    ? _c(
                                                        "span",
                                                        {
                                                          class:
                                                            _vm.Settings.style
                                                              .class
                                                              .formFieldRequiredCaption
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.veeErrors.first(
                                                                "section2.amount-" +
                                                                  [i]
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _vm.getServiceName(
                                                agreementService
                                              ) === "GDPR"
                                                ? [
                                                    _vm._l(
                                                      _vm.getMetadata(
                                                        agreementService
                                                      ),
                                                      function(value, j) {
                                                        return [
                                                          value !== "amount" &&
                                                          value != "start_date"
                                                            ? _c(
                                                                "v-col",
                                                                {
                                                                  key: j,
                                                                  attrs: {
                                                                    cols: "3"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-select",
                                                                    {
                                                                      attrs: {
                                                                        label: _vm.$t(
                                                                          "forms.agreements." +
                                                                            value
                                                                        ),
                                                                        items:
                                                                          _vm
                                                                            .options[
                                                                            value
                                                                          ],
                                                                        "item-text":
                                                                          "name",
                                                                        "item-value":
                                                                          "id"
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          agreementService
                                                                            .metadata[
                                                                            value
                                                                          ],
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            agreementService.metadata,
                                                                            value,
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "agreementService.metadata[value]"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      }
                                                    )
                                                  ]
                                                : _vm._e(),
                                              _vm.getServiceName(
                                                agreementService
                                              ) === "ASSISTENZA_TECNICA"
                                                ? [
                                                    _vm._l(
                                                      _vm.getMetadata(
                                                        agreementService
                                                      ),
                                                      function(value, j) {
                                                        return [
                                                          value !== "amount" &&
                                                          value != "start_date"
                                                            ? _c(
                                                                "v-col",
                                                                {
                                                                  key: j,
                                                                  attrs: {
                                                                    cols: "3"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        label: _vm.$t(
                                                                          "forms.agreements." +
                                                                            value
                                                                        )
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          agreementService
                                                                            .metadata[
                                                                            value
                                                                          ],
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            agreementService.metadata,
                                                                            value,
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "agreementService.metadata[value]"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      }
                                                    )
                                                  ]
                                                : _vm._e()
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }),
                                  _c("FormButtons", {
                                    attrs: {
                                      multiForm: true,
                                      multiLayout: 1,
                                      disabled: _vm.veeErrors.any("section2")
                                    },
                                    on: {
                                      onBack: _vm.onBack,
                                      onNext: _vm.onNext,
                                      onCancel: _vm.onCancel
                                    }
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-step",
                            { attrs: { complete: _vm.e6_step > 3, step: "3" } },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("forms.agreements.section3"))
                              )
                            ]
                          ),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "3" } },
                            [
                              _c(
                                "v-form",
                                {
                                  attrs: { "data-vv-scope": "section3" },
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return _vm.onSubmit($event)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "form-group",
                                              attrs: { cols: "4" }
                                            },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  label:
                                                    "" +
                                                    _vm.$t(
                                                      "forms.agreements.external"
                                                    ),
                                                  name: "external",
                                                  items: this.external,
                                                  "item-text": "text",
                                                  "item-value": "value",
                                                  disabled:
                                                    _vm.mode !=
                                                      _vm.Enums.FORM_MODE
                                                        .CREATE &&
                                                    !this.$props.renewalMode
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.invoice_data
                                                      .external,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form.invoice_data,
                                                      "external",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.invoice_data.external"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "form-group",
                                              attrs: { cols: "4" }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "forms.agreements.billing_frequency"
                                                  ),
                                                  name: "billing_period",
                                                  disabled:
                                                    (_vm.mode !=
                                                      _vm.Enums.FORM_MODE
                                                        .CREATE &&
                                                      !this.$props
                                                        .renewalMode) ||
                                                    _vm.form.invoice_data
                                                      .external
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.invoice_data
                                                      .billing_period,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form.invoice_data,
                                                      "billing_period",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.invoice_data.billing_period"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "form-group",
                                              attrs: { cols: "4" }
                                            },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  label:
                                                    _vm.$t(
                                                      "forms.agreements.invoicing"
                                                    ) +
                                                    " " +
                                                    _vm.$t(
                                                      "forms.agreements.early"
                                                    ),
                                                  name: "early",
                                                  items: this.early,
                                                  "item-text": "text",
                                                  "item-value": "value",
                                                  disabled:
                                                    (_vm.mode !=
                                                      _vm.Enums.FORM_MODE
                                                        .CREATE &&
                                                      !this.$props
                                                        .renewalMode) ||
                                                    _vm.form.invoice_data
                                                      .external
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.invoice_data.early,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form.invoice_data,
                                                      "early",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.invoice_data.early"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("FormButtons", {
                                    attrs: {
                                      multiForm: true,
                                      multiLayout: 1,
                                      disabled: _vm.veeErrors.any("section3")
                                    },
                                    on: {
                                      onBack: _vm.onBack,
                                      onNext: _vm.onNext,
                                      onCancel: _vm.onCancel
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-step",
                            { attrs: { complete: _vm.e6_step > 4, step: "4" } },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("forms.agreements.section4"))
                              )
                            ]
                          ),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "4" } },
                            [
                              _c(
                                "v-container",
                                [
                                  _c("v-row", [
                                    _c("h5", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t("forms.agreements.review")
                                          ) +
                                          "\n              " +
                                          _vm._s(
                                            _vm.$t("forms.agreements.section1")
                                          ) +
                                          "\n            "
                                      )
                                    ])
                                  ]),
                                  _c("br"),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "summary-font-bold",
                                          attrs: { cols: "2" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("forms.agreements.tenant")
                                            )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "summary-font",
                                          attrs: { cols: "2" }
                                        },
                                        [_vm._v(_vm._s(this.tenantName))]
                                      ),
                                      _c("v-col", { attrs: { cols: "1" } }),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "summary-font-bold",
                                          attrs: { cols: "2" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "forms.agreements.customer"
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "summary-font",
                                          attrs: { cols: "4" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.form.customer
                                                ? this.form.customer.name
                                                : ""
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "summary-font-bold",
                                          attrs: { cols: "2" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "forms.agreements.start_date"
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "summary-font",
                                          attrs: { cols: "2" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("fullDate")(
                                                _vm.form.start_date
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _c("v-col", { attrs: { cols: "1" } }),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "summary-font-bold",
                                          attrs: { cols: "2" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "forms.agreements.end_date"
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "summary-font",
                                          attrs: { cols: "3" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("fullDate")(
                                                _vm.form.end_date
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "summary-font-bold",
                                          attrs: { cols: "2" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("forms.agreements.amount")
                                            )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "summary-font",
                                          attrs: { cols: "2" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.form.total_amount) + " €"
                                          )
                                        ]
                                      ),
                                      _c("v-col", { attrs: { cols: "1" } }),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "summary-font-bold",
                                          attrs: { cols: "2" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "forms.agreements.outgoing"
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "summary-font",
                                          attrs: { cols: "4" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.form.outgoing) + " €"
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "summary-font-bold",
                                          attrs: { cols: "2" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("forms.agreements.cig")
                                            )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "summary-font",
                                          attrs: { cols: "2" }
                                        },
                                        [_vm._v(_vm._s(_vm.form.cig))]
                                      ),
                                      _c("v-col", { attrs: { cols: "1" } }),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "summary-font-bold",
                                          attrs: { cols: "2" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "forms.agreements.workflow_contratto"
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "summary-font",
                                          attrs: { cols: "4" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getEnumTranslationFor(
                                                "agreements",
                                                _vm.form.metadata
                                                  .workflow_contratto
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "summary-font-bold",
                                          attrs: { cols: "2" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "forms.agreements.renewal_proposal_id"
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "summary-font",
                                          attrs: { cols: "2" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.form.metadata
                                                .renewal_proposal_id
                                            )
                                          )
                                        ]
                                      ),
                                      _c("v-col", { attrs: { cols: "1" } }),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "summary-font-bold",
                                          attrs: { cols: "2" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "forms.agreements.renewal_proposal_sent_at"
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "summary-font",
                                          attrs: { cols: "2" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("fullDate")(
                                                _vm.form.metadata
                                                  .renewal_proposal_sent_at
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "summary-font-bold",
                                          attrs: { cols: "2" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("forms.agreements.details")
                                            )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "summary-font",
                                          attrs: { cols: "10" }
                                        },
                                        [_vm._v(_vm._s(_vm.form.note))]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("br"),
                                  _c("v-divider"),
                                  _c("br"),
                                  _c("v-row", [
                                    _c("h5", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t("forms.agreements.review")
                                          ) +
                                          "\n              " +
                                          _vm._s(
                                            _vm.$t("forms.agreements.section2")
                                          ) +
                                          "\n            "
                                      )
                                    ])
                                  ]),
                                  _c("br"),
                                  _vm._l(_vm.form.agreementServices, function(
                                    agreementService,
                                    i
                                  ) {
                                    return [
                                      _c(
                                        "v-row",
                                        {
                                          key: i,
                                          staticStyle: {
                                            "margin-bottom": "20px"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "summary-font-bold",
                                              attrs: { cols: "4" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getServiceName(
                                                    agreementService
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._l(
                                            _vm.getMetadata(agreementService),
                                            function(value, j) {
                                              return [
                                                value != "start_date"
                                                  ? [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          key: j + "a",
                                                          staticClass:
                                                            "summary-font-bold",
                                                          attrs: { cols: "4" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "forms.agreements." +
                                                                  value
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          key: j + "b",
                                                          staticClass:
                                                            "summary-font",
                                                          attrs: { cols: "4" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.getTranslation(
                                                                value,
                                                                agreementService
                                                                  .metadata[
                                                                  value
                                                                ]
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c("v-col", {
                                                        key: j + "d",
                                                        attrs: { cols: "4" }
                                                      })
                                                    ]
                                                  : _vm._e()
                                              ]
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    ]
                                  }),
                                  _c("br"),
                                  _c("v-divider"),
                                  _c("br"),
                                  _c("v-row", [
                                    _c("h5", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t("forms.agreements.review")
                                          ) +
                                          "\n              " +
                                          _vm._s(
                                            _vm.$t("forms.agreements.section3")
                                          ) +
                                          "\n            "
                                      )
                                    ])
                                  ]),
                                  _c("br"),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "summary-font-bold",
                                          attrs: { cols: "3" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "forms.agreements.external"
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "summary-font",
                                          attrs: { cols: "2" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.form.invoice_data.external
                                                ? _vm.$t("labels.yes")
                                                : _vm.$t("labels.no")
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  !_vm.form.invoice_data.external
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "summary-font-bold",
                                              attrs: { cols: "3" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "forms.agreements.billing_frequency"
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "summary-font",
                                              attrs: { cols: "2" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.form.invoice_data
                                                    .billing_period
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.form.invoice_data.external
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "summary-font-bold",
                                              attrs: { cols: "3" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "forms.agreements.invoicing"
                                                  )
                                                ) +
                                                  "\n              " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "forms.agreements.early"
                                                    )
                                                  )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "summary-font",
                                              attrs: { cols: "4" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.form.invoice_data.early
                                                    ? _vm.$t("labels.yes")
                                                    : _vm.$t("labels.no")
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("br"),
                                  _c("v-divider")
                                ],
                                2
                              ),
                              _c(
                                "v-form",
                                {
                                  attrs: { "data-vv-scope": "section4" },
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return _vm.onSubmit($event)
                                    }
                                  }
                                },
                                [
                                  _c("FormButtons", {
                                    attrs: {
                                      multiForm: true,
                                      multiLayout: 2,
                                      createLoading: _vm.createLoading,
                                      disabled: _vm.veeErrors.any("section4")
                                    },
                                    on: {
                                      onBack: _vm.onBack,
                                      onSave: _vm.onSubmit,
                                      onCancel: _vm.onCancel
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        : _vm._e(),
      _vm.mode === _vm.Enums.FORM_MODE.SHOW
        ? _c(
            "div",
            { staticClass: "form-body-show" },
            [
              _c("DisplayKV", {
                attrs: {
                  label: _vm.$t("forms.agreements.customer"),
                  value: this.form.customer.name
                }
              }),
              _c("DisplayKV", {
                attrs: {
                  label: _vm.$t("forms.agreements.tenant"),
                  value: this.form.tenant.name
                }
              }),
              _c("DisplayKV", {
                attrs: {
                  label: _vm.$t("forms.agreements.flow"),
                  value: _vm.getEnumTranslationFor(
                    "agreements",
                    _vm.form.metadata.workflow_contratto
                  )
                }
              }),
              _c("DisplayKV", {
                attrs: {
                  label: _vm.$t("forms.agreements.service"),
                  value: this.form.service
                }
              }),
              _c("DisplayKV", {
                attrs: {
                  label: _vm.$t("forms.agreements.state"),
                  value: _vm.getEnumTranslationFor(
                    "agreements",
                    this.form.state
                  )
                }
              }),
              _c("DisplayKV", {
                attrs: {
                  label: _vm.$t("forms.agreements.cig"),
                  value: this.form.cig
                }
              }),
              _c("DisplayKV", {
                attrs: {
                  label: _vm.$t("forms.agreements.amount"),
                  value: this.form.total_amount
                }
              }),
              _c("DisplayKV", {
                attrs: {
                  label: _vm.$t("forms.agreements.cost"),
                  value: this.form.outgoing
                }
              }),
              _c("DisplayKV", {
                attrs: {
                  label: _vm.$t("forms.agreements.worked_hours"),
                  value: this.form.worked_hours
                }
              }),
              _c("DisplayKV", {
                attrs: {
                  label: _vm.$t("forms.agreements.details"),
                  value: this.form.note
                }
              }),
              _c("DisplayKV", {
                attrs: {
                  label: _vm.$t("forms.agreements.begin_date"),
                  value: _vm._f("fullDate")(this.form.start_date)
                }
              }),
              _c("DisplayKV", {
                attrs: {
                  label: _vm.$t("forms.agreements.end_date"),
                  value: _vm._f("fullDate")(this.form.end_date)
                }
              }),
              _c("DisplayKV", {
                attrs: {
                  label: _vm.$t("forms.agreements.last_mail_date"),
                  value: this.form.last_email_sent_date
                }
              }),
              _c("DisplayKV", {
                attrs: {
                  label: _vm.$t("forms.agreements.mail_sent"),
                  value: this.form.email_sent_count
                }
              }),
              _c("DisplaySeparator", {
                attrs: {
                  as: _vm.Enums.DISPLAY_AS.SEPARATOR,
                  label: _vm.$t("labels.metadataAT")
                }
              }),
              _c("DisplayKV", {
                attrs: {
                  as: _vm.Enums.DISPLAY_AS.TEXT,
                  label: _vm.$t(
                    "forms.agreements.metadata_agreements.username"
                  ),
                  value: this.form.metadata_agreements.username_at
                }
              }),
              _c("DisplayKV", {
                attrs: {
                  as: _vm.Enums.DISPLAY_AS.TEXT,
                  label: _vm.$t(
                    "forms.agreements.metadata_agreements.password"
                  ),
                  value: this.form.metadata_agreements.password_at
                }
              }),
              _c("DisplayKV", {
                attrs: {
                  as: _vm.Enums.DISPLAY_AS.TEXT,
                  label: _vm.$t("forms.agreements.metadata_agreements.pin"),
                  value: this.form.metadata_agreements.pin_at
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.mode === _vm.Enums.FORM_MODE.SHOW_SUMMARY
        ? _c(
            "div",
            { staticClass: "form-body-show" },
            [
              _c("DisplayKV", {
                attrs: {
                  label: _vm.$t("forms.agreements.customer"),
                  value: this.form.customer.name
                }
              }),
              _c("DisplayKV", {
                attrs: {
                  label: _vm.$t("forms.agreements.tenant"),
                  value: this.form.tenant.name
                }
              }),
              _c("DisplayKV", {
                attrs: {
                  label: _vm.$t("forms.agreements.flow"),
                  value: _vm.getEnumTranslationFor(
                    "agreements",
                    _vm.form.metadata.workflow_contratto
                  )
                }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }