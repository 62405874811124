/* eslint-disable no-unused-vars */
import _ from "lodash";
import voca from "voca";
import Enums from "@/mixins/enums";

export default {
  mixins: [Enums],
  methods: {
    toast(context, mode, text) {
      const messageObj = {
        context: context,
        mode: mode,
        text: text,
        snackbar: true
      };
      this.$store.dispatch("snackbar/showMessage", messageObj);
    },
    getProfileFullName() {
      const loggedUser = this.$store.getters["login/loggedUser"];
      if (loggedUser.info) {
        let fullName = loggedUser.info.first_name;
        fullName = loggedUser.info.last_name ? fullName + " " + loggedUser.info.last_name : fullName;
        return fullName;
      }
      return "";
    },
    getProfileSignature() {
      const loggedUser = this.$store.getters["login/loggedUser"];
      if (loggedUser.info) {
        const res =
          _.get(loggedUser.info, "first_name", "-").charAt(0) +
          _.get(loggedUser.info, "last_name", "-").charAt(0);
        return voca.upperCase(res);
      }
      return "";
    },
    getProfileRole() {
      const loggedUser = this.$store.getters["login/loggedUser"];
      if (loggedUser.info && loggedUser.info.role) {
        return voca.upperCase(loggedUser.info.role.name);
      }
      return "";
    },
    getTenantAvatarChars(tenant) {
      if (tenant.name == null || tenant.name == "") return "";
      return tenant.machine_name;
    },
    getTenantAvatarColor(tenant) {
      if (tenant.name == null || tenant.name == "") return "";
      if (tenant.machine_name == "NS") {
        return "indigo";
      } else if (tenant.machine_name == "SF") {
        return "green";
      } else {
        return null;
      }
    },
    getEnumTranslationFor(cat, string) {
      if (string) {
        const str = "enums." + cat + "." + voca.upperCase(string);
        const res = this.$t(str);
        if (res) return res;
      }
      return string;
    },
    arrayTranslation(cat, array, attribute = "value", oa = "text") {
      for (var i = 0; i < array.length; i++) {
        const str = "enums." + cat + "." + voca.upperCase(array[i][attribute]);
        array[i][oa] = this.$t(str) || "";
      }
    },
    getLangStrings(array, add_all = true, all_flag = true) {
      if (add_all)
        array = [
          { value: "ALL", checked: false, enabled: false, all: all_flag }
        ].concat(array);
      array.forEach(value => {
        value.name = this.$t("filters." + value.value);
      });
      return array;
    },
    computeFilter(array, matchAttribute = "value") {
      var filter = "";
      array.forEach(function(item) {
        if (item.checked == true) {
          if (typeof item.all === "undefined" || !item.all) {
            filter = item[matchAttribute];
          } else {
            filter = "";
          }
        }
      });
      return filter;
    },
    computeFilterMultiple(array, matchAttribute = "value") {
      var filter = [];
      array.forEach(function(item) {
        if (item.checked == true) {
          filter.push(item[matchAttribute]);
        }
      });
      return filter;
    },
    resetFilter(array) {
      let new_array = _.cloneDeep(array);
      new_array.forEach(function(element) {
        element.checked = false;
        element.enabled = false;
      });
      return new_array;
    },
    computeClear(array) {
      let clear = true;
      array.forEach(val => {
        if (val != null && val != "") clear = false;
      });
      return clear;
    },
    mergeWithSelected(new_array, all) {
      all.forEach(function(a) {
        if (a.checked) {
          let found = null;
          new_array.forEach(function(n) {
            if (n.value == a.value) found = n;
          });
          if (found != null) found.checked = true;
          else
            new_array.push({
              value: a.value,
              name: a.name,
              checked: a.checked,
              enabled: a.enabled
            });
        }
      });
      return new_array;
    },
    fixName(name) {
      if (name != "") return ' "' + name + '"';
      return "";
    },
    async createOrUpdateHelper(
      mode,
      resName,
      ajaxCreateOrUpdateFunction,
      payload,
      createdName,
      updatedName,
      extra = null
    ) {
      let pcopy = _.cloneDeep(payload);
      try {
        if (mode == Enums.FORM_MODE.CREATE) {
          const res = await ajaxCreateOrUpdateFunction(payload);
          if (res && (res.error || res.errors)) {
            let error = ""
            if (res.error) error = res.error;
            if (res.errors && res.errors.length > 0 && res.errors[0].code && res.errors[0].title) error = res.errors[0].title
            this.toast(Enums.TOAST_TYPE.ERROR, "", error);
            return false;
          }
          if (extra) {
            let extra_res = await extra(res);
            if (extra_res) {
              this.toast(Enums.TOAST_TYPE.ERROR, "", extra_res);
              return false;
            }
          }
          this.toast(
            Enums.TOAST_TYPE.SUCCESS,
            "",
            this.$t("toasts.created") +
              " " +
              resName +
              this.fixName(createdName({ v: res ? res.data : {}, p: pcopy }))
          );
          return true;
        } else {
          const res = await ajaxCreateOrUpdateFunction(payload);
          if (res && res.error) {
            this.toast(Enums.TOAST_TYPE.ERROR, "", this.$t('resource_types.customer') + " " + this.$t('toasts.error_response.'+res.error));
            return false;
          }
          if (extra) {
            let extra_res = await extra(res);
            if (extra_res) {
              this.toast(Enums.TOAST_TYPE.ERROR, "", extra_res);
              return false;
            }
          }
          this.toast(
            Enums.TOAST_TYPE.SUCCESS,
            "",
            this.$t("toasts.updated") +
              " " +
              resName +
              this.fixName(updatedName({ v: res ? res.data : {}, p: pcopy }))
          );
          return true;
        }
      } catch (error) {
        console.log(error);
        this.toast(Enums.TOAST_TYPE.ERROR, "", error);
        return false;
      }
    },
    async deleteHelper(resName, ajaxDeleteFunction, payload, deletedName) {
      let pcopy = _.cloneDeep(payload);
      try {
        const res = await ajaxDeleteFunction(payload);
        if (res && res.error) {
          this.toast(Enums.TOAST_TYPE.ERROR, "", res.error);
          return false;
        }
        this.toast(
          Enums.TOAST_TYPE.SUCCESS,
          "",
          this.$t("toasts.deleted") +
            " " +
            resName +
            this.fixName(deletedName({ v: res ? res.data : {}, p: pcopy }))
        );
        return true;
      } catch (error) {
        console.log(error);
        this.toast(Enums.TOAST_TYPE.ERROR, "", error);
        return false;
      }
    }
  },
  computed: {
    _() {
      return _;
    },
    Settings() {
      return this.$settings;
    }
  }
};
