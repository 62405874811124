var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("ToolbarDetail", {
        attrs: { title: _vm._f("capitalize")(_vm.detailTitle) },
        on: {
          onBack: function($event) {
            return _vm.setAgreementFlag(true)
          }
        }
      }),
      _c("br"),
      _c("InvoicePeriodsGrid")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }