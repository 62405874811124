<template>
  <v-container fluid class="grey lighten-4 title">
    <div
      v-if="mode == Enums.FORM_MODE.CREATE || mode == Enums.FORM_MODE.UPDATE"
    >
      <v-card-title class="form-title title">
        {{ formTitle | capitalize }}
      </v-card-title>
      <v-form class="form-body" @submit.prevent="onSubmit">
        <!-- SUBJECT -->
        <div class="form-group required">
          <v-text-field
            :label="$t('forms.links.url')"
            v-model="form.url"
            name="url"
            v-validate.immediate="'required'"
          ></v-text-field>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('url')"
            >{{ veeErrors.first("url") }}</span
          >
        </div>
        <div class="form-group">
          <v-text-field
            :label="$t('forms.links.description')"
            v-model="form.description"
            name="description"
          ></v-text-field>
        </div>        
        <div class="form-group" v-if="mode == Enums.FORM_MODE.CREATE">
          <v-select
            v-model="form.tag_ids"
            :items="this.tags"
            item-text="name"
            item-value="id"
            :label="$t('misc.select_tag')"
            single-line
            clearable
            name="tag"
            :disabled="disabledTag"
          ></v-select>
        </div>

        <!-- BUTTONS -->
        <FormButtons
          @onSave="onSubmit"
          @onCancel="onCancel"
          :disabled="veeErrors.any()"
        />
      </v-form>
    </div>
    <div v-show="mode === Enums.FORM_MODE.SHOW">
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.links.id')"
        :value="this.form.id"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.links.description')"
        :value="form.description"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.links.url')"
        :value="this.form.url"
      />
    </div>
  </v-container>
</template>

<script>
import _ from "lodash";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { mapActions, mapGetters } from "vuex";
import DisplayKV from "@/components/Shared/DisplayKeyValue";
import FormButtons from "@/components/Shared/FormButtons";

export default {
  props: {
    mode: {
      default: Enums.FORM_MODE.CREATE,
      type: String
    },
    selectedItem: Object,
    withModelType: {
      type: String,
      required: true
    },
    withModelId: {
      type: String,
      required: true
    },
    withFolderId: {
      type: String
    },
    tagName: {
      type: String
    }
  },
  data() {
    return {
      progress: true,
      resourceType: this.$t("resource_types.link"),
      disabledTag: false,
      form: {
        id: "",
        url: "",
        description: "",
        folder_id: this.$props.withFolderId,
        tag_ids: "",
        model_type: this.$props.withModelType,
        model_id: this.$props.withModelId
      }
    };
  },
  components: { DisplayKV, FormButtons },
  mixins: [SharedMixin, Enums],
  /*watch: {
    async withModelType() {
      this.form.model_type = this.$props.withModelType;
    },
    async withModelId() {
      this.form.model_id = this.$props.withModelId;
    }
  },*/
  methods: {
    ...mapActions("link", ["ajaxLinkCreateOrUpdate", "ajaxLinkDelete"]),
    ...mapActions("tag", ["ajaxTagFetchAll"]),

    async onSubmit() {
      const validate = await this.$validator.validateAll();
      if (validate) {
        await this.submitToStore();
      } else {
        alert(this.$t("forms.common.errorValidateFields"));
      }
    },
    async onCancel() {
      this.$emit("formCancel");
    },
    async submitToStore() {
      const payload = _.clone(this.form);
      await this.createOrUpdateHelper(
        this.mode,
        this.resourceType,
        this.ajaxLinkCreateOrUpdate,
        payload,
        payload => payload.p.description,
        payload => payload.p.description
      );
      this.$emit("formSucceed");
    },
    setForm(row = null) {
      console.log("this.props tag_name ", this.$props.tagName)
      if (row && !_.isEmpty(row)) {
        this.form.id = row.id;
        this.form.url = row.url;
        this.form.description = row.description;
        this.form.folder_id = row.folder_id;
        //this.form.tag_ids = row.tag_ids;
      } else if (row == null) {
        this.form.id = "";
        this.form.url = "";
        this.form.description = "";
        this.form.folder_id = this.$props.withFolderId;
        this.form.tag_ids = "";
      }
    },
    initForm() {
      if (this.mode == Enums.FORM_MODE.CREATE) {
        this.setForm();
      } else {
        this.setForm(this.$props.selectedItem);
      }
    },
    async initComp() {
      await this.ajaxTagFetchAll();
      console.log("TAG ", this.tags)
      if (this.$props.tagName && this.tags.length > 0) {
        const filtered = this.tags.filter(
          el => el.name == this.$props.tagName
        )
        console.log("FILTERED ", filtered)
        if (filtered && filtered.length == 1) {
          this.form.tag_ids = filtered[0].id;
          this.disabledTag = true;
        }
      }
        console.log("this.form ", this.form)

    }
  },
  computed: {
    ...mapGetters("tag", ["tags"]),

    Enums() {
      return Enums;
    },
    formTitle() {
      var title = "";
      switch (this.mode) {
        case Enums.FORM_MODE.CREATE:
          title = this.$t("labels.createResourceType", {
            resourceType: this.resourceType || "-"
          });
          break;
        case Enums.FORM_MODE.UPDATE:
          title = this.$t("labels.updateResourceTypeName", {
            resourceType: this.resourceType || "-",
            resourceName: this.form.description || "-"
          });
          break;

        default:
          break;
      }
      return title;
    }
  },
  created() {
    this.initForm();
    this.initComp();
  }
};
</script>

<style scoped>
.has-error {
  color: red;
}
.required label:before {
  color: #e32;
  content: "* ";
  display: inline;
}
.summary-font {
  font-size: small;
  line-height: 24px;
}
.summary-font-bold {
  font-size: small;
  font-weight: bold;
  line-height: 24px;
}
</style>
