var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c("font", { attrs: { color: "white", size: "5" } }, [
            _vm._v(_vm._s(_vm.$t("greeting")))
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "text-right px-3", attrs: { cols: "6" } },
            [
              _c("img", {
                attrs: { src: require("../assets/ns-logo.png"), width: "70" }
              })
            ]
          ),
          _c("v-col", { staticClass: "text-left px-3", attrs: { cols: "6" } }, [
            _c("img", {
              attrs: { src: require("../assets/sf-logo.png"), width: "40" }
            })
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-12", attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("v-form", [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("v-text-field", {
                      staticClass: "login-email",
                      attrs: {
                        label: _vm.$t("forms.login.username"),
                        required: "",
                        dark: ""
                      },
                      model: {
                        value: _vm.username,
                        callback: function($$v) {
                          _vm.username = $$v
                        },
                        expression: "username"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("v-text-field", {
                      staticClass: "login-password",
                      attrs: {
                        dark: "",
                        label: _vm.$t("forms.login.password"),
                        "append-icon": _vm.showPassword
                          ? "visibility"
                          : "visibility_off",
                        type: _vm.showPassword ? "password" : "text",
                        required: "",
                        "el-input": ""
                      },
                      on: {
                        "click:append": function($event) {
                          _vm.showPassword = !_vm.showPassword
                        }
                      },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.submit($event)
                        }
                      },
                      model: {
                        value: _vm.password,
                        callback: function($$v) {
                          _vm.password = $$v
                        },
                        expression: "password"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                large: "",
                loading: _vm.inLoading,
                disabled: _vm.inLoading
              },
              on: { click: _vm.submit }
            },
            [
              _c("font", { attrs: { color: "secondary" } }, [
                _vm._v(_vm._s(_vm.$t("buttons.login")))
              ])
            ],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { text: "", disabled: !_vm.username },
              on: { click: _vm.forgot_password }
            },
            [
              _c("font", { attrs: { color: "secondary" } }, [
                _vm._v(_vm._s(_vm.$t("buttons.forgot_password")))
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }