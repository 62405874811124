/* eslint-disable no-unused-vars */
import _ from "lodash";
import Enums from "@/mixins/enums";

export default {
  data() {
    return {
      formDialog: false,
      secondFormDialog: false,
      cloneDialog: false,
      formMode: "",
      secondFormMode: "",
      confirmDialog: false,
      formItem: {},
      secodFormItem: {}
    };
  },
  methods: {
    openFormDialog(mode, formItem = null) {
      this.formMode = mode;
      this.formDialog = true;
      if (formItem) this.formItem = formItem;
    },
    openSecondFormDialog(mode, formItem = null) {
      this.secondFormMode = mode;
      this.secondFormDialog = true;
      if (formItem) this.secodFormItem = formItem;
    },
    closeFormDialog() {
      //this.formMode = null;
      this.formDialog = false;
      this.formItem = null;
    },
    closeSecondFormDialog() {
      this.secondFormDialog = false;
      this.secondFormItem = null;
    },
    openCloneDialog(formItem) {
      this.formItem = formItem;
      this.cloneDialog = true;
    },
    closeCloneDialog() {
      this.cloneDialog = false;
      this.formItem = null;
    },
    openConfirmDialog() {
      this.confirmDialog = true;
    },
    closeConfirmDialog() {
      this.confirmDialog = false;
    }
  },

  mixins: [Enums],
  computed: {
    Enums() {
      return Enums;
    }
  }
};
