var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("EmailFilter", {
        ref: "drawer",
        on: {
          filtersSelected: _vm.handleFiltersSelected,
          drawerChanged: _vm.handleDrawer
        }
      }),
      _c(
        "div",
        { class: { "center-panel": _vm.drawer_flag } },
        [
          this.listMode === _vm.Enums.LIST_MODE.FULL
            ? _c(
                "v-card",
                [
                  _c("ToolbarList", {
                    attrs: {
                      title: _vm.$t("labels.email_message"),
                      subtitle: _vm.$tc("labels.found_elements", this.total),
                      withAdd: false,
                      withSearch: true,
                      withFilter: true,
                      allClear: _vm.allClear
                    },
                    on: {
                      onSearch: _vm.searchFunc,
                      onFilter: function($event) {
                        return _vm.$refs.drawer.open()
                      }
                    }
                  }),
                  _c("BaseGrid", {
                    attrs: {
                      tableName: "email-messages",
                      headers: this.tableData.headers,
                      items: this.tableData.items,
                      totalLength: this.total,
                      pagination: this.tableOptions,
                      loading: this.onLoading,
                      hideActions: true
                    },
                    on: { onPaginationChanged: _vm.handlePaginationChanged }
                  })
                ],
                1
              )
            : _vm._e(),
          this.listMode === _vm.Enums.LIST_MODE.TABBED
            ? _c(
                "v-container",
                [
                  _c(
                    "v-card",
                    [
                      _c("ToolbarTab", {
                        attrs: {
                          subtitle: _vm.$tc(
                            "labels.found_elements",
                            this.total
                          ),
                          withAdd: false
                        }
                      }),
                      _c("BaseGrid", {
                        attrs: {
                          tableName: "email-messages",
                          headers: this.tableData.headers,
                          items: this.tableData.items,
                          totalLength: this.total,
                          pagination: this.tableOptions,
                          loading: this.onLoading,
                          hideActions: true
                        },
                        on: { onPaginationChanged: _vm.handlePaginationChanged }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "content-class": "edit-form-dialog" },
              model: {
                value: _vm.formDialog,
                callback: function($$v) {
                  _vm.formDialog = $$v
                },
                expression: "formDialog"
              }
            },
            [
              _vm.formDialog
                ? _c("EmailModal", {
                    attrs: { selectedItem: _vm.formItem },
                    on: { formCancel: _vm.handleFormCancel }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }