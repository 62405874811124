<template>
  <v-container>
    <v-card v-if="this.listMode === Enums.LIST_MODE.FULL">
      <ToolbarList
        :title="$t('labels.links')"
        :subtitle="$tc('labels.found_elements', this.total)"
        :withAdd="$can('addButton', 'links')"
        @onAdd="openFormDialog(Enums.FORM_MODE.CREATE)"
      ></ToolbarList>

      <BaseGrid
        tableName="links"
        :headers="this.tableData.headers"
        :items="this.tableData.items"
        :totalLength="this.total"
        :pagination="this.tableOptions"
        :loading="this.onLoading"
        @onPaginationChanged="handlePaginationChanged"
        :withEdit="!this.readOnly"
        :withDelete="!this.readOnly"
        @onEdit="handleEdit"
        @onDelete="handleDelete"
      ></BaseGrid>
      
    </v-card>

    <v-card v-if="this.listMode === Enums.LIST_MODE.TABBED">
      <ToolbarTab
        :subtitle="$tc('labels.found_elements', this.total)"
        :withAdd="!this.readOnly"
        @onAdd="openFormDialog(Enums.FORM_MODE.CREATE)"
      ></ToolbarTab>

      <BaseGrid
        tableName="links"
        :headers="this.tableData.headers"
        :items="this.tableData.items"
        :totalLength="this.total"
        :pagination="this.tableOptions"
        :loading="this.onLoading"
        @onPaginationChanged="handlePaginationChanged"
        :withEdit="!this.readOnly"
        :withDelete="!this.readOnly"
        @onEdit="handleEdit"
        @onDelete="handleDelete"
      ></BaseGrid>
    </v-card>

    <v-dialog v-model="formDialog" persistent content-class="edit-form-dialog">
      <LinkForm
        v-if="formDialog"
        :mode="formMode"
        :selectedItem="formItem"
        :withModelType="this.withModelType"
        :withModelId="this.withModelId"
        :withFolderId="this.withFolderId"
        :tagName="this.tagName"
        @formSucceed="handleFormSucceed"
        @formCancel="handleFormCancel"
      ></LinkForm>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import LinkForm from "@/components/Link/LinkForm";
import ToolbarList from "@/components/Shared/ToolbarList";
import ToolbarTab from "@/components/Shared/ToolbarTab";
import FormsHelper from "@/mixins/formsHelper";
import Enums from "@/mixins/enums";
import BaseGrid from "@/components/Shared/BaseGrid";

const computed = {
  ...mapGetters("link", ["links", "selectedLink", "total", "onLoading"]),
  _() {
    return _;
  },
  Enums() {
    return Enums;
  },
  linksList() {
    return this.links;
  }
};

export default {
  components: {
    LinkForm,
    ToolbarList,
    ToolbarTab,
    BaseGrid
  },
  props: {
    withModelType: {
      required: true,
      type: String
    },
    withModelId: {
      required: true,
      type: String
    },
    listMode: {
      default: Enums.LIST_MODE.TABBED,
      type: String
    },
    readOnly: {
      default: false,
      type: Boolean
    },
    withFolderId: {
      type: String
    },
    tagName: {
      type: String
    }
  },
  data() {
    return {
      search: "",
      tableData: { headers: [], items: [] },
      tableOptions: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["description"]
      }
    };
  },
  mixins: [FormsHelper, Enums],
  /*watch: {
    async withModelType() {
      this.fetchData();
    },
    async withModelId() {
      this.fetchData();
    }
  },*/
  methods: {
    ...mapActions("link", [
      "ajaxLinkFetchAll",
      "ajaxLinkCreateOrUpdate",
      "ajaxLinkDelete"
    ]),

    async fetchData(needle = null) {
      const _search = needle ? needle : this.search || "";
      let _filter = null;
      if (this.$props.withModelType) {
        _filter = { model_type: this.$props.withModelType };
      }
      if (this.$props.withModelId) {
        _filter = { ..._filter, ...{ model_id: this.$props.withModelId } };
      }
      if (this.$props.withFolderId) {
        _filter = { ..._filter, ...{ folder_id: this.$props.withFolderId } };
      }

      const filterAndPagination = {
        search: _search,
        filter: _filter,
        pagination: this.tableOptions
      };

      await this.ajaxLinkFetchAll(filterAndPagination);
      this.initTable();
    },
    initTable() {
      this.tableData.headers = this.mapHeaders();
      this.tableData.items = this.mapItems();
    },
    mapHeaders() {
      const tableHeaders = [];
      tableHeaders.push({
        text: "Description",
        value: "description",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Url",
        value: "url",
        sortable: true
      });
      tableHeaders.push({
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center"
      });
      return tableHeaders;
    },
    mapItems() {
      const map = _.map(this.linksList, item => {
        item.fields = {
          description: {
            data: item.description,
            dataType: "text",
            css: "text-xs-left"
          },
          url: { data: item.url, dataType: "text", css: "text-xs-left" }
        };
        item.actions = {};
        return item;
      });
      return map;
    },
    handlePaginationChanged(paginationOpts) {
      this.tableOptions = paginationOpts;
      this.fetchData();
    },
    handleFormSucceed() {
      this.closeFormDialog();
      this.fetchData();
    },
    handleFormCancel() {
      this.closeFormDialog();
    },

    debounceSearch: _.debounce(async function(val) {
      this.search = val;
      await this.fetchData();
    }, process.env.VUE_APP_DEBOUNCE_TIME),

    async handleEdit(item) {
      this.openFormDialog(Enums.FORM_MODE.UPDATE, item);
    },
    async handleDelete(item) {
      this.$confirm({
        message:
          this.$t("dialogs.deleteConfirmMessage") +
          "\n\nLink | " +
          item.description,
        button: {
          no: this.$t("dialogs.no"),
          yes: this.$t("dialogs.yes")
        },
        callback: async confirm => {
          if (confirm) {
            await this.ajaxLinkDelete(item);
            this.fetchData();
          }
        }
      });
    }
  },

  async created() {
    this.formDialog = false;
    await this.fetchData();
  },
  computed
};
</script>
