import { render, staticRenderFns } from "./FormButtons.vue?vue&type=template&id=b768effa&"
import script from "./FormButtons.vue?vue&type=script&lang=js&"
export * from "./FormButtons.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VContainer,VIcon,VProgressCircular,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/deploy/netcrm/staging/source/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b768effa')) {
      api.createRecord('b768effa', component.options)
    } else {
      api.reload('b768effa', component.options)
    }
    module.hot.accept("./FormButtons.vue?vue&type=template&id=b768effa&", function () {
      api.rerender('b768effa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Shared/FormButtons.vue"
export default component.exports