<template color="primary">
  <v-row v-if="isLogged" align="center" justify="space-between">
    <v-col cols="4" align="left" class="py-0 mt-n1">
      <span class="caption">{{ version }}</span>
    </v-col>
    <v-col cols="4" align="center" class="py-0 mt-n1"></v-col>
    <v-col cols="4" align="right" class="py-0 mt-n1">
      <span class="caption">{{ now | fullDate }}</span>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import SharedMixin from "@/mixins/shared";

export default {
  data() {
    return {
      currentTime: ""
    };
  },
  mixins: [SharedMixin],
  computed: {
    ...mapGetters("login", ["isLogged", "applicationVersion"]),
    ...mapGetters("common", ["now"]),
    version() {
      var v = 0;
      if (this.applicationVersion) {
        v = this.applicationVersion.split("-")[0] || "-";
      }
      return this.$t("app_version", {
        version: v
      });
    }
  },
  created() {}
};
</script>
