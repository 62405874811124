<template>
  <v-row>
    <v-col cols="4" class="body-1 font-weight-light nowrap">{{
      this.label | upperCase
    }}</v-col>
    <v-col cols="5" v-if="this.as === Enums.DISPLAY_AS.TEXT" class="body-1">{{
      this.value || "-"
    }}</v-col>
    <v-col
      cols="5"
      v-if="this.as === Enums.DISPLAY_AS.LINK && !custom"
      class="body-1"
    >
      <a :href="this.value" target="_blank">{{ this.value }}</a>
    </v-col>
    <v-col
      cols="5"
      v-if="this.as === Enums.DISPLAY_AS.LINK && custom"
      class="body-1"
    >
      <a :href="resolve()">{{ this.value }}</a>
    </v-col>
    <v-col
      cols="5"
      v-if="this.as === Enums.DISPLAY_AS.BOOLEAN"
      class="body-1"
      >{{ this.value === true ? $t("labels.yes") : $t("labels.no") }}</v-col
    >
  </v-row>
</template>

<script>
import Enums from "@/mixins/enums";

export default {
  props: {
    label: {
      type: String
    },
    custom: {
      type: Boolean,
      default: false
    },
    value: {},
    as: {
      type: String,
      default: "text"
    },
    namedRoot: {},
    namedRootId: {}
  },
  mixins: [Enums],
  computed: {
    Enums() {
      return Enums;
    }
  },
  methods: {
    resolve() {
      let props = this.$router.resolve({
        name: this.namedRoot,
        params: { id: this.namedRootId }
      });
      return props.href;
    }
  }
};
</script>
<style lang="sass">
.row .col
  padding: 4px
</style>
