var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "form-container title", attrs: { fluid: "" } },
    [
      _c("v-card-title", { staticClass: "form-title title" }, [
        _vm._v(_vm._s(_vm._f("capitalize")(this.formTitle)))
      ]),
      _c(
        "v-form",
        {
          staticClass: "form-body",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-group required" },
            [
              _c("v-select", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate.immediate",
                    value: "required",
                    expression: "'required'",
                    modifiers: { immediate: true }
                  }
                ],
                attrs: {
                  items: this.tenants,
                  disabled: this.$props.withModelId != null,
                  "item-text": "name",
                  "item-value": "id",
                  label: _vm.$t("forms.customers.tenant"),
                  "single-line": "",
                  clearable: "",
                  name: "tenant"
                },
                model: {
                  value: _vm.form.tenant_id,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "tenant_id", $$v)
                  },
                  expression: "form.tenant_id"
                }
              }),
              _vm.veeErrors.has("tenant")
                ? _c(
                    "span",
                    {
                      class: _vm.Settings.style.class.formFieldRequiredCaption
                    },
                    [_vm._v(_vm._s(_vm.veeErrors.first("tenant")))]
                  )
                : _vm._e()
            ],
            1
          ),
          _c("FormButtons", {
            attrs: { disabled: _vm.veeErrors.any() },
            on: { onSave: _vm.onSubmit, onCancel: _vm.onCancel }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }