var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "form-container title", attrs: { fluid: "" } },
    [
      _vm.mode == _vm.Enums.FORM_MODE.CREATE ||
      _vm.mode == _vm.Enums.FORM_MODE.UPDATE
        ? _c(
            "div",
            [
              _c("v-card-title", { staticClass: "form-title title" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("capitalize")(this.formTitle)) +
                    "\n    "
                )
              ]),
              _c(
                "v-form",
                {
                  staticClass: "form-body",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.onSubmit($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _c("CustomerAutocomplete", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.immediate",
                            value: "required",
                            expression: "'required'",
                            modifiers: { immediate: true }
                          }
                        ],
                        attrs: {
                          mode: _vm.mode,
                          itemText: "name",
                          itemValue: "id",
                          returnObject: true,
                          label: _vm.$t("forms.tickets.customer"),
                          disabled: _vm.mode == _vm.Enums.FORM_MODE.UPDATE,
                          fetchFunction: _vm.ajaxCustomerFetchAll,
                          getter: _vm.customers,
                          name: "customer"
                        },
                        on: { input: _vm.handleCustomerChange },
                        model: {
                          value: _vm.form.customer,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "customer", $$v)
                          },
                          expression: "form.customer"
                        }
                      }),
                      _vm.veeErrors.has("customer")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [_vm._v(_vm._s(_vm.veeErrors.first("customer")))]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "form-group" },
                            [
                              _c("v-select", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate.immediate",
                                    value: "required",
                                    expression: "'required'",
                                    modifiers: { immediate: true }
                                  }
                                ],
                                attrs: {
                                  items: this.ticket_types_create,
                                  "item-text": "text",
                                  "item-value": "value",
                                  label: _vm.$t("forms.tickets.type"),
                                  disabled:
                                    _vm.mode == _vm.Enums.FORM_MODE.UPDATE ||
                                    _vm._.isEmpty(_vm.form.customer),
                                  name: "type"
                                },
                                on: { input: _vm.handleTypeChange },
                                model: {
                                  value: _vm.form.ticket_type,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "ticket_type", $$v)
                                  },
                                  expression: "form.ticket_type"
                                }
                              }),
                              _vm.veeErrors.has("type")
                                ? _c(
                                    "span",
                                    {
                                      class:
                                        _vm.Settings.style.class
                                          .formFieldRequiredCaption
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.veeErrors.first("type"))
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-dialog",
                            {
                              attrs: { width: "500" },
                              model: {
                                value: _vm.dialog,
                                callback: function($$v) {
                                  _vm.dialog = $$v
                                },
                                expression: "dialog"
                              }
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "text-h5 grey lighten-2" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            this.$t(
                                              "dialogs.info_ticket_subtype"
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _c("br"),
                                  _c("v-card-text", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          this.$t(
                                            "dialogs.info_time_budget_ticket"
                                          )
                                        ) +
                                        "\n              "
                                    )
                                  ]),
                                  _c("v-card-text", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          this.$t("dialogs.info_flat_ticket")
                                        ) +
                                        "\n              "
                                    )
                                  ]),
                                  _c("v-card-text", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          this.$t(
                                            "dialogs.info_warrenty_assistance_ticket"
                                          )
                                        ) +
                                        "\n              "
                                    )
                                  ]),
                                  _c("v-card-text", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          this.$t(
                                            "dialogs.info_without_contract_ticket"
                                          )
                                        ) +
                                        "\n              "
                                    )
                                  ]),
                                  _c("v-divider"),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "primary", text: "" },
                                          on: {
                                            click: function($event) {
                                              _vm.dialog = false
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                this.$t("dialogs.cancel")
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "form-group" },
                            [
                              _c("v-select", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate.immediate",
                                    value: "required",
                                    expression: "'required'",
                                    modifiers: { immediate: true }
                                  }
                                ],
                                ref: "theSelect",
                                attrs: {
                                  items: this.ticket_subtypes,
                                  "item-text": "text",
                                  "item-value": "value",
                                  label: _vm.$t("forms.tickets.subtype"),
                                  disabled:
                                    _vm.mode == _vm.Enums.FORM_MODE.UPDATE ||
                                    _vm.form.ticket_type == "",
                                  name: "subtype",
                                  "prepend-icon": "info"
                                },
                                on: {
                                  "click:prepend": _vm.showInfo,
                                  input: this.handleSubtypeChange
                                },
                                model: {
                                  value: _vm.form.ticket_subtype,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "ticket_subtype", $$v)
                                  },
                                  expression: "form.ticket_subtype"
                                }
                              }),
                              _vm.veeErrors.has("subtype")
                                ? _c(
                                    "span",
                                    {
                                      class:
                                        _vm.Settings.style.class
                                          .formFieldRequiredCaption
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.veeErrors.first("subtype"))
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "form-group" },
                            [
                              _c("v-select", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate.immediate",
                                    value: "required",
                                    expression: "'required'",
                                    modifiers: { immediate: true }
                                  }
                                ],
                                attrs: {
                                  items: this.agreements_services,
                                  "item-text": "service.id",
                                  "item-value": "id",
                                  label: _vm.$t("forms.tickets.service"),
                                  disabled:
                                    _vm.mode == _vm.Enums.FORM_MODE.UPDATE ||
                                    _vm.form.ticket_subtype == "" ||
                                    !_vm.agreementsEnabled,
                                  name: "agreement"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function(data) {
                                        return [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    data.item.service.name
                                                  )
                                                }
                                              }),
                                              _c("v-list-item-subtitle", [
                                                _vm._v(
                                                  "\n                    #" +
                                                    _vm._s(
                                                      data.item.agreement.id
                                                    ) +
                                                    "\n                  "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "selection",
                                      fn: function(data) {
                                        return [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(data.item.service.name) +
                                              " (#" +
                                              _vm._s(data.item.agreement.id) +
                                              ")\n              "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1150225655
                                ),
                                model: {
                                  value: _vm.form.model_id,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "model_id", $$v)
                                  },
                                  expression: "form.model_id"
                                }
                              }),
                              _vm.veeErrors.has("agreement")
                                ? _c(
                                    "span",
                                    {
                                      class:
                                        _vm.Settings.style.class
                                          .formFieldRequiredCaption
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.veeErrors.first("agreement"))
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.immediate",
                            value: "required",
                            expression: "'required'",
                            modifiers: { immediate: true }
                          }
                        ],
                        attrs: {
                          label: _vm.$t("forms.tickets.subject"),
                          name: "subject"
                        },
                        model: {
                          value: _vm.form.subject,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "subject", $$v)
                          },
                          expression: "form.subject"
                        }
                      }),
                      _vm.veeErrors.has("subject")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [_vm._v(_vm._s(_vm.veeErrors.first("subject")))]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("v-textarea", {
                        attrs: {
                          label: _vm.$t("forms.tickets.detail"),
                          name: "detail",
                          "auto-grow": "",
                          rows: "1"
                        },
                        model: {
                          value: _vm.form.detail,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "detail", $$v)
                          },
                          expression: "form.detail"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.users,
                          "item-text": _vm.getOperatorText,
                          "item-value": "id",
                          "return-object": "",
                          label: _vm.$t("forms.tickets.operators"),
                          multiple: "",
                          name: "operators"
                        },
                        model: {
                          value: _vm.form.users,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "users", $$v)
                          },
                          expression: "form.users"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "form-group" },
                            [
                              _c("DatePicker", {
                                attrs: {
                                  label: _vm.$t("forms.tickets.due_date"),
                                  name: "due_date"
                                },
                                model: {
                                  value: _vm.form.due_date,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "due_date", $$v)
                                  },
                                  expression: "form.due_date"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "form-group" },
                            [
                              _c("v-select", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate.immediate",
                                    value: "required",
                                    expression: "'required'",
                                    modifiers: { immediate: true }
                                  }
                                ],
                                attrs: {
                                  items: this.priorities,
                                  "item-text": "text",
                                  "item-value": "value",
                                  label: _vm.$t("forms.tickets.priority"),
                                  name: "priority"
                                },
                                model: {
                                  value: _vm.form.priority,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "priority", $$v)
                                  },
                                  expression: "form.priority"
                                }
                              }),
                              _vm.veeErrors.has("priority")
                                ? _c(
                                    "span",
                                    {
                                      class:
                                        _vm.Settings.style.class
                                          .formFieldRequiredCaption
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.veeErrors.first("priority"))
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "form-group" },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: this.categories,
                                  "item-text": "text",
                                  "item-value": "value",
                                  label: _vm.$t("forms.tickets.category"),
                                  name: "category"
                                },
                                model: {
                                  value: _vm.form.category,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "category", $$v)
                                  },
                                  expression: "form.category"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "form-group" },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: this.open_channel,
                                  "item-text": "text",
                                  "item-value": "value",
                                  label: _vm.$t("forms.tickets.opened_by"),
                                  name: "opened_by"
                                },
                                model: {
                                  value: _vm.form.opened_by,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "opened_by", $$v)
                                  },
                                  expression: "form.opened_by"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.mode == _vm.Enums.FORM_MODE.UPDATE
                    ? _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("v-textarea", {
                            attrs: {
                              label: _vm.$t("forms.tickets.additional_costs"),
                              name: "additional_costs",
                              "auto-grow": "",
                              rows: "1"
                            },
                            model: {
                              value: _vm.form.additional_costs,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "additional_costs", $$v)
                              },
                              expression: "form.additional_costs"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.mode == _vm.Enums.FORM_MODE.UPDATE
                    ? _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("v-textarea", {
                            attrs: {
                              label: _vm.$t("forms.tickets.final_note"),
                              name: "final_note",
                              "auto-grow": "",
                              rows: "1"
                            },
                            model: {
                              value: _vm.form.final_note,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "final_note", $$v)
                              },
                              expression: "form.final_note"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("FormButtons", {
                    attrs: { disabled: _vm.veeErrors.any() },
                    on: { onSave: _vm.onSubmit, onCancel: _vm.onCancel }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.mode === _vm.Enums.FORM_MODE.SHOW ||
      _vm.mode === _vm.Enums.FORM_MODE.READONLY
        ? _c(
            "div",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "ticket-details-label text-sm-left font-weight-medium"
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("forms.tickets.subject")
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _c("v-text-field", {
                                    staticClass: "ticket-details",
                                    attrs: {
                                      flat: "",
                                      solo: "",
                                      readonly: "",
                                      "hide-details": "",
                                      value: this.form.subject
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "ticket-details-label text-sm-left font-weight-medium"
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("forms.tickets.detail")
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _c("v-textarea", {
                                    staticClass: "text-sm-left ticket-details",
                                    attrs: {
                                      flat: "",
                                      solo: "",
                                      readonly: "",
                                      "auto-grow": "",
                                      "hide-details": "",
                                      value: this.form.detail
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "ticket-details-label text-sm-left font-weight-medium"
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "forms.tickets.additional_costs"
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _c("v-text-field", {
                                    staticClass: "ticket-details",
                                    attrs: {
                                      flat: "",
                                      solo: "",
                                      readonly: "",
                                      "hide-details": "",
                                      value: this.form.additional_costs
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "ticket-details-label text-sm-left font-weight-medium"
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("forms.tickets.final_note")
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _c("v-text-field", {
                                    staticClass: "ticket-details",
                                    attrs: {
                                      flat: "",
                                      solo: "",
                                      readonly: "",
                                      "hide-details": "",
                                      value: this.form.final_note
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("TicketActivities", {
                            attrs: {
                              modelType: "tickets",
                              modelId: Number(this.form.id),
                              ticketState: this.form.state,
                              ticketInternalState: this.form.internal_state,
                              ticketSubType: this.form.ticket_subtype,
                              ticketType: this.form.ticket_type
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "info lighten-3 elevation-3 xs-buttons"
                            },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        row: "",
                                        wrap: "",
                                        "mb-1": "",
                                        "ticket-info": ""
                                      }
                                    },
                                    [
                                      _c("v-flex", { attrs: { xs3: "" } }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("forms.tickets.state"))
                                        )
                                      ]),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "font-weight-medium",
                                          attrs: { xs3: "" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.getEnumTranslationFor(
                                                  "tickets",
                                                  _vm.form.state
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "py-2",
                                          attrs: { xs6: "" }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.form.state !=
                                                      _vm.Enums.TICKET_STATUS
                                                        .CLOSED &&
                                                    _vm.form.internal_state ==
                                                      _vm.Enums
                                                        .TICKET_INTERNAL_STATUS
                                                        .FINISHED,
                                                  expression:
                                                    "\n                      form.state != Enums.TICKET_STATUS.CLOSED &&\n                        form.internal_state ==\n                          Enums.TICKET_INTERNAL_STATUS.FINISHED\n                    "
                                                }
                                              ],
                                              staticClass: "mr-3",
                                              attrs: { small: "" },
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.quickStatusAction()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getEnumTranslationFor(
                                                    "tickets",
                                                    _vm.getNextStatusAction()
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        row: "",
                                        wrap: "",
                                        "mb-1": "",
                                        "ticket-info": ""
                                      }
                                    },
                                    [
                                      _c("v-flex", { attrs: { xs3: "" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "forms.tickets.internal_state"
                                            )
                                          )
                                        )
                                      ]),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "font-weight-medium",
                                          attrs: { xs3: "" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.getEnumTranslationFor(
                                                  "tickets",
                                                  this.form.internal_state
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                this.form.state !=
                                                _vm.Enums.TICKET_STATUS.CLOSED,
                                              expression:
                                                "this.form.state != Enums.TICKET_STATUS.CLOSED"
                                            }
                                          ],
                                          staticClass: "py-2",
                                          attrs: { xs6: "" }
                                        },
                                        _vm._l(
                                          _vm.internalStatusNextAction,
                                          function(action) {
                                            return _c(
                                              "v-btn",
                                              {
                                                key: action,
                                                staticClass: "mr-3",
                                                attrs: {
                                                  small: "",
                                                  color: "primary"
                                                },
                                                nativeOn: {
                                                  click: function($event) {
                                                    return _vm.quickInternalStatusAction(
                                                      action
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getEnumTranslationFor(
                                                      "tickets",
                                                      action
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        row: "",
                                        wrap: "",
                                        "mb-1": "",
                                        "ticket-info": ""
                                      }
                                    },
                                    [
                                      _c("v-flex", { attrs: { xs3: "" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "forms.tickets.billed_total_hours"
                                            )
                                          )
                                        )
                                      ]),
                                      _c("v-flex", { attrs: { xs3: "" } }, [
                                        _vm._v(
                                          _vm._s(_vm.getBilledTotalHours())
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("br"),
                          _c("div", { staticClass: "subtitle-2" }, [
                            _vm._v(_vm._s(_vm.$t("forms.tickets.ticket_info")))
                          ]),
                          _c(
                            "v-card",
                            { staticClass: "elevation-2" },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "ticket-info" },
                                    [
                                      _c("v-col", { attrs: { cols: "4" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("forms.tickets.ticket")
                                          ) + ":"
                                        )
                                      ]),
                                      _c("v-col", { attrs: { cols: "8" } }, [
                                        _vm._v(_vm._s(this.form.code))
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "ticket-info" },
                                    [
                                      _c("v-col", { attrs: { cols: "4" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("forms.tickets.ticket_type")
                                          ) + ":"
                                        )
                                      ]),
                                      _c("v-col", { attrs: { cols: "8" } }, [
                                        _vm._v(_vm._s(_vm.getTicketType()))
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "ticket-info" },
                                    [
                                      _c("v-col", { attrs: { cols: "4" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "forms.tickets.ticket_subtype"
                                            )
                                          ) + ":"
                                        )
                                      ]),
                                      _c("v-col", { attrs: { cols: "8" } }, [
                                        _vm._v(_vm._s(_vm.getTicketSubtype()))
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "ticket-info" },
                                    [
                                      _c("v-col", { attrs: { cols: "4" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("forms.tickets.operators")
                                          ) + ":"
                                        )
                                      ]),
                                      _c("v-col", { attrs: { cols: "8" } }, [
                                        _vm._v(
                                          _vm._s(_vm.currentOperator() || "-")
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "ticket-info" },
                                    [
                                      _c("v-col", { attrs: { cols: "4" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("forms.tickets.creator")
                                          ) + ":"
                                        )
                                      ]),
                                      _c("v-col", { attrs: { cols: "8" } }, [
                                        _vm._v(_vm._s(_vm.creator() || "-"))
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "ticket-info" },
                                    [
                                      _c("v-col", { attrs: { cols: "4" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("forms.tickets.priority")
                                          ) + ":"
                                        )
                                      ]),
                                      _c("v-col", { attrs: { cols: "8" } }, [
                                        _vm._v(_vm._s(_vm.getPriority()))
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "ticket-info" },
                                    [
                                      _c("v-col", { attrs: { cols: "4" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("forms.tickets.category")
                                          ) + ":"
                                        )
                                      ]),
                                      _c("v-col", { attrs: { cols: "8" } }, [
                                        _vm._v(_vm._s(_vm.getCategory()))
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "ticket-info" },
                                    [
                                      _c("v-col", { attrs: { cols: "4" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("forms.tickets.opened_by")
                                          ) + ":"
                                        )
                                      ]),
                                      _c("v-col", { attrs: { cols: "8" } }, [
                                        _vm._v(_vm._s(_vm.getOpenedBy()))
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "ticket-info" },
                                    [
                                      _c("v-col", { attrs: { cols: "4" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("forms.tickets.created_at")
                                          ) + ":"
                                        )
                                      ]),
                                      _c("v-col", { attrs: { cols: "8" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("date")(this.form.created_at)
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "ticket-info" },
                                    [
                                      _c("v-col", { attrs: { cols: "4" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("forms.tickets.due_date")
                                          ) + ":"
                                        )
                                      ]),
                                      _c("v-col", { attrs: { cols: "8" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("date")(this.form.due_date)
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  this.form.state ===
                                  _vm.Enums.TICKET_STATUS.OPENED
                                    ? _c(
                                        "v-row",
                                        { staticClass: "ticket-info" },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("forms.tickets.age")
                                                ) + ":"
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "8" } },
                                            [
                                              _vm._v(
                                                _vm._s(this.ticketTiming())
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "v-row",
                                        { staticClass: "ticket-info" },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "forms.tickets.processing_time"
                                                  )
                                                ) + ":"
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "8" } },
                                            [
                                              _vm._v(
                                                _vm._s(this.ticketTiming())
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("br"),
                          _c("div", { staticClass: "subtitle-2" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("forms.tickets.customer_info")) +
                                "\n          "
                            )
                          ]),
                          _c(
                            "v-card",
                            { staticClass: "elevation-2" },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "ticket-info" },
                                    [
                                      _c("v-col", { attrs: { cols: "4" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("forms.tickets.customer")
                                          ) + ":"
                                        )
                                      ]),
                                      _c("v-col", { attrs: { cols: "8" } }, [
                                        _vm._v(
                                          _vm._s(this.form.customer.name || "")
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "ticket-info" },
                                    [
                                      _c("v-col", { attrs: { cols: "4" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "forms.tickets.mechanographyc_code"
                                            )
                                          ) + ":"
                                        )
                                      ]),
                                      _c("v-col", { attrs: { cols: "8" } }, [
                                        _vm._v(
                                          _vm._s(
                                            this.form.customer
                                              .mechanographyc_code || ""
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "ticket-info" },
                                    [
                                      _c("v-col", { attrs: { cols: "4" } }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("forms.tickets.piva")) +
                                            ":"
                                        )
                                      ]),
                                      _c("v-col", { attrs: { cols: "8" } }, [
                                        _vm._v(
                                          _vm._s(this.form.customer.piva || "")
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "ticket-info" },
                                    [
                                      _c("v-col", { attrs: { cols: "4" } }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("forms.tickets.city")) +
                                            ":"
                                        )
                                      ]),
                                      _c("v-col", { attrs: { cols: "8" } }, [
                                        _vm._v(
                                          _vm._s(
                                            this.form.customer.city || ""
                                          ) +
                                            " (" +
                                            _vm._s(
                                              this.form.customer.province || ""
                                            ) +
                                            ")"
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm.showAgreementDetail()
                                    ? _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            row: "",
                                            wrap: "",
                                            "mb-1": "",
                                            "ticket-info": ""
                                          }
                                        },
                                        [
                                          _c("v-flex", { attrs: { xs3: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "forms.tickets.agreement"
                                                )
                                              ) + ":"
                                            )
                                          ]),
                                          _c("v-flex", { attrs: { xs9: "" } }, [
                                            _vm._v(
                                              _vm._s(_vm.getAgreementCode())
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.showAgreementDetail()
                                    ? _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            row: "",
                                            wrap: "",
                                            "mb-1": "",
                                            "ticket-info": ""
                                          }
                                        },
                                        [
                                          _c("v-flex", { attrs: { xs3: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("forms.tickets.services")
                                              ) + ":"
                                            )
                                          ]),
                                          _c("v-flex", { attrs: { xs9: "" } }, [
                                            _vm._v(_vm._s(this.name_services))
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }