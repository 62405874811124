<template>
  <v-container fluid>
    <ToolbarDetail
      :title="detailTitle | upperCase"
      :withEdit="true"
      @onEdit="openFormDialog(Enums.FORM_MODE.UPDATE)"
      @onBack="setOrderFlag(true)"
    ></ToolbarDetail>

    <template v-if="loaded">
      <v-tabs
        class="my-4"
        v-model="activeTab"
        background-color="secondary"
        dark
        slider-color="accent"
        @change="syncUrl"
      >
        <v-tab key="details">{{ $t("labels.details") }}</v-tab>
        <v-tab key="attachments">{{ $t("labels.attachments") }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab">
        <v-tab-item key="details">
          <v-card flat>
            <OrderForm
              :mode="Enums.FORM_MODE.SHOW"
              :selectedItem="this.selectedOrder"
            ></OrderForm>
          </v-card>
        </v-tab-item>
        <v-tab-item key="attachments">
          <v-card flat>
            <AttachmentsList
              withModelType="orders"
              :withModelId="this.selectedOrder.id"
              :listMode="Enums.LIST_MODE.TABBED"
              permittedTypes="ORDINE"
            ></AttachmentsList>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <v-dialog v-model="formDialog" persistent max-width="960">
      <OrderForm
        v-if="formDialog"
        :mode="Enums.FORM_MODE.UPDATE"
        :selectedItem="this.clonedSelected()"
        @formSucceed="handleFormSucceed"
        @formCancel="handleFormCancel"
        @formDelete="handleDelete"
        withModelType="services"
        :withModelId="this.selectedOrder.id"
      ></OrderForm>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "lodash";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { mapActions, mapGetters, mapMutations } from "vuex";
import OrderForm from "@/components/Order/OrderForm";
import ToolbarDetail from "@/components/Shared/ToolbarDetail";
import FormsHelper from "@/mixins/formsHelper";
import AttachmentsList from "@/components/Attachment/AttachmentsList";

export default {
  name: "OrderDetails",
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      resourceType: this.$t("resource_types.order"),
      activeTab: null,
      loaded: false
    };
  },
  components: {
    OrderForm,
    ToolbarDetail,
    AttachmentsList
  },
  mixins: [SharedMixin, Enums, FormsHelper],
  watch: {
    id() {
      this.initComponent();
    }
  },
  methods: {
    ...mapActions("order", ["ajaxOrderSetSelected"]),
    ...mapMutations("filters", ["setOrderFilter", "setOrderFlag"]),
    async initComponent() {
      await this.ajaxOrderSetSelected({ id: this.$props.id });
      this.loaded = true;
    },
    syncUrl() {},

    handleFormSucceed() {
      this.closeFormDialog();
      this.ajaxOrderSetSelected({ id: this.$props.id });
    },
    handleFormCancel() {
      this.closeFormDialog();
    },
    handleDelete() {
      this.$router.pushCatch({ name: "orders" });
    },
    clonedSelected() {
      return _.clone(this.selectedOrder);
    }
  },
  computed: {
    ...mapGetters("order", ["orders", "total", "onLoading", "selectedOrder"]),
    Enums() {
      return Enums;
    },
    detailTitle() {
      return this.$t("labels.detailResourceTypeNumber", {
        resourceType: this.resourceType,
        resourceNumber: this.selectedOrder.number
      });
    }
  },
  created() {
    this.initComponent();
  }
};
</script>
