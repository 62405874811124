<template>
  <div>
    <div class="subtitle-2">{{ $t("forms.tickets.activities") }}</div>

    <v-card class="activity-creator">
      <form
        class="form-group"
        :class="{ adding: this.isAdding, editing: this.isEditing }"
      >
        <v-row>
          <v-col cols="12" class="px-3">
            <v-textarea
              class="required"
              v-validate.immediate="'required'"
              name="description"
              :error-messages="errors.description"
              :label="$t('forms.tickets.log_activity')"
              v-model="form.description"
              @input="handleInputDescription"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="caption">          
          </v-col>
          <v-col cols="3" class="overline">
            <v-text-field
              :label="$t('forms.tickets.people_number')"
              name="person_number"
              v-model="form.person_number"
              class="required caption"
              type="number"
              v-validate="'required'"
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="caption">
            <v-menu
              ref="menu"
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="time"
                  :label="$t('forms.tickets.billing_hours')"
                  prepend-icon="mdi-clock-time-four-outline"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu2"
                format="24hr"
                v-model="time"
                full-width
                @click:minute="$refs.menu.save(time)"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row class="activity-actions" justify="center" align="center">
          <v-col class="text-center">
            <v-btn
              v-if="this.isAdding == true"
              :disabled="
                veeErrors.any() ||
                  $props.ticketState === getTicketStatus.CLOSED ||
                  $props.ticketState === getTicketStatus.CANCELED ||
                  $props.ticketInternalState ===
                    getTicketInternatStatus.FINISHED
              "
              @click.native="addActivity()"
              color="info"
              class="mx-3"
              small
              >{{ $t("buttons.add") }}</v-btn
            >
            <v-btn
              v-if="this.isAdding == true"
              :disabled="
                veeErrors.any() ||
                  $props.ticketState === getTicketStatus.CLOSED ||
                  $props.ticketState === getTicketStatus.CANCELED ||
                  $props.ticketInternalState ===
                    getTicketInternatStatus.FINISHED
              "
              @click.native="clearForm()"
              text
              small
              >{{ $t("buttons.clear") }}</v-btn
            >
            <v-btn
              v-if="this.isEditing"
              @click="updateMe"
              small
              class="mx-3"
              color="info"
            >
              Save
            </v-btn>
            <v-btn v-if="this.isEditing" @click="clearForm" small class="mx-3">
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </form>
    </v-card>

    <v-progress-linear
      v-if="onLoading"
      color="info"
      height="5"
      indeterminate
    ></v-progress-linear>

    <!-- Activities List -->
    <v-card
      v-for="item in this.activities"
      :key="item.id"
      class="activity-card"
    >
      <v-flex ma-1>
        <v-card-actions>
          <v-row fill-height align-center justify-space-between>
            <v-col cols="8">
              <div sm6 class="activity-owner">
                {{ signature(item) }}
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="4">
              <div class="activity-buttons">
                <v-btn
                  text
                  icon
                  @click="prepareEdit(item)"
                  color="info"
                  :disabled="
                    $props.ticketState === getTicketStatus.CLOSED ||
                      $props.ticketState === getTicketStatus.CANCELED ||
                      $props.ticketInternalState ===
                        getTicketInternatStatus.FINISHED
                  "
                >
                  <v-icon>edit</v-icon>
                </v-btn>
                <v-btn
                  text
                  icon
                  @click="handleDelete(item)"
                  color="warning"
                  :disabled="
                    $props.ticketState === getTicketStatus.CLOSED ||
                      $props.ticketState === getTicketStatus.CANCELED ||
                      $props.ticketInternalState ===
                        getTicketInternatStatus.FINISHED
                  "
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-title>
          <v-card-text class="activity-header"> 
            {{ getDurataIntervento(item) }}
          </v-card-text>
          <v-card-text class="activity-header"> 
            {{ getPersonNumber(item) }}
          </v-card-text>
          <v-card-text class="activity-message">
            {{ item.description }}
          </v-card-text>
        </v-card-title>
      </v-flex>
    </v-card>
  </div>
</template>

<script>
import _ from "lodash";
import SharedMixin from "@/mixins/shared";
import Enums from "@/mixins/enums";
import FormValidator from "@/mixins/form-validator";
import { mapActions, mapGetters } from "vuex";
import voca from "voca";
const moment = require("moment");
import { HelperService } from "@/services/helper.service";

export default {
  name: "activities",
  props: {
    modelId: {
      type: Number,
      required: true
    },
    modelType: {
      type: String,
      required: true
    },
    ticketState: {
      type: String,
      required: false
    },
    ticketInternalState: {
      type: String,
      required: false
    },
    ticketType: {
      type: String,
      required: false
    },
    ticketSubType: {
      type: String,
      required: false
    },
    mode: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      menu2: false,
      time: null,
      resourceType: this.$t("resource_types.activity"),
      formMode: Enums.FORM_MODE.ADD,
      form: {
        id: "",
        billed_time: 0,
        description: "",
        model_type: this.$props.modelType,
        model_id: this.$props.modelId,
        person_number: 1
      },
      selectedItem: {
        id: ""
      },
      errors: {
        description: ""
      }
    };
  },
  mixins: [SharedMixin, Enums, FormValidator],
  methods: {
    ...mapActions("activity", [
      "ajaxActivityFetchAll",
      "ajaxActivityCreateOrUpdate",
      "ajaxActivityDelete"
    ]),
    async handleInputDescription() {
      await this.$validator.validateAll();
      if (this.veeErrors.has("description"))
        this.errors.description = this.$t("misc.description_required");
      else this.errors.description = "";
    },
    async fetchActivitiesByModelAndType(payload) {
      const filterAndPagination = {
        filter: {
          model_type: payload.modelType,
          model_id: payload.modelId
        }
      };
      await this.ajaxActivityFetchAll(filterAndPagination);
    },
    async fetchData() {
      await this.fetchActivitiesByModelAndType({
        modelType: this.$props.modelType,
        modelId: this.$props.modelId
      });
    },
    setForm(mode, item = null) {
      mode == null
        ? (this.formMode = Enums.FORM_MODE.ADD)
        : (this.formMode = mode);
      if (item) {
        this.form.id = item.id;
        this.form.model_id = item.model_id;
        this.form.model_type = item.model_type;
        this.form.description = item.description;
        this.form.billed_time = item.billed_time;
        this.time = HelperService.convertHoursToTime(item.billed_time);
        this.form.person_number = item.person_number;
      } else {
        this.veeErrors.clear();
        this.form.description = null;
        this.form.billed_time = null;
        this.time = null;
        this.form.model_id = this.$props.modelId;
        this.form.model_type = this.$props.modelType;
        this.form.id = null;
        this.form.person_number = 1;
      }
      this.handleInputDescription();
    },
    prepareEdit(item) {
      this.setForm(Enums.FORM_MODE.UPDATE, item);
    },
    clearForm() {
      this.setForm(null, null);
    },
    clearSelectedItem() {
      this.selectedItem = {};
    },
    signature(item) {
      return (
        item.user.email + " - " + this.$options.filters.date(item.created_at)
      );
    },
    getDurataIntervento(item) {
      let value = this.$t("forms.tickets.duration") + ": ";
      if (item.billed_time) {
        let hours = HelperService.convertHoursToTime(item.billed_time);
        value = `${value} ${hours} ${this.$t("forms.tickets.to_invoice")}`;
      }
      return value;
    },
    getPersonNumber(item) {
      let value = this.$t("forms.tickets.people_number") + ": ";
      if (item.person_number) {
        value = `${value} ${item.person_number}`;
      }
      return value;
    },
    handleDelete(item) {
      this.$confirm({
        message: this.$t("dialogs.deleteConfirmMessage"),
        button: {
          no: this.$t("dialogs.no"),
          yes: this.$t("dialogs.yes")
        },
        callback: async confirm => {
          if (confirm) {
            await this.deleteHelper(
              this.resourceType,
              this.ajaxActivityDelete,
              item,
              () => ""
            );
            //TMPP
            await new Promise(resolve => setTimeout(resolve, 1500));
            await this.fetchData();
            this.$emit("handleReload");
          }
        }
      });
    },
    async addActivity() {
      const validate = await this.$validator.validateAll();
      if (validate && this.formMode == Enums.FORM_MODE.ADD) {
        let payload = _.cloneDeep(this.form);
        payload.billed_time = HelperService.convertTimeToHours(this.time);
        await this.createOrUpdateHelper(
          Enums.FORM_MODE.CREATE,
          this.resourceType,
          this.ajaxActivityCreateOrUpdate,
          payload,
          () => "",
          () => ""
        );
        this.clearForm();
        await this.fetchData();
        this.$emit("handleReload");
      }
    },
    async updateMe() {
      const validate = await this.$validator.validateAll();
      if (validate && this.formMode == Enums.FORM_MODE.UPDATE) {
        let payload = _.cloneDeep(this.form);
        payload.billed_time = HelperService.convertTimeToHours(this.time);
        await this.createOrUpdateHelper(
          Enums.FORM_MODE.UPDATE,
          this.resourceType,
          this.ajaxActivityCreateOrUpdate,
          payload,
          () => "",
          () => ""
        );
        this.clearForm();
        await this.fetchData();
        this.$emit("handleReload");
      }
    }
  },
  /*watch: {
    modelId() {
      this.form.model_id = this.$props.modelId;
      this.fetchData();
    }
  },*/
  computed: {
    ...mapGetters("activity", ["activities", "onLoading"]),
    isAdding() {
      if (this.formMode == Enums.FORM_MODE.ADD) return true;
      else return false;
    },
    isEditing() {
      if (this.formMode == Enums.FORM_MODE.UPDATE) return true;
      else return false;
    },
    getTicketStatus() {
      return Enums.TICKET_STATUS;
    },
    getTicketInternatStatus() {
      return Enums.TICKET_INTERNAL_STATUS;
    },
    getTicketSubType() {
      return Enums.TICKET_SUBTYPE;
    },
    getTicketType() {
      return Enums.TICKET_TYPE;
    },
    Enums() {
      return Enums;
    }
  },
  created() {
    this.fetchData();
    this.handleInputDescription();
  },
  filters: {
    capitalize: function(value) {
      // if (!value) return '';
      // value = value.toString();
      // return value.charAt(0).toUpperCase() + value.slice(1);
      return voca.capitalize(value);
    },
    trim: function(value) {
      // if (!value) return '';
      // value = value.toString();
      // return value.trim();
      return voca.trim(value);
    },
    titleCase: function(value) {
      return voca.titleCase(value);
    }
  }
};
</script>

<style scoped>
.activities-title {
  margin-bottom: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.v-card {
  margin-bottom: 8px;
}
.activity-actions {
  padding: 0px;
}
.activity-text {
  font-family: monospace;
  padding: 0px;
}
.activity-details {
  font-size: medium;
  padding: 8px;
}
.add-form {
  padding: 4px;
}
.adding {
  padding: 16px;
}
.editing {
  padding: 16px;
  background-color: var(--v-info-lighten3) !important;
}
.v-card__title {
  padding: 7px;
}
.activity-creator {
  border-style: solid;
  border-color: var(--v-secondary-base) !important;
  border-width: 1px;
}

.activity-card {
  width: 90%;
  margin-left: auto;
}

.activity-owner {
  margin: 2px 10px;
  font-size: small;
  font-style: oblique;
  font-weight: 300;
}
.activity-buttons {
  margin: 2px 10px;
  text-align: end;
}
.activity-header {
  font-size: small;
  font-family: monospace;
  padding: 5px;
}
.activity-message {
  font-size: small;
}
</style>
