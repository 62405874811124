/*  eslint no-shadow: ["error", { "allow": ["state"] }] */
/*  eslint no-param-reassign: ["error", { "props": false }] */
import Vue from "vue";
import Vuex from "vuex";
import { UserRoleService } from "@/services/userRole.service";
import { HelperService } from "@/services/helper.service";

Vue.use(Vuex);

const state = {
  userRoles: [],
  roles: [],
  selected: {},
  loading: false,
  totalCount: 0
};

const getters = {
  userRoles: state => state.userRoles,
  roles: state => state.roles,
  selectedUserRole: state => state.selected,
  onLoading: state => state.loading,
  total: state => state.totalCount
};

const mutations = {
  setSelected(state, sel) {
    state.selected = sel;
  },
  setList(state, sel) {
    state.userRoles = sel;
  },
  setRoles(state, sel) {
    state.roles = sel;
  },
  setTotal(state, sel) {
    state.totalCount = Number(sel);
  },
  setLoading(state, sel) {
    state.loading = sel;
  }
};

const actions = {
  async ajaxUserRoleSetSelected({ commit }, payload) {
    await HelperService.genericSetSelected(
      commit,
      UserRoleService.fetchOne,
      payload.id
    );
  },

  async ajaxUserRoleFetchAll({ commit }, payload) {
    let searchFilterPagination = HelperService.localToJSONAPIPagination(
      payload
    );
    searchFilterPagination.include = "";

    await HelperService.genericFetchAll(
      commit,
      UserRoleService.fetchAll,
      searchFilterPagination,
      null,
      null
    );
  },

  async ajaxUserRoleCreateOrUpdate({ commit }, payload) {
    return HelperService.genericCreateOrUpdate(
      commit,
      UserRoleService.createOrUpdate,
      payload
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
