import { render, staticRenderFns } from "./ContentForm.vue?vue&type=template&id=840090fe&scoped=true&"
import script from "./ContentForm.vue?vue&type=script&lang=js&"
export * from "./ContentForm.vue?vue&type=script&lang=js&"
import style0 from "./ContentForm.vue?vue&type=style&index=0&id=840090fe&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "840090fe",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCardTitle,VContainer,VFileInput,VForm,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/home/deploy/netcrm/staging/source/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('840090fe')) {
      api.createRecord('840090fe', component.options)
    } else {
      api.reload('840090fe', component.options)
    }
    module.hot.accept("./ContentForm.vue?vue&type=template&id=840090fe&scoped=true&", function () {
      api.rerender('840090fe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Attachment/ContentForm.vue"
export default component.exports