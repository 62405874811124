var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("ToolbarDetail", {
        attrs: { title: _vm._f("capitalize")(_vm.detailTitle) },
        on: {
          onBack: function($event) {
            return _vm.setAgreementFlag(true)
          }
        }
      }),
      _vm.loaded
        ? [
            _c(
              "v-tabs",
              {
                staticClass: "my-4",
                attrs: {
                  "background-color": "secondary",
                  dark: "",
                  "slider-color": "accent"
                },
                on: { change: _vm.syncUrl },
                model: {
                  value: _vm.activeTab,
                  callback: function($$v) {
                    _vm.activeTab = $$v
                  },
                  expression: "activeTab"
                }
              },
              [
                _c("v-tab", { attrs: { name: "invoicing_periods1" } }, [
                  _vm._v(_vm._s(_vm.$t("labels.invoicing_periods1")))
                ]),
                _c("v-tab", { attrs: { name: "invoicing_periods2" } }, [
                  _vm._v(_vm._s(_vm.$t("labels.invoicing_periods2")))
                ]),
                _c("v-tab", { attrs: { name: "invoices_management" } }, [
                  _vm._v(_vm._s(_vm.$t("labels.invoices_management")))
                ]),
                _c("v-tab", { attrs: { name: "invoicing_data" } }, [
                  _vm._v(_vm._s(_vm.$t("labels.invoicing_data")))
                ])
              ],
              1
            ),
            _c(
              "v-tabs-items",
              {
                model: {
                  value: _vm.activeTab,
                  callback: function($$v) {
                    _vm.activeTab = $$v
                  },
                  expression: "activeTab"
                }
              },
              [
                _c(
                  "v-tab-item",
                  [
                    _c(
                      "v-card",
                      { attrs: { flat: "" } },
                      [
                        _c("InvoicePeriodsGrid", {
                          attrs: {
                            selectedItem: this.preparedSelected(),
                            singleRow: true,
                            onReloadPage: _vm.onReloadPage
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-tab-item",
                  [
                    _c(
                      "v-card",
                      { attrs: { flat: "" } },
                      [
                        _c("InvoicePeriodsList", {
                          attrs: {
                            withModelId: this.preparedSelected().id,
                            selectedItem: this.preparedSelected(),
                            listMode: _vm.Enums.LIST_MODE.TABBED,
                            onReloadPage: _vm.onReloadPage
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-tab-item",
                  [
                    _c(
                      "v-card",
                      { attrs: { flat: "" } },
                      [
                        _c("InvoicesList", {
                          attrs: {
                            withModelId: this.preparedSelected().id,
                            selectedItem: this.preparedSelected(),
                            listMode: _vm.Enums.LIST_MODE.TABBED,
                            onReloadPage: _vm.onReloadPage
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-tab-item",
                  [
                    _c(
                      "v-card",
                      { attrs: { flat: "" } },
                      [
                        _c("InvoicingData", {
                          attrs: {
                            withModelId: this.preparedSelected().id,
                            selectedItem: this.preparedSelected(),
                            listMode: _vm.Enums.LIST_MODE.TABBED,
                            onReloadPage: _vm.onReloadPage
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }