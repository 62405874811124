import Vue from "vue";
import Vuex from "vuex";
import { FolderService } from "@/services/folder.service";
import { HelperService } from "@/services/helper.service";
import store from "@/store";
import _ from "lodash";
import rollbar from "@/rollbar";

Vue.use(Vuex);

const state = {
  folders: [],
  selected: {},
  loading: false,
  totalCount: 0
};

const getters = {
    folders: state => state.folders,
    onLoading: state => state.loading,
    selectedFolder: state => state.selected,
    total: state => state.totalCount
};

const mutations = {
    setSelected(state, sel) {
      state.selected = sel;
    },
    setList(state, sel) {
      state.folders = sel;
    },
    setTotal(state, sel) {
      state.totalCount = Number(sel);
    },  
    setLoading(state, sel) {
      state.loading = sel;
    }
};

const actions = {
    async ajaxFolderSetSelected({ commit }, payload) {
      await HelperService.genericSetSelected(
        commit,
        FolderService.fetchOne,
        payload.id,
        //formatting
      );
    },
  
    async ajaxFolderFetchAll({ commit }, payloadFAP) {
      const searchFilterPagination = HelperService.localToJSONAPIPagination(
        payloadFAP
      );
      searchFilterPagination.include = "tagging,tagging.tag";
      searchFilterPagination.sort = "sorting";
  
      await HelperService.genericFetchAll(
        commit,
        FolderService.fetchAll,
        searchFilterPagination,
        null,
      );
    },
  
    async ajaxFolderCreateOrUpdate({ commit }, payload) {
      return HelperService.genericCreateOrUpdate(
        commit,
        FolderService.createOrUpdate,
        payload
      );
    },
  
    async ajaxFolderDelete({ commit }, payload) {
      return HelperService.genericDelete(
        commit,
        FolderService.delete,
        payload.id
      );
    }
};
  
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};