var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-card",
        [
          !_vm.singleRow
            ? _c("ToolbarTab", {
                attrs: {
                  title: _vm.$t("labels.agreements"),
                  subtitle: _vm.$tc("labels.found_elements", this.total),
                  withSearch: true
                },
                on: { onSearch: _vm.debounceSearch }
              })
            : _vm._e(),
          _c("ColorGrid", {
            attrs: {
              headers: this.tableData.headers,
              items: this.tableData.items,
              elementsList: this.elementsList,
              totalLength: this.total,
              pagination: this.tableOptions,
              loading: this.onLoading,
              tableOptions: this.tableOptions,
              total: this.total,
              singleRow: _vm.singleRow,
              tableName: "invoice-periods-grid"
            },
            on: { onPageChange: _vm.handlePageChange }
          })
        ],
        1
      ),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }