<template>
  <v-container fluid class="form-container title">
    <div>
      <v-card-title class="form-title title">
        {{ $t("labels.settings") }} {{ $t("labels.invoicingSettings") }}
      </v-card-title>
      <v-form
        class="form-body"
        @submit.prevent="onSubmit()"
        data-vv-scope="invoicingSettings"
      >
        <div class="form-group required">
          <v-text-field
            :label="$t('forms.settings.invoicing_ns')"
            v-model="invoicingForm.invoicing_netsense_send_to"
            name="invoicing_netsense_send_to"
          ></v-text-field>
        </div>
        <div class="form-group required">
          <v-text-field
            :label="$t('forms.settings.invoicing_sfera')"
            v-model="invoicingForm.invoicing_sfera_send_to"
            name="invoicing_sfera_send_to"
          ></v-text-field>
        </div>
        <div class="form-group required">
          <v-select
            v-model="invoicingForm.flag_sending_invoice_email"
            :items="enabledValues"
            item-text="label"
            item-value="value"
            :label="$t('forms.settings.invoicing_enabled')"
            name="flag_sending_invoice_email"
          ></v-select>
        </div>
        <FormButtons
          @onSave="onSubmit"
          @onCancel="onCancel"
          :disabled="veeErrors.any('invoicingSettings')"
        />
      </v-form>
    </div>
  </v-container>
</template>

<script>
import SharedMixin from "@/mixins/shared";
import Enums from "@/mixins/enums";
import FormButtons from "@/components/Shared/FormButtons";
import _ from "lodash";

export default {
  name: "InvoicingSettings",
  props: ["settings"],
  data() {
    return {
      invoicingForm: {
        invoicing_netsense_send_to: "",
        invoicing_sfera_send_to: "",
        flag_sending_invoice_email: ""
      },
      enabledValues: [
        {
          label: this.$t("forms.settings.yes"),
          value: true
        },
        {
          label: this.$t("forms.settings.no"),
          value: false
        }
      ]
    };
  },
  components: { FormButtons },
  mixins: [SharedMixin, Enums],
  watch: {
    settings() {
      this.clearForm();
    }
  },
  methods: {
    onSubmit() {
      this.$emit("formSave", this.invoicingForm);
    },
    async onCancel() {
      this.clearForm();
      this.$emit("formCancel");
    },
    clearForm() {
      if (this.settings != null)
        this.invoicingForm = _.cloneDeep(this.settings);
    }
  },
  computed: {
    Enums() {
      return Enums;
    }
  },
  async created() {
    this.clearForm();
  }
};
</script>
