var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      this.listMode === _vm.Enums.LIST_MODE.FULL
        ? _c(
            "v-card",
            [
              _c("ToolbarList", {
                attrs: {
                  title: _vm.$t("labels.services"),
                  subtitle: _vm.$tc("labels.found_elements", this.total),
                  withSearch: true,
                  withAdd: true
                },
                on: {
                  onAdd: function($event) {
                    return _vm.openFormDialog(_vm.Enums.FORM_MODE.CREATE)
                  },
                  onSearch: _vm.searchFunc
                }
              }),
              _c("BaseGrid", {
                attrs: {
                  tableName: "services",
                  headers: this.tableData.headers,
                  items: this.tableData.items,
                  totalLength: this.total,
                  pagination: this.tableOptions,
                  loading: this.onLoading,
                  hideActions: true
                },
                on: { onPaginationChanged: _vm.handlePaginationChanged }
              })
            ],
            1
          )
        : _vm._e(),
      this.listMode === _vm.Enums.LIST_MODE.TABBED
        ? _c(
            "v-container",
            [
              _c(
                "v-card",
                [
                  _c("ToolbarTab", {
                    attrs: {
                      subtitle: _vm.$tc("labels.found_elements", this.total),
                      withAdd: true
                    },
                    on: {
                      onAdd: function($event) {
                        return _vm.openFormDialog(_vm.Enums.FORM_MODE.CREATE)
                      }
                    }
                  }),
                  _c("BaseGrid", {
                    attrs: {
                      tableName: "services",
                      headers: this.tableData.headers,
                      items: this.tableData.items,
                      totalLength: this.total,
                      pagination: this.tableOptions,
                      loading: this.onLoading,
                      hideActions: true
                    },
                    on: { onPaginationChanged: _vm.handlePaginationChanged }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "content-class": "edit-form-dialog" },
          model: {
            value: _vm.formDialog,
            callback: function($$v) {
              _vm.formDialog = $$v
            },
            expression: "formDialog"
          }
        },
        [
          _vm.formDialog
            ? _c("ServiceForm", {
                attrs: { mode: _vm.formMode, withModelId: this.withModelId },
                on: {
                  formSucceed: _vm.handleFormSucceed,
                  formCancel: _vm.handleFormCancel
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }