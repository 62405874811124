var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    { staticClass: "toolbar-94", attrs: { flat: "" } },
    [
      _c(
        "v-row",
        { staticClass: "toolbar-list", attrs: { align: "center" } },
        [
          _c(
            "v-col",
            {
              staticClass: "toolbar-list-info",
              attrs: {
                cols:
                  _vm.withSearch && _vm.withExportPdf
                    ? 3
                    : _vm.withSearch || _vm.withExportPdf
                    ? 4
                    : 8
              }
            },
            [
              _c("v-toolbar-title", [
                _c("div", [
                  _vm.title
                    ? _c("p", { staticClass: "text-h4 text-sm-left my-1" }, [
                        _vm._v(_vm._s(_vm.title))
                      ])
                    : _vm._e(),
                  _vm.subtitle
                    ? _c("p", { staticClass: "caption text-sm-left" }, [
                        _vm._v(_vm._s(_vm.subtitle))
                      ])
                    : _vm._e()
                ])
              ])
            ],
            1
          ),
          _vm.withSearch
            ? _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("misc.search"),
                      color: "warning",
                      clearable: "",
                      "single-line": ""
                    },
                    on: {
                      input: function($event) {
                        _vm.isTyping = true
                      }
                    },
                    model: {
                      value: _vm.searchQuery,
                      callback: function($$v) {
                        _vm.searchQuery = $$v
                      },
                      expression: "searchQuery"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.withExportPdf
            ? _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("ReportFilter", {
                    attrs: { reportType: _vm.reportType },
                    on: { filtersSelected: _vm.onReportFilter }
                  })
                ],
                1
              )
            : _vm._e(),
          _c("v-spacer"),
          _c(
            "v-col",
            {
              staticClass: "align-right toolbar-list-actions",
              attrs: { cols: "4" }
            },
            [
              _vm.withFilter
                ? _c(
                    "v-btn",
                    {
                      class: { "colored-button": !_vm.allClear, "mr-2": true },
                      attrs: { small: "", outlined: "", color: "secondary" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.onFilter($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("buttons.filter")) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.withInvoicing
                ? _c(
                    "v-btn",
                    {
                      attrs: { small: "", outlined: "", color: "secondary" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.onInvoicing($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("buttons.invoicing")) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.withAdd
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mx-4",
                      attrs: { color: "accent", small: "" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.onAdd($event)
                        }
                      }
                    },
                    [
                      _c("v-icon", [_vm._v(_vm._s(_vm.$t("icons.add")))]),
                      _vm._v("\n         \n        "),
                      _vm.tagName !=
                      _vm.Enums.TAG_NAME.ATTI_AMMINISTRAZIONI_ENTI
                        ? _c("div", [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("buttons.create")) +
                                "\n        "
                            )
                          ])
                        : _c("div", [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("buttons.create_act")) +
                                "\n        "
                            )
                          ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.withAddDetermina
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mx-4",
                      attrs: { color: "accent", small: "" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.onAddProceeding(
                            _vm.Enums.TAG_NAME.DETERMINE_DECISIONI_CONTRARRE
                          )
                        }
                      }
                    },
                    [
                      _c("v-icon", [_vm._v(_vm._s(_vm.$t("icons.add")))]),
                      _vm._v(
                        "\n         \n          " +
                          _vm._s(_vm.$t("buttons.create_determina")) +
                          "\n      "
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.withExportPdf
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mx-4",
                      attrs: { color: "accent", small: "" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.onExportPdf($event)
                        }
                      }
                    },
                    [
                      _c("v-icon", [
                        _vm._v(_vm._s(_vm.$t("icons.export_pdf")))
                      ]),
                      _vm._v(
                        "\n         \n        " +
                          _vm._s(_vm.$t("buttons.export_pdf")) +
                          "\n      "
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }