<template>
  <v-container fluid class="grey lighten-4 title">
    <div
      v-if="mode == Enums.FORM_MODE.CREATE || mode == Enums.FORM_MODE.UPDATE"
    >
      <v-card-title class="form-title title">{{
        this.formTitle | capitalize
      }}</v-card-title>
      <v-form class="form-body" @submit.prevent="onSubmit">
        <FileUploader
          v-model="attachment"
          :fileTypeId="this.initial_file_type_id"
          :permittedTypes="this.permittedTypes"
          v-validate.immediate="'required'"
          name="fileUpload"
          :mode="mode"
          :machineName="machineName"
        >
        </FileUploader>
        <span
          :class="Settings.style.class.formFieldRequiredCaption"
          v-if="veeErrors.has('fileUpload')"
          >{{ veeErrors.first("fileUpload") }}</span
        >
        <v-row v-show="withDescription === 'true'">
          <v-text-field
            :label="$t('forms.links.description')"
            v-model="form.detail"
            name="description"
          ></v-text-field>
        </v-row>
        <!-- BUTTON -->
        <FormButtons
          @onSave="onSubmit"
          @onCancel="onCancel"
          :disabled="veeErrors.any()"
        />
      </v-form>
    </div>
    <div v-show="mode === Enums.FORM_MODE.SHOW"></div>
  </v-container>
</template>

<script>
import _ from "lodash";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { mapActions, mapGetters } from "vuex";
import FileUploader from "@/components/Attachment/FileUploader";
import FormButtons from "@/components/Shared/FormButtons";

export default {
  components: {
    FileUploader,
    FormButtons
  },
  props: {
    mode: {
      default: Enums.FORM_MODE.CREATE,
      type: String
    },
    selectedItem: Object,
    permittedTypes: String,
    withDescription: Boolean,
    withModelType: {
      type: String,
      required: true
    },
    withModelId: {
      type: String,
      required: true
    },
    machineName: {}
  },
  data() {
    return {
      progress: true,
      resourceType: this.$t("resource_types.attachment"),
      attachment: null,
      initial_file_type_id: null,
      form: {
        id: "",
        detail: "",
        name: "",
        title: "",
        file: "",
        temp_file: "",
        file_type_id: "",
        file_type: "",
        tag_ids: "",
        model_type: this.$props.withModelType,
        model_id: this.$props.withModelId
      }
    };
  },

  mixins: [SharedMixin, Enums],
  /*watch: {
    async withModelType() {
      this.form.model_type = this.$props.withModelType;
    },
    async withModelId() {
      this.form.model_id = this.$props.withModelId;
    }
  },*/
  methods: {
    ...mapActions("attachment", [
      "ajaxAttachmentDelete",
      "ajaxAttachmentCreateOrUpdate"
    ]),
    async onSubmit() {
      const validate = await this.$validator.validateAll();
      if (validate) {
        await this.submitToStore();
      } else {
        alert(this.$t("forms.common.errorValidateFields"));
      }
    },
    async submitToStore() {
      this.form.file_type_id = this.attachment.file_type_id;
      this.form.tag_ids = this.attachment.tag_ids;
      let payload = _.clone(this.form);
      if (this.mode == Enums.FORM_MODE.CREATE) {
        payload.name = this.attachment.payload.name;
        payload.title = this.attachment.payload.title;
        payload.temp_file = this.attachment.payload.file;
      }      
      await this.createOrUpdateHelper(
        this.mode,
        this.resourceType,
        this.ajaxAttachmentCreateOrUpdate,
        payload,
        payload => payload.p.name,
        payload => payload.p.name
      );
      this.$emit("formSucceed");
    },
    async onCancel() {
      this.$emit("formCancel");
    },
    setForm(row = null) {
      if (row && !_.isEmpty(row)) {
        this.form.id = row.id;
        this.form.name = row.name;
        this.form.detail = row.detail;
        this.form.file = row.file;
        this.form.file_type = row.file_type;
        this.form.file_type_id = row.file_type_id;
        this.form.tag_ids = row.taggings[0].tag_id;
        this.initial_file_type_id = row.file_type_id;
      } else if (row == null) {
        this.form.id = "";
        this.form.name = "";
        this.form.detail = "";
        this.form.file = "";
        this.form.temp_file = "";
        this.form.file_type = "";
        this.form.file_type_id = "";
        this.form.tag_ids = "";
        this.initial_file_type_id = "";
      }
    },
    handleFileUpload(e) {
      this.pickedFiles = e;
    },
    initForm() {
      if (this.mode == Enums.FORM_MODE.CREATE) {
        this.setForm();
      } else {
        this.setForm(this.$props.selectedItem);
      }
    },
    async initComp() {}
  },
  computed: {
    ...mapGetters("attachment", ["attachments", "total"]),

    Enums() {
      return Enums;
    },
    formTitle() {
      var title = "";
      switch (this.mode) {
        case Enums.FORM_MODE.CREATE:
          title = this.$t("labels.createResourceType", {
            resourceType: this.resourceType || "-"
          });
          break;
        case Enums.FORM_MODE.UPDATE:
          title = this.$t("labels.updateResourceTypeName", {
            resourceType: this.resourceType || "-",
            resourceName: this.form.name || "-"
          });
          break;

        default:
          break;
      }
      return title;
    }
  },
  created() {
    this.initForm();
    this.initComp();
  }
};
</script>

<style scoped>
.has-error {
  color: red;
}
.required label:before {
  color: #e32;
  content: "* ";
  display: inline;
}
.summary-font {
  font-size: small;
  line-height: 24px;
}
.summary-font-bold {
  font-size: small;
  font-weight: bold;
  line-height: 24px;
}
</style>
