<template>
  <div class='text-align-center'>
    <v-row class='filterTitle'> 
      <h3>Filtri</h3>
    </v-row>
    <v-row v-if="reportType==='questionario_gdpr'">
      <v-col cols="3">
        <v-autocomplete
          v-model="selected"            
          @change="getAllFiltersSelected"
          dense
          label='Questionatio caricato'
          :items="uploadFileOptions"            
          item-text="name"
          clearable
        >
        </v-autocomplete>
      </v-col>     
    </v-row>
    <v-row v-if="reportType==='expiring_agreements'">
      <v-col cols="3">
        <v-autocomplete
          v-model="customerTypeSelected"            
          @change="getAllFiltersSelected"
          dense
          label='Tipologia cliente'
          :items="customerTypesAll"            
          item-text="name"
          clearable
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="tenantSelected"            
          @change="getAllFiltersSelected"
          dense
          label='Azienda'
          :items="tenantAll"            
          item-text="name"
          item-value="id"
          clearable
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="serviceSelected"            
          @change="getAllFiltersSelected"
          dense
          label='Servizi'
          :items="servicesAll"            
          item-text="name"
          clearable
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="expirationSelected"            
          @change="getAllFiltersSelected"
          dense
          label='Scadenza'
          :items="expirationsAll"              
          item-text="name"
          item-value="value"
          clearable
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import _ from "lodash";
import moment from "moment";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { HelperService } from "@/services/helper.service";

import FilterList from "@/components/Shared/FilterList";

export default {
  components: {
    FilterList
  },
  mixins: [SharedMixin],
  data() {
    return {
      uploadFileOptions: [
        { name: 'Si', value: "YES"},
        { name: 'No', value: "NO"}
      ],
      selected: null,
      customerTypesAll: [],
      customerTypeSelected: null,
      tenantAll: [],
      tenantSelected: null,
      expirationsAll: [
        { name: 'Mese corrente', value: "CURRENT_MONTH", checked: false, enabled: false },
        { name: 'Mese prossimo', value: "FROM_NOW_TO_NEXT_MONTH", checked: false, enabled: false },
        { name: 'Tra 3 mesi', value: "FROM_NOW_TO_3_MONTHS", checked: false, enabled: false },
        { name: 'Tra 6 mesi', value: "FROM_NOW_TO_6_MONTHS", checked: false, enabled: false }
      ],
      expirationSelected: null,
      servicesAll: [],
      serviceSelected: null
    };
  },
  methods: {
    ...mapActions("customerType", ["ajaxCustomerTypeFetchAll"]),
    ...mapActions("tenant", ["ajaxTenantFetchAll"]),
    ...mapActions("service", ["ajaxServiceFetchAll"]),

    ...mapMutations("filters", ["setReportFilter", "setReportFlag"]),

    getAllFiltersSelected() {
      let uploadFileFilter = this.selected;
      let customerTypeFilter = this.customerTypeSelected;
      let tenantFilter = this.tenantSelected;
      let serviceFilter = this.serviceSelected ? [this.serviceSelected] : null;
      let expirationFilter = this.expirationSelected;
      let endDateFrom = this.getEndDateFrom(expirationFilter);
      let endDateTo = this.getEndDateTo(expirationFilter);
      
      let reportFilter = {
        uploadFileFilter,
        customerTypeFilter,
        tenantFilter,
        serviceFilter,
        endDateFrom,
        endDateTo
      }

      this.$emit(
        "filtersSelected",
        reportFilter
      );
    },
    async getCustomerTypes() {
      let customerTypesArray = this.customerTypesAll;
      await this.ajaxCustomerTypeFetchAll();
      if (this.customerTypes) {
        this.customerTypes.forEach(function(customerType) {
          customerTypesArray.push({
            value: customerType.name,
            name: customerType.name
          });
        });
      }/*
      customerTypesArray.unshift({
        id: null,
        name: this.$t("filters.all"),
        all: true,
        checked: false,
        enabled: false
      });*/
      this.customerTypesAll = customerTypesArray;
    },
    async getTenants() {
      let tenantArray = this.tenantAll;
      await this.ajaxTenantFetchAll();
      if (this.tenants) {
        this.tenants.forEach(function(tenant) {
          tenantArray.push({
            id: tenant.id,
            value: tenant.name,
            name: tenant.name
          });
        });
      }/*
      tenantArray.unshift({
        name: this.$t("filters.all"),
        all: true,
        checked: false,
        enabled: false
      });*/
      this.tenantAll = tenantArray;
    },
    async getServices() {
      let payloadFAP = HelperService.buildFilterAndPagination({
        pagination: {
          itemsPerPage: 100
        }
      });
      await this.ajaxServiceFetchAll(payloadFAP);

      let servicesArray = [];
      if (this.services) {
        this.services.forEach(function(service) {
          servicesArray.push({
            machineName: service.machine_name,
            name: service.machine_name
          });
        });
      }
      this.servicesAll = _.orderBy(servicesArray, "machineName");
    },
    getEndDateFrom(expiration) {
      if (expiration && expiration !== "ALL") {
        return new moment().startOf("month").format("YYYY-MM-DD");
      }
      return null;
    },
    getEndDateTo(expiration) {
      if (expiration === "CURRENT_MONTH") {
        return new moment().endOf("month").format("YYYY-MM-DD");
      } else if (expiration === "FROM_NOW_TO_NEXT_MONTH") {
        return new moment()
          .add(1, "M")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (expiration === "FROM_NOW_TO_3_MONTHS") {
        return new moment()
          .add(2, "M")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (expiration === "FROM_NOW_TO_6_MONTHS") {
        return new moment()
          .add(5, "M")
          .endOf("month")
          .format("YYYY-MM-DD");
      }
      return null;
    },
  },
  filters: {},
  computed: {
    ...mapGetters("filters", ["report_filter", "report_flag"]),
    ...mapGetters("customerType", ["customerTypes"]),
    ...mapGetters("tenant", ["tenants"]),
    ...mapGetters("service", ["services"]),

    Enums() {
      return Enums;
    }
  },
  props: {
    reportType: String
  },
  watch: {
  },
  async mounted() {
    await this.getCustomerTypes();
    await this.getTenants();
    await this.getServices();

    this.getAllFiltersSelected();
  }
};
</script>

<style scoped>
.dateComponent {
  border: 1px solid grey;
  margin-left: 15px;
  margin-right: 120px;
}
.filterTitle {
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>
