var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "form-container title", attrs: { fluid: "" } },
    [
      _c(
        "div",
        [
          _c(
            "v-form",
            {
              staticClass: "form-body",
              attrs: { "data-vv-scope": "invoicingData" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.onSubmit()
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("forms.invoicing.total_amount"),
                      value: _vm.getTotalAmount(),
                      name: "total_amount",
                      disabled: ""
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("forms.invoicing.agreement_duration"),
                      value: _vm.invoice_data.agreement_duration,
                      name: "agreement_duration",
                      disabled: ""
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("forms.invoicing.state_billing"),
                      value: _vm.getEnumTranslationFor(
                        "invoice_data",
                        _vm.invoice_data.state_billing
                      ),
                      name: "state_billing",
                      disabled: ""
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("forms.invoicing.billing_period"),
                      name: "billing_period",
                      disabled: _vm.disabledUpdate
                    },
                    model: {
                      value: _vm.invoice_data.billing_period,
                      callback: function($$v) {
                        _vm.$set(_vm.invoice_data, "billing_period", $$v)
                      },
                      expression: "invoice_data.billing_period"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.enabledValues,
                      "item-text": "label",
                      "item-value": "value",
                      label: _vm.$t("forms.invoicing.early"),
                      name: "early",
                      disabled: _vm.disabledUpdate
                    },
                    model: {
                      value: _vm.invoice_data.early,
                      callback: function($$v) {
                        _vm.$set(_vm.invoice_data, "early", $$v)
                      },
                      expression: "invoice_data.early"
                    }
                  })
                ],
                1
              ),
              _c("FormButtons", {
                attrs: { disabled: _vm.veeErrors.any("invoicingData") },
                on: { onSave: _vm.onSubmit, onCancel: _vm.onCancel }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }