<template>
  <v-container>
    <v-card v-if="this.listMode == Enums.LIST_MODE.TABBED">
      <ToolbarTab
        :subtitle="$tc('labels.found_elements', this.total)"
      ></ToolbarTab>

      <BaseGrid
        tableName="invoice-periods"
        :headers="this.tableData.headers"
        :items="this.tableData.items"
        :pagination="this.tableOptions"
        @onPaginationChanged="handlePaginationChanged"
        :totalLength="total"
        :serverItems="false"
      ></BaseGrid>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import SharedMixin from "@/mixins/shared";
import _ from "lodash";
import ToolbarTab from "@/components/Shared/ToolbarTab";
import BaseGrid from "@/components/Shared/BaseGrid";
import Enums from "@/mixins/enums";

const computed = {
  _() {
    return _;
  },
  Enums() {
    return Enums;
  },
  invoicePeriodsList() {
    return this.invoicePeriods;
  }
};

export default {
  components: {
    ToolbarTab,
    BaseGrid
  },
  props: {
    selectedItem: Object,
    /*withModelType: {
      required: false,
      type: String
    },
    withModelId: {
      required: false,
      type: String
    },*/
    listMode: {
      default: Enums.LIST_MODE.TABBED,
      type: String
    },
    onReloadPage: {}
  },
  data() {
    return {
      resourceType: this.$t("resource_types.invoice_period"),
      disabledOption: Enums.INVOICE_PERIOD_DISABLED_OPTION,
      search: "",
      filter_criteria: {},
      invoiceFormKey: 0,
      invoicePeriods: [],
      total: 0,
      tableData: { headers: [], items: [] },
      tableOptions: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["period"],
        sortDesc: [true]
      }
    };
  },
  mixins: [Enums, SharedMixin],
  watch: {
    selectedItem() {
      this.fetchData();
    }
  },
  methods: {
    ...mapActions("invoicePeriod", ["ajaxInvoicePeriodUpdate"]),
    handleAdd() {},
    async fetchData() {
      if (this.selectedItem) {
        this.invoicePeriods = this.selectedItem.invoice_periods;
      } else this.invoicePeriods = [];
      this.total = this.invoicePeriods.length;
      this.initTable();
    },
    initTable() {
      this.tableData.headers = this.mapHeaders();
      this.tableData.items = this.mapItems();
    },
    mapHeaders() {
      const tableHeaders = [];
      tableHeaders.push({
        text: "Period",
        value: "period",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Name",
        value: "name",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Price",
        value: "price",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Notice",
        value: "state",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Enabled",
        value: "enabled",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Option",
        value: "option",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Actions",
        value: "actions",
        sortable: null,
        align: "center"
      });
      return tableHeaders;
    },
    mapItems() {
      const map = _.map(this.invoicePeriodsList, item => {
        let meta = JSON.parse(item.metadata);
        if (meta == null) meta = {};
        item.fields = {
          period: { data: item.period, dataType: "text", css: "text-xs-left" },
          name: { data: item.name, dataType: "text", css: "text-xs-left" },
          price: { data: item.price, dataType: "text", css: "text-xs-left" },
          state: {
            data: this.getEnumTranslationFor("invoice_periods", item.state),
            dataType: "text",
            css: "text-xs-left"
          },
          enabled: {
            data: item.enabled ? this.$t("labels.yes") : this.$t("labels.no"),
            dataType: "text",
            css: "text-xs-left"
          },
          option: {
            data: this.getEnumTranslationFor(
              "invoice_periods",
              meta.disabled_reason
            ),
            dataType: "text",
            css: "text-xs-left"
          }
        };
        item.actions = {
          gift: {
            tooltip: this.$t("labels.gift"),
            actionType: "custom",
            callback: item => {
              this.onChange(item, this.disabledOption.OMAGGIO);
            },
            namedRoot: "agreementDetails",
            namedRootId: item.id,
            icon: this.$t("icons.gift"),
            css: "text-xs-left",
            disabled: !item.enabled || item.state != "NOT_SENT"
          },
          redistribute: {
            tooltip: this.$t("labels.redistribute"),
            actionType: "custom",
            callback: item => {
              this.onChange(item, this.disabledOption.RIDISTRIBUITO);
            },
            namedRoot: "agreementDetails",
            namedRootId: item.id,
            icon: this.$t("icons.tune"),
            css: "text-xs-left",
            disabled: !item.enabled || item.state != "NOT_SENT"
          }
        };
        return item;
      });
      return map;
    },
    async onChange(invoice_period, disabled_option) {
      let metadata = {
        disabled_reason: disabled_option
      };
      let new_invoice_period = {
        id: invoice_period.id,
        agreement_id: invoice_period.agreement_id,
        enabled: false,
        metadata
      };
      let message = this.$t("dialogs.monthly_disable1");
      if (disabled_option === this.disabledOption.OMAGGIO) {
        message = `${message} \n ${this.$t("dialogs.monthly_disable2")}`;
      } else {
        message = `${message} \n ${this.$t("dialogs.monthly_disable3")}`;
      }

      this.$confirm({
        message: message,
        button: {
          no: this.$t("dialogs.no"),
          yes: this.$t("dialogs.yes")
        },
        callback: async confirm => {
          if (confirm) {
            await this.createOrUpdateHelper(
              Enums.FORM_MODE.UPDATE,
              this.resourceType,
              this.ajaxInvoicePeriodUpdate,
              new_invoice_period,
              () => "",
              () => ""
            );
            this.onReloadPage();
          }
        }
      });
    },
    getNumberOfMontly(period) {
      let invoice_periods = this.$props.selectedItem.invoice_periods;
      return invoice_periods.filter(item => item.period == period).length;
    },
    handlePaginationChanged(paginationOpts) {
      this.tableOptions = paginationOpts;
      this.fetchData();
    },

    debounceSearch: _.debounce(async function(val) {
      this.search = val;
      await this.fetchData();
    }, process.env.VUE_APP_DEBOUNCE_TIME)
  },

  async created() {
    await this.fetchData();
  },
  computed
};
</script>
