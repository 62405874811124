var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filter-list" }, [
    _vm.autocomplete && _vm.allowMultiple
      ? _c(
          "div",
          [
            _c("v-autocomplete", {
              attrs: {
                autocomplete: "off",
                items: _vm.value,
                "item-text": "name",
                "return-object": "",
                multiple: "",
                "search-input": _vm.search,
                "no-filter": "",
                clearable: ""
              },
              on: {
                change: _vm.setFilterAutocMultiple,
                "update:searchInput": function($event) {
                  _vm.search = $event
                },
                "update:search-input": function($event) {
                  _vm.search = $event
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "item",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-list-item-action",
                          [
                            item.checked
                              ? _c("v-icon", [_vm._v("check_box")])
                              : _c("v-icon", [
                                  _vm._v("check_box_outline_blank")
                                ])
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", {
                              domProps: { innerHTML: _vm._s(item.name) }
                            }),
                            "subtitle" in item
                              ? _c("v-list-item-subtitle", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(item.subtitle) +
                                      "\n          "
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                785291121
              ),
              model: {
                value: _vm.selectedItems,
                callback: function($$v) {
                  _vm.selectedItems = $$v
                },
                expression: "selectedItems"
              }
            })
          ],
          1
        )
      : _vm.autocomplete && !_vm.allowMultiple
      ? _c(
          "div",
          [
            _c("v-autocomplete", {
              attrs: {
                autocomplete: "off",
                items: _vm.value,
                "item-text": "name",
                "return-object": "",
                "search-input": _vm.search,
                "no-filter": "",
                clearable: ""
              },
              on: {
                change: _vm.setFilterAutoc,
                "update:searchInput": function($event) {
                  _vm.search = $event
                },
                "update:search-input": function($event) {
                  _vm.search = $event
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-list-item-action",
                        [
                          item.checked
                            ? _c("v-icon", [_vm._v("check_box")])
                            : _c("v-icon", [_vm._v("check_box_outline_blank")])
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", {
                            domProps: { innerHTML: _vm._s(item.name) }
                          }),
                          "subtitle" in item
                            ? _c("v-list-item-subtitle", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.subtitle) +
                                    "\n          "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.selectedItem,
                callback: function($$v) {
                  _vm.selectedItem = $$v
                },
                expression: "selectedItem"
              }
            })
          ],
          1
        )
      : _vm.showAll
      ? _c(
          "div",
          [
            _vm._l(_vm.value, function(item, i) {
              return _c(
                "v-list-item",
                { key: i, attrs: { dense: "" } },
                [
                  [
                    _c(
                      "v-list-item-action",
                      [
                        _c("v-checkbox", {
                          attrs: { color: "primary" },
                          on: {
                            change: function($event) {
                              return _vm.setFilterCheckbox(item)
                            }
                          },
                          model: {
                            value: item.checked,
                            callback: function($$v) {
                              _vm.$set(item, "checked", $$v)
                            },
                            expression: "item.checked"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-content",
                      [_c("v-list-item-title", [_vm._v(_vm._s(item.name))])],
                      1
                    )
                  ]
                ],
                2
              )
            }),
            _c(
              "v-col",
              [
                _vm.showButton
                  ? _c(
                      "v-btn",
                      {
                        attrs: { color: "primary", "x-small": "" },
                        on: {
                          click: function($event) {
                            _vm.showAll = false
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("filters.hide")))]
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          2
        )
      : _c(
          "div",
          [
            _vm._l(_vm.value.slice(0, 5), function(item, i) {
              return _c(
                "v-list-item",
                { key: i, attrs: { dense: "" } },
                [
                  [
                    _c(
                      "v-list-item-action",
                      [
                        _c("v-checkbox", {
                          attrs: { color: "primary" },
                          on: {
                            change: function($event) {
                              return _vm.setFilterCheckbox(item)
                            }
                          },
                          model: {
                            value: item.checked,
                            callback: function($$v) {
                              _vm.$set(item, "checked", $$v)
                            },
                            expression: "item.checked"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-content",
                      [_c("v-list-item-title", [_vm._v(_vm._s(item.name))])],
                      1
                    )
                  ]
                ],
                2
              )
            }),
            _c(
              "v-col",
              [
                !_vm.alwaysShowAllComputed
                  ? _c(
                      "v-btn",
                      {
                        attrs: { color: "primary", "x-small": "" },
                        on: {
                          click: function($event) {
                            ;(_vm.showAll = true), (_vm.showButton = true)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("filters.showAll")))]
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          2
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }