<template>
  <div>
    <div class="color-footer">
      {{ this.$t("colorGrid.year") }}: {{ this.selectedYear }}
      <v-btn icon large color="primary" @click="onLeft">
        <v-icon dark>{{ $t("icons.left") }}</v-icon>
      </v-btn>
      <v-btn icon large color="primary" @click="onCurrent">
        <v-icon dark>{{ $t("icons.currentYear") }}</v-icon>
      </v-btn>
      <v-btn icon large color="primary" @click="onRight">
        <v-icon dark>{{ $t("icons.right") }}</v-icon>
      </v-btn>
      <span v-if="!singleRow">
        {{
          tableOptions.page * tableOptions.itemsPerPage +
            1 -
            tableOptions.itemsPerPage
        }}-{{ Math.min(tableOptions.page * tableOptions.itemsPerPage, total) }}
        {{ this.$t("colorGrid.of") }} {{ total }}
        <v-btn icon large color="primary" @click="onUp">
          <v-icon dark>{{ $t("icons.up") }}</v-icon>
        </v-btn>
        <v-btn icon large color="primary" @click="onDown">
          <v-icon dark>{{ $t("icons.down") }}</v-icon>
        </v-btn>
      </span>
    </div>
    <v-progress-linear
      v-if="loading"
      color="info"
      height="5"
      indeterminate
    ></v-progress-linear>
    <table class="color-table">
      <thead>
        <tr>
          <th
            v-for="(header, i) in tableHeaders"
            @click="sortBy(i)"
            :class="{
              'color-header': header.month == -1,
              active: sortKey == i,
              'highlight-col':
                header.month == currentMonth && selectedYear == currentYear
            }"
            :key="i"
          >
            <div
              v-if="header.month == currentMonth && selectedYear == currentYear"
              class="highlight-col-before"
              :style="'height: ' + 56 * (items.length + 1) + 'px'"
            ></div>
            {{ header.label }}
            <span
              v-if="header.sortable"
              class="arrow"
              :class="sortOrders[i] > 0 ? 'asc' : 'dsc'"
            >
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, i) in rows" :key="i">
          <td v-for="(field, k) in items[i].fields" :key="'-' + k">
            <div class="color-content">
              <span v-if="field.dataType == 'text'">{{ field.data }}</span>
              <a
                v-if="field.dataType == 'link'"
                @click="field.linkCallback(items[i])"
                >{{ field.data }}</a
              >
            </div>
          </td>
          <template v-for="(cell, j) in row.cells">
            <td
              :id="'cell' + i + '_' + j"
              :key="j"
              v-if="!cell.skip"
              :colspan="cell.span"
              :class="[
                'color-' + cell.color,
                {
                  'left-cap': cell.color > 0 && cell.left_cap,
                  'right-cap': cell.color > 0 && cell.right_cap,
                  'with-border': cell.color > 0
                }
              ]"
              @mouseover="handleHover(cell, true)"
              @mouseleave="handleHover(cell, false)"
            >
              {{ cell.label }}
              <slot
                v-if="cell.color > 0"
                :origin="cell.origin"
                name="cell-content"
              ></slot>
              <v-tooltip
                top
                v-if="cell.tooltip"
                v-model="showTooltip"
                :attach="'#cell' + i + '_' + j"
              >
                <span v-html="cell.content"></span>
              </v-tooltip>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: [
    "elementsList",
    "total",
    "headers",
    "items",
    "tableOptions",
    "singleRow",
    "loading",
    "tableName"
  ],
  data: function() {
    var sortOrders = [];
    for (var i = 0; i < 13; i++) {
      sortOrders.push(1);
    }

    return {
      selectedYear: new Date().getFullYear(),
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth(),
      rows: [],
      tableHeaders: [],
      sortKey: -1,
      sortOrders: sortOrders,
      showTooltip: true
    };
  },
  mounted() {
    this.renderRows();
  },
  watch: {
    items() {
      this.renderRows();
    }
  },
  computed: {},
  filters: {
    capitalize: function(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  },
  methods: {
    handleHover: function(cell, val) {
      if ("content" in cell) {
        cell.tooltip = val;
        this.rows = _.cloneDeep(this.rows);
      }
    },
    debounceEmit: _.debounce(function() {
      this.$emit("onPageChange", this.tableOptions);
    }, process.env.VUE_APP_DEBOUNCE_TIME),
    sortBy: function(key) {
      if (this.tableHeaders[key].sortable) {
        if (this.sortKey != key) this.sortKey = key;
        else {
          let ar = _.cloneDeep(this.sortOrders);
          ar[key] = ar[key] * -1;
          this.sortOrders = ar;
        }
        this.tableOptions.sortBy = [this.tableHeaders[key].value];
        this.tableOptions.sortDesc = [this.sortOrders[key] == -1];
        this.debounceEmit();
      }
    },
    onUp: function() {
      if (this.tableOptions.page > 1) {
        this.tableOptions.page--;
        this.debounceEmit();
      }
    },
    onDown: function() {
      if (
        this.tableOptions.page <
        this.total / this.tableOptions.itemsPerPage
      ) {
        this.tableOptions.page++;
        this.debounceEmit();
      }
    },
    onLeft: function() {
      this.selectedYear--;
      this.renderRows();
    },
    onRight: function() {
      this.selectedYear++;
      this.renderRows();
    },
    onCurrent: function() {
      this.selectedYear = this.currentYear;
      this.renderRows();
    },
    renderRows: function() {
      this.tableHeaders = [];
      this.headers.forEach(
        function(value) {
          this.tableHeaders.push({
            label: this.$t("headers." + this.tableName + "." + value.value),
            sortable: !this.singleRow,
            value: value.value,
            month: -1
          });
        }.bind(this)
      );
      for (var i = 0; i < 12; i++) {
        this.tableHeaders.push({
          label: i + 1 + "/" + this.selectedYear,
          sortable: false,
          month: i
        });
      }

      this.rows = [];

      this.elementsList.forEach(
        function(value) {
          var cells = [];
          for (var m = 0; m < 12; m++) cells.push({ color: 0, skip: false });

          var row = {
            label: value.label
          };
          value.elements.forEach(
            function(value2) {
              var j = (value2.date.getFullYear() - this.selectedYear) * 12;
              j += value2.date.getMonth() - 1;
              var span = value2.span;
              var left_cap = true;
              var right_cap = true;
              if (j < 0) {
                span = span + j;
                j = 0;
                left_cap = false;
              }
              if (j + span - 1 >= 12) {
                span -= j + span - 1 - 11;
                right_cap = false;
              }
              if (span > 0) {
                cells[j] = {
                  color: value2.color,
                  label: value2.label,
                  span,
                  left_cap,
                  right_cap,
                  skip: false,
                  content: value2.content,
                  origin: value2.origin
                };
                for (var k = 1; k < span; k++) cells[j + k] = { skip: true };
              }
            }.bind(this)
          );
          row.cells = cells;
          this.rows.push(row);
        }.bind(this)
      );
    }
  }
};
</script>

<style>
.color-table {
  border: 2px solid #2196f3;
  border-radius: 3px;
  background-color: #fff;
  width: 100%;
  border-collapse: separate !important;
  table-layout: fixed;
  border-spacing: 0px;
}

.color-table th {
  background-color: #2196f3;
  color: rgba(255, 255, 255, 0.66);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 24px;
}

.color-header {
  width: 15%;
}

.color-content {
  height: 44px;
  overflow: hidden;
}

.color-table td {
  background-color: #f9f9f9;
}

.color-table th,
.color-table td {
  min-width: 120px;
  height: 56px;
  padding: 4px 4px;
  text-align: center;
  border: 2px solid transparent;
  position: relative;
}

.color-table th.active {
  color: #fff;
}

.color-table th.active .arrow {
  opacity: 1;
}

.arrow {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  margin-left: 5px;
  opacity: 0.66;
}

.arrow.asc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #fff;
}

.arrow.dsc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #fff;
}

.color-0 {
  background-color: #ddd !important;
}
.color-1 {
  background-color: #f77a7a !important;
  cursor: pointer;
}
.color-2 {
  background-color: #f7f77a !important;
  cursor: pointer;
}
.color-3 {
  background-color: #7af77a !important;
  cursor: pointer;
}

.color-table td.with-border {
  border-top-color: #666;
  border-bottom-color: #333;
}
.color-table td.left-cap {
  border-left-color: #666;
}
.color-table td.right-cap {
  border-right-color: #333;
}
.color-footer {
  text-align: right;
}

.color-table .highlight-col {
  position: relative;
}
.color-table .highlight-col-before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: -2px;
  /*bottom:-560px;*/ /* i.e. minus of any value that is greater than the height of your table */
  background-image: url("../../assets/diag.png");
  background-repeat: repeat;
  z-index: 6;
  opacity: 1;
  pointer-events: none;
}

.v-tooltip__content {
  position: absolute;
  left: 50% !important;
  top: 36px !important;
  width: 240px !important;
  margin-left: -240px;
}
</style>
