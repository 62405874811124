<template>
  <div>
    <InvoiceFilter
      @filtersSelected="handleFiltersSelected"
      ref="drawer"
      @drawerChanged="handleDrawer"
    ></InvoiceFilter>
    <div :class="{ 'center-panel': drawer_flag }">
      <v-card v-if="this.listMode === Enums.LIST_MODE.FULL">
        <ToolbarList
          :title="$t('labels.invoices')"
          :subtitle="$tc('labels.found_elements', this.total)"
          :withAdd="false"
          :withSearch="true"
          :withFilter="true"
          @onSearch="searchFunc"
          @onFilter="$refs.drawer.open()"
          :allClear="allClear"
        ></ToolbarList>
        <BaseGrid
          tableName="invoices"
          :headers="this.tableData.headers"
          :items="this.tableData.items"
          :totalLength="this.total"
          :pagination="this.tableOptions"
          :loading="this.onLoading"
          :withEdit="true"
          @onEdit="handleEdit"
          @onPaginationChanged="handlePaginationChanged"
        ></BaseGrid>
      </v-card>
      <v-container v-if="this.listMode === Enums.LIST_MODE.TABBED">
        <v-card>
          <ToolbarTab
            :subtitle="$tc('labels.found_elements', this.total)"
            :withAdd="true"
            @onAdd="generateInvoice"
          ></ToolbarTab>
          <BaseGrid
            tableName="invoices"
            :headers="this.tableData.headers"
            :items="this.tableData.items"
            :pagination="this.tableOptions"
            @onPaginationChanged="handlePaginationChanged"
            :withEdit="true"
            @onEdit="handleEdit"
            :totalLength="total"
            :serverItems="false"
          ></BaseGrid>
        </v-card>
      </v-container>
    </div>
    <v-dialog
      v-model="formDialog"
      persistent
      content-class="edit-form-dialog"
    >
      <InvoiceForm
        v-if="formDialog"
        :mode="formMode"
        :selectedItem="formItem"
        @formSucceed="handleFormSucceed"
        @formCancel="handleFormCancel"
      ></InvoiceForm>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import ToolbarList from "@/components/Shared/ToolbarList";
import ToolbarTab from "@/components/Shared/ToolbarTab";
import BaseGrid from "@/components/Shared/BaseGrid";
import InvoiceForm from "@/components/Invoice/InvoiceForm";
import FormsHelper from "@/mixins/formsHelper";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { HelperService } from "@/services/helper.service";
import InvoiceFilter from "@/components/Filter/InvoiceFilter";

const computed = {
  ...mapGetters("invoice", ["invoices", "total", "onLoading"]),
  ...mapGetters("customer", ["customers"]),
  ...mapGetters("agreement", ["agreements"]),
  _() {
    return _;
  },
  Enums() {
    return Enums;
  },
  invoiceList() {
    return this.invoices;
  },
  showPost() {}
};

export default {
  name: "invoiceList",
  components: {
    ToolbarList,
    ToolbarTab,
    BaseGrid,
    InvoiceForm,
    InvoiceFilter,
  },
  props: {
    listMode: {
      default: Enums.LIST_MODE.FULL,
      type: String
    }
  },
  data() {
    return {
      filter: {},
      allClear: true,
      resourceType: this.$t("resource_types.invoice"),
      formMode: "",
      search: "",
      filter_criteria: {},
      tableData: { headers: [], items: [] },
      tableOptions: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["created_at"],
        sortDesc: [false]
      },
      drawer_flag: false
    };
  },
  methods: {
    ...mapActions("invoice", ["ajaxInvoiceFetchAll"]),
    ...mapActions("customer", ["ajaxCustomerFetchAll"]),
    ...mapActions("agreement", ["ajaxAgreementFetchAll"]),

    async fetchData() {
      let payloadFAP = HelperService.buildFilterAndPagination({
        search: this.search,
        pagination: this.tableOptions,
        filter_criteria: this.filter_criteria,
        filter: this.filter
      });
      await this.ajaxInvoiceFetchAll(payloadFAP);
      await this.ajaxCustomerFetchAll();
      this.initTable();
    },
    handleDrawer(val) {
      this.drawer_flag = val;
    },
    handleFormSucceed() {
        this.closeFormDialog();
        this.fetchData();
    },
    handleFormCancel() {
      this.closeFormDialog();
    },
    async handleEdit(item) {
      this.openFormDialog(Enums.FORM_MODE.UPDATE, item);
    },
    getPeriodicity(object) {
      let periodicity_label = 'Unica';
      if (object && object.invoice_periods && object.model_type == 'agreements') {
          let invoice_periods = object.invoice_periods;
          let monthly_number = invoice_periods.filter(item => item.period == object.period).length;
          periodicity_label = this.$t('headers.invoices.period') + ': ' + object.period + ' - ' + this.$t('headers.invoices.monthly') + ': ' + monthly_number;
      }      
      return periodicity_label;
    },
    getModel(object) {        
      let model_id = object.model_id;
      let res = this.$t("resource_type." + object.model_type) + ' ';
      if (object.invoiceable && object.invoiceable.length == 1 && object.invoiceable[0].code) {
        model_id = object.invoiceable[0].code;
      }
      res = res + model_id;
      /*      
      if (object.model_type == "agreements") {
        let agreement = this.agreements.filter((item) => item.id == object.model_id);
        if (agreement && agreement.length == 1) {
          let services_array = agreement[0].services.map((item => item.machine_name));
          let services = services_array.join("-");
          console.log('services: ', services);
          res = res + ' - ( ' + services + ' )';
        }
      }*/
      return res;      
    },
    getCustomer(item){
      let customer_id = null;
      let customer_name = "-";
      if(item.invoiceable && item.invoiceable.length == 1) {
        customer_id = item.invoiceable[0].customer_id;
      }
      let customer = this.customers.filter((item) => item.id == customer_id);
      if (customer && customer.length == 1) {
        customer_name = customer[0].name + " (" + customer[0].tenant.machine_name + ")"
      }
      return customer_name;
    },
    initTable() {
      this.tableData.headers = this.mapHeaders();
      this.tableData.items = this.mapItems();
    },
    mapHeaders() {
      const tableHeaders = [];      
      tableHeaders.push({
        text: "Code",
        value: "code",
        sortable: false,
        align: "start"
      });
      tableHeaders.push({
        text: "Period",
        value: "period",
        align: "start",
        sortable: true
      });
      tableHeaders.push({
        text: "Model type",
        value: "model_type",
        align: "start",
        sortable: false
      });
      tableHeaders.push({
        text: "Customer",
        value: "customer",
        align: "start",
        sortable: false
      });
      tableHeaders.push({
        text: "Price",
        value: "price",
        align: "start",
        sortable: false
      });
      tableHeaders.push({
        text: "State",
        value: "state",
        align: "start",
        sortable: false
      });
      tableHeaders.push({
        text: "Email_sent_count",
        value: "email_sent_count",
        align: "start",
        sortable: false
      });
      tableHeaders.push({
        text: "Cashed",
        value: "cashed",
        align: "start",
        sortable: false
      });
      tableHeaders.push({
        text: "Create Date",
        value: "create_date",
        align: "start",
        sortable: false
      });
      tableHeaders.push({
        text: "Actions",
        value: "actions",
        align: "start",
        sortable: false
      });
      return tableHeaders;
    },
    mapItems() {
      const map = _.map(this.invoiceList, item => {
        let cashed = item.cashed ? "CASHED" : "NOT_CASHED";
        item.fields = {
          code: {
            data: item.code,
            dataType: "text",
            css: "text-xs-left"
          },
          periodicity: {
            data: this.getPeriodicity(item),
            dataType: "text",
            css: "text-xs-left"
          },
          model_type: {
            data: this.getModel(item),
            dataType: "text",
            css: "text-uppercase"
          },
          customer: {
            data: this.getCustomer(item),
            dataType: "text",
            css: "text-uppercase"
          },
          price: {
            data: '€ '+ item.price,
            dataType: "text",
            css: "text-xs-left"
          },
          state: {
            data: this.getEnumTranslationFor("invoices", item.state),
            dataType: "text",
            css: "text-xs-left"
          },
          email_sent_count: {
            data: item.email_sent_count,
            dataType: "text",
            css: "text-xs-left"
          },
          cashed: {
            data: this.getEnumTranslationFor("invoices", cashed),
            dataType: "text",
            css: "text-xs-left"
          },
          create_date: {
            data: item.created_at,
            dataType: "datetime",
            css: "text-xs-left"
          }
        };
        return item;
      });
      return map;
    },
    async handleFiltersSelected(
      allClear,
      stateFilter,
      cashFilter,
      modelTypeFilter,
      customerFilter,
      startDateFilter,
      endDateFilter
    ) {
      this.goBack();
      this.allClear = allClear;
      this.filter_criteria.state = stateFilter;
      this.filter_criteria.cashed = cashFilter;
      this.filter_criteria.model_type = modelTypeFilter;
      this.filter_criteria.customer_id = customerFilter;
      this.filter = {
        gt: { created_at: startDateFilter }
      }
      if(endDateFilter)
        this.filter.lt = { created_at: endDateFilter }
      await this.fetchData();
      this.scrollToTop();
    },
    scrollToTop() {
      this.$vuetify.goTo(0);
    },
    goBack() {
      this.tableOptions = { ...this.tableOptions, page: 1 };
    },
    searchFunc: async function(searchQuery) {
      this.search = searchQuery;
      await this.fetchData();
    },
    handlePaginationChanged(paginationOpts) {
      this.tableOptions = paginationOpts;
      this.fetchData();
    }
  },
  mixins: [FormsHelper, Enums, SharedMixin],
  async created() {},
  computed
};
</script>

<style>
.colored {
  color: #00f;
}
</style>
