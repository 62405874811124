var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FetchAutocomplete", {
    attrs: {
      value: _vm.value,
      itemText: _vm.itemText,
      itemValue: _vm.itemValue,
      returnObject: _vm.returnObject,
      label: _vm.label,
      disabled: _vm.disabled,
      fetchFunction: _vm.fetchFunction,
      getter: _vm.getter,
      withModelId: _vm.withModelId,
      mode: _vm.mode
    },
    on: {
      input: function($event) {
        return _vm.$emit("input", $event)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function(ref) {
          var item = ref.item
          return [
            _vm._v(
              _vm._s(item.name) +
                " - " +
                _vm._s(item.tenant.name) +
                " -\n    " +
                _vm._s(item.mechanographyc_code)
            )
          ]
        }
      },
      {
        key: "item",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "v-list-item-content",
              [
                _c("v-list-item-title", {
                  domProps: { innerHTML: _vm._s(item.name) }
                }),
                _c("v-list-item-subtitle", [
                  _vm._v(
                    "\n        " +
                      _vm._s(item.tenant.name) +
                      " - " +
                      _vm._s(item.mechanographyc_code) +
                      " (" +
                      _vm._s(item.city) +
                      "\n        - " +
                      _vm._s(item.province) +
                      ")\n      "
                  )
                ])
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }