import Vue from "vue";

const ProceedingService = {
  async fetchOne(id) {
    if (id == null) {
      return null;
    }
    try {
      const data = await Vue.$jsonApi.find("proceeding", id, {
        include:
          "attachments"
      });
      return data.data[0];
    } catch (error) {
      console.log("fetchOne error: ", error);
      return { error: error };
    }
  },
  async fetchAll(searchFilterPagination) {
    try {
      const res = await Vue.$jsonApi.findAll(
        "proceedings",
        searchFilterPagination
      );
      return res;
    } catch (error) {
      console.log("fetchAll error: ", error);
      return { error: error };
    }
  },
  async createOrUpdate(payload) {
    try {
      if (payload.id == "" || payload.id == null) {
        return await Vue.$jsonApi.create("proceeding", payload);
      } else {
        return await Vue.$jsonApi.update("proceeding", payload);
      }
    } catch (error) {
      console.log("createOrUpdate error: ", error);
      return { error: error };
    }
  },
  async delete(id) {
    if (id == null) {
      return null;
    }
    try {
      const data = await Vue.$jsonApi.destroy("proceeding", id);
      return data;
    } catch (error) {
      console.log("delete error: ", error);
      return { error: error };
    }
  }
};

export { ProceedingService };
