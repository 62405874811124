/*  eslint no-shadow: ["error", { "allow": ["state"] }] */
/*  eslint no-param-reassign: ["error", { "props": false }] */
import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";
import { AttachmentTypeService } from "@/services/attachmentType.service";
import { HelperService } from "@/services/helper.service";

Vue.use(Vuex);

const state = {
  attachmentTypes: [],
  attachmentTypesTemp: [],
  selected: {},
  loading: false,
  totalCount: 0
};

const getters = {
  attachmentTypes: state => state.attachmentTypes,
  attachmentTypesTemp: state => state.attachmentTypes,
  selectedAttachmentType: state => state.selected,
  onLoading: state => state.loading,
  total: state => state.totalCount,
  mappedAttachmentTypes: state => {
    const mapTypes = _.map(state.attachmentTypes, fileType => {
      return {
        value: Number(fileType.id),
        text: fileType.name,
        machineName: fileType.machine_name
      };
    });
    return mapTypes;
  },
  getTypesByIds: idsArray => {
    const subType = _.filter(getters.mappedAttachmentTypes, t =>
      idsArray.includes(t.value)
    );
    return subType;
  }
};

const mutations = {
  setSelected(state, sel) {
    state.selected = sel;
  },
  setList(state, sel) {
    state.attachmentTypes = sel;
  },
  setTemp(state, sel) {
    state.attachmentTypesTemp = sel;
  },
  setTotal(state, sel) {
    state.totalCount = Number(sel);
  },
  setLoading(state, sel) {
    state.loading = sel;
  }
};

const actions = {
  async ajaxAttachmentTypeFetchAll({ commit }, payload) {
    let machineName=payload.machineName;
    let parentId;
    if (machineName === 'TEMPLATE') {
      parentId = 30;
    }
    else if (machineName === 'ORDER') {
      parentId = 17;
    } 
    else if (machineName === 'ADMINISTRATIVE_DOCUMENT_TEMPLATE') {
      parentId = 66; // 65 dev; 67 staging; 66 production;
    } 
    else if (machineName === 'ATTI_AMMINISTRAZIONI_ENTI') {
      parentId = 50; // 56 dev; 50 staging;
    } 
    else {
      parentId = 31;
    }
    
    const _payload = { ...payload };
    if (!_payload.filter) _payload.filter = {};
    if (parentId) {
      _payload.filter.parent_id = parentId;
    }
    let searchFilterPagination = HelperService.localToJSONAPIPagination(
      _payload
    );

    await HelperService.genericFetchAll(
      commit,
      AttachmentTypeService.fetchAll,
      searchFilterPagination,
      null,
      null
    );
  },
  async ajaxAttachmentTypeFetchTemp({ commit }, payload) {
    let machineName=payload.machineName;
    let parentId;
    if (machineName === 'TEMPLATE') {
      parentId = 15;
    }
    else if (machineName === 'ORDER') {
      parentId = 17;
    } else {
      parentId = 31;
    }
    
    const _payload = { ...payload };
    if (!_payload.filter) _payload.filter = {};
    _payload.filter.parent_id = parentId;
    let searchFilterPagination = HelperService.localToJSONAPIPagination(
      _payload
    );

    await HelperService.tempFetchAll(
      commit,
      AttachmentTypeService.fetchAll,
      searchFilterPagination,
      null,
      null
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
