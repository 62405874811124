<template>
  <v-container fluid class="form-container title">
    <!-- form in create or update mode -->
    <div
      v-if="mode == Enums.FORM_MODE.CREATE || mode == Enums.FORM_MODE.UPDATE"
    >
      <v-card-title class="form-title title">{{
        this.formTitle | capitalize
      }}</v-card-title>
      <v-form class="form-body" @submit.prevent="onSubmit">
        <v-row>
          <v-col cols="6" class="form-group required">
            <v-text-field
              :label="$t('forms.customers.name')"
              v-model="form.name"
              name="name"
              v-validate.immediate="'required'"
            ></v-text-field>
            <span
              :class="Settings.style.class.formFieldRequiredCaption"
              v-if="veeErrors.has('name')"
              >{{ veeErrors.first("name") }}</span
            >
          </v-col>
          <v-col cols="6" class="form-group required">
            <v-select
              v-model="form.tenant_id"
              :items="this.tenants"
              :enabled="!this.$props.withModelId"
              item-text="name"
              item-value="id"
              :label="$t('forms.customers.tenant')"
              single-line
              clearable
              v-validate.immediate="'required'"
              name="tenant"
            ></v-select>
            <span
              :class="Settings.style.class.formFieldRequiredCaption"
              v-if="veeErrors.has('tenant')"
              >{{ veeErrors.first("tenant") }}</span
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="form-group required">
            <v-select
              v-model="form.type_id"
              :items="this.customerTypes"
              ref="customer_type"
              item-text="name"
              item-value="id"
              :label="$t('forms.customers.customer_type')"
              single-line
              clearable
              v-validate.immediate="'required'"
              name="customer_type"
              @change="onCustomerTypeChange"
            ></v-select>
            <span
              :class="Settings.style.class.formFieldRequiredCaption"
              v-if="veeErrors.has('customer_type')"
              >{{ veeErrors.first("customer_type") }}</span
            >
          </v-col>
          <v-col cols="6">
            <v-text-field
              :label="$t('forms.customers.mechanographyc_code')"
              v-model="form.mechanographyc_code"
              v-validate.immediate="'required_if:customer_type,1'"
              name="mechanographyc_code"
            ></v-text-field>
            <span
              :class="Settings.style.class.formFieldRequiredCaption"
              v-if="veeErrors.has('mechanographyc_code')"
              >{{ veeErrors.first("mechanographyc_code") }}</span
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              :label="$t('forms.customers.piva')"
              v-model="form.piva"
              v-validate.immediate="'required_if:customer_type,2'"
              name="piva"
            ></v-text-field>
            <span
              :class="Settings.style.class.formFieldRequiredCaption"
              v-if="veeErrors.has('piva')"
              >{{ veeErrors.first("piva") }}</span
            >
          </v-col>
          <v-col cols="6">
            <v-text-field
              :label="$t('forms.customers.cf')"
              v-model="form.cf"
              v-validate.immediate="'required_if:customer_type,3'"
              name="cf"
            ></v-text-field>
            <span
              :class="Settings.style.class.formFieldRequiredCaption"
              v-if="veeErrors.has('cf')"
              >{{ veeErrors.first("cf") }}</span
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" class="form-group required">
            <v-text-field
              :label="$t('forms.customers.peo')"
              v-model="form.peo"
              name="peo"
              v-validate.immediate="'required|email'"
            ></v-text-field>
            <span
              :class="Settings.style.class.formFieldRequiredCaption"
              v-if="veeErrors.has('peo')"
              >{{ veeErrors.first("peo") }}</span
            >
          </v-col>
          <v-col cols="6" class="form-group required">
            <v-text-field
              :label="$t('forms.customers.pec')"
              v-model="form.pec"
              name="pec"
              v-validate.immediate="'required|email'"
            ></v-text-field>
            <span
              :class="Settings.style.class.formFieldRequiredCaption"
              v-if="veeErrors.has('pec')"
              >{{ veeErrors.first("pec") }}</span
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              :label="$t('forms.customers.address')"
              v-model="form.address"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              :label="$t('forms.customers.city')"
              v-model="form.city"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              :label="$t('forms.customers.cap')"
              v-model="form.cap"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              :label="$t('forms.customers.province')"
              v-model="form.province"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              :label="$t('forms.customers.phone')"
              v-model="form.phone"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              :label="$t('forms.customers.website')"
              v-model="form.website"
              name="website"
              v-validate.immediate="'required'"
            ></v-text-field>
            <span
              :class="Settings.style.class.formFieldRequiredCaption"
              v-if="veeErrors.has('website')"
              >{{ veeErrors.first("website") }}</span
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              :label="$t('forms.customers.referent_surname')"
              v-model="form.referent_surname"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              :label="$t('forms.customers.referent_name')"
              v-model="form.referent_name"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              :label="$t('forms.customers.dsga')"
              v-model="form.dsga"
            ></v-text-field>
          </v-col>
        </v-row>

        <DisplaySeparator
          :as="Enums.DISPLAY_AS.SEPARATOR"
          :label="$t('labels.generalPreferences')"
        />
        <v-row>
          <v-col cols="6">
            <v-select
              :items="ticketCreationEnum"
              :label="$t('forms.customers.preferences.ticket_creation')"
              v-model="form.preferences.ticket_creation"
            ></v-select>
          </v-col>
        </v-row>

        <DisplaySeparator
          :as="Enums.DISPLAY_AS.SEPARATOR"
          :label="$t('labels.metadataScuole')"
        />
        <v-row>
          <v-col cols="6">
            <v-text-field
              :label="$t('forms.customers.metadata_scuole.dirigente_territoriale.city')"
              v-model="form.metadata_scuole.dirigente_territoriale.city"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              :label="$t('forms.customers.metadata_scuole.dirigente_territoriale.province')"
              v-model="form.metadata_scuole.dirigente_territoriale.province"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              :label="$t('forms.customers.metadata_scuole.dirigente_territoriale.email')"
              v-model="form.metadata_scuole.dirigente_territoriale.email"
              name="dirigente_territoriale_email"
              v-validate.immediate="'email'"
            ></v-text-field>
            <span
              :class="Settings.style.class.formFieldRequiredCaption"
              v-if="veeErrors.has('dirigente_territoriale_email')"
              >{{ veeErrors.first("dirigente_territoriale_email") }}</span
            >
          </v-col>
        </v-row>

        <DisplaySeparator
          :as="Enums.DISPLAY_AS.SEPARATOR"
          :label="$t('labels.gdprPreferences')"
        />
        <v-row>
          <v-col cols="6">
            <v-select
              :items="gdprPublicPageVisibilityEnum"
              :label="
                $t('forms.customers.preferences.gdpr_public_page_visibility')
              "
              v-model="form.preferences.gdpr_public_page_visibility"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-text-field
              :label="$t('forms.customers.preferences.gdpr_owncloud_password')"
              v-model="form.preferences.gdpr_owncloud_password"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              :label="$t('forms.customers.preferences.gdpr_owncloud_folder')"
              v-model="form.preferences.gdpr_owncloud_folder"
            ></v-text-field>
          </v-col>
        </v-row>
        <FormButtons
          @onSave="onSubmit"
          @onCancel="onCancel"
          :disabled="veeErrors.any()"
        />
      </v-form>
    </div>
    <!-- form in show mode -->
    <div v-show="mode === Enums.FORM_MODE.SHOW" class="form-body-show">
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.customers.tenant')"
        :value="this.localTenant.name"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.customers.id')"
        :value="this.form.id"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.customers.code')"
        :value="this.form.code"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.customers.name')"
        :value="this.form.name"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.customers.customer_type')"
        :value="this.localCustomerType.name"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.customers.mechanographyc_code')"
        :value="this.form.mechanographyc_code"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.customers.piva')"
        :value="this.form.piva"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.customers.cf')"
        :value="this.form.cf"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.customers.peo')"
        :value="this.form.peo"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.customers.pec')"
        :value="this.form.pec"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.customers.address')"
        :value="this.form.address"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.customers.cap')"
        :value="this.form.cap"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.customers.city')"
        :value="this.form.city"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.customers.province')"
        :value="this.form.province"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.customers.phone')"
        :value="this.form.phone"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.LINK"
        :label="$t('forms.customers.website')"
        :value="this.form.website"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.customers.referent_name')"
        :value="this.form.referent_name"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.customers.referent_surname')"
        :value="this.form.referent_surname"
      />

      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.customers.dsga')"
        :value="this.form.dsga"
      />

      <DisplaySeparator
        :as="Enums.DISPLAY_AS.SEPARATOR"
        :label="$t('labels.generalPreferences')"
      />

      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.customers.preferences.ticket_creation')"
        :value="this.form.preferences.ticket_creation"
      />

      <DisplaySeparator
        :as="Enums.DISPLAY_AS.SEPARATOR"
        :label="$t('labels.metadataScuole')"
      />

      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.customers.metadata_scuole.dirigente_territoriale.city')"
        :value="this.form.metadata_scuole.dirigente_territoriale.city"
      />

      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.customers.metadata_scuole.dirigente_territoriale.email')"
        :value="this.form.metadata_scuole.dirigente_territoriale.email"
      />


      <DisplaySeparator
        :as="Enums.DISPLAY_AS.SEPARATOR"
        :label="$t('labels.gdprPreferences')"
      />

      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.customers.preferences.gdpr_public_page_visibility')"
        :value="this.form.preferences.gdpr_public_page_visibility"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.customers.preferences.gdpr_owncloud_password')"
        :value="this.form.preferences.gdpr_owncloud_password"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.customers.preferences.gdpr_owncloud_folder')"
        :value="this.form.preferences.gdpr_owncloud_folder"
      />
    </div>
  </v-container>
</template>

<script>
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { mapActions, mapGetters } from "vuex";
import DisplayKV from "@/components/Shared/DisplayKeyValue";
import FormButtons from "@/components/Shared/FormButtons";
import DisplaySeparator from "@/components/Shared/DisplaySeparator";
import _ from "lodash";

export default {
  name: "CustomerForm",
  props: {
    mode: {
      default: Enums.FORM_MODE.CREATE,
      type: String
    },
    selectedItem: Object,
    withModelId: {
      type: String,
      required: false
    },
    withModelType: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      resourceType: this.$t("resource_types.customer"),
      form: {
        id: "",
        address: "",
        cap: "",
        cf: "",
        city: "",
        mechanographyc_code: "",
        name: "",
        pec: "",
        peo: "",
        phone: "",
        piva: "",
        province: "",
        referent_name: "",
        referent_surname: "",
        dsga: "",
        tenant_id: this.$props.withModelId,
        type_id: "",
        website: "",
        preferences: {
          gdpr_owncloud_folder: "",
          gdpr_owncloud_password: "",
          gdpr_public_page_visibility: Enums.GDPR_PUBLIC_PAGE_VISIBILITY.AUTO,
          ticket_creation: Enums.TICKET_CREATION.AUTO
        },
        metadata_scuole: {
          dirigente_territoriale : {
            city: "",
            province: "",
            email: ""
          }
        }
      },
      localTenant: "",
      localCustomerType: "",
      ticketCreationEnum: [],
      gdprPublicPageVisibilityEnum: []
    };
  },
  components: { DisplayKV, DisplaySeparator, FormButtons },
  mixins: [SharedMixin, Enums],
  watch: {
    selectedItem() {
      this.initForm();
      this.initComp();
    }
  },
  methods: {
    ...mapActions("customer", ["ajaxCustomerCreateOrUpdate"]),
    ...mapActions("customerType", ["ajaxCustomerTypeFetchAll"]),
    ...mapActions("tenant", ["ajaxTenantFetchAll"]),

    initForm() {
      if (this.mode == Enums.FORM_MODE.CREATE) {
        this.setForm();
      } else {
        let metadata_scuole = this.decodeMetadataScuole(this.$props.selectedItem);
        let preferences = this.decodePreferences(this.$props.selectedItem);
        this.$props.selectedItem.metadata_scuole = metadata_scuole;
        this.$props.selectedItem.preferences = preferences;
        this.setForm(this.$props.selectedItem);
        this.localTenant = this.$props.selectedItem.tenant;
        this.localCustomerType = this.$props.selectedItem.customer_type;
      }
    },
    async initComp() {
      await this.ajaxTenantFetchAll();
      await this.ajaxCustomerTypeFetchAll();
      this.buildTicketCreationEnums();
      this.buildGDPRPageVisibilityEnum();
    },
    decodePreferences(item) {
      let basePreferences = {
        gdpr_owncloud_folder: "",
        gdpr_owncloud_password: "",
        gdpr_public_page_visibility: Enums.GDPR_PUBLIC_PAGE_VISIBILITY.AUTO,
        ticket_creation: Enums.TICKET_CREATION.AUTO
      };

      let currentPreferences = null;
      if (typeof item.preferences == "object") {
        currentPreferences = item.preferences;
      } else {
        const meta = JSON.parse(item.preferences);
        currentPreferences = meta;
      }
      return _.merge(basePreferences, currentPreferences);
    },
    encodePreferences(item) {
      let basePreferences = {
        gdpr_owncloud_folder: "",
        gdpr_owncloud_password: "",
        gdpr_public_page_visibility: Enums.GDPR_PUBLIC_PAGE_VISIBILITY.AUTO,
        ticket_creation: Enums.TICKET_CREATION.AUTO
      };

      let currentPreferences = item.preferences || basePreferences;
      return JSON.stringify(currentPreferences);
      // return _.merge(basePreferences, currentPreferences);
    },
    decodeMetadataScuole(item) {
      let baseMetadataScuole = {
        dirigente_territoriale: {
          city: "",
          email: "",
          province: ""          
        }
      };

      let currentMetadataScuole = null;
      if (typeof item.metadata_scuole == "object") {
        currentMetadataScuole = item.metadata_scuole;
      } else {
        const meta = JSON.parse(item.metadata_scuole);
        currentMetadataScuole = meta;
      }
      return _.merge(baseMetadataScuole, currentMetadataScuole);
    },
    encodeMetadataScuole(item) {
      let baseMetadataScuole = {
        dirigente_territoriale: {
          city: "",
          email: "",
          province: ""
        }
      };

      let currentMetadataScuole = item.metadata_scuole || baseMetadataScuole;
      return JSON.stringify(currentMetadataScuole);
    },

    async onSubmit() {
      const validate = await this.$validator.validateAll();
      if (validate) {
        await this.submitToStore();
      } else {
        alert(this.$t("forms.common.errorValidateFields"));
      }
    },
    async onCancel() {
      this.$emit("formCancel");
    },
    async submitToStore() {
      const payload = _.clone(this.form);
      payload.metadata_scuole = this.encodeMetadataScuole(payload);
      payload.preferences = this.encodePreferences(payload);
      await this.createOrUpdateHelper(
        this.mode,
        this.resourceType,
        this.ajaxCustomerCreateOrUpdate,
        payload,
        payload => payload.p.name,
        payload => payload.p.name
      );
      this.$emit("formSucceed");
    },
    setForm(row = null) {
      if (row && !_.isEmpty(row)) {
        this.form.id = row.id;
        this.form.address = row.address;
        this.form.cap = row.cap;
        this.form.cf = row.cf;
        this.form.city = row.city;
        this.form.code = row.code;
        this.form.mechanographyc_code = row.mechanographyc_code;
        this.form.name = row.name;
        this.form.pec = row.pec;
        this.form.peo = row.peo;
        this.form.phone = row.phone;
        this.form.piva = row.piva;
        this.form.preferences = row.preferences;
        this.form.metadata_scuole = row.metadata_scuole;
        this.form.province = row.province;
        this.form.referent_name = row.referent_name;
        this.form.referent_surname = row.referent_surname;
        this.form.dsga = row.dsga;
        this.form.tenant_id = row.tenant_id;
        this.form.type_id = row.type_id.toString();
        this.form.website = row.website;
      } else if (row == null) {
        this.form.id = "";
        this.form.address = "";
        this.form.cap = "";
        this.form.cf = "";
        this.form.city = "";
        this.form.code = "";
        this.form.mechanographyc_code = "";
        this.form.name = "";
        this.form.pec = "";
        this.form.peo = "";
        this.form.phone = "";
        this.form.piva = "";
        this.form.province = "";
        this.form.referent_name = "";
        this.form.referent_surname = "";
        this.form.dsga = "";
        this.form.tenant_id = this.$props.withModelId || "";
        this.form.type_id = "";
        this.form.metadata_scuole.dirigente_territoriale.city = "";
        this.form.metadata_scuole.dirigente_territoriale.province = "";
        this.form.metadata_scuole.dirigente_territoriale.email = "";
        this.form.preferences.gdpr_owncloud_folder = "";
        this.form.preferences.gdpr_owncloud_password = "";
        this.form.preferences.gdpr_public_page_visibility =
          Enums.GDPR_PUBLIC_PAGE_VISIBILITY.AUTO;
        this.form.preferences.ticket_creation = Enums.TICKET_CREATION.AUTO;
      }
    },
    buildTicketCreationEnums() {
      const keys = Object.keys(Enums.TICKET_CREATION);
      keys.map(k => this.ticketCreationEnum.push(k));
    },
    buildGDPRPageVisibilityEnum() {
      const keys = Object.keys(Enums.GDPR_PUBLIC_PAGE_VISIBILITY);
      keys.map(k => this.gdprPublicPageVisibilityEnum.push(k));
    },
    onCustomerTypeChange() {
      this.$validator.validate("customer_type");
    }
  },
  computed: {
    ...mapGetters("tenant", ["tenants"]),
    ...mapGetters("customerType", ["customerTypes"]),

    Enums() {
      return Enums;
    },
    formTitle() {
      var title = "";
      const subject = this.$t("labels.customer");

      switch (this.mode) {
        case Enums.FORM_MODE.CREATE:
          title = this.$t("labels.createResourceType", {
            resourceType: subject || "-"
          });
          break;
        case Enums.FORM_MODE.UPDATE:
          title = this.$t("labels.updateResourceTypeName", {
            resourceType: subject || "-",
            resourceName: this.form.name || "-"
          });
          break;

        default:
          break;
      }
      return title;
    }
  },
  created() {
    this.initForm();
    this.initComp();
  }
};
</script>

<style scoped>
.has-error {
  color: red;
}
.required label:before {
  color: #e32;
  content: "* ";
  display: inline;
}
.summary-font {
  font-size: small;
  line-height: 24px;
}
.summary-font-bold {
  font-size: small;
  font-weight: bold;
  line-height: 24px;
}
</style>
<!--<style scoped>
input[aria-required="true"] {
  border-color: red;
}
input[aria-invalid="true"] {
  border-color: yellow;
}

input[aria-invalid="false"] {
  border-color: green;
}
</style>-->
