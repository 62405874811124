<template>
  <v-container fluid class="grey lighten-4 title">
    <div v-if="mode == Enums.FORM_MODE.UPDATE">
      <v-card-title class="form-title title">
        {{ formTitle | capitalize }}
      </v-card-title>
      <v-form class="form-body" @submit.prevent="onSubmit">
        <!-- SUBJECT -->
        <div :class="form.state == 'EMITTED' ? 'form-group required' : 'form-group'">
          <v-text-field
            :label="$t('forms.invoicing.code')"
            v-model="form.code"
            name="code"
            v-validate.immediate="form.state == 'EMITTED' ? 'required' : ''"
          ></v-text-field>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('code')"
            >{{ veeErrors.first("code") }}</span
          >
        </div>
        <div class="form-group required">
          <v-select
            v-model="form.state"
            :items="this.options.state"
            item-text="name"
            item-value="id"
            :label="$t('forms.invoicing.state')"
            name="state"
          ></v-select>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('state')"
            >{{ veeErrors.first("state") }}</span
          >
        </div>
        <div >
          <v-select
            v-model="form.cashed"
            :items="this.options.cashed"
            item-text="name"
            item-value="id"
            :label="$t('forms.invoicing.cashed')"
            name="cashed"
          ></v-select>
        </div>
        <!-- BUTTONS -->
        <FormButtons
          @onSave="onSubmit"
          @onCancel="onCancel"
          :disabled="veeErrors.any()"
        />
      </v-form>
    </div>
  </v-container>
</template>

<script>
import _ from "lodash";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { mapActions } from "vuex";
import FormButtons from "@/components/Shared/FormButtons";

export default {
  props: {
    mode: {
      default: Enums.FORM_MODE.CREATE,
      type: String
    },
    selectedItem: Object
  },
  data() {
    return {
      progress: true,
      resourceType: this.$t("resource_types.invoice"),
      form: {
        id: "",
        code: "",
        state: "",
        cashed: ""
      },
      options: {
        state: [{ id: "EMITTED" }, { id: "NOT_EMITTED" }, { id: "CANCELLED" }],
        cashed: [{ id: "CASHED" }, { id: "NOT_CASHED" }]
      }
    };
  },
  components: { FormButtons },
  mixins: [SharedMixin, Enums],
  methods: {
    ...mapActions("invoice", ["ajaxInvoiceCreateOrUpdate"]),

    async onSubmit() {
      const validate = await this.$validator.validateAll();
      if (validate) {
        await this.submitToStore();
      } else {
        alert(this.$t("forms.common.errorValidateFields"));
      }
    },
    async onCancel() {
      this.$emit("formCancel");
    },
    async submitToStore() {
      let payload = _.clone(this.form);
      payload.cashed = payload.cashed == "CASHED" ? true : false;
      await this.createOrUpdateHelper(
        this.mode,
        this.resourceType,
        this.ajaxInvoiceCreateOrUpdate,
        payload,
        payload => payload.p.description,
        payload => payload.p.description
      );
      this.$emit("formSucceed");
    },
    setForm(row = null) {
      if (row && !_.isEmpty(row)) {
        this.form.id = row.id;
        this.form.code = row.code;
        this.form.state = row.state;
        this.form.cashed = row.cashed ? "CASHED" : "NOT_CASHED";
      } else if (row == null) {
        this.form.id = "";
        this.form.code = "";
        this.form.state = "";
        this.form.cashed = "";
      }
    },
    initForm() {
      if (this.mode == Enums.FORM_MODE.CREATE) {
        this.setForm();
      } else {

        this.setForm(this.$props.selectedItem);
      }
    },
    async initComp() {}
  },
  computed: {
    Enums() {
      return Enums;
    },
    formTitle() {
      var title = "";
      switch (this.mode) {
        case Enums.FORM_MODE.CREATE:
          title = this.$t("labels.createResourceType", {
            resourceType: this.resourceType || "-"
          });
          break;
        case Enums.FORM_MODE.UPDATE:
          title = this.$t("labels.updateResourceTypeName", {
            resourceType: this.resourceType || "-",
            resourceName: this.form.id || "-"
          });
          break;

        default:
          break;
      }
      return title;
    }
  },
  created() {
    this.arrayTranslation("invoices", this.options.state, "id", "name");
    this.arrayTranslation("invoices", this.options.cashed, "id", "name");
    this.initForm();
    this.initComp();
  }
};
</script>

<style scoped>
.has-error {
  color: red;
}
.required label:before {
  color: #e32;
  content: "* ";
  display: inline;
}
.summary-font {
  font-size: small;
  line-height: 24px;
}
.summary-font-bold {
  font-size: small;
  font-weight: bold;
  line-height: 24px;
}
</style>
