import Vue from "vue";

const TicketService = {
  async fetchOne(id) {
    if (id == null) {
      return null;
    }
    try {
      const data = await Vue.$jsonApi.find("ticket", id, {
        include: "tenant,customer,agreement,user,users"
      });
      return data.data[0];
    } catch (error) {
      console.log("fetchOne error: ", error);
      return { error: error };
    }
  },
  async fetchAll(searchFilterPagination, headers_query) {
    try {
      Vue.$jsonApi.headers.query = headers_query;
      const res = await Vue.$jsonApi.findAll("tickets", searchFilterPagination);
      return res;
    } catch (error) {
      console.log("fetchAll error: ", error);
      return { error: error };
    }
  },
  async createOrUpdate(payload) {
    try {
      if (payload.id == "" || payload.id == null) {
        return await Vue.$jsonApi.create("ticket", payload);
      } else {
        return await Vue.$jsonApi.update("ticket", payload);
      }
    } catch (error) {
      console.log("createOrUpdate error: ", error);
      return { error: error };
    }
  },
  async delete(id) {
    if (id == null) {
      return null;
    }
    try {
      const data = await Vue.$jsonApi.destroy("ticket", id);
      return data;
    } catch (error) {
      console.log("delete error: ", error);
      return { error: error };
    }
  }
};

export { TicketService };
