var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "app", toolbar: "" } },
    [
      _c("vue-confirm-dialog"),
      this.isLogged ? _c("NavigationBar") : _vm._e(),
      _c(
        "v-main",
        [
          _c(
            "v-container",
            { staticClass: "main-container", attrs: { fluid: "" } },
            [
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [_c("router-view")],
                1
              ),
              _c("Snackbar")
            ],
            1
          )
        ],
        1
      ),
      _c("v-footer", { attrs: { app: "", height: "25" } }, [_c("Footer")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }