import Vue from "vue";
import Vuex from "vuex";
import { GenerationSessionService } from "@/services/generationSession.service";
import { HelperService } from "@/services/helper.service";

Vue.use(Vuex);

const state = {
  generationSessions: [],
  selected: {},
  loading: false,
  totalCount: 0
};

const getters = {
  generationSessions: state => state.generationSessions,
  onLoading: state => state.loading,
  selectedGenerationSession: state => state.selected,
  total: state => state.totalCount
};

const mutations = {
  setSelected(state, sel) {
    state.selected = sel;
  },
  setList(state, sel) {
    state.generationSessions = sel;
  },
  setTotal(state, sel) {
    state.totalCount = Number(sel);
  },

  setLoading(state, sel) {
    state.loading = sel;
  }
};

const formatting = function(instance) {
  instance.metadata = JSON.parse(instance.metadata);
  instance.allegato = instance.metadata.allegato;
  instance.customers = instance.metadata.customers;
  instance.templates = instance.metadata.templates;
};

const actions = {
  async ajaxGenerationSessionSetSelected({ commit }, payload) {
    await HelperService.genericSetSelected(
      commit,
      GenerationSessionService.fetchOne,
      payload.id,
      formatting
    );
  },

  async ajaxGenerationSessionFetchAll({ commit }, payloadFAP) {
    const searchFilterPagination = HelperService.localToJSONAPIPagination(
      payloadFAP
    );
    searchFilterPagination.include = "user";

    await HelperService.genericFetchAll(
      commit,
      GenerationSessionService.fetchAll,
      searchFilterPagination,
      null,
      formatting
    );
  },

  async ajaxGenerationSessionCreateOrUpdate({ commit }, payload) {
    HelperService.unformatGenerationSession(payload);
    return HelperService.genericCreateOrUpdate(
      commit,
      GenerationSessionService.createOrUpdate,
      payload
    );
  },

  async ajaxGenerationSessionDelete({ commit }, payload) {
    return HelperService.genericDelete(
      commit,
      GenerationSessionService.delete,
      payload.id
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
