var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "subtitle-2" }, [
        _vm._v(_vm._s(_vm.$t("forms.tickets.activities")))
      ]),
      _c("v-card", { staticClass: "activity-creator" }, [
        _c(
          "form",
          {
            staticClass: "form-group",
            class: { adding: this.isAdding, editing: this.isEditing }
          },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "px-3", attrs: { cols: "12" } },
                  [
                    _c("v-textarea", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.immediate",
                          value: "required",
                          expression: "'required'",
                          modifiers: { immediate: true }
                        }
                      ],
                      staticClass: "required",
                      attrs: {
                        name: "description",
                        "error-messages": _vm.errors.description,
                        label: _vm.$t("forms.tickets.log_activity")
                      },
                      on: { input: _vm.handleInputDescription },
                      model: {
                        value: _vm.form.description,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "description", $$v)
                        },
                        expression: "form.description"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c("v-col", { staticClass: "caption", attrs: { cols: "6" } }),
                _c(
                  "v-col",
                  { staticClass: "overline", attrs: { cols: "3" } },
                  [
                    _c("v-text-field", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      staticClass: "required caption",
                      attrs: {
                        label: _vm.$t("forms.tickets.people_number"),
                        name: "person_number",
                        type: "number"
                      },
                      model: {
                        value: _vm.form.person_number,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "person_number", $$v)
                        },
                        expression: "form.person_number"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "caption", attrs: { cols: "3" } },
                  [
                    _c(
                      "v-menu",
                      {
                        ref: "menu",
                        attrs: {
                          "close-on-content-click": false,
                          "nudge-right": 40,
                          "return-value": _vm.time,
                          transition: "scale-transition",
                          "offset-y": "",
                          "max-width": "290px",
                          "min-width": "290px"
                        },
                        on: {
                          "update:returnValue": function($event) {
                            _vm.time = $event
                          },
                          "update:return-value": function($event) {
                            _vm.time = $event
                          }
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-text-field",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "forms.tickets.billing_hours"
                                          ),
                                          "prepend-icon":
                                            "mdi-clock-time-four-outline"
                                        },
                                        model: {
                                          value: _vm.time,
                                          callback: function($$v) {
                                            _vm.time = $$v
                                          },
                                          expression: "time"
                                        }
                                      },
                                      "v-text-field",
                                      attrs,
                                      false
                                    ),
                                    on
                                  )
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.menu2,
                          callback: function($$v) {
                            _vm.menu2 = $$v
                          },
                          expression: "menu2"
                        }
                      },
                      [
                        _vm.menu2
                          ? _c("v-time-picker", {
                              attrs: { format: "24hr", "full-width": "" },
                              on: {
                                "click:minute": function($event) {
                                  return _vm.$refs.menu.save(_vm.time)
                                }
                              },
                              model: {
                                value: _vm.time,
                                callback: function($$v) {
                                  _vm.time = $$v
                                },
                                expression: "time"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              {
                staticClass: "activity-actions",
                attrs: { justify: "center", align: "center" }
              },
              [
                _c(
                  "v-col",
                  { staticClass: "text-center" },
                  [
                    this.isAdding == true
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "mx-3",
                            attrs: {
                              disabled:
                                _vm.veeErrors.any() ||
                                _vm.$props.ticketState ===
                                  _vm.getTicketStatus.CLOSED ||
                                _vm.$props.ticketState ===
                                  _vm.getTicketStatus.CANCELED ||
                                _vm.$props.ticketInternalState ===
                                  _vm.getTicketInternatStatus.FINISHED,
                              color: "info",
                              small: ""
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.addActivity()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("buttons.add")))]
                        )
                      : _vm._e(),
                    this.isAdding == true
                      ? _c(
                          "v-btn",
                          {
                            attrs: {
                              disabled:
                                _vm.veeErrors.any() ||
                                _vm.$props.ticketState ===
                                  _vm.getTicketStatus.CLOSED ||
                                _vm.$props.ticketState ===
                                  _vm.getTicketStatus.CANCELED ||
                                _vm.$props.ticketInternalState ===
                                  _vm.getTicketInternatStatus.FINISHED,
                              text: "",
                              small: ""
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.clearForm()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("buttons.clear")))]
                        )
                      : _vm._e(),
                    this.isEditing
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "mx-3",
                            attrs: { small: "", color: "info" },
                            on: { click: _vm.updateMe }
                          },
                          [_vm._v("\n            Save\n          ")]
                        )
                      : _vm._e(),
                    this.isEditing
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "mx-3",
                            attrs: { small: "" },
                            on: { click: _vm.clearForm }
                          },
                          [_vm._v("\n            Cancel\n          ")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm.onLoading
        ? _c("v-progress-linear", {
            attrs: { color: "info", height: "5", indeterminate: "" }
          })
        : _vm._e(),
      _vm._l(this.activities, function(item) {
        return _c(
          "v-card",
          { key: item.id, staticClass: "activity-card" },
          [
            _c(
              "v-flex",
              { attrs: { "ma-1": "" } },
              [
                _c(
                  "v-card-actions",
                  [
                    _c(
                      "v-row",
                      {
                        attrs: {
                          "fill-height": "",
                          "align-center": "",
                          "justify-space-between": ""
                        }
                      },
                      [
                        _c("v-col", { attrs: { cols: "8" } }, [
                          _c(
                            "div",
                            {
                              staticClass: "activity-owner",
                              attrs: { sm6: "" }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.signature(item)) +
                                  "\n            "
                              )
                            ]
                          )
                        ]),
                        _c("v-spacer"),
                        _c("v-col", { attrs: { cols: "4" } }, [
                          _c(
                            "div",
                            { staticClass: "activity-buttons" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: "",
                                    icon: "",
                                    color: "info",
                                    disabled:
                                      _vm.$props.ticketState ===
                                        _vm.getTicketStatus.CLOSED ||
                                      _vm.$props.ticketState ===
                                        _vm.getTicketStatus.CANCELED ||
                                      _vm.$props.ticketInternalState ===
                                        _vm.getTicketInternatStatus.FINISHED
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.prepareEdit(item)
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("edit")])],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: "",
                                    icon: "",
                                    color: "warning",
                                    disabled:
                                      _vm.$props.ticketState ===
                                        _vm.getTicketStatus.CLOSED ||
                                      _vm.$props.ticketState ===
                                        _vm.getTicketStatus.CANCELED ||
                                      _vm.$props.ticketInternalState ===
                                        _vm.getTicketInternatStatus.FINISHED
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleDelete(item)
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("delete")])],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-card-title",
                  [
                    _c("v-card-text", { staticClass: "activity-header" }, [
                      _vm._v(
                        " \n          " +
                          _vm._s(_vm.getDurataIntervento(item)) +
                          "\n        "
                      )
                    ]),
                    _c("v-card-text", { staticClass: "activity-header" }, [
                      _vm._v(
                        " \n          " +
                          _vm._s(_vm.getPersonNumber(item)) +
                          "\n        "
                      )
                    ]),
                    _c("v-card-text", { staticClass: "activity-message" }, [
                      _vm._v(
                        "\n          " + _vm._s(item.description) + "\n        "
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }