<template>
  <v-snackbar
    :color="context"
    :timeout="timeout"
    :top="true"
    :right="x === 'right'"
    :left="x === 'left'"
    :multi-line="mode === 'multi-line'"
    :vertical="mode === 'vertical'"
    :value="snackbar"
  >
    <div class="snack-text">{{ text }}</div>
    <v-btn class="close-button" dark @click.native="closeMessage()"
      >Close</v-btn
    >
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  methods: {
    closeMessage() {
      this.$store.dispatch("snackbar/closeMessage");
    }
  },
  computed: {
    ...mapGetters("snackbar", [
      "context",
      "mode",
      "text",
      "snackbar",
      "x",
      "y",
      "timeout"
    ])
  }
};
</script>

<style scoped>
.snack-text {
  display: inline-block;
  line-height: 36px;
  padding-right: 16px;
}
.close-button {
  float: right;
}
</style>
