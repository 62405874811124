import Vue from "vue";
import Vuex from "vuex";
import { PermaLinkService } from "@/services/permalink.service";
import { HelperService } from "@/services/helper.service";

Vue.use(Vuex);

const state = {
  permalinks: [],
  selected: {},
  onLoading: false,
  totalCount: 0,
};

const getters = {
  permalinks: (state) => state.permalinks,
  selectedLink: (state) => state.selected,
  onLoading: (state) => state.loading,
  totalCount: (state) => state.totalCount,
};

const mutations = {
  setList(state, sel) {
    state.permalinks = sel;
  },
  setSelected(state, sel) {
    state.selected = sel;
  },
  setLoading(state, sel) {
    state.onLoading = sel;
  },
  setTotal(state, sel) {
    state.totalCount = Number(sel);
  },
};

const actions = {
  async ajaxpPermaLinkSetSelected({ commit }, payload) {
    await HelperService.genericSetSelected(
      commit,
      PermaLinkService.fetchOne,
      payload.id
    );
  },
  async ajaxPermaLinkFetchAll({ commit }, payloadFAP) {
    let searchFilterPagination = HelperService.localToJSONAPIPagination(
      payloadFAP
    );
    await HelperService.genericFetchAll(
      commit,
      PermaLinkService.fetchAll,
      searchFilterPagination,
      null
    );
  },
  async ajaxPermaLinkCreateOrUpdate({ commit }, payload) {
    return HelperService.genericCreateOrUpdate(
      commit,
      PermaLinkService.createOrUpdate,
      payload
    );
  },
  async ajaxPermaLinkDelete({ commit }, payload) {
    return HelperService.genericDelete(
      commit,
      PermaLinkService.delete,
      payload.id
    );
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
