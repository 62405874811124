<template>
    <div>
        <div :class="{ 'center-panel': drawer_flag }">
          <v-card v-if="this.newRelease">
            <v-card-title>
              <span class="headline" style="color: red">Stiamo effettuando un aggiornamento del server. Momentaneamente non sarà possibile caricare decisioni a contrarre e atti relativi a procedure di affidamento. Ci scusiamo per il disagio.</span>
            </v-card-title>
          </v-card>
            <v-card>
              <v-toolbar flat class="toolbar-94">
                <v-row align="center" class="toolbar-list">
                  <v-col cols="1">
                    <v-btn fab small @click="goTo()">
                      <v-icon>{{ $t("icons.arrow_back") }}</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="8" class="toolbar-list-info">
                    <v-toolbar-title>
                      <p class="text-h4 text-sm-left my-1">{{ $t('labels.at') }}</p>
                    </v-toolbar-title>
                  </v-col>
                </v-row>
              </v-toolbar>
                
              <template>
                <v-list three-line flat>
                  <template v-for="(item, i) in listOptions">
                    <v-list-item
                      :key="i"
                      :to="item.to"
                      :disabled="item.disabled"
                      >
                      <v-list-item-icon>
                        <v-btn
                          icon
                          :title="item.info"
                          >
                          <v-icon v-text="'mdi-information'" color="grey lighten-1" class="tip-align"></v-icon>                                    
                        </v-btn>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.text"></v-list-item-title>                           
                        <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider v-if="item.divider==true"/>
                  </template>
                </v-list>
                <v-list>                    
                  <v-list-item
                    :disabled="links.length == 0"
                    @click="goToForm()"
                    >
                    <v-list-item-icon>
                      <v-btn
                        icon
                        :title="$t('labels.access_register.info')"
                        >
                        <v-icon v-text="'mdi-information'" color="grey lighten-1" class="tip-align"></v-icon>                                    
                      </v-btn>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{$t('labels.access_register.title')}}</v-list-item-title>
                      <v-list-item-subtitle>{{$t('labels.access_register.subtitle')}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </template>

              <template>
                <v-row justify="center">
                  <v-dialog v-model="dialog" persistent max-width="700px">
                      <v-card>
                          <v-card-text>
                          <v-container>
                              <v-row>
                              <v-col cols="12">
                                <v-card-title>
                                  <span class="headline" style="color: red">Il server Aruba è in manuntenzione.</span>
                                </v-card-title>
                                <v-card-title>
                                  <span class="headline" style="color: red">Al momento non è possibile caricare documenti.</span>
                                </v-card-title>
                                <v-card-title>
                                  <span class="headline" style="color: red">Ci scusiamo per il disagio.</span>
                                </v-card-title>
                              </v-col>
                              </v-row>
                          </v-container>
                          </v-card-text>
                      </v-card>
                  </v-dialog>
                </v-row>
              </template>
            </v-card>
        </div>
    </div>

</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import FormsHelper from "@/mixins/formsHelper";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { HelperService } from "@/services/helper.service";

import ToolbarList from "@/components/Shared/ToolbarList";

const computed = {
  ...mapGetters("link", ["links"]),
  ...mapGetters("folder", ["folders"]),
  
  _() {
    return _;
  },
  Enums() {
    return Enums;
  }
};

export default {
  name: "dashboard",
  components: {
    ToolbarList
  },
  props: {
    withModelId: {
      required: true
    }
  },
  data() {
    return {
      drawer_flag: false,
      dialog: false,
      newRelease: false,
      filter_criteria: {},
      machineName: "AMMINISTRAZIONE_TRASPARENTE",
      tagName: "FORM_COMPILAZIONE_REGISTRO_ACCESSO_CIVICO",
      listOptions: [],
      show: false
    };
  },
  methods: {
    ...mapActions("folder", ["ajaxFolderFetchAll"]),
    ...mapActions("link", ["ajaxLinkFetchAll"]),

    async initComp() {
      this.filter_criteria.having_tag_of_type = [this.tagName];
      let _filter = null;
      _filter = { model_type: "agreements" };
      if (this.$props.withModelId) {
        _filter = { ..._filter, ...{ model_id: this.$props.withModelId } };
      }

      let payloadFAP = HelperService.buildFilterAndPagination({
        filter: _filter,
        filter_criteria: this.filter_criteria
      });

      await this.ajaxLinkFetchAll(payloadFAP);

      _filter = { ..._filter, ...{ parent_id: "null"} };
      payloadFAP = HelperService.buildFilterAndPagination({
        filter: _filter
      });
      await this.ajaxFolderFetchAll(payloadFAP);

      this.listOptions = this.setListOptions();
    },
    setListOptions() {
      const filteredFolders = _.filter(this.folders, function(f) { return f.enabled; })
      let map = _.map(filteredFolders, item => {

        const info = item.metadata ? JSON.parse(item.metadata).info : item.name;
        const divider = (item && item.tagging && item.tagging.tag && (item.tagging.tag.name == this.Enums.TAG_NAME.PROCEDURA_AFFIDAMENTO || item.tagging.tag.name == this.Enums.TAG_NAME.ATTI_AMMINISTRAZIONI_ENTI || item.tagging.tag.name == this.Enums.TAG_NAME.ATTI_NOMINA || item.tagging.tag.name == this.Enums.TAG_NAME.VERBALI_REVISORI_CONTI ) ) ? true : false;
        item.fields = {text: item.name, description: item.description, to: `/amministrazione_trasparente/${this.$props.withModelId}/sections/${item.id}`, info: info, divider: divider }
        //const disabled = this.newRelease && (item.tagging && item.tagging.tag && (item.tagging.tag.name == this.Enums.TAG_NAME.DETERMINE_DECISIONI_CONTRARRE || item.tagging.tag.name == this.Enums.TAG_NAME.ATTI_PROCEDURA || item.tagging.tag.name == this.Enums.TAG_NAME.ATTI_AMMINISTRAZIONI_ENTI)) ? true : false;
        //item.fields = {text: item.name, description: item.description, to: `/amministrazione_trasparente/${this.$props.withModelId}/sections/${item.id}`, info: info, divider: divider, disabled: disabled }
        
        return item.fields;
      });

      return map;
      /*var items = [
        { text: 'Programma annuale', description: 'Caricamento file relativi al programma annuale', to: `/amministrazione_trasparente/${this.$props.withModelId}/programma_annuale`, icon: 'mdi-clock' },
        { text: 'Conto consuntivo', description: 'Caricamento file relativi al conto consuntivo', to: `/amministrazione_trasparente/${this.$props.withModelId}/conto_consuntivo`, icon: 'mdi-account' },
        { text: 'Relazioni al programma annuale e al conto consuntivo', description: 'Caricamenti file relativi a relazioni DS + DSGA', to: `/amministrazione_trasparente/${this.$props.withModelId}/relazioni_pa_cc`, icon: 'mdi-flag' },
        { text: 'Verbali dei revisori conti', description: 'Caricamento file relativi ai verbali dei revisori dei conti', to: `/amministrazione_trasparente/${this.$props.withModelId}/verbali_revisori_conti`, icon: 'mdi-flag' },
        { text: 'Atti delle amministrazioni aggiudicatrici e degli enti aggiudicatori distintamente per ogni procedura', description: 'Creazione sezioni e caricamenti file', to: `/amministrazione_trasparente/${this.$props.withModelId}/atti_amministrazioni_enti`, icon: 'mdi-flag' },
        //{ text: 'Registro degli accessi', description: 'Form di compilazione registro degli accessi civici', to: `/amministrazione_trasparente/${this.$props.withModelId}/registro_accesso_civico`, disabled: true, icon: 'mdi-flag' }
      ];
      return items*/
    },
    goTo() {
      this.$router.pushCatch({ name: "dashboard" });
    },
    goToForm() {
      if (this.links.length == 1) {
        const link = this.links[0];
        window.open(link.url, '_blank');
      }
    }
  },
  mixins: [FormsHelper, Enums, SharedMixin],
  async created() {
    this.initComp();
  },
  computed
};
</script>

<style scoped>

.v-list-item__title {
  font-size: 16px;
  font-weight: bold;
}

.colored {
  color: #00f;
}

</style>