<!-- eslint-disable prettier/prettier -->
<template>
  <div :key="componentKey">

    <PermaLinkList 
      :withAgreementId="this.withModelId" 
      :folderId="this.folderId"
      @formSucceed="forceRerender"
      ></PermaLinkList>

    <FoldersList
      :withModelType="this.withModelType"
      :withModelId="this.withModelId"
      :withParentId="this.withParentId"
      :listMode="Enums.LIST_MODE.FULL"
      :isRootSection="true"
      >
    </FoldersList>

  </div>
</template>

<script>
import Enums from "@/mixins/enums";
import _ from "lodash";
import PermaLinkList from "../components/PermaLink/PermaLinkList.vue";
import FoldersList from "../components/Folder/FoldersList.vue";

export default {
  components: {
    PermaLinkList,
    FoldersList
  },

  props: {
    withModelType: {
      default: Enums.MODEL_NAME.AGREEMENTS,
      type: String,
    },
    withModelId: {
      type: String,
      required: true
    },
    withParentId: {
      type: String,
      required: true
    },
    folderId: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      formDialog: false,
      componentKey: 0
    };
  },

  methods: {
    forceRerender() {
      this.componentKey += 1;
    }
  },
  computed: {
    Enums() {
      return Enums;
    },
  },

  created() {},
};
</script>
