<template>
  <v-container fluid class="grey lighten-4 title">
    <div
      v-if="mode == Enums.FORM_MODE.CREATE || mode == Enums.FORM_MODE.UPDATE"
    >
      <v-card-title class="form-title title">{{
        this.formTitle | capitalize
      }}</v-card-title>
      <v-form class="form-body" @submit.prevent="onSubmit">
        
        <div class="form-group required">
          <DatePicker
            v-validate.immediate="'required'"
            :label="$t('forms.attachments.protocol_date')"
            v-model="form.protocol_date"
            name="protocol_date"
          ></DatePicker>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('protocol_date')"
            >{{ veeErrors.first("protocol_date") }}</span
          >
        </div>

        <div class="form-group required">
          <v-text-field
            :label="$t('forms.attachments.protocol_number')"
            v-model="form.protocol_number"
            name="protocol_number"
            v-validate.immediate="'required'"
          ></v-text-field>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('protocol_number')"
            >{{ veeErrors.first("protocol_number") }}</span
          >
        </div>

        <div class="form-group required">
          <v-text-field
            :label="$t('forms.attachments.detail')"
            v-model="form.detail"
            name="detail"
            v-validate.immediate="'required'"
          ></v-text-field>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('detail')"
            >{{ veeErrors.first("detail") }}</span
          >
        </div>

        <div class="form-group required" v-if="mode == Enums.FORM_MODE.CREATE">
          <v-file-input
            ref="fileupload"
            :accept="acceptedFormats"
            clearable
            @change="handleFileUpload"
            :label="$t('misc.file')"
            name="file"
            v-model="files"
          ></v-file-input>
        </div>

        <!-- BUTTON -->
        <FormButtons
          @onSave="onSubmit"
          @onCancel="onCancel"
          :disabled="veeErrors.any()"
        />
      </v-form>
    </div>
    <div v-show="mode === Enums.FORM_MODE.SHOW"></div>
  </v-container>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { mapActions, mapGetters } from "vuex";
import FileUploader from "@/components/Attachment/FileUploader";
import FormButtons from "@/components/Shared/FormButtons";
import DatePicker from "@/components/Shared/DatePicker";

export default {
  components: {
    FileUploader,
    FormButtons,
    DatePicker
  },
  props: {
    mode: {
      default: Enums.FORM_MODE.CREATE,
      type: String
    },
    selectedItem: Object,
    permittedTypes: String,
    withDescription: Boolean,
    withModelType: {
      type: String,
      required: true
    },
    withModelId: {
      type: String,
      required: true
    },
    tagName: {},
    folderId: {}
  },
  data() {
    return {
      resourceType: this.$t("resource_types.attachment"),
      attachment: null,
      acceptedFormats:
        ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, .jpg, .png",
      form: {
        id: "",
        title: "",
        detail: "",
        name: "",
        file: "",
        temp_file: "",
        file_type_id: "",
        folder_id: this.$props.folderId,
        tag_ids: "",
        model_type: this.$props.withModelType,
        model_id: this.$props.withModelId,
        pickedFiles: null,
        //payload: null
      },
      files: null,
    };
  },

  mixins: [SharedMixin, Enums],
  /*watch: {
    async withModelType() {
      this.form.model_type = this.$props.withModelType;
    },
    async withModelId() {
      this.form.model_id = this.$props.withModelId;
    }
  },*/
  methods: {
    ...mapActions("attachment", [
      "ajaxAttachmentDelete",
      "ajaxAttachmentCreateOrUpdate"
    ]),
    ...mapActions("attachmentType", [
      "ajaxAttachmentTypeFetchAll"
    ]),
    ...mapActions("tag", [
      "ajaxTagFetchAll"
    ]),
    handleFileUpload(e) {
      this.form.pickedFiles = e;
      if (this.form.pickedFiles) {
        let payload = {};
        const fileReader = new FileReader();
        const fileToRead = this.form.pickedFiles;
        fileReader.onload = async e2 => {
          try {
            this.form.name = fileToRead.name;
            this.form.temp_file = e2.target.result;
          } catch (e) {
            console.log("error during file upload: ", e);
            this.form.name = "";
            this.form.temp_file = null;
          }
        };
        fileReader.readAsDataURL(fileToRead);
      } else {
      }
    },
    async onSubmit() {
      const validate = await this.$validator.validateAll();
      if (validate) {
        await this.submitToStore();
      } else {
        alert(this.$t("forms.common.errorValidateFields"));
      }
    },
    async submitToStore() {
      this.form.title = moment(this.form.protocol_date).format("YYYY/MM/DD") + " - " + this.form.protocol_number + " - " + this.form.detail;
      let payload = _.clone(this.form);
      await this.createOrUpdateHelper(
        this.mode,
        this.resourceType,
        this.ajaxAttachmentCreateOrUpdate,
        payload,
        payload => payload.p.title,
        payload => payload.p.title
      );
      this.$emit("formSucceed");
    },
    async onCancel() {
      this.$emit("formCancel");
    },
    setForm(row = null) {
      if (row && !_.isEmpty(row)) {
        const title = row.title;
        const title_parts = title.split("-");

        this.form.id = row.id;
        this.form.name = row.name;
        this.form.title = row.title;
        this.form.detail = row.detail;
        this.form.protocol_date = moment(title_parts[0]);
        this.form.protocol_number= title_parts[1];
        this.form.file = row.file;
        this.form.file_type_id = row.file_type_id;
        this.form.folder_id = row.folder_id;
        this.form.tag_ids = _.map(row.taggings, "tag_id");
      } else if (row == null) {
        this.form.id = "";
        this.form.name = "";
        this.form.title = "";
        this.form.detail = "";
        this.form.file = "";
        this.form.temp_file = "";
        this.form.file_type_id = "";
        this.form.tag_ids = "";
      }
    },
    initForm() {
      if (this.mode == Enums.FORM_MODE.CREATE) {
        this.setForm();
      } else {
        this.setForm(this.$props.selectedItem);
      }
    },
    async initComp() {
      let payloadFAP = { filter: { name: this.$props.tagName } }
      await this.ajaxTagFetchAll(payloadFAP);
      this.form.tag_ids = _.map(this.tags, "id");
      payloadFAP = { machineName: this.$props.tagName, filter: { machine_name: this.$props.tagName } }
      await this.ajaxAttachmentTypeFetchAll(payloadFAP);
      if (this.attachmentTypes && this.attachmentTypes.length === 1) {
        this.form.file_type_id = this.attachmentTypes[0].id;
      }
    }
  },
  computed: {
    ...mapGetters("attachment", ["attachments", "total"]),
    ...mapGetters("attachmentType", ["attachmentTypes"]),
    ...mapGetters("tag", ["tags"]),

    Enums() {
      return Enums;
    },
    formTitle() {
      var title = "";
      switch (this.mode) {
        case Enums.FORM_MODE.CREATE:
          title = this.$t("labels.createResourceType", {
            resourceType: this.resourceType || "-"
          });
          break;
        case Enums.FORM_MODE.UPDATE:
          title = this.$t("labels.updateResourceTypeName", {
            resourceType: this.resourceType || "-",
            resourceName: this.form.name || "-"
          });
          break;

        default:
          break;
      }
      return title;
    }
  },
  created() {
    this.initForm();
    this.initComp();
  }
};
</script>

<style scoped>
.has-error {
  color: red;
}
.required label:before {
  color: #e32;
  content: "* ";
  display: inline;
}
.summary-font {
  font-size: small;
  line-height: 24px;
}
.summary-font-bold {
  font-size: small;
  font-weight: bold;
  line-height: 24px;
}
</style>
