<template>
  <v-container fluid class="form-container title">
    <v-card-title class="form-title title">{{
      this.formTitle | capitalize
    }}</v-card-title>
    <v-form class="form-body" @submit.prevent="onSubmit">
      <div class="form-group">
        <v-text-field
          :label="$t('forms.emails.from')"
          v-model="form.from"
          readonly
        ></v-text-field>
      </div>
      <div class="form-group">
        <v-text-field
          :label="$t('forms.emails.to')"
          v-model="form.to"
          readonly
        ></v-text-field>
      </div>
      <div class="form-group">
        <v-text-field
          :label="$t('forms.emails.subject')"
          v-model="form.subject"
          readonly
        ></v-text-field>
      </div>
      <div class="form-group">
        {{ $t("forms.emails.body") }}
        <MailViewer :body="form.body"></MailViewer>
      </div>
      <!-- BUTTONS -->
      <v-row>
        <v-col cols="12" class="text-right">
          <v-btn text @click="onClose">{{ $t("buttons.close") }}</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import _ from "lodash";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { mapActions, mapGetters } from "vuex";
import MailViewer from "@/components/Shared/MailViewer";

export default {
  name: "EmailForm",
  props: {
    selectedItem: Object,
    withModelId: {
      type: String,
      required: false
    },
    withModelType: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      // progress: true,
      resourceType: this.$t("resource_types.email_message"),
      form: {
        id: "",
        from: "",
        to: "",
        subject: "",
        body: ""
      }
    };
  },
  components: { MailViewer },
  mixins: [SharedMixin, Enums],
  watch: {
    selectedItem() {
      this.initForm();
      this.initComp();
    }
  },
  methods: {
    initForm() {
      this.setForm(this.$props.selectedItem);
    },
    async initComp() {},
    async onClose() {
      this.$emit("formCancel");
    },
    setForm(row = null) {
      if (row && !_.isEmpty(row)) {
        this.form.id = row.id;
        this.form.from = row.from;
        this.form.to = row.to;
        this.form.subject = row.subject;
        this.form.body = row.body;
      }
    }
  },
  computed: {
    Enums() {
      return Enums;
    },
    formTitle() {
      var title = "";
      title = this.$t("labels.detailsResourceTypeName", {
        resourceType: this.resourceType || "-",
        resourceName: this.form.id ? this.form.id : "-"
      });
      return title;
    }
  },
  created() {
    this.initForm();
    this.initComp();
  }
};
</script>

<style scoped>
.has-error {
  color: red;
}
.required label:before {
  color: #e32;
  content: "* ";
  display: inline;
}
.summary-font {
  font-size: small;
  line-height: 24px;
}
.summary-font-bold {
  font-size: small;
  font-weight: bold;
  line-height: 24px;
}
</style>
