<template>
  <div>
    <CustomerFilter
      @filtersSelected="handleFiltersSelected"
      ref="drawer"
      @drawerChanged="handleDrawer"
    ></CustomerFilter>
    <div :class="{ 'center-panel': drawer_flag }">      
      <v-card v-if="this.listMode === Enums.LIST_MODE.FULL">
        <ToolbarList
          :title="$t('labels.customers')"
          :subtitle="$tc('labels.found_elements', this.total)"
          :withSearch="true"
          :withAdd="$can('addButton', 'customers')"
          @onAdd="openFormDialog(Enums.FORM_MODE.CREATE)"
          @onSearch="searchFunc"
          :withFilter="true"
          @onFilter="$refs.drawer.open()"
          :allClear="allClear"
        ></ToolbarList>
        <BaseGrid
          tableName="customers"
          :headers="this.tableData.headers"
          :items="this.tableData.items"
          :totalLength="this.total"
          :pagination="this.tableOptions"
          :loading="this.onLoading"
          @onPaginationChanged="handlePaginationChanged"
          :hideActions="false"
        ></BaseGrid>
      </v-card>
      <v-card v-if="this.listMode === Enums.LIST_MODE.TABBED">
        <ToolbarTab
          :subtitle="$tc('labels.found_elements', this.total)"
          :withAdd="$can('addButton', 'customers')"
          @onAdd="openFormDialog(Enums.FORM_MODE.CREATE)"
        ></ToolbarTab>
        <BaseGrid
          tableName="customers"
          :headers="this.tableData.headers"
          :items="this.tableData.items"
          :totalLength="this.total"
          :pagination="this.tableOptions"
          :loading="this.onLoading"
          @onPaginationChanged="handlePaginationChanged"
          :hideActions="true"
        ></BaseGrid>
      </v-card>
      <v-dialog
        v-model="formDialog"
        persistent
        content-class="edit-form-dialog"
      >
        <CustomerForm
          v-if="formDialog"
          :mode="Enums.FORM_MODE.CREATE"
          :withModelId="this.withModelId"
          @formSucceed="handleFormSucceed"
          @formCancel="handleFormCancel"
        ></CustomerForm>
      </v-dialog>
      <v-dialog
        v-model="cloneDialog"
        persistent
        content-class="edit-form-dialog"
      >
        <CustomerClone
          :selectedItem="formItem"
          @formSucceed="handleFormSucceed"
          @formCancel="handleFormCancel"
        ></CustomerClone>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import CustomerForm from "@/components/Customer/CustomerForm";
import CustomerClone from "@/components/Customer/CustomerClone";
import ToolbarList from "@/components/Shared/ToolbarList";
import ToolbarTab from "@/components/Shared/ToolbarTab";
import { HelperService } from "@/services/helper.service";

import BaseGrid from "@/components/Shared/BaseGrid";
import FormsHelper from "@/mixins/formsHelper";
import Enums from "@/mixins/enums";
import CustomerFilter from "@/components/Filter/CustomerFilter";

const computed = {
  ...mapGetters("customer", ["customers", "total", "onLoading"]),
  ...mapGetters("login", ["token"]),
  _() {
    return _;
  },
  Enums() {
    return Enums;
  },
  customersList() {
    return this.customers;
  }
};

export default {
  name: "customersList",
  components: {
    CustomerForm,
    CustomerClone,
    ToolbarList,
    ToolbarTab,
    BaseGrid,
    CustomerFilter
  },
  props: {
    withModelId: {
      default: null,
      type: String
    },
    withModelType: {
      default: null,
      type: String
    },
    listMode: {
      default: Enums.LIST_MODE.FULL,
      type: String
    }
  },
  data() {
    return {
      resourceType: this.$t("resource_types.customer"),
      allClear: true,
      search: "",
      filter: {},
      filter_criteria: {},
      drawer_flag: false,
      tableData: { headers: [], items: [] },
      tableOptions: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["name"]
      }
    };
  },
  mixins: [FormsHelper, Enums],
  watch: {
  },
  methods: {
    ...mapActions("customer", ["ajaxCustomerFetchAll"]), 
    searchFunc: async function(searchQuery) {
      this.search = searchQuery
      await this.fetchData();
    },
    handleDrawer(val) {
      this.drawer_flag = val;
    },
    async fetchData(needle = null) {
      const _search = needle ? needle : this.search || "";
      const _filter = this.$props.withModelId
        ? { tenant_id: this.$props.withModelId, ...this.filter }
        : this.filter;

      let payloadFAP = HelperService.buildFilterAndPagination({
        search: _search,
        filter: _filter,
        filter_criteria: this.filter_criteria,
        pagination: this.tableOptions
      });
      await this.ajaxCustomerFetchAll(payloadFAP);
      this.initTable();
    },
    initTable() {
      this.tableData.headers = this.mapHeaders();
      this.tableData.items = this.mapItems();
    },
    mapHeaders() {
      const tableHeaders = [];
      if (this.listMode === Enums.LIST_MODE.FULL) {
        tableHeaders.push({
          text: "Name",
          value: "name",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Code",
          value: "code",
          sortable: true,
          align: "start"
        });
        tableHeaders.push({
          text: "Peo",
          value: "peo",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Phone",
          value: "phone",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Tenant",
          value: "tenant_id",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Actions",
          value: "actions",
          align: "center",
          sortable: false
        });
      }
      if (this.listMode === Enums.LIST_MODE.TABBED) {
        tableHeaders.push({
          text: "Name",
          value: "name",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Code",
          value: "code",
          sortable: true,
          align: "start"
        });
        tableHeaders.push({
          text: "Peo",
          value: "peo",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Phone",
          value: "phone",
          align: "start",
          sortable: true
        });
      }
      return tableHeaders;
    },
    mapItems() {
      let map = {};
      if (this.listMode === Enums.LIST_MODE.FULL) {
        map = _.map(this.customersList, item => {
          item.fields = {
            name: { data: item.name, dataType: "text", css: "text-uppercase" },
            code: {
              data: this.getCodeByCustomerType(item),
              dataType: "text",
              css: "text-xs-left"
            },
            peo: { data: item.peo, dataType: "text", css: "text-xs-left" },
            phone: { data: item.phone, dataType: "text", css: "text-xs-left" },
            tenantName: {
              data: item.tenant ? item.tenant.name : "",
              dataType: "text",
              css: "text-xs-left"
            }
          };
          item.click = {
            actionType: "router-link",
            namedRoot: "customerDetails",
            namedRootId: item.id,
            icon: this.$t("icons.detail"),
            show: true
          };
          item.actions = {
            export: {
              show: this.getService(item),
              disabled: false,
              actionType: "custom",
              tooltip: this.$t("buttons.export"),
              icon: this.$t("icons.export"),
              callback: item => {
                this.exportCustomer(item);
              }
            }
          };
          return item;
        });
      }
      if (this.listMode === Enums.LIST_MODE.TABBED) {
        map = _.map(this.customersList, item => {
          item.fields = {
            name: { data: item.name, dataType: "text", css: "text-xs-left" },
            code: {
              data: this.getCodeByCustomerType(item),
              dataType: "text",
              css: "text-xs-left"
            },
            peo: { data: item.peo, dataType: "text", css: "text-xs-left" },
            phone: { data: item.phone, dataType: "text", css: "text-xs-left" }
          };
          item.click = {
            actionType: "router-link",
            namedRoot: "customerDetails",
            namedRootId: item.id,
            icon: this.$t("icons.detail"),
            css: "text-xs-left",
            show: true
          };
          item.actions = {};
          return item;
        });
      }
      return map;
    },
    handlePaginationChanged(paginationOpts) {
      this.tableOptions = paginationOpts;
      this.fetchData();
    },
    handleFormSucceed() {
      this.closeFormDialog();
      this.closeCloneDialog();
      this.fetchData();
    },
    handleFormCancel() {
      this.closeFormDialog();
      this.closeCloneDialog();
    },
    getCodeByCustomerType(item) {
      return item.mechanographyc_code ? item.mechanographyc_code : item.piva;
    },
    goBack() {
      this.tableOptions = { ...this.tableOptions, page: 1 };
    },
    async handleFiltersSelected(
      allClear,
      withActiveAgreements,
      type,
      dateFrom,
      dateTo,
      tenantId,
      services
    ) {
      this.goBack();
      this.allClear = allClear;
      
      if (withActiveAgreements) this.filter_criteria.having_active_agreement = withActiveAgreements;
      if (withActiveAgreements=='') delete this.filter_criteria['having_active_agreement']
      
      if (services) this.filter_criteria.having_agreement_of_type = services;
      if (services=='') delete this.filter_criteria['having_agreement_of_type']
      
      if (tenantId) this.filter.tenant_id = tenantId;
      if (tenantId=='') delete this.filter['tenant_id']

      if (type) this.filter.type_id = type;
      if (type=='') delete this.filter['type_id']

      if (dateFrom) this.filter.gt = { created_at: dateFrom };
      if (dateFrom==null) delete this.filter['gt']

      if (dateTo) this.filter.lt = { created_at: dateTo};
      if (dateTo==null) delete this.filter['lt']

      await this.fetchData();
      this.scrollToTop();
    },
    scrollToTop() {
      this.$vuetify.goTo(0);
    },
    getService(item) {
      let agreements = item.agreement ? item.agreement : null;
      let services = agreements ? _.map(agreements, "services") : null;
      let serviceMachineNameArray = [];
      for (let i=0; i<services.length;i++){
        const service = services[i];
        if(service.length > 0 && service[0].machine_name == "AMMINISTRAZIONE_TRASPARENTE") serviceMachineNameArray.push(service[0].machine_name)
      }
      let hasATService = serviceMachineNameArray && serviceMachineNameArray.length > 0 ? true : false;
      return hasATService
    },
    async exportCustomer(item) {
      let customerId = item.id;
      this.$confirm({
        message: this.$t("dialogs.export-customer") + " " + this.$t("dialogs.sure"),
        button: {
          no: this.$t("dialogs.no"),
          yes: this.$t("dialogs.yes")
        },
        callback: async confirm => {
          if (confirm) {
            Vue.Axios({
              url: `${process.env.VUE_APP_ENDPOINT_API_URI}/private/customers/export/${customerId}`,
              method: "get",
              withCredentials: true,
              headers: {
                Authorization: `Bearer ${this.token}`,
                "Content-Type": "application/json"
              },
              responseType: "blob"
            })
            .then(res => {
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(res.data);
              link.download = "export-data.csv";
              link.click();
            })
            .catch(err => {
              console.log("error: ", err);
              this.$emit("showError", [{ title: err }]);
            });
          }
        }
      });
    },
  },

  async created() {},
  computed
};
</script>
