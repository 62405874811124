/*  eslint no-shadow: ["error", { "allow": ["state"] }] */
/*  eslint no-param-reassign: ["error", { "props": false }] */
import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";
import { LinkService } from "@/services/link.service";
import { HelperService } from "@/services/helper.service";

Vue.use(Vuex);

const state = {
  links: [],
  selected: {},
  loading: false,
  totalCount: 0
};

const getters = {
  links: state => state.links,
  selectedLink: state => state.selected,
  onLoading: state => state.loading,
  total: state => state.totalCount
};

const mutations = {
  setList(state, sel) {
    state.links = sel;
  },
  setSelected(state, sel) {
    state.selected = sel;
  },
  setTotal(state, sel) {
    state.totalCount = Number(sel);
  },
  setLoading(state, sel) {
    state.loading = sel;
  }
};

const actions = {
  async ajaxLinkSetSelected({ commit }, payload) {
    await HelperService.genericSetSelected(
      commit,
      LinkService.fetchOne,
      payload.id
    );
  },

  async ajaxLinkFetchAll({ commit }, payload) {
    const _search = _.isNull(payload) ? "" : _.get(payload, "search", "");
    const _filter = _.isNull(payload) ? "" : _.get(payload, "filter", "");
    const _filter_criteria = _.isNull(payload)
      ? ""
      : _.get(payload, "filter_criteria", "");
    const _page = _.isNull(payload)
      ? process.env.VUE_APP_DEFAULT_PAGE
      : _.get(payload, "pagination.page", process.env.VUE_APP_DEFAULT_PAGE);
    const _pageSize = _.isNull(payload)
      ? process.env.VUE_APP_DEFAULT_PAGE_SIZE
      : _.get(
          payload,
          "pagination.itemsPerPage",
          process.env.VUE_APP_DEFAULT_PAGE_SIZE
        );
    const _sortBy = _.isNull(payload)
      ? ""
      : _.get(payload.pagination, "sortBy", "");
    const _sortDescending = _.isNull(payload)
      ? false
      : _.get(payload.pagination, "descending", false);

    let searchFilterPagination = {
      search: _search,
      filter: _filter,
      filter_criteria: _filter_criteria,
      page: {
        page: _page,
        pageSize: _pageSize
      },
      sort: ""
    };
    if (_sortBy && _sortDescending) {
      searchFilterPagination.sort = _sortBy;
    } else {
      searchFilterPagination.sort = `-${_sortBy}`;
    }

    await HelperService.genericFetchAll(
      commit,
      LinkService.fetchAll,
      searchFilterPagination,
      null,
      null
    );
  },

  async ajaxLinkCreateOrUpdate({ commit }, payload) {
    return HelperService.genericCreateOrUpdate(
      commit,
      LinkService.createOrUpdate,
      payload
    );
  },

  async ajaxLinkDelete({ commit }, payload) {
    return HelperService.genericDelete(commit, LinkService.delete, payload.id);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
