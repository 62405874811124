/*  eslint no-shadow: ["error", { "allow": ["state"] }] */
/*  eslint no-param-reassign: ["error", { "props": false }] */
import Vue from "vue";
import Vuex from "vuex";
import { UserService } from "@/services/user.service";
import { HelperService } from "@/services/helper.service";
import _ from "lodash";

Vue.use(Vuex);

const state = {
  users: [],
  roles: [],
  selected: {},
  loading: false,
  totalCount: 0
};

const getters = {
  users: state => state.users,
  roles: state => state.roles,
  selectedUser: state => state.selected,
  onLoading: state => state.loading,
  total: state => state.totalCount
};

const mutations = {
  setSelected(state, sel) {
    state.selected = sel;
  },
  setList(state, sel) {
    state.users = sel;
  },
  setRoles(state, sel) {
    state.roles = sel;
  },
  setTotal(state, sel) {
    state.totalCount = Number(sel);
  },
  setLoading(state, sel) {
    state.loading = sel;
  }
};

const actions = {
  async ajaxUserSetSelected({ commit }, payload) {
    await HelperService.genericSetSelected(
      commit,
      UserService.fetchOne,
      payload.id
    );
  },

  async ajaxUserFetchAll({ commit }, payload) {
    let searchFilterPagination = HelperService.localToJSONAPIPagination(
      payload
    );
    searchFilterPagination.include = "tenants,role";

    await HelperService.genericFetchAll(
      commit,
      UserService.fetchAll,
      searchFilterPagination,
      null,
      null
    );
  },

  async ajaxUserFilterAndOrder({ commit }, payload) {
    let filtered = _.filter(_.cloneDeep(state.users), user => {
      return (
        user.role.name != "super_admin" &&
        user.role.name != "customer" &&
        !user.first_name.startsWith("ns_") &&
        !user.last_name.startsWith("ns_")
      );
    });
    let ordered = _.orderBy(filtered, "first_name", "asc");
    commit("setList", ordered);
  },

  async ajaxUserCreateOrUpdate({ commit }, payload) {
    HelperService.unformatUser(payload);
    return HelperService.genericCreateOrUpdate(
      commit,
      UserService.createOrUpdate,
      payload
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
