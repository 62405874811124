var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "form-container title", attrs: { fluid: "" } },
    [
      _vm.mode == _vm.Enums.FORM_MODE.CREATE ||
      _vm.mode == _vm.Enums.FORM_MODE.UPDATE
        ? _c(
            "div",
            [
              _c("v-card-title", { staticClass: "form-title title" }, [
                _vm._v(_vm._s(_vm._f("capitalize")(this.formTitle)))
              ]),
              _c(
                "v-form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.mode == _vm.Enums.FORM_MODE.CREATE,
                      expression: "mode == Enums.FORM_MODE.CREATE"
                    }
                  ],
                  staticClass: "form-body",
                  attrs: { "data-vv-scope": "createOrder" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.onSubmit($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _c("CustomerAutocomplete", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.immediate",
                            value: "required",
                            expression: "'required'",
                            modifiers: { immediate: true }
                          }
                        ],
                        attrs: {
                          mode: _vm.mode,
                          itemText: "name",
                          itemValue: "id",
                          returnObject: true,
                          label: _vm.$t("forms.orders.customer"),
                          fetchFunction: _vm.ajaxCustomerFetchAll,
                          getter: _vm.customers,
                          name: "customer"
                        },
                        on: { input: _vm.customerSelected },
                        model: {
                          value: _vm.selectedCustomer,
                          callback: function($$v) {
                            _vm.selectedCustomer = $$v
                          },
                          expression: "selectedCustomer"
                        }
                      }),
                      _vm.veeErrors.has("createOrder.customer")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.veeErrors.first("createOrder.customer")
                                  ) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.immediate",
                            value: "required",
                            expression: "'required'",
                            modifiers: { immediate: true }
                          }
                        ],
                        attrs: {
                          label: _vm.$t("forms.orders.number"),
                          name: "number"
                        },
                        on: {
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "space", 32, $event.key, [
                                " ",
                                "Spacebar"
                              ])
                            ) {
                              return null
                            }
                            $event.preventDefault()
                          }
                        },
                        model: {
                          value: _vm.form.number,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "number", $$v)
                          },
                          expression: "form.number"
                        }
                      }),
                      _vm.veeErrors.has("createOrder.number")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.veeErrors.first("createOrder.number")
                                  ) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.immediate",
                            value: "required",
                            expression: "'required'",
                            modifiers: { immediate: true }
                          }
                        ],
                        attrs: {
                          label: _vm.$t("forms.orders.details"),
                          name: "detail"
                        },
                        model: {
                          value: _vm.form.detail,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "detail", $$v)
                          },
                          expression: "form.detail"
                        }
                      }),
                      _vm.veeErrors.has("createOrder.detail")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.veeErrors.first("createOrder.detail")
                                  ) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.users,
                          "item-text": _vm.getOperatorText,
                          "item-value": "id",
                          label: _vm.$t("forms.orders.operators"),
                          multiple: "",
                          name: "operators"
                        },
                        model: {
                          value: _vm.form.operator_ids,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "operator_ids", $$v)
                          },
                          expression: "form.operator_ids"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.mode == _vm.Enums.FORM_MODE.CREATE
                    ? _c(
                        "v-container",
                        [
                          _c("FileUploader", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate.immediate",
                                value: "required",
                                expression: "'required'",
                                modifiers: { immediate: true }
                              }
                            ],
                            ref: "fileuploader",
                            attrs: {
                              name: "fileUpload",
                              permittedTypes: "ORDINE",
                              mode: _vm.mode
                            },
                            model: {
                              value: _vm.attachment,
                              callback: function($$v) {
                                _vm.attachment = $$v
                              },
                              expression: "attachment"
                            }
                          }),
                          _vm.veeErrors.has("createOrder.fileUpload")
                            ? _c(
                                "span",
                                {
                                  class:
                                    _vm.Settings.style.class
                                      .formFieldRequiredCaption
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.veeErrors.first(
                                        "createOrder.fileUpload"
                                      )
                                    )
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("FormButtons", {
                    attrs: { disabled: _vm.veeErrors.any("createOrder") },
                    on: { onSave: _vm.onSubmit, onCancel: _vm.onCancel }
                  })
                ],
                1
              ),
              _c(
                "v-form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.mode == _vm.Enums.FORM_MODE.UPDATE,
                      expression: "mode == Enums.FORM_MODE.UPDATE"
                    }
                  ],
                  staticClass: "form-body",
                  attrs: { "data-vv-scope": "updateOrder" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.onSubmit($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.immediate",
                            value: "required",
                            expression: "'required'",
                            modifiers: { immediate: true }
                          }
                        ],
                        attrs: {
                          label: _vm.$t("forms.orders.number"),
                          name: "number"
                        },
                        model: {
                          value: _vm.form.number,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "number", $$v)
                          },
                          expression: "form.number"
                        }
                      }),
                      _vm.veeErrors.has("updateOrder.number")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.veeErrors.first("updateOrder.number")
                                  ) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("forms.orders.details"),
                          name: "detail"
                        },
                        model: {
                          value: _vm.form.detail,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "detail", $$v)
                          },
                          expression: "form.detail"
                        }
                      })
                    ],
                    1
                  ),
                  _c("FormButtons", {
                    attrs: { disabled: _vm.veeErrors.any("updateOrder") },
                    on: { onSave: _vm.onSubmit, onCancel: _vm.onCancel }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.mode === _vm.Enums.FORM_MODE.SHOW,
              expression: "mode === Enums.FORM_MODE.SHOW"
            }
          ],
          staticClass: "form-body-show"
        },
        [
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.orders.id"),
              value: this.form.id
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.orders.number"),
              value: this.form.number
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.orders.details"),
              value: this.form.detail
            }
          }),
          this.form.tickets
            ? _c("DisplayKV", {
                attrs: {
                  as: _vm.Enums.DISPLAY_AS.LINK,
                  label: _vm.$t("forms.orders.ticket"),
                  value: "#" + this.form.tickets.id,
                  custom: true,
                  namedRoot: "ticketDetails",
                  namedRootId: this.form.tickets.id
                }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }