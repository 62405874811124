/*  eslint no-shadow: ["error", { "allow": ["state"] }] */
/*  eslint no-param-reassign: ["error", { "props": false }] */
import Vue from "vue";
import Vuex from "vuex";
import { InvoiceService } from "@/services/invoice.service";
import { HelperService } from "@/services/helper.service";

Vue.use(Vuex);

const state = {
  invoices: [],
  selected: {},
  loading: false,
  totalCount: 0
};

const getters = {
  invoices: state => state.invoices,
  selectedInvoice: state => state.selected,
  onLoading: state => state.loading,
  total: state => state.totalCount
};

const mutations = {
  setSelected(state, sel) {
    state.selected = sel;
  },
  setList(state, sel) {
    state.invoices = sel;
  },
  setTotal(state, sel) {
    state.totalCount = Number(sel);
  },
  setLoading(state, sel) {
    state.loading = sel;
  }
};

const actions = {
  async ajaxInvoiceSetSelected({ commit }, payload) {
    await HelperService.genericSetSelected(
      commit,
      InvoiceService.fetchOne,
      payload.id
    );
  },

  async ajaxInvoiceFetchAll({ commit }, payload) {
    let searchFilterPagination = HelperService.localToJSONAPIPagination(
      payload
    );
    searchFilterPagination.include = "invoice_periods,invoiceable";

    await HelperService.genericFetchAll(
      commit,
      InvoiceService.fetchAll,
      searchFilterPagination,
      null,
      null
    );
  },

  async ajaxInvoiceCreateOrUpdate({ commit }, payload) {
    console.log('payload: ', payload)
    return HelperService.genericCreateOrUpdate(
      commit,
      InvoiceService.createOrUpdate,
      payload
    );
  },

  async ajaxInvoiceDelete({ commit }, payload) {
    return HelperService.genericDelete(
      commit,
      InvoiceService.delete,
      payload.id
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
