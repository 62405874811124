import Vue from "vue";
import Vuex from "vuex";
import { ApplicationLogService } from "@/services/applicationLog.service";
import { HelperService } from "@/services/helper.service";
import _ from "lodash";

Vue.use(Vuex);

const state = {
  applicationLogs: [],
  selected: {},
  loading: false,
  totalCount: 0
};

const getters = {
  applicationLogs: state => state.applicationLogs,
  onLoading: state => state.loading,
  selectedApplicationLog: state => state.selected,
  total: state => state.totalCount
};

const mutations = {
  setSelected(state, sel) {
    state.selected = sel;
  },
  setList(state, sel) {
    state.applicationLogs = sel;
  },
  setTotal(state, sel) {
    state.totalCount = Number(sel);
  },

  setLoading(state, sel) {
    state.loading = sel;
  }
};

const linearize = function(diff) {
  let toSearch = ["metadata", "invoice_data", "preferences"];
  toSearch.forEach(value => {
    if (value in diff) {
      if (typeof diff[value] !== "object")
        diff[value] = JSON.parse(diff[value]);
      for (let prop in diff[value]) {
        diff[prop] = diff[value][prop];
      }
      delete diff[value];
    }
  });
};

const formatting = function(instance) {
  instance.metadata = JSON.parse(instance.metadata);
  if (instance.metadata.old_object && instance.metadata.new_object) {
    instance.diff = HelperService.difference(
      instance.metadata.new_object,
      instance.metadata.old_object
    );
  } else if (instance.metadata.new_object)
    instance.diff = _.cloneDeep(instance.metadata.new_object);
  else if (instance.metadata.old_object)
    instance.diff = _.cloneDeep(instance.metadata.old_object);

  if (instance.diff) linearize(instance.diff);
};

const actions = {
  async ajaxApplicationLogSetSelected({ commit }, payload) {
    await HelperService.genericSetSelected(
      commit,
      ApplicationLogService.fetchOne,
      payload.id,
      formatting
    );
  },

  async ajaxApplicationLogFetchAll({ commit }, payloadFAP) {
    const searchFilterPagination = HelperService.localToJSONAPIPagination(
      payloadFAP
    );
    searchFilterPagination.include = "user";
    await HelperService.genericFetchAll(
      commit,
      ApplicationLogService.fetchAll,
      searchFilterPagination,
      null,
      formatting
    );
  },

  async ajaxApplicationLogCreateOrUpdate({ commit }, payload) {
    return HelperService.genericCreateOrUpdate(
      commit,
      ApplicationLogService.createOrUpdate,
      payload
    );
  },

  async ajaxApplicationLogDelete({ commit }, payload) {
    return HelperService.genericDelete(
      commit,
      ApplicationLogService.delete,
      payload.id
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
