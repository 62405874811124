<template>
  <ul id="navigation">
    <template v-for="item in navigationItems">
      <li v-if="$can(item.name, 'app')" v-bind:key="item.text">
        <template v-if="item.children">
          <div class="text-center">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  {{item.text}}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(child, index) in item.children"
                  :key="index"
                >
                  <v-list-item-title :class="{ active: isActive(child.name) }" @click="goToPage(child,'reports')">{{ child.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
        <!--template v-if="item.children">
          <a :href="item.url" :title="item.name" @click="setOpen(item)">
            {{ item.text }}
            <svg viewBox="0 0 451.847 451.847" width="12">
              <path
                d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
                                  c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
                                  c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"
                fill="#fff"
              />
            </svg>
          </a>
          <div :class="item.isOpen ? 'dropdown' : ''" class="dropdown">
            <ul>
              <li v-for="child in item.children" :key="child.text">
                <a
                  :title="child.name"
                  @click="goToPage(child)"
                  :class="{ active: isActive(child.name) }"
                  >{{ child.text }}</a
                >
              </li>
            </ul>
          </div>
        </template-->
        <template v-else>
          <a
            :title="item.name"
            @click="goToPage(item)"
            :class="{ active: isActive(item.name) }"
            >{{ item.text }}</a
          >
        </template>
      </li>
    </template>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
import SharedMixin from "@/mixins/shared";
//import UserProfile from "@/components/UserProfile";

export default {
  name: "Navigation",
  props: ["item"],
  data() {
    return {
      active: false,
      mapping: {
        agreements: "agreements",
        attachments: "attachments",
        tickets: "tickets",
        orders: "orders",
        tenants: "tenants",
        services: "services",
        customers: "customers",
        generation_sessions: "generation_sessions",
        //user_activities: "user_activities",
        application_log: "application_log",
        users: "users",
        tenantDetails: "tenants",
        agreementDetails: "agreements",
        agreementInvoicing: "agreements",
        serviceDetails: "services",
        customerDetails: "customers",
        orderDetails: "orders",
        ticketDetails: "tickets",
        application_settings: "application_settings",
        email_messages: "email_messages",
        invoices: "invoices",
        reports: "reports"
      },
      navigationItems: [
        {
          name: "agreements",
          text: this.$t("navigation_nouns.agreements"),
          icon: "fas fa-file"
        },
        {
          name: "tickets",
          text: this.$t("navigation_nouns.tickets"),
          icon: "fas fa-clipboard-list"
        },
        {
          name: "orders",
          text: this.$t("navigation_nouns.orders"),
          icon: "fas fa-file"
        },
        {
          name: "users",
          text: this.$t("navigation_nouns.users"),
          icon: "fas fa-building"
        },
        {
          name: "tenants",
          text: this.$t("navigation_nouns.tenants"),
          icon: "fas fa-building"
        },
        {
          name: "services",
          text: this.$t("navigation_nouns.services"),
          icon: "fas fa-stream"
        },
        {
          name: "customers",
          text: this.$t("navigation_nouns.customers"),
          icon: "fas fa-address-book"
        },
        {
          name: "generation_sessions",
          text: this.$t("navigation_nouns.generationSessions"),
          icon: "fas fa-bomb"
        },
        {
          name: "application_log",
          text: this.$t("navigation_nouns.applicationLog"),
          icon: "fas fa-bomb"
        },
        {
          name: "application_settings",
          text: this.$t("navigation_nouns.applicationSettings"),
          icon: "fas fa-bomb"
        },
        {
          name: "email_messages",
          text: this.$t("navigation_nouns.emailMessages"),
          icon: "fas fa-bomb"
        },
        {
          name: "invoices",
          text: this.$t("navigation_nouns.invoices"),
          icon: "fas fa-bomb"
        },
        {
          name: "reports",
          text: this.$t("navigation_nouns.reports"),
          icon: "fas fa-bomb",
          children: [
            {
              name: "questionario_gdpr",
              text: "Questionario GDPR"
            },
            {
              name: "expiring_agreements",
              text: "Contratti in scadenza"
            }
          ]
        }
      ]
    };
  },
  components: {
    //UserProfile
  },
  mixins: [SharedMixin],
  computed: {
    ...mapGetters("login", ["isLogged", "user"]),
    ...mapGetters("common", ["drawer"])
  },
  methods: {
    goToPage(item, prefix) {
      this.navigationItems.forEach(function(item) {
        if (item.isOpen == true) {
          item.isOpen = false;
        }
      });
      if (prefix && prefix!="") {
        this.$router.push({ name: prefix, params: { report_type: item.name } })
      } else {
        this.$router.pushCatch({ name: item.name });
      }
    },
    isActive(menuItem) {
      return this.mapping[this.$route.name] === menuItem;
    },
    setOpen(menuItem) {
      this.navigationItems.forEach(function(item) {
        if (item.isOpen == true) {
          item.isOpen = false;
        }
      });
      menuItem.isOpen = !menuItem.isOpen;
    }
  }
};
</script>

<style scoped lang="sass">
$black: #212529
$white: #ffffff

*,
::before,
::after
  box-sizing: border-box
  padding: 0
  margin: 0

=flex($fd: row, $jc: center, $ai: center)
  display: flex
  flex-direction: $fd
  justify-content: $jc
  align-items: $ai

ul
  list-style-type: none

  li
    position: relative
    margin: 0.25em
    text-align: center

    a
      display: block
      padding: 0.55em 1em
      text-decoration: none

      //color: darken($white, 15%);
      color: $white
      border-radius: 0.35em
      background-color: rgba($black, 0.25)
      transition: all 200ms ease

      &.active
        background-color: rgba($black, 0.45)

    ul
      li
        a
          &.active
            background-color: rgba($black, 0.45)

  &#navigation
    +flex($ai: flex-start)

.dropdown
  position: absolute
  left: 50%
  transform: translatex(-50%) rotatex(90deg) scale(0)
  margin-top: 0.55em
  transform-origin: 0 0
  border-radius: 0.35em
  background-color: #0064ba
  visibility: hidden
  opacity: 0
  transition: all 200ms linear

  &.isOpen
    transform: translatex(-50%)
    visibility: visible
    opacity: 1
</style>
