var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _vm.withDelete
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "warning",
                        text: "",
                        disabled: _vm.createLoading
                      },
                      on: {
                        click: function($event) {
                          return _vm.onDelete()
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("delete")])],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "text-right", attrs: { cols: "6" } },
            [
              _vm.createLoading
                ? _c("v-progress-circular", {
                    staticClass: "mr-3",
                    attrs: { indeterminate: "" }
                  })
                : _vm._e(),
              _vm.multiForm && _vm.multiLayout > 0
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-3",
                      attrs: { color: "primary", disabled: _vm.createLoading },
                      on: {
                        click: function($event) {
                          return _vm.onBack()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("buttons.back")))]
                  )
                : _vm._e(),
              _vm.multiForm && _vm.multiLayout != 2
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-3",
                      attrs: {
                        color: "primary",
                        disabled: _vm.disabled || _vm.createLoading
                      },
                      on: {
                        click: function($event) {
                          return _vm.onNext()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("buttons.next")))]
                  )
                : _vm._e(),
              !_vm.multiForm || _vm.multiLayout == 2
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-3",
                      attrs: {
                        color: "primary",
                        disabled: _vm.disabled || _vm.createLoading
                      },
                      on: {
                        click: function($event) {
                          return _vm.onSave()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("buttons.save")))]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: { disabled: _vm.createLoading, text: "" },
                  on: {
                    click: function($event) {
                      return _vm.onCancel()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("buttons.cancel")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }