<template>
  <v-app id="app" toolbar>
    <vue-confirm-dialog></vue-confirm-dialog>

    <!--<NavigationMenu v-if="this.isLogged" />-->
    <NavigationBar v-if="this.isLogged" />

    <v-main>
      <v-container fluid class="main-container">
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
        <!-- <AppLoadingIndicator></AppLoadingIndicator> -->
        <Snackbar />
      </v-container>
    </v-main>

    <v-footer app height="25">
      <Footer />
    </v-footer>
  </v-app>
</template>

<script>
import NavigationBar from "./components/NavigationBar";

import Snackbar from "./components/Snackbar";
import Footer from "./components/Footer";

import ApiService from "./services/api.service";
import { mapActions, mapGetters, mapState } from "vuex";

import "vue-select/dist/vue-select.css";

export default {
  name: "App",
  components: {
    NavigationBar,
    Snackbar,
    // AppLoadingIndicator,
    Footer
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("login", ["isLogged", "user", "loggedUser"]),
    ...mapState("login", ["token"])
  },
  methods: {
    ...mapActions("login", ["ajaxSetProfile", "sessionClose"]),
    ...mapActions("common", [
      "completeConfiguration",
      "clockStart",
      "clockStop",
      "startConfiguration"
    ]),

    applicationShutdown() {
      this.sessionClose();
      // this.clockStop();
      this.completeConfiguration();
      this.$router.pushCatch({ name: "login" });
    }
  },
  watch: {
    token(newValue) {
      if (!newValue) {
        this.applicationShutdown();
      } else {
        //console.log(`got new token ${newValue}`);
      }
    }
  },
  mounted() {
    ApiService.setHeader();
    //this.applicationReconfig();
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.15s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

html {
  overflow-y: hidden !important;
}
.v-main {
  height: 0px;
}
.v-main__wrap {
  overflow-y: scroll;
}
</style>
