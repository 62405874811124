/*  eslint no-shadow: ["error", { "allow": ["state"] }] */
/*  eslint no-param-reassign: ["error", { "props": false }] */
import Vue from "vue";
import Vuex from "vuex";
import moment from "moment";

Vue.use(Vuex);

const state = {
  configurating: false,
  configurated: false,
  drawer: true,
  now: moment(),
  clockTimer: null
};

const getters = {
  onConfiguring: state => state.configurating,
  configurated: state => state.configurated,
  drawer: state => state.drawer,
  now: state => state.now
};

const mutations = {
  setConfiguring(state, sel) {
    state.configurating = sel;
  },
  configurated(state, sel) {
    state.configurated = sel;
  },
  setDrawer(state, sel) {
    state.drawer = sel;
  }
  // updateTime(state) {
  //   state.now = moment()
  // },
  // clearTime(state) {
  //   state.now = null;
  // },
  // setClockTimer(state, interval) {
  //   if (state.clockTimer) {
  //     clearInterval(state.clockTimer);
  //   }
  //   state.clockTimer = interval;
  // },
};

const actions = {
  startConfiguration({ commit }) {
    commit("setConfiguring", true);
    commit("configurated", false);
  },

  completeConfiguration({ commit }) {
    commit("setConfiguring", false);
    commit("configurated", true);
  },
  toggleDrawer({ commit, state }) {
    commit("setDrawer", !state.drawer);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
